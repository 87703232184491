@import "../../node_modules/bootstrap/scss/variables";
@import "custom-variables";
chart {
    display: block;
}

.highcharts-container {
    position: relative;
    overflow: visible;
    width: 100%;
    height: 100%;
    text-align: left;
    line-height: normal;
    z-index: 0;
    /* #1072 */
    -webkit-tap-highlight-color: transparent;
    font-family: $base-font-family;
    font-size: $font-size-base;
    background: linear-gradient($white, $white, $whitegray);
    border: 1px solid $lightblue;
    box-shadow: $box-shadow-2;
    border-radius: $border-radius-base;
}

.highcharts-root text {
    stroke-width: 0;
}


.highcharts-background {
    fill: transparent;
}

.highcharts-plot-border, .highcharts-plot-background {
    fill: none;
}

.highcharts-label-box {
    fill: none;
}

.highcharts-button-box {
    fill: inherit;
}


/* Gradients 
#bg-gradient stop {
	stop-color: $whitegray;
}
#bg-gradient stop[offset="0"] {
	stop-opacity: 0.75;
}
#bg-gradient stop[offset="1"] {
	stop-opacity: 0;
}
*/




/* Titles */
.highcharts-title {
    fill: $blue;
    font-size: $h2-font-size;
}

.highcharts-subtitle {
    fill: $gray;
    font-size: $h3-font-size;
}

/* Axes */
.highcharts-axis-line {
    fill: none;
    stroke: $lightgray;
}

.highcharts-yaxis .highcharts-axis-line {
    stroke-width: 0;
}

.highcharts-axis-title {
    fill: $gray;
}

.highcharts-axis-labels {
    fill: $gray;
    cursor: default;
}

.highcharts-grid-line {
    fill: none;
    stroke: $lightgray;
}

.highcharts-xaxis-grid .highcharts-grid-line {
    stroke-width: 0;
}

.highcharts-tick {
    stroke: $lightgray;
}

.highcharts-yaxis .highcharts-tick {
    stroke-width: 0;
}

.highcharts-minor-grid-line {
    stroke: $whitegray;
}

.highcharts-crosshair-thin {
    stroke-width: 1px;
    stroke: $lightgray;
}

.highcharts-crosshair-category {
    stroke: $whiteblue;
    stroke-opacity: 0.25;
}

/* Credits */
.highcharts-credits {
    cursor: none;
    fill: $white;
    font-size: 1px;
    transition: fill 250ms, font-size 250ms;
}

.highcharts-credits:hover {
    fill: $white;
    font-size: 1px;
}

/* Tooltip */
.highcharts-tooltip {
    cursor: default;
    pointer-events: none;
    white-space: nowrap;
    transition: stroke 150ms;
    filter: none !important; //remove drop shadow on everything

    hr {
        margin-bottom: 10px;
    }
}

.highcharts-tooltip text {
    fill: $gray;
}

.highcharts-tooltip-box {
    stroke: $lightgray;
    stroke-width: 1px;
    fill: $white;
    fill-opacity: 1;
}


.highcharts-selection-marker {
    fill: $green;
    fill-opacity: 0.25;
}

.highcharts-graph {
    fill: none;
    stroke-width: 2px;
    stroke-linecap: round;
    stroke-linejoin: round;
}

.highcharts-state-hover .highcharts-graph {
    stroke-width: 3;
}

.highcharts-state-hover path {
    transition: stroke-width 50;
    /* quick in */
}

.highcharts-state-normal path {
    transition: stroke-width 250ms;
    /* slow out */
}

/* Legend hover affects points and series */
g.highcharts-series, .highcharts-point {
    transition: opacity 250ms;
}

.highcharts-legend-series-active g.highcharts-series:not(.highcharts-series-hover),
.highcharts-legend-point-active .highcharts-point:not(.highcharts-point-hover) {
    opacity: 0.2;
}

/* Series options */
/* Default colors */
.highcharts-color-0,
.hc-darkblue,
.hc-darkblue .highcharts-point {
    fill: $darkblue;
    stroke: $darkblue;
}

.highcharts-color-1,
.hc-green,
.hc-green .highcharts-point {
    fill: $green;
    stroke: $green;
}

.highcharts-color-2,
.hc-yellowgreen,
.hc-yellowgreen .highcharts-point {
    fill: $highcharts-color-2;
    stroke: $highcharts-color-2;
}

.highcharts-color-3,
.hc-darkcyan,
.hc-darkcyan .highcharts-point {
    fill: $highcharts-color-3;
    stroke: $highcharts-color-3;
}

.highcharts-color-4,
.hc-purple,
.hc-purple .highcharts-point {
    fill: $purple;
    stroke: $purple;
}

.highcharts-color-5,
.hc-orange,
.hc-orange .highcharts-point {
    fill: $orange;
    stroke: $orange;
}

.highcharts-color-6,
.hc-darkgreen,
.hc-darkgreen .highcharts-point {
    fill: $darkgreen;
    stroke: $darkgreen;
}

.highcharts-color-7,
.hc-lightblue,
.hc-lightblue .highcharts-point {
    fill: $highcharts-color-7;
    stroke: $highcharts-color-7;
}

.highcharts-color-8,
.hc-yellow,
.hc-yellow .highcharts-point {
    fill: $yellow;
    stroke: $yellow;
}

.highcharts-color-9,
.hc-blue,
.hc-blue .highcharts-point {
    fill: $blue;
    stroke: $blue;
}

.hc-red,
.hc-red .highcharts-point {
    fill: $red;
    stroke: $red;
}


.highcharts-area {
    fill-opacity: 0.75;
    stroke-width: 0;
}

.highcharts-markers {
    stroke-width: 1px;
    stroke: $white;
}

.highcharts-point {
    stroke-width: 1px;
}

.highcharts-dense-data .highcharts-point {
    stroke-width: 0;
}

.highcharts-data-label {
    font-weight: normal;
    font-size: $font-size-small;
}

.highcharts-data-label-box {
    fill: none;
    stroke-width: 0;
}

.highcharts-data-label text {
    fill: $gray;
}

.highcharts-data-label-connector {
    fill: none;
}

.highcharts-halo {
    fill-opacity: 0.25;
    stroke-width: 0;
}

.highcharts-point-select {
    fill: $lightgray;
    stroke: $black;
}



.highcharts-column-series .highcharts-point {
    stroke: $white;
    transition: fill-opacity 250ms;
}

.highcharts-column-series .highcharts-point-hover {
    fill-opacity: 0.75;
    transition: fill-opacity 50ms;
}

.highcharts-pie-series .highcharts-point {
    stroke-linejoin: round;
    stroke: $white;
}

.highcharts-pie-series .highcharts-point-hover {
    fill-opacity: 0.75;
    transition: fill-opacity 50ms;
}

.highcharts-pie-series .highcharts-point-select {
    fill: inherit;
    stroke: inherit;
}

.highcharts-funnel-series .highcharts-point {
    stroke-linejoin: round;
    stroke: $white;
}

.highcharts-funnel-series .highcharts-point-hover {
    fill-opacity: 0.75;
    transition: fill-opacity 50ms;
}

.highcharts-funnel-series .highcharts-point-select {
    fill: inherit;
    stroke: inherit;
}

.highcharts-pyramid-series .highcharts-point {
    stroke-linejoin: round;
    stroke: $white;
}

.highcharts-pyramid-series .highcharts-point-hover {
    fill-opacity: 0.75;
    transition: fill-opacity 50ms;
}

.highcharts-pyramid-series .highcharts-point-select {
    fill: inherit;
    stroke: inherit;
}

.highcharts-solidgauge-series .highcharts-point {
    stroke-width: 0;
}

.highcharts-treemap-series .highcharts-point {
    stroke-width: 1px;
    stroke: $whitegray;
    transition: stroke 250ms, fill 250ms, fill-opacity 250ms;
}

.highcharts-treemap-series .highcharts-point-hover {
    stroke: $gray;
    transition: stroke 25ms, fill 25ms, fill-opacity 25ms;
}

.highcharts-treemap-series .highcharts-above-level {
    display: none;
}

.highcharts-treemap-series .highcharts-internal-node {
    fill: none;
}

.highcharts-treemap-series .highcharts-internal-node-interactive {
    fill-opacity: 0.15;
    cursor: pointer;
}

.highcharts-treemap-series .highcharts-internal-node-interactive:hover {
    fill-opacity: 0.75;
}

/* Legend */
.highcharts-legend-box {
    fill: $white;
    stroke-width: 1px;
    stroke: $lightgray;
}

.highcharts-legend-item text {
    fill: $gray;
    font-weight: bold;
    cursor: pointer;
    stroke-width: 0;
}

.highcharts-legend-item:hover text {
    fill: $gray;
}

.highcharts-legend-item-hidden * {
    fill: $medium-gray !important;
    stroke: $medium-gray !important;
    transition: fill 250ms;
}

.highcharts-legend-nav-active {
    fill: $darkblue;
    cursor: pointer;
}

.highcharts-legend-nav-inactive {
    fill: $lightgray;
}

.highcharts-legend-title-box {
    fill: none;
    stroke-width: 0;
}

/* Loading */
.highcharts-loading {
    position: absolute;
    background-color: $white;
    opacity: 0.5;
    text-align: center;
    z-index: 10;
    transition: opacity 250ms;
}

.highcharts-loading-hidden {
    height: 0 !important;
    opacity: 0;
    overflow: hidden;
    transition: opacity 250ms, height 250ms step-end;
}

.highcharts-loading-inner {
    font-weight: bold;
    position: relative;
    top: 45%;
}

/* Plot bands and polar pane backgrounds */
.highcharts-plot-band {
    fill: $lightgreen;
    fill-opacity: 1;
}

.highcharts-plot-line {
    fill: none;
    stroke: $nobel-gray;
    stroke-width: 1px;
}

/* Highcharts More */
.highcharts-boxplot-box {
    fill: $white;
}

.highcharts-boxplot-median {
    stroke-width: 2px;
}

.highcharts-bubble-series .highcharts-point {
    fill-opacity: 0.5;
}

.highcharts-errorbar-series .highcharts-point {
    stroke: $black;
}

.highcharts-gauge-series .highcharts-data-label-box {
    stroke: $medium-gray;
    stroke-width: 1px;
}

.highcharts-gauge-series .highcharts-dial {
    fill: $black;
    stroke-width: 0;
}

.highcharts-polygon-series .highcharts-graph {
    fill: inherit;
    stroke-width: 0;
}

.highcharts-waterfall-series .highcharts-graph {
    stroke: $dark-charcoal-gray;
    stroke-dasharray: 1, 3;
}











/* Highstock */
.highcharts-navigator-mask {
    fill: $danube-blue;
    /* navigator.maskFill option */
    fill-opacity: 0.25;
}

.highcharts-navigator-mask-inside {
    fill: $danube-blue;
    /* navigator.maskFill option */
    fill-opacity: 0.25;
    cursor: ew-resize;
}

.highcharts-navigator-outline {
    stroke: $medium-gray;
    fill: none;
}

.highcharts-navigator-handle {
    stroke: $medium-gray;
    fill: $white-smoke;
    cursor: ew-resize;
}

.highcharts-navigator-series {
    fill: $mariner-blue;
    stroke: $mariner-blue;
}

.highcharts-navigator-series .highcharts-graph {
    stroke-width: 1px;
}

.highcharts-navigator-series .highcharts-area {
    fill-opacity: 0.05;
}

.highcharts-navigator-xaxis .highcharts-axis-line {
    stroke-width: 0;
}

.highcharts-navigator-xaxis .highcharts-grid-line {
    stroke-width: 1px;
    stroke: $white-dark-smoke;
}

.highcharts-navigator-xaxis.highcharts-axis-labels {
    fill: $nobel-gray
}

.highcharts-navigator-yaxis .highcharts-grid-line {
    stroke-width: 0;
}

.highcharts-scrollbar-thumb {
    fill: $medium-gray;
    stroke: $medium-gray;
    stroke-width: 1px;
}

.highcharts-scrollbar-button {
    fill: $white-dark-smoke;
    stroke: $medium-gray;
    stroke-width: 1px;
}

.highcharts-scrollbar-arrow {
    fill: $dim-gray;
}

.highcharts-scrollbar-rifles {
    stroke: $dim-gray;
    stroke-width: 1px;
}

.highcharts-scrollbar-track {
    fill: $white-smoke;
    stroke: $white-smoke;
    stroke-width: 1px;
}

.highcharts-button {
    fill: $light-gainsboro-gray;
    stroke: $medium-gray;
    cursor: default;
    stroke-width: 1px;
    transition: fill 250ms;
}

.highcharts-button text {
    fill: $dark-charcoal-gray;
}

.highcharts-button-hover {
    transition: fill 0ms;
    fill: $white-dark-smoke;
    stroke: $dark-charcoal-gray;
}

.highcharts-button-pressed {
    font-weight: bold;
    fill: $very-light-blue;
    stroke: $mariner-blue;
}

.highcharts-button-disabled text {
    fill: $medium-gray;
}

.highcharts-range-selector-buttons .highcharts-button {
    stroke-width: 0;
}

.highcharts-range-label rect {
    fill: none;
}

.highcharts-range-label text {
    fill: $dim-gray;
}

.highcharts-range-input rect {
    fill: none;
}

.highcharts-range-input text {
    fill: $dark-charcoal-gray;
}

input.highcharts-range-selector {
    position: absolute;
    border: 0;
    width: 1px;
    /* Chrome needs a pixel to see it */
    height: 1px;
    padding: 0;
    text-align: center;
    left: -9em;
    /* #4798 */
}

.highcharts-crosshair-label text {
    fill: $white;
}

.highcharts-crosshair-label .highcharts-label-box {
    fill: inherit;
}

.highcharts-candlestick-series .highcharts-point {
    stroke: $black;
    stroke-width: 1px;
}

.highcharts-candlestick-series .highcharts-point-up {
    fill: $white;
}

.highcharts-ohlc-series .highcharts-point-hover {
    stroke-width: 3px;
}

.highcharts-flags-series .highcharts-point {
    stroke: $nobel-gray;
    fill: $white;
}

.highcharts-flags-series .highcharts-point-hover {
    stroke: $black;
    fill: $hawakes-blue;
}

.highcharts-flags-series .highcharts-point text {
    fill: $black;
    font-weight: bold;
}

/* Highmaps */
.highcharts-map-series .highcharts-point {
    transition: fill 500ms, fill-opacity 500ms, stroke-width 250ms;
    stroke: $medium-gray;
}

.highcharts-map-series .highcharts-point-hover {
    transition: fill 0ms, fill-opacity 0ms;
    fill-opacity: 0.5;
    stroke-width: 2px;
}

.highcharts-mapline-series .highcharts-point {
    fill: none;
}

.highcharts-heatmap-series .highcharts-point {
    stroke-width: 0;
}

.highcharts-map-navigation {
    font-weight: bold;
    text-align: center;
}

.highcharts-coloraxis {
    stroke-width: 0;
}

.highcharts-coloraxis-marker {
    fill: $nobel-gray;
}

.highcharts-null-point {
    fill: $light-gainsboro-gray;
}

/* 3d charts */
.highcharts-3d-frame {
    fill: transparent;
}

.highcharts-column-series .highcharts-point {
    stroke: inherit;
    /* use point color */
}

/* Exporting module */
.highcharts-contextbutton {
    fill: $white;
    /* needed to capture hover */
    stroke: none;
    stroke-linecap: round;
}

.highcharts-contextbutton:hover {
    fill: $white-dark-smoke;
    stroke: $white-dark-smoke;
}

.highcharts-button-symbol {
    stroke: $dim-gray;
    stroke-width: 3px;
}

.highcharts-menu {
    border: 1px solid $nobel-gray;
    background: $white;
    padding: 5px 0;
    box-shadow: 3px 3px 10px $matte-gray;
}

.highcharts-menu-item {
    padding: 0.5em 1em;
    background: none;
    color: $dark-charcoal-gray;
    cursor: pointer;
    transition: background 250ms, color 250ms;
}

.highcharts-menu-item:hover {
    background: $mariner-blue;
    color: $white;
}

/* Drilldown module */
.highcharts-drilldown-point {
    cursor: pointer;
}

.highcharts-drilldown-data-label text, .highcharts-drilldown-axis-label {
    cursor: pointer;
    fill: $smalt-blue;
    font-weight: bold;
    text-decoration: underline;
}

/* No-data module */
.highcharts-no-data text {
    font-weight: bold;
    fill: $dim-gray;
}

highcharts-chart {
    width: 96%;
    margin:auto;
    display:block;
    overflow: visible !important;
}
