@import "custom-variables";
/*CSS3 loaders http://cssdeck.com/labs/css3-loader-newtons-cradle */

.loading-screen {
    height: 100vh;
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: space-around;
    background: $loading-login-screen-background;

    & .loading-screen-center {
        position: relative;
        width: 300px;
        height: 200px;
        margin: 0 auto;

        & .logo {
            width: 300px;
            height: 122px;
            margin: 0 auto 40px;
            background-image: $loading-screen-logo;
            background-size: contain;
            background-repeat: no-repeat;
        }

        .load-box {
            width: 14px;
            height: 14px;
            margin: 0 13px;
            border-radius: 3px;
            float: left;
            animation-fill-mode: both;
            /*animation: top 1s ease-in-out 0s infinite; /*v1*/
            /*animation: fade 1.4s ease 0s infinite; /*v2 v3*/
            /*animation-name: fade, spin; /*v3*/
            animation: wave 1.2s ease 0s infinite alternate-reverse; /*v4*/
        }

        .load-box.first {
            margin-left: 20px;
        }

        .load-box:nth-child(2) {
            background: $orange;
            /*animation-name: top; /*v1*/
            /*animation-delay: .2s; v2 v3*/
            animation-delay: .075s; /*v4*/
        }

        .load-box:nth-child(3) {
            background: $darkblue;
            /*animation-name: bottom; /*v1*/
            /*animation-delay: .4s; /*v2 v3*/
            animation-delay: .15s; /*v4*/
        }

        .load-box:nth-child(4) {
            background: $yellowgreen;
            /*animation-name: top; /*v1*/
            /*animation-delay: .6s; /*v2 v3*/
            animation-delay: .225s; /*v4*/
        }

        .load-box:nth-child(5) {
            background: $lightblue;
            /*animation-name: bottom; /*v1*/
            /*animation-delay: .8s; /*v2 v3*/
            animation-delay: .3s; /*v4*/
        }

        .load-box:nth-child(6) {
            background: $green;
            /*animation-name: top; /*v1*/
            /*animation-delay: 1.0s; /*v2 v3*/
            animation-delay: .375s; /*v4*/
        }

        .load-box:nth-child(7) {
            background: $yellow;
            /*animation-name: bottom; /*v1*/
            /*animation-delay: 1.2s; /*v2 v3*/
            animation-delay: .45s; /*v4*/
        }

        .load-box:nth-child(8) {
            background: $purple;
            /*animation-name: top; /*v1*/
            /*animation-delay: 1.4s; /*v2 v3*/
            animation-delay: .525s; /*v4*/
        }

        @keyframes top {
            0%,100% {
                transform: translateY(-8px);
            }

            50% {
                transform: translateY(8px);
            }
        }

        @keyframes bottom {
            0%,100% {
                transform: translateY(8px);
            }

            50% {
                transform: translateY(-8px);
            }
        }

        @keyframes fade {
            0%,100% {
                opacity: .1;
            }

            20% {
                opacity: 1;
            }
        }

        @keyframes spin {
            100% {
                transform: rotate(360deg);
            }
        }

        @keyframes wave {
            0%, 40%, 100% {
                transform: translate(0, 0) scale(1, 1);
            }

            10% {
                transform: translate(0, -20px) scale(1.25, 1.25);
            }
        }
    }
}

//Container

.login-container {
    height: 100vh;
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
    background: $loading-login-screen-background;
}

.accent-lines-left {
    position: fixed;
    left: 0;
    top: 15%;
    width: 220px;
    height: 55px;
    background-image: url('.././assets/images/accent-lines-left-lg.png');
}

.accent-lines-right {
    position: fixed;
    right: 0;
    bottom: 15%;
    width: 223px;
    height: 51px;
    background-image: url('.././assets/images/accent-lines-right-lg.png');
}

//Login Box

.login-box {
    border-radius: $border-radius-large;
    padding: 30px;
    background-color: white;
    box-shadow: 0 0 20px $darkblue;
    width: 420px;

    .logo {
        width: 250px;
        height: 102px;
        margin: 10px auto 30px;
        background-image: $login-box-logo;
        background-size: contain;
        background-repeat: no-repeat;
    }

    .btn:not(.btn-link) {
        min-width: calc(50% - 8px);
        margin: 15px 0;

        + .btn {
            margin-left: 16px;
        }
    }

    i {
        margin-right: 6px;
    }

    .error-message {
        margin-bottom: 10px;
    }
}

@media (max-width: $screen-sm-max) {
    .login-box {
        width: 320px;
    }
}

.login-version-info {
    position: fixed;
    bottom: 2px;
    left: 5px;
    font-size: $font-size-small;
    color: white;

    .btn-link {
        color: white !important;
        text-decoration: underline;
        margin-left: 5px;

        &:hover {
            color: $green !important;
        }
    }
}