@import "custom-variables";
@import "../../node_modules/bootstrap/scss/variables";

.content-area {
    margin: 0 25px;
}

//== Dropdowns

.dropdown {
    display: inline-block;
}

.dropdown-menu {
    border-radius: 0;
    border: 1px solid $blue;
    white-space: normal;
    line-height: $line-height-base;
    box-shadow: $box-shadow-3;

    & li > a {
        font-weight: bold;
        padding: 4px 15px;
    }

    & .fa {
        padding-right: 5px;
        margin-right: 5px;
    }

    & .custom-icon {
        color: $blue;
        font-size: 2.25rem;
        width: 18px;
        line-height: 0;
        display: inline-block;
        margin-right: 5px;
        position: relative;
        bottom: 5px;
        left: 2px;
    }

    & .divider {
        background-color: $lightgray-lighten-7percent;
        margin: 5px 0;
        display: block;
        height: 1px;
    }

    .checkbox-radio {
        margin: 5px 10px !important;
    }

    &.right {
        left: auto;
        right: 0;

        & a {
            cursor: pointer;
        }
    }
}

//Animation of header dropdown menus
.header-panel {
    position: relative;
    z-index: 998;

    & .dropdown-menu {
        margin: 5px 0 0;
        //TODO this seems to be causing the fly in
        //transition: opacity 0.2s ease-in, transform 0.2s ease-in;
        transform: translateY(-5px);
        top: -9999px;
        opacity: 0;
    }

    & .show {
        opacity: 1;
        transform: translateY(0px);
        top: 100%;
    }
}

.dropdown-menu:not(.show):not(.opened) {
    pointer-events: none;
}

//== Header Panels

.dropdown-button-styles {
    font-weight: bold;
    padding: 4px 15px;
    color: $blue;
    border: none;
    border-radius: 0;
    background: none;
    margin: 0;
    height: auto;
    line-height: $line-height-base;
    width: 100%;
    text-align: left;
    box-shadow: none;
}

.header-panel {
    background: $blue linear-gradient(to bottom, $blue, $blue 55%, $blue-darken-7percent);
    border-radius: $border-radius-base;
    min-height: 50px;
    padding: 7px 12px;
    margin-bottom: 25px;
    box-shadow: $box-shadow-2;

    .header-left {
        float: left;
        width: calc(100% - 210px);
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .header-right {
        float: right;
        width: 210px;
        text-align: right;
    }

    @media (max-width: $screen-xs-max) {
        .header-left,
        .header-right {
            float: none;
            width: 100%;
            text-align: left;
        }
    }

    &.sticky {
        position: -webkit-sticky;
        position: sticky;
        top: 45px;
        z-index: 888;
    }

    h1, h2, h4 {
        color: white;
        margin: 0;
        display: inline-block;
        line-height: 36px;
    }

    button:last-child,
    div.dropdown:last-child button,
    a.btn:last-child,
    div.dropdown:last-child a.btn {
        margin-right: 0;
    }

    .form-group:not(.checkbox-radio) {
        margin: 0;

        .form-control {
            margin-top: 0;
        }
    }

    .header-right .dropdown-menu {
        //TODO seems to put the dropdowns in the wrong spot
        //left: auto;
        //right: 0;

        & a {
            cursor: pointer;
        }
    }

    .dropdown-menu {
        white-space: nowrap;

        & > li {
            line-height: $line-height-base;

            &:hover,
            &:focus {
                background-color: $whitegray;
            }
        }

    }

    .inline-control {
        margin-right: 10px;
        float: left;
    }

    .inline-control-label {
        .inline-control {
            color: white;
            margin-top: 15px;
        }
    }

    .select-actions {
        background-color: $accent-table-bg;
        margin: 0px -11px;
        padding: 0 100px 0 12px;
        border-bottom: 1px solid $analog-blue;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        position: relative;
        //z-index: -1;
        top: 7px;
        clear: both;

        .selected-row-count {
            margin: 1px 10px 0 6px;

            h3 {
                margin: 0;
                display: inline-block;
                font-weight: bold;
                color: $blue;
            }
        }

        .btn {
            margin: 6px 5px;
            position: relative;
        }

        .vertical-divider {
            display: block;
            float: left;
            background: $lightgray;
            margin: 9px 10px;
            height: 32px;
            width: 1px;
        }

        .text-icon {
            font-size: 2.625rem;
            width: 24px;
            height: 0;
            line-height: 0;
            position: relative;
            bottom: 5px;
        }

        .label-checkbox {
            position: absolute;
            right: 15px;
            top: 7px;

            label {
                font-size: 0.8125rem;
                line-height: 21px;
            }
        }

        //showing tooltip labels
        &.show-labels {

            .fa {
                margin-right: 5px;
            }

            .overlay-icon-blue {
                right: auto;
                left: 15px;
            }

            span {
                font-size: 0.8125rem;
                margin-right: 5px;
            }
        }
    }
}

.header-panel-light {
    background: $whiteblue;
    border-bottom: 1px solid $lightblue;

    h1, h2 {
        color: $blue;
    }
}


//Use h4 for header and btn-icon-sm for buttons
.header-panel-small {
    min-height: 37px;

    h4 {
        line-height: 28px;
    }
}

.modal .header-panel.sticky {
    position: unset;
    top: initial;
    z-index: 888;
}

.header-panel-table,
.header-panel-connect {
    border-radius: $border-radius-base $border-radius-base 0 0;
    margin-bottom: 0;

    &.sticky {
        z-index: 887;
    }

    & .form-control {
        border-radius: $border-radius-small;
    }
}

.header-breadcrumb {
    font-size: $h4-font-size;
    padding: 0 0 17px 15px;
    margin-top: -15px;
    display: none;

    &:before {
        font-family: 'FontAwesome', sans-serif;
        content: "\f148";
        transform: rotate(90deg);
        font-size: $h2-font-size;
        float: left;
        padding: 8px 18px 0 0;
        color: $blue;
    }
}

@media (max-width: 992px) {
    .header-breadcrumb {
        display: block;
    }
}

//== Tabs and Pills

.nav-tabs {
    border-bottom: 1px solid $lightgray;

    & > li > a {
        box-shadow: $box-shadow-6;
    }

    & > li.active > a,
    & > li > a:hover,
    & > li > a:focus {
        box-shadow: none;
    }
}


.nav-tabs li,
.nav-pills li {
    font-size: 0.9375rem;
}

.nav-tabs > li > a,
.nav-pills > li > a {
    color: $gray;

    &:hover,
    &:focus {
        background: $whiteblue;
    }
}

.nav-tabs > li.active > a, .nav-tabs > li.active > a:hover, .nav-tabs > li.active > a:focus {
    color: var(--white-color);
    background-color: var(--primary-color);
    border: 1px solid var(--primary-color);
}

.nav-tabs.green > li.active > a, .nav-tabs.green > li.active > a:hover, .nav-tabs.green > li.active > a:focus {
    color: var(--white-color);
    background-color: $green;
    border: 1px solid $green;
}

.nav-tabs > li > a {
    border: 1px solid $lightgray;
    margin-right: 5px;
    min-width: 100px;
    text-align: center;
    transition: background-color 0.3s, border-color 0.3s, color 0.3s;
    background-color: white;

    &.tab-error,
    &.tab-decline {
        color: $red;
        background-color: $lightred;

        &:before {
            font-family: 'FontAwesome', sans-serif;
            content: "\f071 ";
            padding-right: 6px;
            color: $red;
        }

        &:hover,
        &:focus {
            background-color: $lightred-darken-5percent;
        }
    }

    &.tab-decline:before {
        content: "\f00d";
    }

    &.tab-warning,
    &.tab-counter {
        background-color: $yellow-lighten-27percent;

        &:before {
            font-family: 'FontAwesome', sans-serif;
            content: "\f071 ";
            padding-right: 6px;
            color: $orange;
        }

        &:hover,
        &:focus {
            background-color: $yellow-lighten-17percent;
        }
    }

    &.tab-counter:before {
        content: "\f064";
        display: inline-block;
        transform: rotateY(180deg);
        padding: 0 0 0 8px;
    }

    &.tab-success,
    &.tab-approve {
        background-color: $lightgreen;

        &:before {
            font-family: 'FontAwesome', sans-serif;
            content: "\f00c";
            padding-right: 6px;
            color: $green;
        }

        &:hover,
        &:focus {
            background-color: $lightgreen-darken-10percent;
        }
    }

    &.tab-primary {
        &:after {
            font-family: 'FontAwesome', sans-serif;
            content: "\f00c";
            padding-left: 6px;
            color: $green;
        }
    }

    &.tab-green {
        &:hover,
        &:focus {
            background-color: $green;
        }
    }
}

.nav-tabs > li.active > a {

    &.tab-error {
        color: white;
        background-color: $blue;

        &:before {
            content: "";
            padding: 0;
        }
    }

    &.tab-decline {
        color: white;
        background-color: $red;
        border: 1px solid $red;

        &:before {
            color: white;
        }
    }

    &.tab-warning,
    &.tab-counter {
        color: white;
        background-color: $orange;
        border: 1px solid $orange;

        &:before {
            color: white;
        }
    }

    &.tab-success,
    &.tab-approve {
        background-color: $green;
        border: 1px solid $darkgreen;
        color: white;

        &:before {
            color: white;
        }
    }

    &.tab-green {
        background-color: $green;
        border: 1px solid $darkgreen;
        color: white;
    }
}

.nav-tabs.nav-justified {

    & li {
        float: none;
        display: block;
        width: 100%;
    }

    & > li > a {
        border-bottom: 1px solid $lightgray;
        border-radius: 0;
        padding: 8px 5px;
        white-space: nowrap;
    }
}

@media (min-width: $screen-md-min) {
    .nav-tabs.nav-justified li {
        display: table-cell;
        width: 1%;
    }
}

.nav-tabs.hidden-tabs {
    display: none;
}

/*BDF toggle tabs*/

.bdf-tabs,
.tab-group {

    .nav-tabs {
        margin: 0 0 20px;
        width: initial;

        li {
            //width: 160px;
            display: table-cell !important;

            a {
                padding: 8px 10px;
                margin: 0;
                box-shadow: $box-shadow-1;

                //&:hover {
                //    background: $lightgreen;
                //}
            }

            .active {
                a {
                    background-color: var(--primary-color3);
                    border-color: $darkgreen;
                }
            }
        }

        & li:first-child a {
            border-radius: $border-radius-base 0 0 $border-radius-base;
        }

        & li:last-child a {
            border-radius: 0 $border-radius-base $border-radius-base 0;
        }
    }
}


//== Tooltips

.tooltip-tr,
.tooltip-br,
.tooltip-tl,
.tooltip-bl,
.tooltip-top,
.tooltip-bottom,
.tooltip-left,
.tooltip-right {
    position: relative;
    display: inline-block;
}

.tooltip-tr span,
.tooltip-br span,
.tooltip-tl span,
.tooltip-bl span,
.tooltip-top span,
.tooltip-bottom span,
.tooltip-left span,
.tooltip-right span {
    visibility: hidden;
    opacity: 0;
    position: absolute;
    z-index: 101;
    background: $green !important;
    color: white !important;
    padding: 4px 6px !important;
    border-radius: $border-radius-base;
    border: 1px solid white;
    text-align: center;
    white-space: pre;
    font-size: 13px;
    font-weight: normal;
    font-family: $base-font-family;
    width: auto !important;
    line-height: normal;
}


.tooltip-tr span {
    top: 25%;
    left: 75%;
    transform: translateY(-100%);
}

.tooltip-br span {
    bottom: 25%;
    left: 75%;
    transform: translateY(100%);
}

.tooltip-tl span {
    top: 25%;
    right: 75%;
    transform: translateY(-100%);
}

.tooltip-bl span {
    bottom: 25%;
    left: 25%;
    transform: translate(-100%, 100%);
}

.tooltip-top span {
    top: -30px;
    left: 50%;
    transform: translateX(-50%);
}

.tooltip-bottom span {
    bottom: -30px;
    left: 50%;
    transform: translateX(-50%);
}

.tooltip-left span {
    top: 50%;
    right: 100%;
    transform: translateY(-50%);
}

.tooltip-right span {
    top: 50%;
    left: 100%;
    transform: translateY(-50%);
}

.tooltip-tr:hover span,
.tooltip-br:hover span,
.tooltip-tl:hover span,
.tooltip-bl:hover span,
.tooltip-top:hover span,
.tooltip-bottom:hover span,
.tooltip-left:hover span,
.tooltip-right:hover span {
    visibility: visible;
    opacity: 1;
    transition: opacity 0.2s 0.5s, visibility 0.2s 0.5s;
}

//== Show Text Hover

.hover-text span,
.hover-text span {
    display: none;
    margin-left: 6px;
}

.hover-text:hover span,
.hover-text:focus span {
    display: inline-block;
}

//== Modals

.modal {
    display: block;
    overflow: hidden;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1050;
    -webkit-overflow-scrolling: touch;
    outline: 0;
    height: 0;
    opacity: 0;
    transition: opacity .3s ease-out;

    &.open, &.show {
        opacity: 1;
        height: auto;
    }

    & .modal-center-container {
        display: table;
        height: 100%;
        width: 100%;
        pointer-events: none;

        & .modal-dialog {
            position: relative;
        }

        & .modal-center {
            //display: table-cell;
            //vertical-align: middle;
            //pointer-events: none;
        }

        .modal-content {
            position: relative;
            width: inherit;
            height: inherit;
            max-height: calc(100vh - 20px);
            margin: 0 auto;
            pointer-events: all;
            background-color: $modal-content-bg-color;
            border: 1px solid $modal-content-fallback-border;
            border: 1px solid $modal-content-border;
            border-radius: $border-radius-base;
            box-shadow: $box-shadow-4;
            background-clip: padding-box;
            outline: 0;
            // &.open {
            // }

            .modal-header {
                background-color: $whiteblue;
                padding: 14px 15px 10px;
                border-bottom: 1px solid $lightblue;
                border-radius: $border-radius-base $border-radius-base 0 0;
                clear: both;
                //TODO confirm this didn't cause a problem

                & .modal-title {
                    margin: 0;
                    line-height: $modal-title-line-height;
                }
            }

            & .modal-body {
                position: relative;
                padding: $modal-inner-padding;
                max-height: calc(100vh - 135px); /*height of footer/header and modal height offset*/
                overflow-y: auto;

                .ng2-datetime-picker {
                    position: absolute;
                    box-shadow: $box-shadow-3;
                }
            }

            & .modal-body.modal-fixed {
                .ng2-datetime-picker {
                    position: fixed;
                }
            }

            & .modal-footer {
                padding: 11px 15px;
                text-align: right;
                border-top: 1px solid $lightblue;
                border-radius: 0 0 $border-radius-base $border-radius-base;
                @include clearfix();

                & .bh-modal-buttons {
                    display: inline-block;
                }

                & .btn + .btn {
                    margin-left: 5px;
                    margin-bottom: 0; // account for input[type="submit"] which gets the bottom margin like all other inputs
                }
            }

            & .hidden-modal {
                display: none;
            }
        }
    }

    .modal-content {
        position: relative;
        width: inherit;
        height: inherit;
        max-height: calc(100vh - 20px);
        margin: 0 auto;
        pointer-events: all;
        background-color: $modal-content-bg-color;
        border: 1px solid $modal-content-fallback-border;
        border: 1px solid $modal-content-border;
        border-radius: $border-radius-base;
        box-shadow: $box-shadow-4;
        background-clip: padding-box;
        outline: 0;
        // &.open {
        // }

        .modal-header {
            background-color: $whiteblue;
            padding: 14px 15px 10px;
            border-bottom: 1px solid $lightblue;
            border-radius: $border-radius-base $border-radius-base 0 0;
            clear: both;
            //TODO confirm this didn't cause a problem

            & .modal-title {
                margin: 0;
                line-height: $modal-title-line-height;
            }
        }

        & .modal-body {
            position: relative;
            padding: $modal-inner-padding;
            max-height: calc(100vh - 135px); /*height of footer/header and modal height offset*/
            overflow-y: auto;

            .ng2-datetime-picker {
                position: absolute;
                box-shadow: $box-shadow-3;
            }
        }

        & .modal-body.modal-fixed {
            .ng2-datetime-picker {
                position: fixed;
            }
        }

        & .modal-footer {
            padding: 11px 15px;
            text-align: right;
            border-top: 1px solid $lightblue;
            border-radius: 0 0 $border-radius-base $border-radius-base;
            @include clearfix();

            & .bh-modal-buttons {
                display: inline-block;
            }

            & .btn + .btn {
                margin-left: 5px;
                margin-bottom: 0; // account for input[type="submit"] which gets the bottom margin like all other inputs
            }
        }

        & .hidden-modal {
            display: none;
        }
    }
}

.modal-dialog-scrollable {
    height: unset;
    max-height: calc(100% - 1rem);
}

.large-dropdown-modal {
    &.modal-dialog-scrollable .modal-content {
        overflow: unset;
    }

    &.modal-dialog-scrollable .modal-body {
        overflow-y: unset;
        overflow: unset;
    }

    &.modal .modal-content .modal-body {
        overflow-y: unset !important;
        overflow: unset;
    }

    &.modal-center-container .modal-content .modal-body {
        overflow-y: unset !important;
        overflow: unset;
    }

    .dropdown-menu {
        max-height: 40vh !important;
        overflow-x: hidden !important;
        overflow-y: scroll !important;
    }

}

@media (max-width: 767px) {
    .modal-content {
        margin: 0px 10px !important;
    }
}


@media (min-width: $screen-sm-min) {
    .modal-dialog {
        width: $modal-md;
        margin: 30px auto;
    }

    .modal-content {
        box-shadow: $box-shadow-3;
    }

    .modal-sm {
        width: $modal-sm;
    }
}

@media (min-width: $screen-md-min) {
    .modal-lg, .modal-xl {
        width: $modal-lg;
    }
}

@media (min-width: $screen-lg-min) {
    .modal-xl {
        width: $modal-xl;
    }
}

//Modals fix For mockups only
.modal.fade.in {
    height: 100%;

    & .modal-center-container {
        background-color: $box-shadow-8;
    }
}

// Progress Bar

.progress-bar-label {
    text-align: center;
    font-size: $font-size-base;
    line-height: 30px;
}

.progress-bar-container {
    overflow: hidden;
    height: 30px;
    margin: 5px 0;
    background-color: $whitegray;
    box-shadow: $box-shadow-5;
    border-radius: $border-radius-small;
    border: 1px solid $lightgray;

    .progress-bar {
        float: left;
        min-width: 4%;
        width: 0;
        height: 100%;
        font-size: $font-size-base;
        line-height: 30px;
        color: white;
        text-align: center;
        background-color: $green;
        box-shadow: $box-shadow-7;
        transition: width .6s ease;
    }
}

//Two column Multiselect

.multiselect-col-header {
    border: 1px solid $lightblue;
    border-bottom: none;
    padding: 3px 15px 1px;
    background-color: $whiteblue;

    & .form-section-header {

        & h3 {
            margin: 25px 0 25px;

            & span {
                background-color: $whiteblue;
            }
        }

        & .btn {
            top: -15px;
            right: 15px;
            padding: 6px 10px;

            & .fa-check {
                color: $blue;
            }

            &:not(:disabled):hover, :not(:disabled):focus {

                & .fa-check {
                    color: white;
                }
            }
        }
    }
}

.multiselect-col1,
.multiselect-col2 {
    overflow-y: auto;
    height: 65vh;
    border: 1px solid $lightblue;
    padding-top: 5px;

    & .group-title {
        color: $blue;
        font-weight: bold;
        margin: 15px 0 5px 15px;
    }

    & .option-container {
        padding-left: 15px;
        padding-right: 10px;

        & .group-title {
            margin-left: 0;
        }

        .fa,
        [class*='icon-'] {
            margin-left: 10px;

            &.fa-bar-chart.flip {
                transform: rotate(90deg) scaleX(-.9) scaleY(1.3);
            }
        }


        &.draggable-row {
            cursor: move;
            position: relative;
            padding-left: 35px;
            border: 1px solid transparent;

            .list-order {
                color: $lightblue;
                position: relative;
                left: -9px;
                width: 10px;
                display: inline-block;
            }

            &.drag-moving {
                opacity: .6;
            }

            &.drag-hover {
                border: 2px dashed $blue;
                opacity: .6;

                & * {
                    pointer-events: none;
                }
            }

            &:hover {
                border: 1px solid $lightblue;

                .list-order {
                    color: $blue;
                }
            }

            &:before, &:after {
                font-family: 'FontAwesome', sans-serif;
                content: "\f142";
                font-size: 18px;
                color: $lightblue;
                position: absolute;
                left: 9px;
                top: 7px;
            }

            &:after {
                left: 16px;
            }

            &:hover::before,
            &:hover::after {
                color: $blue;
            }
        }
    }
}

@media (max-width: $screen-sm-max) {
    .multiselect-col-header {

        & .form-section-header .btn {
            position: relative;
            right: 0;
        }
    }
}

//Contact Card Layout

.contact-card {
    display: block;
    margin-bottom: 20px;
    background-color: white;
    border: 1px solid $lightgray;
    border-radius: $border-radius-base;
    box-shadow: $box-shadow-all;

    & .contact-card-header {
        background-color: $whiteblue;
        padding: 12px 14px;
        border-bottom: 1px solid $lightgray;
        position: relative;
        border-radius: $border-radius-base $border-radius-base 0 0;


        & a {
            cursor: pointer;
        }

        & h4 {
            text-align: center;
            margin: 5px 0;
            padding: 0 0;
            border-bottom: none;
            color: $gray;
        }

        & button {
            position: absolute;
            top: 10px;
            right: 10px;
        }
    }

    & .contact-card-content {
        padding-bottom: 0;
    }

    & h2 {
        text-align: center;
        margin: 5px;
        color: $blue;
    }

    & .nav {
        margin-top: 15px;
    }

    & h4 {
        margin: 14px 0 0;
        padding: 0 10px 6px;
        border-bottom: 1px solid $lightgray;
        color: $blue;

        & i {
            float: right;
            padding-right: 2px;
        }
    }

    & .nav > li > a {
        padding: 8px 10px;
        border: 1px solid $blue;

        &:focus,
        &:hover {
            background-color: $blue;
            color: white;
        }
    }

    & .nav > li:first-child a {
        margin-right: 15px;
    }
}

.contact-card .contact-card-header.secondary {
    background-color: $whitegray;
}

.table-contact-card {
    margin: 4px 0;
    border: none !important;
    table-layout: fixed;

    & > tbody > tr {

        &:first-child {
            border-top: none;
        }

        &:last-child {
            border-bottom: none;
        }
    }

    & > tbody > tr > td {
        border: none;
        vertical-align: middle;
        padding: 4px 8px;
        height: 37px;

        &:first-child {
            padding-left: 10px;
            padding-right: 4px;
            min-width: 40px;
            width: 40px;

            &.btn2 {
                min-width: 70px;
                width: 70px;
            }
        }

        &:last-child {
            text-align: right;
            padding-right: 10px;
            width: 50%;

                & div {
                    width: 100%;
                    word-break: keep-all;
                }
            }
        }
    }
    //== My Tasks Table

@media (max-width: $screen-lg-max) {
    .my-tasks .nav-tabs.nav-justified li {
        float: none;
        display: block;
        width: 100%;
    }
}

table.tasks {
    margin-top: -1px;

    & tr {
        cursor: pointer;

        &:not(.outlook-task):not(.no-hover):hover,
        &:not(.outlook-task):not(.no-hover):focus {
            background-color: $blue;
            color: white;

            & td span.outlook-icon {
                background-color: white;
                border: 2px solid white;
                box-sizing: content-box;
                top: 8px;
                right: 8px;
            }

            & td span.fa,
            & td .task-activity,
            & td .task-client {
                color: white;
            }
        }

        &:first-child td {
            border-top: 1px solid $border-table-color;
        }
    }

    & tr.outlook-task,
    & tr.no-hover {
        cursor: default;
    }

    & td {
        position: relative;
        padding: 10px;

        & div {

            &.task-datetime {
                font-size: $large-font-size;
                border-bottom: 1px solid $lightgray;
                margin-bottom: 4px;
                padding-bottom: 2px;
            }

            @media (max-width: $screen-lg-max) {
                &.task-datetime {
                    font-size: 15px;
                }
            }

            &.task-activity {
                color: $blue;
                padding-left: 40px;
                font-weight: bold;
            }

            &.task-subject {
                padding-left: 40px;
            }

            &.task-client {
                padding-left: 40px;
                font-style: italic;
                color: $blue;
            }
        }

        & span.fa {
            color: $blue;
            position: absolute;
            top: 45px;
            left: 12px;
        }

        & span.outlook-icon {
            position: absolute;
            top: 10px;
            right: 10px;
            background-image: url('../assets/images/outlook-icon.png');
            background-size: contain;
            width: 16px;
            height: 16px;
        }
    }
}

//== File Upload Drag and Drop

.file-dropbox {
    display: block;
    font-size: $large-font-size;
    text-align: center;
    padding: 30px 0;
    color: $gray;
    border: 2px dashed $lightgray;
    border-radius: $border-radius-large;
    cursor: default;
    margin-bottom: 10px;

    & i {
        color: $blue;
    }
}


label.upload-label {

    & input[type="file"] {
        position: fixed;
        top: -1000px;
    }

    &.fa-upload {
        padding-top: 2px;
        width: 38px;
    }
}

.btn.upload-label {
    width: auto;
}

.filetype-icon {
    color: $blue;
    margin-top: 18px;
}

.file-dropbox-error {
    color: $red;
    background-color: $lightred;
    border: 1px solid $red;
    width: auto;
    padding: 6px 10px;
    font-size: $font-size-base;
    border-radius: $border-radius-base;
    z-index: 100;
    font-weight: bold;
    white-space: pre;
    margin-bottom: 10px;
}

.file-dropbox-error span i {
    font-family: 'FontAwesome', sans-serif;
    margin-right: 8px;
    font-weight: normal;
}

//== Report Manager Screen

.report-box-container {
    & a, a:hover, a:focus {
        color: $gray;
    }
}

.report-box {
    text-align: center;
    cursor: pointer;
    padding-bottom: 15px;

    &:before {
        font-family: 'FontAwesome', sans-serif;
        content: "\f0f6";
        font-size: 42px;
        color: $blue;
        display: block;
    }

    &:focus:before,
    &:hover:before {
        content: "\f15c";
        color: $green;
    }
}

//== Correspondence Screen

.corresponence-list {
    font-size: $large-font-size;
    padding: 10px 10px 0 20px;
    color: $blue;
    border: 1px solid $lightblue;
    border-radius: $border-radius-base;

    & ul {
        padding: 0;

        & ul {
            padding-left: 32px;
        }
    }

    & li {
        list-style: none;
        padding: 10px 0;

        & a {
            cursor: pointer;
        }

        & .fa {
            margin-right: 5px;
            position: relative;
            right: 2px;
        }
    }
}

//Icon Selection Grid Modal
.icon-grid div {
    margin: 12px 0;

    & .fa {
        margin: 0 10px;
        color: $gray !important;
    }
}

//inline
.icon-view {
    padding-top: 20px;
    color: $gray;
}

//== Drag and Drop

.draggable-field {

    &[class*='col-'],
    div[class*='col-'] { //BDF
        cursor: move;
        padding: 15px;
        outline: 2px solid transparent;
        outline-offset: -8px;


        .form-group {
            margin-bottom: 0;
        }

        &:hover {
            outline: 2px dashed $blue;
            position: relative;
            right: 2px;
            bottom: 2px;
        }

        img {
            max-width: 100%;
            max-height: 100%;
        }
    }

    &.active[class*='col-'],
    &.active div[class*='col-'] {
        outline: 2px solid $green;
        background-color: $lightgreen;
    }

    &.drag-moving[class*='col-'],
    &.drag-moving div[class*='col-'] {
        opacity: .75;
    }

    &.drag-hover[class*='col-'],
    &.drag-hover div[class*='col-'] {
        transform: scale(0.88);
        transition: .3s ease-out;
        opacity: .6;
    }
}


.bdf-add-btn {
    border: 1px solid $lightgray;
    padding: 25px;
    width: 100%;
    height: 92px;
    text-align: center;

    & .fa {
        color: $lightblue;
    }

    &:hover {
        border: 1px solid $blue;
        outline: 1px solid $blue;

        & .fa {
            color: $blue;
        }
    }
}

//== Message Builder

.message-builder-container {
    border: 1px solid $lightblue;
    background-color: $whiteblue-lighten-2percent;
    margin: 20px 0;
    padding: 35px 0 5px;
    border-radius: $border-radius-base;
}

.message-builder-actions {
    position: absolute;
    top: -2px;
    right: 23px;

    & .btn {
        margin-right: 8px;

        & .fa {
            margin-right: 8px;
        }
    }
}

.message-builder-block {
    padding: 15px;
    background-color: white;
    border: 1px solid $lightgray;
    text-align: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    &[class*="mbb-"]:before {
        font-family: 'FontAwesome', sans-serif;
        content: "";
        font-size: $font-size-base;
        color: $blue;
        position: absolute;
        bottom: 15px;
        right: 18px;
    }

    &.mbb-text:before,
    &.mbb-text2:before {
        content: "\f031";
    }

    &.mbb-field:before {
        content: "\f00e";
    }

    &.mbb-link:before {
        content: "\f0c1";
    }

    &.mbb-date:before {
        content: "\f073";
    }
}

//== Query Builder

.query-filter-container {

    & label.form-group,
    & label .form-control,
    & .form-group .form-control {
        margin: 0;
    }

    & .definition-output {
        background-color: $lightgreen;
        margin: 15px 0;
        padding: 10px 15px;
        border: 1px solid $green;
        border-radius: $border-radius-base;
        position: relative;

        & strong {
            color: $blue;
        }
    }

    & .filter-group {
        background-color: $whiteblue-lighten-2percent;
        margin: 35px 0;
        padding: 25px 15px 5px;
        border: 1px solid $lightblue;
        border-radius: $border-radius-base;
        position: relative;

        & .and-or-dropdown {
            position: absolute;
            top: -15px;
            left: 35px;

            & .form-control-sm {
                width: 67px;

                & .form-control {
                    border-color: $blue;
                }
            }
        }

        & .button-options {
            position: absolute;
            top: -14px;
            left: 125px;

            &.first {
                left: 30px;
            }

            & .btn {
                display: inline;
                margin: 0 5px;
                padding: 3px;

                & .large {
                    font-weight: bold;
                    letter-spacing: 1.5px;
                    line-height: 16px;
                    padding: 0 1px 0 3px;
                }
            }
        }


        & .delete-col {

            & label {
                width: calc(100% - 60px);
            }

            & button {
                margin: 0 0 5px 10px;
            }
        }

        &.tier2 {
            margin: 35px 0 15px 0;
            padding-right: 0;
            background-color: $whiteblue;
        }
    }
}

.query-filter-table {

    & .btn2-col {
        width: 85px !important;
    }

    & .tree-cell i,
    & th i {
        padding: 0 3px;
    }

    & .andor {
        margin-right: 6px;
        color: $blue;
        font-weight: bold;
        text-transform: uppercase;
    }

    & .definition-output {
        background-color: $lightgreen;
        margin: 0;
        padding: 10px 15px;
        border-left: 1px solid $lightblue;
        border-right: 1px solid $lightblue;
        position: relative;

        & .andor {
            margin: 0;
        }
    }
}

.andor-radios {
    text-align: center;
    width: 190px;
    background-color: $whiteblue;
    outline: 1px solid $lightblue;
    margin-bottom: 15px;
    padding-left: 12px;

    & .form-group {
        text-transform: uppercase;
        margin-bottom: 0;
    }

    & span.radio-label {
        margin-bottom: 0;
    }
}

/*amounts fees expenses*/

.afe-controls {

    .no-label {
        padding-top: 4px;

        .form-group {
            margin-bottom: 3px;
        }
    }
}

/*Profitability View*/

.profitability-btn {
    position: absolute;
    right: 13px;
    bottom: -31px;
    width: 166px;
    font-weight: bold;
    font-size: 12px;
    padding: 7px 10px 6px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;

    &.btn-white2, .btn-sm {
        padding: 7px 10px 6px;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
    }

    &:disabled, &.disabled {
        background-color: var(--disabled-color) !important;
        color: var(--primary-color) !important;
        border-color: var(--disabled-color) !important;
    }
}

.profitability-container {
    background-color: $whiteblue-lighten-2percent;
    padding: 30px 15px 0 15px;
    margin-bottom: 15px;
    position: relative;
    top: -42px;
    border: 1px solid $lightblue;
    border-radius: 0 0 $border-radius-base $border-radius-base;

    .form-box-container {
        background-color: white;
    }

    .form-section-header h2 span,
    .form-section-header h3 span {
        background-color: $whiteblue-lighten-2percent;
    }

    .calculate-button {
        z-index: 1;
    }
}

.profitability-tabs {
    margin-top: -57px;

    .nav-tabs {
        border-bottom: 0;
    }

    .tab-group .nav-tabs.nav-justified {
        margin: 0;
    }
}

/*Negotiation Matrix*/
.matrix-table {
    th, td {
        min-width: unset;

        button {
            width: 100%;
        }
    }

    tr td:first-of-type {
        border-right: 2px solid $border-table-color;
        background-color: $whiteblue;
        width: 100px;
    }
}

/*Score Trend Reports*/

.score-trend-layout {

    .table-hscroll {
        margin-bottom: 20px;
    }

    thead {
        text-transform: uppercase;
    }

    table > thead > tr > th,
    table > tbody > tr > th,
    table > tfoot > tr > th,
    table > thead > tr > td,
    table > tbody > tr > td,
    table > tfoot > tr > td {
        padding: 6px 10px;
    }

    tr td:first-child,
    th {
        font-weight: bold;
        text-align: left;
    }
}

/*Business Rules & Decision Tables*/

.set-radios {
    .andor-radios {
        width: 90px;
    }
}


.condition-container,
.action-container {
    margin: 20px 0;
    padding: 10px 0 0;
    border: 1px solid $lightgray;
    position: relative;

    .action-label {
        position: absolute;
        top: -22px;
        left: 15px;
        background-color: white;
        border: 1px solid $green;
        border-left-width: 4px;
        padding: 8px 12px;
        box-shadow: $box-shadow-1;
    }

    .ignore-container {
        .header-checkbox-container {
            right: 20px;
            top: -22px;
            border: 1px solid $green;
            border-radius: 0;
            padding: 0 12px;
        }
    }

    .form-group {
        margin-bottom: 10px;
    }

    bh-r-checkbox:last-of-type {
        padding-bottom: 10px;
    }

    bh-r-date.date-options,
    bh-r-dropdown.date-options,
    bh-r-search-and-select.date-options,
    .bh-r-date.date-options,
    .bh-r-dropdown.date-options,
    .bh-r-search-and-select.date-options {
        margin-top: -10px;
    }

    & .btn-icon-sm {
        margin-top: 5px;
        float: right;
    }

    .wj-flexgrid .btn-icon-sm {
        margin-top: 0;
        float: none;
    }
}

.condition-container {
    background: $whiteblue;
    border-left: 6px solid $blue;
}

.action-container {
    // background: $lightgreen;
    border-left: 6px solid $green;
    margin: 35px 0;
    padding: 30px 0 0;
}

/*News Feed Post*/
.news-message {
    border: 1px solid $lightblue;
    border-left-width: 6px;
    background-color: $whiteblue-lighten-2percent;
    padding: 7px 75px 7px 12px;
    display: inline-block;
    font-size: 13px;
    width: 100%;
    margin: 7px 0;
    position: relative;

    &.fi {
        border-color: $lightblue;
        background-color: $whiteblue;
    }

    &.bakerhill {
        border-color: $green;
        background-color: $lightgreen;
    }

    &.comment {
        border-color: $lightgray;
        background-color: $whitegray-lighten-5percent;
    }

    &.unread {
        font-weight: bold;
    }

    & span {
        display: block;
        padding-bottom: 5px;

        & + span {
            border-top: 1px solid $lightgray-lighten-7percent;
            padding: 3px 0 0 0;

            & span {
                border: none;
                padding: 0 0 0 15px;
                display: inline;
            }
        }
    }

    & a {
        color: var(--primary-color);
        text-decoration: underline;
    }

    & a:not([href]):not([class]), a:not([href]):not([class]):hover {
        color: var(--primary-color);
        text-decoration: underline;
    }

    &.btn {
        top: 15px;
    }
}

.business-hours {
    h4 {
        text-align: right;
        margin-top: 12px;
    }

    .row:first-of-type h4 {
        text-align: center;
    }
}

//== Control Help Page

.header-help {
    padding: 15px 0;

    & h1 {
        color: $darkblue;
        display: inline-block;
        margin: 0;
    }

    & img {
        width: 210px;
        padding: 0 10px 0 15px;
        vertical-align: text-bottom;
        position: relative;
        top: 2px;
    }

    & hr {
        margin: 15px 0;
    }
}

.activity-console {
    border: 1px solid $lightgray;
    background-color: $whitegray;
    padding: 10px;
    margin: 15px 0 50px;
}

/*Module Homepage Slider*/

.chart-slider {
    position: relative;
    text-align: center;

    label {
        margin: 0;
        cursor: pointer;
    }

    input[type="radio"] {
        display: none;
    }

    #slide1:checked ~ .slides .chart-container {
        margin-left: 0;
    }

    #slide2:checked ~ .slides .chart-container {
        margin-left: -50%;
    }

    #slide3:checked ~ .slides .chart-container {
        margin-left: -100%;
    }

    #slide4:checked ~ .slides .chart-container {
        margin-left: -150%;
    }

    #slide5:checked ~ .slides .chart-container {
        margin-left: -200%;
    }

    #slide6:checked ~ .slides .chart-container {
        margin-left: -250%;
    }

    .slides {
        overflow: hidden;

        .chart-container {
            width: 300%;
            transition: all 800ms cubic-bezier(0.770, 0.000, 0.175, 1.000);
            transition-timing-function: cubic-bezier(0.770, 0.000, 0.175, 1.000);

            .chart {
                width: calc(100% / 6);
                float: left;

                img {
                    width: calc(100% - 20px);
                    height: auto;
                    margin: 0 10px;
                }

                chart {
                    width: calc(100% - 40px);
                    height: auto;
                    margin: 5px 20px;
                }
            }
        }
    }

    .arrows {
        width: 100%;
        position: absolute;
        top: calc(50% - 55px);

        label {
            display: none;
            width: 36px;
            height: 66px;
            border-radius: 6px;
            transition: opacity 0.2s ease-out;
            background-color: white;
            border: 1px solid $lightblue;
            color: $blue;
            font-size: 40px;
            text-align: center;
            box-shadow: $box-shadow-2;


            &:hover {
                background: $blue;
                color: white;
            }
        }
    }

    .slide-number {
        display: inline-block;
        text-align: center;
        padding: 5px;
        position: relative;
        bottom: 25px;
        background-color: white;
        border: 1px solid $lightblue;
        border-radius: 4px;
        box-shadow: $box-shadow-2;


        label {
            border-radius: 3px;
            display: inline-block;
            width: 20px;
            height: 20px;
            background: $blue;
            color: white;

            &:hover {
                background: $green;
            }
        }
    }

    #slide1:checked ~ .arrows label:nth-child(2),
    #slide2:checked ~ .arrows label:nth-child(3),
    #slide3:checked ~ .arrows label:nth-child(4),
    #slide4:checked ~ .arrows label:nth-child(5),
    #slide5:checked ~ .arrows label:nth-child(6) {
        float: right;
        display: block;

        &:before {
            font-family: 'FontAwesome', sans-serif;
            content: "\f105";
            vertical-align: middle;
            font-size: 3.4rem;
        }
    }

    #slide2:checked ~ .arrows label:nth-child(1),
    #slide3:checked ~ .arrows label:nth-child(2),
    #slide4:checked ~ .arrows label:nth-child(3),
    #slide5:checked ~ .arrows label:nth-child(4),
    #slide6:checked ~ .arrows label:nth-child(5) {
        float: left;
        display: block;

        &:before {
            font-family: 'FontAwesome', sans-serif;
            content: "\f104";
            vertical-align: middle;
            font-size: 3.4rem;
        }
    }

    #slide1:checked ~ .slide-number label:nth-child(1),
    #slide2:checked ~ .slide-number label:nth-child(2),
    #slide3:checked ~ .slide-number label:nth-child(3),
    #slide4:checked ~ .slide-number label:nth-child(4),
    #slide5:checked ~ .slide-number label:nth-child(5),
    #slide6:checked ~ .slide-number label:nth-child(6) {
        background: $green;
        width: 22px;
        height: 22px;
        padding: 1px;
    }

    .arrows,
    .arrows label,
    .slides,
    .slide-number,
    .slide-number label {
        transition: all 0.3s ease-out;
    }

    @media (max-width: $screen-md-max) {
        #slide1:checked ~ .slides .chart-container {
            margin-left: 0;
        }

        #slide2:checked ~ .slides .chart-container {
            margin-left: -100%;
        }

        #slide3:checked ~ .slides .chart-container {
            margin-left: -200%;
        }

        #slide4:checked ~ .slides .chart-container {
            margin-left: -300%;
        }

        #slide5:checked ~ .slides .chart-container {
            margin-left: -400%;
        }

        #slide6:checked ~ .slides .chart-container {
            margin-left: -500%;
        }

        .slides .chart-container {
            width: 600%;
        }
    }

    @media (min-width: $screen-lg-min) {
        #slide1:checked ~ .slide-number label:nth-child(2),
        #slide2:checked ~ .slide-number label:nth-child(3),
        #slide3:checked ~ .slide-number label:nth-child(4),
        #slide4:checked ~ .slide-number label:nth-child(5),
        #slide5:checked ~ .slide-number label:nth-child(6) {
            background: $green;
            width: 22px;
            height: 22px;
            padding: 1px;
        }
    }
}

.top-chart {
    margin-bottom: 20px;

    img {
        border-radius: 4px;
        box-shadow: $box-shadow-all;
        border: 1px solid $blue;
    }
}

/*Dashboards*/

.dashboard-container {

    img {
        width: 100%;
        height: auto;

        &.auto-width {
            height: 100%;
            width: auto;
        }
    }

    .chart-placeholder-name {
        position: absolute;
        margin: 50px 30px 0;
        left: 0;
        right: 0;
        text-align: center;

        + img {
            border-radius: 4px;
            box-shadow: $box-shadow-all;
            border: 1px solid $lightblue;
        }
    }

    .chart-view-placeholder-name,
    .query-table-placeholder-title {
        position: absolute;
        left: 28px;
        top: 23px;
        color: white;
        margin: 0;
        line-height: 36px;
    }
}

.count-widget {
    color: $white;
    border-radius: $border-radius-base;
    padding: 20px;
    font-size: $h3-font-size;
    text-align: center;
    box-shadow: $box-shadow-2;
    cursor: pointer;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    .count-number {
        font-size: 40px;
    }

    &:hover, &:focus {
        opacity: 0.7;
    }
}

.bh-tabs-vertical {
    li {
        display: block !important;
        width: 100% !important;
        margin: 2px 0;

        &.active {
            a {
                background: var(--primary-light1-color-darken-15percent);
            }
        }
    }

    .add-tab {
        width: 100% !important;
    }
}

.bh-vertical-tab-content {
    width: 75%;
}

.notes-icon-margin-left {
    margin-left: 5px;
}

.fade.in {
    opacity: 1;
}

bh-edit-payment-stream .form-section-header .btn {
    top: -15px;
}

bh-tabs .nav-fill li a {
    margin-right: 0;
    border-radius: 0;
}

.faa-ring.animated, .faa-ring.animated-hover:hover, .faa-parent.animated-hover:hover > .faa-ring {
    -webkit-animation: ring 2s ease infinite;
    animation: ring 2s ease infinite;
    //transform-origin-x: 50%;
    //transform-origin-y: 0px;
    //transform-origin-z: initial;
}

@keyframes ring {
    0% {
        transform: rotate(-15deg);
    }

    2% {
        transform: rotate(15deg);
    }

    4% {
        transform: rotate(-18deg);
    }

    6% {
        transform: rotate(18deg);
    }

    8% {
        transform: rotate(-22deg);
    }

    10% {
        transform: rotate(22deg);
    }

    12% {
        transform: rotate(-18deg);
    }

    14% {
        transform: rotate(18deg);
    }

    16% {
        transform: rotate(-12deg);
    }

    18% {
        transform: rotate(12deg);
    }

    20%, 100% {
        transform: rotate(0deg);
    }
}

.btn-badge-violation {
    padding: 0 1px;
    border-radius: 4px;
    min-width: 20px;
    text-align: center;
    display: inline-block;
    font-weight: bold;
    margin-left: 5px;
    background-color: var(--violation-bg);
    color: var(--violation-text);
}

.btn-badge-warning {
    padding: 0 1px;
    border-radius: 4px;
    min-width: 20px;
    text-align: center;
    display: inline-block;
    font-weight: bold;
    margin-left: 5px;
    background-color: var(--warning-bg);
    color: var(--warning-text);
}

.btn-badge-information {
    padding: 0 1px;
    border-radius: 4px;
    min-width: 20px;
    text-align: center;
    display: inline-block;
    font-weight: bold;
    margin-left: 5px;
    background-color: var(--primary-white-color-lighten-2percent);
    color: var(--primary-color);
}


@media screen and (max-width: 520px) {
    .header-panel-table .select-actions {
        display: none !important;
    }
}


.sheet-selector-input {
    padding-right: 58px !important;
}

.sheet-selector-icon-right {
    font-size: 1rem;
    color: var(--primary-color);
    z-index: 8;
    position: absolute;
    right: 70px;
    top: 15px;
    pointer-events: none;
}

.sheet-selector-info {
    font-size: medium;
    font-weight: bold;
    color: forestgreen !important;
}

button.sheet-selector-clearbutton {
    border: none;
    margin: 0;
    color: var(--grayscale-color) !important;
    text-align: inherit;
    box-shadow: none;
    position: absolute;
    padding: 10px;
    right: 40px;
    z-index: 5;
    background-color: transparent;

    &:after {
        font-family: 'FontAwesome', sans-serif;
        content: "\f00d";
        font-size: $large-font-size;
        color: $blue;
    }

    &:hover::after, &:focus::after {
        color: $green;
    }
}

.entity-picker-card {
    padding: 10px;
    border: 1px solid var(--primary-color-lighten-10percent);
    border-radius: 4px;
    background: var(--primary-white-color-lighten-4percent);

    .entity-picker-card-details {
        padding: 0 5px 8px 2px;
        position: relative;

        h5, p, button {
            margin-top: 5px;
        }

        h5 {
            color: $blue;
            font-size: 1rem;
        }
    }
}

.booking-loading-wrapper {
    position: relative;
    min-height: 200px;
    color: #fff;
}

.booking-loading {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: 0;
    opacity: 0.5;
    background-color: var(--primary-white-color);
    border-radius: 4px;
    min-height: 200px;
    z-index: 100;
}

.booking-loading-content {
    position: fixed;
    top: 25%;
    width: 50%;
    transform: translateX(35%);
    background: var(--primary-color);
    padding: 1em;
    border-radius: 4px;
    z-index: 101;
}

// Carts
.card.tax-document, .card.gray-box {
    background-color: var(--grayscale-white-color-lighten-2percent);
    border: 1px solid var(--light-danube-blue);
    border-radius: 4px;
    margin-bottom: 15px;
    padding: 10px;
    box-shadow: var(--dark-whisper-gray) 2px 2px 6px;

    h4 {
        color: var(--primary-color);
        margin-bottom: 0;
    }

    h6 {
        margin-top: 0;
    }

    .text-end {
        float: right;

        .btn {
            margin-right: 2px;
        }
    }

    .card-body {
        td {
            overflow-wrap: anywhere;
        }
    }
}

.card.gray-box.border-orange {
    border: 2px solid var(--secondary-color2);
}

.tooltip-container {
    position: relative;
    display: inline-block;
    cursor: pointer;
}

.tooltip-content {
    visibility: hidden;
    position: absolute;
    background-color: var(--primary-color);
    color: #fff; 
    text-align: left;
    padding: 8px 10px;
    border-radius: 4px;
    white-space: normal;
    word-break: break-word;
    width: max-content;
    max-width: 300px;
    z-index: 1000;
    top: 100%;
    left: 0;
}

.tooltip-container:hover .tooltip-content {
    visibility: visible;
}

.tooltip-content.warning {
    background-color: var(--secondary-color2);
}

.ml-5 {
    margin-left: 5px
}

.tooltip-r-max-50wv span {
    visibility: hidden;
    opacity: 0;
    position: absolute;
    z-index: 101;
    background: $green !important;
    color: white !important;
    padding: 4px 6px !important;
    border-radius: $border-radius-base;
    border: 1px solid white;
    text-align: center;
    white-space: normal;
    font-size: 13px;
    font-weight: normal;
    font-family: $base-font-family;
    width: max-content;
    max-width: 50vw !important;
    line-height: normal;
}

.tooltip-r-max-50wv span {
    top: 50%;
    left: 100%;
    transform: translateY(-50%);
}

.tooltip-r-max-50wv:hover span {
    visibility: visible;
    opacity: 1;
    transition: opacity 0.2s 0.5s, visibility 0.2s 0.5s;
}
