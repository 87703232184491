@import "../../node_modules/bootstrap/scss/variables";

/* Old Colors

$color0: #ededed; // white gray
$color1: rgb(190,190,190); // #bebebe - light gray
$color2: rgb(80,80,80); // #505050 - gray
$color3: rgb(207,214,224); // #cfd6e0 - white blue
$color4: rgb(159,172,193); // #9facc1 - light blue
$color5: rgb(88,111,146); // #586f92 - mid blue
$color6: rgb(15,48,99); // #1B3D6D - dark blue
$color7: rgb(57,213,31); // #39d51f - green
$color8: rgb(52,197,26); // #34C51A - dark green
*/

/*Theming*/
:root {
  //noinspection CssUnknownTarget
  --loading-login-screen-background:
          linear-gradient(to right, rgba(15, 48, 99, 0) 0%, rgba(75, 107, 155, .4) 25%, rgba(168, 187, 215, .4) 42%, rgba(168, 187, 215, .4) 58%, rgba(75, 107, 155, .4) 75%, rgba(15, 48, 99, 0) 100%),
          url('.././assets/images/pattern-block-lines.png') repeat;
  //noinspection CssUnknownTarget
  --loading-screen-logo: url('.././assets/images/bakerhill-nextgen-logo-white.png');
  //noinspection CssUnknownTarget
  --login-box-logo: url('.././assets/images/bakerhill-nextgen-logo.png');
  --primary-dark-color: #1B3D6D;
  --primary-color: #456aa1;
  --primary-color-darken-7percent: #{darken(#456aa1, 7%)};
  --primary-color-darken-11percent: #{darken(#456aa1, 11%)};
  --primary-color-lighten-10percent: #{lighten(#456aa1, 10%)};
  --primary-color-lighten-20percent: #{lighten(#456aa1, 20%)};
  --primary-color-lighten-40percent: #{lighten(#456aa1, 40%)};
  --primary-light1-color: #a6bad9;
  --primary-light1-color-darken-5percent:#{darken(#a6bad9, 5%)};
  --primary-light1-color-darken-10percent: #{darken(#a6bad9, 10%)};
  --primary-light1-color-darken-15percent: #{darken(#a6bad9, 15%)};
  --primary-light1-color-lighten-6percent: #{lighten(#a6bad9, 6%)};
  --primary-white-color: #e6ecf2;
  --primary-white-color-darken-2percent: #{darken(#e6ecf2, 2%)};
  --primary-white-color-darken-6percent: #{darken(#e6ecf2, 6%)};
  --primary-white-color-lighten-2percent: #{lighten(#e6ecf2, 2%)};
  --primary-white-color-lighten-4percent: #{lighten(#e6ecf2, 4%)};
  --primary-color2: #cddc29;
  --primary-color3: #81BD41;
  --primary-color3-lighten-40percent: #{lighten(#81BD41, 40%)};
  --primary-color3-darken-5percent: #{darken(#81BD41, 5%)};
  --primary-dark2-color: #689834;
  --primary-dark2-color-darken-10percent: #{darken(#689834, 10%)};
  --primary-light2-color: #e6f2d9;
  --primary-light2-color-darken-2percent: #{darken(#e6f2d9, 2%)};
  --primary-light2-color-darken-6percent: #{darken(#e6f2d9, 6%)};
  --primary-light2-color-darken-10percent: #{darken(#e6f2d9, 10%)};
  --grayscale-color: #58595B;
  --grayscale-color-lighten-25percent: #{lighten(#58595B, 25%)};
  --grayscale-light-color: #bdbdc2;
  --grayscale-light-color-darken-8percent: #{darken(#bdbdc2, 8%)};
  --grayscale-light-color-lighten-7percent: #{lighten(#bdbdc2, 7%)};
  --grayscale-white-color: #ebebed;
  --grayscale-white-color-darken-5percent: #{darken(#ebebed, 5%)};
  --grayscale-white-color-darken-8percent: #{darken(#ebebed, 8%)};
  --grayscale-white-color-lighten-2percent: #{lighten(#ebebed, 2%)};
  --grayscale-white-color-lighten-5percent: #{lighten(#ebebed, 5%)};
  --white-color: #ffffff;
  --black-color: #000000;
  --secondary-dark-color: #007299;
  --secondary-color: #77256c;
  --secondary-color2: #f68d29;
  --secondary-color3: #ffd240;
  --secondary-color3-darken-3percent: #{darken(#ffd240, 3%)};
  --secondary-color3-lighten-17percent: #{lighten(#ffd240, 17%)};
  --secondary-color3-lighten-27percent: #{lighten(#ffd240, 27%)};
  --secondary-bright-color: #ece815;
  --error-color: #cc0000;
  --error-color-darken-10percent: #{darken(#cc0000, 10%)};
  --error-color-lighten-50percent: #{lighten(#cc0000, 50%)};
  --error-color-lighten-55percent: #{lighten(#cc0000, 55%)};
  --error-light-color: #ffe6e6;
  --error-light-color-darken-5percent: #{darken(#ffe6e6, 5%)};
  --disabled-color: #abbcd5;
  --disabled-color2: #9badca;
  --disabled-color3: #a7d17a;
  --default-sprite-position: top left;
  --active-hover-sprite-position: top right;
  /*remaing colors*/
  --snow-gray: #fcfcfc;
  --light-gainsboro-gray: #f7f7f7;
  --white-smoke: #f4f4f4;
  --very-light-gray: #f0f0f0;
  --light-pink: #febbbb;
  --light-yellow: #ffffe5;
  --orange-peel: #ff9900;
  --electric-yellow: #ffff00;
  --yellow-green: #effadb;
  --whisper-gray: #ededed;
  --dark-whisper-gray: #eeeeee;
  --white-dark-smoke: #e6e6e6;
  --gainsboro-gray: #e0e0e0;
  --medium-gray: #cccccc;
  --silver: #c6c6c6;
  --silver-very-light: #b9b9b9;
  --silver-light: #bbbbbb;
  --blue-smoke: #eef1f7;
  --ivory-red: #f8f8f2;
  --spun-pearl: #afafb6;
  --very-light-blue: #e6ebf5;
  --dark-gainsboro-gray: #d9d9d9;
  --bright-gainsboro-gray: #dddddd;
  --hawakes-blue: #ccd6eb;
  --blue-violet: #9933ff;
  --nobel-gray: #999999;
  --light-danube-blue: #89a4cd;
  --matte-gray: #888888;
  --light-matte-gray: #787878;
  --dark-gray: #777777;
  --raven-gray: #707275;
  --danube-blue: #6685c2;
  --dim-gray: #666666;
  --davy-grey: #555555;
  --analog-blue: #435f8b;
  --charcoal-gray: #444444;
  --mariner-blue: #335cad;
  --dark-charcoal-gray: #333333;
  --green-waterloo: #23241f;
  --nero-dark: #222222;
  --smalt-blue: #003399;
  --deep-green: #008a00;
  --navy-blue: #0066cc;
  --bondi-blue: #0085c7;
  --violation-text: #cc0000;
  --violation-bg: #ffe6e6;
  --warning-text: #f68d29;
  --warning-bg: #{lighten(#ffd240, 27%)};
  --highcharts-color-2: #cddc29;
  --highcharts-color-3: #007299;
  --highcharts-color-6: #689834;
  --highcharts-color-7: #a6bad9;
}

[data-theme="fiserv"] {
  --loading-login-screen-background: #666666;
  //noinspection CssUnknownTarget
  --loading-screen-logo: url('.././assets/images/Fiserv-logo-1200x603.png');
  //noinspection CssUnknownTarget
  --login-box-logo: url('.././assets/images/Fiserv-logo-1200x603.png');
  --primary-dark-color: #333333;
  --primary-color: #666666;
  --primary-color-darken-7percent: #{darken(#666666, 7%)};
  --primary-color-darken-11percent: #{darken(#666666, 11%)};
  --primary-color-lighten-10percent: #{lighten(#666666, 10%)};
  --primary-color-lighten-20percent: #{lighten(#666666, 20%)};
  --primary-color-lighten-40percent: #{lighten(#666666, 40%)};
  --primary-light1-color: #bfbfbf;
  --primary-light1-color-darken-5percent: #{darken(#bfbfbf, 5%)};
  --primary-light1-color-darken-10percent: #{darken(#bfbfbf, 10%)};
  --primary-light1-color-darken-15percent: #{darken(#bfbfbf, 15%)};
  --primary-light1-color-lighten-6percent: #{lighten(#bfbfbf, 6%)};
  --primary-white-color: #efefef;
  --primary-white-color-darken-2percent: #{darken(#efefef, 2%)};
  --primary-white-color-darken-6percent: #{darken(#efefef, 6%)};
  --primary-white-color-lighten-2percent: #{lighten(#efefef, 2%)};
  --primary-white-color-lighten-4percent: #{lighten(#efefef, 4%)};
  --primary-color2: #4695d1;
  --primary-color3: #ff6600;
  --primary-color3-lighten-40percent: #{lighten(#ff6600, 40%)};
  --primary-color3-darken-5percent: #{darken(#ff6600, 5%)};
  --primary-dark2-color: #ea6b15;
  --primary-dark2-color-darken-10percent: #{darken(#ea6b15, 10%)};
  --primary-light2-color: #f2d3c4;
  --primary-light2-color-darken-2percent: #{darken(#f2d3c4, 2%)};
  --primary-light2-color-darken-6percent: #{darken(#f2d3c4, 6%)};
  --primary-light2-color-darken-10percent: #{darken(#f2d3c4, 10%)};
  --grayscale-color: #666666;
  --grayscale-color-lighten-25percent: #{lighten(#666666, 25%)};
  --grayscale-light-color: #e0e0e0;
  --grayscale-light-color-darken-8percent: #{darken(#e0e0e0, 8%)};
  --grayscale-light-color-lighten-7percent: #{lighten(#e0e0e0, 7%)};
  --grayscale-white-color: #e0e0e0;
  --grayscale-white-color-darken-5percent: #{darken(#e0e0e0, 5%)};
  --grayscale-white-color-darken-8percent: #{darken(#e0e0e0, 8%)};
  --grayscale-white-color-lighten-2percent: #{lighten(#e0e0e0, 2%)};
  --grayscale-white-color-lighten-5percent: #{lighten(#e0e0e0, 5%)};
  --white-color: #ffffff;
  --black-color: #0e0e0e;
  --secondary-dark-color: #007299;
  --secondary-color: #77256c;
  --secondary-color2: #81bd41;
  --secondary-color3: #ffd240;
  --secondary-color3-darken-3percent: #{darken(#ffd240, 3%)};
  --secondary-color3-lighten-17percent: #{lighten(#ffd240, 17%)};
  --secondary-color3-lighten-27percent: #{lighten(#ffd240, 27%)};
  --secondary-bright-color: #ece815;
  --error-color: #dd3435;
  --error-color-darken-10percent: #{darken(#dd3435, 10%)};
  --error-color-lighten-50percent: #{lighten(#dd3435, 50%)};
  --error-color-lighten-55percent: #{lighten(#dd3435, 55%)};
  --error-light-color: #eabec0;
  --error-light-color-darken-5percent: #{darken(#eabec0, 5%)};
  --disabled-color: #c9c9c9;
  --disabled-color2: #c9c9c9;
  --disabled-color3: #dd9e73;
  --default-sprite-position: bottom left;
  --active-hover-sprite-position: bottom right;
  /*remaing colors*/
  --snow-gray: #fcfcfc;
  --light-gainsboro-gray: #f7f7f7;
  --white-smoke: #f4f4f4;
  --very-light-gray: #f0f0f0;
  --light-pink: #febbbb;
  --light-yellow: #ffffe5;
  --orange-peel: #ff9900;
  --electric-yellow: #ffff00;
  --yellow-green: #effadb;
  --whisper-gray: #ededed;
  --dark-whisper-gray: #eeeeee;
  --white-dark-smoke: #e6e6e6;
  --gainsboro-gray: #e0e0e0;
  --medium-gray: #cccccc;
  --silver: #c6c6c6;
  --silver-very-light: #b9b9b9;
  --silver-light: #bbbbbb;
  --blue-smoke: #eef1f7;
  --ivory-red: #f8f8f2;
  --spun-pearl: #afafb6;
  --very-light-blue: #e6ebf5;
  --dark-gainsboro-gray: #d9d9d9;
  --bright-gainsboro-gray: #dddddd;
  --hawakes-blue: #ccd6eb;
  --blue-violet: #9933ff;
  --nobel-gray: #999999;
  --light-danube-blue: #89a4cd;
  --matte-gray: #888888;
  --light-matte-gray: #787878;
  --dark-gray: #777777;
  --raven-gray: #707275;
  --danube-blue: #6685c2;
  --dim-gray: #666666;
  --davy-grey: #555555;
  --analog-blue: #435f8b;
  --charcoal-gray: #444444;
  --mariner-blue: #335cad;
  --dark-charcoal-gray: #333333;
  --green-waterloo: #23241f;
  --nero-dark: #222222;
  --smalt-blue: #003399;
  --deep-green: #008a00;
  --navy-blue: #0066cc;
  --bondi-blue: #0085c7;
  --violation-text: #cc0000;
  --violation-bg: #ffe6e6;
  --warning-text: #f68d29;
  --warning-bg: #{lighten(#ffd240, 27%)};
  --highcharts-color-2: #{lighten(#ff6600, 20%)};
  --highcharts-color-3: #777777;
  --highcharts-color-6: #{darken(#ff6600, 5%)};
  --highcharts-color-7: #e0e0e0;
}

/*Sprite Positions*/
$default-sprite-position: var(--default-sprite-position);
$active-hover-sprite-position: var(--active-hover-sprite-position);
/*Images*/
$loading-screen-logo: var(--loading-screen-logo);
$login-box-logo: var(--login-box-logo);
//== New Brand Colors
/*Primary*/
$loading-login-screen-background: var(--loading-login-screen-background);
$darkblue: var(--primary-dark-color);
$blue: var(--primary-color);
$blue-darken-7percent: var(--primary-color-darken-7percent);
$blue-darken-11percent: var(--primary-color-darken-11percent);
$blue-lighten-10percent: var(--primary-color-lighten-10percent);
$blue-lighten-20percent: var(--primary-color-lighten-20percent);
$blue-lighten-40percent: var(--primary-color-lighten-40percent);
$lightblue: var(--primary-light1-color);
$lightblue-darken-5percent: var(--primary-light1-color-darken-5percent);
$lightblue-darken-10percent: var(--primary-light1-color-darken-10percent);
$lightblue-darken-15percent: var(--primary-light1-color-darken-15percent);
$lightblue-lighten-6percent: var(--primary-light1-color-lighten-6percent);
$whiteblue: var(--primary-white-color);
$whiteblue-darken-2percent: var(--primary-white-color-darken-2percent);
$whiteblue-darken-6percent: var(--primary-white-color-darken-6percent);
$whiteblue-lighten-2percent: var(--primary-white-color-lighten-2percent);
$whiteblue-lighten-4percent: var(--primary-white-color-lighten-4percent);
$yellowgreen: var(--primary-color2);
$green: var(--primary-color3);
$green-lighten-40percent: var(--primary-color3-lighten-40percent);
$green-darken-5percent: var(--primary-color3-darken-5percent);
$darkgreen: var(--primary-dark2-color);
$darkgreen-darken-10percent: var(--primary-dark2-color-darken-10percent);
$lightgreen: var(--primary-light2-color);
$lightgreen-darken-2percent: var(--primary-light2-color-darken-2percent);
$lightgreen-darken-6percent: var(--primary-light2-color-darken-6percent);
$lightgreen-darken-10percent: var(--primary-light2-color-darken-10percent);
/*Grayscale*/
$gray: var(--grayscale-color);
$grayscale-color-lighten-25percent: var(--grayscale-color-lighten-25percent);
$lightgray: var(--grayscale-light-color);
$lightgray-darken-8percent: var(--grayscale-light-color-darken-8percent);
$lightgray-lighten-7percent: var(--grayscale-light-color-lighten-7percent);
$whitegray: var(--grayscale-white-color);
$whitegray-darken-5percent: var(--grayscale-white-color-darken-5percent);
$whitegray-darken-8percent: var(--grayscale-white-color-darken-8percent);
$whitegray-lighten-2percent: var(--grayscale-white-color-lighten-2percent);
$whitegray-lighten-5percent: var(--grayscale-white-color-lighten-5percent);
$white: var(--white-color);
$black: var(--black-color);
/*Secondary*/
$darkcyan: var(--secondary-dark-color);
$purple: var(--secondary-color);
$orange: var(--secondary-color2);
$yellow: var(--secondary-color3);
$yellow-darken-3percent: var(--secondary-color3-darken-3percent);
$yellow-lighten-17percent: var(--secondary-color3-lighten-17percent);
$yellow-lighten-27percent: var(--secondary-color3-lighten-27percent);
$brightyellow: var(--secondary-bright-color);

$red: var(--error-color); //error
$red-darken-10percent: var(--error-color-darken-10percent); //error
$red-lighten-50percent: var(--error-color-lighten-50percent); //error
$red-lighten-55percent: var(--error-color-lighten-55percent); //error
$lightred: var(--error-light-color);
$lightred-darken-5percent: var(--error-light-color-darken-5percent);
/*disabled*/
$white-disabled: var(--disabled-color);
$blue-disabled: var(--disabled-color2);
$green-disabled: var(--disabled-color3);


/*remaing colors*/
$white-smoke: var(--white-smoke);
$white-dark-smoke: var(--white-dark-smoke);
$gainsboro-gray: var(--gainsboro-gray);
$light-gainsboro-gray: var(--light-gainsboro-gray);
$dark-gainsboro-gray: var(--dark-gainsboro-gray);
$whisper-gray: var(--whisper-gray);
$light-matte-gray: var(--light-matte-gray);
$dim-gray: var(--dim-gray);
$silver-very-light: var(--silver-very-light);
$silver-light: var(--silver-light);
$silver: var(--silver);
$bondi-blue: var(--bondi-blue);
$blue-smoke: var(--blue-smoke);
$nobel-gray: var(--nobel-gray);
$medium-gray: var(--medium-gray);
$navy-blue: var(--navy-blue);
$charcoal-gray: var(--charcoal-gray);
$dark-charcoal-gray: var(--dark-charcoal-gray);
$very-light-gray: var(--very-light-gray);
$green-waterloo: var(--green-waterloo);
$ivory-red: var(--ivory-red);
$spun-pearl: var(--spun-pearl);
$raven-gray: var(--raven-gray);
$danube-blue: var(--danube-blue);
$mariner-blue: var(--mariner-blue);
$very-light-blue: var(--very-light-blue);
$hawakes-blue: var(--hawakes-blue);
$matte-gray: var(--matte-gray);
$smalt-blue: var(--smalt-blue);
$nero-dark: var(--nero-dark);
$light-pink: var(--light-pink);
$light-yellow: var(--light-yellow);
$dark-gray: var(--dark-gray);
$orange-peel: var(--orange-peel);
$electric-yellow: var(--electric-yellow);
$deep-green: var(--deep-green);
$blue-violet: var(--blue--violet);
$bright-gainsboro-gray: var(--bright-gainsboro-gray);
$snow-gray: var(--snow-gray);
$davy-grey: var(--davy-grey);
$dark-whisper-gray: var(--dark-whisper-gray);
$analog-blue: var(--analog-blue);
$yellow-green: var(--yellow-green);
$light-danube-blue: var(--light-danube-blue);
$warning-text: var(--warning-text);
$warning-bg: var(--warning-bg);
$violation-text: var(--violation-text);
$violation-bg: var(--violation-bg);
$highcharts-color-2: var(--highcharts-color-2);
$highcharts-color-3: var(--highcharts-color-3);
$highcharts-color-6: var(--highcharts-color-6);
$highcharts-color-7: var(--highcharts-color-7);

$box-shadow-5: inset 0 1px 2px rgba(0, 0, 0, .1);
$box-shadow-6: inset 0 -3px 3px rgba(0, 0, 0, 0.05);
$box-shadow-7: inset 0 -1px 0 rgba(0, 0, 0, .15);
$box-shadow-8: rgba(0, 0, 0, 0.6);
$box-shadow-9: rgba(0, 0, 0, 0.2) 0 2px 8px;
$box-shadow-10: 1px 0px 3px rgba(0, 0, 0, .12), 1px 0px 2px rgba(0, 0, 0, .24);
$box-shadow-11: -1px 2px 3px 0px rgba(0, 0, 0, 0.2);
$box-shadow-12: 0 1px 2px rgba(0, 0, 0, .08), 0 1px 1px rgba(0, 0, 0, .16);
$box-shadow-13: 0 3px 9px rgba(0, 0, 0, .5);
$black-alpha-60: rgba(0, 0, 0, 0.6);
$black-alpha-50: rgba(0, 0, 0, .5);
$black-alpha-20: rgba(0, 0, 0, 0.2);
$black-alpha-10: rgba(0, 0, 0, 0.1);
$fullwhite-alpha-30: rgba(255, 0, 0, 0.30);

.darkblue {
  color: $darkblue;
}

.blue {
  color: $blue;
}

.lightblue {
  color: $lightblue;
}

.whiteblue {
  color: $whiteblue;
}

.yellowgreen {
  color: $yellowgreen;
}

.green {
  color: $green !important;
}

.darkgreen {
  color: $darkgreen;
}

.lightgreen {
  color: $lightgreen;
}

.gray {
  color: $gray;
}

.lightgray {
  color: $lightgray;
}

.whitegray {
  color: $whitegray;
}

.darkcyan {
  color: $darkcyan;
}

.purple {
  color: $purple;
}

.orange {
  color: $orange !important;
}

.yellow {
  color: $yellow;
}

.brightyellow {
  color: $brightyellow;
}

.red {
  color: $red !important;
}

.lightred {
  color: $lightred;
}

.white {
  color: $white !important;
}

.darkblue-bg {
  background-color: $darkblue;
}

.blue-bg {
  background-color: $blue !important;
}

.lightblue-bg {
  background-color: $lightblue;
}

.whiteblue-bg {
  background-color: $whiteblue;
}

.yellowgreen-bg {
  background-color: $yellowgreen;
}

.green-bg {
  background-color: $green;
}

.darkgreen-bg {
  background-color: $darkgreen;
}

.lightgreen-bg {
  background-color: $lightgreen;
}

.gray-bg {
  background-color: $gray;
}

.lightgray-bg {
  background-color: $lightgray;
}

.whitegray-bg {
  background-color: $whitegray;
}

.darkcyan-bg {
  background-color: $darkcyan;
}

.purple-bg {
  background-color: $purple;
}

.orange-bg {
  background-color: $orange !important;
}

.yellow-bg {
  background-color: $yellow !important;
}

.brightyellow-bg {
  background-color: $brightyellow !important;
}

.red-bg {
  background-color: $red !important;
}

.lightred-bg {
  background-color: $lightred !important;
}

//== Typography

//## Font, line-height, and color for body text, headings, and more.
$sans-serif-font-family: Arial, Helvetica, sans-serif;
$base-font-family: $sans-serif-font-family;
$font-size-base:	0.875rem; //14px
$body-color: var(--grayscale-color)!important;

$large-font-size: $font-size-base * 1.10; // ~16px

$h1-font-size: $font-size-base * 2; // ~28px
$h2-font-size: $font-size-base * 1.6; // ~22px
$h3-font-size: $font-size-base * 1.25; // ~18px
$h4-font-size: $font-size-base * 1.10; // ~16px

$box-shadow-all: 0px 0px 5px $lightgray-lighten-7percent; //shadow all sides - contact cards/comments/bdf placement etc
$box-shadow-1: 0 1px 2px rgba(0, 0, 0, 0.08), 0 1px 1px rgba(0, 0, 0, 0.16); //Buttons
$box-shadow-2: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24); // Navbar, sidebar, header panels, deal nav
$box-shadow-3: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23); // dropdown menus / controls
$box-shadow-4: 0 8px 16px rgba(0, 0, 0, 0.19), 0 5px 5px rgba(0, 0, 0, 0.23); // modals

$label-color: var(--grayscale-color);

//== Tables
//
//## Customizes the `.table` component with basic values, each used across all table variations.
//** Padding for `<th>`s and `<td>`s.
$padding-table-cell: 8px 10px;
$padding-table-condensed-cell: 6px;
//** Default background color used for all tables.
$table-bg-color: $white;
//** Background color used for `.table-striped`.
$accent-table-bg: $whitegray-lighten-2percent;
//** Background color used for `.table-hover`.
$hover-table-bg: $whitegray;
//** Border color for table and cell borders.
$border-table-color: $lightblue-darken-10percent;
$table-cell-border-color: $lightgray;
//== Forms
//** `<input>` background color
$input-bg-color: $white;
//** `<input disabled>` background color
$disabled-input-bg: $whitegray;
//** Text color for `<input>`s
$input-text-color: $gray;
//** `<input>` border color
$input-border-color: $lightgray;
//** Large `.form-control` height

//** Unit-less `line-height` for use in components like buttons.
$line-height-base:        1.428571429; // 20/14
//** Computed "line-height" (`font-size` * `line-height`) for use with `margin`, `padding`, etc.
$line-height-computed:    floor(($font-size-base * $line-height-base)); // ~20px

$line-height-large:         1.3333333; // extra decimals for Win 8.1 Chrome
$line-height-small:         1.5;
$padding-large-vertical:    0.625rem;
$large-input-height: (ceil($large-font-size * $line-height-large) + ($padding-large-vertical * 2) + 2);

$legend-border: $whitegray;
//== Dropdowns
//
//## Dropdown menu container and contents.
//** Background for the dropdown menu.
$dropdown-bg-color: $white;
//** Dropdown menu `border-color`.
$dropdown-border-color: $lightgray;
//** Divider color for between dropdown items.
$dropdown-divider-bg-color: $whitegray;
//** Dropdown link text color.
$dropdown-link: $blue;
//** Hover color for dropdown links.
$dropdown-link-hover: $blue;
//** Hover background for dropdown links.
$dropdown-link-hover-bg-color: $whitegray;
//** Disabled dropdown menu item background color.
$dropdown-link-disabled: $whitegray;
//** Text color for headers within dropdown menus.
$dropdown-header: $whitegray;
//== Media queries breakpoints
//


// Extra small screen / phone
//** Deprecated `$screen-xs` as of v3.0.1
$screen-xs: 480px;
//** Deprecated `$screen-xs-min` as of v3.2.0
$screen-xs-min: $screen-xs;
//** Deprecated `$screen-phone` as of v3.0.1
$screen-phone: $screen-xs-min;

// Small screen / tablet
//** Deprecated `$screen-sm` as of v3.0.1
$screen-sm: 768px;
$screen-sm-min: $screen-sm;
//** Deprecated `$screen-tablet` as of v3.0.1
$screen-tablet: $screen-sm-min;

// Medium screen / desktop
//** Deprecated `$screen-md` as of v3.0.1
$screen-md: 992px;
$screen-md-min: $screen-md;
//** Deprecated `$screen-desktop` as of v3.0.1
$screen-desktop: $screen-md-min;

// Large screen / wide desktop
//** Deprecated `$screen-lg` as of v3.0.1
$screen-lg: 1200px;
$screen-lg-min: $screen-lg;
//** Deprecated `$screen-lg-desktop` as of v3.0.1
$screen-lg-desktop: $screen-lg-min;


// Extra small screen / phone
$screen-xs: 1px; //was 480
$screen-xs-min: $screen-xs;
$screen-phone: $screen-xs-min;
// Horizontal Mobile Small grid
$screen-hs: 480px; //NEW
$screen-hs-min: $screen-hs;
$screen-phone: $screen-hs-min;
// Extra Large screen / wide desktop
$screen-xl: 1600px;
$screen-xl-min: $screen-xl;
$screen-xl-desktop: $screen-xl-min;
// So media queries don't overlap when required, provide a maximum
$screen-xs-max: ($screen-hs-min - 1);
$screen-hs-max: ($screen-sm-min - 1);
$screen-sm-max: ($screen-md-min - 1);
$screen-md-max: ($screen-lg-min - 1);
$screen-lg-max: ($screen-xl-min - 1);
//== Container sizes
//## Define the maximum width of `.container` for different screen sizes.
// Small screen / tablet
$container-phonehs: (27.5rem + $grid-gutter-width); //27.5rem ~ 440px
//** For `$screen-hs-min` and up.
$container-hs: $container-phonehs;
// xLarge screen / wide desktop
$container-xlarge-desktop: (96.25rem + $grid-gutter-width); // 96.25rem ~1540px
//** For `$screen-xl-min` and up.
$container-xl: $container-xlarge-desktop;
//== Navs
//=== Shared nav styles
$nav-link-pad: 8px 14px;
//== Tabs
$nav-tabs-border: $lightgray;
//== Labels
//** Default label text color
$label-text-color: $white;
//** Default text color of a linked label
$link-label-hover-color: $white;
//== Modals
//** Padding applied to the modal title
$modal-title-pad: 15px 15px 10px;
//** Background color of modal content area
$modal-content-bg-color: $white;
//** Modal content border color
$modal-content-border: $blue;
//** Modal content border color **for IE8**
$modal-content-fallback-border: $gray;

$modal-xl: 1150px;


//Bootstrap 3 variables

$border-radius-base: 4px;
$border-radius-small: 3px;
$border-radius-large: 6px;

$font-size-small: .75rem;


//== Grid system
//
//## Define your custom responsive grid.

//** Number of columns in the grid.
$grid-columns: 12;
//** Padding between columns. Gets divided in half for the left and right.
$grid-gutter-width: 1.875rem; //~30px
// Navbar collapse
//** Point at which the navbar becomes uncollapsed.
$grid-float-breakpoint: $screen-sm-min;
//** Point at which the navbar begins collapsing.
$grid-float-breakpoint-max: ($grid-float-breakpoint - 1);


