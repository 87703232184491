@import "../../node_modules/bootstrap/scss/variables";
@import "custom-variables";
body {
    position: relative;
}

#sidebar-wrapper {
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    min-height: calc(100vh - 60px);
    position: relative;
    top: 46px;

    & a:focus {
        outline: none;
    }
}


/* Container for toggling minimized */
#sidebar-container {
    padding-top: 25px;
}


/* SIDEBAR LEFT PANEL */

#sidebar-left-panel {
    background: $darkblue none repeat scroll 0 0;
    color: white;
    width: 240px;
    box-shadow: $box-shadow-10;
    height: calc(100vh - 46px);
    overflow-y: auto;
    position: fixed;
    top: 46px;
    z-index: 777;

    & hr {
        border-top: 2px solid $lightblue;
    }

    & ul {
        margin: 0;
        padding: 0;
    }

    & a {
        cursor: pointer;
    }

    & li.menu-header {
        background-color: $darkblue;
        padding: 5px 0;
        display: table;
        white-space: nowrap;
        position: relative;

        & span {
            font-size: $font-size-base;
            background-color: $darkblue;
            padding: 0 6px;
        }

        &:before, &:after {
            border-top: 2px solid $lightblue;
            content: '';
            display: table-cell;
            position: relative;
            top: 9px;
        }

        &:before {
            width: 7%;
        }

        &:after {
            width: 93%;
        }

        button {
            position: absolute;
            right: 8px;
            top: 8px;
            z-index: 1;
            background-color: $darkblue;
            padding: 0 8px;
            border: none;

            &:hover, &:focus {
                color: $green;
            }
        }
    }

    & .sidebar-sub-menu li.menu-header,
    & .sidebar-sub-menu li.menu-header span,
    & .sidebar-sub-menu li.menu-header button {
        background-color: $blue;
    }

    & .menu-header {

        & i {
            display: inline-block;
            font-size: $h1-font-size;
            margin-right: 15px;
            width: 30px;
            text-align: center;
            vertical-align: bottom;
        }

        &:first-child {
            border-top: 0 solid $blue;
        }
    }

    & .sidebar-sub-menu li.menu-nonlink {
        background-color: $blue;
        padding: 5px 25px;
        font-size: $font-size-base;
    }

    & .panel-list {
        margin-bottom: 20px;

        & ul {
            background: var(--primary-color);
            box-shadow: $box-shadow-11;


            &.sidebar-sub-menu li > a {
                background: $blue;
                border-top: none;
                color: white;
                display: block;
                font-size: $font-size-base;
                font-weight: 500;
                padding: 7px 10px!important;
                position: relative;
            }
        }

        & > li {

            & > a {
                background: $darkblue;
                border-top: none;
                border-right: 1px solid $blue;
                color: white;
                display: block;
                font-size: 14px;
                font-weight: 500;
                padding: 12px 13px;
                position: relative;

                &.disabled {
                    opacity: .65;
                    cursor: not-allowed;
                }

                &:hover,
                &:focus,
                &:active {
                    color: white;
                    background: $blue;
                }

                & > i {
                    position: relative;
                    display: inline-block;
                    font-size: 26px;
                    line-height: 24px;
                    margin-right: 6px;
                    width: 30px;
                    text-align: center;
                    vertical-align: bottom;
                    transition: all .3s ease-in-out;

                    span.overlay-icon {
                        position: absolute;
                        top: 12px;
                        right: -1px;
                        font-size: 18px;
                        -webkit-text-fill-color: $white;
                        text-shadow: -2px -2px 0 $darkblue,
                            2px -2px 0 $darkblue,
                        -2px 2px 0 $darkblue,
                        2px 2px 0 $darkblue;
                    }
                }

                &:hover i:not(.fa-plus),
                &:focus i:not(.fa-plus) {
                    font-size: 30px;
                    margin-right: 10px;

                    span.overlay-icon {
                        -webkit-text-fill-color: $white;
                        text-shadow: -2px -2px 0 $blue,
                            2px -2px 0 $blue,
                        -2px 2px 0 $blue,
                        2px 2px 0 $blue;
                    }
                }

                & span.menu-text {
                    display: inline-block;

                    &.top-level {
                        display: inline-block;
                        font-size: $large-font-size;
                        position: relative;
                        top: 1px;
                    }
                }

                & .label {
                    border-radius: 0.25em;
                    color: white;
                    display: none;
                    font-size: $font-size-small;
                    line-height: 1;
                    padding: 4px 5px;
                    position: absolute;
                    right: 10px;
                    top: 14px;
                    text-align: center;
                    white-space: nowrap;
                    background-color: $green;
                    transition-duration: 0.2s;
                    box-shadow: $box-shadow-1;

                    & .fa {
                        padding-right: 4px;
                    }
                }

                & button.label {
                    border: none;
                }

                & .label:focus,
                & .label:hover {
                    color: white;
                    background-color: $darkgreen;
                }

                &:focus .label,
                &:hover .label {
                    display: inline;
                }
            }

            &.openfeature a .label {
                display: inline;
            }

            &.mod-home > a {
                border-bottom: 1px solid $lightblue;
                padding: 8px 17px;
                cursor: default;

                & span.menu-text.top-level {
                    font-weight: bold;
                    text-transform: uppercase;
                    letter-spacing: .5px;
                    /*font-family: 'Open Sans', sans-serif;*/
                    & span {
                        right: 1px;
                        position: relative;
                    }
                }

                &:hover, &:focus {
                    background: $darkblue;
                }
            }

            &:last-child > a {
                border-bottom: none;
            }

            &:first-child > a {
                border-top: none;
            }

            &.active > a {
                background: $blue;
                border-top: 1px solid $blue;
                color: white;
            }

            &.sidebar-has-menu {

                &.openfeature > a {
                    background: $blue;
                    color: white;
                    /*border-bottom: 1px solid $lightblue;*/
                    span.overlay-icon {
                        background-color: $blue;
                    }
                }

                & li.sidebar-has-menu > a:before {
                    font-family: 'FontAwesome', sans-serif;
                    content: "\f054";
                    font-size: $font-size-small;
                    position: relative;
                    float: left;
                    left: 5px;
                    top: 2px;
                }

                & li.sidebar-has-menu.openfeature > a:before {
                    font-family: 'FontAwesome', sans-serif;
                    content: "\f078";
                    font-size: $font-size-small;
                    position: relative;
                    float: left;
                    left: 3px;
                }

                & > ul.sidebar-sub-menu {
                    display: none;

                    & li {

                        &.has-divider {
                            border-bottom: 1px solid $lightblue;
                        }

                        & > a:hover,
                        & > a:focus {
                            text-decoration: none;
                            color: white;
                            background: $lightblue-darken-15percent;
                        }

                        & > ul.sidebar-sub-menu li > a {
                            color: white;
                            background: $blue-darken-11percent;
                            padding: 5px 10px;

                            &:hover,
                            &:focus {
                                background: $lightblue-darken-15percent;
                            }
                        }
                    }
                }

                & > ul ul {
                    display: none;
                }

                &.openfeature > ul,
                &.openfeature > ul > li.sidebar-has-menu.openfeature > ul,
                &.openfeature > ul > li.sidebar-has-menu.openfeature > ul > li.sidebar-has-menu.openfeature > ul,
                &.active > ul,
                &.active > ul > li.sidebar-has-menu.active > ul,
                &.active > ul > li.sidebar-has-menu.active > ul > li.sidebar-has-menu.active > ul {
                    display: block;
                }

                & > ul > li > a span.menu-text {
                    padding-left: 15px;
                    position: relative;
                    display: inline-block;
                }

                & li.sidebar-has-menu.openfeature > a span.menu-text {
                    padding-left: 12px;
                }

                & li.sidebar-has-menu > ul.sidebar-sub-menu {
                    list-style: outside none none;
                }

                & > ul > li.sidebar-has-menu > ul > li > a span.menu-text,
                & > ul > li.sb-indent > a span.menu-text {
                    padding-left: 25px;
                    position: relative;
                    display: inline-block;
                }

                & > ul > li.sidebar-has-menu > ul > li > ul > li > a span.menu-text {
                    padding-left: 35px;
                    position: relative;
                    display: inline-block;
                }

                & > ul > li.sidebar-has-menu > ul > li > ul > li > ul > li > a span.menu-text {
                    padding-left: 40px;
                    position: relative;
                    display: inline-block;
                }

                & > ul.sidebar-sub-menu {
                    list-style: none;

                    & > li {
                        &:nth-of-type(3) > a span.menu-text.level-down {
                            margin-left: 16px;
                        }

                        &:nth-of-type(4) > a span.menu-text.level-down {
                            margin-left: 19px;
                        }

                        &:nth-of-type(5) > a span.menu-text.level-down {
                            margin-left: 22px;
                        }

                        &:nth-of-type(6) > a span.menu-text.level-down {
                            margin-left: 25px;
                        }

                        &:nth-of-type(7) > a span.menu-text.level-down {
                            margin-left: 28px;
                        }

                        &:nth-of-type(1n+8) > a span.menu-text.level-down {
                            margin-left: 31px;
                        }

                        & > a span.menu-text.level-down:before {
                            content: "\f148";
                            transform: rotate(90deg);
                            margin-left: -10px;
                        }

                        & > a span.menu-text:before {
                            font-family: 'FontAwesome', sans-serif;
                            content: "";
                            font-size: $large-font-size;
                            position: relative;
                            float: left;
                            left: -10px;
                            top: -2px;
                        }

                        & > a span.menu-text.plus:before {
                            content: "\f055";
                        }

                        & > a span.menu-text.home:before {
                            content: "\f015";
                        }

                        & > a span.menu-text.info:before {
                            content: "\f05a";
                        }
                    }

                    & > li.active a .selected {
                        display: inline-block;
                        height: 0;
                        position: absolute;
                        right: 0;
                        top: 5px;
                        vertical-align: middle;
                        width: 0;
                    }

                    & > li.active a {
                        background-color: $lightblue-darken-15percent;
                    }
                }

                & > a .selected {
                    display: none;
                }
            }
        }

        & li.active > a .selected {
            border-bottom: 10px solid transparent;
            border-right: 8px solid white;
            border-top: 10px solid transparent;
            display: inline-block;
            height: 0;
            position: absolute;
            right: -1px;
            top: 20px;
            vertical-align: middle;
            width: 0;
        }
    }
}

#main-content {
    padding-left: 240px;
    position: relative;
}

span.menu-text + span.menu-text {
    display: block;
}


@media (max-width: 992px) {
    #sidebar-left-panel {
        min-height: 100%;
        height: auto;
        overflow-y: visible;
    }
}


/* Minimized Panel - Tablet/Phone */

.sidebar-minimized #main-content {
    padding-left: 60px;
}

.sidebar-minimized #sidebar-left-panel {
    width: 60px;
    background: $darkblue;
    color: white;
    z-index: 999;

    & .panel-list {

        & > li > a {

            & span.menu-text {
                display: none;
            }

            & > i {
                display: inline-block;
                font-size: $h1-font-size;
                width: 30px;
                text-align: center;
            }
        }

        & > li:hover > a:not(.closed),
        & > li:focus > a:not(.closed),
        & > li:active > a:not(.closed){
            display: block;
            position: relative;
            width: 300px;
            margin-bottom: -1px;
            border-radius: 0 4px 4px 0;

            & span.menu-text {
                display: inline-block;
                position: absolute;
                left: 76px;
                top: 14px;
            }
        }

        & > li.sidebar-has-menu {

            & > a:after {
                display: none;
            }

            &:hover,
            &:focus,
            &:active {

                & > a {
                    border-radius: 0 4px 0 0;
                    background: $blue;
                }

                & > ul.sidebar-sub-menu:not(.closed),
                & > ul.sidebar-sub-menu:not(.closed) {
                    display: block !important;
                    border-right: 1px solid $blue;
                    width: 240px;
                    left: 60px;
                    overflow-y: auto;
                    max-height: 50vh;
                    overflow-x: hidden;

                    & ul.sidebar-sub-menu {
                        display: none !important;
                        left: 0;
                    }
                }
            }

            & > ul.sidebar-sub-menu {
                display: none !important;
                position: absolute;
                left: 60px;
                width: 240px;

                & ul.sidebar-sub-menu {
                    display: none !important;
                    position: absolute;
                    width: 240px;
                    left: 0;
                }

                & > li.sidebar-has-menu:hover > ul.sidebar-sub-menu,
                & > li.sidebar-has-menu:focus > ul.sidebar-sub-menu {
                    display: block !important;
                    position: relative;
                    left: 0;
                }

                & > li.sidebar-has-menu > ul.sidebar-sub-menu > li.sidebar-has-menu:hover > ul.sidebar-sub-menu,
                & > li.sidebar-has-menu > ul.sidebar-sub-menu > li.sidebar-has-menu:focus > ul.sidebar-sub-menu {
                    display: block !important;
                    position: relative;
                    left: 0;
                }

                & > li.active a .selected {
                    right: -2px;
                }

                & > li.sidebar-has-menu > ul.sidebar-sub-menu li > a span.menu-text {
                    padding-left: 15px;
                    position: relative;
                    display: inline-block;
                }

                & > li.sidebar-has-menu > ul.sidebar-sub-menu > li > a span.menu-text:before,
                & > li.sidebar-has-menu > ul.sidebar-sub-menu > li.sidebar-has-menu > ul.sidebar-sub-menu > li > a span.menu-text:before {
                    left: 4px;
                }
            }

            & li.sidebar-has-menu {

                & > a:after {
                    display: none;
                }

                & > a:before {
                    font-family: 'FontAwesome', sans-serif;
                    content: "\f054";
                    font-size: $font-size-small;
                    position: relative;
                    float: left;
                    display: block;
                }

                &:hover > a:before,
                &:focus > a:before {
                    font-family: 'FontAwesome', sans-serif;
                    content: "\f078 ";
                    font-size: $font-size-small;
                    position: relative;
                    float: left;
                    display: block;
                }
            }

            &.active > li.active a .selected {
                display: block;
            }

            &.openfeature > a,
            &.openfeature > a:hover
            {
                background: $blue;
                color: white;
            }
        }

        & ul.sidebar-sub-menu li {
            list-style: none;
        }

        & > li.active:hover > a .selected,
        & > li.active:focus > a .selected {
            display: none;
        }

        & .label,
        & .openfeature .label {
            display: none !important;
        }

        & li:hover a:not(.closed) .label,  {
            display: block !important;
            position: absolute;
            right: 12px;
            top: 16px;
        }
    }
}


/*Tablet Toggle function*/

.nav > li > a.nav-tablet {
    display: none !important;
}

@media (max-width: 992px) {
    .nav > li > a.nav-tablet {
        display: block !important;
    }

    .nav > li > a.nav-desktop {
        display: none !important;
    }
}


/* Mobile fixes */

@media screen and (max-width: 520px) {
    #sidebar-wrapper, #sidebar-left-panel {
        top: 90px;
    }

    #sidebar-left-panel .nav-tablet {
        z-index: 1000!important;
    }
}
