.banker {
    .sticky-panel {
        position: sticky;
        top: 110px;
        z-index: 999;
    }

    .banker-application-configuration-controls {
        .application-pages {
            position: sticky;
            z-index: 700;
        }

        .application-sections {
            position: sticky;
            z-index: 650;
        }

        .z-index-send-back {
            z-index: -1;
        }
    }

    .text-right {
        text-align: right;
    }

    .lending-icon-container div:first-child .lending-icon-bar {
        left: 50%
    }

    a.card-link:hover {
        color: unset;
    }

    bh-r-checkbox {
        padding: 4px;
    }

    .card {
        border: 1px solid var(--grayscale-color-lighten-25percent);
        border-radius: 4px;
        margin-bottom: 15px;
        padding: 10px;
        box-shadow: var(--dark-whisper-gray) 2px 2px 6px;

        h2 {
            margin-top: unset;
        }

        h3 {
            margin-top: unset;
        }

        h6 {
            margin-top: 0;
        }
    }

    .status-info {
        color: var(--secondary-color2);
    }


    .equal {
        display: flex;
        display: -webkit-flex;
        flex-wrap: wrap;
    }

    .application-card {
        background: var(--grayscale-white-color-lighten-2percent);
        border: solid 1px var(--primary-light1-color);
        padding: 1rem 1.5rem;
        border-radius: 4px;

        .form-section-header {
            h3 {
                span {
                    background: var(--grayscale-white-color-lighten-2percent);
                }
            }
        }
    }

    .modal-body {
        .form-section-header h3 span {
            background: white;
        }
    }

    .tile-selection {
        background: var(--white-color);
        border-radius: 4px;
        border: 1px solid var(--primary-white-color-darken-2percent);
        box-shadow: 5px 5px 10px var(--grayscale-light-color-darken-7percent);
        padding: 40px 8px;
        cursor: pointer;
        margin-bottom: 10px;

        p.bold {
            text-align: center;
            margin-bottom: 0;
        }
    }

    .row.equal {
        display: flex;
        display: -webkit-flex;
        flex-wrap: wrap;
    }

    .tile-selection.tile-selected {
        box-shadow: inset var(--primary-color-darken-7percent) 4px 4px 6px;
        border: 1px solid var(--primary-white-color-darken-6percent);
        background: var(--primary-color);
        color: var(--white-color);
    }

    button.tile-selection {
        width: 100%;
    }

    .card-link .card:hover, .tile-selection:hover {
        box-shadow: var(--primary-white-color-darken-2percent) 4px 4px 6px;
        color: var(--primary-color3);
    }

    .tile-selected:hover {
        background-color: var(--primary-light1-color);
        border-color: var(--primary-light1-color-darken-5percent);
        color: var(--white-color);
    }

    .list-group-item {
        border-top: unset;
        border-left: unset;
        border-right: unset;
        background-color: unset;
        border-bottom: #ddd 1px solid;
    }

    .list-group-item a b:hover {
        color: var(--primary-color3);
    }


    .app-page-header {
        color: var(--primary-color)
    }

    .help-link {
        i {
            margin-left: 8px;
        }
    }

    .mb-5 {
        margin-bottom: 5px;
    }

    .application-page-container {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;

        .page {
            display: flex;
            text-align: center;
            cursor: pointer;
            position: relative;


            .application-page-bar {
                width: 100px;
                height: 5px;
                background-color: var(--primary-light1-color-darken-5percent);
                position: absolute;
                bottom: 44px;
                z-index: -1000;
                margin-left: 50px;
            }
        }


        .page-icon {
            min-width: 100px;



            .fa-circle {
                color: var(--grayscale-light-color);
            }

            i {
                color: white;
                text-shadow: 1px 2px 6px rgba(00, 00, 00, .21);
            }


            .fa-stack-1x {
                color: white !important;
            }

            .page-title-text {
                font-weight: 700;
                text-align: center;
            }
        }
    }
}

.page-icon-complete .fa-circle {
    color: var(--primary-color3) !important;
}

.page div:last-child {
    .application-page-bar {
        width: 0 !important;
    }
}

.page-icon.page-icon-active .fa-circle.fa-stack-2x {
    color: var(--primary-light1-color-darken-15percent) !important;
}

.sidebar-has-menu .nav-desktop .fa-book {
    transform: rotate(10deg);
}

table.spreadTable {
    i.fa-star-o {
        font-size: 0.6875rem;
        margin-left: 2px;
    }

    .spread-table-button {
        margin-left: 30px;
    }

    .line-number {
        margin-left: 6px;
    }

    .tooltip-br.overflow-visible {
        vertical-align: bottom;
    }
}

.font-weight-bold {
    font-weight: 700;
}

.banker-parent-container {
    position: relative;
}

.banker-parent-container input {
    width: 100%;
}

.banker-parent-container {
    position: relative;
}

.banker-parent-container input {
    width: 100%;
}

.banker-search-results {
    background: white;
    overflow-y: hidden;
    position: absolute;
    left: 0;
    right: 0;
    opacity: 0;
    padding: 3px;
    overflow-x: hidden;
}

.banker-search-results.opened {
    opacity: 1;
    transform: translateY(0px);
    z-index: 999;
}

.banker-form-group {
    margin-bottom: -15px !important;
}

.banker-blue-background {
    vertical-align: middle;
    text-align: center;
    background: $whiteblue-darken-2percent linear-gradient(to bottom, $whiteblue, $whiteblue 50%, $whiteblue-darken-6percent);
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}


.banker-tiles-move {
    background: $whiteblue-darken-2percent linear-gradient(to bottom, $whiteblue, $whiteblue-darken-6percent);
    cursor: move !important;

    &:before,
    &:after {
        font-family: 'FontAwesome', sans-serif;
        content: "\f142";
        font-size: 18px;
        color: $lightblue;
    }

    &:after {
        left: 16px;
    }

    &:hover::before,
    &:hover::after {
        color: $blue;
    }
}
