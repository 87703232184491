@import "custom-variables";
a {
    cursor: pointer;

    &:focus {
        color: inherit;
    }

    &.disabled {
        cursor: not-allowed;
        pointer-events: none;
        opacity: .65;
    }
}

hr {
    border-top: 1px solid $lightblue;
    margin-top: 10px;
}

.large {
    font-size: $large-font-size;
}

small, .small {
    font-size: $font-size-small;
}

.pre-wrap {
    white-space: pre-wrap;
}

.nowrap {
    white-space: nowrap;
}

.bold {
    font-weight: bold;
}

.italic {
    font-style: italic;
}

.underline {
    text-decoration: underline;
}

.uppercase {
    text-transform: uppercase;
}

.uppercase-input input {
    text-transform: uppercase;
}

.center-text {
    display: flex;
    align-items: center;
    justify-content: center;
}

//Message Boxes
.info-message,
.warning-message,
.alert-message,
.error-message,
.violation-message {
    border: 1px solid $lightblue;
    background-color: $whiteblue-lighten-2percent;
    padding: 3px 10px;
    display: inline-block;
    font-size: 13px;
    color: $gray !important;

    &:before {
        font-family: 'FontAwesome', sans-serif;
        content: "";
        font-size: $large-font-size;
        margin-right: 10px;
    }

    &.large {
        font-size: $large-font-size;
        padding: 6px 15px 5px;
    }

    &.policy-message {
        width: 100%;
        border-width: 0 1px;

        &:first-of-type {
            border-top-width: 1px;
        }

        &:last-of-type {
            border-bottom-width: 1px;
            margin-bottom: 10px;
        }
    }
}
.import-lock-message {
    border: 1px solid var(--violation-text);
    background-color: var(--violation-bg);
    color: var(--violation-text) !important;
    padding: 3px 10px;
    display: inline-block;
    font-size: 13px;
}

.import-lock-message:before {
    font-family: "FontAwesome", sans-serif;
    content: "\f023";
    font-size: 0.9625rem;
    margin-right: 10px;
    color: var(--violation-text);
}

.info-message {
    border: 1px solid $lightblue;
    background-color: $whiteblue-lighten-2percent;

    &:before {
        content: "\f05a";
        color: $blue;
    }
}

.warning-message {
    border: 1px solid $yellow;
    background-color: $warning-bg;

    &:before {
        content: "\f071";
        color: $warning-text;
    }
}

.alert-message,
.violation-message,
.error-message {
    border: 1px solid $violation-text;
    background-color: $violation-bg;
    color: $violation-text !important;

    &:before {
        content: "\f057";
        color: $violation-text;
    }
}

//Custom Icons


.icon-unbalanced {
    display: inline-block;
    height: 20px;
    width: 22px;
    vertical-align: bottom;
    background-image: url('../assets/images/icon-unbalanced@2x.png');
    background-size: cover;
}

//Wrap long words/urls
.single-wordwrap,
.single-wordwrap label {
    overflow-wrap: break-word;
    word-wrap: break-word;
    word-break: break-word;
}
//Ellipsis
.truncate-ellipsis {
    display: table;
    table-layout: fixed;
    width: 100%;
    white-space: nowrap;

    & > * {
        display: table-cell;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .input-group-append, .input-group-btn {
        width: 38px;
    }
}

h1 .fa-spin {
    position: relative;
    top: 7px;
}
//File Type Colors
.file-type-icons {
    .fa-file-word-o {
        color: $blue;
    }

    .fa-file-excel-o {
        color: $green;
    }

    .fa-file-pdf-o {
        color: $red;
    }

    .fa-file-powerpoint-o {
        color: $orange;
    }

    .fa-file-code-o {
        color: $purple;
    }
}

.primary-check:after {
    font-family: 'FontAwesome', sans-serif;
    font-weight: bold;
    font-size: $large-font-size;
    content: "\f00c";
    padding-left: 5px;
    color: $green;
    line-height: normal;
}

.badge-container {
    padding-top: 8px;
}
