@import "../../node_modules/bootstrap/scss/variables";
@import "custom-variables";

//== Forms

.checkbox-title {
    .form-section-header {
        position: relative;

        .btn-container {
            position: absolute;
            background-color: $white;
            left: 25px;
            top: 5px;
            padding-right: 10px;
            z-index: 10;
        }
    }
}

.form-section-header {
    position: relative;

    h2, & h3 {
        width: 100%;
        border-bottom: 1px solid $lightblue;
        line-height: 0.1em;
        margin: 30px 0 30px;
        color: $blue;

        & span {
            background: $white;
            padding-right: 12px;
        }

        & i {
            line-height: 0.1em;

            &:nth-of-type(2) {
                margin-left: 10px;
                font-size: 0.9375rem;
            }
        }
    }

    .btn-container {
        position: absolute;
        right: 40px;
        top: 14px;
        z-index: 10;

        .btn {
            position: static;

            + .btn {
                margin-left: 10px;
            }
        }
    }

    .dropdown,
    .btn {
        position: absolute;
        right: 40px;
        z-index: 10;

        &:disabled {
            opacity: 1;
        }
    }

    .dropdown .btn {
        position: static;
    }

    .dropdown-menu {
        s & .checkbox-radio {
            margin-left: 10px;
            margin-right: 10px;
        }
    }

    a.form-section-toggle {
        cursor: pointer;

        & .fa {
            margin-right: 10px;
        }
    }

    .bdf-icon {
        display: inline;

        &:before {
            font-family: 'FontAwesome', sans-serif;
            content: "\f19c";
            color: $green;
            font-size: 0.875rem;
        }
    }

    &.plus-checkbox {
        width: calc(100% - 30px);

        & h3 {
            margin-left: 30px;
        }

        & .checkbox {
            position: absolute;
            top: 12px;
        }
    }

    .btn-sm {
        top: 14px;
    }
}


.header-checkbox-container {
    position: absolute;
    right: 40px;
    top: 12px;
    z-index: 10;
    background-color: white;
    border: 1px solid $lightblue;
    padding: 0 15px;
    border-radius: $border-radius-base;
    box-shadow: $box-shadow-1;

    & .header-checkbox {
        padding: 0;
    }
}

.toggle-section {
    width: 100%;
}

.form-group {
    margin-bottom: 17px;
}

.dropdown-menu .form-control-sm {
    margin-bottom: 12px;
}


.form-control-sm {
    .form-control {
        font-size: $font-size-base;
        min-height: 30px;
        padding: 2px 10px 0;
        border-radius: 0;
        line-height: 2;
    }

    .input-group-btn .btn {
        font-size: 1.125rem;
        width: 32px;
        height: 30px;
    }

    select.form-control {
        line-height: normal;
    }
}


label {
    color: $gray;
    font-size: $font-size-base;
    font-weight: normal;
    width: 100%;
    margin-bottom: 0;
    position: relative;
}

.datepicker-label {
    position: relative;
    display: inline-block;
    width: 100%;
}



.form-control {
    height: auto;
}

.form-control,
.input-span,
.info-span {
    border: 1px solid $lightgray;
    border-bottom: 3px solid $lightgray;
    box-shadow: none;
    border-radius: 0;
    font-size: $large-font-size;
    min-height: 36px;
    line-height: 26px;
    padding: 4px 12px 0;
    transition: none;

    &#listbox {
        border-right-width: 2px;
    }

    &:focus {
        border-color: $blue;
        box-shadow: none;
    }
}

.clear-field-btn {
    .btn-unstyled{
    position: absolute;
    padding: 4px;
    right: 50px;
    bottom: 4px;
    z-index: 5;
    background-color: white;
    }

    &:after {
        font-family: 'FontAwesome', sans-serif;
        content: "\f00d";
        font-size: $large-font-size;
        color: $blue;
    }

    &:hover::after, &:focus::after {
        color: $green;
    }

    &.dropdown {
        right: 35px;
    }
}

.select-field > .clear-field-btn {
    right: 60px;
}

/*Text on sides of control - dont use no-padding's*/

.sidetext-control {
    display: table-cell;
    width: 100%;

    .form-control {
        float: none;
    }
}

.sidetext-before {
    display: table-cell;
    padding-right: 30px;
    white-space: nowrap;
    vertical-align: middle;
}

.sidetext-after {
    display: table-cell;
    padding-left: 30px;
    white-space: nowrap;
    vertical-align: middle;
}

/* A2 margin*/
label .form-control,
.form-group .form-control {
    margin-top: 4px;
}

/*Add space for label if there isnt one - Add to column div*/
.no-label,
.no-label-spacing {

    .form-control,
    label .form-control,
    .form-group .form-control {
        margin-top: 0;
    }
}

.no-label {
    padding-top: 24px;
}

bh-date-with-days .no-label, .no-label-inline {
        padding-top: 19px;
    }

//Placeholder Text
::-webkit-input-placeholder {
    opacity: .50;
}

:-moz-placeholder {
    opacity: .50;
}

::-moz-placeholder {
    opacity: .50;
}

:-ms-input-placeholder {
    opacity: .50 !important;
}

.form-control:focus {
    box-shadow: none;
}

.form-control[disabled], fieldset[disabled] .form-control,
.form-control[readonly], fieldset[readonly] .form-control,
.info-span,
.input-span {
    background-color: $whitegray;
}

.form-control[disabled], fieldset[disabled] .form-control {
    cursor: not-allowed;
}

.info-span,
.input-span {
    border-color: $whitegray;
}

.form-control.readonly-white[readonly] {
    background-color: white;

    &[disabled] {
        background-color: $whitegray;
    }
}

select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    text-indent: 1px;
    text-overflow: '';
    cursor: pointer;

    &::-ms-expand { /* for IE 11 */
        display: none;
    }
}

input.dropdown-table {
    cursor: pointer;
}

textarea {
    resize: vertical;

    &.form-control {
        min-height: 90px;
    }
}

// ------------ Checkboxes and radios - custom overrides

.checkbox-radio {
    position: relative;
    display: inline-block;
    margin: 8px 0!important;
    padding-left: 32px;
    cursor: pointer;
    width: initial;
    min-height: 20px;

    &.radio {
        pointer-events: auto;
    }
}

.checkbox-radio-no-margin {
    @extend .checkbox-radio;
    margin: 0 !important;
}

//Inine Radio and Checkbox
bh-r-radio,
bh-r-checkbox,
div {
    &.inline,
    &.inline div,
    & .inline-radio,
    &.inline-radio,
    &.inline-checkbox {
        display: inline-block;
        margin-right: 20px;
        width: auto;
    }
}

.in-header-input {
    .form-group {
        margin-bottom: 0;
    }
}



.checkbox-radio input {
    position: absolute;
    left: 3px;
    bottom: 4px;
    z-index: -1;
    opacity: 0;
    margin-left: 0 !important;
}

.checkbox-radio-indicator {
    position: absolute;
    top: -1px;
    left: 0;
    width: 22px;
    height: 22px;
    background: white;
    border: 1px solid $lightgray-darken-8percent;
    border-radius: $border-radius-small;
}

.radio .checkbox-radio-indicator {
    border-radius: 50%;
}

.checkbox-radio:hover input ~ .checkbox-radio-indicator,
.checkbox-radio input:focus ~ .checkbox-radio-indicator {
    background: $whiteblue;
}

.checkbox-radio input:focus ~ .checkbox-radio-indicator {
    background: $whiteblue;
    box-shadow: 0 0 0 2px $green;
}

.checkbox-radio input:checked ~ .checkbox-radio-indicator {
    background: $blue;
}

.checkbox-radio:hover input:not([disabled]):checked ~ .checkbox-radio-indicator,
.checkbox-radio input:checked:focus ~ .checkbox-radio-indicator {
    background: $blue-darken-7percent;
}

.checkbox-radio input:disabled ~ .checkbox-radio-indicator {
    cursor: not-allowed;
    opacity: 0.6;
    background: $whitegray;
}

.checkbox-radio input:disabled:checked ~ .checkbox-radio-indicator {
    background: $blue;
}

.checkbox-radio-indicator:after {
    position: absolute;
    display: none;
    content: '';
}

.checkbox-radio input:checked ~ .checkbox-radio-indicator:after {
    display: block;
}

.checkbox .checkbox-radio-indicator:after {
    top: 10%;
    right: 10%;
    font-family: 'FontAwesome', sans-serif;
    content: "\f00c";
    color: white;
    font-size: $large-font-size;
}

.checkbox input.indeterminate ~ .checkbox-radio-indicator:after {
    display: block;
    top: -1px;
    left: 3px;
    font-family: 'FontAwesome', sans-serif;
    content: "\f068";
    color: $blue;
    font-weight: bold;
}


.radio .checkbox-radio-indicator:after {
    top: 5px;
    left: 5px;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: $white;
}


/*Spacing for checkboxes*/
bh-r-checkbox,
.checkbox-container {
    display: block;

    &:first-of-type {
        padding-top: 24px;
    }

    &:last-of-type {
        padding-bottom: 17px;
    }
}

//if in table
td bh-r-checkbox:last-of-type {
    padding-bottom: 8px;
}

bh-r-checkbox.no-label {
    padding-top: 0;
    margin-top: 0;
}

bh-r-checkbox:first-of-type .checkbox-radio {
    margin-top: 0;
}

//select all checkbox - listview
.checkbox-selectall {
    border-bottom: 1px solid $lightgray;
    margin: 15px 0 5px;
    padding-bottom: 10px;
    font-weight: bold;
    color: $blue;
}

//
a:hover.checkbox-radio.checkbox {
    color: $darkblue;
}

a.checkbox-radio.checkbox {
    text-decoration: none;
}

span.radio-label,
span.checkbox-label {
    display: block;
    margin-bottom: 4px;
}


/*Date with options Control*/

bh-r-radio.date-options,
.bh-r-radio.date-options {

    div {
        margin-left: 10px;
        display: inline-block;

        label {
            margin: 0;
            padding-left: 25px;
            font-size: 0.8125rem;

            .checkbox-radio-indicator {
                top: -1px;
                width: 18px;
                height: 18px;

                &:after {
                    top: 3px;
                    left: 3px;
                }
            }
        }
    }
}

bh-r-date.date-options,
bh-r-dropdown.date-options,
bh-r-search-and-select.date-options,
.bh-r-date.date-options,
.bh-r-dropdown.date-options,
.bh-r-search-and-select.date-options {
    display: block;
    margin-top: -17px;
}

/*Date Field bootstrap overrides*/
@media screen and (-webkit-min-device-pixel-ratio: 0) {
    input[type="date"].form-control,
    input[type="time"].form-control,
    input[type="datetime-local"].form-control,
    input[type="month"].form-control,
    input[type="date"].input-sm,
    input[type="time"].input-sm,
    input[type="datetime-local"].input-sm,
    input[type="month"].input-sm,
    .input-group-sm input[type="date"],
    .input-group-sm input[type="time"],
    .input-group-sm input[type="datetime-local"],
    .input-group-sm input[type="month"],
    input[type="date"].input-lg,
    input[type="time"].input-lg,
    input[type="datetime-local"].input-lg,
    input[type="month"].input-lg,
    .input-group-lg input[type="date"],
    .input-group-lg input[type="time"],
    .input-group-lg input[type="datetime-local"],
    .input-group-lg input[type="month"] {
        line-height: normal;
    }
}


/*Number range*/

span.number-range {
    float: left;

    & input {
        width: 40px;
        height: 32px;
        text-align: center;
        border: 1px solid $lightgray;
        margin: 0 3px;

        &:focus {
            border: 1px solid $blue;
            outline: none;
        }
    }
}

/*Range Input*/

input[type=range] {
    -webkit-appearance: none;
    width: 100%;
    height: 18px;
    margin: 6px 0;
}

input[type=range]:focus {
    outline: none;
}

input[type=range]::-webkit-slider-runnable-track {
    width: 100%;
    height: 6px;
    cursor: pointer;
    background: $whitegray;
}

input[type=range]::-webkit-slider-thumb {
    height: 18px;
    width: 10px;
    background: $blue;
    cursor: pointer;
    -webkit-appearance: none;
    margin-top: -6px;
}

input[type=range]:focus::-webkit-slider-runnable-track {
    background: $whiteblue;
}

input[type=range]::-moz-range-track {
    width: 100%;
    height: 6px;
    cursor: pointer;
    background: $whitegray;
}

input[type=range]::-moz-range-thumb {
    height: 18px;
    width: 10px;
    background: $blue;
    cursor: pointer;
}

//IE/Edge range input
input[type=range]::-ms-track {
    width: 100%;
    height: 6px;
    cursor: pointer;
    background: transparent;
    border-color: transparent;
    color: transparent;
}

input[type=range]::-ms-fill-lower {
    background: $whitegray-darken-5percent;
}

input[type=range]::-ms-fill-upper {
    background: $whitegray;
}

input[type=range]::-ms-thumb {
    height: 18px;
    width: 10px;
    background: $blue;
    cursor: pointer;
    margin-top: 2px;
}

input[type=range]:focus::-ms-fill-lower {
    background: $whiteblue-darken-6percent;
}

input[type=range]:focus::-ms-fill-upper {
    background: $whiteblue;
}


/*ng2 DateTime Picker*/

ng2-datetime-picker {
    width: 234px !important;
    box-shadow: $box-shadow-3;
    border: 1px solid $blue;
}

.datepicker-label.disable-dropdown ng2-datetime-picker {
    display: none;
}

.ng2-datetime-picker {
    color: $gray !important;

    & > .month {
        background-color: $blue !important;
        color: white !important;
        line-height: 20px !important;

        & > .prev_next {
            color: $lightblue !important;
            position: relative;
            top: -6px;
            width: 20px !important;
            font-size: 1.6875rem !important;

            &:hover, &:focus {
                background-color: white !important;
                color: $blue !important;
            }
        }
    }

    & > .days {
        margin-top: 5px !important;

        & .day-of-week,
        & .day-of-week.weekend {
            color: $blue !important;
        }

        & .day,
        & .day.weekend {
            color: $gray !important;
            line-height: 26px !important;

            &:not(.selected).selectable:hover {
                background: $blue !important;
                color: white !important;
            }
        }

        & .day:not(.selectable) {
            color: $whitegray-darken-8percent !important;
        }

        & .day.weekend {
            background-color: $whitegray-lighten-2percent !important;
        }

        & .day.selected,
        & .day.selected:hover,
        & .day.selected:focus {
            background: $green !important;
            color: white !important;
        }
    }

    & #time {
        margin: 0 !important;
        padding: 10px;
        border-top: 1px solid $lightgray;
        width: 230px;

        & .timeLabel {
            width: auto !important;
            margin-right: 3px;
            margin-bottom: 7px !important;
        }

        & .timeValue {
            color: $green;
            font-weight: bold;
        }

        & .hourLabel,
        & .minutesLabel {
            float: left;
            margin: 6px 0 !important;
        }

        & input[type=range] {
            float: right;
            width: 160px !important;
        }
    }
}

/* Form Buttons with Icons */

.input-group {
    display: inline-table;
}

.input-wrap {
    display: table;
}

.input-group-btn,
.input-group-append {
    vertical-align: bottom;

    & .btn {
        font-size: 1.25rem;
        padding: 0;
        width: 38px;
        height: 36px;
        border-radius: 0;
        border-bottom: 3px solid $blue-darken-7percent;

        &.fa-caret-down,
        &.fa-caret-up {
            font-size: 1.625rem;
        }

        &.fa-plus {
            padding-top: 2px;
        }
    }

    & .dropdown-menu {
        left: auto;
        right: 0;
        padding: 15px 10px 10px;
        margin-top: 0;
    }
}

.input-group-append {
    padding: 0;
    background: none;
    border: none;

    & .dropdown-menu {
        width: 100%;

        & label {
            float: left;
        }
    }

    &.textarea {

        & .btn {
            height: 100%;
            min-height: 90px;
        }
    }
}

//Specfic Inputs

.ties-search-dropdown label,
.ties-search-dropdown label .form-control {
    margin: 0;
}

.iconpicker-icon {
    margin-right: 12px;
    font-weight: normal;
    height: 18px;
}

//Icon indicators

.icon-right,
.icon-left,
.ng2-datetime-picker-wrapper {

    & + span {
        font-size: 1.25rem;
        color: $blue;
        z-index: 50;
        position: relative;
        pointer-events: none;

        &.fa-caret-down,
        &.fa-caret-up
        {
            font-size: 1.625rem;
            bottom: 5px;
        }

        &.fa-check,
        &.fa-envelope-o,
        &.fa-unlock-alt {
            font-size: 1.125rem;
            bottom: 9px;
        }

        &.fa-usd,
        &.fa-share,
        &.fa-exclamation-triangle,
        &.fa-phone,
        &.fa-globe {
            font-size: 1.0625rem;
            bottom: 10px;
        }

        &.fa-percent {
            font-size: 1rem;
            top: 13px;
        }
    }
}

.input-container {
    width: 100%;
    position: relative;
}

.icon-right {
    padding-right: 32px !important;

    & + span {
        position: absolute;
        right: 10px;
        //top: 10px;
    }
}

bh-mega-picker .icon-right, .select-input .icon-right{
    & + span {
        top: unset;
    }
}

.icon-left {
    padding-left: 32px !important;

    & + span {
        position: absolute;
        left: 10px;
        bottom: 8px;
    }
}

.ng2-datetime-picker-wrapper {

    & .icon-right {
        padding-right: 42px;
    }

    & + span {
        position: absolute;
        right: 10px;
        bottom: 8px;
    }
}

.form-control-sm {

    .icon-right,
    .icon-left,
    .ng2-datetime-picker-wrapper {

        & + span {
            font-size: 0.875rem;

            &.fa-caret-down,
            &.fa-caret-up {
                font-size: 1.375rem;
            }
        }
    }

    .icon-right,
    .ng2-datetime-picker-wrapper {
        padding-right: 28px !important;
    }

    .icon-left {
        padding-left: 28px !important;
    }

    .fa {
        margin: 0 !important;
        padding: 0 !important;
    }
}

bh-r-name .dropdown-menu .fa-caret-down {
    top: 31px;
    right: 16px;
}

.hide-icon {
    .icon-right,
    .icon-left {
        padding: 4px 12px 0 !important;

        & + span {
            display: none;
        }
    }
}

.primary-icon:before {
    font-family: 'FontAwesome', sans-serif;
    content: "\f00c";
    color: $green;
    font-size: 0.875rem;
    margin-left: 5px;
    line-height: normal;
}

.info-icon:before {
    font-family: 'FontAwesome', sans-serif;
    content: "\f05a";
    color: $blue;
    font-size: 1rem;
    margin-left: 8px;
    line-height: normal;
}

.form-box-container { //add to row
    border: 1px solid $lightgray;
    padding-top: 15px;
    margin: 0 0 15px 0;
}

//STATES

.form-success {
    background-color: $lightgreen !important;
}

.form-error {
    background-color: $lightred !important;
}

.form-warning {
    background-color: $yellow-lighten-27percent !important;
}


.bdf-icon:before {
    font-family: 'FontAwesome', sans-serif;
    content: "\f19c";
    color: $green;
    margin-left: 8px;
}


//VALIDATION

.required-icon:before {
    font-family: 'FontAwesome', sans-serif;
    content: "\f069";
    color: $red;
    font-size: 0.5rem;
    margin-left: 5px;
    position: relative;
    bottom: 4px;
}


.errorMessage {
    display: none;
    color: $red;
    background-color: $lightred;
    border: 1px solid $red;
    width: 100%;
    padding: 3px 8px;
    font-size: $font-size-small;
    border-radius: $border-radius-small;
    z-index: 1000;
    margin-top: 5px;
    font-weight: bold;
    position: relative;
    left: 0;
    line-height: $line-height-base;

    & p {
        margin: 0;

        & i {
            font-weight: normal;
            font-size: $font-size-base;
            margin-right: 7px;
        }
    }
}

.form-control.ng-dirty:focus ~ .errorMessage {
    display: inline-block;
}

.input-group .form-control.ng-dirty:focus ~ .errorMessage {
    //bottom: -28px;
}

.wj-control.ng-dirty.wj-state-focused ~ .errorMessage /*wijmo*/ {
    display: block;
}


.datepicker-label {
    &.focus .errorMessage {
        display: inline-block;
    }
}

//force error message to show without focus
.force-errorMessage .form-control.ng-dirty ~ .errorMessage {
    display: inline-block;
}

.ng-valid,
.ng-valid .wj-form-control /*wijmo*/ {
    border-color: $lightgray;
}

.form-control:not(.parsed-input).ng-valid:not(.ng-invalid).ng-dirty:focus,
.ng-valid.ng-dirty + input:focus /*hidden inputs*/,
.wj-control.ng-valid.ng-dirty.wj-state-focused .wj-form-control /*wijmo*/ {
    border-color: $green;
}

.ng-invalid,
.ng-invalid .wj-form-control /*wijmo*/ {
    border-color: $lightgray;

    &.ng-touched,
    &.ng-touched + input /*hidden inputs*/ {
        border-color: $red;
    }
}

.wj-control.ng-invalid.ng-dirty .wj-form-control, /*wijmo*/
.wj-control.ng-invalid.wj-state-focused .wj-form-control /*wijmo*/ {
    border-color: $red;
}

.form-control.ng-invalid:focus,
.ng-invalid + input:focus /*hidden inputs*/ {
    border-color: $red;
}

.header-panel .form-control.ng-valid.ng-dirty:focus,
table .form-control.ng-valid.ng-dirty:focus {
    border-color: $lightgray;
}

//Hide yellow background of prefilled login info
input:-webkit-autofill,
input:-webkit-autofill:focus {
    -webkit-box-shadow: 0 0 0 1000px white inset;
    box-shadow: 0 0 0 1000px white inset;
}


//Fix for Zooming in browser subpixel rendering of form layouts - add only if issue

.zoom-layout-fix div[class^=col-] {
    height: 77px;
}

// generic class for things that are clickable
.pointer {
    cursor: pointer;
}

.input-group .form-control {
    position: relative;
    z-index: 2;
    float: left;
    margin-bottom: 0;
}

.input-group-append {
    margin-top: 4px;
    border-top-right-radius: 0;
    border-bottom-left-radius: 0;
}

.force-errorMessage .editor-label .ng-dirty ~ .errorMessage {
    display: inline-block;
}

.filter {
    .form-control {
        max-width: 192px;
    }

    .form-control-sm {
        padding-right: 0;
    }
}

bh-booking-v2 .ng-invalid,
bh-booking-v2 .ng-invalid .wj-form-control {
    border-color: $red;
}
