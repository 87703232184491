@import 'custom-variables';

.toast-message {
    position: fixed;
    background: $blue;
    right: 16px;
    top: 12px;
    width: auto;
    height: auto;
    z-index: 3000;
    padding: 16px 45px 16px 16px;
    color: white;
    border-radius: 3px;
    font-size: 0.9375rem;
    box-shadow: $box-shadow-2;

    i {
        margin-right: 8px;
    }

    button {
        position: absolute;
        top: 5px;
        right: 0;

        .fa {
            font-size: 1rem;
        }
    }

    &.error {
        background: $red;

        .fa-exclamation-triangle {
            color: $red-lighten-50percent;
        }
    }

    &.success {
        background: $green;

        .fa-check {
            color: $green-lighten-40percent;
        }
    }
}

.modal.show, .modal.open {
    background-color: rgba(0, 0, 0, 0.6666666667);;
}

.modal-dialog.notification .modal-content {
    text-align: center;

    .modal-icon {
        padding: 8px;
        background-color: white;
        width: 46px;
        height: 46px;
        margin: -23px auto;
        border-radius: 50%;
        box-shadow: $box-shadow-2;

        &.error {
            background-color: $lightred;

            .fa {
                color: $red;
            }
        }

        &.question {
            background-color: $whiteblue;

            .fa {
                color: $blue;
                font-size: 2.3em;
            }
        }
    }

    .modal-body {
        padding-top: 40px;
    }

    .modal-footer {
        border-top: none;
        padding-top: 3px;
        text-align: center;

        button {
            min-width: 60px;
        }
    }
}
