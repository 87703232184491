@charset "UTF-8";
.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.float-start {
  float: left !important;
}

.float-end {
  float: right !important;
}

.float-none {
  float: none !important;
}

.opacity-0 {
  opacity: 0 !important;
}

.opacity-25 {
  opacity: 0.25 !important;
}

.opacity-50 {
  opacity: 0.5 !important;
}

.opacity-75 {
  opacity: 0.75 !important;
}

.opacity-100 {
  opacity: 1 !important;
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.overflow-visible {
  overflow: visible !important;
}

.overflow-scroll {
  overflow: scroll !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-grid {
  display: grid !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

.d-none {
  display: none !important;
}

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}

.shadow-none {
  box-shadow: none !important;
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: sticky !important;
}

.top-0 {
  top: 0 !important;
}

.top-50 {
  top: 50% !important;
}

.top-100 {
  top: 100% !important;
}

.bottom-0 {
  bottom: 0 !important;
}

.bottom-50 {
  bottom: 50% !important;
}

.bottom-100 {
  bottom: 100% !important;
}

.start-0 {
  left: 0 !important;
}

.start-50 {
  left: 50% !important;
}

.start-100 {
  left: 100% !important;
}

.end-0 {
  right: 0 !important;
}

.end-50 {
  right: 50% !important;
}

.end-100 {
  right: 100% !important;
}

.translate-middle {
  transform: translate(-50%, -50%) !important;
}

.translate-middle-x {
  transform: translateX(-50%) !important;
}

.translate-middle-y {
  transform: translateY(-50%) !important;
}

.border {
  border: 1px solid #dee2e6 !important;
}

.border-0 {
  border: 0 !important;
}

.border-top {
  border-top: 1px solid #dee2e6 !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-end {
  border-right: 1px solid #dee2e6 !important;
}

.border-end-0 {
  border-right: 0 !important;
}

.border-bottom {
  border-bottom: 1px solid #dee2e6 !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-start {
  border-left: 1px solid #dee2e6 !important;
}

.border-start-0 {
  border-left: 0 !important;
}

.border-primary {
  border-color: #0d6efd !important;
}

.border-secondary {
  border-color: #6c757d !important;
}

.border-success {
  border-color: #198754 !important;
}

.border-info {
  border-color: #0dcaf0 !important;
}

.border-warning {
  border-color: #ffc107 !important;
}

.border-danger {
  border-color: #dc3545 !important;
}

.border-light {
  border-color: #f8f9fa !important;
}

.border-dark {
  border-color: #212529 !important;
}

.border-white {
  border-color: #fff !important;
}

.border-1 {
  border-width: 1px !important;
}

.border-2 {
  border-width: 2px !important;
}

.border-3 {
  border-width: 3px !important;
}

.border-4 {
  border-width: 4px !important;
}

.border-5 {
  border-width: 5px !important;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.mw-100 {
  max-width: 100% !important;
}

.vw-100 {
  width: 100vw !important;
}

.min-vw-100 {
  min-width: 100vw !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.mh-100 {
  max-height: 100% !important;
}

.vh-100 {
  height: 100vh !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.gap-0 {
  gap: 0 !important;
}

.gap-1 {
  gap: 0.25rem !important;
}

.gap-2 {
  gap: 0.5rem !important;
}

.gap-3 {
  gap: 1rem !important;
}

.gap-4 {
  gap: 1.5rem !important;
}

.gap-5 {
  gap: 3rem !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.justify-content-evenly {
  justify-content: space-evenly !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

.order-first {
  order: -1 !important;
}

.order-0 {
  order: 0 !important;
}

.order-1 {
  order: 1 !important;
}

.order-2 {
  order: 2 !important;
}

.order-3 {
  order: 3 !important;
}

.order-4 {
  order: 4 !important;
}

.order-5 {
  order: 5 !important;
}

.order-last {
  order: 6 !important;
}

.m-0 {
  margin: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.m-auto {
  margin: auto !important;
}

.mx-0 {
  margin-right: 0 !important;
  margin-left: 0 !important;
}

.mx-1 {
  margin-right: 0.25rem !important;
  margin-left: 0.25rem !important;
}

.mx-2 {
  margin-right: 0.5rem !important;
  margin-left: 0.5rem !important;
}

.mx-3 {
  margin-right: 1rem !important;
  margin-left: 1rem !important;
}

.mx-4 {
  margin-right: 1.5rem !important;
  margin-left: 1.5rem !important;
}

.mx-5 {
  margin-right: 3rem !important;
  margin-left: 3rem !important;
}

.mx-auto {
  margin-right: auto !important;
  margin-left: auto !important;
}

.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.my-1 {
  margin-top: 0.25rem !important;
  margin-bottom: 0.25rem !important;
}

.my-2 {
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important;
}

.my-3 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}

.my-4 {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important;
}

.my-5 {
  margin-top: 3rem !important;
  margin-bottom: 3rem !important;
}

.my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.mt-1 {
  margin-top: 0.25rem !important;
}

.mt-2 {
  margin-top: 0.5rem !important;
}

.mt-3 {
  margin-top: 1rem !important;
}

.mt-4 {
  margin-top: 1.5rem !important;
}

.mt-5 {
  margin-top: 3rem !important;
}

.mt-auto {
  margin-top: auto !important;
}

.me-0 {
  margin-right: 0 !important;
}

.me-1 {
  margin-right: 0.25rem !important;
}

.me-2 {
  margin-right: 0.5rem !important;
}

.me-3 {
  margin-right: 1rem !important;
}

.me-4 {
  margin-right: 1.5rem !important;
}

.me-5 {
  margin-right: 3rem !important;
}

.me-auto {
  margin-right: auto !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mb-1 {
  margin-bottom: 0.25rem !important;
}

.mb-2 {
  margin-bottom: 0.5rem !important;
}

.mb-3 {
  margin-bottom: 1rem !important;
}

.mb-4 {
  margin-bottom: 1.5rem !important;
}

.mb-5 {
  margin-bottom: 3rem !important;
}

.mb-auto {
  margin-bottom: auto !important;
}

.ms-0 {
  margin-left: 0 !important;
}

.ms-1 {
  margin-left: 0.25rem !important;
}

.ms-2 {
  margin-left: 0.5rem !important;
}

.ms-3 {
  margin-left: 1rem !important;
}

.ms-4 {
  margin-left: 1.5rem !important;
}

.ms-5 {
  margin-left: 3rem !important;
}

.ms-auto {
  margin-left: auto !important;
}

.p-0 {
  padding: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.px-0 {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.px-1 {
  padding-right: 0.25rem !important;
  padding-left: 0.25rem !important;
}

.px-2 {
  padding-right: 0.5rem !important;
  padding-left: 0.5rem !important;
}

.px-3 {
  padding-right: 1rem !important;
  padding-left: 1rem !important;
}

.px-4 {
  padding-right: 1.5rem !important;
  padding-left: 1.5rem !important;
}

.px-5 {
  padding-right: 3rem !important;
  padding-left: 3rem !important;
}

.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.py-1 {
  padding-top: 0.25rem !important;
  padding-bottom: 0.25rem !important;
}

.py-2 {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
}

.py-3 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}

.py-4 {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important;
}

.py-5 {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important;
}

.pt-0 {
  padding-top: 0 !important;
}

.pt-1 {
  padding-top: 0.25rem !important;
}

.pt-2 {
  padding-top: 0.5rem !important;
}

.pt-3 {
  padding-top: 1rem !important;
}

.pt-4 {
  padding-top: 1.5rem !important;
}

.pt-5 {
  padding-top: 3rem !important;
}

.pe-0 {
  padding-right: 0 !important;
}

.pe-1 {
  padding-right: 0.25rem !important;
}

.pe-2 {
  padding-right: 0.5rem !important;
}

.pe-3 {
  padding-right: 1rem !important;
}

.pe-4 {
  padding-right: 1.5rem !important;
}

.pe-5 {
  padding-right: 3rem !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.pb-1 {
  padding-bottom: 0.25rem !important;
}

.pb-2 {
  padding-bottom: 0.5rem !important;
}

.pb-3 {
  padding-bottom: 1rem !important;
}

.pb-4 {
  padding-bottom: 1.5rem !important;
}

.pb-5 {
  padding-bottom: 3rem !important;
}

.ps-0 {
  padding-left: 0 !important;
}

.ps-1 {
  padding-left: 0.25rem !important;
}

.ps-2 {
  padding-left: 0.5rem !important;
}

.ps-3 {
  padding-left: 1rem !important;
}

.ps-4 {
  padding-left: 1.5rem !important;
}

.ps-5 {
  padding-left: 3rem !important;
}

.font-monospace {
  font-family: var(--bs-font-monospace) !important;
}

.fs-1 {
  font-size: calc(1.375rem + 1.5vw) !important;
}

.fs-2 {
  font-size: calc(1.325rem + 0.9vw) !important;
}

.fs-3 {
  font-size: calc(1.3rem + 0.6vw) !important;
}

.fs-4 {
  font-size: calc(1.275rem + 0.3vw) !important;
}

.fs-5 {
  font-size: 1.25rem !important;
}

.fs-6 {
  font-size: 1rem !important;
}

.fst-italic {
  font-style: italic !important;
}

.fst-normal {
  font-style: normal !important;
}

.fw-light {
  font-weight: 300 !important;
}

.fw-lighter {
  font-weight: lighter !important;
}

.fw-normal {
  font-weight: 400 !important;
}

.fw-bold {
  font-weight: 700 !important;
}

.fw-bolder {
  font-weight: bolder !important;
}

.lh-1 {
  line-height: 1 !important;
}

.lh-sm {
  line-height: 1.25 !important;
}

.lh-base {
  line-height: 1.5 !important;
}

.lh-lg {
  line-height: 2 !important;
}

.text-start {
  text-align: left !important;
}

.text-end {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-decoration-underline {
  text-decoration: underline !important;
}

.text-decoration-line-through {
  text-decoration: line-through !important;
}

.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

/* rtl:begin:remove */
.text-break {
  word-wrap: break-word !important;
  word-break: break-word !important;
}

/* rtl:end:remove */
.text-primary {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-primary-rgb), var(--bs-text-opacity)) !important;
}

.text-secondary {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-secondary-rgb), var(--bs-text-opacity)) !important;
}

.text-success {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-success-rgb), var(--bs-text-opacity)) !important;
}

.text-info {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-info-rgb), var(--bs-text-opacity)) !important;
}

.text-warning {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-warning-rgb), var(--bs-text-opacity)) !important;
}

.text-danger {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-danger-rgb), var(--bs-text-opacity)) !important;
}

.text-light {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-light-rgb), var(--bs-text-opacity)) !important;
}

.text-dark {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-dark-rgb), var(--bs-text-opacity)) !important;
}

.text-black {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-black-rgb), var(--bs-text-opacity)) !important;
}

.text-white {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-white-rgb), var(--bs-text-opacity)) !important;
}

.text-body {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-body-rgb), var(--bs-text-opacity)) !important;
}

.text-muted {
  --bs-text-opacity: 1;
  color: #6c757d !important;
}

.text-black-50 {
  --bs-text-opacity: 1;
  color: rgba(0, 0, 0, 0.5) !important;
}

.text-white-50 {
  --bs-text-opacity: 1;
  color: rgba(255, 255, 255, 0.5) !important;
}

.text-reset {
  --bs-text-opacity: 1;
  color: inherit !important;
}

.text-opacity-25 {
  --bs-text-opacity: 0.25;
}

.text-opacity-50 {
  --bs-text-opacity: 0.5;
}

.text-opacity-75 {
  --bs-text-opacity: 0.75;
}

.text-opacity-100 {
  --bs-text-opacity: 1;
}

.bg-primary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-primary-rgb), var(--bs-bg-opacity)) !important;
}

.bg-secondary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-secondary-rgb), var(--bs-bg-opacity)) !important;
}

.bg-success {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-success-rgb), var(--bs-bg-opacity)) !important;
}

.bg-info {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-info-rgb), var(--bs-bg-opacity)) !important;
}

.bg-warning {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-warning-rgb), var(--bs-bg-opacity)) !important;
}

.bg-danger {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-danger-rgb), var(--bs-bg-opacity)) !important;
}

.bg-light {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-light-rgb), var(--bs-bg-opacity)) !important;
}

.bg-dark {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-dark-rgb), var(--bs-bg-opacity)) !important;
}

.bg-black {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-black-rgb), var(--bs-bg-opacity)) !important;
}

.bg-white {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-white-rgb), var(--bs-bg-opacity)) !important;
}

.bg-body {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-body-rgb), var(--bs-bg-opacity)) !important;
}

.bg-transparent {
  --bs-bg-opacity: 1;
  background-color: transparent !important;
}

.bg-opacity-10 {
  --bs-bg-opacity: 0.1;
}

.bg-opacity-25 {
  --bs-bg-opacity: 0.25;
}

.bg-opacity-50 {
  --bs-bg-opacity: 0.5;
}

.bg-opacity-75 {
  --bs-bg-opacity: 0.75;
}

.bg-opacity-100 {
  --bs-bg-opacity: 1;
}

.bg-gradient {
  background-image: var(--bs-gradient) !important;
}

.user-select-all {
  user-select: all !important;
}

.user-select-auto {
  user-select: auto !important;
}

.user-select-none {
  user-select: none !important;
}

.pe-none {
  pointer-events: none !important;
}

.pe-auto {
  pointer-events: auto !important;
}

.rounded {
  border-radius: 0.25rem !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.rounded-1 {
  border-radius: 0.2rem !important;
}

.rounded-2 {
  border-radius: 0.25rem !important;
}

.rounded-3 {
  border-radius: 0.3rem !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-pill {
  border-radius: 50rem !important;
}

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important;
}

.rounded-end {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important;
}

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

.rounded-start {
  border-bottom-left-radius: 0.25rem !important;
  border-top-left-radius: 0.25rem !important;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

@media (min-width: 576px) {
  .float-sm-start {
    float: left !important;
  }
  .float-sm-end {
    float: right !important;
  }
  .float-sm-none {
    float: none !important;
  }
  .d-sm-inline {
    display: inline !important;
  }
  .d-sm-inline-block {
    display: inline-block !important;
  }
  .d-sm-block {
    display: block !important;
  }
  .d-sm-grid {
    display: grid !important;
  }
  .d-sm-table {
    display: table !important;
  }
  .d-sm-table-row {
    display: table-row !important;
  }
  .d-sm-table-cell {
    display: table-cell !important;
  }
  .d-sm-flex {
    display: flex !important;
  }
  .d-sm-inline-flex {
    display: inline-flex !important;
  }
  .d-sm-none {
    display: none !important;
  }
  .flex-sm-fill {
    flex: 1 1 auto !important;
  }
  .flex-sm-row {
    flex-direction: row !important;
  }
  .flex-sm-column {
    flex-direction: column !important;
  }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .gap-sm-0 {
    gap: 0 !important;
  }
  .gap-sm-1 {
    gap: 0.25rem !important;
  }
  .gap-sm-2 {
    gap: 0.5rem !important;
  }
  .gap-sm-3 {
    gap: 1rem !important;
  }
  .gap-sm-4 {
    gap: 1.5rem !important;
  }
  .gap-sm-5 {
    gap: 3rem !important;
  }
  .justify-content-sm-start {
    justify-content: flex-start !important;
  }
  .justify-content-sm-end {
    justify-content: flex-end !important;
  }
  .justify-content-sm-center {
    justify-content: center !important;
  }
  .justify-content-sm-between {
    justify-content: space-between !important;
  }
  .justify-content-sm-around {
    justify-content: space-around !important;
  }
  .justify-content-sm-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-sm-start {
    align-items: flex-start !important;
  }
  .align-items-sm-end {
    align-items: flex-end !important;
  }
  .align-items-sm-center {
    align-items: center !important;
  }
  .align-items-sm-baseline {
    align-items: baseline !important;
  }
  .align-items-sm-stretch {
    align-items: stretch !important;
  }
  .align-content-sm-start {
    align-content: flex-start !important;
  }
  .align-content-sm-end {
    align-content: flex-end !important;
  }
  .align-content-sm-center {
    align-content: center !important;
  }
  .align-content-sm-between {
    align-content: space-between !important;
  }
  .align-content-sm-around {
    align-content: space-around !important;
  }
  .align-content-sm-stretch {
    align-content: stretch !important;
  }
  .align-self-sm-auto {
    align-self: auto !important;
  }
  .align-self-sm-start {
    align-self: flex-start !important;
  }
  .align-self-sm-end {
    align-self: flex-end !important;
  }
  .align-self-sm-center {
    align-self: center !important;
  }
  .align-self-sm-baseline {
    align-self: baseline !important;
  }
  .align-self-sm-stretch {
    align-self: stretch !important;
  }
  .order-sm-first {
    order: -1 !important;
  }
  .order-sm-0 {
    order: 0 !important;
  }
  .order-sm-1 {
    order: 1 !important;
  }
  .order-sm-2 {
    order: 2 !important;
  }
  .order-sm-3 {
    order: 3 !important;
  }
  .order-sm-4 {
    order: 4 !important;
  }
  .order-sm-5 {
    order: 5 !important;
  }
  .order-sm-last {
    order: 6 !important;
  }
  .m-sm-0 {
    margin: 0 !important;
  }
  .m-sm-1 {
    margin: 0.25rem !important;
  }
  .m-sm-2 {
    margin: 0.5rem !important;
  }
  .m-sm-3 {
    margin: 1rem !important;
  }
  .m-sm-4 {
    margin: 1.5rem !important;
  }
  .m-sm-5 {
    margin: 3rem !important;
  }
  .m-sm-auto {
    margin: auto !important;
  }
  .mx-sm-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-sm-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-sm-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-sm-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-sm-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-sm-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-sm-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-sm-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-sm-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-sm-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-sm-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-sm-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-sm-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-sm-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-sm-0 {
    margin-top: 0 !important;
  }
  .mt-sm-1 {
    margin-top: 0.25rem !important;
  }
  .mt-sm-2 {
    margin-top: 0.5rem !important;
  }
  .mt-sm-3 {
    margin-top: 1rem !important;
  }
  .mt-sm-4 {
    margin-top: 1.5rem !important;
  }
  .mt-sm-5 {
    margin-top: 3rem !important;
  }
  .mt-sm-auto {
    margin-top: auto !important;
  }
  .me-sm-0 {
    margin-right: 0 !important;
  }
  .me-sm-1 {
    margin-right: 0.25rem !important;
  }
  .me-sm-2 {
    margin-right: 0.5rem !important;
  }
  .me-sm-3 {
    margin-right: 1rem !important;
  }
  .me-sm-4 {
    margin-right: 1.5rem !important;
  }
  .me-sm-5 {
    margin-right: 3rem !important;
  }
  .me-sm-auto {
    margin-right: auto !important;
  }
  .mb-sm-0 {
    margin-bottom: 0 !important;
  }
  .mb-sm-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-sm-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-sm-3 {
    margin-bottom: 1rem !important;
  }
  .mb-sm-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-sm-5 {
    margin-bottom: 3rem !important;
  }
  .mb-sm-auto {
    margin-bottom: auto !important;
  }
  .ms-sm-0 {
    margin-left: 0 !important;
  }
  .ms-sm-1 {
    margin-left: 0.25rem !important;
  }
  .ms-sm-2 {
    margin-left: 0.5rem !important;
  }
  .ms-sm-3 {
    margin-left: 1rem !important;
  }
  .ms-sm-4 {
    margin-left: 1.5rem !important;
  }
  .ms-sm-5 {
    margin-left: 3rem !important;
  }
  .ms-sm-auto {
    margin-left: auto !important;
  }
  .p-sm-0 {
    padding: 0 !important;
  }
  .p-sm-1 {
    padding: 0.25rem !important;
  }
  .p-sm-2 {
    padding: 0.5rem !important;
  }
  .p-sm-3 {
    padding: 1rem !important;
  }
  .p-sm-4 {
    padding: 1.5rem !important;
  }
  .p-sm-5 {
    padding: 3rem !important;
  }
  .px-sm-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-sm-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-sm-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-sm-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-sm-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-sm-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .py-sm-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-sm-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-sm-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-sm-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-sm-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-sm-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .pt-sm-0 {
    padding-top: 0 !important;
  }
  .pt-sm-1 {
    padding-top: 0.25rem !important;
  }
  .pt-sm-2 {
    padding-top: 0.5rem !important;
  }
  .pt-sm-3 {
    padding-top: 1rem !important;
  }
  .pt-sm-4 {
    padding-top: 1.5rem !important;
  }
  .pt-sm-5 {
    padding-top: 3rem !important;
  }
  .pe-sm-0 {
    padding-right: 0 !important;
  }
  .pe-sm-1 {
    padding-right: 0.25rem !important;
  }
  .pe-sm-2 {
    padding-right: 0.5rem !important;
  }
  .pe-sm-3 {
    padding-right: 1rem !important;
  }
  .pe-sm-4 {
    padding-right: 1.5rem !important;
  }
  .pe-sm-5 {
    padding-right: 3rem !important;
  }
  .pb-sm-0 {
    padding-bottom: 0 !important;
  }
  .pb-sm-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-sm-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-sm-3 {
    padding-bottom: 1rem !important;
  }
  .pb-sm-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-sm-5 {
    padding-bottom: 3rem !important;
  }
  .ps-sm-0 {
    padding-left: 0 !important;
  }
  .ps-sm-1 {
    padding-left: 0.25rem !important;
  }
  .ps-sm-2 {
    padding-left: 0.5rem !important;
  }
  .ps-sm-3 {
    padding-left: 1rem !important;
  }
  .ps-sm-4 {
    padding-left: 1.5rem !important;
  }
  .ps-sm-5 {
    padding-left: 3rem !important;
  }
  .text-sm-start {
    text-align: left !important;
  }
  .text-sm-end {
    text-align: right !important;
  }
  .text-sm-center {
    text-align: center !important;
  }
}
@media (min-width: 768px) {
  .float-md-start {
    float: left !important;
  }
  .float-md-end {
    float: right !important;
  }
  .float-md-none {
    float: none !important;
  }
  .d-md-inline {
    display: inline !important;
  }
  .d-md-inline-block {
    display: inline-block !important;
  }
  .d-md-block {
    display: block !important;
  }
  .d-md-grid {
    display: grid !important;
  }
  .d-md-table {
    display: table !important;
  }
  .d-md-table-row {
    display: table-row !important;
  }
  .d-md-table-cell {
    display: table-cell !important;
  }
  .d-md-flex {
    display: flex !important;
  }
  .d-md-inline-flex {
    display: inline-flex !important;
  }
  .d-md-none {
    display: none !important;
  }
  .flex-md-fill {
    flex: 1 1 auto !important;
  }
  .flex-md-row {
    flex-direction: row !important;
  }
  .flex-md-column {
    flex-direction: column !important;
  }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-md-wrap {
    flex-wrap: wrap !important;
  }
  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .gap-md-0 {
    gap: 0 !important;
  }
  .gap-md-1 {
    gap: 0.25rem !important;
  }
  .gap-md-2 {
    gap: 0.5rem !important;
  }
  .gap-md-3 {
    gap: 1rem !important;
  }
  .gap-md-4 {
    gap: 1.5rem !important;
  }
  .gap-md-5 {
    gap: 3rem !important;
  }
  .justify-content-md-start {
    justify-content: flex-start !important;
  }
  .justify-content-md-end {
    justify-content: flex-end !important;
  }
  .justify-content-md-center {
    justify-content: center !important;
  }
  .justify-content-md-between {
    justify-content: space-between !important;
  }
  .justify-content-md-around {
    justify-content: space-around !important;
  }
  .justify-content-md-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-md-start {
    align-items: flex-start !important;
  }
  .align-items-md-end {
    align-items: flex-end !important;
  }
  .align-items-md-center {
    align-items: center !important;
  }
  .align-items-md-baseline {
    align-items: baseline !important;
  }
  .align-items-md-stretch {
    align-items: stretch !important;
  }
  .align-content-md-start {
    align-content: flex-start !important;
  }
  .align-content-md-end {
    align-content: flex-end !important;
  }
  .align-content-md-center {
    align-content: center !important;
  }
  .align-content-md-between {
    align-content: space-between !important;
  }
  .align-content-md-around {
    align-content: space-around !important;
  }
  .align-content-md-stretch {
    align-content: stretch !important;
  }
  .align-self-md-auto {
    align-self: auto !important;
  }
  .align-self-md-start {
    align-self: flex-start !important;
  }
  .align-self-md-end {
    align-self: flex-end !important;
  }
  .align-self-md-center {
    align-self: center !important;
  }
  .align-self-md-baseline {
    align-self: baseline !important;
  }
  .align-self-md-stretch {
    align-self: stretch !important;
  }
  .order-md-first {
    order: -1 !important;
  }
  .order-md-0 {
    order: 0 !important;
  }
  .order-md-1 {
    order: 1 !important;
  }
  .order-md-2 {
    order: 2 !important;
  }
  .order-md-3 {
    order: 3 !important;
  }
  .order-md-4 {
    order: 4 !important;
  }
  .order-md-5 {
    order: 5 !important;
  }
  .order-md-last {
    order: 6 !important;
  }
  .m-md-0 {
    margin: 0 !important;
  }
  .m-md-1 {
    margin: 0.25rem !important;
  }
  .m-md-2 {
    margin: 0.5rem !important;
  }
  .m-md-3 {
    margin: 1rem !important;
  }
  .m-md-4 {
    margin: 1.5rem !important;
  }
  .m-md-5 {
    margin: 3rem !important;
  }
  .m-md-auto {
    margin: auto !important;
  }
  .mx-md-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-md-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-md-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-md-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-md-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-md-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-md-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-md-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-md-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-md-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-md-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-md-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-md-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-md-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-md-0 {
    margin-top: 0 !important;
  }
  .mt-md-1 {
    margin-top: 0.25rem !important;
  }
  .mt-md-2 {
    margin-top: 0.5rem !important;
  }
  .mt-md-3 {
    margin-top: 1rem !important;
  }
  .mt-md-4 {
    margin-top: 1.5rem !important;
  }
  .mt-md-5 {
    margin-top: 3rem !important;
  }
  .mt-md-auto {
    margin-top: auto !important;
  }
  .me-md-0 {
    margin-right: 0 !important;
  }
  .me-md-1 {
    margin-right: 0.25rem !important;
  }
  .me-md-2 {
    margin-right: 0.5rem !important;
  }
  .me-md-3 {
    margin-right: 1rem !important;
  }
  .me-md-4 {
    margin-right: 1.5rem !important;
  }
  .me-md-5 {
    margin-right: 3rem !important;
  }
  .me-md-auto {
    margin-right: auto !important;
  }
  .mb-md-0 {
    margin-bottom: 0 !important;
  }
  .mb-md-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-md-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-md-3 {
    margin-bottom: 1rem !important;
  }
  .mb-md-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-md-5 {
    margin-bottom: 3rem !important;
  }
  .mb-md-auto {
    margin-bottom: auto !important;
  }
  .ms-md-0 {
    margin-left: 0 !important;
  }
  .ms-md-1 {
    margin-left: 0.25rem !important;
  }
  .ms-md-2 {
    margin-left: 0.5rem !important;
  }
  .ms-md-3 {
    margin-left: 1rem !important;
  }
  .ms-md-4 {
    margin-left: 1.5rem !important;
  }
  .ms-md-5 {
    margin-left: 3rem !important;
  }
  .ms-md-auto {
    margin-left: auto !important;
  }
  .p-md-0 {
    padding: 0 !important;
  }
  .p-md-1 {
    padding: 0.25rem !important;
  }
  .p-md-2 {
    padding: 0.5rem !important;
  }
  .p-md-3 {
    padding: 1rem !important;
  }
  .p-md-4 {
    padding: 1.5rem !important;
  }
  .p-md-5 {
    padding: 3rem !important;
  }
  .px-md-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-md-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-md-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-md-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-md-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-md-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .py-md-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-md-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-md-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-md-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-md-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-md-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .pt-md-0 {
    padding-top: 0 !important;
  }
  .pt-md-1 {
    padding-top: 0.25rem !important;
  }
  .pt-md-2 {
    padding-top: 0.5rem !important;
  }
  .pt-md-3 {
    padding-top: 1rem !important;
  }
  .pt-md-4 {
    padding-top: 1.5rem !important;
  }
  .pt-md-5 {
    padding-top: 3rem !important;
  }
  .pe-md-0 {
    padding-right: 0 !important;
  }
  .pe-md-1 {
    padding-right: 0.25rem !important;
  }
  .pe-md-2 {
    padding-right: 0.5rem !important;
  }
  .pe-md-3 {
    padding-right: 1rem !important;
  }
  .pe-md-4 {
    padding-right: 1.5rem !important;
  }
  .pe-md-5 {
    padding-right: 3rem !important;
  }
  .pb-md-0 {
    padding-bottom: 0 !important;
  }
  .pb-md-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-md-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-md-3 {
    padding-bottom: 1rem !important;
  }
  .pb-md-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-md-5 {
    padding-bottom: 3rem !important;
  }
  .ps-md-0 {
    padding-left: 0 !important;
  }
  .ps-md-1 {
    padding-left: 0.25rem !important;
  }
  .ps-md-2 {
    padding-left: 0.5rem !important;
  }
  .ps-md-3 {
    padding-left: 1rem !important;
  }
  .ps-md-4 {
    padding-left: 1.5rem !important;
  }
  .ps-md-5 {
    padding-left: 3rem !important;
  }
  .text-md-start {
    text-align: left !important;
  }
  .text-md-end {
    text-align: right !important;
  }
  .text-md-center {
    text-align: center !important;
  }
}
@media (min-width: 992px) {
  .float-lg-start {
    float: left !important;
  }
  .float-lg-end {
    float: right !important;
  }
  .float-lg-none {
    float: none !important;
  }
  .d-lg-inline {
    display: inline !important;
  }
  .d-lg-inline-block {
    display: inline-block !important;
  }
  .d-lg-block {
    display: block !important;
  }
  .d-lg-grid {
    display: grid !important;
  }
  .d-lg-table {
    display: table !important;
  }
  .d-lg-table-row {
    display: table-row !important;
  }
  .d-lg-table-cell {
    display: table-cell !important;
  }
  .d-lg-flex {
    display: flex !important;
  }
  .d-lg-inline-flex {
    display: inline-flex !important;
  }
  .d-lg-none {
    display: none !important;
  }
  .flex-lg-fill {
    flex: 1 1 auto !important;
  }
  .flex-lg-row {
    flex-direction: row !important;
  }
  .flex-lg-column {
    flex-direction: column !important;
  }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .gap-lg-0 {
    gap: 0 !important;
  }
  .gap-lg-1 {
    gap: 0.25rem !important;
  }
  .gap-lg-2 {
    gap: 0.5rem !important;
  }
  .gap-lg-3 {
    gap: 1rem !important;
  }
  .gap-lg-4 {
    gap: 1.5rem !important;
  }
  .gap-lg-5 {
    gap: 3rem !important;
  }
  .justify-content-lg-start {
    justify-content: flex-start !important;
  }
  .justify-content-lg-end {
    justify-content: flex-end !important;
  }
  .justify-content-lg-center {
    justify-content: center !important;
  }
  .justify-content-lg-between {
    justify-content: space-between !important;
  }
  .justify-content-lg-around {
    justify-content: space-around !important;
  }
  .justify-content-lg-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-lg-start {
    align-items: flex-start !important;
  }
  .align-items-lg-end {
    align-items: flex-end !important;
  }
  .align-items-lg-center {
    align-items: center !important;
  }
  .align-items-lg-baseline {
    align-items: baseline !important;
  }
  .align-items-lg-stretch {
    align-items: stretch !important;
  }
  .align-content-lg-start {
    align-content: flex-start !important;
  }
  .align-content-lg-end {
    align-content: flex-end !important;
  }
  .align-content-lg-center {
    align-content: center !important;
  }
  .align-content-lg-between {
    align-content: space-between !important;
  }
  .align-content-lg-around {
    align-content: space-around !important;
  }
  .align-content-lg-stretch {
    align-content: stretch !important;
  }
  .align-self-lg-auto {
    align-self: auto !important;
  }
  .align-self-lg-start {
    align-self: flex-start !important;
  }
  .align-self-lg-end {
    align-self: flex-end !important;
  }
  .align-self-lg-center {
    align-self: center !important;
  }
  .align-self-lg-baseline {
    align-self: baseline !important;
  }
  .align-self-lg-stretch {
    align-self: stretch !important;
  }
  .order-lg-first {
    order: -1 !important;
  }
  .order-lg-0 {
    order: 0 !important;
  }
  .order-lg-1 {
    order: 1 !important;
  }
  .order-lg-2 {
    order: 2 !important;
  }
  .order-lg-3 {
    order: 3 !important;
  }
  .order-lg-4 {
    order: 4 !important;
  }
  .order-lg-5 {
    order: 5 !important;
  }
  .order-lg-last {
    order: 6 !important;
  }
  .m-lg-0 {
    margin: 0 !important;
  }
  .m-lg-1 {
    margin: 0.25rem !important;
  }
  .m-lg-2 {
    margin: 0.5rem !important;
  }
  .m-lg-3 {
    margin: 1rem !important;
  }
  .m-lg-4 {
    margin: 1.5rem !important;
  }
  .m-lg-5 {
    margin: 3rem !important;
  }
  .m-lg-auto {
    margin: auto !important;
  }
  .mx-lg-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-lg-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-lg-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-lg-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-lg-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-lg-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-lg-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-lg-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-lg-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-lg-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-lg-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-lg-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-lg-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-lg-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-lg-0 {
    margin-top: 0 !important;
  }
  .mt-lg-1 {
    margin-top: 0.25rem !important;
  }
  .mt-lg-2 {
    margin-top: 0.5rem !important;
  }
  .mt-lg-3 {
    margin-top: 1rem !important;
  }
  .mt-lg-4 {
    margin-top: 1.5rem !important;
  }
  .mt-lg-5 {
    margin-top: 3rem !important;
  }
  .mt-lg-auto {
    margin-top: auto !important;
  }
  .me-lg-0 {
    margin-right: 0 !important;
  }
  .me-lg-1 {
    margin-right: 0.25rem !important;
  }
  .me-lg-2 {
    margin-right: 0.5rem !important;
  }
  .me-lg-3 {
    margin-right: 1rem !important;
  }
  .me-lg-4 {
    margin-right: 1.5rem !important;
  }
  .me-lg-5 {
    margin-right: 3rem !important;
  }
  .me-lg-auto {
    margin-right: auto !important;
  }
  .mb-lg-0 {
    margin-bottom: 0 !important;
  }
  .mb-lg-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-lg-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-lg-3 {
    margin-bottom: 1rem !important;
  }
  .mb-lg-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-lg-5 {
    margin-bottom: 3rem !important;
  }
  .mb-lg-auto {
    margin-bottom: auto !important;
  }
  .ms-lg-0 {
    margin-left: 0 !important;
  }
  .ms-lg-1 {
    margin-left: 0.25rem !important;
  }
  .ms-lg-2 {
    margin-left: 0.5rem !important;
  }
  .ms-lg-3 {
    margin-left: 1rem !important;
  }
  .ms-lg-4 {
    margin-left: 1.5rem !important;
  }
  .ms-lg-5 {
    margin-left: 3rem !important;
  }
  .ms-lg-auto {
    margin-left: auto !important;
  }
  .p-lg-0 {
    padding: 0 !important;
  }
  .p-lg-1 {
    padding: 0.25rem !important;
  }
  .p-lg-2 {
    padding: 0.5rem !important;
  }
  .p-lg-3 {
    padding: 1rem !important;
  }
  .p-lg-4 {
    padding: 1.5rem !important;
  }
  .p-lg-5 {
    padding: 3rem !important;
  }
  .px-lg-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-lg-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-lg-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-lg-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-lg-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-lg-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .py-lg-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-lg-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-lg-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-lg-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-lg-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-lg-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .pt-lg-0 {
    padding-top: 0 !important;
  }
  .pt-lg-1 {
    padding-top: 0.25rem !important;
  }
  .pt-lg-2 {
    padding-top: 0.5rem !important;
  }
  .pt-lg-3 {
    padding-top: 1rem !important;
  }
  .pt-lg-4 {
    padding-top: 1.5rem !important;
  }
  .pt-lg-5 {
    padding-top: 3rem !important;
  }
  .pe-lg-0 {
    padding-right: 0 !important;
  }
  .pe-lg-1 {
    padding-right: 0.25rem !important;
  }
  .pe-lg-2 {
    padding-right: 0.5rem !important;
  }
  .pe-lg-3 {
    padding-right: 1rem !important;
  }
  .pe-lg-4 {
    padding-right: 1.5rem !important;
  }
  .pe-lg-5 {
    padding-right: 3rem !important;
  }
  .pb-lg-0 {
    padding-bottom: 0 !important;
  }
  .pb-lg-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-lg-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-lg-3 {
    padding-bottom: 1rem !important;
  }
  .pb-lg-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-lg-5 {
    padding-bottom: 3rem !important;
  }
  .ps-lg-0 {
    padding-left: 0 !important;
  }
  .ps-lg-1 {
    padding-left: 0.25rem !important;
  }
  .ps-lg-2 {
    padding-left: 0.5rem !important;
  }
  .ps-lg-3 {
    padding-left: 1rem !important;
  }
  .ps-lg-4 {
    padding-left: 1.5rem !important;
  }
  .ps-lg-5 {
    padding-left: 3rem !important;
  }
  .text-lg-start {
    text-align: left !important;
  }
  .text-lg-end {
    text-align: right !important;
  }
  .text-lg-center {
    text-align: center !important;
  }
}
@media (min-width: 1200px) {
  .float-xl-start {
    float: left !important;
  }
  .float-xl-end {
    float: right !important;
  }
  .float-xl-none {
    float: none !important;
  }
  .d-xl-inline {
    display: inline !important;
  }
  .d-xl-inline-block {
    display: inline-block !important;
  }
  .d-xl-block {
    display: block !important;
  }
  .d-xl-grid {
    display: grid !important;
  }
  .d-xl-table {
    display: table !important;
  }
  .d-xl-table-row {
    display: table-row !important;
  }
  .d-xl-table-cell {
    display: table-cell !important;
  }
  .d-xl-flex {
    display: flex !important;
  }
  .d-xl-inline-flex {
    display: inline-flex !important;
  }
  .d-xl-none {
    display: none !important;
  }
  .flex-xl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xl-row {
    flex-direction: row !important;
  }
  .flex-xl-column {
    flex-direction: column !important;
  }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .gap-xl-0 {
    gap: 0 !important;
  }
  .gap-xl-1 {
    gap: 0.25rem !important;
  }
  .gap-xl-2 {
    gap: 0.5rem !important;
  }
  .gap-xl-3 {
    gap: 1rem !important;
  }
  .gap-xl-4 {
    gap: 1.5rem !important;
  }
  .gap-xl-5 {
    gap: 3rem !important;
  }
  .justify-content-xl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xl-center {
    justify-content: center !important;
  }
  .justify-content-xl-between {
    justify-content: space-between !important;
  }
  .justify-content-xl-around {
    justify-content: space-around !important;
  }
  .justify-content-xl-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-xl-start {
    align-items: flex-start !important;
  }
  .align-items-xl-end {
    align-items: flex-end !important;
  }
  .align-items-xl-center {
    align-items: center !important;
  }
  .align-items-xl-baseline {
    align-items: baseline !important;
  }
  .align-items-xl-stretch {
    align-items: stretch !important;
  }
  .align-content-xl-start {
    align-content: flex-start !important;
  }
  .align-content-xl-end {
    align-content: flex-end !important;
  }
  .align-content-xl-center {
    align-content: center !important;
  }
  .align-content-xl-between {
    align-content: space-between !important;
  }
  .align-content-xl-around {
    align-content: space-around !important;
  }
  .align-content-xl-stretch {
    align-content: stretch !important;
  }
  .align-self-xl-auto {
    align-self: auto !important;
  }
  .align-self-xl-start {
    align-self: flex-start !important;
  }
  .align-self-xl-end {
    align-self: flex-end !important;
  }
  .align-self-xl-center {
    align-self: center !important;
  }
  .align-self-xl-baseline {
    align-self: baseline !important;
  }
  .align-self-xl-stretch {
    align-self: stretch !important;
  }
  .order-xl-first {
    order: -1 !important;
  }
  .order-xl-0 {
    order: 0 !important;
  }
  .order-xl-1 {
    order: 1 !important;
  }
  .order-xl-2 {
    order: 2 !important;
  }
  .order-xl-3 {
    order: 3 !important;
  }
  .order-xl-4 {
    order: 4 !important;
  }
  .order-xl-5 {
    order: 5 !important;
  }
  .order-xl-last {
    order: 6 !important;
  }
  .m-xl-0 {
    margin: 0 !important;
  }
  .m-xl-1 {
    margin: 0.25rem !important;
  }
  .m-xl-2 {
    margin: 0.5rem !important;
  }
  .m-xl-3 {
    margin: 1rem !important;
  }
  .m-xl-4 {
    margin: 1.5rem !important;
  }
  .m-xl-5 {
    margin: 3rem !important;
  }
  .m-xl-auto {
    margin: auto !important;
  }
  .mx-xl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-xl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-xl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-xl-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-xl-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-xl-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-xl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-xl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-xl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-xl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-xl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-xl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-xl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-xl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-xl-0 {
    margin-top: 0 !important;
  }
  .mt-xl-1 {
    margin-top: 0.25rem !important;
  }
  .mt-xl-2 {
    margin-top: 0.5rem !important;
  }
  .mt-xl-3 {
    margin-top: 1rem !important;
  }
  .mt-xl-4 {
    margin-top: 1.5rem !important;
  }
  .mt-xl-5 {
    margin-top: 3rem !important;
  }
  .mt-xl-auto {
    margin-top: auto !important;
  }
  .me-xl-0 {
    margin-right: 0 !important;
  }
  .me-xl-1 {
    margin-right: 0.25rem !important;
  }
  .me-xl-2 {
    margin-right: 0.5rem !important;
  }
  .me-xl-3 {
    margin-right: 1rem !important;
  }
  .me-xl-4 {
    margin-right: 1.5rem !important;
  }
  .me-xl-5 {
    margin-right: 3rem !important;
  }
  .me-xl-auto {
    margin-right: auto !important;
  }
  .mb-xl-0 {
    margin-bottom: 0 !important;
  }
  .mb-xl-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-xl-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-xl-3 {
    margin-bottom: 1rem !important;
  }
  .mb-xl-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-xl-5 {
    margin-bottom: 3rem !important;
  }
  .mb-xl-auto {
    margin-bottom: auto !important;
  }
  .ms-xl-0 {
    margin-left: 0 !important;
  }
  .ms-xl-1 {
    margin-left: 0.25rem !important;
  }
  .ms-xl-2 {
    margin-left: 0.5rem !important;
  }
  .ms-xl-3 {
    margin-left: 1rem !important;
  }
  .ms-xl-4 {
    margin-left: 1.5rem !important;
  }
  .ms-xl-5 {
    margin-left: 3rem !important;
  }
  .ms-xl-auto {
    margin-left: auto !important;
  }
  .p-xl-0 {
    padding: 0 !important;
  }
  .p-xl-1 {
    padding: 0.25rem !important;
  }
  .p-xl-2 {
    padding: 0.5rem !important;
  }
  .p-xl-3 {
    padding: 1rem !important;
  }
  .p-xl-4 {
    padding: 1.5rem !important;
  }
  .p-xl-5 {
    padding: 3rem !important;
  }
  .px-xl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-xl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-xl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-xl-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-xl-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-xl-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .py-xl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-xl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-xl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-xl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-xl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-xl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .pt-xl-0 {
    padding-top: 0 !important;
  }
  .pt-xl-1 {
    padding-top: 0.25rem !important;
  }
  .pt-xl-2 {
    padding-top: 0.5rem !important;
  }
  .pt-xl-3 {
    padding-top: 1rem !important;
  }
  .pt-xl-4 {
    padding-top: 1.5rem !important;
  }
  .pt-xl-5 {
    padding-top: 3rem !important;
  }
  .pe-xl-0 {
    padding-right: 0 !important;
  }
  .pe-xl-1 {
    padding-right: 0.25rem !important;
  }
  .pe-xl-2 {
    padding-right: 0.5rem !important;
  }
  .pe-xl-3 {
    padding-right: 1rem !important;
  }
  .pe-xl-4 {
    padding-right: 1.5rem !important;
  }
  .pe-xl-5 {
    padding-right: 3rem !important;
  }
  .pb-xl-0 {
    padding-bottom: 0 !important;
  }
  .pb-xl-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-xl-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-xl-3 {
    padding-bottom: 1rem !important;
  }
  .pb-xl-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-xl-5 {
    padding-bottom: 3rem !important;
  }
  .ps-xl-0 {
    padding-left: 0 !important;
  }
  .ps-xl-1 {
    padding-left: 0.25rem !important;
  }
  .ps-xl-2 {
    padding-left: 0.5rem !important;
  }
  .ps-xl-3 {
    padding-left: 1rem !important;
  }
  .ps-xl-4 {
    padding-left: 1.5rem !important;
  }
  .ps-xl-5 {
    padding-left: 3rem !important;
  }
  .text-xl-start {
    text-align: left !important;
  }
  .text-xl-end {
    text-align: right !important;
  }
  .text-xl-center {
    text-align: center !important;
  }
}
@media (min-width: 1400px) {
  .float-xxl-start {
    float: left !important;
  }
  .float-xxl-end {
    float: right !important;
  }
  .float-xxl-none {
    float: none !important;
  }
  .d-xxl-inline {
    display: inline !important;
  }
  .d-xxl-inline-block {
    display: inline-block !important;
  }
  .d-xxl-block {
    display: block !important;
  }
  .d-xxl-grid {
    display: grid !important;
  }
  .d-xxl-table {
    display: table !important;
  }
  .d-xxl-table-row {
    display: table-row !important;
  }
  .d-xxl-table-cell {
    display: table-cell !important;
  }
  .d-xxl-flex {
    display: flex !important;
  }
  .d-xxl-inline-flex {
    display: inline-flex !important;
  }
  .d-xxl-none {
    display: none !important;
  }
  .flex-xxl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xxl-row {
    flex-direction: row !important;
  }
  .flex-xxl-column {
    flex-direction: column !important;
  }
  .flex-xxl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xxl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xxl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xxl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xxl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xxl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-xxl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xxl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xxl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .gap-xxl-0 {
    gap: 0 !important;
  }
  .gap-xxl-1 {
    gap: 0.25rem !important;
  }
  .gap-xxl-2 {
    gap: 0.5rem !important;
  }
  .gap-xxl-3 {
    gap: 1rem !important;
  }
  .gap-xxl-4 {
    gap: 1.5rem !important;
  }
  .gap-xxl-5 {
    gap: 3rem !important;
  }
  .justify-content-xxl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xxl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xxl-center {
    justify-content: center !important;
  }
  .justify-content-xxl-between {
    justify-content: space-between !important;
  }
  .justify-content-xxl-around {
    justify-content: space-around !important;
  }
  .justify-content-xxl-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-xxl-start {
    align-items: flex-start !important;
  }
  .align-items-xxl-end {
    align-items: flex-end !important;
  }
  .align-items-xxl-center {
    align-items: center !important;
  }
  .align-items-xxl-baseline {
    align-items: baseline !important;
  }
  .align-items-xxl-stretch {
    align-items: stretch !important;
  }
  .align-content-xxl-start {
    align-content: flex-start !important;
  }
  .align-content-xxl-end {
    align-content: flex-end !important;
  }
  .align-content-xxl-center {
    align-content: center !important;
  }
  .align-content-xxl-between {
    align-content: space-between !important;
  }
  .align-content-xxl-around {
    align-content: space-around !important;
  }
  .align-content-xxl-stretch {
    align-content: stretch !important;
  }
  .align-self-xxl-auto {
    align-self: auto !important;
  }
  .align-self-xxl-start {
    align-self: flex-start !important;
  }
  .align-self-xxl-end {
    align-self: flex-end !important;
  }
  .align-self-xxl-center {
    align-self: center !important;
  }
  .align-self-xxl-baseline {
    align-self: baseline !important;
  }
  .align-self-xxl-stretch {
    align-self: stretch !important;
  }
  .order-xxl-first {
    order: -1 !important;
  }
  .order-xxl-0 {
    order: 0 !important;
  }
  .order-xxl-1 {
    order: 1 !important;
  }
  .order-xxl-2 {
    order: 2 !important;
  }
  .order-xxl-3 {
    order: 3 !important;
  }
  .order-xxl-4 {
    order: 4 !important;
  }
  .order-xxl-5 {
    order: 5 !important;
  }
  .order-xxl-last {
    order: 6 !important;
  }
  .m-xxl-0 {
    margin: 0 !important;
  }
  .m-xxl-1 {
    margin: 0.25rem !important;
  }
  .m-xxl-2 {
    margin: 0.5rem !important;
  }
  .m-xxl-3 {
    margin: 1rem !important;
  }
  .m-xxl-4 {
    margin: 1.5rem !important;
  }
  .m-xxl-5 {
    margin: 3rem !important;
  }
  .m-xxl-auto {
    margin: auto !important;
  }
  .mx-xxl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-xxl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-xxl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-xxl-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-xxl-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-xxl-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-xxl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-xxl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-xxl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-xxl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-xxl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-xxl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-xxl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-xxl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-xxl-0 {
    margin-top: 0 !important;
  }
  .mt-xxl-1 {
    margin-top: 0.25rem !important;
  }
  .mt-xxl-2 {
    margin-top: 0.5rem !important;
  }
  .mt-xxl-3 {
    margin-top: 1rem !important;
  }
  .mt-xxl-4 {
    margin-top: 1.5rem !important;
  }
  .mt-xxl-5 {
    margin-top: 3rem !important;
  }
  .mt-xxl-auto {
    margin-top: auto !important;
  }
  .me-xxl-0 {
    margin-right: 0 !important;
  }
  .me-xxl-1 {
    margin-right: 0.25rem !important;
  }
  .me-xxl-2 {
    margin-right: 0.5rem !important;
  }
  .me-xxl-3 {
    margin-right: 1rem !important;
  }
  .me-xxl-4 {
    margin-right: 1.5rem !important;
  }
  .me-xxl-5 {
    margin-right: 3rem !important;
  }
  .me-xxl-auto {
    margin-right: auto !important;
  }
  .mb-xxl-0 {
    margin-bottom: 0 !important;
  }
  .mb-xxl-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-xxl-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-xxl-3 {
    margin-bottom: 1rem !important;
  }
  .mb-xxl-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-xxl-5 {
    margin-bottom: 3rem !important;
  }
  .mb-xxl-auto {
    margin-bottom: auto !important;
  }
  .ms-xxl-0 {
    margin-left: 0 !important;
  }
  .ms-xxl-1 {
    margin-left: 0.25rem !important;
  }
  .ms-xxl-2 {
    margin-left: 0.5rem !important;
  }
  .ms-xxl-3 {
    margin-left: 1rem !important;
  }
  .ms-xxl-4 {
    margin-left: 1.5rem !important;
  }
  .ms-xxl-5 {
    margin-left: 3rem !important;
  }
  .ms-xxl-auto {
    margin-left: auto !important;
  }
  .p-xxl-0 {
    padding: 0 !important;
  }
  .p-xxl-1 {
    padding: 0.25rem !important;
  }
  .p-xxl-2 {
    padding: 0.5rem !important;
  }
  .p-xxl-3 {
    padding: 1rem !important;
  }
  .p-xxl-4 {
    padding: 1.5rem !important;
  }
  .p-xxl-5 {
    padding: 3rem !important;
  }
  .px-xxl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-xxl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-xxl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-xxl-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-xxl-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-xxl-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .py-xxl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-xxl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-xxl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-xxl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-xxl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-xxl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .pt-xxl-0 {
    padding-top: 0 !important;
  }
  .pt-xxl-1 {
    padding-top: 0.25rem !important;
  }
  .pt-xxl-2 {
    padding-top: 0.5rem !important;
  }
  .pt-xxl-3 {
    padding-top: 1rem !important;
  }
  .pt-xxl-4 {
    padding-top: 1.5rem !important;
  }
  .pt-xxl-5 {
    padding-top: 3rem !important;
  }
  .pe-xxl-0 {
    padding-right: 0 !important;
  }
  .pe-xxl-1 {
    padding-right: 0.25rem !important;
  }
  .pe-xxl-2 {
    padding-right: 0.5rem !important;
  }
  .pe-xxl-3 {
    padding-right: 1rem !important;
  }
  .pe-xxl-4 {
    padding-right: 1.5rem !important;
  }
  .pe-xxl-5 {
    padding-right: 3rem !important;
  }
  .pb-xxl-0 {
    padding-bottom: 0 !important;
  }
  .pb-xxl-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-xxl-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-xxl-3 {
    padding-bottom: 1rem !important;
  }
  .pb-xxl-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-xxl-5 {
    padding-bottom: 3rem !important;
  }
  .ps-xxl-0 {
    padding-left: 0 !important;
  }
  .ps-xxl-1 {
    padding-left: 0.25rem !important;
  }
  .ps-xxl-2 {
    padding-left: 0.5rem !important;
  }
  .ps-xxl-3 {
    padding-left: 1rem !important;
  }
  .ps-xxl-4 {
    padding-left: 1.5rem !important;
  }
  .ps-xxl-5 {
    padding-left: 3rem !important;
  }
  .text-xxl-start {
    text-align: left !important;
  }
  .text-xxl-end {
    text-align: right !important;
  }
  .text-xxl-center {
    text-align: center !important;
  }
}
@media (min-width: 1200px) {
  .fs-1 {
    font-size: 2.5rem !important;
  }
  .fs-2 {
    font-size: 2rem !important;
  }
  .fs-3 {
    font-size: 1.75rem !important;
  }
  .fs-4 {
    font-size: 1.5rem !important;
  }
}
@media print {
  .d-print-inline {
    display: inline !important;
  }
  .d-print-inline-block {
    display: inline-block !important;
  }
  .d-print-block {
    display: block !important;
  }
  .d-print-grid {
    display: grid !important;
  }
  .d-print-table {
    display: table !important;
  }
  .d-print-table-row {
    display: table-row !important;
  }
  .d-print-table-cell {
    display: table-cell !important;
  }
  .d-print-flex {
    display: flex !important;
  }
  .d-print-inline-flex {
    display: inline-flex !important;
  }
  .d-print-none {
    display: none !important;
  }
}
:root {
  --bs-blue: #0d6efd;
  --bs-indigo: #6610f2;
  --bs-purple: #6f42c1;
  --bs-pink: #d63384;
  --bs-red: #dc3545;
  --bs-orange: #fd7e14;
  --bs-yellow: #ffc107;
  --bs-green: #198754;
  --bs-teal: #20c997;
  --bs-cyan: #0dcaf0;
  --bs-white: #fff;
  --bs-gray: #6c757d;
  --bs-gray-dark: #343a40;
  --bs-gray-100: #f8f9fa;
  --bs-gray-200: #e9ecef;
  --bs-gray-300: #dee2e6;
  --bs-gray-400: #ced4da;
  --bs-gray-500: #adb5bd;
  --bs-gray-600: #6c757d;
  --bs-gray-700: #495057;
  --bs-gray-800: #343a40;
  --bs-gray-900: #212529;
  --bs-primary: #0d6efd;
  --bs-secondary: #6c757d;
  --bs-success: #198754;
  --bs-info: #0dcaf0;
  --bs-warning: #ffc107;
  --bs-danger: #dc3545;
  --bs-light: #f8f9fa;
  --bs-dark: #212529;
  --bs-primary-rgb: 13, 110, 253;
  --bs-secondary-rgb: 108, 117, 125;
  --bs-success-rgb: 25, 135, 84;
  --bs-info-rgb: 13, 202, 240;
  --bs-warning-rgb: 255, 193, 7;
  --bs-danger-rgb: 220, 53, 69;
  --bs-light-rgb: 248, 249, 250;
  --bs-dark-rgb: 33, 37, 41;
  --bs-white-rgb: 255, 255, 255;
  --bs-black-rgb: 0, 0, 0;
  --bs-body-rgb: 33, 37, 41;
  --bs-font-sans-serif: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  --bs-gradient: linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0));
  --bs-body-font-family: var(--bs-font-sans-serif);
  --bs-body-font-size: 1rem;
  --bs-body-font-weight: 400;
  --bs-body-line-height: 1.5;
  --bs-body-color: #212529;
  --bs-body-bg: #fff;
}

.row {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(var(--bs-gutter-y) * -1);
  margin-right: calc(var(--bs-gutter-x) * -0.5);
  margin-left: calc(var(--bs-gutter-x) * -0.5);
}
.row > * {
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  margin-top: var(--bs-gutter-y);
}

.col {
  flex: 1 0 0%;
}

.row-cols-auto > * {
  flex: 0 0 auto;
  width: auto;
}

.row-cols-1 > * {
  flex: 0 0 auto;
  width: 100%;
}

.row-cols-2 > * {
  flex: 0 0 auto;
  width: 50%;
}

.row-cols-3 > * {
  flex: 0 0 auto;
  width: 33.3333333333%;
}

.row-cols-4 > * {
  flex: 0 0 auto;
  width: 25%;
}

.row-cols-5 > * {
  flex: 0 0 auto;
  width: 20%;
}

.row-cols-6 > * {
  flex: 0 0 auto;
  width: 16.6666666667%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
}

.col-1 {
  flex: 0 0 auto;
  width: 8.33333333%;
}

.col-2 {
  flex: 0 0 auto;
  width: 16.66666667%;
}

.col-3 {
  flex: 0 0 auto;
  width: 25%;
}

.col-4 {
  flex: 0 0 auto;
  width: 33.33333333%;
}

.col-5 {
  flex: 0 0 auto;
  width: 41.66666667%;
}

.col-6 {
  flex: 0 0 auto;
  width: 50%;
}

.col-7 {
  flex: 0 0 auto;
  width: 58.33333333%;
}

.col-8 {
  flex: 0 0 auto;
  width: 66.66666667%;
}

.col-9 {
  flex: 0 0 auto;
  width: 75%;
}

.col-10 {
  flex: 0 0 auto;
  width: 83.33333333%;
}

.col-11 {
  flex: 0 0 auto;
  width: 91.66666667%;
}

.col-12 {
  flex: 0 0 auto;
  width: 100%;
}

.offset-1 {
  margin-left: 8.33333333%;
}

.offset-2 {
  margin-left: 16.66666667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.33333333%;
}

.offset-5 {
  margin-left: 41.66666667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.33333333%;
}

.offset-8 {
  margin-left: 66.66666667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.33333333%;
}

.offset-11 {
  margin-left: 91.66666667%;
}

.g-0,
.gx-0 {
  --bs-gutter-x: 0;
}

.g-0,
.gy-0 {
  --bs-gutter-y: 0;
}

.g-1,
.gx-1 {
  --bs-gutter-x: 0.25rem;
}

.g-1,
.gy-1 {
  --bs-gutter-y: 0.25rem;
}

.g-2,
.gx-2 {
  --bs-gutter-x: 0.5rem;
}

.g-2,
.gy-2 {
  --bs-gutter-y: 0.5rem;
}

.g-3,
.gx-3 {
  --bs-gutter-x: 1rem;
}

.g-3,
.gy-3 {
  --bs-gutter-y: 1rem;
}

.g-4,
.gx-4 {
  --bs-gutter-x: 1.5rem;
}

.g-4,
.gy-4 {
  --bs-gutter-y: 1.5rem;
}

.g-5,
.gx-5 {
  --bs-gutter-x: 3rem;
}

.g-5,
.gy-5 {
  --bs-gutter-y: 3rem;
}

@media (min-width: 576px) {
  .col-sm {
    flex: 1 0 0%;
  }
  .row-cols-sm-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-sm-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-sm-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-sm-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }
  .row-cols-sm-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-sm-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-sm-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-sm-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-sm-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-sm-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-sm-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-sm-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-sm-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-sm-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-sm-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-sm-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-sm-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-sm-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-sm-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-sm-0 {
    margin-left: 0;
  }
  .offset-sm-1 {
    margin-left: 8.33333333%;
  }
  .offset-sm-2 {
    margin-left: 16.66666667%;
  }
  .offset-sm-3 {
    margin-left: 25%;
  }
  .offset-sm-4 {
    margin-left: 33.33333333%;
  }
  .offset-sm-5 {
    margin-left: 41.66666667%;
  }
  .offset-sm-6 {
    margin-left: 50%;
  }
  .offset-sm-7 {
    margin-left: 58.33333333%;
  }
  .offset-sm-8 {
    margin-left: 66.66666667%;
  }
  .offset-sm-9 {
    margin-left: 75%;
  }
  .offset-sm-10 {
    margin-left: 83.33333333%;
  }
  .offset-sm-11 {
    margin-left: 91.66666667%;
  }
  .g-sm-0,
  .gx-sm-0 {
    --bs-gutter-x: 0;
  }
  .g-sm-0,
  .gy-sm-0 {
    --bs-gutter-y: 0;
  }
  .g-sm-1,
  .gx-sm-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-sm-1,
  .gy-sm-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-sm-2,
  .gx-sm-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-sm-2,
  .gy-sm-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-sm-3,
  .gx-sm-3 {
    --bs-gutter-x: 1rem;
  }
  .g-sm-3,
  .gy-sm-3 {
    --bs-gutter-y: 1rem;
  }
  .g-sm-4,
  .gx-sm-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-sm-4,
  .gy-sm-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-sm-5,
  .gx-sm-5 {
    --bs-gutter-x: 3rem;
  }
  .g-sm-5,
  .gy-sm-5 {
    --bs-gutter-y: 3rem;
  }
}
@media (min-width: 768px) {
  .col-md {
    flex: 1 0 0%;
  }
  .row-cols-md-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-md-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-md-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-md-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }
  .row-cols-md-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-md-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-md-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-md-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-md-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-md-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-md-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-md-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-md-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-md-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-md-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-md-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-md-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-md-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-md-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-md-0 {
    margin-left: 0;
  }
  .offset-md-1 {
    margin-left: 8.33333333%;
  }
  .offset-md-2 {
    margin-left: 16.66666667%;
  }
  .offset-md-3 {
    margin-left: 25%;
  }
  .offset-md-4 {
    margin-left: 33.33333333%;
  }
  .offset-md-5 {
    margin-left: 41.66666667%;
  }
  .offset-md-6 {
    margin-left: 50%;
  }
  .offset-md-7 {
    margin-left: 58.33333333%;
  }
  .offset-md-8 {
    margin-left: 66.66666667%;
  }
  .offset-md-9 {
    margin-left: 75%;
  }
  .offset-md-10 {
    margin-left: 83.33333333%;
  }
  .offset-md-11 {
    margin-left: 91.66666667%;
  }
  .g-md-0,
  .gx-md-0 {
    --bs-gutter-x: 0;
  }
  .g-md-0,
  .gy-md-0 {
    --bs-gutter-y: 0;
  }
  .g-md-1,
  .gx-md-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-md-1,
  .gy-md-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-md-2,
  .gx-md-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-md-2,
  .gy-md-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-md-3,
  .gx-md-3 {
    --bs-gutter-x: 1rem;
  }
  .g-md-3,
  .gy-md-3 {
    --bs-gutter-y: 1rem;
  }
  .g-md-4,
  .gx-md-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-md-4,
  .gy-md-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-md-5,
  .gx-md-5 {
    --bs-gutter-x: 3rem;
  }
  .g-md-5,
  .gy-md-5 {
    --bs-gutter-y: 3rem;
  }
}
@media (min-width: 992px) {
  .col-lg {
    flex: 1 0 0%;
  }
  .row-cols-lg-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-lg-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-lg-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-lg-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }
  .row-cols-lg-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-lg-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-lg-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-lg-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-lg-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-lg-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-lg-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-lg-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-lg-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-lg-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-lg-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-lg-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-lg-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-lg-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-lg-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-lg-0 {
    margin-left: 0;
  }
  .offset-lg-1 {
    margin-left: 8.33333333%;
  }
  .offset-lg-2 {
    margin-left: 16.66666667%;
  }
  .offset-lg-3 {
    margin-left: 25%;
  }
  .offset-lg-4 {
    margin-left: 33.33333333%;
  }
  .offset-lg-5 {
    margin-left: 41.66666667%;
  }
  .offset-lg-6 {
    margin-left: 50%;
  }
  .offset-lg-7 {
    margin-left: 58.33333333%;
  }
  .offset-lg-8 {
    margin-left: 66.66666667%;
  }
  .offset-lg-9 {
    margin-left: 75%;
  }
  .offset-lg-10 {
    margin-left: 83.33333333%;
  }
  .offset-lg-11 {
    margin-left: 91.66666667%;
  }
  .g-lg-0,
  .gx-lg-0 {
    --bs-gutter-x: 0;
  }
  .g-lg-0,
  .gy-lg-0 {
    --bs-gutter-y: 0;
  }
  .g-lg-1,
  .gx-lg-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-lg-1,
  .gy-lg-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-lg-2,
  .gx-lg-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-lg-2,
  .gy-lg-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-lg-3,
  .gx-lg-3 {
    --bs-gutter-x: 1rem;
  }
  .g-lg-3,
  .gy-lg-3 {
    --bs-gutter-y: 1rem;
  }
  .g-lg-4,
  .gx-lg-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-lg-4,
  .gy-lg-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-lg-5,
  .gx-lg-5 {
    --bs-gutter-x: 3rem;
  }
  .g-lg-5,
  .gy-lg-5 {
    --bs-gutter-y: 3rem;
  }
}
@media (min-width: 1200px) {
  .col-xl {
    flex: 1 0 0%;
  }
  .row-cols-xl-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-xl-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-xl-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-xl-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }
  .row-cols-xl-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-xl-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-xl-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-xl-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-xl-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-xl-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-xl-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-xl-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-xl-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-xl-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-xl-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-xl-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-xl-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-xl-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-xl-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-xl-0 {
    margin-left: 0;
  }
  .offset-xl-1 {
    margin-left: 8.33333333%;
  }
  .offset-xl-2 {
    margin-left: 16.66666667%;
  }
  .offset-xl-3 {
    margin-left: 25%;
  }
  .offset-xl-4 {
    margin-left: 33.33333333%;
  }
  .offset-xl-5 {
    margin-left: 41.66666667%;
  }
  .offset-xl-6 {
    margin-left: 50%;
  }
  .offset-xl-7 {
    margin-left: 58.33333333%;
  }
  .offset-xl-8 {
    margin-left: 66.66666667%;
  }
  .offset-xl-9 {
    margin-left: 75%;
  }
  .offset-xl-10 {
    margin-left: 83.33333333%;
  }
  .offset-xl-11 {
    margin-left: 91.66666667%;
  }
  .g-xl-0,
  .gx-xl-0 {
    --bs-gutter-x: 0;
  }
  .g-xl-0,
  .gy-xl-0 {
    --bs-gutter-y: 0;
  }
  .g-xl-1,
  .gx-xl-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-xl-1,
  .gy-xl-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-xl-2,
  .gx-xl-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-xl-2,
  .gy-xl-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-xl-3,
  .gx-xl-3 {
    --bs-gutter-x: 1rem;
  }
  .g-xl-3,
  .gy-xl-3 {
    --bs-gutter-y: 1rem;
  }
  .g-xl-4,
  .gx-xl-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-xl-4,
  .gy-xl-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-xl-5,
  .gx-xl-5 {
    --bs-gutter-x: 3rem;
  }
  .g-xl-5,
  .gy-xl-5 {
    --bs-gutter-y: 3rem;
  }
}
@media (min-width: 1400px) {
  .col-xxl {
    flex: 1 0 0%;
  }
  .row-cols-xxl-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-xxl-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-xxl-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-xxl-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }
  .row-cols-xxl-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-xxl-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-xxl-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
  .col-xxl-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-xxl-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-xxl-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-xxl-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-xxl-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-xxl-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-xxl-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-xxl-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-xxl-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-xxl-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-xxl-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-xxl-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-xxl-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-xxl-0 {
    margin-left: 0;
  }
  .offset-xxl-1 {
    margin-left: 8.33333333%;
  }
  .offset-xxl-2 {
    margin-left: 16.66666667%;
  }
  .offset-xxl-3 {
    margin-left: 25%;
  }
  .offset-xxl-4 {
    margin-left: 33.33333333%;
  }
  .offset-xxl-5 {
    margin-left: 41.66666667%;
  }
  .offset-xxl-6 {
    margin-left: 50%;
  }
  .offset-xxl-7 {
    margin-left: 58.33333333%;
  }
  .offset-xxl-8 {
    margin-left: 66.66666667%;
  }
  .offset-xxl-9 {
    margin-left: 75%;
  }
  .offset-xxl-10 {
    margin-left: 83.33333333%;
  }
  .offset-xxl-11 {
    margin-left: 91.66666667%;
  }
  .g-xxl-0,
  .gx-xxl-0 {
    --bs-gutter-x: 0;
  }
  .g-xxl-0,
  .gy-xxl-0 {
    --bs-gutter-y: 0;
  }
  .g-xxl-1,
  .gx-xxl-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-xxl-1,
  .gy-xxl-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-xxl-2,
  .gx-xxl-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-xxl-2,
  .gy-xxl-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-xxl-3,
  .gx-xxl-3 {
    --bs-gutter-x: 1rem;
  }
  .g-xxl-3,
  .gy-xxl-3 {
    --bs-gutter-y: 1rem;
  }
  .g-xxl-4,
  .gx-xxl-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-xxl-4,
  .gy-xxl-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-xxl-5,
  .gx-xxl-5 {
    --bs-gutter-x: 3rem;
  }
  .g-xxl-5,
  .gy-xxl-5 {
    --bs-gutter-y: 3rem;
  }
}
.fade {
  transition: opacity 0.15s linear;
}
@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}
.fade:not(.show) {
  opacity: 0;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}
.collapsing.collapse-horizontal {
  width: 0;
  height: auto;
  transition: width 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing.collapse-horizontal {
    transition: none;
  }
}

/*!
 * Bootstrap Reboot v5.1.0 (https://getbootstrap.com/)
 * Copyright 2011-2021 The Bootstrap Authors
 * Copyright 2011-2021 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 * Forked from Normalize.css, licensed MIT (https://github.com/necolas/normalize.css/blob/master/LICENSE.md)
 */
*,
*::before,
*::after {
  box-sizing: border-box;
}

@media (prefers-reduced-motion: no-preference) {
  :root {
    scroll-behavior: smooth;
  }
}

body {
  margin: 0;
  font-family: var(--bs-body-font-family);
  font-size: var(--bs-body-font-size);
  font-weight: var(--bs-body-font-weight);
  line-height: var(--bs-body-line-height);
  color: var(--bs-body-color);
  text-align: var(--bs-body-text-align);
  background-color: var(--bs-body-bg);
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

hr {
  margin: 1rem 0;
  color: inherit;
  background-color: currentColor;
  border: 0;
  opacity: 0.25;
}

hr:not([size]) {
  height: 1px;
}

h6, .h6, h5, .h5, h4, .h4, h3, .h3, h2, .h2, h1, .h1 {
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
}

h1, .h1 {
  font-size: calc(1.375rem + 1.5vw);
}
@media (min-width: 1200px) {
  h1, .h1 {
    font-size: 2.5rem;
  }
}

h2, .h2 {
  font-size: calc(1.325rem + 0.9vw);
}
@media (min-width: 1200px) {
  h2, .h2 {
    font-size: 2rem;
  }
}

h3, .h3 {
  font-size: calc(1.3rem + 0.6vw);
}
@media (min-width: 1200px) {
  h3, .h3 {
    font-size: 1.75rem;
  }
}

h4, .h4 {
  font-size: calc(1.275rem + 0.3vw);
}
@media (min-width: 1200px) {
  h4, .h4 {
    font-size: 1.5rem;
  }
}

h5, .h5 {
  font-size: 1.25rem;
}

h6, .h6 {
  font-size: 1rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title],
abbr[data-bs-original-title] {
  text-decoration: underline dotted;
  cursor: help;
  text-decoration-skip-ink: none;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul {
  padding-left: 2rem;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b,
strong {
  font-weight: bolder;
}

small, .small {
  font-size: 0.875em;
}

mark, .mark {
  padding: 0.2em;
  background-color: #fcf8e3;
}

sub,
sup {
  position: relative;
  font-size: 0.75em;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

a {
  color: #0d6efd;
  text-decoration: underline;
}
a:hover {
  color: #0a58ca;
}

a:not([href]):not([class]), a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em;
  direction: ltr /* rtl:ignore */;
  unicode-bidi: bidi-override;
}

pre {
  display: block;
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  font-size: 0.875em;
}
pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

code {
  font-size: 0.875em;
  color: #d63384;
  word-wrap: break-word;
}
a > code {
  color: inherit;
}

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 0.875em;
  color: #fff;
  background-color: #212529;
  border-radius: 0.2rem;
}
kbd kbd {
  padding: 0;
  font-size: 1em;
  font-weight: 700;
}

figure {
  margin: 0 0 1rem;
}

img,
svg {
  vertical-align: middle;
}

table {
  caption-side: bottom;
  border-collapse: collapse;
}

caption {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  color: #6c757d;
  text-align: left;
}

th {
  text-align: inherit;
  text-align: -webkit-match-parent;
}

thead,
tbody,
tfoot,
tr,
td,
th {
  border-color: inherit;
  border-style: solid;
  border-width: 0;
}

label {
  display: inline-block;
}

button {
  border-radius: 0;
}

button:focus:not(:focus-visible) {
  outline: 0;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
select {
  text-transform: none;
}

[role=button] {
  cursor: pointer;
}

select {
  word-wrap: normal;
}
select:disabled {
  opacity: 1;
}

[list]::-webkit-calendar-picker-indicator {
  display: none;
}

button,
[type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
}
button:not(:disabled),
[type=button]:not(:disabled),
[type=reset]:not(:disabled),
[type=submit]:not(:disabled) {
  cursor: pointer;
}

::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

textarea {
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  float: left;
  width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: calc(1.275rem + 0.3vw);
  line-height: inherit;
}
@media (min-width: 1200px) {
  legend {
    font-size: 1.5rem;
  }
}
legend + * {
  clear: left;
}

::-webkit-datetime-edit-fields-wrapper,
::-webkit-datetime-edit-text,
::-webkit-datetime-edit-minute,
::-webkit-datetime-edit-hour-field,
::-webkit-datetime-edit-day-field,
::-webkit-datetime-edit-month-field,
::-webkit-datetime-edit-year-field {
  padding: 0;
}

::-webkit-inner-spin-button {
  height: auto;
}

[type=search] {
  outline-offset: -2px;
  -webkit-appearance: textfield;
}

/* rtl:raw:
[type="tel"],
[type="url"],
[type="email"],
[type="number"] {
  direction: ltr;
}
*/
::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-color-swatch-wrapper {
  padding: 0;
}

::file-selector-button {
  font: inherit;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

iframe {
  border: 0;
}

summary {
  display: list-item;
  cursor: pointer;
}

progress {
  vertical-align: baseline;
}

[hidden] {
  display: none !important;
}

.lead {
  font-size: 1.25rem;
  font-weight: 300;
}

.display-1 {
  font-size: calc(1.625rem + 4.5vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-1 {
    font-size: 5rem;
  }
}

.display-2 {
  font-size: calc(1.575rem + 3.9vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-2 {
    font-size: 4.5rem;
  }
}

.display-3 {
  font-size: calc(1.525rem + 3.3vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-3 {
    font-size: 4rem;
  }
}

.display-4 {
  font-size: calc(1.475rem + 2.7vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-4 {
    font-size: 3.5rem;
  }
}

.display-5 {
  font-size: calc(1.425rem + 2.1vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-5 {
    font-size: 3rem;
  }
}

.display-6 {
  font-size: calc(1.375rem + 1.5vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-6 {
    font-size: 2.5rem;
  }
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.list-inline {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}
.list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}

.initialism {
  font-size: 0.875em;
  text-transform: uppercase;
}

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem;
}
.blockquote > :last-child {
  margin-bottom: 0;
}

.blockquote-footer {
  margin-top: -1rem;
  margin-bottom: 1rem;
  font-size: 0.875em;
  color: #6c757d;
}
.blockquote-footer::before {
  content: "— ";
}

.table {
  --bs-table-bg: transparent;
  --bs-table-accent-bg: transparent;
  --bs-table-striped-color: #212529;
  --bs-table-striped-bg: rgba(0, 0, 0, 0.05);
  --bs-table-active-color: #212529;
  --bs-table-active-bg: rgba(0, 0, 0, 0.1);
  --bs-table-hover-color: #212529;
  --bs-table-hover-bg: rgba(0, 0, 0, 0.075);
  width: 100%;
  margin-bottom: 1rem;
  color: #212529;
  vertical-align: top;
  border-color: #dee2e6;
}
.table > :not(caption) > * > * {
  padding: 0.5rem 0.5rem;
  background-color: var(--bs-table-bg);
  border-bottom-width: 1px;
  box-shadow: inset 0 0 0 9999px var(--bs-table-accent-bg);
}
.table > tbody {
  vertical-align: inherit;
}
.table > thead {
  vertical-align: bottom;
}
.table > :not(:last-child) > :last-child > * {
  border-bottom-color: currentColor;
}

.caption-top {
  caption-side: top;
}

.table-sm > :not(caption) > * > * {
  padding: 0.25rem 0.25rem;
}

.table-bordered > :not(caption) > * {
  border-width: 1px 0;
}
.table-bordered > :not(caption) > * > * {
  border-width: 0 1px;
}

.table-borderless > :not(caption) > * > * {
  border-bottom-width: 0;
}

.table-striped > tbody > tr:nth-of-type(odd) {
  --bs-table-accent-bg: var(--bs-table-striped-bg);
  color: var(--bs-table-striped-color);
}

.table-active {
  --bs-table-accent-bg: var(--bs-table-active-bg);
  color: var(--bs-table-active-color);
}

.table-hover > tbody > tr:hover {
  --bs-table-accent-bg: var(--bs-table-hover-bg);
  color: var(--bs-table-hover-color);
}

.table-primary {
  --bs-table-bg: #cfe2ff;
  --bs-table-striped-bg: #c5d7f2;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #bacbe6;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #bfd1ec;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #bacbe6;
}

.table-secondary {
  --bs-table-bg: #e2e3e5;
  --bs-table-striped-bg: #d7d8da;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #cbccce;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #d1d2d4;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #cbccce;
}

.table-success {
  --bs-table-bg: #d1e7dd;
  --bs-table-striped-bg: #c7dbd2;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #bcd0c7;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #c1d6cc;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #bcd0c7;
}

.table-info {
  --bs-table-bg: #cff4fc;
  --bs-table-striped-bg: #c5e8ef;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #badce3;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #bfe2e9;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #badce3;
}

.table-warning {
  --bs-table-bg: #fff3cd;
  --bs-table-striped-bg: #f2e7c3;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #e6dbb9;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #ece1be;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #e6dbb9;
}

.table-danger {
  --bs-table-bg: #f8d7da;
  --bs-table-striped-bg: #eccccf;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #dfc2c4;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #e5c7ca;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #dfc2c4;
}

.table-light {
  --bs-table-bg: #f8f9fa;
  --bs-table-striped-bg: #ecedee;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #dfe0e1;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #e5e6e7;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #dfe0e1;
}

.table-dark {
  --bs-table-bg: #212529;
  --bs-table-striped-bg: #2c3034;
  --bs-table-striped-color: #fff;
  --bs-table-active-bg: #373b3e;
  --bs-table-active-color: #fff;
  --bs-table-hover-bg: #323539;
  --bs-table-hover-color: #fff;
  color: #fff;
  border-color: #373b3e;
}

.table-responsive {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

@media (max-width: 575.98px) {
  .table-responsive-sm {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 767.98px) {
  .table-responsive-md {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 991.98px) {
  .table-responsive-lg {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 1199.98px) {
  .table-responsive-xl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 1399.98px) {
  .table-responsive-xxl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
.form-label {
  margin-bottom: 0.5rem;
}

.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5;
}

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.25rem;
}

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.875rem;
}

.form-text {
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #6c757d;
}

.form-control {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  appearance: none;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none;
  }
}
.form-control[type=file] {
  overflow: hidden;
}
.form-control[type=file]:not(:disabled):not([readonly]) {
  cursor: pointer;
}
.form-control:focus {
  color: #212529;
  background-color: #fff;
  border-color: #86b7fe;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}
.form-control::-webkit-date-and-time-value {
  height: 1.5em;
}
.form-control::placeholder {
  color: #6c757d;
  opacity: 1;
}
.form-control:disabled, .form-control[readonly] {
  background-color: #e9ecef;
  opacity: 1;
}
.form-control::file-selector-button {
  padding: 0.375rem 0.75rem;
  margin: -0.375rem -0.75rem;
  margin-inline-end: 0.75rem;
  color: #212529;
  background-color: #e9ecef;
  pointer-events: none;
  border-color: inherit;
  border-style: solid;
  border-width: 0;
  border-inline-end-width: 1px;
  border-radius: 0;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control::file-selector-button {
    transition: none;
  }
}
.form-control:hover:not(:disabled):not([readonly])::file-selector-button {
  background-color: #dde0e3;
}
.form-control::-webkit-file-upload-button {
  padding: 0.375rem 0.75rem;
  margin: -0.375rem -0.75rem;
  margin-inline-end: 0.75rem;
  color: #212529;
  background-color: #e9ecef;
  pointer-events: none;
  border-color: inherit;
  border-style: solid;
  border-width: 0;
  border-inline-end-width: 1px;
  border-radius: 0;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control::-webkit-file-upload-button {
    transition: none;
  }
}
.form-control:hover:not(:disabled):not([readonly])::-webkit-file-upload-button {
  background-color: #dde0e3;
}

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.375rem 0;
  margin-bottom: 0;
  line-height: 1.5;
  color: #212529;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0;
}
.form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
  padding-right: 0;
  padding-left: 0;
}

.form-control-sm {
  min-height: calc(1.5em + 0.5rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  border-radius: 0.2rem;
}
.form-control-sm::file-selector-button {
  padding: 0.25rem 0.5rem;
  margin: -0.25rem -0.5rem;
  margin-inline-end: 0.5rem;
}
.form-control-sm::-webkit-file-upload-button {
  padding: 0.25rem 0.5rem;
  margin: -0.25rem -0.5rem;
  margin-inline-end: 0.5rem;
}

.form-control-lg {
  min-height: calc(1.5em + 1rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  border-radius: 0.3rem;
}
.form-control-lg::file-selector-button {
  padding: 0.5rem 1rem;
  margin: -0.5rem -1rem;
  margin-inline-end: 1rem;
}
.form-control-lg::-webkit-file-upload-button {
  padding: 0.5rem 1rem;
  margin: -0.5rem -1rem;
  margin-inline-end: 1rem;
}

textarea.form-control {
  min-height: calc(1.5em + 0.75rem + 2px);
}
textarea.form-control-sm {
  min-height: calc(1.5em + 0.5rem + 2px);
}
textarea.form-control-lg {
  min-height: calc(1.5em + 1rem + 2px);
}

.form-control-color {
  width: 3rem;
  height: auto;
  padding: 0.375rem;
}
.form-control-color:not(:disabled):not([readonly]) {
  cursor: pointer;
}
.form-control-color::-moz-color-swatch {
  height: 1.5em;
  border-radius: 0.25rem;
}
.form-control-color::-webkit-color-swatch {
  height: 1.5em;
  border-radius: 0.25rem;
}

.form-select {
  display: block;
  width: 100%;
  padding: 0.375rem 2.25rem 0.375rem 0.75rem;
  -moz-padding-start: calc(0.75rem - 3px);
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  background-size: 16px 12px;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .form-select {
    transition: none;
  }
}
.form-select:focus {
  border-color: #86b7fe;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}
.form-select[multiple], .form-select[size]:not([size="1"]) {
  padding-right: 0.75rem;
  background-image: none;
}
.form-select:disabled {
  background-color: #e9ecef;
}
.form-select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #212529;
}

.form-select-sm {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.875rem;
}

.form-select-lg {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: 1.25rem;
}

.form-check {
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5em;
  margin-bottom: 0.125rem;
}
.form-check .form-check-input {
  float: left;
  margin-left: -1.5em;
}

.form-check-input {
  width: 1em;
  height: 1em;
  margin-top: 0.25em;
  vertical-align: top;
  background-color: #fff;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  border: 1px solid rgba(0, 0, 0, 0.25);
  appearance: none;
  color-adjust: exact;
}
.form-check-input[type=checkbox] {
  border-radius: 0.25em;
}
.form-check-input[type=radio] {
  border-radius: 50%;
}
.form-check-input:active {
  filter: brightness(90%);
}
.form-check-input:focus {
  border-color: #86b7fe;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}
.form-check-input:checked {
  background-color: #0d6efd;
  border-color: #0d6efd;
}
.form-check-input:checked[type=checkbox] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e");
}
.form-check-input:checked[type=radio] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23fff'/%3e%3c/svg%3e");
}
.form-check-input[type=checkbox]:indeterminate {
  background-color: #0d6efd;
  border-color: #0d6efd;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10h8'/%3e%3c/svg%3e");
}
.form-check-input:disabled {
  pointer-events: none;
  filter: none;
  opacity: 0.5;
}
.form-check-input[disabled] ~ .form-check-label, .form-check-input:disabled ~ .form-check-label {
  opacity: 0.5;
}

.form-switch {
  padding-left: 2.5em;
}
.form-switch .form-check-input {
  width: 2em;
  margin-left: -2.5em;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%280, 0, 0, 0.25%29'/%3e%3c/svg%3e");
  background-position: left center;
  border-radius: 2em;
  transition: background-position 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-switch .form-check-input {
    transition: none;
  }
}
.form-switch .form-check-input:focus {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%2386b7fe'/%3e%3c/svg%3e");
}
.form-switch .form-check-input:checked {
  background-position: right center;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
}

.form-check-inline {
  display: inline-block;
  margin-right: 1rem;
}

.btn-check {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}
.btn-check[disabled] + .btn, .btn-check:disabled + .btn {
  pointer-events: none;
  filter: none;
  opacity: 0.65;
}

.form-range {
  width: 100%;
  height: 1.5rem;
  padding: 0;
  background-color: transparent;
  appearance: none;
}
.form-range:focus {
  outline: 0;
}
.form-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}
.form-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}
.form-range::-moz-focus-outer {
  border: 0;
}
.form-range::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: -0.25rem;
  background-color: #0d6efd;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .form-range::-webkit-slider-thumb {
    transition: none;
  }
}
.form-range::-webkit-slider-thumb:active {
  background-color: #b6d4fe;
}
.form-range::-webkit-slider-runnable-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
}
.form-range::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  background-color: #0d6efd;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .form-range::-moz-range-thumb {
    transition: none;
  }
}
.form-range::-moz-range-thumb:active {
  background-color: #b6d4fe;
}
.form-range::-moz-range-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
}
.form-range:disabled {
  pointer-events: none;
}
.form-range:disabled::-webkit-slider-thumb {
  background-color: #adb5bd;
}
.form-range:disabled::-moz-range-thumb {
  background-color: #adb5bd;
}

.form-floating {
  position: relative;
}
.form-floating > .form-control,
.form-floating > .form-select {
  height: calc(3.5rem + 2px);
  line-height: 1.25;
}
.form-floating > label {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  padding: 1rem 0.75rem;
  pointer-events: none;
  border: 1px solid transparent;
  transform-origin: 0 0;
  transition: opacity 0.1s ease-in-out, transform 0.1s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-floating > label {
    transition: none;
  }
}
.form-floating > .form-control {
  padding: 1rem 0.75rem;
}
.form-floating > .form-control::placeholder {
  color: transparent;
}
.form-floating > .form-control:focus, .form-floating > .form-control:not(:placeholder-shown) {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
.form-floating > .form-control:-webkit-autofill {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
.form-floating > .form-select {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
.form-floating > .form-control:focus ~ label,
.form-floating > .form-control:not(:placeholder-shown) ~ label,
.form-floating > .form-select ~ label {
  opacity: 0.65;
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}
.form-floating > .form-control:-webkit-autofill ~ label {
  opacity: 0.65;
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}
.input-group > .form-control,
.input-group > .form-select {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
}
.input-group > .form-control:focus,
.input-group > .form-select:focus {
  z-index: 3;
}
.input-group .btn {
  position: relative;
  z-index: 2;
}
.input-group .btn:focus {
  z-index: 3;
}

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}

.input-group-lg > .form-control,
.input-group-lg > .form-select,
.input-group-lg > .input-group-text,
.input-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  border-radius: 0.3rem;
}

.input-group-sm > .form-control,
.input-group-sm > .form-select,
.input-group-sm > .input-group-text,
.input-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  border-radius: 0.2rem;
}

.input-group-lg > .form-select,
.input-group-sm > .form-select {
  padding-right: 3rem;
}

.input-group:not(.has-validation) > :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu),
.input-group:not(.has-validation) > .dropdown-toggle:nth-last-child(n+3) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group.has-validation > :nth-last-child(n+3):not(.dropdown-toggle):not(.dropdown-menu),
.input-group.has-validation > .dropdown-toggle:nth-last-child(n+4) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
  margin-left: -1px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #198754;
}

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  color: #fff;
  background-color: rgba(25, 135, 84, 0.9);
  border-radius: 0.25rem;
}

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: #198754;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23198754' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .form-control:valid:focus, .form-control.is-valid:focus {
  border-color: #198754;
  box-shadow: 0 0 0 0.25rem rgba(25, 135, 84, 0.25);
}

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
}

.was-validated .form-select:valid, .form-select.is-valid {
  border-color: #198754;
}
.was-validated .form-select:valid:not([multiple]):not([size]), .was-validated .form-select:valid:not([multiple])[size="1"], .form-select.is-valid:not([multiple]):not([size]), .form-select.is-valid:not([multiple])[size="1"] {
  padding-right: 4.125rem;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e"), url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23198754' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-position: right 0.75rem center, center right 2.25rem;
  background-size: 16px 12px, calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .form-select:valid:focus, .form-select.is-valid:focus {
  border-color: #198754;
  box-shadow: 0 0 0 0.25rem rgba(25, 135, 84, 0.25);
}

.was-validated .form-check-input:valid, .form-check-input.is-valid {
  border-color: #198754;
}
.was-validated .form-check-input:valid:checked, .form-check-input.is-valid:checked {
  background-color: #198754;
}
.was-validated .form-check-input:valid:focus, .form-check-input.is-valid:focus {
  box-shadow: 0 0 0 0.25rem rgba(25, 135, 84, 0.25);
}
.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #198754;
}

.form-check-inline .form-check-input ~ .valid-feedback {
  margin-left: 0.5em;
}

.was-validated .input-group .form-control:valid, .input-group .form-control.is-valid,
.was-validated .input-group .form-select:valid,
.input-group .form-select.is-valid {
  z-index: 1;
}
.was-validated .input-group .form-control:valid:focus, .input-group .form-control.is-valid:focus,
.was-validated .input-group .form-select:valid:focus,
.input-group .form-select.is-valid:focus {
  z-index: 3;
}

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #dc3545;
}

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  color: #fff;
  background-color: rgba(220, 53, 69, 0.9);
  border-radius: 0.25rem;
}

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: #dc3545;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.25rem rgba(220, 53, 69, 0.25);
}

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
}

.was-validated .form-select:invalid, .form-select.is-invalid {
  border-color: #dc3545;
}
.was-validated .form-select:invalid:not([multiple]):not([size]), .was-validated .form-select:invalid:not([multiple])[size="1"], .form-select.is-invalid:not([multiple]):not([size]), .form-select.is-invalid:not([multiple])[size="1"] {
  padding-right: 4.125rem;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e"), url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-position: right 0.75rem center, center right 2.25rem;
  background-size: 16px 12px, calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .form-select:invalid:focus, .form-select.is-invalid:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.25rem rgba(220, 53, 69, 0.25);
}

.was-validated .form-check-input:invalid, .form-check-input.is-invalid {
  border-color: #dc3545;
}
.was-validated .form-check-input:invalid:checked, .form-check-input.is-invalid:checked {
  background-color: #dc3545;
}
.was-validated .form-check-input:invalid:focus, .form-check-input.is-invalid:focus {
  box-shadow: 0 0 0 0.25rem rgba(220, 53, 69, 0.25);
}
.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #dc3545;
}

.form-check-inline .form-check-input ~ .invalid-feedback {
  margin-left: 0.5em;
}

.was-validated .input-group .form-control:invalid, .input-group .form-control.is-invalid,
.was-validated .input-group .form-select:invalid,
.input-group .form-select.is-invalid {
  z-index: 2;
}
.was-validated .input-group .form-control:invalid:focus, .input-group .form-control.is-invalid:focus,
.was-validated .input-group .form-select:invalid:focus,
.input-group .form-select.is-invalid:focus {
  z-index: 3;
}

.dropup,
.dropend,
.dropdown,
.dropstart {
  position: relative;
}

.dropdown-toggle {
  white-space: nowrap;
}
.dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}
.dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropdown-menu {
  position: absolute;
  z-index: 1000;
  display: none;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
}
.dropdown-menu[data-bs-popper] {
  top: 100%;
  left: 0;
  margin-top: 0.125rem;
}

.dropdown-menu-start {
  --bs-position: start;
}
.dropdown-menu-start[data-bs-popper] {
  right: auto;
  left: 0;
}

.dropdown-menu-end {
  --bs-position: end;
}
.dropdown-menu-end[data-bs-popper] {
  right: 0;
  left: auto;
}

@media (min-width: 576px) {
  .dropdown-menu-sm-start {
    --bs-position: start;
  }
  .dropdown-menu-sm-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-sm-end {
    --bs-position: end;
  }
  .dropdown-menu-sm-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 768px) {
  .dropdown-menu-md-start {
    --bs-position: start;
  }
  .dropdown-menu-md-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-md-end {
    --bs-position: end;
  }
  .dropdown-menu-md-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 992px) {
  .dropdown-menu-lg-start {
    --bs-position: start;
  }
  .dropdown-menu-lg-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-lg-end {
    --bs-position: end;
  }
  .dropdown-menu-lg-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 1200px) {
  .dropdown-menu-xl-start {
    --bs-position: start;
  }
  .dropdown-menu-xl-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-xl-end {
    --bs-position: end;
  }
  .dropdown-menu-xl-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 1400px) {
  .dropdown-menu-xxl-start {
    --bs-position: start;
  }
  .dropdown-menu-xxl-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-xxl-end {
    --bs-position: end;
  }
  .dropdown-menu-xxl-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
.dropup .dropdown-menu[data-bs-popper] {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem;
}
.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent;
}
.dropup .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropend .dropdown-menu[data-bs-popper] {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem;
}
.dropend .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid;
}
.dropend .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropend .dropdown-toggle::after {
  vertical-align: 0;
}

.dropstart .dropdown-menu[data-bs-popper] {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem;
}
.dropstart .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
}
.dropstart .dropdown-toggle::after {
  display: none;
}
.dropstart .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent;
}
.dropstart .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropstart .dropdown-toggle::before {
  vertical-align: 0;
}

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid rgba(0, 0, 0, 0.15);
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  text-decoration: none;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}
.dropdown-item:hover, .dropdown-item:focus {
  color: #1e2125;
  background-color: #e9ecef;
}
.dropdown-item.active, .dropdown-item:active {
  color: #fff;
  text-decoration: none;
  background-color: #0d6efd;
}
.dropdown-item.disabled, .dropdown-item:disabled {
  color: #adb5bd;
  pointer-events: none;
  background-color: transparent;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-header {
  display: block;
  padding: 0.5rem 1rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #6c757d;
  white-space: nowrap;
}

.dropdown-item-text {
  display: block;
  padding: 0.25rem 1rem;
  color: #212529;
}

.dropdown-menu-dark {
  color: #dee2e6;
  background-color: #343a40;
  border-color: rgba(0, 0, 0, 0.15);
}
.dropdown-menu-dark .dropdown-item {
  color: #dee2e6;
}
.dropdown-menu-dark .dropdown-item:hover, .dropdown-menu-dark .dropdown-item:focus {
  color: #fff;
  background-color: rgba(255, 255, 255, 0.15);
}
.dropdown-menu-dark .dropdown-item.active, .dropdown-menu-dark .dropdown-item:active {
  color: #fff;
  background-color: #0d6efd;
}
.dropdown-menu-dark .dropdown-item.disabled, .dropdown-menu-dark .dropdown-item:disabled {
  color: #adb5bd;
}
.dropdown-menu-dark .dropdown-divider {
  border-color: rgba(0, 0, 0, 0.15);
}
.dropdown-menu-dark .dropdown-item-text {
  color: #dee2e6;
}
.dropdown-menu-dark .dropdown-header {
  color: #adb5bd;
}

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle;
}
.btn-group > .btn,
.btn-group-vertical > .btn {
  position: relative;
  flex: 1 1 auto;
}
.btn-group > .btn-check:checked + .btn,
.btn-group > .btn-check:focus + .btn,
.btn-group > .btn:hover,
.btn-group > .btn:focus,
.btn-group > .btn:active,
.btn-group > .btn.active,
.btn-group-vertical > .btn-check:checked + .btn,
.btn-group-vertical > .btn-check:focus + .btn,
.btn-group-vertical > .btn:hover,
.btn-group-vertical > .btn:focus,
.btn-group-vertical > .btn:active,
.btn-group-vertical > .btn.active {
  z-index: 1;
}

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.btn-toolbar .input-group {
  width: auto;
}

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) {
  margin-left: -1px;
}
.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.btn-group > .btn:nth-child(n+3),
.btn-group > :not(.btn-check) + .btn,
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.dropdown-toggle-split {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem;
}
.dropdown-toggle-split::after, .dropup .dropdown-toggle-split::after, .dropend .dropdown-toggle-split::after {
  margin-left: 0;
}
.dropstart .dropdown-toggle-split::before {
  margin-right: 0;
}

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem;
}

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem;
}

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.btn-group-vertical > .btn,
.btn-group-vertical > .btn-group {
  width: 100%;
}
.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) {
  margin-top: -1px;
}
.btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group-vertical > .btn-group:not(:last-child) > .btn {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.btn-group-vertical > .btn ~ .btn,
.btn-group-vertical > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-link {
  display: block;
  padding: 0.5rem 1rem;
  color: #0d6efd;
  text-decoration: none;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .nav-link {
    transition: none;
  }
}
.nav-link:hover, .nav-link:focus {
  color: #0a58ca;
}
.nav-link.disabled {
  color: #6c757d;
  pointer-events: none;
  cursor: default;
}

.nav-tabs {
  border-bottom: 1px solid #dee2e6;
}
.nav-tabs .nav-link {
  margin-bottom: -1px;
  background: none;
  border: 1px solid transparent;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}
.nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
  border-color: #e9ecef #e9ecef #dee2e6;
  isolation: isolate;
}
.nav-tabs .nav-link.disabled {
  color: #6c757d;
  background-color: transparent;
  border-color: transparent;
}
.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: #495057;
  background-color: #fff;
  border-color: #dee2e6 #dee2e6 #fff;
}
.nav-tabs .dropdown-menu {
  margin-top: -1px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav-pills .nav-link {
  background: none;
  border: 0;
  border-radius: 0.25rem;
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #0d6efd;
}

.nav-fill > .nav-link,
.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center;
}

.nav-justified > .nav-link,
.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
}

.nav-fill .nav-item .nav-link,
.nav-justified .nav-item .nav-link {
  width: 100%;
}

.tab-content > .tab-pane {
  display: none;
}
.tab-content > .active {
  display: block;
}

.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: 0.25rem;
}

.list-group-numbered {
  list-style-type: none;
  counter-reset: section;
}
.list-group-numbered > li::before {
  content: counters(section, ".") ". ";
  counter-increment: section;
}

.list-group-item-action {
  width: 100%;
  color: #495057;
  text-align: inherit;
}
.list-group-item-action:hover, .list-group-item-action:focus {
  z-index: 1;
  color: #495057;
  text-decoration: none;
  background-color: #f8f9fa;
}
.list-group-item-action:active {
  color: #212529;
  background-color: #e9ecef;
}

.list-group-item {
  position: relative;
  display: block;
  padding: 0.5rem 1rem;
  color: #212529;
  text-decoration: none;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125);
}
.list-group-item:first-child {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}
.list-group-item:last-child {
  border-bottom-right-radius: inherit;
  border-bottom-left-radius: inherit;
}
.list-group-item.disabled, .list-group-item:disabled {
  color: #6c757d;
  pointer-events: none;
  background-color: #fff;
}
.list-group-item.active {
  z-index: 2;
  color: #fff;
  background-color: #0d6efd;
  border-color: #0d6efd;
}
.list-group-item + .list-group-item {
  border-top-width: 0;
}
.list-group-item + .list-group-item.active {
  margin-top: -1px;
  border-top-width: 1px;
}

.list-group-horizontal {
  flex-direction: row;
}
.list-group-horizontal > .list-group-item:first-child {
  border-bottom-left-radius: 0.25rem;
  border-top-right-radius: 0;
}
.list-group-horizontal > .list-group-item:last-child {
  border-top-right-radius: 0.25rem;
  border-bottom-left-radius: 0;
}
.list-group-horizontal > .list-group-item.active {
  margin-top: 0;
}
.list-group-horizontal > .list-group-item + .list-group-item {
  border-top-width: 1px;
  border-left-width: 0;
}
.list-group-horizontal > .list-group-item + .list-group-item.active {
  margin-left: -1px;
  border-left-width: 1px;
}

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row;
  }
  .list-group-horizontal-sm > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row;
  }
  .list-group-horizontal-md > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row;
  }
  .list-group-horizontal-lg > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row;
  }
  .list-group-horizontal-xl > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 1400px) {
  .list-group-horizontal-xxl {
    flex-direction: row;
  }
  .list-group-horizontal-xxl > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xxl > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-xxl > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xxl > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-xxl > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
.list-group-flush {
  border-radius: 0;
}
.list-group-flush > .list-group-item {
  border-width: 0 0 1px;
}
.list-group-flush > .list-group-item:last-child {
  border-bottom-width: 0;
}

.list-group-item-primary {
  color: #084298;
  background-color: #cfe2ff;
}
.list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
  color: #084298;
  background-color: #bacbe6;
}
.list-group-item-primary.list-group-item-action.active {
  color: #fff;
  background-color: #084298;
  border-color: #084298;
}

.list-group-item-secondary {
  color: #41464b;
  background-color: #e2e3e5;
}
.list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
  color: #41464b;
  background-color: #cbccce;
}
.list-group-item-secondary.list-group-item-action.active {
  color: #fff;
  background-color: #41464b;
  border-color: #41464b;
}

.list-group-item-success {
  color: #0f5132;
  background-color: #d1e7dd;
}
.list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
  color: #0f5132;
  background-color: #bcd0c7;
}
.list-group-item-success.list-group-item-action.active {
  color: #fff;
  background-color: #0f5132;
  border-color: #0f5132;
}

.list-group-item-info {
  color: #055160;
  background-color: #cff4fc;
}
.list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
  color: #055160;
  background-color: #badce3;
}
.list-group-item-info.list-group-item-action.active {
  color: #fff;
  background-color: #055160;
  border-color: #055160;
}

.list-group-item-warning {
  color: #664d03;
  background-color: #fff3cd;
}
.list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
  color: #664d03;
  background-color: #e6dbb9;
}
.list-group-item-warning.list-group-item-action.active {
  color: #fff;
  background-color: #664d03;
  border-color: #664d03;
}

.list-group-item-danger {
  color: #842029;
  background-color: #f8d7da;
}
.list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
  color: #842029;
  background-color: #dfc2c4;
}
.list-group-item-danger.list-group-item-action.active {
  color: #fff;
  background-color: #842029;
  border-color: #842029;
}

.list-group-item-light {
  color: #636464;
  background-color: #fefefe;
}
.list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
  color: #636464;
  background-color: #e5e5e5;
}
.list-group-item-light.list-group-item-action.active {
  color: #fff;
  background-color: #636464;
  border-color: #636464;
}

.list-group-item-dark {
  color: #141619;
  background-color: #d3d3d4;
}
.list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
  color: #141619;
  background-color: #bebebf;
}
.list-group-item-dark.list-group-item-action.active {
  color: #fff;
  background-color: #141619;
  border-color: #141619;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1055;
  display: none;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none;
}
.modal.fade .modal-dialog {
  transition: transform 0.3s ease-out;
  transform: translate(0, -50px);
}
@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}
.modal.show .modal-dialog {
  transform: none;
}
.modal.modal-static .modal-dialog {
  transform: scale(1.02);
}

.modal-dialog-scrollable {
  height: calc(100% - 1rem);
}
.modal-dialog-scrollable .modal-content {
  max-height: 100%;
  overflow: hidden;
}
.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem);
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  width: 100vw;
  height: 100vh;
  background-color: #000;
}
.modal-backdrop.fade {
  opacity: 0;
}
.modal-backdrop.show {
  opacity: 0.5;
}

.modal-header {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #dee2e6;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
}
.modal-header .btn-close {
  padding: 0.5rem 0.5rem;
  margin: -0.5rem -0.5rem -0.5rem auto;
}

.modal-title {
  margin-bottom: 0;
  line-height: 1.5;
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem;
}

.modal-footer {
  display: flex;
  flex-wrap: wrap;
  flex-shrink: 0;
  align-items: center;
  justify-content: flex-end;
  padding: 0.75rem;
  border-top: 1px solid #dee2e6;
  border-bottom-right-radius: calc(0.3rem - 1px);
  border-bottom-left-radius: calc(0.3rem - 1px);
}
.modal-footer > * {
  margin: 0.25rem;
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto;
  }
  .modal-dialog-scrollable {
    height: calc(100% - 3.5rem);
  }
  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem);
  }
  .modal-sm {
    max-width: 300px;
  }
}
@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    max-width: 800px;
  }
}
@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px;
  }
}
.modal-fullscreen {
  width: 100vw;
  max-width: none;
  height: 100%;
  margin: 0;
}
.modal-fullscreen .modal-content {
  height: 100%;
  border: 0;
  border-radius: 0;
}
.modal-fullscreen .modal-header {
  border-radius: 0;
}
.modal-fullscreen .modal-body {
  overflow-y: auto;
}
.modal-fullscreen .modal-footer {
  border-radius: 0;
}

@media (max-width: 575.98px) {
  .modal-fullscreen-sm-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-sm-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-sm-down .modal-header {
    border-radius: 0;
  }
  .modal-fullscreen-sm-down .modal-body {
    overflow-y: auto;
  }
  .modal-fullscreen-sm-down .modal-footer {
    border-radius: 0;
  }
}
@media (max-width: 767.98px) {
  .modal-fullscreen-md-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-md-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-md-down .modal-header {
    border-radius: 0;
  }
  .modal-fullscreen-md-down .modal-body {
    overflow-y: auto;
  }
  .modal-fullscreen-md-down .modal-footer {
    border-radius: 0;
  }
}
@media (max-width: 991.98px) {
  .modal-fullscreen-lg-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-lg-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-lg-down .modal-header {
    border-radius: 0;
  }
  .modal-fullscreen-lg-down .modal-body {
    overflow-y: auto;
  }
  .modal-fullscreen-lg-down .modal-footer {
    border-radius: 0;
  }
}
@media (max-width: 1199.98px) {
  .modal-fullscreen-xl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-xl-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-xl-down .modal-header {
    border-radius: 0;
  }
  .modal-fullscreen-xl-down .modal-body {
    overflow-y: auto;
  }
  .modal-fullscreen-xl-down .modal-footer {
    border-radius: 0;
  }
}
@media (max-width: 1399.98px) {
  .modal-fullscreen-xxl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-xxl-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-xxl-down .modal-header {
    border-radius: 0;
  }
  .modal-fullscreen-xxl-down .modal-body {
    overflow-y: auto;
  }
  .modal-fullscreen-xxl-down .modal-footer {
    border-radius: 0;
  }
}
.popover {
  position: absolute;
  top: 0;
  left: 0 /* rtl:ignore */;
  z-index: 1070;
  display: block;
  max-width: 276px;
  font-family: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
}
.popover .popover-arrow {
  position: absolute;
  display: block;
  width: 1rem;
  height: 0.5rem;
}
.popover .popover-arrow::before, .popover .popover-arrow::after {
  position: absolute;
  display: block;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-popover-top > .popover-arrow, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow {
  bottom: calc(-0.5rem - 1px);
}
.bs-popover-top > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow::before {
  bottom: 0;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-top > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow::after {
  bottom: 1px;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: #fff;
}

.bs-popover-end > .popover-arrow, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow {
  left: calc(-0.5rem - 1px);
  width: 0.5rem;
  height: 1rem;
}
.bs-popover-end > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow::before {
  left: 0;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-end > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow::after {
  left: 1px;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: #fff;
}

.bs-popover-bottom > .popover-arrow, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow {
  top: calc(-0.5rem - 1px);
}
.bs-popover-bottom > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::before {
  top: 0;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-bottom > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::after {
  top: 1px;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: #fff;
}
.bs-popover-bottom .popover-header::before, .bs-popover-auto[data-popper-placement^=bottom] .popover-header::before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: 1rem;
  margin-left: -0.5rem;
  content: "";
  border-bottom: 1px solid #f0f0f0;
}

.bs-popover-start > .popover-arrow, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow {
  right: calc(-0.5rem - 1px);
  width: 0.5rem;
  height: 1rem;
}
.bs-popover-start > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow::before {
  right: 0;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-start > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow::after {
  right: 1px;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: #fff;
}

.popover-header {
  padding: 0.5rem 1rem;
  margin-bottom: 0;
  font-size: 1rem;
  background-color: #f0f0f0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
}
.popover-header:empty {
  display: none;
}

.popover-body {
  padding: 1rem 1rem;
  color: #212529;
}

/* Old Colors

$color0: #ededed; // white gray
$color1: rgb(190,190,190); // #bebebe - light gray
$color2: rgb(80,80,80); // #505050 - gray
$color3: rgb(207,214,224); // #cfd6e0 - white blue
$color4: rgb(159,172,193); // #9facc1 - light blue
$color5: rgb(88,111,146); // #586f92 - mid blue
$color6: rgb(15,48,99); // #1B3D6D - dark blue
$color7: rgb(57,213,31); // #39d51f - green
$color8: rgb(52,197,26); // #34C51A - dark green
*/
/*Theming*/
:root {
  --loading-login-screen-background:
          linear-gradient(to right, rgba(15, 48, 99, 0) 0%, rgba(75, 107, 155, .4) 25%, rgba(168, 187, 215, .4) 42%, rgba(168, 187, 215, .4) 58%, rgba(75, 107, 155, .4) 75%, rgba(15, 48, 99, 0) 100%),
          url(".././assets/images/pattern-block-lines.png") repeat;
  --loading-screen-logo: url(".././assets/images/bakerhill-nextgen-logo-white.png");
  --login-box-logo: url(".././assets/images/bakerhill-nextgen-logo.png");
  --primary-dark-color: #1B3D6D;
  --primary-color: #456aa1;
  --primary-color-darken-7percent: #3a5a88;
  --primary-color-darken-11percent: #34507a;
  --primary-color-lighten-10percent: #5f84ba;
  --primary-color-lighten-20percent: #829fca;
  --primary-color-lighten-40percent: #cad6e8;
  --primary-light1-color: #a6bad9;
  --primary-light1-color-darken-5percent:#94acd1;
  --primary-light1-color-darken-10percent: #829eca;
  --primary-light1-color-darken-15percent: #7090c2;
  --primary-light1-color-lighten-6percent: #bbcbe2;
  --primary-white-color: #e6ecf2;
  --primary-white-color-darken-2percent: #dfe7ef;
  --primary-white-color-darken-6percent: #d2dde8;
  --primary-white-color-lighten-2percent: #edf1f5;
  --primary-white-color-lighten-4percent: #f3f6f9;
  --primary-color2: #cddc29;
  --primary-color3: #81BD41;
  --primary-color3-lighten-40percent: #e5f2d8;
  --primary-color3-darken-5percent: #74aa3a;
  --primary-dark2-color: #689834;
  --primary-dark2-color-darken-10percent: #4e7227;
  --primary-light2-color: #e6f2d9;
  --primary-light2-color-darken-2percent: #e1efd1;
  --primary-light2-color-darken-6percent: #d7eac2;
  --primary-light2-color-darken-10percent: #cde5b3;
  --grayscale-color: #58595B;
  --grayscale-color-lighten-25percent: #98999b;
  --grayscale-light-color: #bdbdc2;
  --grayscale-light-color-darken-8percent: #a8a8ae;
  --grayscale-light-color-lighten-7percent: #d0d0d3;
  --grayscale-white-color: #ebebed;
  --grayscale-white-color-darken-5percent: #dedee1;
  --grayscale-white-color-darken-8percent: #d6d6da;
  --grayscale-white-color-lighten-2percent: #f0f0f2;
  --grayscale-white-color-lighten-5percent: #f8f8f9;
  --white-color: #ffffff;
  --black-color: #000000;
  --secondary-dark-color: #007299;
  --secondary-color: #77256c;
  --secondary-color2: #f68d29;
  --secondary-color3: #ffd240;
  --secondary-color3-darken-3percent: #ffce31;
  --secondary-color3-lighten-17percent: #ffe697;
  --secondary-color3-lighten-27percent: #fff2ca;
  --secondary-bright-color: #ece815;
  --error-color: #cc0000;
  --error-color-darken-10percent: #990000;
  --error-color-lighten-50percent: #ffcccc;
  --error-color-lighten-55percent: #ffe6e6;
  --error-light-color: #ffe6e6;
  --error-light-color-darken-5percent: #ffcdcd;
  --disabled-color: #abbcd5;
  --disabled-color2: #9badca;
  --disabled-color3: #a7d17a;
  --default-sprite-position: top left;
  --active-hover-sprite-position: top right;
  /*remaing colors*/
  --snow-gray: #fcfcfc;
  --light-gainsboro-gray: #f7f7f7;
  --white-smoke: #f4f4f4;
  --very-light-gray: #f0f0f0;
  --light-pink: #febbbb;
  --light-yellow: #ffffe5;
  --orange-peel: #ff9900;
  --electric-yellow: #ffff00;
  --yellow-green: #effadb;
  --whisper-gray: #ededed;
  --dark-whisper-gray: #eeeeee;
  --white-dark-smoke: #e6e6e6;
  --gainsboro-gray: #e0e0e0;
  --medium-gray: #cccccc;
  --silver: #c6c6c6;
  --silver-very-light: #b9b9b9;
  --silver-light: #bbbbbb;
  --blue-smoke: #eef1f7;
  --ivory-red: #f8f8f2;
  --spun-pearl: #afafb6;
  --very-light-blue: #e6ebf5;
  --dark-gainsboro-gray: #d9d9d9;
  --bright-gainsboro-gray: #dddddd;
  --hawakes-blue: #ccd6eb;
  --blue-violet: #9933ff;
  --nobel-gray: #999999;
  --light-danube-blue: #89a4cd;
  --matte-gray: #888888;
  --light-matte-gray: #787878;
  --dark-gray: #777777;
  --raven-gray: #707275;
  --danube-blue: #6685c2;
  --dim-gray: #666666;
  --davy-grey: #555555;
  --analog-blue: #435f8b;
  --charcoal-gray: #444444;
  --mariner-blue: #335cad;
  --dark-charcoal-gray: #333333;
  --green-waterloo: #23241f;
  --nero-dark: #222222;
  --smalt-blue: #003399;
  --deep-green: #008a00;
  --navy-blue: #0066cc;
  --bondi-blue: #0085c7;
  --violation-text: #cc0000;
  --violation-bg: #ffe6e6;
  --warning-text: #f68d29;
  --warning-bg: #fff2ca;
  --highcharts-color-2: #cddc29;
  --highcharts-color-3: #007299;
  --highcharts-color-6: #689834;
  --highcharts-color-7: #a6bad9;
}

[data-theme=fiserv] {
  --loading-login-screen-background: #666666;
  --loading-screen-logo: url(".././assets/images/Fiserv-logo-1200x603.png");
  --login-box-logo: url(".././assets/images/Fiserv-logo-1200x603.png");
  --primary-dark-color: #333333;
  --primary-color: #666666;
  --primary-color-darken-7percent: #545454;
  --primary-color-darken-11percent: #4a4a4a;
  --primary-color-lighten-10percent: gray;
  --primary-color-lighten-20percent: #999999;
  --primary-color-lighten-40percent: #cccccc;
  --primary-light1-color: #bfbfbf;
  --primary-light1-color-darken-5percent: #b2b2b2;
  --primary-light1-color-darken-10percent: #a6a6a6;
  --primary-light1-color-darken-15percent: #999999;
  --primary-light1-color-lighten-6percent: #cecece;
  --primary-white-color: #efefef;
  --primary-white-color-darken-2percent: #eaeaea;
  --primary-white-color-darken-6percent: #e0e0e0;
  --primary-white-color-lighten-2percent: #f4f4f4;
  --primary-white-color-lighten-4percent: #f9f9f9;
  --primary-color2: #4695d1;
  --primary-color3: #ff6600;
  --primary-color3-lighten-40percent: #ffe0cc;
  --primary-color3-darken-5percent: #e65c00;
  --primary-dark2-color: #ea6b15;
  --primary-dark2-color-darken-10percent: #bb5611;
  --primary-light2-color: #f2d3c4;
  --primary-light2-color-darken-2percent: #f0cdbc;
  --primary-light2-color-darken-6percent: #ecc0ab;
  --primary-light2-color-darken-10percent: #e9b49a;
  --grayscale-color: #666666;
  --grayscale-color-lighten-25percent: #a6a6a6;
  --grayscale-light-color: #e0e0e0;
  --grayscale-light-color-darken-8percent: #cccccc;
  --grayscale-light-color-lighten-7percent: #f2f2f2;
  --grayscale-white-color: #e0e0e0;
  --grayscale-white-color-darken-5percent: lightgray;
  --grayscale-white-color-darken-8percent: #cccccc;
  --grayscale-white-color-lighten-2percent: #e5e5e5;
  --grayscale-white-color-lighten-5percent: #ededed;
  --white-color: #ffffff;
  --black-color: #0e0e0e;
  --secondary-dark-color: #007299;
  --secondary-color: #77256c;
  --secondary-color2: #81bd41;
  --secondary-color3: #ffd240;
  --secondary-color3-darken-3percent: #ffce31;
  --secondary-color3-lighten-17percent: #ffe697;
  --secondary-color3-lighten-27percent: #fff2ca;
  --secondary-bright-color: #ece815;
  --error-color: #dd3435;
  --error-color-darken-10percent: #be2021;
  --error-color-lighten-50percent: white;
  --error-color-lighten-55percent: white;
  --error-light-color: #eabec0;
  --error-light-color-darken-5percent: #e4abad;
  --disabled-color: #c9c9c9;
  --disabled-color2: #c9c9c9;
  --disabled-color3: #dd9e73;
  --default-sprite-position: bottom left;
  --active-hover-sprite-position: bottom right;
  /*remaing colors*/
  --snow-gray: #fcfcfc;
  --light-gainsboro-gray: #f7f7f7;
  --white-smoke: #f4f4f4;
  --very-light-gray: #f0f0f0;
  --light-pink: #febbbb;
  --light-yellow: #ffffe5;
  --orange-peel: #ff9900;
  --electric-yellow: #ffff00;
  --yellow-green: #effadb;
  --whisper-gray: #ededed;
  --dark-whisper-gray: #eeeeee;
  --white-dark-smoke: #e6e6e6;
  --gainsboro-gray: #e0e0e0;
  --medium-gray: #cccccc;
  --silver: #c6c6c6;
  --silver-very-light: #b9b9b9;
  --silver-light: #bbbbbb;
  --blue-smoke: #eef1f7;
  --ivory-red: #f8f8f2;
  --spun-pearl: #afafb6;
  --very-light-blue: #e6ebf5;
  --dark-gainsboro-gray: #d9d9d9;
  --bright-gainsboro-gray: #dddddd;
  --hawakes-blue: #ccd6eb;
  --blue-violet: #9933ff;
  --nobel-gray: #999999;
  --light-danube-blue: #89a4cd;
  --matte-gray: #888888;
  --light-matte-gray: #787878;
  --dark-gray: #777777;
  --raven-gray: #707275;
  --danube-blue: #6685c2;
  --dim-gray: #666666;
  --davy-grey: #555555;
  --analog-blue: #435f8b;
  --charcoal-gray: #444444;
  --mariner-blue: #335cad;
  --dark-charcoal-gray: #333333;
  --green-waterloo: #23241f;
  --nero-dark: #222222;
  --smalt-blue: #003399;
  --deep-green: #008a00;
  --navy-blue: #0066cc;
  --bondi-blue: #0085c7;
  --violation-text: #cc0000;
  --violation-bg: #ffe6e6;
  --warning-text: #f68d29;
  --warning-bg: #fff2ca;
  --highcharts-color-2: #ffa366;
  --highcharts-color-3: #777777;
  --highcharts-color-6: #e65c00;
  --highcharts-color-7: #e0e0e0;
}

/*Sprite Positions*/
/*Images*/
/*Primary*/
/*Grayscale*/
/*Secondary*/
/*disabled*/
/*remaing colors*/
.darkblue {
  color: var(--primary-dark-color);
}

.blue {
  color: var(--primary-color);
}

.lightblue {
  color: var(--primary-light1-color);
}

.whiteblue {
  color: var(--primary-white-color);
}

.yellowgreen {
  color: var(--primary-color2);
}

.green {
  color: var(--primary-color3) !important;
}

.darkgreen {
  color: var(--primary-dark2-color);
}

.lightgreen {
  color: var(--primary-light2-color);
}

.gray {
  color: var(--grayscale-color);
}

.lightgray {
  color: var(--grayscale-light-color);
}

.whitegray {
  color: var(--grayscale-white-color);
}

.darkcyan {
  color: var(--secondary-dark-color);
}

.purple {
  color: var(--secondary-color);
}

.orange {
  color: var(--secondary-color2) !important;
}

.yellow {
  color: var(--secondary-color3);
}

.brightyellow {
  color: var(--secondary-bright-color);
}

.red {
  color: var(--error-color) !important;
}

.lightred {
  color: var(--error-light-color);
}

.white {
  color: var(--white-color) !important;
}

.darkblue-bg {
  background-color: var(--primary-dark-color);
}

.blue-bg {
  background-color: var(--primary-color) !important;
}

.lightblue-bg {
  background-color: var(--primary-light1-color);
}

.whiteblue-bg {
  background-color: var(--primary-white-color);
}

.yellowgreen-bg {
  background-color: var(--primary-color2);
}

.green-bg {
  background-color: var(--primary-color3);
}

.darkgreen-bg {
  background-color: var(--primary-dark2-color);
}

.lightgreen-bg {
  background-color: var(--primary-light2-color);
}

.gray-bg {
  background-color: var(--grayscale-color);
}

.lightgray-bg {
  background-color: var(--grayscale-light-color);
}

.whitegray-bg {
  background-color: var(--grayscale-white-color);
}

.darkcyan-bg {
  background-color: var(--secondary-dark-color);
}

.purple-bg {
  background-color: var(--secondary-color);
}

.orange-bg {
  background-color: var(--secondary-color2) !important;
}

.yellow-bg {
  background-color: var(--secondary-color3) !important;
}

.brightyellow-bg {
  background-color: var(--secondary-bright-color) !important;
}

.red-bg {
  background-color: var(--error-color) !important;
}

.lightred-bg {
  background-color: var(--error-light-color) !important;
}

/* Old Colors

$color0: #ededed; // white gray
$color1: rgb(190,190,190); // #bebebe - light gray
$color2: rgb(80,80,80); // #505050 - gray
$color3: rgb(207,214,224); // #cfd6e0 - white blue
$color4: rgb(159,172,193); // #9facc1 - light blue
$color5: rgb(88,111,146); // #586f92 - mid blue
$color6: rgb(15,48,99); // #1B3D6D - dark blue
$color7: rgb(57,213,31); // #39d51f - green
$color8: rgb(52,197,26); // #34C51A - dark green
*/
/*Theming*/
:root {
  --loading-login-screen-background:
          linear-gradient(to right, rgba(15, 48, 99, 0) 0%, rgba(75, 107, 155, .4) 25%, rgba(168, 187, 215, .4) 42%, rgba(168, 187, 215, .4) 58%, rgba(75, 107, 155, .4) 75%, rgba(15, 48, 99, 0) 100%),
          url(".././assets/images/pattern-block-lines.png") repeat;
  --loading-screen-logo: url(".././assets/images/bakerhill-nextgen-logo-white.png");
  --login-box-logo: url(".././assets/images/bakerhill-nextgen-logo.png");
  --primary-dark-color: #1B3D6D;
  --primary-color: #456aa1;
  --primary-color-darken-7percent: #3a5a88;
  --primary-color-darken-11percent: #34507a;
  --primary-color-lighten-10percent: #5f84ba;
  --primary-color-lighten-20percent: #829fca;
  --primary-color-lighten-40percent: #cad6e8;
  --primary-light1-color: #a6bad9;
  --primary-light1-color-darken-5percent:#94acd1;
  --primary-light1-color-darken-10percent: #829eca;
  --primary-light1-color-darken-15percent: #7090c2;
  --primary-light1-color-lighten-6percent: #bbcbe2;
  --primary-white-color: #e6ecf2;
  --primary-white-color-darken-2percent: #dfe7ef;
  --primary-white-color-darken-6percent: #d2dde8;
  --primary-white-color-lighten-2percent: #edf1f5;
  --primary-white-color-lighten-4percent: #f3f6f9;
  --primary-color2: #cddc29;
  --primary-color3: #81BD41;
  --primary-color3-lighten-40percent: #e5f2d8;
  --primary-color3-darken-5percent: #74aa3a;
  --primary-dark2-color: #689834;
  --primary-dark2-color-darken-10percent: #4e7227;
  --primary-light2-color: #e6f2d9;
  --primary-light2-color-darken-2percent: #e1efd1;
  --primary-light2-color-darken-6percent: #d7eac2;
  --primary-light2-color-darken-10percent: #cde5b3;
  --grayscale-color: #58595B;
  --grayscale-color-lighten-25percent: #98999b;
  --grayscale-light-color: #bdbdc2;
  --grayscale-light-color-darken-8percent: #a8a8ae;
  --grayscale-light-color-lighten-7percent: #d0d0d3;
  --grayscale-white-color: #ebebed;
  --grayscale-white-color-darken-5percent: #dedee1;
  --grayscale-white-color-darken-8percent: #d6d6da;
  --grayscale-white-color-lighten-2percent: #f0f0f2;
  --grayscale-white-color-lighten-5percent: #f8f8f9;
  --white-color: #ffffff;
  --black-color: #000000;
  --secondary-dark-color: #007299;
  --secondary-color: #77256c;
  --secondary-color2: #f68d29;
  --secondary-color3: #ffd240;
  --secondary-color3-darken-3percent: #ffce31;
  --secondary-color3-lighten-17percent: #ffe697;
  --secondary-color3-lighten-27percent: #fff2ca;
  --secondary-bright-color: #ece815;
  --error-color: #cc0000;
  --error-color-darken-10percent: #990000;
  --error-color-lighten-50percent: #ffcccc;
  --error-color-lighten-55percent: #ffe6e6;
  --error-light-color: #ffe6e6;
  --error-light-color-darken-5percent: #ffcdcd;
  --disabled-color: #abbcd5;
  --disabled-color2: #9badca;
  --disabled-color3: #a7d17a;
  --default-sprite-position: top left;
  --active-hover-sprite-position: top right;
  /*remaing colors*/
  --snow-gray: #fcfcfc;
  --light-gainsboro-gray: #f7f7f7;
  --white-smoke: #f4f4f4;
  --very-light-gray: #f0f0f0;
  --light-pink: #febbbb;
  --light-yellow: #ffffe5;
  --orange-peel: #ff9900;
  --electric-yellow: #ffff00;
  --yellow-green: #effadb;
  --whisper-gray: #ededed;
  --dark-whisper-gray: #eeeeee;
  --white-dark-smoke: #e6e6e6;
  --gainsboro-gray: #e0e0e0;
  --medium-gray: #cccccc;
  --silver: #c6c6c6;
  --silver-very-light: #b9b9b9;
  --silver-light: #bbbbbb;
  --blue-smoke: #eef1f7;
  --ivory-red: #f8f8f2;
  --spun-pearl: #afafb6;
  --very-light-blue: #e6ebf5;
  --dark-gainsboro-gray: #d9d9d9;
  --bright-gainsboro-gray: #dddddd;
  --hawakes-blue: #ccd6eb;
  --blue-violet: #9933ff;
  --nobel-gray: #999999;
  --light-danube-blue: #89a4cd;
  --matte-gray: #888888;
  --light-matte-gray: #787878;
  --dark-gray: #777777;
  --raven-gray: #707275;
  --danube-blue: #6685c2;
  --dim-gray: #666666;
  --davy-grey: #555555;
  --analog-blue: #435f8b;
  --charcoal-gray: #444444;
  --mariner-blue: #335cad;
  --dark-charcoal-gray: #333333;
  --green-waterloo: #23241f;
  --nero-dark: #222222;
  --smalt-blue: #003399;
  --deep-green: #008a00;
  --navy-blue: #0066cc;
  --bondi-blue: #0085c7;
  --violation-text: #cc0000;
  --violation-bg: #ffe6e6;
  --warning-text: #f68d29;
  --warning-bg: #fff2ca;
  --highcharts-color-2: #cddc29;
  --highcharts-color-3: #007299;
  --highcharts-color-6: #689834;
  --highcharts-color-7: #a6bad9;
}

[data-theme=fiserv] {
  --loading-login-screen-background: #666666;
  --loading-screen-logo: url(".././assets/images/Fiserv-logo-1200x603.png");
  --login-box-logo: url(".././assets/images/Fiserv-logo-1200x603.png");
  --primary-dark-color: #333333;
  --primary-color: #666666;
  --primary-color-darken-7percent: #545454;
  --primary-color-darken-11percent: #4a4a4a;
  --primary-color-lighten-10percent: gray;
  --primary-color-lighten-20percent: #999999;
  --primary-color-lighten-40percent: #cccccc;
  --primary-light1-color: #bfbfbf;
  --primary-light1-color-darken-5percent: #b2b2b2;
  --primary-light1-color-darken-10percent: #a6a6a6;
  --primary-light1-color-darken-15percent: #999999;
  --primary-light1-color-lighten-6percent: #cecece;
  --primary-white-color: #efefef;
  --primary-white-color-darken-2percent: #eaeaea;
  --primary-white-color-darken-6percent: #e0e0e0;
  --primary-white-color-lighten-2percent: #f4f4f4;
  --primary-white-color-lighten-4percent: #f9f9f9;
  --primary-color2: #4695d1;
  --primary-color3: #ff6600;
  --primary-color3-lighten-40percent: #ffe0cc;
  --primary-color3-darken-5percent: #e65c00;
  --primary-dark2-color: #ea6b15;
  --primary-dark2-color-darken-10percent: #bb5611;
  --primary-light2-color: #f2d3c4;
  --primary-light2-color-darken-2percent: #f0cdbc;
  --primary-light2-color-darken-6percent: #ecc0ab;
  --primary-light2-color-darken-10percent: #e9b49a;
  --grayscale-color: #666666;
  --grayscale-color-lighten-25percent: #a6a6a6;
  --grayscale-light-color: #e0e0e0;
  --grayscale-light-color-darken-8percent: #cccccc;
  --grayscale-light-color-lighten-7percent: #f2f2f2;
  --grayscale-white-color: #e0e0e0;
  --grayscale-white-color-darken-5percent: lightgray;
  --grayscale-white-color-darken-8percent: #cccccc;
  --grayscale-white-color-lighten-2percent: #e5e5e5;
  --grayscale-white-color-lighten-5percent: #ededed;
  --white-color: #ffffff;
  --black-color: #0e0e0e;
  --secondary-dark-color: #007299;
  --secondary-color: #77256c;
  --secondary-color2: #81bd41;
  --secondary-color3: #ffd240;
  --secondary-color3-darken-3percent: #ffce31;
  --secondary-color3-lighten-17percent: #ffe697;
  --secondary-color3-lighten-27percent: #fff2ca;
  --secondary-bright-color: #ece815;
  --error-color: #dd3435;
  --error-color-darken-10percent: #be2021;
  --error-color-lighten-50percent: white;
  --error-color-lighten-55percent: white;
  --error-light-color: #eabec0;
  --error-light-color-darken-5percent: #e4abad;
  --disabled-color: #c9c9c9;
  --disabled-color2: #c9c9c9;
  --disabled-color3: #dd9e73;
  --default-sprite-position: bottom left;
  --active-hover-sprite-position: bottom right;
  /*remaing colors*/
  --snow-gray: #fcfcfc;
  --light-gainsboro-gray: #f7f7f7;
  --white-smoke: #f4f4f4;
  --very-light-gray: #f0f0f0;
  --light-pink: #febbbb;
  --light-yellow: #ffffe5;
  --orange-peel: #ff9900;
  --electric-yellow: #ffff00;
  --yellow-green: #effadb;
  --whisper-gray: #ededed;
  --dark-whisper-gray: #eeeeee;
  --white-dark-smoke: #e6e6e6;
  --gainsboro-gray: #e0e0e0;
  --medium-gray: #cccccc;
  --silver: #c6c6c6;
  --silver-very-light: #b9b9b9;
  --silver-light: #bbbbbb;
  --blue-smoke: #eef1f7;
  --ivory-red: #f8f8f2;
  --spun-pearl: #afafb6;
  --very-light-blue: #e6ebf5;
  --dark-gainsboro-gray: #d9d9d9;
  --bright-gainsboro-gray: #dddddd;
  --hawakes-blue: #ccd6eb;
  --blue-violet: #9933ff;
  --nobel-gray: #999999;
  --light-danube-blue: #89a4cd;
  --matte-gray: #888888;
  --light-matte-gray: #787878;
  --dark-gray: #777777;
  --raven-gray: #707275;
  --danube-blue: #6685c2;
  --dim-gray: #666666;
  --davy-grey: #555555;
  --analog-blue: #435f8b;
  --charcoal-gray: #444444;
  --mariner-blue: #335cad;
  --dark-charcoal-gray: #333333;
  --green-waterloo: #23241f;
  --nero-dark: #222222;
  --smalt-blue: #003399;
  --deep-green: #008a00;
  --navy-blue: #0066cc;
  --bondi-blue: #0085c7;
  --violation-text: #cc0000;
  --violation-bg: #ffe6e6;
  --warning-text: #f68d29;
  --warning-bg: #fff2ca;
  --highcharts-color-2: #ffa366;
  --highcharts-color-3: #777777;
  --highcharts-color-6: #e65c00;
  --highcharts-color-7: #e0e0e0;
}

/*Sprite Positions*/
/*Images*/
/*Primary*/
/*Grayscale*/
/*Secondary*/
/*disabled*/
/*remaing colors*/
.darkblue {
  color: var(--primary-dark-color);
}

.blue {
  color: var(--primary-color);
}

.lightblue {
  color: var(--primary-light1-color);
}

.whiteblue {
  color: var(--primary-white-color);
}

.yellowgreen {
  color: var(--primary-color2);
}

.green {
  color: var(--primary-color3) !important;
}

.darkgreen {
  color: var(--primary-dark2-color);
}

.lightgreen {
  color: var(--primary-light2-color);
}

.gray {
  color: var(--grayscale-color);
}

.lightgray {
  color: var(--grayscale-light-color);
}

.whitegray {
  color: var(--grayscale-white-color);
}

.darkcyan {
  color: var(--secondary-dark-color);
}

.purple {
  color: var(--secondary-color);
}

.orange {
  color: var(--secondary-color2) !important;
}

.yellow {
  color: var(--secondary-color3);
}

.brightyellow {
  color: var(--secondary-bright-color);
}

.red {
  color: var(--error-color) !important;
}

.lightred {
  color: var(--error-light-color);
}

.white {
  color: var(--white-color) !important;
}

.darkblue-bg {
  background-color: var(--primary-dark-color);
}

.blue-bg {
  background-color: var(--primary-color) !important;
}

.lightblue-bg {
  background-color: var(--primary-light1-color);
}

.whiteblue-bg {
  background-color: var(--primary-white-color);
}

.yellowgreen-bg {
  background-color: var(--primary-color2);
}

.green-bg {
  background-color: var(--primary-color3);
}

.darkgreen-bg {
  background-color: var(--primary-dark2-color);
}

.lightgreen-bg {
  background-color: var(--primary-light2-color);
}

.gray-bg {
  background-color: var(--grayscale-color);
}

.lightgray-bg {
  background-color: var(--grayscale-light-color);
}

.whitegray-bg {
  background-color: var(--grayscale-white-color);
}

.darkcyan-bg {
  background-color: var(--secondary-dark-color);
}

.purple-bg {
  background-color: var(--secondary-color);
}

.orange-bg {
  background-color: var(--secondary-color2) !important;
}

.yellow-bg {
  background-color: var(--secondary-color3) !important;
}

.brightyellow-bg {
  background-color: var(--secondary-bright-color) !important;
}

.red-bg {
  background-color: var(--error-color) !important;
}

.lightred-bg {
  background-color: var(--error-light-color) !important;
}

body {
  font-family: Arial, Helvetica, sans-serif;
  color: var(--grayscale-color);
  background-color: var(--white-color);
  font-size: 0.875rem;
}

a {
  color: var(--primary-color);
  text-decoration: none;
}
a:hover, a:focus {
  color: var(--primary-color3-darken-5percent);
  text-decoration: none;
}

h1, .h1 {
  font-size: 1.75rem;
}

h2, .h2 {
  font-size: 1.4rem;
}

h3, .h3 {
  font-size: 1.09375rem;
}

h4, .h4 {
  font-size: 0.9625rem;
}

.img-thumbnail {
  background-color: var(--white-color);
}

table {
  background-color: var(--white-color);
}

caption {
  padding-top: 8px 10px;
  padding-bottom: 8px 10px;
}

.table > thead > tr > th,
.table > thead > tr > td,
.table > tbody > tr > th,
.table > tbody > tr > td,
.table > tfoot > tr > th,
.table > tfoot > tr > td {
  padding: 8px 10px;
  border-top: 1px solid var(--primary-light1-color-darken-10percent);
}
.table > thead > tr > th {
  border-bottom: 2px solid var(--primary-light1-color-darken-10percent);
}
.table > tbody + tbody {
  border-top: 2px solid var(--primary-light1-color-darken-10percent);
}
.table .table {
  background-color: var(--white-color);
}

.table-condensed > thead > tr > th,
.table-condensed > thead > tr > td,
.table-condensed > tbody > tr > th,
.table-condensed > tbody > tr > td,
.table-condensed > tfoot > tr > th,
.table-condensed > tfoot > tr > td {
  padding: 6px;
}

.table-bordered {
  border: 1px solid var(--primary-light1-color-darken-10percent);
}
.table-bordered > thead > tr > th,
.table-bordered > thead > tr > td,
.table-bordered > tbody > tr > th,
.table-bordered > tbody > tr > td,
.table-bordered > tfoot > tr > th,
.table-bordered > tfoot > tr > td {
  border: 1px solid var(--primary-light1-color-darken-10percent);
}

.table-striped > tbody > tr:nth-of-type(odd) {
  background-color: var(--grayscale-white-color-lighten-2percent);
}

.table-hover > tbody > tr:hover {
  background-color: var(--grayscale-white-color);
}

@media screen and (max-width: 479px) {
  .table-responsive {
    border: 1px solid var(--primary-light1-color-darken-10percent);
  }
}

legend {
  border-bottom: 1px solid var(--grayscale-white-color);
}

output {
  color: var(--grayscale-color);
}

.form-control {
  color: var(--grayscale-color);
  background-color: var(--white-color);
  border: 1px solid var(--grayscale-light-color);
}
.form-control[disabled], .form-control[readonly],
.form-control fieldset[disabled] {
  background-color: var(--grayscale-white-color);
}

@media screen and (-webkit-min-device-pixel-ratio: 0) {
  input[type=date].input-lg,
  input[type=date] .input-group-lg,
  input[type=time].input-lg,
  input[type=time] .input-group-lg,
  input[type=datetime-local].input-lg,
  input[type=datetime-local] .input-group-lg,
  input[type=month].input-lg,
  input[type=month] .input-group-lg {
    line-height: 5.25rem;
  }
}
.form-group-lg .form-control {
  height: 5.25rem;
  font-size: 0.9625rem;
}
.form-group-lg select.form-control {
  height: 5.25rem;
  line-height: 5.25rem;
}
.form-group-lg .form-control-static {
  height: 5.25rem;
  min-height: 1.9625rem;
  font-size: 0.9625rem;
}

.input-lg + .form-control-feedback,
.input-group-lg + .form-control-feedback,
.form-group-lg .form-control + .form-control-feedback {
  width: 5.25rem;
  height: 5.25rem;
  line-height: 5.25rem;
}

.help-block {
  color: var(--grayscale-color-lighten-25percent);
}

@media (min-width: 768px) {
  .form-horizontal .form-group-lg .control-label {
    font-size: 0.9625rem;
  }
}

.dropdown-menu {
  background-color: var(--white-color);
  border: 1px solid var(--grayscale-light-color);
}
.dropdown-menu .divider .nav-divider {
  background: var(--grayscale-white-color);
}
.dropdown-menu li > a {
  color: var(--primary-color);
}
.dropdown-menu li > a:hover, .dropdown-menu li > a:focus {
  color: var(--primary-color);
  background-color: var(--grayscale-white-color);
}

.dropdown-menu > .active > a:hover, .dropdown-menu > .active > a:focus {
  color: var(--white-color);
  background-color: var(--primary-color);
}

.dropdown-menu > .disabled > a:hover, .dropdown-menu > .disabled > a:focus {
  color: var(--grayscale-white-color);
}

.dropdown-header {
  color: var(--grayscale-white-color);
}

.btn-link {
  color: var(--primary-color);
  background: unset;
  box-shadow: none !important;
}
.btn-link:hover, .btn-link:focus {
  color: var(--primary-color3-darken-5percent);
  text-decoration: none;
}

.input-group-append {
  color: var(--grayscale-color);
  border: 1px solid var(--grayscale-light-color);
}
.input-group-append.input-lg {
  font-size: 0.9625rem;
}

.nav li a {
  padding: 8px 14px;
  display: block;
  position: relative;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
}
.nav .show > a:hover, .nav .show > a:focus {
  border-color: var(--primary-color);
}

.navbar-brand {
  font-size: 0.9625rem;
}

.nav-tabs {
  border-bottom: 1px solid var(--grayscale-light-color);
}
.nav-tabs li a:hover, .nav-tabs li a:focus {
  color: var(--white-color);
  background-color: var(--primary-color);
  border: 1px solid var(--primary-color);
}
.nav-tabs li .active a {
  color: var(--white-color);
  background-color: var(--primary-color);
  border: 1px solid var(--primary-color);
}

.nav-pills > li.active > a:hover, .nav-pills > li.active > a:focus {
  color: var(--white-color);
  background-color: var(--primary-color);
}

.nav-tabs-justified > .active > a,
.nav-tabs-justified > .active > a:hover,
.nav-tabs-justified > .active > a:focus {
  border: 1px solid var(--primary-color);
}
@media (min-width: 768px) {
  .nav-tabs-justified > li > a {
    border-bottom: 1px solid var(--primary-color);
  }
  .nav-tabs-justified > .active > a,
  .nav-tabs-justified > .active > a:hover,
  .nav-tabs-justified > .active > a:focus {
    border-bottom-color: var(--primary-color);
  }
}

.label {
  color: var(--grayscale-color);
}
.label a:hover, .label a:focus {
  color: var(--white-color);
}

.list-group-item {
  background-color: var(--white-color);
  border: 1px solid var(--grayscale-color);
}
.list-group-item.active, .list-group-item.active:hover, .list-group-item.active:focus {
  color: var(--white-color);
  background-color: var(--primary-color);
  border-color: var(--primary-color);
}
.list-group-item.active .list-group-item-text, .list-group-item.active:hover .list-group-item-text, .list-group-item.active:focus .list-group-item-text {
  color: var(--primary-color-lighten-40percent);
}

a.list-group-item,
button.list-group-item {
  color: var(--grayscale-color);
}
a.list-group-item .list-group-item-heading,
button.list-group-item .list-group-item-heading {
  color: var(--grayscale-color);
}
a.list-group-item:hover, a.list-group-item:focus,
button.list-group-item:hover,
button.list-group-item:focus {
  color: var(--grayscale-color);
  background-color: var(--grayscale-white-color);
}

.panel {
  background-color: var(--white-color);
}

.panel-footer {
  background-color: var(--grayscale-white-color);
  border-top: 1px solid var(--grayscale-light-color);
}

.panel > .panel-body + .table,
.panel > .panel-body + .table-responsive,
.panel > .table + .panel-body,
.panel > .table-responsive + .panel-body {
  border-top: 1px solid var(--primary-light1-color-darken-10percent);
}

.panel-group .panel-heading + .panel-collapse > .panel-body,
.panel-group .panel-heading + .panel-collapse > .list-group {
  border-top: 1px solid var(--grayscale-light-color);
}
.panel-group .panel-footer + .panel-collapse .panel-body {
  border-bottom: 1px solid var(--grayscale-light-color);
}

.modal-content {
  background-color: var(--white-color);
  border: 1px solid var(--grayscale-color);
  border: 1px solid var(--primary-color);
}

.modal-backdrop {
  display: none;
}

.modal-header {
  padding: 15px 15px 10px;
  border-bottom: 1px solid var(--grayscale-color);
}

.modal-footer {
  border-top: 1px solid var(--grayscale-color);
}

.thumbnail {
  background-color: var(--white-color);
}
a .thumbnail:hover, a .thumbnail:focus, a .thumbnail.active {
  border-color: var(--primary-color);
}
.thumbnail .caption {
  color: var(--grayscale-color);
}

.pagination > li > a,
.pagination > li > span {
  color: var(--primary-color);
}
.pagination > li > a:hover, .pagination > li > a:focus,
.pagination > li > span:hover,
.pagination > li > span:focus {
  color: var(--primary-color3-darken-5percent);
}

.list-group-item.active > .badge, .nav-pills > .active > a > .badge {
  color: var(--primary-color);
}

.btn.fa {
  font-weight: 900;
}

.header-panel .show > .dropdown-menu {
  opacity: 1;
  transform: translateY(0);
  top: 100%;
}

/* Old Colors

$color0: #ededed; // white gray
$color1: rgb(190,190,190); // #bebebe - light gray
$color2: rgb(80,80,80); // #505050 - gray
$color3: rgb(207,214,224); // #cfd6e0 - white blue
$color4: rgb(159,172,193); // #9facc1 - light blue
$color5: rgb(88,111,146); // #586f92 - mid blue
$color6: rgb(15,48,99); // #1B3D6D - dark blue
$color7: rgb(57,213,31); // #39d51f - green
$color8: rgb(52,197,26); // #34C51A - dark green
*/
/*Theming*/
:root {
  --loading-login-screen-background:
          linear-gradient(to right, rgba(15, 48, 99, 0) 0%, rgba(75, 107, 155, .4) 25%, rgba(168, 187, 215, .4) 42%, rgba(168, 187, 215, .4) 58%, rgba(75, 107, 155, .4) 75%, rgba(15, 48, 99, 0) 100%),
          url(".././assets/images/pattern-block-lines.png") repeat;
  --loading-screen-logo: url(".././assets/images/bakerhill-nextgen-logo-white.png");
  --login-box-logo: url(".././assets/images/bakerhill-nextgen-logo.png");
  --primary-dark-color: #1B3D6D;
  --primary-color: #456aa1;
  --primary-color-darken-7percent: #3a5a88;
  --primary-color-darken-11percent: #34507a;
  --primary-color-lighten-10percent: #5f84ba;
  --primary-color-lighten-20percent: #829fca;
  --primary-color-lighten-40percent: #cad6e8;
  --primary-light1-color: #a6bad9;
  --primary-light1-color-darken-5percent:#94acd1;
  --primary-light1-color-darken-10percent: #829eca;
  --primary-light1-color-darken-15percent: #7090c2;
  --primary-light1-color-lighten-6percent: #bbcbe2;
  --primary-white-color: #e6ecf2;
  --primary-white-color-darken-2percent: #dfe7ef;
  --primary-white-color-darken-6percent: #d2dde8;
  --primary-white-color-lighten-2percent: #edf1f5;
  --primary-white-color-lighten-4percent: #f3f6f9;
  --primary-color2: #cddc29;
  --primary-color3: #81BD41;
  --primary-color3-lighten-40percent: #e5f2d8;
  --primary-color3-darken-5percent: #74aa3a;
  --primary-dark2-color: #689834;
  --primary-dark2-color-darken-10percent: #4e7227;
  --primary-light2-color: #e6f2d9;
  --primary-light2-color-darken-2percent: #e1efd1;
  --primary-light2-color-darken-6percent: #d7eac2;
  --primary-light2-color-darken-10percent: #cde5b3;
  --grayscale-color: #58595B;
  --grayscale-color-lighten-25percent: #98999b;
  --grayscale-light-color: #bdbdc2;
  --grayscale-light-color-darken-8percent: #a8a8ae;
  --grayscale-light-color-lighten-7percent: #d0d0d3;
  --grayscale-white-color: #ebebed;
  --grayscale-white-color-darken-5percent: #dedee1;
  --grayscale-white-color-darken-8percent: #d6d6da;
  --grayscale-white-color-lighten-2percent: #f0f0f2;
  --grayscale-white-color-lighten-5percent: #f8f8f9;
  --white-color: #ffffff;
  --black-color: #000000;
  --secondary-dark-color: #007299;
  --secondary-color: #77256c;
  --secondary-color2: #f68d29;
  --secondary-color3: #ffd240;
  --secondary-color3-darken-3percent: #ffce31;
  --secondary-color3-lighten-17percent: #ffe697;
  --secondary-color3-lighten-27percent: #fff2ca;
  --secondary-bright-color: #ece815;
  --error-color: #cc0000;
  --error-color-darken-10percent: #990000;
  --error-color-lighten-50percent: #ffcccc;
  --error-color-lighten-55percent: #ffe6e6;
  --error-light-color: #ffe6e6;
  --error-light-color-darken-5percent: #ffcdcd;
  --disabled-color: #abbcd5;
  --disabled-color2: #9badca;
  --disabled-color3: #a7d17a;
  --default-sprite-position: top left;
  --active-hover-sprite-position: top right;
  /*remaing colors*/
  --snow-gray: #fcfcfc;
  --light-gainsboro-gray: #f7f7f7;
  --white-smoke: #f4f4f4;
  --very-light-gray: #f0f0f0;
  --light-pink: #febbbb;
  --light-yellow: #ffffe5;
  --orange-peel: #ff9900;
  --electric-yellow: #ffff00;
  --yellow-green: #effadb;
  --whisper-gray: #ededed;
  --dark-whisper-gray: #eeeeee;
  --white-dark-smoke: #e6e6e6;
  --gainsboro-gray: #e0e0e0;
  --medium-gray: #cccccc;
  --silver: #c6c6c6;
  --silver-very-light: #b9b9b9;
  --silver-light: #bbbbbb;
  --blue-smoke: #eef1f7;
  --ivory-red: #f8f8f2;
  --spun-pearl: #afafb6;
  --very-light-blue: #e6ebf5;
  --dark-gainsboro-gray: #d9d9d9;
  --bright-gainsboro-gray: #dddddd;
  --hawakes-blue: #ccd6eb;
  --blue-violet: #9933ff;
  --nobel-gray: #999999;
  --light-danube-blue: #89a4cd;
  --matte-gray: #888888;
  --light-matte-gray: #787878;
  --dark-gray: #777777;
  --raven-gray: #707275;
  --danube-blue: #6685c2;
  --dim-gray: #666666;
  --davy-grey: #555555;
  --analog-blue: #435f8b;
  --charcoal-gray: #444444;
  --mariner-blue: #335cad;
  --dark-charcoal-gray: #333333;
  --green-waterloo: #23241f;
  --nero-dark: #222222;
  --smalt-blue: #003399;
  --deep-green: #008a00;
  --navy-blue: #0066cc;
  --bondi-blue: #0085c7;
  --violation-text: #cc0000;
  --violation-bg: #ffe6e6;
  --warning-text: #f68d29;
  --warning-bg: #fff2ca;
  --highcharts-color-2: #cddc29;
  --highcharts-color-3: #007299;
  --highcharts-color-6: #689834;
  --highcharts-color-7: #a6bad9;
}

[data-theme=fiserv] {
  --loading-login-screen-background: #666666;
  --loading-screen-logo: url(".././assets/images/Fiserv-logo-1200x603.png");
  --login-box-logo: url(".././assets/images/Fiserv-logo-1200x603.png");
  --primary-dark-color: #333333;
  --primary-color: #666666;
  --primary-color-darken-7percent: #545454;
  --primary-color-darken-11percent: #4a4a4a;
  --primary-color-lighten-10percent: gray;
  --primary-color-lighten-20percent: #999999;
  --primary-color-lighten-40percent: #cccccc;
  --primary-light1-color: #bfbfbf;
  --primary-light1-color-darken-5percent: #b2b2b2;
  --primary-light1-color-darken-10percent: #a6a6a6;
  --primary-light1-color-darken-15percent: #999999;
  --primary-light1-color-lighten-6percent: #cecece;
  --primary-white-color: #efefef;
  --primary-white-color-darken-2percent: #eaeaea;
  --primary-white-color-darken-6percent: #e0e0e0;
  --primary-white-color-lighten-2percent: #f4f4f4;
  --primary-white-color-lighten-4percent: #f9f9f9;
  --primary-color2: #4695d1;
  --primary-color3: #ff6600;
  --primary-color3-lighten-40percent: #ffe0cc;
  --primary-color3-darken-5percent: #e65c00;
  --primary-dark2-color: #ea6b15;
  --primary-dark2-color-darken-10percent: #bb5611;
  --primary-light2-color: #f2d3c4;
  --primary-light2-color-darken-2percent: #f0cdbc;
  --primary-light2-color-darken-6percent: #ecc0ab;
  --primary-light2-color-darken-10percent: #e9b49a;
  --grayscale-color: #666666;
  --grayscale-color-lighten-25percent: #a6a6a6;
  --grayscale-light-color: #e0e0e0;
  --grayscale-light-color-darken-8percent: #cccccc;
  --grayscale-light-color-lighten-7percent: #f2f2f2;
  --grayscale-white-color: #e0e0e0;
  --grayscale-white-color-darken-5percent: lightgray;
  --grayscale-white-color-darken-8percent: #cccccc;
  --grayscale-white-color-lighten-2percent: #e5e5e5;
  --grayscale-white-color-lighten-5percent: #ededed;
  --white-color: #ffffff;
  --black-color: #0e0e0e;
  --secondary-dark-color: #007299;
  --secondary-color: #77256c;
  --secondary-color2: #81bd41;
  --secondary-color3: #ffd240;
  --secondary-color3-darken-3percent: #ffce31;
  --secondary-color3-lighten-17percent: #ffe697;
  --secondary-color3-lighten-27percent: #fff2ca;
  --secondary-bright-color: #ece815;
  --error-color: #dd3435;
  --error-color-darken-10percent: #be2021;
  --error-color-lighten-50percent: white;
  --error-color-lighten-55percent: white;
  --error-light-color: #eabec0;
  --error-light-color-darken-5percent: #e4abad;
  --disabled-color: #c9c9c9;
  --disabled-color2: #c9c9c9;
  --disabled-color3: #dd9e73;
  --default-sprite-position: bottom left;
  --active-hover-sprite-position: bottom right;
  /*remaing colors*/
  --snow-gray: #fcfcfc;
  --light-gainsboro-gray: #f7f7f7;
  --white-smoke: #f4f4f4;
  --very-light-gray: #f0f0f0;
  --light-pink: #febbbb;
  --light-yellow: #ffffe5;
  --orange-peel: #ff9900;
  --electric-yellow: #ffff00;
  --yellow-green: #effadb;
  --whisper-gray: #ededed;
  --dark-whisper-gray: #eeeeee;
  --white-dark-smoke: #e6e6e6;
  --gainsboro-gray: #e0e0e0;
  --medium-gray: #cccccc;
  --silver: #c6c6c6;
  --silver-very-light: #b9b9b9;
  --silver-light: #bbbbbb;
  --blue-smoke: #eef1f7;
  --ivory-red: #f8f8f2;
  --spun-pearl: #afafb6;
  --very-light-blue: #e6ebf5;
  --dark-gainsboro-gray: #d9d9d9;
  --bright-gainsboro-gray: #dddddd;
  --hawakes-blue: #ccd6eb;
  --blue-violet: #9933ff;
  --nobel-gray: #999999;
  --light-danube-blue: #89a4cd;
  --matte-gray: #888888;
  --light-matte-gray: #787878;
  --dark-gray: #777777;
  --raven-gray: #707275;
  --danube-blue: #6685c2;
  --dim-gray: #666666;
  --davy-grey: #555555;
  --analog-blue: #435f8b;
  --charcoal-gray: #444444;
  --mariner-blue: #335cad;
  --dark-charcoal-gray: #333333;
  --green-waterloo: #23241f;
  --nero-dark: #222222;
  --smalt-blue: #003399;
  --deep-green: #008a00;
  --navy-blue: #0066cc;
  --bondi-blue: #0085c7;
  --violation-text: #cc0000;
  --violation-bg: #ffe6e6;
  --warning-text: #f68d29;
  --warning-bg: #fff2ca;
  --highcharts-color-2: #ffa366;
  --highcharts-color-3: #777777;
  --highcharts-color-6: #e65c00;
  --highcharts-color-7: #e0e0e0;
}

/*Sprite Positions*/
/*Images*/
/*Primary*/
/*Grayscale*/
/*Secondary*/
/*disabled*/
/*remaing colors*/
.darkblue {
  color: var(--primary-dark-color);
}

.blue {
  color: var(--primary-color);
}

.lightblue {
  color: var(--primary-light1-color);
}

.whiteblue {
  color: var(--primary-white-color);
}

.yellowgreen {
  color: var(--primary-color2);
}

.green {
  color: var(--primary-color3) !important;
}

.darkgreen {
  color: var(--primary-dark2-color);
}

.lightgreen {
  color: var(--primary-light2-color);
}

.gray {
  color: var(--grayscale-color);
}

.lightgray {
  color: var(--grayscale-light-color);
}

.whitegray {
  color: var(--grayscale-white-color);
}

.darkcyan {
  color: var(--secondary-dark-color);
}

.purple {
  color: var(--secondary-color);
}

.orange {
  color: var(--secondary-color2) !important;
}

.yellow {
  color: var(--secondary-color3);
}

.brightyellow {
  color: var(--secondary-bright-color);
}

.red {
  color: var(--error-color) !important;
}

.lightred {
  color: var(--error-light-color);
}

.white {
  color: var(--white-color) !important;
}

.darkblue-bg {
  background-color: var(--primary-dark-color);
}

.blue-bg {
  background-color: var(--primary-color) !important;
}

.lightblue-bg {
  background-color: var(--primary-light1-color);
}

.whiteblue-bg {
  background-color: var(--primary-white-color);
}

.yellowgreen-bg {
  background-color: var(--primary-color2);
}

.green-bg {
  background-color: var(--primary-color3);
}

.darkgreen-bg {
  background-color: var(--primary-dark2-color);
}

.lightgreen-bg {
  background-color: var(--primary-light2-color);
}

.gray-bg {
  background-color: var(--grayscale-color);
}

.lightgray-bg {
  background-color: var(--grayscale-light-color);
}

.whitegray-bg {
  background-color: var(--grayscale-white-color);
}

.darkcyan-bg {
  background-color: var(--secondary-dark-color);
}

.purple-bg {
  background-color: var(--secondary-color);
}

.orange-bg {
  background-color: var(--secondary-color2) !important;
}

.yellow-bg {
  background-color: var(--secondary-color3) !important;
}

.brightyellow-bg {
  background-color: var(--secondary-bright-color) !important;
}

.red-bg {
  background-color: var(--error-color) !important;
}

.lightred-bg {
  background-color: var(--error-light-color) !important;
}

.flex-wrap,
.inline-flex-wrap {
  display: flex;
  flex-wrap: wrap;
}
.flex-wrap > [class*=col-],
.inline-flex-wrap > [class*=col-] {
  display: flex;
  flex-direction: column;
}

.inline-flex-wrap {
  display: inline-flex;
}

.flex-center {
  display: flex;
  align-items: center;
}

.content-block {
  margin-bottom: 25px;
}

.spacer10 {
  margin-bottom: 10px !important;
}

.spacer20 {
  margin-bottom: 20px !important;
}

.spacer30 {
  margin-bottom: 30px !important;
}

.spacer40 {
  margin-bottom: 40px !important;
}

.spacer50 {
  margin-bottom: 50px !important;
}

.spacer60 {
  margin-bottom: 60px !important;
}

.spacer70 {
  margin-bottom: 70px !important;
}

.spacer80 {
  margin-bottom: 80px !important;
}

.spacer90 {
  margin-bottom: 90px !important;
}

.spacer100 {
  margin-bottom: 100px !important;
}

.spacertop5 {
  margin-top: 5px !important;
}

.spacertop10 {
  margin-top: 10px !important;
}

.spacertop20 {
  margin-top: 20px !important;
}

.spacertop30 {
  margin-top: 30px !important;
}

.spacertop40 {
  margin-top: 40px !important;
}

.spacertop50 {
  margin-top: 50px !important;
}

.spacertop60 {
  margin-top: 60px !important;
}

.spacertop70 {
  margin-top: 70px !important;
}

.spacertop80 {
  margin-top: 80px !important;
}

.spacertop90 {
  margin-top: 90px !important;
}

.spacertop100 {
  margin-top: 100px !important;
}

.no-padding {
  padding: 0 !important;
}

.no-padding-top {
  padding-top: 0 !important;
}

@media (min-width: 768px) {
  .no-padding-left {
    padding-left: 0 !important;
  }
  .no-padding-right {
    padding-right: 0 !important;
  }
}
.no-margin {
  margin: 0 !important;
}

.no-border {
  border: none !important;
}

.disable-click {
  pointer-events: none;
}

.visible-xl {
  display: none !important;
}

@media (min-width: 1600px) {
  .visible-xl {
    display: block !important;
  }
}
/*Webkit*/
::-webkit-scrollbar {
  width: 14px;
  height: 14px;
}

::-webkit-scrollbar-button {
  width: 14px;
  height: 14px;
  background-color: var(--primary-color);
}

::-webkit-scrollbar-button:vertical:increment {
  background-image: url("../assets/images/caret-down.png");
  background-position: center;
  background-size: contain;
}

::-webkit-scrollbar-button:vertical:decrement {
  background-image: url("../assets/images/caret-up.png");
  background-position: center;
  background-size: contain;
}

::-webkit-scrollbar-button:horizontal:increment {
  background-image: url("../assets/images/caret-right.png");
  background-position: center;
  background-size: contain;
}

::-webkit-scrollbar-button:horizontal:decrement {
  background-image: url("../assets/images/caret-left.png");
  background-position: center;
  background-size: contain;
}

::-webkit-scrollbar-thumb {
  background: var(--primary-light1-color);
  border: 0 none var(--white-color);
}

::-webkit-scrollbar-thumb:hover {
  background: var(--primary-color);
}

::-webkit-scrollbar-thumb:active {
  background: var(--primary-color);
}

::-webkit-scrollbar-track {
  background: var(--grayscale-white-color);
  border: 0 none var(--white-color);
}

::-webkit-scrollbar-track:hover {
  background: var(--grayscale-white-color);
}

::-webkit-scrollbar-track:active {
  background: var(--grayscale-white-color);
}

::-webkit-scrollbar-corner {
  background: transparent;
}

body {
  scrollbar-face-color: var(--primary-light1-color);
  -ms-overflow-style: scrollbar;
}

#wrapper {
  padding: 30px;
  background: var(--black-color);
  color: var(--white-color);
}

.loading-indicator {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 40px 0;
}
.loading-indicator i {
  color: var(--primary-color3);
}

.loading-indicator-details-container {
  position: relative;
  min-height: 200px;
}
.loading-indicator-details-container .loading-indicator-details {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: 0;
  opacity: 0.5;
  background-color: var(--primary-white-color);
  z-index: 1000;
  border-radius: 4px;
  min-height: 200px;
}
.loading-indicator-details-container .loading-indicator-details i {
  position: absolute;
  top: 80px;
  left: calc(50% - 20px);
  z-index: 1001;
  color: var(--primary-color3);
}

/* Old Colors

$color0: #ededed; // white gray
$color1: rgb(190,190,190); // #bebebe - light gray
$color2: rgb(80,80,80); // #505050 - gray
$color3: rgb(207,214,224); // #cfd6e0 - white blue
$color4: rgb(159,172,193); // #9facc1 - light blue
$color5: rgb(88,111,146); // #586f92 - mid blue
$color6: rgb(15,48,99); // #1B3D6D - dark blue
$color7: rgb(57,213,31); // #39d51f - green
$color8: rgb(52,197,26); // #34C51A - dark green
*/
/*Theming*/
:root {
  --loading-login-screen-background:
          linear-gradient(to right, rgba(15, 48, 99, 0) 0%, rgba(75, 107, 155, .4) 25%, rgba(168, 187, 215, .4) 42%, rgba(168, 187, 215, .4) 58%, rgba(75, 107, 155, .4) 75%, rgba(15, 48, 99, 0) 100%),
          url(".././assets/images/pattern-block-lines.png") repeat;
  --loading-screen-logo: url(".././assets/images/bakerhill-nextgen-logo-white.png");
  --login-box-logo: url(".././assets/images/bakerhill-nextgen-logo.png");
  --primary-dark-color: #1B3D6D;
  --primary-color: #456aa1;
  --primary-color-darken-7percent: #3a5a88;
  --primary-color-darken-11percent: #34507a;
  --primary-color-lighten-10percent: #5f84ba;
  --primary-color-lighten-20percent: #829fca;
  --primary-color-lighten-40percent: #cad6e8;
  --primary-light1-color: #a6bad9;
  --primary-light1-color-darken-5percent:#94acd1;
  --primary-light1-color-darken-10percent: #829eca;
  --primary-light1-color-darken-15percent: #7090c2;
  --primary-light1-color-lighten-6percent: #bbcbe2;
  --primary-white-color: #e6ecf2;
  --primary-white-color-darken-2percent: #dfe7ef;
  --primary-white-color-darken-6percent: #d2dde8;
  --primary-white-color-lighten-2percent: #edf1f5;
  --primary-white-color-lighten-4percent: #f3f6f9;
  --primary-color2: #cddc29;
  --primary-color3: #81BD41;
  --primary-color3-lighten-40percent: #e5f2d8;
  --primary-color3-darken-5percent: #74aa3a;
  --primary-dark2-color: #689834;
  --primary-dark2-color-darken-10percent: #4e7227;
  --primary-light2-color: #e6f2d9;
  --primary-light2-color-darken-2percent: #e1efd1;
  --primary-light2-color-darken-6percent: #d7eac2;
  --primary-light2-color-darken-10percent: #cde5b3;
  --grayscale-color: #58595B;
  --grayscale-color-lighten-25percent: #98999b;
  --grayscale-light-color: #bdbdc2;
  --grayscale-light-color-darken-8percent: #a8a8ae;
  --grayscale-light-color-lighten-7percent: #d0d0d3;
  --grayscale-white-color: #ebebed;
  --grayscale-white-color-darken-5percent: #dedee1;
  --grayscale-white-color-darken-8percent: #d6d6da;
  --grayscale-white-color-lighten-2percent: #f0f0f2;
  --grayscale-white-color-lighten-5percent: #f8f8f9;
  --white-color: #ffffff;
  --black-color: #000000;
  --secondary-dark-color: #007299;
  --secondary-color: #77256c;
  --secondary-color2: #f68d29;
  --secondary-color3: #ffd240;
  --secondary-color3-darken-3percent: #ffce31;
  --secondary-color3-lighten-17percent: #ffe697;
  --secondary-color3-lighten-27percent: #fff2ca;
  --secondary-bright-color: #ece815;
  --error-color: #cc0000;
  --error-color-darken-10percent: #990000;
  --error-color-lighten-50percent: #ffcccc;
  --error-color-lighten-55percent: #ffe6e6;
  --error-light-color: #ffe6e6;
  --error-light-color-darken-5percent: #ffcdcd;
  --disabled-color: #abbcd5;
  --disabled-color2: #9badca;
  --disabled-color3: #a7d17a;
  --default-sprite-position: top left;
  --active-hover-sprite-position: top right;
  /*remaing colors*/
  --snow-gray: #fcfcfc;
  --light-gainsboro-gray: #f7f7f7;
  --white-smoke: #f4f4f4;
  --very-light-gray: #f0f0f0;
  --light-pink: #febbbb;
  --light-yellow: #ffffe5;
  --orange-peel: #ff9900;
  --electric-yellow: #ffff00;
  --yellow-green: #effadb;
  --whisper-gray: #ededed;
  --dark-whisper-gray: #eeeeee;
  --white-dark-smoke: #e6e6e6;
  --gainsboro-gray: #e0e0e0;
  --medium-gray: #cccccc;
  --silver: #c6c6c6;
  --silver-very-light: #b9b9b9;
  --silver-light: #bbbbbb;
  --blue-smoke: #eef1f7;
  --ivory-red: #f8f8f2;
  --spun-pearl: #afafb6;
  --very-light-blue: #e6ebf5;
  --dark-gainsboro-gray: #d9d9d9;
  --bright-gainsboro-gray: #dddddd;
  --hawakes-blue: #ccd6eb;
  --blue-violet: #9933ff;
  --nobel-gray: #999999;
  --light-danube-blue: #89a4cd;
  --matte-gray: #888888;
  --light-matte-gray: #787878;
  --dark-gray: #777777;
  --raven-gray: #707275;
  --danube-blue: #6685c2;
  --dim-gray: #666666;
  --davy-grey: #555555;
  --analog-blue: #435f8b;
  --charcoal-gray: #444444;
  --mariner-blue: #335cad;
  --dark-charcoal-gray: #333333;
  --green-waterloo: #23241f;
  --nero-dark: #222222;
  --smalt-blue: #003399;
  --deep-green: #008a00;
  --navy-blue: #0066cc;
  --bondi-blue: #0085c7;
  --violation-text: #cc0000;
  --violation-bg: #ffe6e6;
  --warning-text: #f68d29;
  --warning-bg: #fff2ca;
  --highcharts-color-2: #cddc29;
  --highcharts-color-3: #007299;
  --highcharts-color-6: #689834;
  --highcharts-color-7: #a6bad9;
}

[data-theme=fiserv] {
  --loading-login-screen-background: #666666;
  --loading-screen-logo: url(".././assets/images/Fiserv-logo-1200x603.png");
  --login-box-logo: url(".././assets/images/Fiserv-logo-1200x603.png");
  --primary-dark-color: #333333;
  --primary-color: #666666;
  --primary-color-darken-7percent: #545454;
  --primary-color-darken-11percent: #4a4a4a;
  --primary-color-lighten-10percent: gray;
  --primary-color-lighten-20percent: #999999;
  --primary-color-lighten-40percent: #cccccc;
  --primary-light1-color: #bfbfbf;
  --primary-light1-color-darken-5percent: #b2b2b2;
  --primary-light1-color-darken-10percent: #a6a6a6;
  --primary-light1-color-darken-15percent: #999999;
  --primary-light1-color-lighten-6percent: #cecece;
  --primary-white-color: #efefef;
  --primary-white-color-darken-2percent: #eaeaea;
  --primary-white-color-darken-6percent: #e0e0e0;
  --primary-white-color-lighten-2percent: #f4f4f4;
  --primary-white-color-lighten-4percent: #f9f9f9;
  --primary-color2: #4695d1;
  --primary-color3: #ff6600;
  --primary-color3-lighten-40percent: #ffe0cc;
  --primary-color3-darken-5percent: #e65c00;
  --primary-dark2-color: #ea6b15;
  --primary-dark2-color-darken-10percent: #bb5611;
  --primary-light2-color: #f2d3c4;
  --primary-light2-color-darken-2percent: #f0cdbc;
  --primary-light2-color-darken-6percent: #ecc0ab;
  --primary-light2-color-darken-10percent: #e9b49a;
  --grayscale-color: #666666;
  --grayscale-color-lighten-25percent: #a6a6a6;
  --grayscale-light-color: #e0e0e0;
  --grayscale-light-color-darken-8percent: #cccccc;
  --grayscale-light-color-lighten-7percent: #f2f2f2;
  --grayscale-white-color: #e0e0e0;
  --grayscale-white-color-darken-5percent: lightgray;
  --grayscale-white-color-darken-8percent: #cccccc;
  --grayscale-white-color-lighten-2percent: #e5e5e5;
  --grayscale-white-color-lighten-5percent: #ededed;
  --white-color: #ffffff;
  --black-color: #0e0e0e;
  --secondary-dark-color: #007299;
  --secondary-color: #77256c;
  --secondary-color2: #81bd41;
  --secondary-color3: #ffd240;
  --secondary-color3-darken-3percent: #ffce31;
  --secondary-color3-lighten-17percent: #ffe697;
  --secondary-color3-lighten-27percent: #fff2ca;
  --secondary-bright-color: #ece815;
  --error-color: #dd3435;
  --error-color-darken-10percent: #be2021;
  --error-color-lighten-50percent: white;
  --error-color-lighten-55percent: white;
  --error-light-color: #eabec0;
  --error-light-color-darken-5percent: #e4abad;
  --disabled-color: #c9c9c9;
  --disabled-color2: #c9c9c9;
  --disabled-color3: #dd9e73;
  --default-sprite-position: bottom left;
  --active-hover-sprite-position: bottom right;
  /*remaing colors*/
  --snow-gray: #fcfcfc;
  --light-gainsboro-gray: #f7f7f7;
  --white-smoke: #f4f4f4;
  --very-light-gray: #f0f0f0;
  --light-pink: #febbbb;
  --light-yellow: #ffffe5;
  --orange-peel: #ff9900;
  --electric-yellow: #ffff00;
  --yellow-green: #effadb;
  --whisper-gray: #ededed;
  --dark-whisper-gray: #eeeeee;
  --white-dark-smoke: #e6e6e6;
  --gainsboro-gray: #e0e0e0;
  --medium-gray: #cccccc;
  --silver: #c6c6c6;
  --silver-very-light: #b9b9b9;
  --silver-light: #bbbbbb;
  --blue-smoke: #eef1f7;
  --ivory-red: #f8f8f2;
  --spun-pearl: #afafb6;
  --very-light-blue: #e6ebf5;
  --dark-gainsboro-gray: #d9d9d9;
  --bright-gainsboro-gray: #dddddd;
  --hawakes-blue: #ccd6eb;
  --blue-violet: #9933ff;
  --nobel-gray: #999999;
  --light-danube-blue: #89a4cd;
  --matte-gray: #888888;
  --light-matte-gray: #787878;
  --dark-gray: #777777;
  --raven-gray: #707275;
  --danube-blue: #6685c2;
  --dim-gray: #666666;
  --davy-grey: #555555;
  --analog-blue: #435f8b;
  --charcoal-gray: #444444;
  --mariner-blue: #335cad;
  --dark-charcoal-gray: #333333;
  --green-waterloo: #23241f;
  --nero-dark: #222222;
  --smalt-blue: #003399;
  --deep-green: #008a00;
  --navy-blue: #0066cc;
  --bondi-blue: #0085c7;
  --violation-text: #cc0000;
  --violation-bg: #ffe6e6;
  --warning-text: #f68d29;
  --warning-bg: #fff2ca;
  --highcharts-color-2: #ffa366;
  --highcharts-color-3: #777777;
  --highcharts-color-6: #e65c00;
  --highcharts-color-7: #e0e0e0;
}

/*Sprite Positions*/
/*Images*/
/*Primary*/
/*Grayscale*/
/*Secondary*/
/*disabled*/
/*remaing colors*/
.darkblue {
  color: var(--primary-dark-color);
}

.blue {
  color: var(--primary-color);
}

.lightblue {
  color: var(--primary-light1-color);
}

.whiteblue {
  color: var(--primary-white-color);
}

.yellowgreen {
  color: var(--primary-color2);
}

.green {
  color: var(--primary-color3) !important;
}

.darkgreen {
  color: var(--primary-dark2-color);
}

.lightgreen {
  color: var(--primary-light2-color);
}

.gray {
  color: var(--grayscale-color);
}

.lightgray {
  color: var(--grayscale-light-color);
}

.whitegray {
  color: var(--grayscale-white-color);
}

.darkcyan {
  color: var(--secondary-dark-color);
}

.purple {
  color: var(--secondary-color);
}

.orange {
  color: var(--secondary-color2) !important;
}

.yellow {
  color: var(--secondary-color3);
}

.brightyellow {
  color: var(--secondary-bright-color);
}

.red {
  color: var(--error-color) !important;
}

.lightred {
  color: var(--error-light-color);
}

.white {
  color: var(--white-color) !important;
}

.darkblue-bg {
  background-color: var(--primary-dark-color);
}

.blue-bg {
  background-color: var(--primary-color) !important;
}

.lightblue-bg {
  background-color: var(--primary-light1-color);
}

.whiteblue-bg {
  background-color: var(--primary-white-color);
}

.yellowgreen-bg {
  background-color: var(--primary-color2);
}

.green-bg {
  background-color: var(--primary-color3);
}

.darkgreen-bg {
  background-color: var(--primary-dark2-color);
}

.lightgreen-bg {
  background-color: var(--primary-light2-color);
}

.gray-bg {
  background-color: var(--grayscale-color);
}

.lightgray-bg {
  background-color: var(--grayscale-light-color);
}

.whitegray-bg {
  background-color: var(--grayscale-white-color);
}

.darkcyan-bg {
  background-color: var(--secondary-dark-color);
}

.purple-bg {
  background-color: var(--secondary-color);
}

.orange-bg {
  background-color: var(--secondary-color2) !important;
}

.yellow-bg {
  background-color: var(--secondary-color3) !important;
}

.brightyellow-bg {
  background-color: var(--secondary-bright-color) !important;
}

.red-bg {
  background-color: var(--error-color) !important;
}

.lightred-bg {
  background-color: var(--error-light-color) !important;
}

.content-area {
  margin: 0 25px;
}

.dropdown {
  display: inline-block;
}

.dropdown-menu {
  border-radius: 0;
  border: 1px solid var(--primary-color);
  white-space: normal;
  line-height: 1.428571429;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}
.dropdown-menu li > a {
  font-weight: bold;
  padding: 4px 15px;
}
.dropdown-menu .fa {
  padding-right: 5px;
  margin-right: 5px;
}
.dropdown-menu .custom-icon {
  color: var(--primary-color);
  font-size: 2.25rem;
  width: 18px;
  line-height: 0;
  display: inline-block;
  margin-right: 5px;
  position: relative;
  bottom: 5px;
  left: 2px;
}
.dropdown-menu .divider {
  background-color: var(--grayscale-light-color-lighten-7percent);
  margin: 5px 0;
  display: block;
  height: 1px;
}
.dropdown-menu .checkbox-radio, .dropdown-menu .checkbox-radio-no-margin {
  margin: 5px 10px !important;
}
.dropdown-menu.right {
  left: auto;
  right: 0;
}
.dropdown-menu.right a {
  cursor: pointer;
}

.header-panel {
  position: relative;
  z-index: 998;
}
.header-panel .dropdown-menu {
  margin: 5px 0 0;
  transform: translateY(-5px);
  top: -9999px;
  opacity: 0;
}
.header-panel .show {
  opacity: 1;
  transform: translateY(0px);
  top: 100%;
}

.dropdown-menu:not(.show):not(.opened) {
  pointer-events: none;
}

.dropdown-button-styles {
  font-weight: bold;
  padding: 4px 15px;
  color: var(--primary-color);
  border: none;
  border-radius: 0;
  background: none;
  margin: 0;
  height: auto;
  line-height: 1.428571429;
  width: 100%;
  text-align: left;
  box-shadow: none;
}

.header-panel {
  background: var(--primary-color) linear-gradient(to bottom, var(--primary-color), var(--primary-color) 55%, var(--primary-color-darken-7percent));
  border-radius: 4px;
  min-height: 50px;
  padding: 7px 12px;
  margin-bottom: 25px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}
.header-panel .header-left {
  float: left;
  width: calc(100% - 210px);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.header-panel .header-right {
  float: right;
  width: 210px;
  text-align: right;
}
@media (max-width: 479px) {
  .header-panel .header-left,
  .header-panel .header-right {
    float: none;
    width: 100%;
    text-align: left;
  }
}
.header-panel.sticky {
  position: -webkit-sticky;
  position: sticky;
  top: 45px;
  z-index: 888;
}
.header-panel h1, .header-panel .h1, .header-panel h2, .header-panel .h2, .header-panel h4, .header-panel .h4 {
  color: white;
  margin: 0;
  display: inline-block;
  line-height: 36px;
}
.header-panel button:last-child,
.header-panel div.dropdown:last-child button,
.header-panel a.btn:last-child,
.header-panel div.dropdown:last-child a.btn {
  margin-right: 0;
}
.header-panel .form-group:not(.checkbox-radio):not(.checkbox-radio-no-margin) {
  margin: 0;
}
.header-panel .form-group:not(.checkbox-radio):not(.checkbox-radio-no-margin) .form-control {
  margin-top: 0;
}
.header-panel .header-right .dropdown-menu a {
  cursor: pointer;
}
.header-panel .dropdown-menu {
  white-space: nowrap;
}
.header-panel .dropdown-menu > li {
  line-height: 1.428571429;
}
.header-panel .dropdown-menu > li:hover, .header-panel .dropdown-menu > li:focus {
  background-color: var(--grayscale-white-color);
}
.header-panel .inline-control {
  margin-right: 10px;
  float: left;
}
.header-panel .inline-control-label .inline-control {
  color: white;
  margin-top: 15px;
}
.header-panel .select-actions {
  background-color: var(--grayscale-white-color-lighten-2percent);
  margin: 0px -11px;
  padding: 0 100px 0 12px;
  border-bottom: 1px solid var(--analog-blue);
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  position: relative;
  top: 7px;
  clear: both;
}
.header-panel .select-actions .selected-row-count {
  margin: 1px 10px 0 6px;
}
.header-panel .select-actions .selected-row-count h3, .header-panel .select-actions .selected-row-count .h3 {
  margin: 0;
  display: inline-block;
  font-weight: bold;
  color: var(--primary-color);
}
.header-panel .select-actions .btn {
  margin: 6px 5px;
  position: relative;
}
.header-panel .select-actions .vertical-divider {
  display: block;
  float: left;
  background: var(--grayscale-light-color);
  margin: 9px 10px;
  height: 32px;
  width: 1px;
}
.header-panel .select-actions .text-icon {
  font-size: 2.625rem;
  width: 24px;
  height: 0;
  line-height: 0;
  position: relative;
  bottom: 5px;
}
.header-panel .select-actions .label-checkbox {
  position: absolute;
  right: 15px;
  top: 7px;
}
.header-panel .select-actions .label-checkbox label {
  font-size: 0.8125rem;
  line-height: 21px;
}
.header-panel .select-actions.show-labels .fa {
  margin-right: 5px;
}
.header-panel .select-actions.show-labels .overlay-icon-blue {
  right: auto;
  left: 15px;
}
.header-panel .select-actions.show-labels span {
  font-size: 0.8125rem;
  margin-right: 5px;
}

.header-panel-light {
  background: var(--primary-white-color);
  border-bottom: 1px solid var(--primary-light1-color);
}
.header-panel-light h1, .header-panel-light .h1, .header-panel-light h2, .header-panel-light .h2 {
  color: var(--primary-color);
}

.header-panel-small {
  min-height: 37px;
}
.header-panel-small h4, .header-panel-small .h4 {
  line-height: 28px;
}

.modal .header-panel.sticky {
  position: unset;
  top: initial;
  z-index: 888;
}

.header-panel-table,
.header-panel-connect {
  border-radius: 4px 4px 0 0;
  margin-bottom: 0;
}
.header-panel-table.sticky,
.header-panel-connect.sticky {
  z-index: 887;
}
.header-panel-table .form-control,
.header-panel-connect .form-control {
  border-radius: 3px;
}

.header-breadcrumb {
  font-size: 0.9625rem;
  padding: 0 0 17px 15px;
  margin-top: -15px;
  display: none;
}
.header-breadcrumb:before {
  font-family: "FontAwesome", sans-serif;
  content: "\f148";
  transform: rotate(90deg);
  font-size: 1.4rem;
  float: left;
  padding: 8px 18px 0 0;
  color: var(--primary-color);
}

@media (max-width: 992px) {
  .header-breadcrumb {
    display: block;
  }
}
.nav-tabs {
  border-bottom: 1px solid var(--grayscale-light-color);
}
.nav-tabs > li > a {
  box-shadow: inset 0 -3px 3px rgba(0, 0, 0, 0.05);
}
.nav-tabs > li.active > a, .nav-tabs > li > a:hover, .nav-tabs > li > a:focus {
  box-shadow: none;
}

.nav-tabs li,
.nav-pills li {
  font-size: 0.9375rem;
}

.nav-tabs > li > a,
.nav-pills > li > a {
  color: var(--grayscale-color);
}
.nav-tabs > li > a:hover, .nav-tabs > li > a:focus,
.nav-pills > li > a:hover,
.nav-pills > li > a:focus {
  background: var(--primary-white-color);
}

.nav-tabs > li.active > a, .nav-tabs > li.active > a:hover, .nav-tabs > li.active > a:focus {
  color: var(--white-color);
  background-color: var(--primary-color);
  border: 1px solid var(--primary-color);
}

.nav-tabs.green > li.active > a, .nav-tabs.green > li.active > a:hover, .nav-tabs.green > li.active > a:focus {
  color: var(--white-color);
  background-color: var(--primary-color3);
  border: 1px solid var(--primary-color3);
}

.nav-tabs > li > a {
  border: 1px solid var(--grayscale-light-color);
  margin-right: 5px;
  min-width: 100px;
  text-align: center;
  transition: background-color 0.3s, border-color 0.3s, color 0.3s;
  background-color: white;
}
.nav-tabs > li > a.tab-error, .nav-tabs > li > a.tab-decline {
  color: var(--error-color);
  background-color: var(--error-light-color);
}
.nav-tabs > li > a.tab-error:before, .nav-tabs > li > a.tab-decline:before {
  font-family: "FontAwesome", sans-serif;
  content: "\f071";
  padding-right: 6px;
  color: var(--error-color);
}
.nav-tabs > li > a.tab-error:hover, .nav-tabs > li > a.tab-error:focus, .nav-tabs > li > a.tab-decline:hover, .nav-tabs > li > a.tab-decline:focus {
  background-color: var(--error-light-color-darken-5percent);
}
.nav-tabs > li > a.tab-decline:before {
  content: "\f00d";
}
.nav-tabs > li > a.tab-warning, .nav-tabs > li > a.tab-counter {
  background-color: var(--secondary-color3-lighten-27percent);
}
.nav-tabs > li > a.tab-warning:before, .nav-tabs > li > a.tab-counter:before {
  font-family: "FontAwesome", sans-serif;
  content: "\f071";
  padding-right: 6px;
  color: var(--secondary-color2);
}
.nav-tabs > li > a.tab-warning:hover, .nav-tabs > li > a.tab-warning:focus, .nav-tabs > li > a.tab-counter:hover, .nav-tabs > li > a.tab-counter:focus {
  background-color: var(--secondary-color3-lighten-17percent);
}
.nav-tabs > li > a.tab-counter:before {
  content: "\f064";
  display: inline-block;
  transform: rotateY(180deg);
  padding: 0 0 0 8px;
}
.nav-tabs > li > a.tab-success, .nav-tabs > li > a.tab-approve {
  background-color: var(--primary-light2-color);
}
.nav-tabs > li > a.tab-success:before, .nav-tabs > li > a.tab-approve:before {
  font-family: "FontAwesome", sans-serif;
  content: "\f00c";
  padding-right: 6px;
  color: var(--primary-color3);
}
.nav-tabs > li > a.tab-success:hover, .nav-tabs > li > a.tab-success:focus, .nav-tabs > li > a.tab-approve:hover, .nav-tabs > li > a.tab-approve:focus {
  background-color: var(--primary-light2-color-darken-10percent);
}
.nav-tabs > li > a.tab-primary:after {
  font-family: "FontAwesome", sans-serif;
  content: "\f00c";
  padding-left: 6px;
  color: var(--primary-color3);
}
.nav-tabs > li > a.tab-green:hover, .nav-tabs > li > a.tab-green:focus {
  background-color: var(--primary-color3);
}

.nav-tabs > li.active > a.tab-error {
  color: white;
  background-color: var(--primary-color);
}
.nav-tabs > li.active > a.tab-error:before {
  content: "";
  padding: 0;
}
.nav-tabs > li.active > a.tab-decline {
  color: white;
  background-color: var(--error-color);
  border: 1px solid var(--error-color);
}
.nav-tabs > li.active > a.tab-decline:before {
  color: white;
}
.nav-tabs > li.active > a.tab-warning, .nav-tabs > li.active > a.tab-counter {
  color: white;
  background-color: var(--secondary-color2);
  border: 1px solid var(--secondary-color2);
}
.nav-tabs > li.active > a.tab-warning:before, .nav-tabs > li.active > a.tab-counter:before {
  color: white;
}
.nav-tabs > li.active > a.tab-success, .nav-tabs > li.active > a.tab-approve {
  background-color: var(--primary-color3);
  border: 1px solid var(--primary-dark2-color);
  color: white;
}
.nav-tabs > li.active > a.tab-success:before, .nav-tabs > li.active > a.tab-approve:before {
  color: white;
}
.nav-tabs > li.active > a.tab-green {
  background-color: var(--primary-color3);
  border: 1px solid var(--primary-dark2-color);
  color: white;
}

.nav-tabs.nav-justified li {
  float: none;
  display: block;
  width: 100%;
}
.nav-tabs.nav-justified > li > a {
  border-bottom: 1px solid var(--grayscale-light-color);
  border-radius: 0;
  padding: 8px 5px;
  white-space: nowrap;
}

@media (min-width: 992px) {
  .nav-tabs.nav-justified li {
    display: table-cell;
    width: 1%;
  }
}
.nav-tabs.hidden-tabs {
  display: none;
}

/*BDF toggle tabs*/
.bdf-tabs .nav-tabs,
.tab-group .nav-tabs {
  margin: 0 0 20px;
  width: initial;
}
.bdf-tabs .nav-tabs li,
.tab-group .nav-tabs li {
  display: table-cell !important;
}
.bdf-tabs .nav-tabs li a,
.tab-group .nav-tabs li a {
  padding: 8px 10px;
  margin: 0;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.08), 0 1px 1px rgba(0, 0, 0, 0.16);
}
.bdf-tabs .nav-tabs li .active a,
.tab-group .nav-tabs li .active a {
  background-color: var(--primary-color3);
  border-color: var(--primary-dark2-color);
}
.bdf-tabs .nav-tabs li:first-child a,
.tab-group .nav-tabs li:first-child a {
  border-radius: 4px 0 0 4px;
}
.bdf-tabs .nav-tabs li:last-child a,
.tab-group .nav-tabs li:last-child a {
  border-radius: 0 4px 4px 0;
}

.tooltip-tr,
.tooltip-br,
.tooltip-tl,
.tooltip-bl,
.tooltip-top,
.tooltip-bottom,
.tooltip-left,
.tooltip-right {
  position: relative;
  display: inline-block;
}

.tooltip-tr span,
.tooltip-br span,
.tooltip-tl span,
.tooltip-bl span,
.tooltip-top span,
.tooltip-bottom span,
.tooltip-left span,
.tooltip-right span {
  visibility: hidden;
  opacity: 0;
  position: absolute;
  z-index: 101;
  background: var(--primary-color3) !important;
  color: white !important;
  padding: 4px 6px !important;
  border-radius: 4px;
  border: 1px solid white;
  text-align: center;
  white-space: pre;
  font-size: 13px;
  font-weight: normal;
  font-family: Arial, Helvetica, sans-serif;
  width: auto !important;
  line-height: normal;
}

.tooltip-tr span {
  top: 25%;
  left: 75%;
  transform: translateY(-100%);
}

.tooltip-br span {
  bottom: 25%;
  left: 75%;
  transform: translateY(100%);
}

.tooltip-tl span {
  top: 25%;
  right: 75%;
  transform: translateY(-100%);
}

.tooltip-bl span {
  bottom: 25%;
  left: 25%;
  transform: translate(-100%, 100%);
}

.tooltip-top span {
  top: -30px;
  left: 50%;
  transform: translateX(-50%);
}

.tooltip-bottom span {
  bottom: -30px;
  left: 50%;
  transform: translateX(-50%);
}

.tooltip-left span {
  top: 50%;
  right: 100%;
  transform: translateY(-50%);
}

.tooltip-right span {
  top: 50%;
  left: 100%;
  transform: translateY(-50%);
}

.tooltip-tr:hover span,
.tooltip-br:hover span,
.tooltip-tl:hover span,
.tooltip-bl:hover span,
.tooltip-top:hover span,
.tooltip-bottom:hover span,
.tooltip-left:hover span,
.tooltip-right:hover span {
  visibility: visible;
  opacity: 1;
  transition: opacity 0.2s 0.5s, visibility 0.2s 0.5s;
}

.hover-text span,
.hover-text span {
  display: none;
  margin-left: 6px;
}

.hover-text:hover span,
.hover-text:focus span {
  display: inline-block;
}

.modal {
  display: block;
  overflow: hidden;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1050;
  -webkit-overflow-scrolling: touch;
  outline: 0;
  height: 0;
  opacity: 0;
  transition: opacity 0.3s ease-out;
}
.modal.open, .modal.show {
  opacity: 1;
  height: auto;
}
.modal .modal-center-container {
  display: table;
  height: 100%;
  width: 100%;
  pointer-events: none;
}
.modal .modal-center-container .modal-dialog {
  position: relative;
}
.modal .modal-center-container .modal-content {
  position: relative;
  width: inherit;
  height: inherit;
  max-height: calc(100vh - 20px);
  margin: 0 auto;
  pointer-events: all;
  background-color: var(--white-color);
  border: 1px solid var(--grayscale-color);
  border: 1px solid var(--primary-color);
  border-radius: 4px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.19), 0 5px 5px rgba(0, 0, 0, 0.23);
  background-clip: padding-box;
  outline: 0;
}
.modal .modal-center-container .modal-content .modal-header {
  background-color: var(--primary-white-color);
  padding: 14px 15px 10px;
  border-bottom: 1px solid var(--primary-light1-color);
  border-radius: 4px 4px 0 0;
  clear: both;
}
.modal .modal-center-container .modal-content .modal-header .modal-title {
  margin: 0;
  line-height: 1.5;
}
.modal .modal-center-container .modal-content .modal-body {
  position: relative;
  padding: 1rem;
  max-height: calc(100vh - 135px); /*height of footer/header and modal height offset*/
  overflow-y: auto;
}
.modal .modal-center-container .modal-content .modal-body .ng2-datetime-picker {
  position: absolute;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}
.modal .modal-center-container .modal-content .modal-body.modal-fixed .ng2-datetime-picker {
  position: fixed;
}
.modal .modal-center-container .modal-content .modal-footer {
  padding: 11px 15px;
  text-align: right;
  border-top: 1px solid var(--primary-light1-color);
  border-radius: 0 0 4px 4px;
}
.modal .modal-center-container .modal-content .modal-footer:before, .modal .modal-center-container .modal-content .modal-footer:after {
  content: " ";
  display: table;
}
.modal .modal-center-container .modal-content .modal-footer:after {
  clear: both;
}
.modal .modal-center-container .modal-content .modal-footer .bh-modal-buttons {
  display: inline-block;
}
.modal .modal-center-container .modal-content .modal-footer .btn + .btn {
  margin-left: 5px;
  margin-bottom: 0;
}
.modal .modal-center-container .modal-content .hidden-modal {
  display: none;
}
.modal .modal-content {
  position: relative;
  width: inherit;
  height: inherit;
  max-height: calc(100vh - 20px);
  margin: 0 auto;
  pointer-events: all;
  background-color: var(--white-color);
  border: 1px solid var(--grayscale-color);
  border: 1px solid var(--primary-color);
  border-radius: 4px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.19), 0 5px 5px rgba(0, 0, 0, 0.23);
  background-clip: padding-box;
  outline: 0;
}
.modal .modal-content .modal-header {
  background-color: var(--primary-white-color);
  padding: 14px 15px 10px;
  border-bottom: 1px solid var(--primary-light1-color);
  border-radius: 4px 4px 0 0;
  clear: both;
}
.modal .modal-content .modal-header .modal-title {
  margin: 0;
  line-height: 1.5;
}
.modal .modal-content .modal-body {
  position: relative;
  padding: 1rem;
  max-height: calc(100vh - 135px); /*height of footer/header and modal height offset*/
  overflow-y: auto;
}
.modal .modal-content .modal-body .ng2-datetime-picker {
  position: absolute;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}
.modal .modal-content .modal-body.modal-fixed .ng2-datetime-picker {
  position: fixed;
}
.modal .modal-content .modal-footer {
  padding: 11px 15px;
  text-align: right;
  border-top: 1px solid var(--primary-light1-color);
  border-radius: 0 0 4px 4px;
}
.modal .modal-content .modal-footer:before, .modal .modal-content .modal-footer:after {
  content: " ";
  display: table;
}
.modal .modal-content .modal-footer:after {
  clear: both;
}
.modal .modal-content .modal-footer .bh-modal-buttons {
  display: inline-block;
}
.modal .modal-content .modal-footer .btn + .btn {
  margin-left: 5px;
  margin-bottom: 0;
}
.modal .modal-content .hidden-modal {
  display: none;
}

.modal-dialog-scrollable {
  height: unset;
  max-height: calc(100% - 1rem);
}

.large-dropdown-modal.modal-dialog-scrollable .modal-content {
  overflow: unset;
}
.large-dropdown-modal.modal-dialog-scrollable .modal-body {
  overflow-y: unset;
  overflow: unset;
}
.large-dropdown-modal.modal .modal-content .modal-body {
  overflow-y: unset !important;
  overflow: unset;
}
.large-dropdown-modal.modal-center-container .modal-content .modal-body {
  overflow-y: unset !important;
  overflow: unset;
}
.large-dropdown-modal .dropdown-menu {
  max-height: 40vh !important;
  overflow-x: hidden !important;
  overflow-y: scroll !important;
}

@media (max-width: 767px) {
  .modal-content {
    margin: 0px 10px !important;
  }
}
@media (min-width: 768px) {
  .modal-dialog {
    width: 500px;
    margin: 30px auto;
  }
  .modal-content {
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  }
  .modal-sm {
    width: 300px;
  }
}
@media (min-width: 992px) {
  .modal-lg, .modal-xl {
    width: 800px;
  }
}
@media (min-width: 1200px) {
  .modal-xl {
    width: 1150px;
  }
}
.modal.fade.in {
  height: 100%;
}
.modal.fade.in .modal-center-container {
  background-color: rgba(0, 0, 0, 0.6);
}

.progress-bar-label {
  text-align: center;
  font-size: 0.875rem;
  line-height: 30px;
}

.progress-bar-container {
  overflow: hidden;
  height: 30px;
  margin: 5px 0;
  background-color: var(--grayscale-white-color);
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
  border-radius: 3px;
  border: 1px solid var(--grayscale-light-color);
}
.progress-bar-container .progress-bar {
  float: left;
  min-width: 4%;
  width: 0;
  height: 100%;
  font-size: 0.875rem;
  line-height: 30px;
  color: white;
  text-align: center;
  background-color: var(--primary-color3);
  box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.15);
  transition: width 0.6s ease;
}

.multiselect-col-header {
  border: 1px solid var(--primary-light1-color);
  border-bottom: none;
  padding: 3px 15px 1px;
  background-color: var(--primary-white-color);
}
.multiselect-col-header .form-section-header h3, .multiselect-col-header .form-section-header .h3 {
  margin: 25px 0 25px;
}
.multiselect-col-header .form-section-header h3 span, .multiselect-col-header .form-section-header .h3 span {
  background-color: var(--primary-white-color);
}
.multiselect-col-header .form-section-header .btn {
  top: -15px;
  right: 15px;
  padding: 6px 10px;
}
.multiselect-col-header .form-section-header .btn .fa-check {
  color: var(--primary-color);
}
.multiselect-col-header .form-section-header .btn:not(:disabled):hover .fa-check, .multiselect-col-header .form-section-header .btn :not(:disabled):focus .fa-check {
  color: white;
}

.multiselect-col1,
.multiselect-col2 {
  overflow-y: auto;
  height: 65vh;
  border: 1px solid var(--primary-light1-color);
  padding-top: 5px;
}
.multiselect-col1 .group-title,
.multiselect-col2 .group-title {
  color: var(--primary-color);
  font-weight: bold;
  margin: 15px 0 5px 15px;
}
.multiselect-col1 .option-container,
.multiselect-col2 .option-container {
  padding-left: 15px;
  padding-right: 10px;
}
.multiselect-col1 .option-container .group-title,
.multiselect-col2 .option-container .group-title {
  margin-left: 0;
}
.multiselect-col1 .option-container .fa,
.multiselect-col1 .option-container [class*=icon-],
.multiselect-col2 .option-container .fa,
.multiselect-col2 .option-container [class*=icon-] {
  margin-left: 10px;
}
.multiselect-col1 .option-container .fa.fa-bar-chart.flip,
.multiselect-col1 .option-container [class*=icon-].fa-bar-chart.flip,
.multiselect-col2 .option-container .fa.fa-bar-chart.flip,
.multiselect-col2 .option-container [class*=icon-].fa-bar-chart.flip {
  transform: rotate(90deg) scaleX(-0.9) scaleY(1.3);
}
.multiselect-col1 .option-container.draggable-row,
.multiselect-col2 .option-container.draggable-row {
  cursor: move;
  position: relative;
  padding-left: 35px;
  border: 1px solid transparent;
}
.multiselect-col1 .option-container.draggable-row .list-order,
.multiselect-col2 .option-container.draggable-row .list-order {
  color: var(--primary-light1-color);
  position: relative;
  left: -9px;
  width: 10px;
  display: inline-block;
}
.multiselect-col1 .option-container.draggable-row.drag-moving,
.multiselect-col2 .option-container.draggable-row.drag-moving {
  opacity: 0.6;
}
.multiselect-col1 .option-container.draggable-row.drag-hover,
.multiselect-col2 .option-container.draggable-row.drag-hover {
  border: 2px dashed var(--primary-color);
  opacity: 0.6;
}
.multiselect-col1 .option-container.draggable-row.drag-hover *,
.multiselect-col2 .option-container.draggable-row.drag-hover * {
  pointer-events: none;
}
.multiselect-col1 .option-container.draggable-row:hover,
.multiselect-col2 .option-container.draggable-row:hover {
  border: 1px solid var(--primary-light1-color);
}
.multiselect-col1 .option-container.draggable-row:hover .list-order,
.multiselect-col2 .option-container.draggable-row:hover .list-order {
  color: var(--primary-color);
}
.multiselect-col1 .option-container.draggable-row:before, .multiselect-col1 .option-container.draggable-row:after,
.multiselect-col2 .option-container.draggable-row:before,
.multiselect-col2 .option-container.draggable-row:after {
  font-family: "FontAwesome", sans-serif;
  content: "\f142";
  font-size: 18px;
  color: var(--primary-light1-color);
  position: absolute;
  left: 9px;
  top: 7px;
}
.multiselect-col1 .option-container.draggable-row:after,
.multiselect-col2 .option-container.draggable-row:after {
  left: 16px;
}
.multiselect-col1 .option-container.draggable-row:hover::before, .multiselect-col1 .option-container.draggable-row:hover::after,
.multiselect-col2 .option-container.draggable-row:hover::before,
.multiselect-col2 .option-container.draggable-row:hover::after {
  color: var(--primary-color);
}

@media (max-width: 991px) {
  .multiselect-col-header .form-section-header .btn {
    position: relative;
    right: 0;
  }
}
.contact-card {
  display: block;
  margin-bottom: 20px;
  background-color: white;
  border: 1px solid var(--grayscale-light-color);
  border-radius: 4px;
  box-shadow: 0px 0px 5px var(--grayscale-light-color-lighten-7percent);
}
.contact-card .contact-card-header {
  background-color: var(--primary-white-color);
  padding: 12px 14px;
  border-bottom: 1px solid var(--grayscale-light-color);
  position: relative;
  border-radius: 4px 4px 0 0;
}
.contact-card .contact-card-header a {
  cursor: pointer;
}
.contact-card .contact-card-header h4, .contact-card .contact-card-header .h4 {
  text-align: center;
  margin: 5px 0;
  padding: 0 0;
  border-bottom: none;
  color: var(--grayscale-color);
}
.contact-card .contact-card-header button {
  position: absolute;
  top: 10px;
  right: 10px;
}
.contact-card .contact-card-content {
  padding-bottom: 0;
}
.contact-card h2, .contact-card .h2 {
  text-align: center;
  margin: 5px;
  color: var(--primary-color);
}
.contact-card .nav {
  margin-top: 15px;
}
.contact-card h4, .contact-card .h4 {
  margin: 14px 0 0;
  padding: 0 10px 6px;
  border-bottom: 1px solid var(--grayscale-light-color);
  color: var(--primary-color);
}
.contact-card h4 i, .contact-card .h4 i {
  float: right;
  padding-right: 2px;
}
.contact-card .nav > li > a {
  padding: 8px 10px;
  border: 1px solid var(--primary-color);
}
.contact-card .nav > li > a:focus, .contact-card .nav > li > a:hover {
  background-color: var(--primary-color);
  color: white;
}
.contact-card .nav > li:first-child a {
  margin-right: 15px;
}

.contact-card .contact-card-header.secondary {
  background-color: var(--grayscale-white-color);
}

.table-contact-card {
  margin: 4px 0;
  border: none !important;
  table-layout: fixed;
}
.table-contact-card > tbody > tr:first-child {
  border-top: none;
}
.table-contact-card > tbody > tr:last-child {
  border-bottom: none;
}
.table-contact-card > tbody > tr > td {
  border: none;
  vertical-align: middle;
  padding: 4px 8px;
  height: 37px;
}
.table-contact-card > tbody > tr > td:first-child {
  padding-left: 10px;
  padding-right: 4px;
  min-width: 40px;
  width: 40px;
}
.table-contact-card > tbody > tr > td:first-child.btn2 {
  min-width: 70px;
  width: 70px;
}
.table-contact-card > tbody > tr > td:last-child {
  text-align: right;
  padding-right: 10px;
  width: 50%;
}
.table-contact-card > tbody > tr > td:last-child div {
  width: 100%;
  word-break: keep-all;
}

@media (max-width: 1599px) {
  .my-tasks .nav-tabs.nav-justified li {
    float: none;
    display: block;
    width: 100%;
  }
}
table.tasks {
  margin-top: -1px;
}
table.tasks tr {
  cursor: pointer;
}
table.tasks tr:not(.outlook-task):not(.no-hover):hover, table.tasks tr:not(.outlook-task):not(.no-hover):focus {
  background-color: var(--primary-color);
  color: white;
}
table.tasks tr:not(.outlook-task):not(.no-hover):hover td span.outlook-icon, table.tasks tr:not(.outlook-task):not(.no-hover):focus td span.outlook-icon {
  background-color: white;
  border: 2px solid white;
  box-sizing: content-box;
  top: 8px;
  right: 8px;
}
table.tasks tr:not(.outlook-task):not(.no-hover):hover td span.fa, table.tasks tr:not(.outlook-task):not(.no-hover):hover td .task-activity, table.tasks tr:not(.outlook-task):not(.no-hover):hover td .task-client, table.tasks tr:not(.outlook-task):not(.no-hover):focus td span.fa, table.tasks tr:not(.outlook-task):not(.no-hover):focus td .task-activity, table.tasks tr:not(.outlook-task):not(.no-hover):focus td .task-client {
  color: white;
}
table.tasks tr:first-child td {
  border-top: 1px solid var(--primary-light1-color-darken-10percent);
}
table.tasks tr.outlook-task, table.tasks tr.no-hover {
  cursor: default;
}
table.tasks td {
  position: relative;
  padding: 10px;
}
table.tasks td div.task-datetime {
  font-size: 0.9625rem;
  border-bottom: 1px solid var(--grayscale-light-color);
  margin-bottom: 4px;
  padding-bottom: 2px;
}
@media (max-width: 1599px) {
  table.tasks td div.task-datetime {
    font-size: 15px;
  }
}
table.tasks td div.task-activity {
  color: var(--primary-color);
  padding-left: 40px;
  font-weight: bold;
}
table.tasks td div.task-subject {
  padding-left: 40px;
}
table.tasks td div.task-client {
  padding-left: 40px;
  font-style: italic;
  color: var(--primary-color);
}
table.tasks td span.fa {
  color: var(--primary-color);
  position: absolute;
  top: 45px;
  left: 12px;
}
table.tasks td span.outlook-icon {
  position: absolute;
  top: 10px;
  right: 10px;
  background-image: url("../assets/images/outlook-icon.png");
  background-size: contain;
  width: 16px;
  height: 16px;
}

.file-dropbox {
  display: block;
  font-size: 0.9625rem;
  text-align: center;
  padding: 30px 0;
  color: var(--grayscale-color);
  border: 2px dashed var(--grayscale-light-color);
  border-radius: 6px;
  cursor: default;
  margin-bottom: 10px;
}
.file-dropbox i {
  color: var(--primary-color);
}

label.upload-label input[type=file] {
  position: fixed;
  top: -1000px;
}
label.upload-label.fa-upload {
  padding-top: 2px;
  width: 38px;
}

.btn.upload-label {
  width: auto;
}

.filetype-icon {
  color: var(--primary-color);
  margin-top: 18px;
}

.file-dropbox-error {
  color: var(--error-color);
  background-color: var(--error-light-color);
  border: 1px solid var(--error-color);
  width: auto;
  padding: 6px 10px;
  font-size: 0.875rem;
  border-radius: 4px;
  z-index: 100;
  font-weight: bold;
  white-space: pre;
  margin-bottom: 10px;
}

.file-dropbox-error span i {
  font-family: "FontAwesome", sans-serif;
  margin-right: 8px;
  font-weight: normal;
}

.report-box-container a, .report-box-container a:hover, .report-box-container a:focus {
  color: var(--grayscale-color);
}

.report-box {
  text-align: center;
  cursor: pointer;
  padding-bottom: 15px;
}
.report-box:before {
  font-family: "FontAwesome", sans-serif;
  content: "\f0f6";
  font-size: 42px;
  color: var(--primary-color);
  display: block;
}
.report-box:focus:before, .report-box:hover:before {
  content: "\f15c";
  color: var(--primary-color3);
}

.corresponence-list {
  font-size: 0.9625rem;
  padding: 10px 10px 0 20px;
  color: var(--primary-color);
  border: 1px solid var(--primary-light1-color);
  border-radius: 4px;
}
.corresponence-list ul {
  padding: 0;
}
.corresponence-list ul ul {
  padding-left: 32px;
}
.corresponence-list li {
  list-style: none;
  padding: 10px 0;
}
.corresponence-list li a {
  cursor: pointer;
}
.corresponence-list li .fa {
  margin-right: 5px;
  position: relative;
  right: 2px;
}

.icon-grid div {
  margin: 12px 0;
}
.icon-grid div .fa {
  margin: 0 10px;
  color: var(--grayscale-color) !important;
}

.icon-view {
  padding-top: 20px;
  color: var(--grayscale-color);
}

.draggable-field[class*=col-],
.draggable-field div[class*=col-] {
  cursor: move;
  padding: 15px;
  outline: 2px solid transparent;
  outline-offset: -8px;
}
.draggable-field[class*=col-] .form-group,
.draggable-field div[class*=col-] .form-group {
  margin-bottom: 0;
}
.draggable-field[class*=col-]:hover,
.draggable-field div[class*=col-]:hover {
  outline: 2px dashed var(--primary-color);
  position: relative;
  right: 2px;
  bottom: 2px;
}
.draggable-field[class*=col-] img,
.draggable-field div[class*=col-] img {
  max-width: 100%;
  max-height: 100%;
}
.draggable-field.active[class*=col-], .draggable-field.active div[class*=col-] {
  outline: 2px solid var(--primary-color3);
  background-color: var(--primary-light2-color);
}
.draggable-field.drag-moving[class*=col-], .draggable-field.drag-moving div[class*=col-] {
  opacity: 0.75;
}
.draggable-field.drag-hover[class*=col-], .draggable-field.drag-hover div[class*=col-] {
  transform: scale(0.88);
  transition: 0.3s ease-out;
  opacity: 0.6;
}

.bdf-add-btn {
  border: 1px solid var(--grayscale-light-color);
  padding: 25px;
  width: 100%;
  height: 92px;
  text-align: center;
}
.bdf-add-btn .fa {
  color: var(--primary-light1-color);
}
.bdf-add-btn:hover {
  border: 1px solid var(--primary-color);
  outline: 1px solid var(--primary-color);
}
.bdf-add-btn:hover .fa {
  color: var(--primary-color);
}

.message-builder-container {
  border: 1px solid var(--primary-light1-color);
  background-color: var(--primary-white-color-lighten-2percent);
  margin: 20px 0;
  padding: 35px 0 5px;
  border-radius: 4px;
}

.message-builder-actions {
  position: absolute;
  top: -2px;
  right: 23px;
}
.message-builder-actions .btn {
  margin-right: 8px;
}
.message-builder-actions .btn .fa {
  margin-right: 8px;
}

.message-builder-block {
  padding: 15px;
  background-color: white;
  border: 1px solid var(--grayscale-light-color);
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.message-builder-block[class*=mbb-]:before {
  font-family: "FontAwesome", sans-serif;
  content: "";
  font-size: 0.875rem;
  color: var(--primary-color);
  position: absolute;
  bottom: 15px;
  right: 18px;
}
.message-builder-block.mbb-text:before, .message-builder-block.mbb-text2:before {
  content: "\f031";
}
.message-builder-block.mbb-field:before {
  content: "\f00e";
}
.message-builder-block.mbb-link:before {
  content: "\f0c1";
}
.message-builder-block.mbb-date:before {
  content: "\f073";
}

.query-filter-container label.form-group, .query-filter-container label .form-control, .query-filter-container .form-group .form-control {
  margin: 0;
}
.query-filter-container .definition-output {
  background-color: var(--primary-light2-color);
  margin: 15px 0;
  padding: 10px 15px;
  border: 1px solid var(--primary-color3);
  border-radius: 4px;
  position: relative;
}
.query-filter-container .definition-output strong {
  color: var(--primary-color);
}
.query-filter-container .filter-group {
  background-color: var(--primary-white-color-lighten-2percent);
  margin: 35px 0;
  padding: 25px 15px 5px;
  border: 1px solid var(--primary-light1-color);
  border-radius: 4px;
  position: relative;
}
.query-filter-container .filter-group .and-or-dropdown {
  position: absolute;
  top: -15px;
  left: 35px;
}
.query-filter-container .filter-group .and-or-dropdown .form-control-sm {
  width: 67px;
}
.query-filter-container .filter-group .and-or-dropdown .form-control-sm .form-control {
  border-color: var(--primary-color);
}
.query-filter-container .filter-group .button-options {
  position: absolute;
  top: -14px;
  left: 125px;
}
.query-filter-container .filter-group .button-options.first {
  left: 30px;
}
.query-filter-container .filter-group .button-options .btn {
  display: inline;
  margin: 0 5px;
  padding: 3px;
}
.query-filter-container .filter-group .button-options .btn .large {
  font-weight: bold;
  letter-spacing: 1.5px;
  line-height: 16px;
  padding: 0 1px 0 3px;
}
.query-filter-container .filter-group .delete-col label {
  width: calc(100% - 60px);
}
.query-filter-container .filter-group .delete-col button {
  margin: 0 0 5px 10px;
}
.query-filter-container .filter-group.tier2 {
  margin: 35px 0 15px 0;
  padding-right: 0;
  background-color: var(--primary-white-color);
}

.query-filter-table .btn2-col {
  width: 85px !important;
}
.query-filter-table .tree-cell i, .query-filter-table th i {
  padding: 0 3px;
}
.query-filter-table .andor {
  margin-right: 6px;
  color: var(--primary-color);
  font-weight: bold;
  text-transform: uppercase;
}
.query-filter-table .definition-output {
  background-color: var(--primary-light2-color);
  margin: 0;
  padding: 10px 15px;
  border-left: 1px solid var(--primary-light1-color);
  border-right: 1px solid var(--primary-light1-color);
  position: relative;
}
.query-filter-table .definition-output .andor {
  margin: 0;
}

.andor-radios {
  text-align: center;
  width: 190px;
  background-color: var(--primary-white-color);
  outline: 1px solid var(--primary-light1-color);
  margin-bottom: 15px;
  padding-left: 12px;
}
.andor-radios .form-group {
  text-transform: uppercase;
  margin-bottom: 0;
}
.andor-radios span.radio-label {
  margin-bottom: 0;
}

/*amounts fees expenses*/
.afe-controls .no-label {
  padding-top: 4px;
}
.afe-controls .no-label .form-group {
  margin-bottom: 3px;
}

/*Profitability View*/
.profitability-btn {
  position: absolute;
  right: 13px;
  bottom: -31px;
  width: 166px;
  font-weight: bold;
  font-size: 12px;
  padding: 7px 10px 6px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.profitability-btn.btn-white2, .profitability-btn .btn-sm, .profitability-btn .btn-group-sm > .btn {
  padding: 7px 10px 6px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.profitability-btn:disabled, .profitability-btn.disabled {
  background-color: var(--disabled-color) !important;
  color: var(--primary-color) !important;
  border-color: var(--disabled-color) !important;
}

.profitability-container {
  background-color: var(--primary-white-color-lighten-2percent);
  padding: 30px 15px 0 15px;
  margin-bottom: 15px;
  position: relative;
  top: -42px;
  border: 1px solid var(--primary-light1-color);
  border-radius: 0 0 4px 4px;
}
.profitability-container .form-box-container {
  background-color: white;
}
.profitability-container .form-section-header h2 span, .profitability-container .form-section-header .h2 span,
.profitability-container .form-section-header h3 span,
.profitability-container .form-section-header .h3 span {
  background-color: var(--primary-white-color-lighten-2percent);
}
.profitability-container .calculate-button {
  z-index: 1;
}

.profitability-tabs {
  margin-top: -57px;
}
.profitability-tabs .nav-tabs {
  border-bottom: 0;
}
.profitability-tabs .tab-group .nav-tabs.nav-justified {
  margin: 0;
}

/*Negotiation Matrix*/
.matrix-table th, .matrix-table td {
  min-width: unset;
}
.matrix-table th button, .matrix-table td button {
  width: 100%;
}
.matrix-table tr td:first-of-type {
  border-right: 2px solid var(--primary-light1-color-darken-10percent);
  background-color: var(--primary-white-color);
  width: 100px;
}

/*Score Trend Reports*/
.score-trend-layout .table-hscroll {
  margin-bottom: 20px;
}
.score-trend-layout thead {
  text-transform: uppercase;
}
.score-trend-layout table > thead > tr > th,
.score-trend-layout table > tbody > tr > th,
.score-trend-layout table > tfoot > tr > th,
.score-trend-layout table > thead > tr > td,
.score-trend-layout table > tbody > tr > td,
.score-trend-layout table > tfoot > tr > td {
  padding: 6px 10px;
}
.score-trend-layout tr td:first-child,
.score-trend-layout th {
  font-weight: bold;
  text-align: left;
}

/*Business Rules & Decision Tables*/
.set-radios .andor-radios {
  width: 90px;
}

.condition-container,
.action-container {
  margin: 20px 0;
  padding: 10px 0 0;
  border: 1px solid var(--grayscale-light-color);
  position: relative;
}
.condition-container .action-label,
.action-container .action-label {
  position: absolute;
  top: -22px;
  left: 15px;
  background-color: white;
  border: 1px solid var(--primary-color3);
  border-left-width: 4px;
  padding: 8px 12px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.08), 0 1px 1px rgba(0, 0, 0, 0.16);
}
.condition-container .ignore-container .header-checkbox-container,
.action-container .ignore-container .header-checkbox-container {
  right: 20px;
  top: -22px;
  border: 1px solid var(--primary-color3);
  border-radius: 0;
  padding: 0 12px;
}
.condition-container .form-group,
.action-container .form-group {
  margin-bottom: 10px;
}
.condition-container bh-r-checkbox:last-of-type,
.action-container bh-r-checkbox:last-of-type {
  padding-bottom: 10px;
}
.condition-container bh-r-date.date-options,
.condition-container bh-r-dropdown.date-options,
.condition-container bh-r-search-and-select.date-options,
.condition-container .bh-r-date.date-options,
.condition-container .bh-r-dropdown.date-options,
.condition-container .bh-r-search-and-select.date-options,
.action-container bh-r-date.date-options,
.action-container bh-r-dropdown.date-options,
.action-container bh-r-search-and-select.date-options,
.action-container .bh-r-date.date-options,
.action-container .bh-r-dropdown.date-options,
.action-container .bh-r-search-and-select.date-options {
  margin-top: -10px;
}
.condition-container .btn-icon-sm,
.action-container .btn-icon-sm {
  margin-top: 5px;
  float: right;
}
.condition-container .wj-flexgrid .btn-icon-sm,
.action-container .wj-flexgrid .btn-icon-sm {
  margin-top: 0;
  float: none;
}

.condition-container {
  background: var(--primary-white-color);
  border-left: 6px solid var(--primary-color);
}

.action-container {
  border-left: 6px solid var(--primary-color3);
  margin: 35px 0;
  padding: 30px 0 0;
}

/*News Feed Post*/
.news-message {
  border: 1px solid var(--primary-light1-color);
  border-left-width: 6px;
  background-color: var(--primary-white-color-lighten-2percent);
  padding: 7px 75px 7px 12px;
  display: inline-block;
  font-size: 13px;
  width: 100%;
  margin: 7px 0;
  position: relative;
}
.news-message.fi {
  border-color: var(--primary-light1-color);
  background-color: var(--primary-white-color);
}
.news-message.bakerhill {
  border-color: var(--primary-color3);
  background-color: var(--primary-light2-color);
}
.news-message.comment {
  border-color: var(--grayscale-light-color);
  background-color: var(--grayscale-white-color-lighten-5percent);
}
.news-message.unread {
  font-weight: bold;
}
.news-message span {
  display: block;
  padding-bottom: 5px;
}
.news-message span + span {
  border-top: 1px solid var(--grayscale-light-color-lighten-7percent);
  padding: 3px 0 0 0;
}
.news-message span + span span {
  border: none;
  padding: 0 0 0 15px;
  display: inline;
}
.news-message a {
  color: var(--primary-color);
  text-decoration: underline;
}
.news-message a:not([href]):not([class]), .news-message a:not([href]):not([class]):hover {
  color: var(--primary-color);
  text-decoration: underline;
}
.news-message.btn {
  top: 15px;
}

.business-hours h4, .business-hours .h4 {
  text-align: right;
  margin-top: 12px;
}
.business-hours .row:first-of-type h4, .business-hours .row:first-of-type .h4 {
  text-align: center;
}

.header-help {
  padding: 15px 0;
}
.header-help h1, .header-help .h1 {
  color: var(--primary-dark-color);
  display: inline-block;
  margin: 0;
}
.header-help img {
  width: 210px;
  padding: 0 10px 0 15px;
  vertical-align: text-bottom;
  position: relative;
  top: 2px;
}
.header-help hr {
  margin: 15px 0;
}

.activity-console {
  border: 1px solid var(--grayscale-light-color);
  background-color: var(--grayscale-white-color);
  padding: 10px;
  margin: 15px 0 50px;
}

/*Module Homepage Slider*/
.chart-slider {
  position: relative;
  text-align: center;
}
.chart-slider label {
  margin: 0;
  cursor: pointer;
}
.chart-slider input[type=radio] {
  display: none;
}
.chart-slider #slide1:checked ~ .slides .chart-container {
  margin-left: 0;
}
.chart-slider #slide2:checked ~ .slides .chart-container {
  margin-left: -50%;
}
.chart-slider #slide3:checked ~ .slides .chart-container {
  margin-left: -100%;
}
.chart-slider #slide4:checked ~ .slides .chart-container {
  margin-left: -150%;
}
.chart-slider #slide5:checked ~ .slides .chart-container {
  margin-left: -200%;
}
.chart-slider #slide6:checked ~ .slides .chart-container {
  margin-left: -250%;
}
.chart-slider .slides {
  overflow: hidden;
}
.chart-slider .slides .chart-container {
  width: 300%;
  transition: all 800ms cubic-bezier(0.77, 0, 0.175, 1);
  transition-timing-function: cubic-bezier(0.77, 0, 0.175, 1);
}
.chart-slider .slides .chart-container .chart {
  width: 16.6666666667%;
  float: left;
}
.chart-slider .slides .chart-container .chart img {
  width: calc(100% - 20px);
  height: auto;
  margin: 0 10px;
}
.chart-slider .slides .chart-container .chart chart {
  width: calc(100% - 40px);
  height: auto;
  margin: 5px 20px;
}
.chart-slider .arrows {
  width: 100%;
  position: absolute;
  top: calc(50% - 55px);
}
.chart-slider .arrows label {
  display: none;
  width: 36px;
  height: 66px;
  border-radius: 6px;
  transition: opacity 0.2s ease-out;
  background-color: white;
  border: 1px solid var(--primary-light1-color);
  color: var(--primary-color);
  font-size: 40px;
  text-align: center;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}
.chart-slider .arrows label:hover {
  background: var(--primary-color);
  color: white;
}
.chart-slider .slide-number {
  display: inline-block;
  text-align: center;
  padding: 5px;
  position: relative;
  bottom: 25px;
  background-color: white;
  border: 1px solid var(--primary-light1-color);
  border-radius: 4px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}
.chart-slider .slide-number label {
  border-radius: 3px;
  display: inline-block;
  width: 20px;
  height: 20px;
  background: var(--primary-color);
  color: white;
}
.chart-slider .slide-number label:hover {
  background: var(--primary-color3);
}
.chart-slider #slide1:checked ~ .arrows label:nth-child(2),
.chart-slider #slide2:checked ~ .arrows label:nth-child(3),
.chart-slider #slide3:checked ~ .arrows label:nth-child(4),
.chart-slider #slide4:checked ~ .arrows label:nth-child(5),
.chart-slider #slide5:checked ~ .arrows label:nth-child(6) {
  float: right;
  display: block;
}
.chart-slider #slide1:checked ~ .arrows label:nth-child(2):before,
.chart-slider #slide2:checked ~ .arrows label:nth-child(3):before,
.chart-slider #slide3:checked ~ .arrows label:nth-child(4):before,
.chart-slider #slide4:checked ~ .arrows label:nth-child(5):before,
.chart-slider #slide5:checked ~ .arrows label:nth-child(6):before {
  font-family: "FontAwesome", sans-serif;
  content: "\f105";
  vertical-align: middle;
  font-size: 3.4rem;
}
.chart-slider #slide2:checked ~ .arrows label:nth-child(1),
.chart-slider #slide3:checked ~ .arrows label:nth-child(2),
.chart-slider #slide4:checked ~ .arrows label:nth-child(3),
.chart-slider #slide5:checked ~ .arrows label:nth-child(4),
.chart-slider #slide6:checked ~ .arrows label:nth-child(5) {
  float: left;
  display: block;
}
.chart-slider #slide2:checked ~ .arrows label:nth-child(1):before,
.chart-slider #slide3:checked ~ .arrows label:nth-child(2):before,
.chart-slider #slide4:checked ~ .arrows label:nth-child(3):before,
.chart-slider #slide5:checked ~ .arrows label:nth-child(4):before,
.chart-slider #slide6:checked ~ .arrows label:nth-child(5):before {
  font-family: "FontAwesome", sans-serif;
  content: "\f104";
  vertical-align: middle;
  font-size: 3.4rem;
}
.chart-slider #slide1:checked ~ .slide-number label:nth-child(1),
.chart-slider #slide2:checked ~ .slide-number label:nth-child(2),
.chart-slider #slide3:checked ~ .slide-number label:nth-child(3),
.chart-slider #slide4:checked ~ .slide-number label:nth-child(4),
.chart-slider #slide5:checked ~ .slide-number label:nth-child(5),
.chart-slider #slide6:checked ~ .slide-number label:nth-child(6) {
  background: var(--primary-color3);
  width: 22px;
  height: 22px;
  padding: 1px;
}
.chart-slider .arrows,
.chart-slider .arrows label,
.chart-slider .slides,
.chart-slider .slide-number,
.chart-slider .slide-number label {
  transition: all 0.3s ease-out;
}
@media (max-width: 1199px) {
  .chart-slider #slide1:checked ~ .slides .chart-container {
    margin-left: 0;
  }
  .chart-slider #slide2:checked ~ .slides .chart-container {
    margin-left: -100%;
  }
  .chart-slider #slide3:checked ~ .slides .chart-container {
    margin-left: -200%;
  }
  .chart-slider #slide4:checked ~ .slides .chart-container {
    margin-left: -300%;
  }
  .chart-slider #slide5:checked ~ .slides .chart-container {
    margin-left: -400%;
  }
  .chart-slider #slide6:checked ~ .slides .chart-container {
    margin-left: -500%;
  }
  .chart-slider .slides .chart-container {
    width: 600%;
  }
}
@media (min-width: 1200px) {
  .chart-slider #slide1:checked ~ .slide-number label:nth-child(2),
  .chart-slider #slide2:checked ~ .slide-number label:nth-child(3),
  .chart-slider #slide3:checked ~ .slide-number label:nth-child(4),
  .chart-slider #slide4:checked ~ .slide-number label:nth-child(5),
  .chart-slider #slide5:checked ~ .slide-number label:nth-child(6) {
    background: var(--primary-color3);
    width: 22px;
    height: 22px;
    padding: 1px;
  }
}

.top-chart {
  margin-bottom: 20px;
}
.top-chart img {
  border-radius: 4px;
  box-shadow: 0px 0px 5px var(--grayscale-light-color-lighten-7percent);
  border: 1px solid var(--primary-color);
}

/*Dashboards*/
.dashboard-container img {
  width: 100%;
  height: auto;
}
.dashboard-container img.auto-width {
  height: 100%;
  width: auto;
}
.dashboard-container .chart-placeholder-name {
  position: absolute;
  margin: 50px 30px 0;
  left: 0;
  right: 0;
  text-align: center;
}
.dashboard-container .chart-placeholder-name + img {
  border-radius: 4px;
  box-shadow: 0px 0px 5px var(--grayscale-light-color-lighten-7percent);
  border: 1px solid var(--primary-light1-color);
}
.dashboard-container .chart-view-placeholder-name,
.dashboard-container .query-table-placeholder-title {
  position: absolute;
  left: 28px;
  top: 23px;
  color: white;
  margin: 0;
  line-height: 36px;
}

.count-widget {
  color: var(--white-color);
  border-radius: 4px;
  padding: 20px;
  font-size: 1.09375rem;
  text-align: center;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.count-widget .count-number {
  font-size: 40px;
}
.count-widget:hover, .count-widget:focus {
  opacity: 0.7;
}

.bh-tabs-vertical li {
  display: block !important;
  width: 100% !important;
  margin: 2px 0;
}
.bh-tabs-vertical li.active a {
  background: var(--primary-light1-color-darken-15percent);
}
.bh-tabs-vertical .add-tab {
  width: 100% !important;
}

.bh-vertical-tab-content {
  width: 75%;
}

.notes-icon-margin-left {
  margin-left: 5px;
}

.fade.in {
  opacity: 1;
}

bh-edit-payment-stream .form-section-header .btn {
  top: -15px;
}

bh-tabs .nav-fill li a {
  margin-right: 0;
  border-radius: 0;
}

.faa-ring.animated, .faa-ring.animated-hover:hover, .faa-parent.animated-hover:hover > .faa-ring {
  -webkit-animation: ring 2s ease infinite;
  animation: ring 2s ease infinite;
}

@keyframes ring {
  0% {
    transform: rotate(-15deg);
  }
  2% {
    transform: rotate(15deg);
  }
  4% {
    transform: rotate(-18deg);
  }
  6% {
    transform: rotate(18deg);
  }
  8% {
    transform: rotate(-22deg);
  }
  10% {
    transform: rotate(22deg);
  }
  12% {
    transform: rotate(-18deg);
  }
  14% {
    transform: rotate(18deg);
  }
  16% {
    transform: rotate(-12deg);
  }
  18% {
    transform: rotate(12deg);
  }
  20%, 100% {
    transform: rotate(0deg);
  }
}
.btn-badge-violation {
  padding: 0 1px;
  border-radius: 4px;
  min-width: 20px;
  text-align: center;
  display: inline-block;
  font-weight: bold;
  margin-left: 5px;
  background-color: var(--violation-bg);
  color: var(--violation-text);
}

.btn-badge-warning {
  padding: 0 1px;
  border-radius: 4px;
  min-width: 20px;
  text-align: center;
  display: inline-block;
  font-weight: bold;
  margin-left: 5px;
  background-color: var(--warning-bg);
  color: var(--warning-text);
}

.btn-badge-information {
  padding: 0 1px;
  border-radius: 4px;
  min-width: 20px;
  text-align: center;
  display: inline-block;
  font-weight: bold;
  margin-left: 5px;
  background-color: var(--primary-white-color-lighten-2percent);
  color: var(--primary-color);
}

@media screen and (max-width: 520px) {
  .header-panel-table .select-actions {
    display: none !important;
  }
}
.sheet-selector-input {
  padding-right: 58px !important;
}

.sheet-selector-icon-right {
  font-size: 1rem;
  color: var(--primary-color);
  z-index: 8;
  position: absolute;
  right: 70px;
  top: 15px;
  pointer-events: none;
}

.sheet-selector-info {
  font-size: medium;
  font-weight: bold;
  color: forestgreen !important;
}

button.sheet-selector-clearbutton {
  border: none;
  margin: 0;
  color: var(--grayscale-color) !important;
  text-align: inherit;
  box-shadow: none;
  position: absolute;
  padding: 10px;
  right: 40px;
  z-index: 5;
  background-color: transparent;
}
button.sheet-selector-clearbutton:after {
  font-family: "FontAwesome", sans-serif;
  content: "\f00d";
  font-size: 0.9625rem;
  color: var(--primary-color);
}
button.sheet-selector-clearbutton:hover::after, button.sheet-selector-clearbutton:focus::after {
  color: var(--primary-color3);
}

.entity-picker-card {
  padding: 10px;
  border: 1px solid var(--primary-color-lighten-10percent);
  border-radius: 4px;
  background: var(--primary-white-color-lighten-4percent);
}
.entity-picker-card .entity-picker-card-details {
  padding: 0 5px 8px 2px;
  position: relative;
}
.entity-picker-card .entity-picker-card-details h5, .entity-picker-card .entity-picker-card-details .h5, .entity-picker-card .entity-picker-card-details p, .entity-picker-card .entity-picker-card-details button {
  margin-top: 5px;
}
.entity-picker-card .entity-picker-card-details h5, .entity-picker-card .entity-picker-card-details .h5 {
  color: var(--primary-color);
  font-size: 1rem;
}

.booking-loading-wrapper {
  position: relative;
  min-height: 200px;
  color: #fff;
}

.booking-loading {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: 0;
  opacity: 0.5;
  background-color: var(--primary-white-color);
  border-radius: 4px;
  min-height: 200px;
  z-index: 100;
}

.booking-loading-content {
  position: fixed;
  top: 25%;
  width: 50%;
  transform: translateX(35%);
  background: var(--primary-color);
  padding: 1em;
  border-radius: 4px;
  z-index: 101;
}

.card.tax-document, .card.gray-box {
  background-color: var(--grayscale-white-color-lighten-2percent);
  border: 1px solid var(--light-danube-blue);
  border-radius: 4px;
  margin-bottom: 15px;
  padding: 10px;
  box-shadow: var(--dark-whisper-gray) 2px 2px 6px;
}
.card.tax-document h4, .card.tax-document .h4, .card.gray-box h4, .card.gray-box .h4 {
  color: var(--primary-color);
  margin-bottom: 0;
}
.card.tax-document h6, .card.tax-document .h6, .card.gray-box h6, .card.gray-box .h6 {
  margin-top: 0;
}
.card.tax-document .text-end, .card.gray-box .text-end {
  float: right;
}
.card.tax-document .text-end .btn, .card.gray-box .text-end .btn {
  margin-right: 2px;
}
.card.tax-document .card-body td, .card.gray-box .card-body td {
  overflow-wrap: anywhere;
}

.card.gray-box.border-orange {
  border: 2px solid var(--secondary-color2);
}

.tooltip-container {
  position: relative;
  display: inline-block;
  cursor: pointer;
}

.tooltip-content {
  visibility: hidden;
  position: absolute;
  background-color: var(--primary-color);
  color: #fff;
  text-align: left;
  padding: 8px 10px;
  border-radius: 4px;
  white-space: normal;
  word-break: break-word;
  width: max-content;
  max-width: 300px;
  z-index: 1000;
  top: 100%;
  left: 0;
}

.tooltip-container:hover .tooltip-content {
  visibility: visible;
}

.tooltip-content.warning {
  background-color: var(--secondary-color2);
}

.ml-5 {
  margin-left: 5px;
}

.tooltip-r-max-50wv span {
  visibility: hidden;
  opacity: 0;
  position: absolute;
  z-index: 101;
  background: var(--primary-color3) !important;
  color: white !important;
  padding: 4px 6px !important;
  border-radius: 4px;
  border: 1px solid white;
  text-align: center;
  white-space: normal;
  font-size: 13px;
  font-weight: normal;
  font-family: Arial, Helvetica, sans-serif;
  width: max-content;
  max-width: 50vw !important;
  line-height: normal;
}

.tooltip-r-max-50wv span {
  top: 50%;
  left: 100%;
  transform: translateY(-50%);
}

.tooltip-r-max-50wv:hover span {
  visibility: visible;
  opacity: 1;
  transition: opacity 0.2s 0.5s, visibility 0.2s 0.5s;
}

/* Old Colors

$color0: #ededed; // white gray
$color1: rgb(190,190,190); // #bebebe - light gray
$color2: rgb(80,80,80); // #505050 - gray
$color3: rgb(207,214,224); // #cfd6e0 - white blue
$color4: rgb(159,172,193); // #9facc1 - light blue
$color5: rgb(88,111,146); // #586f92 - mid blue
$color6: rgb(15,48,99); // #1B3D6D - dark blue
$color7: rgb(57,213,31); // #39d51f - green
$color8: rgb(52,197,26); // #34C51A - dark green
*/
/*Theming*/
:root {
  --loading-login-screen-background:
          linear-gradient(to right, rgba(15, 48, 99, 0) 0%, rgba(75, 107, 155, .4) 25%, rgba(168, 187, 215, .4) 42%, rgba(168, 187, 215, .4) 58%, rgba(75, 107, 155, .4) 75%, rgba(15, 48, 99, 0) 100%),
          url(".././assets/images/pattern-block-lines.png") repeat;
  --loading-screen-logo: url(".././assets/images/bakerhill-nextgen-logo-white.png");
  --login-box-logo: url(".././assets/images/bakerhill-nextgen-logo.png");
  --primary-dark-color: #1B3D6D;
  --primary-color: #456aa1;
  --primary-color-darken-7percent: #3a5a88;
  --primary-color-darken-11percent: #34507a;
  --primary-color-lighten-10percent: #5f84ba;
  --primary-color-lighten-20percent: #829fca;
  --primary-color-lighten-40percent: #cad6e8;
  --primary-light1-color: #a6bad9;
  --primary-light1-color-darken-5percent:#94acd1;
  --primary-light1-color-darken-10percent: #829eca;
  --primary-light1-color-darken-15percent: #7090c2;
  --primary-light1-color-lighten-6percent: #bbcbe2;
  --primary-white-color: #e6ecf2;
  --primary-white-color-darken-2percent: #dfe7ef;
  --primary-white-color-darken-6percent: #d2dde8;
  --primary-white-color-lighten-2percent: #edf1f5;
  --primary-white-color-lighten-4percent: #f3f6f9;
  --primary-color2: #cddc29;
  --primary-color3: #81BD41;
  --primary-color3-lighten-40percent: #e5f2d8;
  --primary-color3-darken-5percent: #74aa3a;
  --primary-dark2-color: #689834;
  --primary-dark2-color-darken-10percent: #4e7227;
  --primary-light2-color: #e6f2d9;
  --primary-light2-color-darken-2percent: #e1efd1;
  --primary-light2-color-darken-6percent: #d7eac2;
  --primary-light2-color-darken-10percent: #cde5b3;
  --grayscale-color: #58595B;
  --grayscale-color-lighten-25percent: #98999b;
  --grayscale-light-color: #bdbdc2;
  --grayscale-light-color-darken-8percent: #a8a8ae;
  --grayscale-light-color-lighten-7percent: #d0d0d3;
  --grayscale-white-color: #ebebed;
  --grayscale-white-color-darken-5percent: #dedee1;
  --grayscale-white-color-darken-8percent: #d6d6da;
  --grayscale-white-color-lighten-2percent: #f0f0f2;
  --grayscale-white-color-lighten-5percent: #f8f8f9;
  --white-color: #ffffff;
  --black-color: #000000;
  --secondary-dark-color: #007299;
  --secondary-color: #77256c;
  --secondary-color2: #f68d29;
  --secondary-color3: #ffd240;
  --secondary-color3-darken-3percent: #ffce31;
  --secondary-color3-lighten-17percent: #ffe697;
  --secondary-color3-lighten-27percent: #fff2ca;
  --secondary-bright-color: #ece815;
  --error-color: #cc0000;
  --error-color-darken-10percent: #990000;
  --error-color-lighten-50percent: #ffcccc;
  --error-color-lighten-55percent: #ffe6e6;
  --error-light-color: #ffe6e6;
  --error-light-color-darken-5percent: #ffcdcd;
  --disabled-color: #abbcd5;
  --disabled-color2: #9badca;
  --disabled-color3: #a7d17a;
  --default-sprite-position: top left;
  --active-hover-sprite-position: top right;
  /*remaing colors*/
  --snow-gray: #fcfcfc;
  --light-gainsboro-gray: #f7f7f7;
  --white-smoke: #f4f4f4;
  --very-light-gray: #f0f0f0;
  --light-pink: #febbbb;
  --light-yellow: #ffffe5;
  --orange-peel: #ff9900;
  --electric-yellow: #ffff00;
  --yellow-green: #effadb;
  --whisper-gray: #ededed;
  --dark-whisper-gray: #eeeeee;
  --white-dark-smoke: #e6e6e6;
  --gainsboro-gray: #e0e0e0;
  --medium-gray: #cccccc;
  --silver: #c6c6c6;
  --silver-very-light: #b9b9b9;
  --silver-light: #bbbbbb;
  --blue-smoke: #eef1f7;
  --ivory-red: #f8f8f2;
  --spun-pearl: #afafb6;
  --very-light-blue: #e6ebf5;
  --dark-gainsboro-gray: #d9d9d9;
  --bright-gainsboro-gray: #dddddd;
  --hawakes-blue: #ccd6eb;
  --blue-violet: #9933ff;
  --nobel-gray: #999999;
  --light-danube-blue: #89a4cd;
  --matte-gray: #888888;
  --light-matte-gray: #787878;
  --dark-gray: #777777;
  --raven-gray: #707275;
  --danube-blue: #6685c2;
  --dim-gray: #666666;
  --davy-grey: #555555;
  --analog-blue: #435f8b;
  --charcoal-gray: #444444;
  --mariner-blue: #335cad;
  --dark-charcoal-gray: #333333;
  --green-waterloo: #23241f;
  --nero-dark: #222222;
  --smalt-blue: #003399;
  --deep-green: #008a00;
  --navy-blue: #0066cc;
  --bondi-blue: #0085c7;
  --violation-text: #cc0000;
  --violation-bg: #ffe6e6;
  --warning-text: #f68d29;
  --warning-bg: #fff2ca;
  --highcharts-color-2: #cddc29;
  --highcharts-color-3: #007299;
  --highcharts-color-6: #689834;
  --highcharts-color-7: #a6bad9;
}

[data-theme=fiserv] {
  --loading-login-screen-background: #666666;
  --loading-screen-logo: url(".././assets/images/Fiserv-logo-1200x603.png");
  --login-box-logo: url(".././assets/images/Fiserv-logo-1200x603.png");
  --primary-dark-color: #333333;
  --primary-color: #666666;
  --primary-color-darken-7percent: #545454;
  --primary-color-darken-11percent: #4a4a4a;
  --primary-color-lighten-10percent: gray;
  --primary-color-lighten-20percent: #999999;
  --primary-color-lighten-40percent: #cccccc;
  --primary-light1-color: #bfbfbf;
  --primary-light1-color-darken-5percent: #b2b2b2;
  --primary-light1-color-darken-10percent: #a6a6a6;
  --primary-light1-color-darken-15percent: #999999;
  --primary-light1-color-lighten-6percent: #cecece;
  --primary-white-color: #efefef;
  --primary-white-color-darken-2percent: #eaeaea;
  --primary-white-color-darken-6percent: #e0e0e0;
  --primary-white-color-lighten-2percent: #f4f4f4;
  --primary-white-color-lighten-4percent: #f9f9f9;
  --primary-color2: #4695d1;
  --primary-color3: #ff6600;
  --primary-color3-lighten-40percent: #ffe0cc;
  --primary-color3-darken-5percent: #e65c00;
  --primary-dark2-color: #ea6b15;
  --primary-dark2-color-darken-10percent: #bb5611;
  --primary-light2-color: #f2d3c4;
  --primary-light2-color-darken-2percent: #f0cdbc;
  --primary-light2-color-darken-6percent: #ecc0ab;
  --primary-light2-color-darken-10percent: #e9b49a;
  --grayscale-color: #666666;
  --grayscale-color-lighten-25percent: #a6a6a6;
  --grayscale-light-color: #e0e0e0;
  --grayscale-light-color-darken-8percent: #cccccc;
  --grayscale-light-color-lighten-7percent: #f2f2f2;
  --grayscale-white-color: #e0e0e0;
  --grayscale-white-color-darken-5percent: lightgray;
  --grayscale-white-color-darken-8percent: #cccccc;
  --grayscale-white-color-lighten-2percent: #e5e5e5;
  --grayscale-white-color-lighten-5percent: #ededed;
  --white-color: #ffffff;
  --black-color: #0e0e0e;
  --secondary-dark-color: #007299;
  --secondary-color: #77256c;
  --secondary-color2: #81bd41;
  --secondary-color3: #ffd240;
  --secondary-color3-darken-3percent: #ffce31;
  --secondary-color3-lighten-17percent: #ffe697;
  --secondary-color3-lighten-27percent: #fff2ca;
  --secondary-bright-color: #ece815;
  --error-color: #dd3435;
  --error-color-darken-10percent: #be2021;
  --error-color-lighten-50percent: white;
  --error-color-lighten-55percent: white;
  --error-light-color: #eabec0;
  --error-light-color-darken-5percent: #e4abad;
  --disabled-color: #c9c9c9;
  --disabled-color2: #c9c9c9;
  --disabled-color3: #dd9e73;
  --default-sprite-position: bottom left;
  --active-hover-sprite-position: bottom right;
  /*remaing colors*/
  --snow-gray: #fcfcfc;
  --light-gainsboro-gray: #f7f7f7;
  --white-smoke: #f4f4f4;
  --very-light-gray: #f0f0f0;
  --light-pink: #febbbb;
  --light-yellow: #ffffe5;
  --orange-peel: #ff9900;
  --electric-yellow: #ffff00;
  --yellow-green: #effadb;
  --whisper-gray: #ededed;
  --dark-whisper-gray: #eeeeee;
  --white-dark-smoke: #e6e6e6;
  --gainsboro-gray: #e0e0e0;
  --medium-gray: #cccccc;
  --silver: #c6c6c6;
  --silver-very-light: #b9b9b9;
  --silver-light: #bbbbbb;
  --blue-smoke: #eef1f7;
  --ivory-red: #f8f8f2;
  --spun-pearl: #afafb6;
  --very-light-blue: #e6ebf5;
  --dark-gainsboro-gray: #d9d9d9;
  --bright-gainsboro-gray: #dddddd;
  --hawakes-blue: #ccd6eb;
  --blue-violet: #9933ff;
  --nobel-gray: #999999;
  --light-danube-blue: #89a4cd;
  --matte-gray: #888888;
  --light-matte-gray: #787878;
  --dark-gray: #777777;
  --raven-gray: #707275;
  --danube-blue: #6685c2;
  --dim-gray: #666666;
  --davy-grey: #555555;
  --analog-blue: #435f8b;
  --charcoal-gray: #444444;
  --mariner-blue: #335cad;
  --dark-charcoal-gray: #333333;
  --green-waterloo: #23241f;
  --nero-dark: #222222;
  --smalt-blue: #003399;
  --deep-green: #008a00;
  --navy-blue: #0066cc;
  --bondi-blue: #0085c7;
  --violation-text: #cc0000;
  --violation-bg: #ffe6e6;
  --warning-text: #f68d29;
  --warning-bg: #fff2ca;
  --highcharts-color-2: #ffa366;
  --highcharts-color-3: #777777;
  --highcharts-color-6: #e65c00;
  --highcharts-color-7: #e0e0e0;
}

/*Sprite Positions*/
/*Images*/
/*Primary*/
/*Grayscale*/
/*Secondary*/
/*disabled*/
/*remaing colors*/
.darkblue {
  color: var(--primary-dark-color);
}

.blue {
  color: var(--primary-color);
}

.lightblue {
  color: var(--primary-light1-color);
}

.whiteblue {
  color: var(--primary-white-color);
}

.yellowgreen {
  color: var(--primary-color2);
}

.green {
  color: var(--primary-color3) !important;
}

.darkgreen {
  color: var(--primary-dark2-color);
}

.lightgreen {
  color: var(--primary-light2-color);
}

.gray {
  color: var(--grayscale-color);
}

.lightgray {
  color: var(--grayscale-light-color);
}

.whitegray {
  color: var(--grayscale-white-color);
}

.darkcyan {
  color: var(--secondary-dark-color);
}

.purple {
  color: var(--secondary-color);
}

.orange {
  color: var(--secondary-color2) !important;
}

.yellow {
  color: var(--secondary-color3);
}

.brightyellow {
  color: var(--secondary-bright-color);
}

.red {
  color: var(--error-color) !important;
}

.lightred {
  color: var(--error-light-color);
}

.white {
  color: var(--white-color) !important;
}

.darkblue-bg {
  background-color: var(--primary-dark-color);
}

.blue-bg {
  background-color: var(--primary-color) !important;
}

.lightblue-bg {
  background-color: var(--primary-light1-color);
}

.whiteblue-bg {
  background-color: var(--primary-white-color);
}

.yellowgreen-bg {
  background-color: var(--primary-color2);
}

.green-bg {
  background-color: var(--primary-color3);
}

.darkgreen-bg {
  background-color: var(--primary-dark2-color);
}

.lightgreen-bg {
  background-color: var(--primary-light2-color);
}

.gray-bg {
  background-color: var(--grayscale-color);
}

.lightgray-bg {
  background-color: var(--grayscale-light-color);
}

.whitegray-bg {
  background-color: var(--grayscale-white-color);
}

.darkcyan-bg {
  background-color: var(--secondary-dark-color);
}

.purple-bg {
  background-color: var(--secondary-color);
}

.orange-bg {
  background-color: var(--secondary-color2) !important;
}

.yellow-bg {
  background-color: var(--secondary-color3) !important;
}

.brightyellow-bg {
  background-color: var(--secondary-bright-color) !important;
}

.red-bg {
  background-color: var(--error-color) !important;
}

.lightred-bg {
  background-color: var(--error-light-color) !important;
}

.bh-navbar {
  height: auto;
  min-height: 44px;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
  background: linear-gradient(to bottom, var(--grayscale-white-color), var(--grayscale-white-color-darken-5percent));
  border-top: 1px solid var(--grayscale-color);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}
.bh-navbar ul {
  margin: 0;
}
.bh-navbar ul li {
  font-weight: bold;
  list-style: none;
  float: left;
}
.bh-navbar ul li a {
  color: var(--grayscale-color);
  display: block;
  text-decoration: unset;
}
.bh-navbar ul li a .fa {
  color: var(--primary-color);
}
.bh-navbar .navbar-logo {
  float: left;
  font-size: 0.9625rem;
  padding: 7px 15px;
  width: 239px;
}
.bh-navbar .navbar-logo img {
  max-width: 130px;
  height: 30px;
}
.bh-navbar .navbar-modules li.module a {
  padding: 11px 14px;
  height: 44px;
  border-left: 1px solid transparent;
  border-right: 1px solid transparent;
  font-family: "Open Sans", sans-serif;
  text-transform: uppercase;
  color: var(--primary-color);
}
.bh-navbar .navbar-modules li.module a span:first-of-type {
  display: inline-block;
  height: 22px;
  width: 22px;
  background-position: var(--default-sprite-position);
  background-size: 44px 44px;
}
.bh-navbar .navbar-modules li.module a span:first-of-type.icon-dashboard {
  background-image: url("../assets/images/icon-dashboard@2x-sprite.png");
}
.bh-navbar .navbar-modules li.module a span:first-of-type.icon-crm {
  background-image: url("../assets/images/icon-crm@2x-sprite.png");
}
.bh-navbar .navbar-modules li.module a span:first-of-type.icon-lending {
  background-image: url("../assets/images/icon-lending@2x-sprite.png");
}
.bh-navbar .navbar-modules li.module a span:first-of-type.icon-portfolio {
  background-image: url("../assets/images/icon-portfolio@2x-sprite.png");
}
.bh-navbar .navbar-modules li.module a span:first-of-type.icon-cecl {
  background-image: url("../assets/images/icon-cecl@2x-sprite.png");
}
.bh-navbar .navbar-modules li.module a span:first-of-type.icon-analytics {
  background-image: url("../assets/images/icon-analytics@2x-sprite.png");
}
.bh-navbar .navbar-modules li.module a span:first-of-type.icon-banker {
  background-image: url("../assets/images/icon-banker@2x-sprite.png");
}
.bh-navbar .navbar-modules li.module a:hover span:first-of-type {
  background-position: var(--active-hover-sprite-position);
}
.bh-navbar .navbar-modules li.module a:hover span:last-of-type {
  color: var(--primary-color3);
}
.bh-navbar .navbar-modules li.module a span:last-of-type {
  line-height: 24px;
  vertical-align: top;
  margin-left: 5px;
}
.bh-navbar .navbar-modules li.module a:hover .fa {
  color: var(--primary-color3);
}
.bh-navbar .navbar-modules li.module a .fa {
  vertical-align: 30%;
  margin-left: 5px;
}
.bh-navbar .navbar-modules li.module a:hover, .bh-navbar .navbar-modules li.module a:focus, .bh-navbar .navbar-modules li.module.active a, .bh-navbar .navbar-modules li.module.active a:hover, .bh-navbar .navbar-modules li.module.active a:focus {
  background-color: white;
  color: var(--primary-color);
  border-left: 1px solid var(--grayscale-light-color);
  border-right: 1px solid var(--grayscale-light-color);
}
.bh-navbar .navbar-modules li.module a:hover span:first-of-type, .bh-navbar .navbar-modules li.module a:focus span:first-of-type, .bh-navbar .navbar-modules li.module.active a span:first-of-type, .bh-navbar .navbar-modules li.module.active a:hover span:first-of-type, .bh-navbar .navbar-modules li.module.active a:focus span:first-of-type {
  background-position: var(--active-hover-sprite-position);
}
.bh-navbar .navbar-modules li.module.disabled a, .bh-navbar .navbar-modules li.module.disabled a:hover, .bh-navbar .navbar-modules li.module.disabled a:focus {
  background-color: transparent;
  border: none;
  opacity: 0.65;
}
.bh-navbar .navbar-modules li.module.disabled a span:first-of-type, .bh-navbar .navbar-modules li.module.disabled a:hover span:first-of-type, .bh-navbar .navbar-modules li.module.disabled a:focus span:first-of-type {
  background-position: initial;
}
.bh-navbar .navbar-menus {
  float: right;
  padding: 0;
}
.bh-navbar .navbar-menus li a {
  padding: 12px 14px;
}
.bh-navbar .navbar-menus li a span {
  margin-left: 10px;
}
.bh-navbar .navbar-menus li a:hover .fa, .bh-navbar .navbar-menus li a:focus .fa, .bh-navbar .navbar-menus li.active a .fa, .bh-navbar .navbar-menus li.active a:hover .fa, .bh-navbar .navbar-menus li.active a:focus .fa {
  color: var(--primary-color3);
}
.bh-navbar .navbar-modules li.dropdown .dropdown-menu .version-info,
.bh-navbar .navbar-menus li.dropdown .dropdown-menu .version-info {
  font-size: 0.75rem;
  padding: 0 5px;
  text-align: center;
  color: var(--grayscale-color) !important;
}
.bh-navbar .navbar-modules li.dropdown .dropdown-menu .version-info span,
.bh-navbar .navbar-menus li.dropdown .dropdown-menu .version-info span {
  display: block;
}
.bh-navbar .navbar-modules li.dropdown .dropdown-menu .version-info .btn-link,
.bh-navbar .navbar-menus li.dropdown .dropdown-menu .version-info .btn-link {
  font-weight: bold;
  border: 0;
}
.bh-navbar .navbar-menus li.dropdown:last-of-type a .fa {
  padding-right: 3px;
}
.bh-navbar .navbar-menus li.dropdown .dropdown-menu {
  left: auto;
  right: 0;
}
.bh-navbar .dropdown-menu {
  margin: 5px 0 0;
  transform: translateY(-5px);
  top: -9999px;
  opacity: 0;
  display: block;
}
.bh-navbar .dropdown-menu.show {
  opacity: 1;
  transform: translateY(0px);
  top: 100%;
}

@media (max-width: 1199px) {
  .bh-navbar .navbar-logo {
    width: 59px;
  }
  .bh-navbar .navbar-logo img {
    width: 30px;
  }
}
/* Search Container*/
.search-container {
  display: block;
  opacity: 0;
  position: fixed;
  top: -9999px;
  right: 0;
  left: unset;
  width: 740px;
  height: auto;
  min-height: calc(50vh - 70px);
  max-height: calc(100vh - 70px);
  overflow-x: hidden;
  z-index: 2000;
  padding: 20px;
  background-color: white;
  border: 1px solid var(--primary-color);
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  background-clip: padding-box;
  transition: opacity 0.2s ease-in, transform 0.2s ease-in, width 0.4s;
  transform: translateY(-5px);
}
.search-container.opened {
  opacity: 1;
  transform: translateY(0px);
  top: 45px;
}
.search-container.full {
  width: calc(100vw - 255px);
}
.search-container .dropdown-menu .checkbox-radio, .search-container .dropdown-menu .checkbox-radio-no-margin {
  margin: 5px 10px 7px;
}
.search-container .nav-tabs {
  border-bottom: unset !important;
}
.search-container .nav-tabs .nav-item .nav-link {
  border: 1px solid var(--grayscale-light-color);
  border-top-left-radius: unset;
  border-top-right-radius: unset;
}
.search-container .nav-tabs li a {
  min-width: unset;
}
.search-container .nav-tabs a {
  margin-right: 0;
  text-decoration: unset;
}
.search-container .nav-tabs .active a {
  background-color: var(--primary-color);
  color: var(--white-color);
}

@media (max-width: 991px) {
  .search-container {
    width: calc(100vw - 60px);
  }
  .search-container.opened {
    top: 89px;
  }
  .search-container .min-max-btn {
    display: none;
  }
}
.dropdown-toggle::after {
  display: none;
}

.dropdown-menu {
  text-align: left;
  margin-top: 0;
}
.dropdown-menu .btn {
  color: var(--primary-color);
  font-weight: bold;
  background: none;
  width: -webkit-fill-available;
  text-align: left;
  padding: 6px 15px;
  box-shadow: none;
}
.dropdown-menu .btn .dropdown-button-styles {
  padding: 6px 15px;
}
.dropdown-menu .btn:hover, .dropdown-menu .btn:focus {
  background-color: var(--grayscale-white-color);
}
.dropdown-menu .btn.active {
  background-color: var(--primary-color);
  color: white;
}

#sidebar-left-panel ul.panel-list li {
  width: 100%;
}

/* Old Colors

$color0: #ededed; // white gray
$color1: rgb(190,190,190); // #bebebe - light gray
$color2: rgb(80,80,80); // #505050 - gray
$color3: rgb(207,214,224); // #cfd6e0 - white blue
$color4: rgb(159,172,193); // #9facc1 - light blue
$color5: rgb(88,111,146); // #586f92 - mid blue
$color6: rgb(15,48,99); // #1B3D6D - dark blue
$color7: rgb(57,213,31); // #39d51f - green
$color8: rgb(52,197,26); // #34C51A - dark green
*/
/*Theming*/
:root {
  --loading-login-screen-background:
          linear-gradient(to right, rgba(15, 48, 99, 0) 0%, rgba(75, 107, 155, .4) 25%, rgba(168, 187, 215, .4) 42%, rgba(168, 187, 215, .4) 58%, rgba(75, 107, 155, .4) 75%, rgba(15, 48, 99, 0) 100%),
          url(".././assets/images/pattern-block-lines.png") repeat;
  --loading-screen-logo: url(".././assets/images/bakerhill-nextgen-logo-white.png");
  --login-box-logo: url(".././assets/images/bakerhill-nextgen-logo.png");
  --primary-dark-color: #1B3D6D;
  --primary-color: #456aa1;
  --primary-color-darken-7percent: #3a5a88;
  --primary-color-darken-11percent: #34507a;
  --primary-color-lighten-10percent: #5f84ba;
  --primary-color-lighten-20percent: #829fca;
  --primary-color-lighten-40percent: #cad6e8;
  --primary-light1-color: #a6bad9;
  --primary-light1-color-darken-5percent:#94acd1;
  --primary-light1-color-darken-10percent: #829eca;
  --primary-light1-color-darken-15percent: #7090c2;
  --primary-light1-color-lighten-6percent: #bbcbe2;
  --primary-white-color: #e6ecf2;
  --primary-white-color-darken-2percent: #dfe7ef;
  --primary-white-color-darken-6percent: #d2dde8;
  --primary-white-color-lighten-2percent: #edf1f5;
  --primary-white-color-lighten-4percent: #f3f6f9;
  --primary-color2: #cddc29;
  --primary-color3: #81BD41;
  --primary-color3-lighten-40percent: #e5f2d8;
  --primary-color3-darken-5percent: #74aa3a;
  --primary-dark2-color: #689834;
  --primary-dark2-color-darken-10percent: #4e7227;
  --primary-light2-color: #e6f2d9;
  --primary-light2-color-darken-2percent: #e1efd1;
  --primary-light2-color-darken-6percent: #d7eac2;
  --primary-light2-color-darken-10percent: #cde5b3;
  --grayscale-color: #58595B;
  --grayscale-color-lighten-25percent: #98999b;
  --grayscale-light-color: #bdbdc2;
  --grayscale-light-color-darken-8percent: #a8a8ae;
  --grayscale-light-color-lighten-7percent: #d0d0d3;
  --grayscale-white-color: #ebebed;
  --grayscale-white-color-darken-5percent: #dedee1;
  --grayscale-white-color-darken-8percent: #d6d6da;
  --grayscale-white-color-lighten-2percent: #f0f0f2;
  --grayscale-white-color-lighten-5percent: #f8f8f9;
  --white-color: #ffffff;
  --black-color: #000000;
  --secondary-dark-color: #007299;
  --secondary-color: #77256c;
  --secondary-color2: #f68d29;
  --secondary-color3: #ffd240;
  --secondary-color3-darken-3percent: #ffce31;
  --secondary-color3-lighten-17percent: #ffe697;
  --secondary-color3-lighten-27percent: #fff2ca;
  --secondary-bright-color: #ece815;
  --error-color: #cc0000;
  --error-color-darken-10percent: #990000;
  --error-color-lighten-50percent: #ffcccc;
  --error-color-lighten-55percent: #ffe6e6;
  --error-light-color: #ffe6e6;
  --error-light-color-darken-5percent: #ffcdcd;
  --disabled-color: #abbcd5;
  --disabled-color2: #9badca;
  --disabled-color3: #a7d17a;
  --default-sprite-position: top left;
  --active-hover-sprite-position: top right;
  /*remaing colors*/
  --snow-gray: #fcfcfc;
  --light-gainsboro-gray: #f7f7f7;
  --white-smoke: #f4f4f4;
  --very-light-gray: #f0f0f0;
  --light-pink: #febbbb;
  --light-yellow: #ffffe5;
  --orange-peel: #ff9900;
  --electric-yellow: #ffff00;
  --yellow-green: #effadb;
  --whisper-gray: #ededed;
  --dark-whisper-gray: #eeeeee;
  --white-dark-smoke: #e6e6e6;
  --gainsboro-gray: #e0e0e0;
  --medium-gray: #cccccc;
  --silver: #c6c6c6;
  --silver-very-light: #b9b9b9;
  --silver-light: #bbbbbb;
  --blue-smoke: #eef1f7;
  --ivory-red: #f8f8f2;
  --spun-pearl: #afafb6;
  --very-light-blue: #e6ebf5;
  --dark-gainsboro-gray: #d9d9d9;
  --bright-gainsboro-gray: #dddddd;
  --hawakes-blue: #ccd6eb;
  --blue-violet: #9933ff;
  --nobel-gray: #999999;
  --light-danube-blue: #89a4cd;
  --matte-gray: #888888;
  --light-matte-gray: #787878;
  --dark-gray: #777777;
  --raven-gray: #707275;
  --danube-blue: #6685c2;
  --dim-gray: #666666;
  --davy-grey: #555555;
  --analog-blue: #435f8b;
  --charcoal-gray: #444444;
  --mariner-blue: #335cad;
  --dark-charcoal-gray: #333333;
  --green-waterloo: #23241f;
  --nero-dark: #222222;
  --smalt-blue: #003399;
  --deep-green: #008a00;
  --navy-blue: #0066cc;
  --bondi-blue: #0085c7;
  --violation-text: #cc0000;
  --violation-bg: #ffe6e6;
  --warning-text: #f68d29;
  --warning-bg: #fff2ca;
  --highcharts-color-2: #cddc29;
  --highcharts-color-3: #007299;
  --highcharts-color-6: #689834;
  --highcharts-color-7: #a6bad9;
}

[data-theme=fiserv] {
  --loading-login-screen-background: #666666;
  --loading-screen-logo: url(".././assets/images/Fiserv-logo-1200x603.png");
  --login-box-logo: url(".././assets/images/Fiserv-logo-1200x603.png");
  --primary-dark-color: #333333;
  --primary-color: #666666;
  --primary-color-darken-7percent: #545454;
  --primary-color-darken-11percent: #4a4a4a;
  --primary-color-lighten-10percent: gray;
  --primary-color-lighten-20percent: #999999;
  --primary-color-lighten-40percent: #cccccc;
  --primary-light1-color: #bfbfbf;
  --primary-light1-color-darken-5percent: #b2b2b2;
  --primary-light1-color-darken-10percent: #a6a6a6;
  --primary-light1-color-darken-15percent: #999999;
  --primary-light1-color-lighten-6percent: #cecece;
  --primary-white-color: #efefef;
  --primary-white-color-darken-2percent: #eaeaea;
  --primary-white-color-darken-6percent: #e0e0e0;
  --primary-white-color-lighten-2percent: #f4f4f4;
  --primary-white-color-lighten-4percent: #f9f9f9;
  --primary-color2: #4695d1;
  --primary-color3: #ff6600;
  --primary-color3-lighten-40percent: #ffe0cc;
  --primary-color3-darken-5percent: #e65c00;
  --primary-dark2-color: #ea6b15;
  --primary-dark2-color-darken-10percent: #bb5611;
  --primary-light2-color: #f2d3c4;
  --primary-light2-color-darken-2percent: #f0cdbc;
  --primary-light2-color-darken-6percent: #ecc0ab;
  --primary-light2-color-darken-10percent: #e9b49a;
  --grayscale-color: #666666;
  --grayscale-color-lighten-25percent: #a6a6a6;
  --grayscale-light-color: #e0e0e0;
  --grayscale-light-color-darken-8percent: #cccccc;
  --grayscale-light-color-lighten-7percent: #f2f2f2;
  --grayscale-white-color: #e0e0e0;
  --grayscale-white-color-darken-5percent: lightgray;
  --grayscale-white-color-darken-8percent: #cccccc;
  --grayscale-white-color-lighten-2percent: #e5e5e5;
  --grayscale-white-color-lighten-5percent: #ededed;
  --white-color: #ffffff;
  --black-color: #0e0e0e;
  --secondary-dark-color: #007299;
  --secondary-color: #77256c;
  --secondary-color2: #81bd41;
  --secondary-color3: #ffd240;
  --secondary-color3-darken-3percent: #ffce31;
  --secondary-color3-lighten-17percent: #ffe697;
  --secondary-color3-lighten-27percent: #fff2ca;
  --secondary-bright-color: #ece815;
  --error-color: #dd3435;
  --error-color-darken-10percent: #be2021;
  --error-color-lighten-50percent: white;
  --error-color-lighten-55percent: white;
  --error-light-color: #eabec0;
  --error-light-color-darken-5percent: #e4abad;
  --disabled-color: #c9c9c9;
  --disabled-color2: #c9c9c9;
  --disabled-color3: #dd9e73;
  --default-sprite-position: bottom left;
  --active-hover-sprite-position: bottom right;
  /*remaing colors*/
  --snow-gray: #fcfcfc;
  --light-gainsboro-gray: #f7f7f7;
  --white-smoke: #f4f4f4;
  --very-light-gray: #f0f0f0;
  --light-pink: #febbbb;
  --light-yellow: #ffffe5;
  --orange-peel: #ff9900;
  --electric-yellow: #ffff00;
  --yellow-green: #effadb;
  --whisper-gray: #ededed;
  --dark-whisper-gray: #eeeeee;
  --white-dark-smoke: #e6e6e6;
  --gainsboro-gray: #e0e0e0;
  --medium-gray: #cccccc;
  --silver: #c6c6c6;
  --silver-very-light: #b9b9b9;
  --silver-light: #bbbbbb;
  --blue-smoke: #eef1f7;
  --ivory-red: #f8f8f2;
  --spun-pearl: #afafb6;
  --very-light-blue: #e6ebf5;
  --dark-gainsboro-gray: #d9d9d9;
  --bright-gainsboro-gray: #dddddd;
  --hawakes-blue: #ccd6eb;
  --blue-violet: #9933ff;
  --nobel-gray: #999999;
  --light-danube-blue: #89a4cd;
  --matte-gray: #888888;
  --light-matte-gray: #787878;
  --dark-gray: #777777;
  --raven-gray: #707275;
  --danube-blue: #6685c2;
  --dim-gray: #666666;
  --davy-grey: #555555;
  --analog-blue: #435f8b;
  --charcoal-gray: #444444;
  --mariner-blue: #335cad;
  --dark-charcoal-gray: #333333;
  --green-waterloo: #23241f;
  --nero-dark: #222222;
  --smalt-blue: #003399;
  --deep-green: #008a00;
  --navy-blue: #0066cc;
  --bondi-blue: #0085c7;
  --violation-text: #cc0000;
  --violation-bg: #ffe6e6;
  --warning-text: #f68d29;
  --warning-bg: #fff2ca;
  --highcharts-color-2: #ffa366;
  --highcharts-color-3: #777777;
  --highcharts-color-6: #e65c00;
  --highcharts-color-7: #e0e0e0;
}

/*Sprite Positions*/
/*Images*/
/*Primary*/
/*Grayscale*/
/*Secondary*/
/*disabled*/
/*remaing colors*/
.darkblue {
  color: var(--primary-dark-color);
}

.blue {
  color: var(--primary-color);
}

.lightblue {
  color: var(--primary-light1-color);
}

.whiteblue {
  color: var(--primary-white-color);
}

.yellowgreen {
  color: var(--primary-color2);
}

.green {
  color: var(--primary-color3) !important;
}

.darkgreen {
  color: var(--primary-dark2-color);
}

.lightgreen {
  color: var(--primary-light2-color);
}

.gray {
  color: var(--grayscale-color);
}

.lightgray {
  color: var(--grayscale-light-color);
}

.whitegray {
  color: var(--grayscale-white-color);
}

.darkcyan {
  color: var(--secondary-dark-color);
}

.purple {
  color: var(--secondary-color);
}

.orange {
  color: var(--secondary-color2) !important;
}

.yellow {
  color: var(--secondary-color3);
}

.brightyellow {
  color: var(--secondary-bright-color);
}

.red {
  color: var(--error-color) !important;
}

.lightred {
  color: var(--error-light-color);
}

.white {
  color: var(--white-color) !important;
}

.darkblue-bg {
  background-color: var(--primary-dark-color);
}

.blue-bg {
  background-color: var(--primary-color) !important;
}

.lightblue-bg {
  background-color: var(--primary-light1-color);
}

.whiteblue-bg {
  background-color: var(--primary-white-color);
}

.yellowgreen-bg {
  background-color: var(--primary-color2);
}

.green-bg {
  background-color: var(--primary-color3);
}

.darkgreen-bg {
  background-color: var(--primary-dark2-color);
}

.lightgreen-bg {
  background-color: var(--primary-light2-color);
}

.gray-bg {
  background-color: var(--grayscale-color);
}

.lightgray-bg {
  background-color: var(--grayscale-light-color);
}

.whitegray-bg {
  background-color: var(--grayscale-white-color);
}

.darkcyan-bg {
  background-color: var(--secondary-dark-color);
}

.purple-bg {
  background-color: var(--secondary-color);
}

.orange-bg {
  background-color: var(--secondary-color2) !important;
}

.yellow-bg {
  background-color: var(--secondary-color3) !important;
}

.brightyellow-bg {
  background-color: var(--secondary-bright-color) !important;
}

.red-bg {
  background-color: var(--error-color) !important;
}

.lightred-bg {
  background-color: var(--error-light-color) !important;
}

body {
  position: relative;
}

#sidebar-wrapper {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  min-height: calc(100vh - 60px);
  position: relative;
  top: 46px;
}
#sidebar-wrapper a:focus {
  outline: none;
}

/* Container for toggling minimized */
#sidebar-container {
  padding-top: 25px;
}

/* SIDEBAR LEFT PANEL */
#sidebar-left-panel {
  background: var(--primary-dark-color) none repeat scroll 0 0;
  color: white;
  width: 240px;
  box-shadow: 1px 0px 3px rgba(0, 0, 0, 0.12), 1px 0px 2px rgba(0, 0, 0, 0.24);
  height: calc(100vh - 46px);
  overflow-y: auto;
  position: fixed;
  top: 46px;
  z-index: 777;
}
#sidebar-left-panel hr {
  border-top: 2px solid var(--primary-light1-color);
}
#sidebar-left-panel ul {
  margin: 0;
  padding: 0;
}
#sidebar-left-panel a {
  cursor: pointer;
}
#sidebar-left-panel li.menu-header {
  background-color: var(--primary-dark-color);
  padding: 5px 0;
  display: table;
  white-space: nowrap;
  position: relative;
}
#sidebar-left-panel li.menu-header span {
  font-size: 0.875rem;
  background-color: var(--primary-dark-color);
  padding: 0 6px;
}
#sidebar-left-panel li.menu-header:before, #sidebar-left-panel li.menu-header:after {
  border-top: 2px solid var(--primary-light1-color);
  content: "";
  display: table-cell;
  position: relative;
  top: 9px;
}
#sidebar-left-panel li.menu-header:before {
  width: 7%;
}
#sidebar-left-panel li.menu-header:after {
  width: 93%;
}
#sidebar-left-panel li.menu-header button {
  position: absolute;
  right: 8px;
  top: 8px;
  z-index: 1;
  background-color: var(--primary-dark-color);
  padding: 0 8px;
  border: none;
}
#sidebar-left-panel li.menu-header button:hover, #sidebar-left-panel li.menu-header button:focus {
  color: var(--primary-color3);
}
#sidebar-left-panel .sidebar-sub-menu li.menu-header, #sidebar-left-panel .sidebar-sub-menu li.menu-header span, #sidebar-left-panel .sidebar-sub-menu li.menu-header button {
  background-color: var(--primary-color);
}
#sidebar-left-panel .menu-header i {
  display: inline-block;
  font-size: 1.75rem;
  margin-right: 15px;
  width: 30px;
  text-align: center;
  vertical-align: bottom;
}
#sidebar-left-panel .menu-header:first-child {
  border-top: 0 solid var(--primary-color);
}
#sidebar-left-panel .sidebar-sub-menu li.menu-nonlink {
  background-color: var(--primary-color);
  padding: 5px 25px;
  font-size: 0.875rem;
}
#sidebar-left-panel .panel-list {
  margin-bottom: 20px;
}
#sidebar-left-panel .panel-list ul {
  background: var(--primary-color);
  box-shadow: -1px 2px 3px 0px rgba(0, 0, 0, 0.2);
}
#sidebar-left-panel .panel-list ul.sidebar-sub-menu li > a {
  background: var(--primary-color);
  border-top: none;
  color: white;
  display: block;
  font-size: 0.875rem;
  font-weight: 500;
  padding: 7px 10px !important;
  position: relative;
}
#sidebar-left-panel .panel-list > li > a {
  background: var(--primary-dark-color);
  border-top: none;
  border-right: 1px solid var(--primary-color);
  color: white;
  display: block;
  font-size: 14px;
  font-weight: 500;
  padding: 12px 13px;
  position: relative;
}
#sidebar-left-panel .panel-list > li > a.disabled {
  opacity: 0.65;
  cursor: not-allowed;
}
#sidebar-left-panel .panel-list > li > a:hover, #sidebar-left-panel .panel-list > li > a:focus, #sidebar-left-panel .panel-list > li > a:active {
  color: white;
  background: var(--primary-color);
}
#sidebar-left-panel .panel-list > li > a > i {
  position: relative;
  display: inline-block;
  font-size: 26px;
  line-height: 24px;
  margin-right: 6px;
  width: 30px;
  text-align: center;
  vertical-align: bottom;
  transition: all 0.3s ease-in-out;
}
#sidebar-left-panel .panel-list > li > a > i span.overlay-icon {
  position: absolute;
  top: 12px;
  right: -1px;
  font-size: 18px;
  -webkit-text-fill-color: var(--white-color);
  text-shadow: -2px -2px 0 var(--primary-dark-color), 2px -2px 0 var(--primary-dark-color), -2px 2px 0 var(--primary-dark-color), 2px 2px 0 var(--primary-dark-color);
}
#sidebar-left-panel .panel-list > li > a:hover i:not(.fa-plus), #sidebar-left-panel .panel-list > li > a:focus i:not(.fa-plus) {
  font-size: 30px;
  margin-right: 10px;
}
#sidebar-left-panel .panel-list > li > a:hover i:not(.fa-plus) span.overlay-icon, #sidebar-left-panel .panel-list > li > a:focus i:not(.fa-plus) span.overlay-icon {
  -webkit-text-fill-color: var(--white-color);
  text-shadow: -2px -2px 0 var(--primary-color), 2px -2px 0 var(--primary-color), -2px 2px 0 var(--primary-color), 2px 2px 0 var(--primary-color);
}
#sidebar-left-panel .panel-list > li > a span.menu-text {
  display: inline-block;
}
#sidebar-left-panel .panel-list > li > a span.menu-text.top-level {
  display: inline-block;
  font-size: 0.9625rem;
  position: relative;
  top: 1px;
}
#sidebar-left-panel .panel-list > li > a .label {
  border-radius: 0.25em;
  color: white;
  display: none;
  font-size: 0.75rem;
  line-height: 1;
  padding: 4px 5px;
  position: absolute;
  right: 10px;
  top: 14px;
  text-align: center;
  white-space: nowrap;
  background-color: var(--primary-color3);
  transition-duration: 0.2s;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.08), 0 1px 1px rgba(0, 0, 0, 0.16);
}
#sidebar-left-panel .panel-list > li > a .label .fa {
  padding-right: 4px;
}
#sidebar-left-panel .panel-list > li > a button.label {
  border: none;
}
#sidebar-left-panel .panel-list > li > a .label:focus, #sidebar-left-panel .panel-list > li > a .label:hover {
  color: white;
  background-color: var(--primary-dark2-color);
}
#sidebar-left-panel .panel-list > li > a:focus .label, #sidebar-left-panel .panel-list > li > a:hover .label {
  display: inline;
}
#sidebar-left-panel .panel-list > li.openfeature a .label {
  display: inline;
}
#sidebar-left-panel .panel-list > li.mod-home > a {
  border-bottom: 1px solid var(--primary-light1-color);
  padding: 8px 17px;
  cursor: default;
}
#sidebar-left-panel .panel-list > li.mod-home > a span.menu-text.top-level {
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  /*font-family: 'Open Sans', sans-serif;*/
}
#sidebar-left-panel .panel-list > li.mod-home > a span.menu-text.top-level span {
  right: 1px;
  position: relative;
}
#sidebar-left-panel .panel-list > li.mod-home > a:hover, #sidebar-left-panel .panel-list > li.mod-home > a:focus {
  background: var(--primary-dark-color);
}
#sidebar-left-panel .panel-list > li:last-child > a {
  border-bottom: none;
}
#sidebar-left-panel .panel-list > li:first-child > a {
  border-top: none;
}
#sidebar-left-panel .panel-list > li.active > a {
  background: var(--primary-color);
  border-top: 1px solid var(--primary-color);
  color: white;
}
#sidebar-left-panel .panel-list > li.sidebar-has-menu.openfeature > a {
  background: var(--primary-color);
  color: white;
  /*border-bottom: 1px solid $lightblue;*/
}
#sidebar-left-panel .panel-list > li.sidebar-has-menu.openfeature > a span.overlay-icon {
  background-color: var(--primary-color);
}
#sidebar-left-panel .panel-list > li.sidebar-has-menu li.sidebar-has-menu > a:before {
  font-family: "FontAwesome", sans-serif;
  content: "\f054";
  font-size: 0.75rem;
  position: relative;
  float: left;
  left: 5px;
  top: 2px;
}
#sidebar-left-panel .panel-list > li.sidebar-has-menu li.sidebar-has-menu.openfeature > a:before {
  font-family: "FontAwesome", sans-serif;
  content: "\f078";
  font-size: 0.75rem;
  position: relative;
  float: left;
  left: 3px;
}
#sidebar-left-panel .panel-list > li.sidebar-has-menu > ul.sidebar-sub-menu {
  display: none;
}
#sidebar-left-panel .panel-list > li.sidebar-has-menu > ul.sidebar-sub-menu li.has-divider {
  border-bottom: 1px solid var(--primary-light1-color);
}
#sidebar-left-panel .panel-list > li.sidebar-has-menu > ul.sidebar-sub-menu li > a:hover, #sidebar-left-panel .panel-list > li.sidebar-has-menu > ul.sidebar-sub-menu li > a:focus {
  text-decoration: none;
  color: white;
  background: var(--primary-light1-color-darken-15percent);
}
#sidebar-left-panel .panel-list > li.sidebar-has-menu > ul.sidebar-sub-menu li > ul.sidebar-sub-menu li > a {
  color: white;
  background: var(--primary-color-darken-11percent);
  padding: 5px 10px;
}
#sidebar-left-panel .panel-list > li.sidebar-has-menu > ul.sidebar-sub-menu li > ul.sidebar-sub-menu li > a:hover, #sidebar-left-panel .panel-list > li.sidebar-has-menu > ul.sidebar-sub-menu li > ul.sidebar-sub-menu li > a:focus {
  background: var(--primary-light1-color-darken-15percent);
}
#sidebar-left-panel .panel-list > li.sidebar-has-menu > ul ul {
  display: none;
}
#sidebar-left-panel .panel-list > li.sidebar-has-menu.openfeature > ul, #sidebar-left-panel .panel-list > li.sidebar-has-menu.openfeature > ul > li.sidebar-has-menu.openfeature > ul, #sidebar-left-panel .panel-list > li.sidebar-has-menu.openfeature > ul > li.sidebar-has-menu.openfeature > ul > li.sidebar-has-menu.openfeature > ul, #sidebar-left-panel .panel-list > li.sidebar-has-menu.active > ul, #sidebar-left-panel .panel-list > li.sidebar-has-menu.active > ul > li.sidebar-has-menu.active > ul, #sidebar-left-panel .panel-list > li.sidebar-has-menu.active > ul > li.sidebar-has-menu.active > ul > li.sidebar-has-menu.active > ul {
  display: block;
}
#sidebar-left-panel .panel-list > li.sidebar-has-menu > ul > li > a span.menu-text {
  padding-left: 15px;
  position: relative;
  display: inline-block;
}
#sidebar-left-panel .panel-list > li.sidebar-has-menu li.sidebar-has-menu.openfeature > a span.menu-text {
  padding-left: 12px;
}
#sidebar-left-panel .panel-list > li.sidebar-has-menu li.sidebar-has-menu > ul.sidebar-sub-menu {
  list-style: outside none none;
}
#sidebar-left-panel .panel-list > li.sidebar-has-menu > ul > li.sidebar-has-menu > ul > li > a span.menu-text, #sidebar-left-panel .panel-list > li.sidebar-has-menu > ul > li.sb-indent > a span.menu-text {
  padding-left: 25px;
  position: relative;
  display: inline-block;
}
#sidebar-left-panel .panel-list > li.sidebar-has-menu > ul > li.sidebar-has-menu > ul > li > ul > li > a span.menu-text {
  padding-left: 35px;
  position: relative;
  display: inline-block;
}
#sidebar-left-panel .panel-list > li.sidebar-has-menu > ul > li.sidebar-has-menu > ul > li > ul > li > ul > li > a span.menu-text {
  padding-left: 40px;
  position: relative;
  display: inline-block;
}
#sidebar-left-panel .panel-list > li.sidebar-has-menu > ul.sidebar-sub-menu {
  list-style: none;
}
#sidebar-left-panel .panel-list > li.sidebar-has-menu > ul.sidebar-sub-menu > li:nth-of-type(3) > a span.menu-text.level-down {
  margin-left: 16px;
}
#sidebar-left-panel .panel-list > li.sidebar-has-menu > ul.sidebar-sub-menu > li:nth-of-type(4) > a span.menu-text.level-down {
  margin-left: 19px;
}
#sidebar-left-panel .panel-list > li.sidebar-has-menu > ul.sidebar-sub-menu > li:nth-of-type(5) > a span.menu-text.level-down {
  margin-left: 22px;
}
#sidebar-left-panel .panel-list > li.sidebar-has-menu > ul.sidebar-sub-menu > li:nth-of-type(6) > a span.menu-text.level-down {
  margin-left: 25px;
}
#sidebar-left-panel .panel-list > li.sidebar-has-menu > ul.sidebar-sub-menu > li:nth-of-type(7) > a span.menu-text.level-down {
  margin-left: 28px;
}
#sidebar-left-panel .panel-list > li.sidebar-has-menu > ul.sidebar-sub-menu > li:nth-of-type(1n+8) > a span.menu-text.level-down {
  margin-left: 31px;
}
#sidebar-left-panel .panel-list > li.sidebar-has-menu > ul.sidebar-sub-menu > li > a span.menu-text.level-down:before {
  content: "\f148";
  transform: rotate(90deg);
  margin-left: -10px;
}
#sidebar-left-panel .panel-list > li.sidebar-has-menu > ul.sidebar-sub-menu > li > a span.menu-text:before {
  font-family: "FontAwesome", sans-serif;
  content: "";
  font-size: 0.9625rem;
  position: relative;
  float: left;
  left: -10px;
  top: -2px;
}
#sidebar-left-panel .panel-list > li.sidebar-has-menu > ul.sidebar-sub-menu > li > a span.menu-text.plus:before {
  content: "\f055";
}
#sidebar-left-panel .panel-list > li.sidebar-has-menu > ul.sidebar-sub-menu > li > a span.menu-text.home:before {
  content: "\f015";
}
#sidebar-left-panel .panel-list > li.sidebar-has-menu > ul.sidebar-sub-menu > li > a span.menu-text.info:before {
  content: "\f05a";
}
#sidebar-left-panel .panel-list > li.sidebar-has-menu > ul.sidebar-sub-menu > li.active a .selected {
  display: inline-block;
  height: 0;
  position: absolute;
  right: 0;
  top: 5px;
  vertical-align: middle;
  width: 0;
}
#sidebar-left-panel .panel-list > li.sidebar-has-menu > ul.sidebar-sub-menu > li.active a {
  background-color: var(--primary-light1-color-darken-15percent);
}
#sidebar-left-panel .panel-list > li.sidebar-has-menu > a .selected {
  display: none;
}
#sidebar-left-panel .panel-list li.active > a .selected {
  border-bottom: 10px solid transparent;
  border-right: 8px solid white;
  border-top: 10px solid transparent;
  display: inline-block;
  height: 0;
  position: absolute;
  right: -1px;
  top: 20px;
  vertical-align: middle;
  width: 0;
}

#main-content {
  padding-left: 240px;
  position: relative;
}

span.menu-text + span.menu-text {
  display: block;
}

@media (max-width: 992px) {
  #sidebar-left-panel {
    min-height: 100%;
    height: auto;
    overflow-y: visible;
  }
}
/* Minimized Panel - Tablet/Phone */
.sidebar-minimized #main-content {
  padding-left: 60px;
}

.sidebar-minimized #sidebar-left-panel {
  width: 60px;
  background: var(--primary-dark-color);
  color: white;
  z-index: 999;
}
.sidebar-minimized #sidebar-left-panel .panel-list > li > a span.menu-text {
  display: none;
}
.sidebar-minimized #sidebar-left-panel .panel-list > li > a > i {
  display: inline-block;
  font-size: 1.75rem;
  width: 30px;
  text-align: center;
}
.sidebar-minimized #sidebar-left-panel .panel-list > li:hover > a:not(.closed), .sidebar-minimized #sidebar-left-panel .panel-list > li:focus > a:not(.closed), .sidebar-minimized #sidebar-left-panel .panel-list > li:active > a:not(.closed) {
  display: block;
  position: relative;
  width: 300px;
  margin-bottom: -1px;
  border-radius: 0 4px 4px 0;
}
.sidebar-minimized #sidebar-left-panel .panel-list > li:hover > a:not(.closed) span.menu-text, .sidebar-minimized #sidebar-left-panel .panel-list > li:focus > a:not(.closed) span.menu-text, .sidebar-minimized #sidebar-left-panel .panel-list > li:active > a:not(.closed) span.menu-text {
  display: inline-block;
  position: absolute;
  left: 76px;
  top: 14px;
}
.sidebar-minimized #sidebar-left-panel .panel-list > li.sidebar-has-menu > a:after {
  display: none;
}
.sidebar-minimized #sidebar-left-panel .panel-list > li.sidebar-has-menu:hover > a, .sidebar-minimized #sidebar-left-panel .panel-list > li.sidebar-has-menu:focus > a, .sidebar-minimized #sidebar-left-panel .panel-list > li.sidebar-has-menu:active > a {
  border-radius: 0 4px 0 0;
  background: var(--primary-color);
}
.sidebar-minimized #sidebar-left-panel .panel-list > li.sidebar-has-menu:hover > ul.sidebar-sub-menu:not(.closed), .sidebar-minimized #sidebar-left-panel .panel-list > li.sidebar-has-menu:hover > ul.sidebar-sub-menu:not(.closed), .sidebar-minimized #sidebar-left-panel .panel-list > li.sidebar-has-menu:focus > ul.sidebar-sub-menu:not(.closed), .sidebar-minimized #sidebar-left-panel .panel-list > li.sidebar-has-menu:focus > ul.sidebar-sub-menu:not(.closed), .sidebar-minimized #sidebar-left-panel .panel-list > li.sidebar-has-menu:active > ul.sidebar-sub-menu:not(.closed), .sidebar-minimized #sidebar-left-panel .panel-list > li.sidebar-has-menu:active > ul.sidebar-sub-menu:not(.closed) {
  display: block !important;
  border-right: 1px solid var(--primary-color);
  width: 240px;
  left: 60px;
  overflow-y: auto;
  max-height: 50vh;
  overflow-x: hidden;
}
.sidebar-minimized #sidebar-left-panel .panel-list > li.sidebar-has-menu:hover > ul.sidebar-sub-menu:not(.closed) ul.sidebar-sub-menu, .sidebar-minimized #sidebar-left-panel .panel-list > li.sidebar-has-menu:hover > ul.sidebar-sub-menu:not(.closed) ul.sidebar-sub-menu, .sidebar-minimized #sidebar-left-panel .panel-list > li.sidebar-has-menu:focus > ul.sidebar-sub-menu:not(.closed) ul.sidebar-sub-menu, .sidebar-minimized #sidebar-left-panel .panel-list > li.sidebar-has-menu:focus > ul.sidebar-sub-menu:not(.closed) ul.sidebar-sub-menu, .sidebar-minimized #sidebar-left-panel .panel-list > li.sidebar-has-menu:active > ul.sidebar-sub-menu:not(.closed) ul.sidebar-sub-menu, .sidebar-minimized #sidebar-left-panel .panel-list > li.sidebar-has-menu:active > ul.sidebar-sub-menu:not(.closed) ul.sidebar-sub-menu {
  display: none !important;
  left: 0;
}
.sidebar-minimized #sidebar-left-panel .panel-list > li.sidebar-has-menu > ul.sidebar-sub-menu {
  display: none !important;
  position: absolute;
  left: 60px;
  width: 240px;
}
.sidebar-minimized #sidebar-left-panel .panel-list > li.sidebar-has-menu > ul.sidebar-sub-menu ul.sidebar-sub-menu {
  display: none !important;
  position: absolute;
  width: 240px;
  left: 0;
}
.sidebar-minimized #sidebar-left-panel .panel-list > li.sidebar-has-menu > ul.sidebar-sub-menu > li.sidebar-has-menu:hover > ul.sidebar-sub-menu, .sidebar-minimized #sidebar-left-panel .panel-list > li.sidebar-has-menu > ul.sidebar-sub-menu > li.sidebar-has-menu:focus > ul.sidebar-sub-menu {
  display: block !important;
  position: relative;
  left: 0;
}
.sidebar-minimized #sidebar-left-panel .panel-list > li.sidebar-has-menu > ul.sidebar-sub-menu > li.sidebar-has-menu > ul.sidebar-sub-menu > li.sidebar-has-menu:hover > ul.sidebar-sub-menu, .sidebar-minimized #sidebar-left-panel .panel-list > li.sidebar-has-menu > ul.sidebar-sub-menu > li.sidebar-has-menu > ul.sidebar-sub-menu > li.sidebar-has-menu:focus > ul.sidebar-sub-menu {
  display: block !important;
  position: relative;
  left: 0;
}
.sidebar-minimized #sidebar-left-panel .panel-list > li.sidebar-has-menu > ul.sidebar-sub-menu > li.active a .selected {
  right: -2px;
}
.sidebar-minimized #sidebar-left-panel .panel-list > li.sidebar-has-menu > ul.sidebar-sub-menu > li.sidebar-has-menu > ul.sidebar-sub-menu li > a span.menu-text {
  padding-left: 15px;
  position: relative;
  display: inline-block;
}
.sidebar-minimized #sidebar-left-panel .panel-list > li.sidebar-has-menu > ul.sidebar-sub-menu > li.sidebar-has-menu > ul.sidebar-sub-menu > li > a span.menu-text:before, .sidebar-minimized #sidebar-left-panel .panel-list > li.sidebar-has-menu > ul.sidebar-sub-menu > li.sidebar-has-menu > ul.sidebar-sub-menu > li.sidebar-has-menu > ul.sidebar-sub-menu > li > a span.menu-text:before {
  left: 4px;
}
.sidebar-minimized #sidebar-left-panel .panel-list > li.sidebar-has-menu li.sidebar-has-menu > a:after {
  display: none;
}
.sidebar-minimized #sidebar-left-panel .panel-list > li.sidebar-has-menu li.sidebar-has-menu > a:before {
  font-family: "FontAwesome", sans-serif;
  content: "\f054";
  font-size: 0.75rem;
  position: relative;
  float: left;
  display: block;
}
.sidebar-minimized #sidebar-left-panel .panel-list > li.sidebar-has-menu li.sidebar-has-menu:hover > a:before, .sidebar-minimized #sidebar-left-panel .panel-list > li.sidebar-has-menu li.sidebar-has-menu:focus > a:before {
  font-family: "FontAwesome", sans-serif;
  content: "\f078";
  font-size: 0.75rem;
  position: relative;
  float: left;
  display: block;
}
.sidebar-minimized #sidebar-left-panel .panel-list > li.sidebar-has-menu.active > li.active a .selected {
  display: block;
}
.sidebar-minimized #sidebar-left-panel .panel-list > li.sidebar-has-menu.openfeature > a, .sidebar-minimized #sidebar-left-panel .panel-list > li.sidebar-has-menu.openfeature > a:hover {
  background: var(--primary-color);
  color: white;
}
.sidebar-minimized #sidebar-left-panel .panel-list ul.sidebar-sub-menu li {
  list-style: none;
}
.sidebar-minimized #sidebar-left-panel .panel-list > li.active:hover > a .selected, .sidebar-minimized #sidebar-left-panel .panel-list > li.active:focus > a .selected {
  display: none;
}
.sidebar-minimized #sidebar-left-panel .panel-list .label, .sidebar-minimized #sidebar-left-panel .panel-list .openfeature .label {
  display: none !important;
}
.sidebar-minimized #sidebar-left-panel .panel-list li:hover a:not(.closed) .label {
  display: block !important;
  position: absolute;
  right: 12px;
  top: 16px;
}

/*Tablet Toggle function*/
.nav > li > a.nav-tablet {
  display: none !important;
}

@media (max-width: 992px) {
  .nav > li > a.nav-tablet {
    display: block !important;
  }
  .nav > li > a.nav-desktop {
    display: none !important;
  }
}
/* Mobile fixes */
@media screen and (max-width: 520px) {
  #sidebar-wrapper, #sidebar-left-panel {
    top: 90px;
  }
  #sidebar-left-panel .nav-tablet {
    z-index: 1000 !important;
  }
}
/* .bs-datepicker */
.bs-datepicker {
  display: flex;
  align-items: stretch;
  flex-flow: row wrap;
  background: #fff;
  box-shadow: 0 0 10px 0 #aaa;
  position: relative;
  z-index: 1;
  /* button */
  /* .bs-datepicker-head */
  /* .bs-datepicker-body */
  /* .current-timedate */
  /* .bs-datepicker-multiple */
  /* .bs-datepicker-btns */
  /*.bs-datepicker-custom-range */
  /* .bs-datepicker-predefined-btns */
  /* .bs-datepicker-buttons */
}

.bs-datepicker:after {
  clear: both;
  content: "";
  display: block;
}

.bs-datepicker {
  float: left;
}

.bs-datepicker button:hover,
.bs-datepicker button:focus,
.bs-datepicker button:active,
.bs-datepicker input:hover,
.bs-datepicker input:focus,
.bs-datepicker input:active,
.bs-datepicker-btns button:hover,
.bs-datepicker-btns button:focus,
.bs-datepicker-btns button:active,
.bs-datepicker-predefined-btns button:active,
.bs-datepicker-predefined-btns button:focus {
  outline: none;
}

.bs-datepicker-head {
  min-width: 270px;
  height: 50px;
  padding: 10px;
  border-radius: 3px 3px 0 0;
  text-align: justify;
  /* .bs-datepicker-head button */
}

.bs-datepicker-head:after {
  content: "";
  display: inline-block;
  vertical-align: top;
  width: 100%;
}

.bs-datepicker-head button {
  display: inline-block;
  vertical-align: top;
  padding: 0;
  height: 30px;
  line-height: 1.875rem;
  border: 0;
  background: transparent;
  text-align: center;
  cursor: pointer;
  color: #fff;
  transition: 0.3s;
}

.bs-datepicker-head button[disabled], .bs-datepicker-head button[disabled]:hover, .bs-datepicker-head button[disabled]:active {
  background: rgba(221, 221, 221, 0.3);
  color: #f5f5f5;
  cursor: not-allowed;
}

.bs-datepicker-head button.next, .bs-datepicker-head button.previous {
  border-radius: 50%;
  width: 30px;
  height: 30px;
}

.bs-datepicker-head button.next span, .bs-datepicker-head button.previous span {
  font-size: 1.75rem;
  line-height: 1;
  display: inline-block;
  position: relative;
  height: 100%;
  width: 100%;
  border-radius: 50%;
}

.bs-datepicker-head button.current {
  border-radius: 15px;
  max-width: 155px;
  padding: 0 13px;
}

.bs-datepicker-head button:hover {
  background-color: rgba(0, 0, 0, 0.1);
}

.bs-datepicker-head button:active {
  background-color: rgba(0, 0, 0, 0.2);
}

.bs-datepicker-body {
  padding: 10px;
  border-radius: 0 0 3px 3px;
  min-height: 232px;
  min-width: 278px;
  border: 1px solid #e9edf0;
  /* .bs-datepicker-body table */
}

.bs-datepicker-body .days.weeks {
  position: relative;
  z-index: 1;
}

.bs-datepicker-body table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
  /* .bs-datepicker-body table.days */
  /* .bs-datepicker-body table.weeks */
}

.bs-datepicker-body table th {
  font-size: 0.8125rem;
  color: #9aaec1;
  font-weight: 400;
  text-align: center;
}

.bs-datepicker-body table td {
  color: #54708b;
  text-align: center;
  position: relative;
  padding: 0;
}

.bs-datepicker-body table td span {
  display: block;
  margin: 0 auto;
  font-size: 0.8125rem;
  border-radius: 50%;
  position: relative;
  /*z-index: 1;*/
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.bs-datepicker-body table td:not(.disabled):not(.week) span:not(.disabled):not(.is-other-month) {
  cursor: pointer;
}

.bs-datepicker-body table td.is-highlighted:not(.disabled):not(.selected) span,
.bs-datepicker-body table td span.is-highlighted:not(.disabled):not(.selected) {
  background-color: #e9edf0;
  transition: 0s;
}

.bs-datepicker-body table td.is-active-other-month:not(.disabled):not(.selected) span,
.bs-datepicker-body table td span.is-active-other-month:not(.disabled):not(.selected) {
  background-color: #e9edf0;
  transition: 0s;
  cursor: pointer;
}

.bs-datepicker-body table td span.disabled,
.bs-datepicker-body table td.disabled span {
  color: #9aaec1;
}

.bs-datepicker-body table td span.selected,
.bs-datepicker-body table td.selected span {
  color: #fff;
}

.bs-datepicker-body table td span.is-other-month,
.bs-datepicker-body table td.is-other-month span {
  color: rgba(0, 0, 0, 0.25);
}

.bs-datepicker-body table td.active {
  position: relative;
}

.bs-datepicker-body table td.active.select-start:before {
  left: 35%;
}

.bs-datepicker-body table td.active.select-end:before {
  left: -85%;
}

.bs-datepicker-body table td span.active.select-start:after,
.bs-datepicker-body table td span.active.select-end:after,
.bs-datepicker-body table td.active.select-start span:after,
.bs-datepicker-body table td.active.select-end span:after {
  content: "";
  display: block;
  position: absolute;
  z-index: -1;
  width: 100%;
  height: 100%;
  transition: 0.3s;
  top: 0;
  border-radius: 50%;
}

.bs-datepicker-body table td:before,
.bs-datepicker-body table td span:before {
  content: "";
  display: block;
  position: absolute;
  z-index: -1;
  top: 6px;
  bottom: 6px;
  left: -2px;
  right: -2px;
  box-sizing: content-box;
  background: transparent;
}

.bs-datepicker-body table td.active.select-start + td.active:before {
  left: -20%;
}

.bs-datepicker-body table td:last-child.active:before {
  border-radius: 0 3px 3px 0;
  width: 125%;
  left: -25%;
}

.bs-datepicker-body table td span[class*=select-],
.bs-datepicker-body table td[class*=select-] span {
  border-radius: 50%;
  color: #fff;
}

.bs-datepicker-body table.days td.active:not(.select-start):before, .bs-datepicker-body table.days td.in-range:not(.select-start):before,
.bs-datepicker-body table.days span.active:not(.select-start):before,
.bs-datepicker-body table.days span.in-range:not(.select-start):before {
  background: #e9edf0;
}

.bs-datepicker-body table.days span {
  width: 32px;
  height: 32px;
  line-height: 2rem;
}

.bs-datepicker-body table.days span.select-start {
  z-index: 2;
}

.bs-datepicker-body table.days span.is-highlighted.in-range:before, .bs-datepicker-body table.days span.in-range.select-end:before {
  background: none;
  right: 0;
  left: 0;
}

.bs-datepicker-body table.days td.select-start + td.select-end:before,
.bs-datepicker-body table.days td.select-start + td.is-highlighted:before,
.bs-datepicker-body table.days td.active + td.is-highlighted:before,
.bs-datepicker-body table.days td.active + td.select-end:before,
.bs-datepicker-body table.days td.in-range + td.is-highlighted:before,
.bs-datepicker-body table.days td.in-range + td.select-end:before {
  background: #e9edf0;
  width: 100%;
}

.bs-datepicker-body table.weeks tr td:nth-child(2).active:before {
  border-radius: 3px 0 0 3px;
  left: 0;
  width: 100%;
}

.bs-datepicker-body table:not(.weeks) tr td:first-child:before {
  border-radius: 3px 0 0 3px;
}

.bs-datepicker-body table.years td span {
  width: 46px;
  height: 46px;
  line-height: 2.8125rem;
  margin: 0 auto;
}

.bs-datepicker-body table.years tr:not(:last-child) td span {
  margin-bottom: 8px;
}

.bs-datepicker-body table.months td {
  height: 52px;
}

.bs-datepicker-body table.months td span {
  padding: 6px;
  border-radius: 15px;
}

.bs-datepicker .current-timedate {
  color: #54708b;
  font-size: 0.9375rem;
  text-align: center;
  height: 30px;
  line-height: 1.875rem;
  border-radius: 20px;
  border: 1px solid #e9edf0;
  margin-bottom: 10px;
  cursor: pointer;
  text-transform: uppercase;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.bs-datepicker .current-timedate span:not(:empty):before {
  content: "";
  width: 15px;
  height: 16px;
  display: inline-block;
  margin-right: 4px;
  vertical-align: text-bottom;
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA8AAAAQCAYAAADJViUEAAABMklEQVQoU9VTwW3CQBCcOUgBtEBKSAukAnBKME+wFCAlYIhk8sQlxFABtJAScAsuAPBEewYcxCP8ouxrPDsza61uiVN1o6RNHD4htSCmq49RfO71BvMJqBBkITRf1kmUW49nQRC9h1I5AZlBClaL8aP1fKgOOxCx8aSLs+Q19eZuNO8QmPqJRtDFguy7OAcDbJPs+/BKVPDIPrvD2ZJgWAmVe7O0rI0Vqs1seyWUXpuJoppYCa5L+U++NpNPkr5OE2oMdARsb3gykJT5ydZcL8Z9Ww60nxg2LhjON9li9OwXZzo+xLbp3nC2s9CL2RrueGyVrgwNm8HpsCzZ9EEW6kqXlo1GQe03FzP/7W8Hl0dBtu7Bf7zt6mIwvX1RvzDCm7+q3mAW0Dl/GPdUCeXrZLT9BrDrGkm4qlPvAAAAAElFTkSuQmCC);
}

.bs-datepicker-multiple {
  border-radius: 4px 0 0 4px;
}

.bs-datepicker-multiple + .bs-datepicker-multiple {
  margin-left: 10px;
}

.bs-datepicker-multiple .bs-datepicker {
  box-shadow: none;
  position: relative;
}

.bs-datepicker-multiple .bs-datepicker:not(:last-child) {
  padding-right: 10px;
}

.bs-datepicker-multiple .bs-datepicker + .bs-datepicker:after {
  content: "";
  display: block;
  width: 14px;
  height: 10px;
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA8AAAAKCAYAAABrGwT5AAAA1ElEQVQoU42RsQrCUAxF77VuDu7O4oMWW//BURBBpZvgKk4uIrjoqKOTf+DopIO4uYggtFTfw3+pkQqCW1/G5J7kJiFy4m5MxUlxAzgIPHX+lzMPzupRYlYgxiR7vqsOP8YKzsTx0yxFMCUZ+q7aZzlr+OvgoWcAFyAHgat2jLWu48252DdqAihDJGSSJNUUxYmQjs3+hPQBlAh2rG2LCOPnaw3IiGDX99TRCs7ASJsNhUOA7d/LcuHvRG22FIZvsNXw1MX6VZExCilOQKEfeLXr/10+aC9Ho7arh7oAAAAASUVORK5CYII=);
  position: absolute;
  top: 25px;
  left: -8px;
}

.bs-datepicker-multiple .bs-datepicker .left {
  float: left;
}

.bs-datepicker-multiple .bs-datepicker .right {
  float: right;
}

.bs-datepicker-container {
  padding: 15px;
}

.bs-datepicker .bs-media-container {
  display: flex;
}

@media (max-width: 768px) {
  .bs-datepicker .bs-media-container {
    flex-direction: column;
  }
}
.bs-datepicker-custom-range {
  padding: 15px;
  background: #eee;
}

.bs-datepicker-predefined-btns button {
  width: 100%;
  display: block;
  height: 30px;
  background-color: #9aaec1;
  border-radius: 4px;
  color: #fff;
  border: 0;
  margin-bottom: 10px;
  padding: 0 18px;
  text-align: left;
  transition: 0.3s;
}

.bs-datepicker-predefined-btns button:hover {
  background-color: #54708b;
}

.bs-datepicker-buttons {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-end;
  padding-top: 10px;
  border-top: 1px solid #e9edf0;
}

.bs-datepicker-buttons .btn-default {
  margin-left: 10px;
}

/* .bs-timepicker */
.bs-timepicker-container {
  padding: 10px 0;
}

.bs-timepicker-label {
  color: #54708b;
  margin-bottom: 10px;
}

.bs-timepicker-controls {
  display: inline-block;
  vertical-align: top;
  margin-right: 10px;
}

.bs-timepicker-controls button {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 0;
  background-color: #e9edf0;
  color: #54708b;
  font-size: 1rem;
  font-weight: 700;
  vertical-align: middle;
  line-height: 0;
  padding: 0;
  transition: 0.3s;
}

.bs-timepicker-controls button:hover {
  background-color: #d5dadd;
}

.bs-timepicker-controls input {
  width: 35px;
  height: 25px;
  border-radius: 13px;
  text-align: center;
  border: 1px solid #e9edf0;
}

.bs-timepicker .switch-time-format {
  text-transform: uppercase;
  min-width: 54px;
  height: 25px;
  border-radius: 20px;
  border: 1px solid #e9edf0;
  background: #fff;
  color: #54708b;
  font-size: 0.8125rem;
}

.bs-timepicker .switch-time-format img {
  vertical-align: initial;
  margin-left: 4px;
}

bs-datepicker-container,
bs-daterangepicker-container {
  z-index: 1080;
}

/* screen size < 1024px */
@media (max-width: 768px) {
  .bs-datepicker-multiple {
    display: flex;
  }
  .bs-datepicker-multiple + .bs-datepicker-multiple {
    margin-top: 10px;
    margin-left: 0;
  }
}
/* theming */
.theme-default .bs-datepicker-head {
  background-color: #777;
}

.theme-default .bs-datepicker-predefined-btns button.selected {
  background-color: #777;
}

.theme-default .bs-datepicker-body table td span.selected,
.theme-default .bs-datepicker-body table td.selected span,
.theme-default .bs-datepicker-body table td span[class*=select-]:after,
.theme-default .bs-datepicker-body table td[class*=select-] span:after {
  background-color: #777;
}

.theme-default .bs-datepicker-body table td.week span {
  color: #777;
}

.theme-default .bs-datepicker-body table td.active-week span:hover {
  cursor: pointer;
  background-color: #777;
  color: #fff;
  opacity: 0.5;
  transition: 0s;
}

.theme-green .bs-datepicker-head {
  background-color: #5cb85c;
}

.theme-green .bs-datepicker-predefined-btns button.selected {
  background-color: #5cb85c;
}

.theme-green .bs-datepicker-body table td span.selected,
.theme-green .bs-datepicker-body table td.selected span,
.theme-green .bs-datepicker-body table td span[class*=select-]:after,
.theme-green .bs-datepicker-body table td[class*=select-] span:after {
  background-color: #5cb85c;
}

.theme-green .bs-datepicker-body table td.week span {
  color: #5cb85c;
}

.theme-green .bs-datepicker-body table td.active-week span:hover {
  cursor: pointer;
  background-color: #5cb85c;
  color: #fff;
  opacity: 0.5;
  transition: 0s;
}

.theme-blue .bs-datepicker-head {
  background-color: #5bc0de;
}

.theme-blue .bs-datepicker-predefined-btns button.selected {
  background-color: #5bc0de;
}

.theme-blue .bs-datepicker-body table td span.selected,
.theme-blue .bs-datepicker-body table td.selected span,
.theme-blue .bs-datepicker-body table td span[class*=select-]:after,
.theme-blue .bs-datepicker-body table td[class*=select-] span:after {
  background-color: #5bc0de;
}

.theme-blue .bs-datepicker-body table td.week span {
  color: #5bc0de;
}

.theme-blue .bs-datepicker-body table td.active-week span:hover {
  cursor: pointer;
  background-color: #5bc0de;
  color: #fff;
  opacity: 0.5;
  transition: 0s;
}

.theme-dark-blue .bs-datepicker-head {
  background-color: #337ab7;
}

.theme-dark-blue .bs-datepicker-predefined-btns button.selected {
  background-color: #337ab7;
}

.theme-dark-blue .bs-datepicker-body table td span.selected,
.theme-dark-blue .bs-datepicker-body table td.selected span,
.theme-dark-blue .bs-datepicker-body table td span[class*=select-]:after,
.theme-dark-blue .bs-datepicker-body table td[class*=select-] span:after {
  background-color: #337ab7;
}

.theme-dark-blue .bs-datepicker-body table td.week span {
  color: #337ab7;
}

.theme-dark-blue .bs-datepicker-body table td.active-week span:hover {
  cursor: pointer;
  background-color: #337ab7;
  color: #fff;
  opacity: 0.5;
  transition: 0s;
}

.theme-red .bs-datepicker-head {
  background-color: #d9534f;
}

.theme-red .bs-datepicker-predefined-btns button.selected {
  background-color: #d9534f;
}

.theme-red .bs-datepicker-body table td span.selected,
.theme-red .bs-datepicker-body table td.selected span,
.theme-red .bs-datepicker-body table td span[class*=select-]:after,
.theme-red .bs-datepicker-body table td[class*=select-] span:after {
  background-color: #d9534f;
}

.theme-red .bs-datepicker-body table td.week span {
  color: #d9534f;
}

.theme-red .bs-datepicker-body table td.active-week span:hover {
  cursor: pointer;
  background-color: #d9534f;
  color: #fff;
  opacity: 0.5;
  transition: 0s;
}

.theme-orange .bs-datepicker-head {
  background-color: #f0ad4e;
}

.theme-orange .bs-datepicker-predefined-btns button.selected {
  background-color: #f0ad4e;
}

.theme-orange .bs-datepicker-body table td span.selected,
.theme-orange .bs-datepicker-body table td.selected span,
.theme-orange .bs-datepicker-body table td span[class*=select-]:after,
.theme-orange .bs-datepicker-body table td[class*=select-] span:after {
  background-color: #f0ad4e;
}

.theme-orange .bs-datepicker-body table td.week span {
  color: #f0ad4e;
}

.theme-orange .bs-datepicker-body table td.active-week span:hover {
  cursor: pointer;
  background-color: #f0ad4e;
  color: #fff;
  opacity: 0.5;
  transition: 0s;
}


/* based on angular-toastr css https://github.com/Foxandxss/angular-toastr/blob/cb508fe6801d6b288d3afc525bb40fee1b101650/dist/angular-toastr.css */
/* position */
.toast-center-center {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.toast-top-center {
  top: 0;
  right: 0;
  width: 100%;
}

.toast-bottom-center {
  bottom: 0;
  right: 0;
  width: 100%;
}

.toast-top-full-width {
  top: 0;
  right: 0;
  width: 100%;
}

.toast-bottom-full-width {
  bottom: 0;
  right: 0;
  width: 100%;
}

.toast-top-left {
  top: 12px;
  left: 12px;
}

.toast-top-right {
  top: 12px;
  right: 12px;
}

.toast-bottom-right {
  right: 12px;
  bottom: 12px;
}

.toast-bottom-left {
  bottom: 12px;
  left: 12px;
}

/* toast styles */
.toast-title {
  font-weight: bold;
}

.toast-message {
  word-wrap: break-word;
}

.toast-message a,
.toast-message label {
  color: #FFFFFF;
}

.toast-message a:hover {
  color: #CCCCCC;
  text-decoration: none;
}

.toast-close-button {
  position: relative;
  right: -0.3em;
  top: -0.3em;
  float: right;
  font-size: 1.25rem;
  font-weight: bold;
  color: #FFFFFF;
  text-shadow: 0 1px 0 #ffffff;
  /* opacity: 0.8; */
}

.toast-close-button:hover,
.toast-close-button:focus {
  color: #000000;
  text-decoration: none;
  cursor: pointer;
  opacity: 0.4;
}

/*Additional properties for button version
 iOS requires the button element instead of an anchor tag.
 If you want the anchor version, it requires `href="#"`.*/
button.toast-close-button {
  padding: 0;
  cursor: pointer;
  background: transparent;
  border: 0;
}

.toast-container {
  pointer-events: none;
  position: fixed;
  z-index: 999999;
}

.toast-container * {
  box-sizing: border-box;
}

.toast-container .ngx-toastr {
  position: relative;
  overflow: hidden;
  margin: 0 0 6px;
  padding: 15px 15px 15px 50px;
  width: 300px;
  border-radius: 3px 3px 3px 3px;
  background-position: 15px center;
  background-repeat: no-repeat;
  background-size: 24px;
  box-shadow: 0 0 12px #999999;
  color: #FFFFFF;
}

.toast-container .ngx-toastr:hover {
  box-shadow: 0 0 12px #000000;
  opacity: 1;
  cursor: pointer;
}

/* https://github.com/FortAwesome/Font-Awesome-Pro/blob/master/advanced-options/raw-svg/regular/info-circle.svg */
.toast-info {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512' width='512' height='512'%3E%3Cpath fill='rgb(255,255,255)' d='M256 8C119.043 8 8 119.083 8 256c0 136.997 111.043 248 248 248s248-111.003 248-248C504 119.083 392.957 8 256 8zm0 110c23.196 0 42 18.804 42 42s-18.804 42-42 42-42-18.804-42-42 18.804-42 42-42zm56 254c0 6.627-5.373 12-12 12h-88c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h12v-64h-12c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h64c6.627 0 12 5.373 12 12v100h12c6.627 0 12 5.373 12 12v24z'/%3E%3C/svg%3E");
}

/* https://github.com/FortAwesome/Font-Awesome-Pro/blob/master/advanced-options/raw-svg/regular/times-circle.svg */
.toast-error {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512' width='512' height='512'%3E%3Cpath fill='rgb(255,255,255)' d='M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm121.6 313.1c4.7 4.7 4.7 12.3 0 17L338 377.6c-4.7 4.7-12.3 4.7-17 0L256 312l-65.1 65.6c-4.7 4.7-12.3 4.7-17 0L134.4 338c-4.7-4.7-4.7-12.3 0-17l65.6-65-65.6-65.1c-4.7-4.7-4.7-12.3 0-17l39.6-39.6c4.7-4.7 12.3-4.7 17 0l65 65.7 65.1-65.6c4.7-4.7 12.3-4.7 17 0l39.6 39.6c4.7 4.7 4.7 12.3 0 17L312 256l65.6 65.1z'/%3E%3C/svg%3E");
}

/* https://github.com/FortAwesome/Font-Awesome-Pro/blob/master/advanced-options/raw-svg/regular/check.svg */
.toast-success {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512' width='512' height='512'%3E%3Cpath fill='rgb(255,255,255)' d='M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z'/%3E%3C/svg%3E");
}

/* https://github.com/FortAwesome/Font-Awesome-Pro/blob/master/advanced-options/raw-svg/regular/exclamation-triangle.svg */
.toast-warning {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 576 512' width='576' height='512'%3E%3Cpath fill='rgb(255,255,255)' d='M569.517 440.013C587.975 472.007 564.806 512 527.94 512H48.054c-36.937 0-59.999-40.055-41.577-71.987L246.423 23.985c18.467-32.009 64.72-31.951 83.154 0l239.94 416.028zM288 354c-25.405 0-46 20.595-46 46s20.595 46 46 46 46-20.595 46-46-20.595-46-46-46zm-43.673-165.346l7.418 136c.347 6.364 5.609 11.346 11.982 11.346h48.546c6.373 0 11.635-4.982 11.982-11.346l7.418-136c.375-6.874-5.098-12.654-11.982-12.654h-63.383c-6.884 0-12.356 5.78-11.981 12.654z'/%3E%3C/svg%3E");
}

.toast-container.toast-top-center .ngx-toastr,
.toast-container.toast-bottom-center .ngx-toastr {
  width: 300px;
  margin-left: auto;
  margin-right: auto;
}

.toast-container.toast-top-full-width .ngx-toastr,
.toast-container.toast-bottom-full-width .ngx-toastr {
  width: 96%;
  margin-left: auto;
  margin-right: auto;
}

.ngx-toastr {
  background-color: #030303;
  pointer-events: auto;
}

.toast-success {
  background-color: #51A351;
}

.toast-error {
  background-color: #BD362F;
}

.toast-info {
  background-color: #2F96B4;
}

.toast-warning {
  background-color: #F89406;
}

.toast-progress {
  position: absolute;
  left: 0;
  bottom: 0;
  height: 4px;
  background-color: #000000;
  opacity: 0.4;
}

/* Responsive Design */
@media all and (max-width: 240px) {
  .toast-container .ngx-toastr.div {
    padding: 8px 8px 8px 50px;
    width: 11em;
  }
  .toast-container .toast-close-button {
    right: -0.2em;
    top: -0.2em;
  }
}
@media all and (min-width: 241px) and (max-width: 480px) {
  .toast-container .ngx-toastr.div {
    padding: 8px 8px 8px 50px;
    width: 18em;
  }
  .toast-container .toast-close-button {
    right: -0.2em;
    top: -0.2em;
  }
}
@media all and (min-width: 481px) and (max-width: 768px) {
  .toast-container .ngx-toastr.div {
    padding: 15px 15px 15px 50px;
    width: 25em;
  }
}
.mega-picker-selected-item {
  color: white;
  background-color: dodgerblue;
}
.mega-picker-selected-item a {
  color: white;
}

.mega-picker .form-control {
  cursor: pointer;
}

.mega-picker-image {
  max-height: 20px;
  max-width: 40px;
}

.mega-picker-content-overflow-auto {
  overflow: auto;
}

.mega-picker-content-container {
  left: 0;
  right: 0;
  z-index: 1000;
  position: absolute;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.5) 0 2px 4px 0;
}

.mega-picker-suggestion-container {
  left: 0;
  right: 0;
  z-index: 1000;
  position: inherit;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.5) 0 2px 4px 0;
  overflow-y: scroll;
  padding-bottom: 30px;
}

.mega-picker-suggestion {
  min-height: 25px;
  padding: 10px;
  cursor: pointer;
}

.mega-picker-suggestion:hover {
  color: white;
  background-color: dodgerblue;
}
.mega-picker-suggestion:hover a {
  color: white;
}

.mega-picker-suggestion-selected {
  color: white;
  background-color: dodgerblue;
}

.mega-picker-add {
  border-top: 1px solid grey;
  padding: 10px;
  cursor: pointer;
}
.mega-picker-add a {
  float: right;
  margin-bottom: 10px;
}

.mega-picker-child-container {
  margin-top: 10px;
  border-left: 2px solid var(--primary-color);
}

.position-fixed-top {
  position: sticky;
  z-index: 9;
  background: #fff;
  top: 0;
}

.position-fixed-bottom {
  position: fixed;
  margin-top: 115px;
  z-index: 99;
  background: #fff;
  height: 25px;
  width: 255px;
  line-height: 0;
}

.input-suggestion-container {
  position: absolute;
  top: 100%;
  background-color: #fff;
  box-shadow: rgba(0, 0, 0, 0.5) 0 2px 4px 0;
  overflow-y: scroll;
  z-index: 1000;
}

.top-absolute {
  position: absolute !important;
  top: 100%;
}

.mega-picker-disabled-item {
  background-color: var(--grayscale-white-color);
  cursor: not-allowed !important;
}

.ngx-data-viewer .toggle.right::after {
  display: inline-block;
  content: "►";
  color: #505050;
  cursor: pointer;
  position: absolute;
}
.ngx-data-viewer .toggle.down::after {
  display: inline-block;
  content: "►";
  transform: rotate(90deg);
  color: #505050;
  cursor: pointer;
  position: absolute;
}
.ngx-data-viewer div {
  max-width: 100%;
}
.ngx-data-viewer .tag {
  color: #4E187C;
}
.ngx-data-viewer span, .ngx-data-viewer div {
  font-family: monospace;
  font-size: 1em;
}
.ngx-data-viewer .value {
  color: #FF6B6B;
  overflow-wrap: anywhere;
}
.ngx-data-viewer .hint {
  color: #999;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.ngx-data-viewer .spacer {
  width: 18px;
  display: inline-block;
}
.ngx-data-viewer .container {
  margin-left: 10px;
  max-width: 100%;
}
.ngx-data-viewer .container.collapsed {
  display: flex;
}
.ngx-data-viewer .collection {
  position: relative;
}

/* Old Colors

$color0: #ededed; // white gray
$color1: rgb(190,190,190); // #bebebe - light gray
$color2: rgb(80,80,80); // #505050 - gray
$color3: rgb(207,214,224); // #cfd6e0 - white blue
$color4: rgb(159,172,193); // #9facc1 - light blue
$color5: rgb(88,111,146); // #586f92 - mid blue
$color6: rgb(15,48,99); // #1B3D6D - dark blue
$color7: rgb(57,213,31); // #39d51f - green
$color8: rgb(52,197,26); // #34C51A - dark green
*/
/*Theming*/
:root {
  --loading-login-screen-background:
          linear-gradient(to right, rgba(15, 48, 99, 0) 0%, rgba(75, 107, 155, .4) 25%, rgba(168, 187, 215, .4) 42%, rgba(168, 187, 215, .4) 58%, rgba(75, 107, 155, .4) 75%, rgba(15, 48, 99, 0) 100%),
          url(".././assets/images/pattern-block-lines.png") repeat;
  --loading-screen-logo: url(".././assets/images/bakerhill-nextgen-logo-white.png");
  --login-box-logo: url(".././assets/images/bakerhill-nextgen-logo.png");
  --primary-dark-color: #1B3D6D;
  --primary-color: #456aa1;
  --primary-color-darken-7percent: #3a5a88;
  --primary-color-darken-11percent: #34507a;
  --primary-color-lighten-10percent: #5f84ba;
  --primary-color-lighten-20percent: #829fca;
  --primary-color-lighten-40percent: #cad6e8;
  --primary-light1-color: #a6bad9;
  --primary-light1-color-darken-5percent:#94acd1;
  --primary-light1-color-darken-10percent: #829eca;
  --primary-light1-color-darken-15percent: #7090c2;
  --primary-light1-color-lighten-6percent: #bbcbe2;
  --primary-white-color: #e6ecf2;
  --primary-white-color-darken-2percent: #dfe7ef;
  --primary-white-color-darken-6percent: #d2dde8;
  --primary-white-color-lighten-2percent: #edf1f5;
  --primary-white-color-lighten-4percent: #f3f6f9;
  --primary-color2: #cddc29;
  --primary-color3: #81BD41;
  --primary-color3-lighten-40percent: #e5f2d8;
  --primary-color3-darken-5percent: #74aa3a;
  --primary-dark2-color: #689834;
  --primary-dark2-color-darken-10percent: #4e7227;
  --primary-light2-color: #e6f2d9;
  --primary-light2-color-darken-2percent: #e1efd1;
  --primary-light2-color-darken-6percent: #d7eac2;
  --primary-light2-color-darken-10percent: #cde5b3;
  --grayscale-color: #58595B;
  --grayscale-color-lighten-25percent: #98999b;
  --grayscale-light-color: #bdbdc2;
  --grayscale-light-color-darken-8percent: #a8a8ae;
  --grayscale-light-color-lighten-7percent: #d0d0d3;
  --grayscale-white-color: #ebebed;
  --grayscale-white-color-darken-5percent: #dedee1;
  --grayscale-white-color-darken-8percent: #d6d6da;
  --grayscale-white-color-lighten-2percent: #f0f0f2;
  --grayscale-white-color-lighten-5percent: #f8f8f9;
  --white-color: #ffffff;
  --black-color: #000000;
  --secondary-dark-color: #007299;
  --secondary-color: #77256c;
  --secondary-color2: #f68d29;
  --secondary-color3: #ffd240;
  --secondary-color3-darken-3percent: #ffce31;
  --secondary-color3-lighten-17percent: #ffe697;
  --secondary-color3-lighten-27percent: #fff2ca;
  --secondary-bright-color: #ece815;
  --error-color: #cc0000;
  --error-color-darken-10percent: #990000;
  --error-color-lighten-50percent: #ffcccc;
  --error-color-lighten-55percent: #ffe6e6;
  --error-light-color: #ffe6e6;
  --error-light-color-darken-5percent: #ffcdcd;
  --disabled-color: #abbcd5;
  --disabled-color2: #9badca;
  --disabled-color3: #a7d17a;
  --default-sprite-position: top left;
  --active-hover-sprite-position: top right;
  /*remaing colors*/
  --snow-gray: #fcfcfc;
  --light-gainsboro-gray: #f7f7f7;
  --white-smoke: #f4f4f4;
  --very-light-gray: #f0f0f0;
  --light-pink: #febbbb;
  --light-yellow: #ffffe5;
  --orange-peel: #ff9900;
  --electric-yellow: #ffff00;
  --yellow-green: #effadb;
  --whisper-gray: #ededed;
  --dark-whisper-gray: #eeeeee;
  --white-dark-smoke: #e6e6e6;
  --gainsboro-gray: #e0e0e0;
  --medium-gray: #cccccc;
  --silver: #c6c6c6;
  --silver-very-light: #b9b9b9;
  --silver-light: #bbbbbb;
  --blue-smoke: #eef1f7;
  --ivory-red: #f8f8f2;
  --spun-pearl: #afafb6;
  --very-light-blue: #e6ebf5;
  --dark-gainsboro-gray: #d9d9d9;
  --bright-gainsboro-gray: #dddddd;
  --hawakes-blue: #ccd6eb;
  --blue-violet: #9933ff;
  --nobel-gray: #999999;
  --light-danube-blue: #89a4cd;
  --matte-gray: #888888;
  --light-matte-gray: #787878;
  --dark-gray: #777777;
  --raven-gray: #707275;
  --danube-blue: #6685c2;
  --dim-gray: #666666;
  --davy-grey: #555555;
  --analog-blue: #435f8b;
  --charcoal-gray: #444444;
  --mariner-blue: #335cad;
  --dark-charcoal-gray: #333333;
  --green-waterloo: #23241f;
  --nero-dark: #222222;
  --smalt-blue: #003399;
  --deep-green: #008a00;
  --navy-blue: #0066cc;
  --bondi-blue: #0085c7;
  --violation-text: #cc0000;
  --violation-bg: #ffe6e6;
  --warning-text: #f68d29;
  --warning-bg: #fff2ca;
  --highcharts-color-2: #cddc29;
  --highcharts-color-3: #007299;
  --highcharts-color-6: #689834;
  --highcharts-color-7: #a6bad9;
}

[data-theme=fiserv] {
  --loading-login-screen-background: #666666;
  --loading-screen-logo: url(".././assets/images/Fiserv-logo-1200x603.png");
  --login-box-logo: url(".././assets/images/Fiserv-logo-1200x603.png");
  --primary-dark-color: #333333;
  --primary-color: #666666;
  --primary-color-darken-7percent: #545454;
  --primary-color-darken-11percent: #4a4a4a;
  --primary-color-lighten-10percent: gray;
  --primary-color-lighten-20percent: #999999;
  --primary-color-lighten-40percent: #cccccc;
  --primary-light1-color: #bfbfbf;
  --primary-light1-color-darken-5percent: #b2b2b2;
  --primary-light1-color-darken-10percent: #a6a6a6;
  --primary-light1-color-darken-15percent: #999999;
  --primary-light1-color-lighten-6percent: #cecece;
  --primary-white-color: #efefef;
  --primary-white-color-darken-2percent: #eaeaea;
  --primary-white-color-darken-6percent: #e0e0e0;
  --primary-white-color-lighten-2percent: #f4f4f4;
  --primary-white-color-lighten-4percent: #f9f9f9;
  --primary-color2: #4695d1;
  --primary-color3: #ff6600;
  --primary-color3-lighten-40percent: #ffe0cc;
  --primary-color3-darken-5percent: #e65c00;
  --primary-dark2-color: #ea6b15;
  --primary-dark2-color-darken-10percent: #bb5611;
  --primary-light2-color: #f2d3c4;
  --primary-light2-color-darken-2percent: #f0cdbc;
  --primary-light2-color-darken-6percent: #ecc0ab;
  --primary-light2-color-darken-10percent: #e9b49a;
  --grayscale-color: #666666;
  --grayscale-color-lighten-25percent: #a6a6a6;
  --grayscale-light-color: #e0e0e0;
  --grayscale-light-color-darken-8percent: #cccccc;
  --grayscale-light-color-lighten-7percent: #f2f2f2;
  --grayscale-white-color: #e0e0e0;
  --grayscale-white-color-darken-5percent: lightgray;
  --grayscale-white-color-darken-8percent: #cccccc;
  --grayscale-white-color-lighten-2percent: #e5e5e5;
  --grayscale-white-color-lighten-5percent: #ededed;
  --white-color: #ffffff;
  --black-color: #0e0e0e;
  --secondary-dark-color: #007299;
  --secondary-color: #77256c;
  --secondary-color2: #81bd41;
  --secondary-color3: #ffd240;
  --secondary-color3-darken-3percent: #ffce31;
  --secondary-color3-lighten-17percent: #ffe697;
  --secondary-color3-lighten-27percent: #fff2ca;
  --secondary-bright-color: #ece815;
  --error-color: #dd3435;
  --error-color-darken-10percent: #be2021;
  --error-color-lighten-50percent: white;
  --error-color-lighten-55percent: white;
  --error-light-color: #eabec0;
  --error-light-color-darken-5percent: #e4abad;
  --disabled-color: #c9c9c9;
  --disabled-color2: #c9c9c9;
  --disabled-color3: #dd9e73;
  --default-sprite-position: bottom left;
  --active-hover-sprite-position: bottom right;
  /*remaing colors*/
  --snow-gray: #fcfcfc;
  --light-gainsboro-gray: #f7f7f7;
  --white-smoke: #f4f4f4;
  --very-light-gray: #f0f0f0;
  --light-pink: #febbbb;
  --light-yellow: #ffffe5;
  --orange-peel: #ff9900;
  --electric-yellow: #ffff00;
  --yellow-green: #effadb;
  --whisper-gray: #ededed;
  --dark-whisper-gray: #eeeeee;
  --white-dark-smoke: #e6e6e6;
  --gainsboro-gray: #e0e0e0;
  --medium-gray: #cccccc;
  --silver: #c6c6c6;
  --silver-very-light: #b9b9b9;
  --silver-light: #bbbbbb;
  --blue-smoke: #eef1f7;
  --ivory-red: #f8f8f2;
  --spun-pearl: #afafb6;
  --very-light-blue: #e6ebf5;
  --dark-gainsboro-gray: #d9d9d9;
  --bright-gainsboro-gray: #dddddd;
  --hawakes-blue: #ccd6eb;
  --blue-violet: #9933ff;
  --nobel-gray: #999999;
  --light-danube-blue: #89a4cd;
  --matte-gray: #888888;
  --light-matte-gray: #787878;
  --dark-gray: #777777;
  --raven-gray: #707275;
  --danube-blue: #6685c2;
  --dim-gray: #666666;
  --davy-grey: #555555;
  --analog-blue: #435f8b;
  --charcoal-gray: #444444;
  --mariner-blue: #335cad;
  --dark-charcoal-gray: #333333;
  --green-waterloo: #23241f;
  --nero-dark: #222222;
  --smalt-blue: #003399;
  --deep-green: #008a00;
  --navy-blue: #0066cc;
  --bondi-blue: #0085c7;
  --violation-text: #cc0000;
  --violation-bg: #ffe6e6;
  --warning-text: #f68d29;
  --warning-bg: #fff2ca;
  --highcharts-color-2: #ffa366;
  --highcharts-color-3: #777777;
  --highcharts-color-6: #e65c00;
  --highcharts-color-7: #e0e0e0;
}

/*Sprite Positions*/
/*Images*/
/*Primary*/
/*Grayscale*/
/*Secondary*/
/*disabled*/
/*remaing colors*/
.darkblue {
  color: var(--primary-dark-color);
}

.blue {
  color: var(--primary-color);
}

.lightblue {
  color: var(--primary-light1-color);
}

.whiteblue {
  color: var(--primary-white-color);
}

.yellowgreen {
  color: var(--primary-color2);
}

.green {
  color: var(--primary-color3) !important;
}

.darkgreen {
  color: var(--primary-dark2-color);
}

.lightgreen {
  color: var(--primary-light2-color);
}

.gray {
  color: var(--grayscale-color);
}

.lightgray {
  color: var(--grayscale-light-color);
}

.whitegray {
  color: var(--grayscale-white-color);
}

.darkcyan {
  color: var(--secondary-dark-color);
}

.purple {
  color: var(--secondary-color);
}

.orange {
  color: var(--secondary-color2) !important;
}

.yellow {
  color: var(--secondary-color3);
}

.brightyellow {
  color: var(--secondary-bright-color);
}

.red {
  color: var(--error-color) !important;
}

.lightred {
  color: var(--error-light-color);
}

.white {
  color: var(--white-color) !important;
}

.darkblue-bg {
  background-color: var(--primary-dark-color);
}

.blue-bg {
  background-color: var(--primary-color) !important;
}

.lightblue-bg {
  background-color: var(--primary-light1-color);
}

.whiteblue-bg {
  background-color: var(--primary-white-color);
}

.yellowgreen-bg {
  background-color: var(--primary-color2);
}

.green-bg {
  background-color: var(--primary-color3);
}

.darkgreen-bg {
  background-color: var(--primary-dark2-color);
}

.lightgreen-bg {
  background-color: var(--primary-light2-color);
}

.gray-bg {
  background-color: var(--grayscale-color);
}

.lightgray-bg {
  background-color: var(--grayscale-light-color);
}

.whitegray-bg {
  background-color: var(--grayscale-white-color);
}

.darkcyan-bg {
  background-color: var(--secondary-dark-color);
}

.purple-bg {
  background-color: var(--secondary-color);
}

.orange-bg {
  background-color: var(--secondary-color2) !important;
}

.yellow-bg {
  background-color: var(--secondary-color3) !important;
}

.brightyellow-bg {
  background-color: var(--secondary-bright-color) !important;
}

.red-bg {
  background-color: var(--error-color) !important;
}

.lightred-bg {
  background-color: var(--error-light-color) !important;
}

/* TABLES */
table {
  empty-cells: show;
  border: 1px solid var(--primary-light1-color-darken-10percent);
  width: 100%;
  max-width: 100%;
}
table > thead > tr > th,
table > thead > tr > td,
table > tbody > tr > th,
table > tbody > tr > td,
table > tfoot > tr > th,
table > tfoot > tr > td {
  padding: 8px 10px;
  font-weight: normal;
  line-height: 1.428571429;
  vertical-align: top;
}
table > tfoot > tr > td {
  font-weight: bold;
}

thead {
  border-bottom: 2px solid var(--primary-light1-color-darken-10percent);
  background: var(--primary-white-color-darken-2percent) linear-gradient(to bottom, var(--primary-white-color), var(--primary-white-color) 50%, var(--primary-white-color-darken-6percent));
}

tfoot {
  border-top: 2px solid var(--primary-light1-color-darken-10percent);
  background-color: var(--grayscale-white-color);
}

th + th {
  border-left: 1px solid var(--grayscale-light-color);
  border-right: 1px solid var(--grayscale-light-color);
}

tr + tr {
  border-top: 1px solid var(--grayscale-light-color);
  border-bottom: 1px solid var(--grayscale-light-color);
}

td + td {
  border-left: 1px solid var(--grayscale-light-color);
  border-right: 1px solid var(--grayscale-light-color);
}

.table-striped > tbody > tr:nth-of-type(odd),
tbody.table-striped > tr:nth-of-type(odd) {
  background-color: var(--white-color);
}

.table-striped > tbody > tr:nth-of-type(even),
tbody.table-striped > tr:nth-of-type(even) {
  background-color: var(--grayscale-white-color-lighten-2percent);
}

.table-hscroll {
  overflow-x: auto;
  overflow-y: hidden;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  margin-bottom: 15px;
  border: 1px solid var(--primary-light1-color-darken-10percent);
}
.table-hscroll table {
  border: none;
}
.table-hscroll table tbody tr:last-of-type {
  border-bottom: none;
}
.table-hscroll table thead tr th:last-of-type, .table-hscroll table tbody tr td:last-of-type, .table-hscroll table tfoot tr td:last-of-type {
  border-right: none;
}
.table-hscroll + .pagination-container {
  margin-top: 0;
}

.table-nowrap th,
.table-nowrap td {
  white-space: nowrap;
}

.table-nowrap-rows td {
  white-space: nowrap;
}

th:not(.btn-col),
th:not(.btn2-col) {
  min-width: 90px;
}

.table-searchbar {
  background: var(--primary-white-color-darken-2percent) linear-gradient(to bottom, var(--primary-white-color), var(--primary-white-color) 50%, var(--primary-white-color-darken-6percent));
  border: 1px solid var(--primary-light1-color-darken-10percent);
  border-bottom: none;
  padding: 8px 10px;
}
.table-searchbar .input-group[class*=col-] {
  float: left;
  padding-left: 15px;
  padding-right: 0;
}
.table-searchbar .expand-collapse-container {
  display: inline-block;
  margin: 0 10px;
}
.table-searchbar .text-selected {
  text-align: right;
  margin: 11px 0 0;
  float: right;
}

.table-advanced-searchbar {
  background-color: var(--grayscale-white-color-lighten-2percent);
  padding: 5px 70px 5px 12px;
  margin: 0;
  border: 1px solid var(--primary-light1-color-darken-10percent);
  position: relative;
}
.table-advanced-searchbar .input-group-btn .btn {
  margin: 0;
}
.table-advanced-searchbar .col-sm-4 {
  padding-bottom: 6px;
}
.table-advanced-searchbar .advanced-checkbox {
  padding: 0;
}
.table-advanced-searchbar .advanced-checkbox .checkbox {
  margin: 13px 0 15px;
}
.table-advanced-searchbar .column-toggle-btn {
  position: absolute;
  top: 12px;
  right: 12px;
}
.table-advanced-searchbar .column-toggle-btn .dropdown-menu {
  padding-left: 8px;
  padding-right: 8px;
}

.column-toggle-btn .dropdown-menu {
  padding-left: 8px;
  padding-right: 8px;
}

.header-panel-table .dropdown {
  position: unset;
}

.table-multiedit-container {
  float: left;
  padding: 4px 10px 5px 18px;
  border-right: 1px solid var(--grayscale-light-color);
}
.table-multiedit-container .selected-rows-indicator {
  color: var(--primary-color);
  font-weight: bold;
  font-size: 1.09375rem;
  text-align: center;
  display: inline-block;
  position: relative;
  top: 2px;
  min-width: 20px;
  margin-right: 20px;
}
.table-multiedit-container .dropdown {
  margin-left: 5px;
}

table tbody tr.group-header,
.table tbody tr.group-header {
  background-color: var(--primary-color) !important;
  color: white;
  font-size: 0.875rem;
  border-bottom: 2px solid var(--primary-light1-color);
}
table tbody tr.group-header > td,
.table tbody tr.group-header > td {
  padding: 7px 10px;
}
table tbody tr.group-header > td.clickable,
.table tbody tr.group-header > td.clickable {
  cursor: pointer;
}
table tbody tr.group-header > td .collapse-btn .fa,
.table tbody tr.group-header > td .collapse-btn .fa {
  margin-right: 5px;
  color: white;
  font-size: 1.4rem;
  font-weight: bold;
  width: 26px;
}
table tbody tr.group-header > td .select-btn,
.table tbody tr.group-header > td .select-btn {
  width: 26px;
  margin-right: 18px;
}
table tbody tr.group-header .fa,
.table tbody tr.group-header .fa {
  color: white;
  margin-right: 6px;
}

.table-group-header {
  background-color: var(--primary-color);
  color: white;
  border-bottom: 2px solid var(--primary-light1-color);
  padding: 7px 10px;
}
.table-group-header .collapse-btn .fa {
  margin-right: 5px;
  color: white;
  font-size: 1.4rem;
  font-weight: bold;
  width: 26px;
}

tr.sort-row {
  background: var(--primary-white-color-darken-2percent) linear-gradient(to bottom, var(--primary-white-color), var(--primary-white-color-darken-6percent));
}
tr.sort-row button {
  text-align: left;
}

th:not(.btn-col).sort,
th:not(.btn2-col).sort {
  padding: 0;
}
th:not(.btn-col).sort button,
th:not(.btn2-col).sort button {
  padding: 8px 25px 5px 10px;
  position: relative;
  width: 100%;
}
th:not(.btn-col).sort button .sort-icon:after, th:not(.btn-col).sort button .sort-icon-desc:after, th:not(.btn-col).sort button .sort-icon-asc:after,
th:not(.btn2-col).sort button .sort-icon:after,
th:not(.btn2-col).sort button .sort-icon-desc:after,
th:not(.btn2-col).sort button .sort-icon-asc:after {
  font-family: "FontAwesome", sans-serif;
  font-size: 0.9625rem;
  position: absolute;
  top: 6px;
  right: 10px;
}
th:not(.btn-col).sort button .sort-icon:after,
th:not(.btn2-col).sort button .sort-icon:after {
  content: "\f0dc";
  color: var(--primary-light1-color);
}
th:not(.btn-col).sort button:hover .sort-icon:after,
th:not(.btn2-col).sort button:hover .sort-icon:after {
  content: "\f0dc";
  color: var(--primary-color);
}
th:not(.btn-col).sort button .sort-icon-desc:after, th:not(.btn-col).sort button:hover .sort-icon-desc:after,
th:not(.btn2-col).sort button .sort-icon-desc:after,
th:not(.btn2-col).sort button:hover .sort-icon-desc:after {
  content: "\f0dd";
  color: var(--primary-color);
}
th:not(.btn-col).sort button .sort-icon-asc:after, th:not(.btn-col).sort button:hover .sort-icon-asc:after,
th:not(.btn2-col).sort button .sort-icon-asc:after,
th:not(.btn2-col).sort button:hover .sort-icon-asc:after {
  content: "\f0de";
  color: var(--primary-color);
}

tr.filter-row,
tr.filter-row th {
  border-top: none !important;
  background: var(--primary-white-color-darken-6percent);
}

th:not(.btn-col).filter,
th:not(.btn2-col).filter {
  padding: 0;
  min-width: 120px !important;
}
th:not(.btn-col).filter .input-group,
th:not(.btn2-col).filter .input-group {
  margin: 0 10px 8px 10px;
}
th:not(.btn-col).filter .input-group .form-control,
th:not(.btn2-col).filter .input-group .form-control {
  z-index: 1;
}
th:not(.btn-col).filter :last-child,
th:not(.btn2-col).filter :last-child {
  padding: unset;
  margin-right: unset;
  max-width: 192px;
}
th:not(.btn-col).filter .input-group-btn .btn,
th:not(.btn2-col).filter .input-group-btn .btn {
  font-size: 18px;
  height: 30px;
  padding: 0 0 0 10px;
  width: auto;
  border: none;
  background: none;
  color: var(--primary-color);
}
th:not(.btn-col).filter .input-group-btn .btn:hover,
th:not(.btn2-col).filter .input-group-btn .btn:hover {
  color: var(--primary-dark-color);
}

table th .dropdown-menu > li,
table td .dropdown-menu > li,
.table-searchbar .dropdown-menu > li {
  line-height: 1.428571429;
}
table th .dropdown-menu > li:hover, table th .dropdown-menu > li:focus,
table td .dropdown-menu > li:hover,
table td .dropdown-menu > li:focus,
.table-searchbar .dropdown-menu > li:hover,
.table-searchbar .dropdown-menu > li:focus {
  background-color: var(--grayscale-white-color);
}
table th .dropdown-menu > li > button .dropdown-button-styles:hover, table th .dropdown-menu > li > button .dropdown-button-styles:focus,
table td .dropdown-menu > li > button .dropdown-button-styles:hover,
table td .dropdown-menu > li > button .dropdown-button-styles:focus,
.table-searchbar .dropdown-menu > li > button .dropdown-button-styles:hover,
.table-searchbar .dropdown-menu > li > button .dropdown-button-styles:focus {
  color: var(--primary-color);
}

td.btn-col .dropdown,
td.btn2-col .dropdown {
  position: static;
  float: right;
}

td.btn-col .dropdown-menu,
td.btn2-col .dropdown-menu {
  top: unset;
  left: unset;
}

.pagination-container {
  margin-top: 8px;
  margin-bottom: 20px;
  font-size: 0.875rem !important;
  width: 100%;
  display: flex;
  display: -webkit-flex;
  flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  justify-content: space-between;
}
.pagination-container .pagination-left {
  float: left;
  display: inline-block;
  margin-bottom: 10px;
  margin-right: 10px;
}
.pagination-container .pagination-left button {
  border-radius: 0;
  padding: 5px 11px;
  color: var(--grayscale-color);
  background-color: white;
  border: 1px solid var(--grayscale-light-color);
  margin-left: -1px;
}
.pagination-container .pagination-left button .fa {
  color: var(--primary-color);
}
.pagination-container .pagination-left button:hover, .pagination-container .pagination-left button:focus, .pagination-container .pagination-left button:hover .fa, .pagination-container .pagination-left button:focus .fa {
  color: white;
  background-color: var(--primary-color);
  border-color: var(--primary-color);
  outline: none;
}
.pagination-container .pagination-left button.disabled, .pagination-container .pagination-left button[disabled], .pagination-container .pagination-left button.disabled .fa, .pagination-container .pagination-left button[disabled] .fa {
  color: var(--grayscale-light-color);
  background-color: white;
  border-color: var(--grayscale-light-color);
  cursor: not-allowed;
}
.pagination-container .pagination-left span.page-numbers {
  margin-left: 5px;
}
.pagination-container .pagination-left span.page-numbers input {
  width: 34px;
  height: 32px;
  text-align: center;
  border: 1px solid var(--grayscale-light-color);
  margin: 0 5px;
}
.pagination-container .pagination-left span.page-numbers input:focus {
  border: 1px solid var(--primary-color);
  outline: none;
}
.pagination-container .pagination-right {
  float: right;
  display: inline-block;
}
.pagination-container .pagination-right button.row-numbers:hover, .pagination-container .pagination-right button.row-numbers:focus, .pagination-container .pagination-right button.ellipses:hover, .pagination-container .pagination-right button.ellipses:focus {
  color: var(--grayscale-color);
  background-color: white;
  border-color: var(--grayscale-light-color);
  cursor: default;
}
.pagination-container .pagination-right span.row-numbers {
  height: 32px;
  line-height: 32px;
}
.pagination-container .pagination-right .row-selector {
  margin: 0 5px;
  display: inline-block;
  position: relative;
}
.pagination-container .pagination-right .row-selector .form-control {
  border: 1px solid var(--grayscale-light-color);
  font-size: 0.875rem;
  width: 50px;
  min-height: 32px;
  padding: 0 7px;
}
.pagination-container .pagination-right .row-selector .form-control.icon-right {
  padding-right: 0 !important;
}
.pagination-container .pagination-right .row-selector .fa-caret-down {
  font-size: 20px;
  right: 7px;
  top: 6px;
}

/*Tree collapsing */
button.toggle-collapse {
  float: right;
  margin-left: 15px;
}
button.toggle-collapse i {
  margin-right: 5px;
}

td.tree-tier {
  padding-left: 36px;
}
td.tree-tier .tree-cell {
  position: relative;
}
td.tree-tier .tree-cell .tree-line {
  position: absolute;
  top: -8px;
  left: -36px;
  border-right: 1px solid var(--primary-color-lighten-10percent);
  background-color: var(--primary-white-color-lighten-2percent);
  height: calc(100% + 16px);
  width: 16px;
  display: block;
  z-index: 1;
}
td.tree-tier .tree-cell .tree-btn {
  position: absolute;
  top: -8px;
  left: -28px;
  z-index: 2;
  height: calc(100% + 16px);
  width: 15px;
}
td.tree-tier .tree-cell .tree-btn .fa {
  color: white;
  font-size: 0.9625rem;
  font-weight: bold;
  width: 15px;
  padding: 1px;
  border: 1px solid var(--primary-color);
  background-color: var(--primary-color);
  border-radius: 2px;
  position: relative;
  bottom: 1px;
}
td.tree-tier .tree-cell .tree-btn .fa.fa-angle-right:before {
  position: relative;
  bottom: 1px;
}
td.tree-tier .tree-cell .tree-btn:hover .fa {
  background-color: var(--primary-light1-color);
  color: white;
}
td.tree-tier .tree-cell .tree-btn.open .fa {
  color: var(--primary-color);
  background-color: white;
}
td.tree-tier .tree-cell .tree-btn.open:hover .fa {
  background-color: var(--primary-white-color);
  color: var(--primary-color);
}

td.tree-tier-1 {
  padding-left: 60px;
}
td.tree-tier-1 .tree-cell .tree-line {
  left: -60px;
  width: 40px;
}

td.tree-tier-2 {
  padding-left: 84px;
}
td.tree-tier-2 .tree-cell .tree-line {
  left: -84px;
  width: 64px;
}

td.tree-tier-3 {
  padding-left: 108px;
}
td.tree-tier-3 .tree-cell .tree-line {
  left: -108px;
  width: 88px;
}

td.tree-tier-4 {
  padding-left: 132px;
}
td.tree-tier-4 .tree-cell .tree-line {
  left: -132px;
  width: 112px;
}

td.tree-tier-5 {
  padding-left: 156px;
}
td.tree-tier-5 .tree-cell .tree-line {
  left: -156px;
  width: 136px;
}

td.tree-tier-6 {
  padding-left: 180px;
}
td.tree-tier-6 .tree-cell .tree-line {
  left: -180px;
  width: 160px;
}

td.tree-tier-7 {
  padding-left: 204px;
}
td.tree-tier-7 .tree-cell .tree-line {
  left: -204px;
  width: 184px;
}

td.tree-tier-8 {
  padding-left: 228px;
}
td.tree-tier-8 .tree-cell .tree-line {
  left: -228px;
  width: 208px;
}

td.tree-tier-9 {
  padding-left: 252px;
}
td.tree-tier-9 .tree-cell .tree-line {
  left: -252px;
  width: 232px;
}

td.tree-tier-10 {
  padding-left: 276px;
}
td.tree-tier-10 .tree-cell .tree-line {
  left: -276px;
  width: 256px;
}

td.tree-tier-11 {
  padding-left: 300px;
}
td.tree-tier-11 .tree-cell .tree-line {
  left: -300px;
  width: 280px;
}

td.tree-tier-12 {
  padding-left: 324px;
}
td.tree-tier-12 .tree-cell .tree-line {
  left: -324px;
  width: 304px;
}

td.tree-tier-13 {
  padding-left: 348px;
}
td.tree-tier-13 .tree-cell .tree-line {
  left: -348px;
  width: 328px;
}

td.tree-tier-14 {
  padding-left: 372px;
}
td.tree-tier-14 .tree-cell .tree-line {
  left: -372px;
  width: 352px;
}

td.tree-tier-15 {
  padding-left: 396px;
}
td.tree-tier-15 .tree-cell .tree-line {
  left: -396px;
  width: 376px;
}

td.tree-tier-16 {
  padding-left: 420px;
}
td.tree-tier-16 .tree-cell .tree-line {
  left: -420px;
  width: 400px;
}

td.tree-tier-17 {
  padding-left: 444px;
}
td.tree-tier-17 .tree-cell .tree-line {
  left: -444px;
  width: 424px;
}

td.tree-tier-18 {
  padding-left: 468px;
}
td.tree-tier-18 .tree-cell .tree-line {
  left: -468px;
  width: 448px;
}

td.tree-tier-19 {
  padding-left: 492px;
}
td.tree-tier-19 .tree-cell .tree-line {
  left: -492px;
  width: 472px;
}

td.tree-tier-20 {
  padding-left: 516px;
}
td.tree-tier-20 .tree-cell .tree-line {
  left: -516px;
  width: 496px;
}

td.tree-tier-21 {
  padding-left: 540px;
}
td.tree-tier-21 .tree-cell .tree-line {
  left: -540px;
  width: 520px;
}

td.tree-tier-22 {
  padding-left: 564px;
}
td.tree-tier-22 .tree-cell .tree-line {
  left: -564px;
  width: 544px;
}

td.tree-tier-23 {
  padding-left: 588px;
}
td.tree-tier-23 .tree-cell .tree-line {
  left: -588px;
  width: 568px;
}

td.tree-tier-24 {
  padding-left: 612px;
}
td.tree-tier-24 .tree-cell .tree-line {
  left: -612px;
  width: 592px;
}

td.tree-tier-25 {
  padding-left: 636px;
}
td.tree-tier-25 .tree-cell .tree-line {
  left: -636px;
  width: 616px;
}

td.tree-tier-26 {
  padding-left: 660px;
}
td.tree-tier-26 .tree-cell .tree-line {
  left: -660px;
  width: 640px;
}

table > tr > td {
  padding: 8px 10px;
  vertical-align: top;
}

/*Edit button columnn*/
th.btn-col,
td.btn-col,
.col-symbol {
  width: 46px !important;
  min-width: 46px !important;
}

th.btn2-col,
td.btn2-col {
  width: 82px !important;
  min-width: 82px !important;
}

th.btn3-col,
td.btn3-col {
  width: 92px !important;
  min-width: 92px !important;
}

th.btn-col,
td.btn-col,
th.btn2-col,
td.btn2-col {
  padding: 5px 10px !important;
}
th.btn-col .btn ~ .btn,
td.btn-col .btn ~ .btn,
th.btn2-col .btn ~ .btn,
td.btn2-col .btn ~ .btn {
  float: right;
}

.btn-col .btn + .btn {
  margin-left: 10px;
}

/*Table slideout Menu - NOT USED*/
td.table-row-menu-container {
  position: relative;
  min-width: 280px;
}

.table-row-menu-btn {
  float: right;
  cursor: pointer;
  padding-top: 5px;
}

.table-row-menu-btn:focus,
.table-row-menu-btn:hover {
  color: var(--primary-dark2-color);
}

.table-row-menu {
  position: absolute;
  top: 0;
  right: 0;
  padding: 0 8px;
  background-color: var(--primary-white-color);
  height: 100%;
  max-height: 40px;
  width: auto;
  display: none;
}
.table-row-menu .fa-times {
  color: var(--primary-color);
  vertical-align: middle;
}
.table-row-menu .fa-times:hover {
  color: var(--primary-dark-color);
}
.table-row-menu button, .table-row-menu a.btn {
  margin: 5px 4px;
}
.table-row-menu button:hover, .table-row-menu a.btn:hover {
  background-color: var(--primary-dark-color);
}

td.select-col {
  display: none;
}

/*Dropdown Table*/
.dropdown-table table tbody tr {
  cursor: pointer;
}
.dropdown-table table tbody tr:hover, .dropdown-table table tbody tr:focus {
  background-color: var(--primary-color);
  color: white;
}

/*Column Type Styling*/
.columntype-checkmark,
.columntype-boolean {
  text-align: center;
}
.columntype-checkmark .fa-check,
.columntype-boolean .fa-check {
  color: var(--primary-color3);
}
.columntype-checkmark .fa-times,
.columntype-boolean .fa-times {
  color: var(--error-color);
}
.columntype-checkmark .fa-share,
.columntype-boolean .fa-share {
  color: var(--secondary-color2);
}

.columntype-currency,
.columntype-number,
.columntype-decimal {
  text-align: right;
  padding-left: 15px;
  position: relative;
}

.columntype-currency:before {
  font-family: "FontAwesome", sans-serif;
  content: "\f155";
  font-size: 0.875rem;
  position: absolute;
  left: 0;
  color: var(--primary-light1-color);
}

td.columntype-currency {
  padding-left: 25px !important;
}
td.columntype-currency:before {
  left: 10px;
}
td.columntype-currency.error, td.columntype-currency.success, td.columntype-currency.info, td.columntype-currency.warning {
  padding-left: 42px !important;
}
td.columntype-currency:after {
  left: 27px !important;
}

.columntype-percentage {
  text-align: right;
  padding-right: 20px;
  position: relative;
}

.columntype-percentage:after {
  font-family: "FontAwesome", sans-serif;
  content: "\f295";
  font-size: 0.875rem;
  position: absolute;
  right: 0;
  color: var(--primary-light1-color);
}

td.columntype-percentage {
  padding-right: 30px !important;
}
td.columntype-percentage:after {
  right: 10px;
}

.columntype-time {
  min-width: 61px;
}

.column-group {
  text-align: center;
  font-weight: bold;
}

td.green-background,
th.green-background {
  background: var(--primary-light2-color-darken-2percent) linear-gradient(to bottom, var(--primary-light2-color), var(--primary-light2-color-darken-6percent));
}

/*Row Type Styling*/
.error-row {
  background-color: var(--error-color-lighten-55percent) !important;
}

.attention-row {
  background-color: var(--secondary-color3-lighten-27percent) !important;
}

.selected-row {
  background-color: var(--primary-light2-color) !important;
}

.shaded-row {
  background-color: var(--grayscale-white-color-lighten-2percent);
}

.summed-row .columntype-currency {
  font-weight: bold;
  padding-left: 35px;
}
.summed-row .columntype-currency:after {
  content: "Σ";
  font-size: 0.9625rem;
  position: absolute;
  left: 20px;
  color: var(--primary-light1-color);
}

tr.row-readonly td:not(.btn-col):not(.btn2-col),
td.row-readonly {
  opacity: 0.65;
}

tr.row-disabled,
td.row-disabled {
  cursor: not-allowed;
  opacity: 0.65;
}
tr.row-disabled .btn,
td.row-disabled .btn {
  cursor: not-allowed;
  pointer-events: none;
}

/*Cell Type Styling*/
td.cell-disabled {
  cursor: not-allowed;
  opacity: 0.65;
}

td.single-line,
td.double-line,
td.solid-line {
  position: relative;
}
td.single-line span:before,
td.double-line span:before,
td.solid-line span:before {
  content: " ";
  position: absolute;
  top: 50%;
  border-bottom: 2px solid var(--grayscale-color);
  width: calc(100% - 20px);
  margin: 0 10px;
}

td.double-line span:before {
  top: 15px;
  border-bottom: 7px double var(--grayscale-color);
}

td.solid-line span:before {
  top: 10px;
  border-bottom: 17px solid var(--grayscale-color);
}

th.width75,
td.width75 {
  min-width: 75px;
  max-width: 75px;
}

th.width85,
td.width85 {
  min-width: 85px;
  max-width: 85px;
}

th.width95,
td.width95 {
  min-width: 95px;
  max-width: 95px;
}

td span.column-colorblock {
  display: inline-block;
  width: 15px;
  height: 15px;
  position: relative;
  top: 2px;
  margin-right: 8px;
  border-radius: 2px;
  border: 1px solid var(--grayscale-color);
}

td.error,
td.success,
td.info,
td.warning {
  text-align: right;
  padding-left: 35px;
  position: relative;
}
td.error:after,
td.success:after,
td.info:after,
td.warning:after {
  font-family: "FontAwesome", sans-serif;
  content: "";
  font-size: 0.875rem;
  position: absolute;
  left: 10px;
}

td.error {
  background-color: var(--error-light-color);
}
td.error:after {
  content: "\f071";
  color: var(--error-color);
}

td.success {
  background-color: var(--primary-color3-lighten-40percent);
}
td.success:after {
  content: "\f00c";
  color: var(--primary-color3);
}

td.warning {
  background-color: var(--secondary-color3-lighten-27percent);
}
td.warning:after {
  content: "\f071";
  color: var(--secondary-color2);
}

td.forecast {
  background-color: var(--secondary-color3-lighten-27percent);
}

td.info {
  background-color: var(--primary-white-color-darken-2percent);
}
td.info:after {
  content: "\f05a";
  color: var(--primary-color);
}

td.indent,
td.indent2 {
  padding-left: 35px;
}
td.indent.dash:before,
td.indent2.dash:before {
  content: "-";
  padding-right: 5px;
}

td.indent2 {
  padding-left: 60px;
}

/*Report and Document Folder Tables*/
.folder-group-table {
  margin-bottom: 10px;
}
.folder-group-table .folder-indent:last-child {
  margin-bottom: 15px;
}

.folder-group-header {
  cursor: pointer;
  background: var(--grayscale-white-color-lighten-2percent);
  margin: 0 15px;
  padding: 0 9px 8px !important;
  border: 1px solid var(--primary-light1-color-darken-10percent);
  border-bottom: none;
}
.folder-group-header .folder-actions {
  position: absolute;
  right: 25px;
}
.folder-group-header .folder-actions button {
  position: relative;
  top: 7px;
}
.folder-group-header .folder-actions .dropdown-menu {
  left: auto;
  right: 0;
}
.folder-group-header .folder-actions .dropdown-menu > li {
  line-height: 1.428571429;
}
.folder-group-header .folder-actions .dropdown-menu > li:hover, .folder-group-header .folder-actions .dropdown-menu > li:focus {
  background-color: var(--grayscale-white-color);
}
.folder-group-header .folder-actions .dropdown-menu > li > button .dropdown-button-styles {
  top: 0;
}
.folder-group-header th {
  padding: 0 9px 8px !important;
}
.folder-group-header:hover {
  background-color: var(--primary-white-color);
}
.folder-group-header.closed {
  margin-bottom: -1px;
  border: 1px solid var(--primary-light1-color-darken-10percent);
}

.folder-group-header h4, .folder-group-header .h4 {
  margin: 0;
}
.folder-group-header h4 .fa, .folder-group-header .h4 .fa {
  color: var(--primary-color);
  margin-right: 8px;
  position: relative;
  top: 6px;
}
.folder-group-header h4 span, .folder-group-header .h4 span {
  font-size: 0.875rem;
  position: relative;
  bottom: 7px;
  left: 7px;
}
.folder-group-header h4 span .fa, .folder-group-header .h4 span .fa {
  color: var(--primary-color3);
}

.folder-indent {
  margin-left: 10px;
}

.col-check {
  width: 38px !important;
  padding: 8px 6px !important;
  color: var(--primary-color3);
}

.check-key {
  margin-left: 15px;
  display: inline-block;
}

.drag-mockup {
  width: 30px;
  height: 37px;
  background: var(--primary-white-color-darken-2percent) linear-gradient(to bottom, var(--primary-white-color), var(--primary-white-color-darken-6percent));
  cursor: move;
  text-align: center;
  position: relative;
}
.drag-mockup:before, .drag-mockup:after {
  font-family: "FontAwesome", sans-serif;
  content: "\f142";
  font-size: 18px;
  color: var(--primary-light1-color-lighten-6percent);
  position: absolute;
  left: 9px;
}
.drag-mockup:after {
  left: 17px;
}
.drag-mockup:hover::before, .drag-mockup:hover::after {
  color: var(--primary-color);
}

.treeview-shadebox {
  position: absolute;
  top: 0;
  left: 0;
  border-right: 1px solid var(--primary-color-lighten-20percent);
  background-color: var(--primary-white-color-lighten-4percent);
  height: 100%;
  z-index: 0;
}

.wj-flexgrid:not(.wj-state-focused) .row-selected .treeview-shadebox,
.wj-state-multi-selected .treeview-shadebox {
  background-color: var(--primary-light2-color);
}

.factor-weight {
  display: block;
  color: var(--primary-color);
  font-weight: bold;
}
.factor-weight:after {
  content: "%";
}

/*For Mockups*/
.table-show-filter th:not(.btn-col) {
  position: relative;
  padding-right: 33px;
}
.table-show-filter th:not(.btn-col):after {
  font-family: "FontAwesome", sans-serif;
  content: "\f0b0";
  font-size: 13px;
  position: absolute;
  right: 8px;
  cursor: pointer;
  padding: 0 4px;
  background-color: var(--primary-light1-color);
  border-radius: 3px;
  color: white;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.08), 0 1px 1px rgba(0, 0, 0, 0.16);
}

.header-panel-table h2, .header-panel-table .h2 {
  color: var(--white-color);
}

.header-panel-table .select-actions {
  background-color: var(--grayscale-white-color-lighten-2percent);
  margin: 0 -11px;
  padding: 0 100px 0 12px;
  border-bottom: 1px solid var(--analog-blue);
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  position: relative;
  top: 7px;
  clear: both;
}
.header-panel-table .select-actions .selected-row-count {
  margin: 1px 10px 0 6px;
}
.header-panel-table .select-actions h3, .header-panel-table .select-actions .h3 {
  margin: 0;
  display: inline-block;
  font-weight: bold;
  color: var(--primary-color);
}
.header-panel-table .select-actions .vertical-divider {
  display: block;
  float: left;
  background: var(--grayscale-light-color);
  margin: 9px 10px;
  height: 32px;
  width: 1px;
}

/* Old Colors

$color0: #ededed; // white gray
$color1: rgb(190,190,190); // #bebebe - light gray
$color2: rgb(80,80,80); // #505050 - gray
$color3: rgb(207,214,224); // #cfd6e0 - white blue
$color4: rgb(159,172,193); // #9facc1 - light blue
$color5: rgb(88,111,146); // #586f92 - mid blue
$color6: rgb(15,48,99); // #1B3D6D - dark blue
$color7: rgb(57,213,31); // #39d51f - green
$color8: rgb(52,197,26); // #34C51A - dark green
*/
/*Theming*/
:root {
  --loading-login-screen-background:
          linear-gradient(to right, rgba(15, 48, 99, 0) 0%, rgba(75, 107, 155, .4) 25%, rgba(168, 187, 215, .4) 42%, rgba(168, 187, 215, .4) 58%, rgba(75, 107, 155, .4) 75%, rgba(15, 48, 99, 0) 100%),
          url(".././assets/images/pattern-block-lines.png") repeat;
  --loading-screen-logo: url(".././assets/images/bakerhill-nextgen-logo-white.png");
  --login-box-logo: url(".././assets/images/bakerhill-nextgen-logo.png");
  --primary-dark-color: #1B3D6D;
  --primary-color: #456aa1;
  --primary-color-darken-7percent: #3a5a88;
  --primary-color-darken-11percent: #34507a;
  --primary-color-lighten-10percent: #5f84ba;
  --primary-color-lighten-20percent: #829fca;
  --primary-color-lighten-40percent: #cad6e8;
  --primary-light1-color: #a6bad9;
  --primary-light1-color-darken-5percent:#94acd1;
  --primary-light1-color-darken-10percent: #829eca;
  --primary-light1-color-darken-15percent: #7090c2;
  --primary-light1-color-lighten-6percent: #bbcbe2;
  --primary-white-color: #e6ecf2;
  --primary-white-color-darken-2percent: #dfe7ef;
  --primary-white-color-darken-6percent: #d2dde8;
  --primary-white-color-lighten-2percent: #edf1f5;
  --primary-white-color-lighten-4percent: #f3f6f9;
  --primary-color2: #cddc29;
  --primary-color3: #81BD41;
  --primary-color3-lighten-40percent: #e5f2d8;
  --primary-color3-darken-5percent: #74aa3a;
  --primary-dark2-color: #689834;
  --primary-dark2-color-darken-10percent: #4e7227;
  --primary-light2-color: #e6f2d9;
  --primary-light2-color-darken-2percent: #e1efd1;
  --primary-light2-color-darken-6percent: #d7eac2;
  --primary-light2-color-darken-10percent: #cde5b3;
  --grayscale-color: #58595B;
  --grayscale-color-lighten-25percent: #98999b;
  --grayscale-light-color: #bdbdc2;
  --grayscale-light-color-darken-8percent: #a8a8ae;
  --grayscale-light-color-lighten-7percent: #d0d0d3;
  --grayscale-white-color: #ebebed;
  --grayscale-white-color-darken-5percent: #dedee1;
  --grayscale-white-color-darken-8percent: #d6d6da;
  --grayscale-white-color-lighten-2percent: #f0f0f2;
  --grayscale-white-color-lighten-5percent: #f8f8f9;
  --white-color: #ffffff;
  --black-color: #000000;
  --secondary-dark-color: #007299;
  --secondary-color: #77256c;
  --secondary-color2: #f68d29;
  --secondary-color3: #ffd240;
  --secondary-color3-darken-3percent: #ffce31;
  --secondary-color3-lighten-17percent: #ffe697;
  --secondary-color3-lighten-27percent: #fff2ca;
  --secondary-bright-color: #ece815;
  --error-color: #cc0000;
  --error-color-darken-10percent: #990000;
  --error-color-lighten-50percent: #ffcccc;
  --error-color-lighten-55percent: #ffe6e6;
  --error-light-color: #ffe6e6;
  --error-light-color-darken-5percent: #ffcdcd;
  --disabled-color: #abbcd5;
  --disabled-color2: #9badca;
  --disabled-color3: #a7d17a;
  --default-sprite-position: top left;
  --active-hover-sprite-position: top right;
  /*remaing colors*/
  --snow-gray: #fcfcfc;
  --light-gainsboro-gray: #f7f7f7;
  --white-smoke: #f4f4f4;
  --very-light-gray: #f0f0f0;
  --light-pink: #febbbb;
  --light-yellow: #ffffe5;
  --orange-peel: #ff9900;
  --electric-yellow: #ffff00;
  --yellow-green: #effadb;
  --whisper-gray: #ededed;
  --dark-whisper-gray: #eeeeee;
  --white-dark-smoke: #e6e6e6;
  --gainsboro-gray: #e0e0e0;
  --medium-gray: #cccccc;
  --silver: #c6c6c6;
  --silver-very-light: #b9b9b9;
  --silver-light: #bbbbbb;
  --blue-smoke: #eef1f7;
  --ivory-red: #f8f8f2;
  --spun-pearl: #afafb6;
  --very-light-blue: #e6ebf5;
  --dark-gainsboro-gray: #d9d9d9;
  --bright-gainsboro-gray: #dddddd;
  --hawakes-blue: #ccd6eb;
  --blue-violet: #9933ff;
  --nobel-gray: #999999;
  --light-danube-blue: #89a4cd;
  --matte-gray: #888888;
  --light-matte-gray: #787878;
  --dark-gray: #777777;
  --raven-gray: #707275;
  --danube-blue: #6685c2;
  --dim-gray: #666666;
  --davy-grey: #555555;
  --analog-blue: #435f8b;
  --charcoal-gray: #444444;
  --mariner-blue: #335cad;
  --dark-charcoal-gray: #333333;
  --green-waterloo: #23241f;
  --nero-dark: #222222;
  --smalt-blue: #003399;
  --deep-green: #008a00;
  --navy-blue: #0066cc;
  --bondi-blue: #0085c7;
  --violation-text: #cc0000;
  --violation-bg: #ffe6e6;
  --warning-text: #f68d29;
  --warning-bg: #fff2ca;
  --highcharts-color-2: #cddc29;
  --highcharts-color-3: #007299;
  --highcharts-color-6: #689834;
  --highcharts-color-7: #a6bad9;
}

[data-theme=fiserv] {
  --loading-login-screen-background: #666666;
  --loading-screen-logo: url(".././assets/images/Fiserv-logo-1200x603.png");
  --login-box-logo: url(".././assets/images/Fiserv-logo-1200x603.png");
  --primary-dark-color: #333333;
  --primary-color: #666666;
  --primary-color-darken-7percent: #545454;
  --primary-color-darken-11percent: #4a4a4a;
  --primary-color-lighten-10percent: gray;
  --primary-color-lighten-20percent: #999999;
  --primary-color-lighten-40percent: #cccccc;
  --primary-light1-color: #bfbfbf;
  --primary-light1-color-darken-5percent: #b2b2b2;
  --primary-light1-color-darken-10percent: #a6a6a6;
  --primary-light1-color-darken-15percent: #999999;
  --primary-light1-color-lighten-6percent: #cecece;
  --primary-white-color: #efefef;
  --primary-white-color-darken-2percent: #eaeaea;
  --primary-white-color-darken-6percent: #e0e0e0;
  --primary-white-color-lighten-2percent: #f4f4f4;
  --primary-white-color-lighten-4percent: #f9f9f9;
  --primary-color2: #4695d1;
  --primary-color3: #ff6600;
  --primary-color3-lighten-40percent: #ffe0cc;
  --primary-color3-darken-5percent: #e65c00;
  --primary-dark2-color: #ea6b15;
  --primary-dark2-color-darken-10percent: #bb5611;
  --primary-light2-color: #f2d3c4;
  --primary-light2-color-darken-2percent: #f0cdbc;
  --primary-light2-color-darken-6percent: #ecc0ab;
  --primary-light2-color-darken-10percent: #e9b49a;
  --grayscale-color: #666666;
  --grayscale-color-lighten-25percent: #a6a6a6;
  --grayscale-light-color: #e0e0e0;
  --grayscale-light-color-darken-8percent: #cccccc;
  --grayscale-light-color-lighten-7percent: #f2f2f2;
  --grayscale-white-color: #e0e0e0;
  --grayscale-white-color-darken-5percent: lightgray;
  --grayscale-white-color-darken-8percent: #cccccc;
  --grayscale-white-color-lighten-2percent: #e5e5e5;
  --grayscale-white-color-lighten-5percent: #ededed;
  --white-color: #ffffff;
  --black-color: #0e0e0e;
  --secondary-dark-color: #007299;
  --secondary-color: #77256c;
  --secondary-color2: #81bd41;
  --secondary-color3: #ffd240;
  --secondary-color3-darken-3percent: #ffce31;
  --secondary-color3-lighten-17percent: #ffe697;
  --secondary-color3-lighten-27percent: #fff2ca;
  --secondary-bright-color: #ece815;
  --error-color: #dd3435;
  --error-color-darken-10percent: #be2021;
  --error-color-lighten-50percent: white;
  --error-color-lighten-55percent: white;
  --error-light-color: #eabec0;
  --error-light-color-darken-5percent: #e4abad;
  --disabled-color: #c9c9c9;
  --disabled-color2: #c9c9c9;
  --disabled-color3: #dd9e73;
  --default-sprite-position: bottom left;
  --active-hover-sprite-position: bottom right;
  /*remaing colors*/
  --snow-gray: #fcfcfc;
  --light-gainsboro-gray: #f7f7f7;
  --white-smoke: #f4f4f4;
  --very-light-gray: #f0f0f0;
  --light-pink: #febbbb;
  --light-yellow: #ffffe5;
  --orange-peel: #ff9900;
  --electric-yellow: #ffff00;
  --yellow-green: #effadb;
  --whisper-gray: #ededed;
  --dark-whisper-gray: #eeeeee;
  --white-dark-smoke: #e6e6e6;
  --gainsboro-gray: #e0e0e0;
  --medium-gray: #cccccc;
  --silver: #c6c6c6;
  --silver-very-light: #b9b9b9;
  --silver-light: #bbbbbb;
  --blue-smoke: #eef1f7;
  --ivory-red: #f8f8f2;
  --spun-pearl: #afafb6;
  --very-light-blue: #e6ebf5;
  --dark-gainsboro-gray: #d9d9d9;
  --bright-gainsboro-gray: #dddddd;
  --hawakes-blue: #ccd6eb;
  --blue-violet: #9933ff;
  --nobel-gray: #999999;
  --light-danube-blue: #89a4cd;
  --matte-gray: #888888;
  --light-matte-gray: #787878;
  --dark-gray: #777777;
  --raven-gray: #707275;
  --danube-blue: #6685c2;
  --dim-gray: #666666;
  --davy-grey: #555555;
  --analog-blue: #435f8b;
  --charcoal-gray: #444444;
  --mariner-blue: #335cad;
  --dark-charcoal-gray: #333333;
  --green-waterloo: #23241f;
  --nero-dark: #222222;
  --smalt-blue: #003399;
  --deep-green: #008a00;
  --navy-blue: #0066cc;
  --bondi-blue: #0085c7;
  --violation-text: #cc0000;
  --violation-bg: #ffe6e6;
  --warning-text: #f68d29;
  --warning-bg: #fff2ca;
  --highcharts-color-2: #ffa366;
  --highcharts-color-3: #777777;
  --highcharts-color-6: #e65c00;
  --highcharts-color-7: #e0e0e0;
}

/*Sprite Positions*/
/*Images*/
/*Primary*/
/*Grayscale*/
/*Secondary*/
/*disabled*/
/*remaing colors*/
.darkblue {
  color: var(--primary-dark-color);
}

.blue {
  color: var(--primary-color);
}

.lightblue {
  color: var(--primary-light1-color);
}

.whiteblue {
  color: var(--primary-white-color);
}

.yellowgreen {
  color: var(--primary-color2);
}

.green {
  color: var(--primary-color3) !important;
}

.darkgreen {
  color: var(--primary-dark2-color);
}

.lightgreen {
  color: var(--primary-light2-color);
}

.gray {
  color: var(--grayscale-color);
}

.lightgray {
  color: var(--grayscale-light-color);
}

.whitegray {
  color: var(--grayscale-white-color);
}

.darkcyan {
  color: var(--secondary-dark-color);
}

.purple {
  color: var(--secondary-color);
}

.orange {
  color: var(--secondary-color2) !important;
}

.yellow {
  color: var(--secondary-color3);
}

.brightyellow {
  color: var(--secondary-bright-color);
}

.red {
  color: var(--error-color) !important;
}

.lightred {
  color: var(--error-light-color);
}

.white {
  color: var(--white-color) !important;
}

.darkblue-bg {
  background-color: var(--primary-dark-color);
}

.blue-bg {
  background-color: var(--primary-color) !important;
}

.lightblue-bg {
  background-color: var(--primary-light1-color);
}

.whiteblue-bg {
  background-color: var(--primary-white-color);
}

.yellowgreen-bg {
  background-color: var(--primary-color2);
}

.green-bg {
  background-color: var(--primary-color3);
}

.darkgreen-bg {
  background-color: var(--primary-dark2-color);
}

.lightgreen-bg {
  background-color: var(--primary-light2-color);
}

.gray-bg {
  background-color: var(--grayscale-color);
}

.lightgray-bg {
  background-color: var(--grayscale-light-color);
}

.whitegray-bg {
  background-color: var(--grayscale-white-color);
}

.darkcyan-bg {
  background-color: var(--secondary-dark-color);
}

.purple-bg {
  background-color: var(--secondary-color);
}

.orange-bg {
  background-color: var(--secondary-color2) !important;
}

.yellow-bg {
  background-color: var(--secondary-color3) !important;
}

.brightyellow-bg {
  background-color: var(--secondary-bright-color) !important;
}

.red-bg {
  background-color: var(--error-color) !important;
}

.lightred-bg {
  background-color: var(--error-light-color) !important;
}

.checkbox-title .form-section-header {
  position: relative;
}
.checkbox-title .form-section-header .btn-container {
  position: absolute;
  background-color: var(--white-color);
  left: 25px;
  top: 5px;
  padding-right: 10px;
  z-index: 10;
}

.form-section-header {
  position: relative;
}
.form-section-header h2, .form-section-header .h2, .form-section-header h3, .form-section-header .h3 {
  width: 100%;
  border-bottom: 1px solid var(--primary-light1-color);
  line-height: 0.1em;
  margin: 30px 0 30px;
  color: var(--primary-color);
}
.form-section-header h2 span, .form-section-header .h2 span, .form-section-header h3 span, .form-section-header .h3 span {
  background: var(--white-color);
  padding-right: 12px;
}
.form-section-header h2 i, .form-section-header .h2 i, .form-section-header h3 i, .form-section-header .h3 i {
  line-height: 0.1em;
}
.form-section-header h2 i:nth-of-type(2), .form-section-header .h2 i:nth-of-type(2), .form-section-header h3 i:nth-of-type(2), .form-section-header .h3 i:nth-of-type(2) {
  margin-left: 10px;
  font-size: 0.9375rem;
}
.form-section-header .btn-container {
  position: absolute;
  right: 40px;
  top: 14px;
  z-index: 10;
}
.form-section-header .btn-container .btn {
  position: static;
}
.form-section-header .btn-container .btn + .btn {
  margin-left: 10px;
}
.form-section-header .dropdown,
.form-section-header .btn {
  position: absolute;
  right: 40px;
  z-index: 10;
}
.form-section-header .dropdown:disabled,
.form-section-header .btn:disabled {
  opacity: 1;
}
.form-section-header .dropdown .btn {
  position: static;
}
s .form-section-header .dropdown-menu .checkbox-radio, s .form-section-header .dropdown-menu .checkbox-radio-no-margin {
  margin-left: 10px;
  margin-right: 10px;
}
.form-section-header a.form-section-toggle {
  cursor: pointer;
}
.form-section-header a.form-section-toggle .fa {
  margin-right: 10px;
}
.form-section-header .bdf-icon {
  display: inline;
}
.form-section-header .bdf-icon:before {
  font-family: "FontAwesome", sans-serif;
  content: "\f19c";
  color: var(--primary-color3);
  font-size: 0.875rem;
}
.form-section-header.plus-checkbox {
  width: calc(100% - 30px);
}
.form-section-header.plus-checkbox h3, .form-section-header.plus-checkbox .h3 {
  margin-left: 30px;
}
.form-section-header.plus-checkbox .checkbox {
  position: absolute;
  top: 12px;
}
.form-section-header .btn-sm, .form-section-header .btn-group-sm > .btn {
  top: 14px;
}

.header-checkbox-container {
  position: absolute;
  right: 40px;
  top: 12px;
  z-index: 10;
  background-color: white;
  border: 1px solid var(--primary-light1-color);
  padding: 0 15px;
  border-radius: 4px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.08), 0 1px 1px rgba(0, 0, 0, 0.16);
}
.header-checkbox-container .header-checkbox {
  padding: 0;
}

.toggle-section {
  width: 100%;
}

.form-group {
  margin-bottom: 17px;
}

.dropdown-menu .form-control-sm {
  margin-bottom: 12px;
}

.form-control-sm .form-control {
  font-size: 0.875rem;
  min-height: 30px;
  padding: 2px 10px 0;
  border-radius: 0;
  line-height: 2;
}
.form-control-sm .input-group-btn .btn {
  font-size: 1.125rem;
  width: 32px;
  height: 30px;
}
.form-control-sm select.form-control {
  line-height: normal;
}

label {
  color: var(--grayscale-color);
  font-size: 0.875rem;
  font-weight: normal;
  width: 100%;
  margin-bottom: 0;
  position: relative;
}

.datepicker-label {
  position: relative;
  display: inline-block;
  width: 100%;
}

.form-control {
  height: auto;
}

.form-control,
.input-span,
.info-span {
  border: 1px solid var(--grayscale-light-color);
  border-bottom: 3px solid var(--grayscale-light-color);
  box-shadow: none;
  border-radius: 0;
  font-size: 0.9625rem;
  min-height: 36px;
  line-height: 26px;
  padding: 4px 12px 0;
  transition: none;
}
.form-control#listbox,
.input-span#listbox,
.info-span#listbox {
  border-right-width: 2px;
}
.form-control:focus,
.input-span:focus,
.info-span:focus {
  border-color: var(--primary-color);
  box-shadow: none;
}

.clear-field-btn .btn-unstyled {
  position: absolute;
  padding: 4px;
  right: 50px;
  bottom: 4px;
  z-index: 5;
  background-color: white;
}
.clear-field-btn:after {
  font-family: "FontAwesome", sans-serif;
  content: "\f00d";
  font-size: 0.9625rem;
  color: var(--primary-color);
}
.clear-field-btn:hover::after, .clear-field-btn:focus::after {
  color: var(--primary-color3);
}
.clear-field-btn.dropdown {
  right: 35px;
}

.select-field > .clear-field-btn {
  right: 60px;
}

/*Text on sides of control - dont use no-padding's*/
.sidetext-control {
  display: table-cell;
  width: 100%;
}
.sidetext-control .form-control {
  float: none;
}

.sidetext-before {
  display: table-cell;
  padding-right: 30px;
  white-space: nowrap;
  vertical-align: middle;
}

.sidetext-after {
  display: table-cell;
  padding-left: 30px;
  white-space: nowrap;
  vertical-align: middle;
}

/* A2 margin*/
label .form-control,
.form-group .form-control {
  margin-top: 4px;
}

/*Add space for label if there isnt one - Add to column div*/
.no-label .form-control,
.no-label label .form-control,
.no-label .form-group .form-control,
.no-label-spacing .form-control,
.no-label-spacing label .form-control,
.no-label-spacing .form-group .form-control {
  margin-top: 0;
}

.no-label {
  padding-top: 24px;
}

bh-date-with-days .no-label, .no-label-inline {
  padding-top: 19px;
}

::-webkit-input-placeholder {
  opacity: 0.5;
}

:-moz-placeholder {
  opacity: 0.5;
}

::-moz-placeholder {
  opacity: 0.5;
}

:-ms-input-placeholder {
  opacity: 0.5 !important;
}

.form-control:focus {
  box-shadow: none;
}

.form-control[disabled], fieldset[disabled] .form-control,
.form-control[readonly], fieldset[readonly] .form-control,
.info-span,
.input-span {
  background-color: var(--grayscale-white-color);
}

.form-control[disabled], fieldset[disabled] .form-control {
  cursor: not-allowed;
}

.info-span,
.input-span {
  border-color: var(--grayscale-white-color);
}

.form-control.readonly-white[readonly] {
  background-color: white;
}
.form-control.readonly-white[readonly][disabled] {
  background-color: var(--grayscale-white-color);
}

select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  text-indent: 1px;
  text-overflow: "";
  cursor: pointer;
}
select::-ms-expand { /* for IE 11 */
  display: none;
}

input.dropdown-table {
  cursor: pointer;
}

textarea {
  resize: vertical;
}
textarea.form-control {
  min-height: 90px;
}

.checkbox-radio, .checkbox-radio-no-margin {
  position: relative;
  display: inline-block;
  margin: 8px 0 !important;
  padding-left: 32px;
  cursor: pointer;
  width: initial;
  min-height: 20px;
}
.checkbox-radio.radio, .radio.checkbox-radio-no-margin {
  pointer-events: auto;
}

.checkbox-radio-no-margin {
  margin: 0 !important;
}

bh-r-radio.inline, bh-r-radio.inline div, bh-r-radio .inline-radio, bh-r-radio.inline-radio, bh-r-radio.inline-checkbox,
bh-r-checkbox.inline,
bh-r-checkbox.inline div,
bh-r-checkbox .inline-radio,
bh-r-checkbox.inline-radio,
bh-r-checkbox.inline-checkbox,
div.inline,
div.inline div,
div .inline-radio,
div.inline-radio,
div.inline-checkbox {
  display: inline-block;
  margin-right: 20px;
  width: auto;
}

.in-header-input .form-group {
  margin-bottom: 0;
}

.checkbox-radio input, .checkbox-radio-no-margin input {
  position: absolute;
  left: 3px;
  bottom: 4px;
  z-index: -1;
  opacity: 0;
  margin-left: 0 !important;
}

.checkbox-radio-indicator {
  position: absolute;
  top: -1px;
  left: 0;
  width: 22px;
  height: 22px;
  background: white;
  border: 1px solid var(--grayscale-light-color-darken-8percent);
  border-radius: 3px;
}

.radio .checkbox-radio-indicator {
  border-radius: 50%;
}

.checkbox-radio:hover input ~ .checkbox-radio-indicator, .checkbox-radio-no-margin:hover input ~ .checkbox-radio-indicator,
.checkbox-radio input:focus ~ .checkbox-radio-indicator,
.checkbox-radio-no-margin input:focus ~ .checkbox-radio-indicator {
  background: var(--primary-white-color);
}

.checkbox-radio input:focus ~ .checkbox-radio-indicator, .checkbox-radio-no-margin input:focus ~ .checkbox-radio-indicator {
  background: var(--primary-white-color);
  box-shadow: 0 0 0 2px var(--primary-color3);
}

.checkbox-radio input:checked ~ .checkbox-radio-indicator, .checkbox-radio-no-margin input:checked ~ .checkbox-radio-indicator {
  background: var(--primary-color);
}

.checkbox-radio:hover input:not([disabled]):checked ~ .checkbox-radio-indicator, .checkbox-radio-no-margin:hover input:not([disabled]):checked ~ .checkbox-radio-indicator,
.checkbox-radio input:checked:focus ~ .checkbox-radio-indicator,
.checkbox-radio-no-margin input:checked:focus ~ .checkbox-radio-indicator {
  background: var(--primary-color-darken-7percent);
}

.checkbox-radio input:disabled ~ .checkbox-radio-indicator, .checkbox-radio-no-margin input:disabled ~ .checkbox-radio-indicator {
  cursor: not-allowed;
  opacity: 0.6;
  background: var(--grayscale-white-color);
}

.checkbox-radio input:disabled:checked ~ .checkbox-radio-indicator, .checkbox-radio-no-margin input:disabled:checked ~ .checkbox-radio-indicator {
  background: var(--primary-color);
}

.checkbox-radio-indicator:after {
  position: absolute;
  display: none;
  content: "";
}

.checkbox-radio input:checked ~ .checkbox-radio-indicator:after, .checkbox-radio-no-margin input:checked ~ .checkbox-radio-indicator:after {
  display: block;
}

.checkbox .checkbox-radio-indicator:after {
  top: 10%;
  right: 10%;
  font-family: "FontAwesome", sans-serif;
  content: "\f00c";
  color: white;
  font-size: 0.9625rem;
}

.checkbox input.indeterminate ~ .checkbox-radio-indicator:after {
  display: block;
  top: -1px;
  left: 3px;
  font-family: "FontAwesome", sans-serif;
  content: "\f068";
  color: var(--primary-color);
  font-weight: bold;
}

.radio .checkbox-radio-indicator:after {
  top: 5px;
  left: 5px;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: var(--white-color);
}

/*Spacing for checkboxes*/
bh-r-checkbox,
.checkbox-container {
  display: block;
}
bh-r-checkbox:first-of-type,
.checkbox-container:first-of-type {
  padding-top: 24px;
}
bh-r-checkbox:last-of-type,
.checkbox-container:last-of-type {
  padding-bottom: 17px;
}

td bh-r-checkbox:last-of-type {
  padding-bottom: 8px;
}

bh-r-checkbox.no-label {
  padding-top: 0;
  margin-top: 0;
}

bh-r-checkbox:first-of-type .checkbox-radio, bh-r-checkbox:first-of-type .checkbox-radio-no-margin {
  margin-top: 0;
}

.checkbox-selectall {
  border-bottom: 1px solid var(--grayscale-light-color);
  margin: 15px 0 5px;
  padding-bottom: 10px;
  font-weight: bold;
  color: var(--primary-color);
}

a:hover.checkbox-radio.checkbox, a.checkbox-radio-no-margin:hover.checkbox {
  color: var(--primary-dark-color);
}

a.checkbox-radio.checkbox, a.checkbox.checkbox-radio-no-margin {
  text-decoration: none;
}

span.radio-label,
span.checkbox-label {
  display: block;
  margin-bottom: 4px;
}

/*Date with options Control*/
bh-r-radio.date-options div,
.bh-r-radio.date-options div {
  margin-left: 10px;
  display: inline-block;
}
bh-r-radio.date-options div label,
.bh-r-radio.date-options div label {
  margin: 0;
  padding-left: 25px;
  font-size: 0.8125rem;
}
bh-r-radio.date-options div label .checkbox-radio-indicator,
.bh-r-radio.date-options div label .checkbox-radio-indicator {
  top: -1px;
  width: 18px;
  height: 18px;
}
bh-r-radio.date-options div label .checkbox-radio-indicator:after,
.bh-r-radio.date-options div label .checkbox-radio-indicator:after {
  top: 3px;
  left: 3px;
}

bh-r-date.date-options,
bh-r-dropdown.date-options,
bh-r-search-and-select.date-options,
.bh-r-date.date-options,
.bh-r-dropdown.date-options,
.bh-r-search-and-select.date-options {
  display: block;
  margin-top: -17px;
}

/*Date Field bootstrap overrides*/
@media screen and (-webkit-min-device-pixel-ratio: 0) {
  input[type=date].form-control,
  input[type=time].form-control,
  input[type=datetime-local].form-control,
  input[type=month].form-control,
  input[type=date].input-sm,
  input[type=time].input-sm,
  input[type=datetime-local].input-sm,
  input[type=month].input-sm,
  .input-group-sm input[type=date],
  .input-group-sm input[type=time],
  .input-group-sm input[type=datetime-local],
  .input-group-sm input[type=month],
  input[type=date].input-lg,
  input[type=time].input-lg,
  input[type=datetime-local].input-lg,
  input[type=month].input-lg,
  .input-group-lg input[type=date],
  .input-group-lg input[type=time],
  .input-group-lg input[type=datetime-local],
  .input-group-lg input[type=month] {
    line-height: normal;
  }
}
/*Number range*/
span.number-range {
  float: left;
}
span.number-range input {
  width: 40px;
  height: 32px;
  text-align: center;
  border: 1px solid var(--grayscale-light-color);
  margin: 0 3px;
}
span.number-range input:focus {
  border: 1px solid var(--primary-color);
  outline: none;
}

/*Range Input*/
input[type=range] {
  -webkit-appearance: none;
  width: 100%;
  height: 18px;
  margin: 6px 0;
}

input[type=range]:focus {
  outline: none;
}

input[type=range]::-webkit-slider-runnable-track {
  width: 100%;
  height: 6px;
  cursor: pointer;
  background: var(--grayscale-white-color);
}

input[type=range]::-webkit-slider-thumb {
  height: 18px;
  width: 10px;
  background: var(--primary-color);
  cursor: pointer;
  -webkit-appearance: none;
  margin-top: -6px;
}

input[type=range]:focus::-webkit-slider-runnable-track {
  background: var(--primary-white-color);
}

input[type=range]::-moz-range-track {
  width: 100%;
  height: 6px;
  cursor: pointer;
  background: var(--grayscale-white-color);
}

input[type=range]::-moz-range-thumb {
  height: 18px;
  width: 10px;
  background: var(--primary-color);
  cursor: pointer;
}

input[type=range]::-ms-track {
  width: 100%;
  height: 6px;
  cursor: pointer;
  background: transparent;
  border-color: transparent;
  color: transparent;
}

input[type=range]::-ms-fill-lower {
  background: var(--grayscale-white-color-darken-5percent);
}

input[type=range]::-ms-fill-upper {
  background: var(--grayscale-white-color);
}

input[type=range]::-ms-thumb {
  height: 18px;
  width: 10px;
  background: var(--primary-color);
  cursor: pointer;
  margin-top: 2px;
}

input[type=range]:focus::-ms-fill-lower {
  background: var(--primary-white-color-darken-6percent);
}

input[type=range]:focus::-ms-fill-upper {
  background: var(--primary-white-color);
}

/*ng2 DateTime Picker*/
ng2-datetime-picker {
  width: 234px !important;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  border: 1px solid var(--primary-color);
}

.datepicker-label.disable-dropdown ng2-datetime-picker {
  display: none;
}

.ng2-datetime-picker {
  color: var(--grayscale-color) !important;
}
.ng2-datetime-picker > .month {
  background-color: var(--primary-color) !important;
  color: white !important;
  line-height: 20px !important;
}
.ng2-datetime-picker > .month > .prev_next {
  color: var(--primary-light1-color) !important;
  position: relative;
  top: -6px;
  width: 20px !important;
  font-size: 1.6875rem !important;
}
.ng2-datetime-picker > .month > .prev_next:hover, .ng2-datetime-picker > .month > .prev_next:focus {
  background-color: white !important;
  color: var(--primary-color) !important;
}
.ng2-datetime-picker > .days {
  margin-top: 5px !important;
}
.ng2-datetime-picker > .days .day-of-week, .ng2-datetime-picker > .days .day-of-week.weekend {
  color: var(--primary-color) !important;
}
.ng2-datetime-picker > .days .day, .ng2-datetime-picker > .days .day.weekend {
  color: var(--grayscale-color) !important;
  line-height: 26px !important;
}
.ng2-datetime-picker > .days .day:not(.selected).selectable:hover, .ng2-datetime-picker > .days .day.weekend:not(.selected).selectable:hover {
  background: var(--primary-color) !important;
  color: white !important;
}
.ng2-datetime-picker > .days .day:not(.selectable) {
  color: var(--grayscale-white-color-darken-8percent) !important;
}
.ng2-datetime-picker > .days .day.weekend {
  background-color: var(--grayscale-white-color-lighten-2percent) !important;
}
.ng2-datetime-picker > .days .day.selected, .ng2-datetime-picker > .days .day.selected:hover, .ng2-datetime-picker > .days .day.selected:focus {
  background: var(--primary-color3) !important;
  color: white !important;
}
.ng2-datetime-picker #time {
  margin: 0 !important;
  padding: 10px;
  border-top: 1px solid var(--grayscale-light-color);
  width: 230px;
}
.ng2-datetime-picker #time .timeLabel {
  width: auto !important;
  margin-right: 3px;
  margin-bottom: 7px !important;
}
.ng2-datetime-picker #time .timeValue {
  color: var(--primary-color3);
  font-weight: bold;
}
.ng2-datetime-picker #time .hourLabel, .ng2-datetime-picker #time .minutesLabel {
  float: left;
  margin: 6px 0 !important;
}
.ng2-datetime-picker #time input[type=range] {
  float: right;
  width: 160px !important;
}

/* Form Buttons with Icons */
.input-group {
  display: inline-table;
}

.input-wrap {
  display: table;
}

.input-group-btn,
.input-group-append {
  vertical-align: bottom;
}
.input-group-btn .btn,
.input-group-append .btn {
  font-size: 1.25rem;
  padding: 0;
  width: 38px;
  height: 36px;
  border-radius: 0;
  border-bottom: 3px solid var(--primary-color-darken-7percent);
}
.input-group-btn .btn.fa-caret-down, .input-group-btn .btn.fa-caret-up,
.input-group-append .btn.fa-caret-down,
.input-group-append .btn.fa-caret-up {
  font-size: 1.625rem;
}
.input-group-btn .btn.fa-plus,
.input-group-append .btn.fa-plus {
  padding-top: 2px;
}
.input-group-btn .dropdown-menu,
.input-group-append .dropdown-menu {
  left: auto;
  right: 0;
  padding: 15px 10px 10px;
  margin-top: 0;
}

.input-group-append {
  padding: 0;
  background: none;
  border: none;
}
.input-group-append .dropdown-menu {
  width: 100%;
}
.input-group-append .dropdown-menu label {
  float: left;
}
.input-group-append.textarea .btn {
  height: 100%;
  min-height: 90px;
}

.ties-search-dropdown label,
.ties-search-dropdown label .form-control {
  margin: 0;
}

.iconpicker-icon {
  margin-right: 12px;
  font-weight: normal;
  height: 18px;
}

.icon-right + span,
.icon-left + span,
.ng2-datetime-picker-wrapper + span {
  font-size: 1.25rem;
  color: var(--primary-color);
  z-index: 50;
  position: relative;
  pointer-events: none;
}
.icon-right + span.fa-caret-down, .icon-right + span.fa-caret-up,
.icon-left + span.fa-caret-down,
.icon-left + span.fa-caret-up,
.ng2-datetime-picker-wrapper + span.fa-caret-down,
.ng2-datetime-picker-wrapper + span.fa-caret-up {
  font-size: 1.625rem;
  bottom: 5px;
}
.icon-right + span.fa-check, .icon-right + span.fa-envelope-o, .icon-right + span.fa-unlock-alt,
.icon-left + span.fa-check,
.icon-left + span.fa-envelope-o,
.icon-left + span.fa-unlock-alt,
.ng2-datetime-picker-wrapper + span.fa-check,
.ng2-datetime-picker-wrapper + span.fa-envelope-o,
.ng2-datetime-picker-wrapper + span.fa-unlock-alt {
  font-size: 1.125rem;
  bottom: 9px;
}
.icon-right + span.fa-usd, .icon-right + span.fa-share, .icon-right + span.fa-exclamation-triangle, .icon-right + span.fa-phone, .icon-right + span.fa-globe,
.icon-left + span.fa-usd,
.icon-left + span.fa-share,
.icon-left + span.fa-exclamation-triangle,
.icon-left + span.fa-phone,
.icon-left + span.fa-globe,
.ng2-datetime-picker-wrapper + span.fa-usd,
.ng2-datetime-picker-wrapper + span.fa-share,
.ng2-datetime-picker-wrapper + span.fa-exclamation-triangle,
.ng2-datetime-picker-wrapper + span.fa-phone,
.ng2-datetime-picker-wrapper + span.fa-globe {
  font-size: 1.0625rem;
  bottom: 10px;
}
.icon-right + span.fa-percent,
.icon-left + span.fa-percent,
.ng2-datetime-picker-wrapper + span.fa-percent {
  font-size: 1rem;
  top: 13px;
}

.input-container {
  width: 100%;
  position: relative;
}

.icon-right {
  padding-right: 32px !important;
}
.icon-right + span {
  position: absolute;
  right: 10px;
}

bh-mega-picker .icon-right + span, .select-input .icon-right + span {
  top: unset;
}

.icon-left {
  padding-left: 32px !important;
}
.icon-left + span {
  position: absolute;
  left: 10px;
  bottom: 8px;
}

.ng2-datetime-picker-wrapper .icon-right {
  padding-right: 42px;
}
.ng2-datetime-picker-wrapper + span {
  position: absolute;
  right: 10px;
  bottom: 8px;
}

.form-control-sm .icon-right + span,
.form-control-sm .icon-left + span,
.form-control-sm .ng2-datetime-picker-wrapper + span {
  font-size: 0.875rem;
}
.form-control-sm .icon-right + span.fa-caret-down, .form-control-sm .icon-right + span.fa-caret-up,
.form-control-sm .icon-left + span.fa-caret-down,
.form-control-sm .icon-left + span.fa-caret-up,
.form-control-sm .ng2-datetime-picker-wrapper + span.fa-caret-down,
.form-control-sm .ng2-datetime-picker-wrapper + span.fa-caret-up {
  font-size: 1.375rem;
}
.form-control-sm .icon-right,
.form-control-sm .ng2-datetime-picker-wrapper {
  padding-right: 28px !important;
}
.form-control-sm .icon-left {
  padding-left: 28px !important;
}
.form-control-sm .fa {
  margin: 0 !important;
  padding: 0 !important;
}

bh-r-name .dropdown-menu .fa-caret-down {
  top: 31px;
  right: 16px;
}

.hide-icon .icon-right,
.hide-icon .icon-left {
  padding: 4px 12px 0 !important;
}
.hide-icon .icon-right + span,
.hide-icon .icon-left + span {
  display: none;
}

.primary-icon:before {
  font-family: "FontAwesome", sans-serif;
  content: "\f00c";
  color: var(--primary-color3);
  font-size: 0.875rem;
  margin-left: 5px;
  line-height: normal;
}

.info-icon:before {
  font-family: "FontAwesome", sans-serif;
  content: "\f05a";
  color: var(--primary-color);
  font-size: 1rem;
  margin-left: 8px;
  line-height: normal;
}

.form-box-container {
  border: 1px solid var(--grayscale-light-color);
  padding-top: 15px;
  margin: 0 0 15px 0;
}

.form-success {
  background-color: var(--primary-light2-color) !important;
}

.form-error {
  background-color: var(--error-light-color) !important;
}

.form-warning {
  background-color: var(--secondary-color3-lighten-27percent) !important;
}

.bdf-icon:before {
  font-family: "FontAwesome", sans-serif;
  content: "\f19c";
  color: var(--primary-color3);
  margin-left: 8px;
}

.required-icon:before {
  font-family: "FontAwesome", sans-serif;
  content: "\f069";
  color: var(--error-color);
  font-size: 0.5rem;
  margin-left: 5px;
  position: relative;
  bottom: 4px;
}

.errorMessage {
  display: none;
  color: var(--error-color);
  background-color: var(--error-light-color);
  border: 1px solid var(--error-color);
  width: 100%;
  padding: 3px 8px;
  font-size: 0.75rem;
  border-radius: 3px;
  z-index: 1000;
  margin-top: 5px;
  font-weight: bold;
  position: relative;
  left: 0;
  line-height: 1.428571429;
}
.errorMessage p {
  margin: 0;
}
.errorMessage p i {
  font-weight: normal;
  font-size: 0.875rem;
  margin-right: 7px;
}

.form-control.ng-dirty:focus ~ .errorMessage {
  display: inline-block;
}

.wj-control.ng-dirty.wj-state-focused ~ .errorMessage {
  display: block;
}

.datepicker-label.focus .errorMessage {
  display: inline-block;
}

.force-errorMessage .form-control.ng-dirty ~ .errorMessage {
  display: inline-block;
}

.ng-valid,
.ng-valid .wj-form-control {
  border-color: var(--grayscale-light-color);
}

.form-control:not(.parsed-input).ng-valid:not(.ng-invalid).ng-dirty:focus,
.ng-valid.ng-dirty + input:focus,
.wj-control.ng-valid.ng-dirty.wj-state-focused .wj-form-control {
  border-color: var(--primary-color3);
}

.ng-invalid,
.ng-invalid .wj-form-control {
  border-color: var(--grayscale-light-color);
}
.ng-invalid.ng-touched, .ng-invalid.ng-touched + input,
.ng-invalid .wj-form-control.ng-touched,
.ng-invalid .wj-form-control.ng-touched + input {
  border-color: var(--error-color);
}

.wj-control.ng-invalid.ng-dirty .wj-form-control,
.wj-control.ng-invalid.wj-state-focused .wj-form-control {
  border-color: var(--error-color);
}

.form-control.ng-invalid:focus,
.ng-invalid + input:focus {
  border-color: var(--error-color);
}

.header-panel .form-control.ng-valid.ng-dirty:focus,
table .form-control.ng-valid.ng-dirty:focus {
  border-color: var(--grayscale-light-color);
}

input:-webkit-autofill,
input:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0 1000px white inset;
  box-shadow: 0 0 0 1000px white inset;
}

.zoom-layout-fix div[class^=col-] {
  height: 77px;
}

.pointer {
  cursor: pointer;
}

.input-group .form-control {
  position: relative;
  z-index: 2;
  float: left;
  margin-bottom: 0;
}

.input-group-append {
  margin-top: 4px;
  border-top-right-radius: 0;
  border-bottom-left-radius: 0;
}

.force-errorMessage .editor-label .ng-dirty ~ .errorMessage {
  display: inline-block;
}

.filter .form-control {
  max-width: 192px;
}
.filter .form-control-sm {
  padding-right: 0;
}

bh-booking-v2 .ng-invalid,
bh-booking-v2 .ng-invalid .wj-form-control {
  border-color: var(--error-color);
}

/* Old Colors

$color0: #ededed; // white gray
$color1: rgb(190,190,190); // #bebebe - light gray
$color2: rgb(80,80,80); // #505050 - gray
$color3: rgb(207,214,224); // #cfd6e0 - white blue
$color4: rgb(159,172,193); // #9facc1 - light blue
$color5: rgb(88,111,146); // #586f92 - mid blue
$color6: rgb(15,48,99); // #1B3D6D - dark blue
$color7: rgb(57,213,31); // #39d51f - green
$color8: rgb(52,197,26); // #34C51A - dark green
*/
/*Theming*/
:root {
  --loading-login-screen-background:
          linear-gradient(to right, rgba(15, 48, 99, 0) 0%, rgba(75, 107, 155, .4) 25%, rgba(168, 187, 215, .4) 42%, rgba(168, 187, 215, .4) 58%, rgba(75, 107, 155, .4) 75%, rgba(15, 48, 99, 0) 100%),
          url(".././assets/images/pattern-block-lines.png") repeat;
  --loading-screen-logo: url(".././assets/images/bakerhill-nextgen-logo-white.png");
  --login-box-logo: url(".././assets/images/bakerhill-nextgen-logo.png");
  --primary-dark-color: #1B3D6D;
  --primary-color: #456aa1;
  --primary-color-darken-7percent: #3a5a88;
  --primary-color-darken-11percent: #34507a;
  --primary-color-lighten-10percent: #5f84ba;
  --primary-color-lighten-20percent: #829fca;
  --primary-color-lighten-40percent: #cad6e8;
  --primary-light1-color: #a6bad9;
  --primary-light1-color-darken-5percent:#94acd1;
  --primary-light1-color-darken-10percent: #829eca;
  --primary-light1-color-darken-15percent: #7090c2;
  --primary-light1-color-lighten-6percent: #bbcbe2;
  --primary-white-color: #e6ecf2;
  --primary-white-color-darken-2percent: #dfe7ef;
  --primary-white-color-darken-6percent: #d2dde8;
  --primary-white-color-lighten-2percent: #edf1f5;
  --primary-white-color-lighten-4percent: #f3f6f9;
  --primary-color2: #cddc29;
  --primary-color3: #81BD41;
  --primary-color3-lighten-40percent: #e5f2d8;
  --primary-color3-darken-5percent: #74aa3a;
  --primary-dark2-color: #689834;
  --primary-dark2-color-darken-10percent: #4e7227;
  --primary-light2-color: #e6f2d9;
  --primary-light2-color-darken-2percent: #e1efd1;
  --primary-light2-color-darken-6percent: #d7eac2;
  --primary-light2-color-darken-10percent: #cde5b3;
  --grayscale-color: #58595B;
  --grayscale-color-lighten-25percent: #98999b;
  --grayscale-light-color: #bdbdc2;
  --grayscale-light-color-darken-8percent: #a8a8ae;
  --grayscale-light-color-lighten-7percent: #d0d0d3;
  --grayscale-white-color: #ebebed;
  --grayscale-white-color-darken-5percent: #dedee1;
  --grayscale-white-color-darken-8percent: #d6d6da;
  --grayscale-white-color-lighten-2percent: #f0f0f2;
  --grayscale-white-color-lighten-5percent: #f8f8f9;
  --white-color: #ffffff;
  --black-color: #000000;
  --secondary-dark-color: #007299;
  --secondary-color: #77256c;
  --secondary-color2: #f68d29;
  --secondary-color3: #ffd240;
  --secondary-color3-darken-3percent: #ffce31;
  --secondary-color3-lighten-17percent: #ffe697;
  --secondary-color3-lighten-27percent: #fff2ca;
  --secondary-bright-color: #ece815;
  --error-color: #cc0000;
  --error-color-darken-10percent: #990000;
  --error-color-lighten-50percent: #ffcccc;
  --error-color-lighten-55percent: #ffe6e6;
  --error-light-color: #ffe6e6;
  --error-light-color-darken-5percent: #ffcdcd;
  --disabled-color: #abbcd5;
  --disabled-color2: #9badca;
  --disabled-color3: #a7d17a;
  --default-sprite-position: top left;
  --active-hover-sprite-position: top right;
  /*remaing colors*/
  --snow-gray: #fcfcfc;
  --light-gainsboro-gray: #f7f7f7;
  --white-smoke: #f4f4f4;
  --very-light-gray: #f0f0f0;
  --light-pink: #febbbb;
  --light-yellow: #ffffe5;
  --orange-peel: #ff9900;
  --electric-yellow: #ffff00;
  --yellow-green: #effadb;
  --whisper-gray: #ededed;
  --dark-whisper-gray: #eeeeee;
  --white-dark-smoke: #e6e6e6;
  --gainsboro-gray: #e0e0e0;
  --medium-gray: #cccccc;
  --silver: #c6c6c6;
  --silver-very-light: #b9b9b9;
  --silver-light: #bbbbbb;
  --blue-smoke: #eef1f7;
  --ivory-red: #f8f8f2;
  --spun-pearl: #afafb6;
  --very-light-blue: #e6ebf5;
  --dark-gainsboro-gray: #d9d9d9;
  --bright-gainsboro-gray: #dddddd;
  --hawakes-blue: #ccd6eb;
  --blue-violet: #9933ff;
  --nobel-gray: #999999;
  --light-danube-blue: #89a4cd;
  --matte-gray: #888888;
  --light-matte-gray: #787878;
  --dark-gray: #777777;
  --raven-gray: #707275;
  --danube-blue: #6685c2;
  --dim-gray: #666666;
  --davy-grey: #555555;
  --analog-blue: #435f8b;
  --charcoal-gray: #444444;
  --mariner-blue: #335cad;
  --dark-charcoal-gray: #333333;
  --green-waterloo: #23241f;
  --nero-dark: #222222;
  --smalt-blue: #003399;
  --deep-green: #008a00;
  --navy-blue: #0066cc;
  --bondi-blue: #0085c7;
  --violation-text: #cc0000;
  --violation-bg: #ffe6e6;
  --warning-text: #f68d29;
  --warning-bg: #fff2ca;
  --highcharts-color-2: #cddc29;
  --highcharts-color-3: #007299;
  --highcharts-color-6: #689834;
  --highcharts-color-7: #a6bad9;
}

[data-theme=fiserv] {
  --loading-login-screen-background: #666666;
  --loading-screen-logo: url(".././assets/images/Fiserv-logo-1200x603.png");
  --login-box-logo: url(".././assets/images/Fiserv-logo-1200x603.png");
  --primary-dark-color: #333333;
  --primary-color: #666666;
  --primary-color-darken-7percent: #545454;
  --primary-color-darken-11percent: #4a4a4a;
  --primary-color-lighten-10percent: gray;
  --primary-color-lighten-20percent: #999999;
  --primary-color-lighten-40percent: #cccccc;
  --primary-light1-color: #bfbfbf;
  --primary-light1-color-darken-5percent: #b2b2b2;
  --primary-light1-color-darken-10percent: #a6a6a6;
  --primary-light1-color-darken-15percent: #999999;
  --primary-light1-color-lighten-6percent: #cecece;
  --primary-white-color: #efefef;
  --primary-white-color-darken-2percent: #eaeaea;
  --primary-white-color-darken-6percent: #e0e0e0;
  --primary-white-color-lighten-2percent: #f4f4f4;
  --primary-white-color-lighten-4percent: #f9f9f9;
  --primary-color2: #4695d1;
  --primary-color3: #ff6600;
  --primary-color3-lighten-40percent: #ffe0cc;
  --primary-color3-darken-5percent: #e65c00;
  --primary-dark2-color: #ea6b15;
  --primary-dark2-color-darken-10percent: #bb5611;
  --primary-light2-color: #f2d3c4;
  --primary-light2-color-darken-2percent: #f0cdbc;
  --primary-light2-color-darken-6percent: #ecc0ab;
  --primary-light2-color-darken-10percent: #e9b49a;
  --grayscale-color: #666666;
  --grayscale-color-lighten-25percent: #a6a6a6;
  --grayscale-light-color: #e0e0e0;
  --grayscale-light-color-darken-8percent: #cccccc;
  --grayscale-light-color-lighten-7percent: #f2f2f2;
  --grayscale-white-color: #e0e0e0;
  --grayscale-white-color-darken-5percent: lightgray;
  --grayscale-white-color-darken-8percent: #cccccc;
  --grayscale-white-color-lighten-2percent: #e5e5e5;
  --grayscale-white-color-lighten-5percent: #ededed;
  --white-color: #ffffff;
  --black-color: #0e0e0e;
  --secondary-dark-color: #007299;
  --secondary-color: #77256c;
  --secondary-color2: #81bd41;
  --secondary-color3: #ffd240;
  --secondary-color3-darken-3percent: #ffce31;
  --secondary-color3-lighten-17percent: #ffe697;
  --secondary-color3-lighten-27percent: #fff2ca;
  --secondary-bright-color: #ece815;
  --error-color: #dd3435;
  --error-color-darken-10percent: #be2021;
  --error-color-lighten-50percent: white;
  --error-color-lighten-55percent: white;
  --error-light-color: #eabec0;
  --error-light-color-darken-5percent: #e4abad;
  --disabled-color: #c9c9c9;
  --disabled-color2: #c9c9c9;
  --disabled-color3: #dd9e73;
  --default-sprite-position: bottom left;
  --active-hover-sprite-position: bottom right;
  /*remaing colors*/
  --snow-gray: #fcfcfc;
  --light-gainsboro-gray: #f7f7f7;
  --white-smoke: #f4f4f4;
  --very-light-gray: #f0f0f0;
  --light-pink: #febbbb;
  --light-yellow: #ffffe5;
  --orange-peel: #ff9900;
  --electric-yellow: #ffff00;
  --yellow-green: #effadb;
  --whisper-gray: #ededed;
  --dark-whisper-gray: #eeeeee;
  --white-dark-smoke: #e6e6e6;
  --gainsboro-gray: #e0e0e0;
  --medium-gray: #cccccc;
  --silver: #c6c6c6;
  --silver-very-light: #b9b9b9;
  --silver-light: #bbbbbb;
  --blue-smoke: #eef1f7;
  --ivory-red: #f8f8f2;
  --spun-pearl: #afafb6;
  --very-light-blue: #e6ebf5;
  --dark-gainsboro-gray: #d9d9d9;
  --bright-gainsboro-gray: #dddddd;
  --hawakes-blue: #ccd6eb;
  --blue-violet: #9933ff;
  --nobel-gray: #999999;
  --light-danube-blue: #89a4cd;
  --matte-gray: #888888;
  --light-matte-gray: #787878;
  --dark-gray: #777777;
  --raven-gray: #707275;
  --danube-blue: #6685c2;
  --dim-gray: #666666;
  --davy-grey: #555555;
  --analog-blue: #435f8b;
  --charcoal-gray: #444444;
  --mariner-blue: #335cad;
  --dark-charcoal-gray: #333333;
  --green-waterloo: #23241f;
  --nero-dark: #222222;
  --smalt-blue: #003399;
  --deep-green: #008a00;
  --navy-blue: #0066cc;
  --bondi-blue: #0085c7;
  --violation-text: #cc0000;
  --violation-bg: #ffe6e6;
  --warning-text: #f68d29;
  --warning-bg: #fff2ca;
  --highcharts-color-2: #ffa366;
  --highcharts-color-3: #777777;
  --highcharts-color-6: #e65c00;
  --highcharts-color-7: #e0e0e0;
}

/*Sprite Positions*/
/*Images*/
/*Primary*/
/*Grayscale*/
/*Secondary*/
/*disabled*/
/*remaing colors*/
.darkblue {
  color: var(--primary-dark-color);
}

.blue {
  color: var(--primary-color);
}

.lightblue {
  color: var(--primary-light1-color);
}

.whiteblue {
  color: var(--primary-white-color);
}

.yellowgreen {
  color: var(--primary-color2);
}

.green {
  color: var(--primary-color3) !important;
}

.darkgreen {
  color: var(--primary-dark2-color);
}

.lightgreen {
  color: var(--primary-light2-color);
}

.gray {
  color: var(--grayscale-color);
}

.lightgray {
  color: var(--grayscale-light-color);
}

.whitegray {
  color: var(--grayscale-white-color);
}

.darkcyan {
  color: var(--secondary-dark-color);
}

.purple {
  color: var(--secondary-color);
}

.orange {
  color: var(--secondary-color2) !important;
}

.yellow {
  color: var(--secondary-color3);
}

.brightyellow {
  color: var(--secondary-bright-color);
}

.red {
  color: var(--error-color) !important;
}

.lightred {
  color: var(--error-light-color);
}

.white {
  color: var(--white-color) !important;
}

.darkblue-bg {
  background-color: var(--primary-dark-color);
}

.blue-bg {
  background-color: var(--primary-color) !important;
}

.lightblue-bg {
  background-color: var(--primary-light1-color);
}

.whiteblue-bg {
  background-color: var(--primary-white-color);
}

.yellowgreen-bg {
  background-color: var(--primary-color2);
}

.green-bg {
  background-color: var(--primary-color3);
}

.darkgreen-bg {
  background-color: var(--primary-dark2-color);
}

.lightgreen-bg {
  background-color: var(--primary-light2-color);
}

.gray-bg {
  background-color: var(--grayscale-color);
}

.lightgray-bg {
  background-color: var(--grayscale-light-color);
}

.whitegray-bg {
  background-color: var(--grayscale-white-color);
}

.darkcyan-bg {
  background-color: var(--secondary-dark-color);
}

.purple-bg {
  background-color: var(--secondary-color);
}

.orange-bg {
  background-color: var(--secondary-color2) !important;
}

.yellow-bg {
  background-color: var(--secondary-color3) !important;
}

.brightyellow-bg {
  background-color: var(--secondary-bright-color) !important;
}

.red-bg {
  background-color: var(--error-color) !important;
}

.lightred-bg {
  background-color: var(--error-light-color) !important;
}

.inline-edit-table table tbody td .form-group, .inline-edit-table table tbody td .form-control-sm {
  margin-bottom: 0;
}
.inline-edit-table table tbody td .form-group .form-control, .inline-edit-table table tbody td .form-control-sm .form-control {
  font-size: 0.875rem;
  min-height: 30px;
  padding: 2px 10px 0;
  border-bottom-width: 1px;
  margin-top: 0;
}
.inline-edit-table table tbody td .form-group .form-control.ng-valid.ng-dirty:focus, .inline-edit-table table tbody td .form-control-sm .form-control.ng-valid.ng-dirty:focus {
  border-color: var(--primary-color3);
}
.inline-edit-table table tbody td .form-group .input-group-btn .btn, .inline-edit-table table tbody td .form-control-sm .input-group-btn .btn {
  font-size: 1.125rem;
  width: 32px;
  height: 30px;
}
.inline-edit-table table tbody td .form-group .icon-right + span, .inline-edit-table table tbody td .form-group .icon-left + span, .inline-edit-table table tbody td .form-group .ng2-datetime-picker-wrapper + span, .inline-edit-table table tbody td .form-control-sm .icon-right + span, .inline-edit-table table tbody td .form-control-sm .icon-left + span, .inline-edit-table table tbody td .form-control-sm .ng2-datetime-picker-wrapper + span {
  font-size: 0.875rem;
}
.inline-edit-table table tbody td .form-group .icon-right + span.fa-caret-down, .inline-edit-table table tbody td .form-group .icon-right + span.fa-caret-up, .inline-edit-table table tbody td .form-group .icon-left + span.fa-caret-down, .inline-edit-table table tbody td .form-group .icon-left + span.fa-caret-up, .inline-edit-table table tbody td .form-group .ng2-datetime-picker-wrapper + span.fa-caret-down, .inline-edit-table table tbody td .form-group .ng2-datetime-picker-wrapper + span.fa-caret-up, .inline-edit-table table tbody td .form-control-sm .icon-right + span.fa-caret-down, .inline-edit-table table tbody td .form-control-sm .icon-right + span.fa-caret-up, .inline-edit-table table tbody td .form-control-sm .icon-left + span.fa-caret-down, .inline-edit-table table tbody td .form-control-sm .icon-left + span.fa-caret-up, .inline-edit-table table tbody td .form-control-sm .ng2-datetime-picker-wrapper + span.fa-caret-down, .inline-edit-table table tbody td .form-control-sm .ng2-datetime-picker-wrapper + span.fa-caret-up {
  font-size: 1.25rem;
}
.inline-edit-table table tbody td .form-group .icon-right, .inline-edit-table table tbody td .form-control-sm .icon-right {
  padding-right: 28px !important;
}
.inline-edit-table table tbody td .form-group .icon-left, .inline-edit-table table tbody td .form-control-sm .icon-left {
  padding-left: 28px !important;
}
.inline-edit-table table tbody td bh-r-checkbox .checkbox-radio-indicator {
  left: 50%;
  transform: translate(-50%, -25%);
}
.inline-edit-table table tbody td ng2-datetime-picker {
  transform: scale(0.75, 0.75);
  top: 5px !important;
  left: -22px !important;
  width: 175px !important;
}
.inline-edit-table table tbody td .datepicker-label.focus > .errorMessage {
  margin-left: 175px;
}

.rentroll-table {
  height: calc(100vh - 160px);
  overflow: auto;
}
.rentroll-table table thead, .rentroll-table table tbody {
  font-size: 0.75rem;
}
.rentroll-table table td:not(.btn2-col) {
  padding: 4px 8px;
  vertical-align: middle;
}
.rentroll-table table tbody td {
  line-height: 1;
}
.rentroll-table table tbody td .form-group .form-control, .rentroll-table table tbody td .form-control-sm .form-control {
  font-size: 0.75rem;
  padding: 2px 6px 0;
}
.rentroll-table table tbody td.btn-col .dropdown, .rentroll-table table tbody td.btn2-col .dropdown {
  position: relative;
}
.rentroll-table table tbody td.btn-col .dropdown-menu, .rentroll-table table tbody td.btn2-col .dropdown-menu {
  top: -2px;
  left: calc(100% + 10px);
}
.rentroll-table table tbody td .checkbox-radio-indicator {
  top: -4px;
}
.rentroll-table table tbody td .checkbox-radio-indicator:after {
  top: 1px;
}
.rentroll-table table tbody td .columntype-currency:before, .rentroll-table table tbody td .columntype-number:before, .rentroll-table table tbody td .columntype-decimal:before {
  top: -1px;
}
.rentroll-table table tbody td .errorMessage {
  width: 230px;
}
.rentroll-table .modal table thead, .rentroll-table .modal table tbody {
  font-size: 0.875rem;
}
.rentroll-table .modal table td:not(.btn2-col) {
  padding: 8px 10px;
}
.rentroll-table .modal table tbody td .form-group .form-control, .rentroll-table .modal table tbody td .form-control-sm .form-control {
  font-size: 0.875rem;
  padding: 2px 10px 0;
}

.statement-template-table {
  max-height: calc(100vh - 180px);
  overflow: auto;
}

.group-reporting table,
.sa-table-container table {
  position: relative;
  width: 100%;
  overflow: hidden;
  table-layout: fixed;
  border: 2px solid var(--primary-light1-color-darken-10percent);
}
.group-reporting table thead,
.sa-table-container table thead {
  position: relative;
  display: block;
  width: 100%;
  overflow: hidden; /*changed from visible for simultaneous scrollbars*/
}
.group-reporting table thead th,
.sa-table-container table thead th {
  text-align: center;
  min-width: 150px;
  width: 150px;
  box-sizing: border-box;
  line-height: 20px;
  vertical-align: middle;
  padding: 8px;
}
.group-reporting table thead th a,
.sa-table-container table thead th a {
  color: var(--grayscale-color);
  display: block;
}
.group-reporting table thead th a:hover,
.sa-table-container table thead th a:hover {
  color: var(--primary-color3);
}
.group-reporting table thead th .column-truncate,
.sa-table-container table thead th .column-truncate {
  display: block;
  width: 130px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.group-reporting table thead th.fixed-col,
.sa-table-container table thead th.fixed-col {
  position: relative;
  display: block;
  min-width: 300px;
  max-width: 300px;
  height: 70px;
  border-right: 2px solid var(--primary-light1-color-darken-10percent);
}
.group-reporting table thead th.fixed-col button,
.sa-table-container table thead th.fixed-col button {
  height: 36px;
  position: absolute;
  width: 125px;
}
.group-reporting table thead th.fixed-col button.top-left,
.sa-table-container table thead th.fixed-col button.top-left {
  margin: auto;
  left: 15px;
  top: 0;
  bottom: 0;
}
.group-reporting table thead th.fixed-col button.top-right,
.sa-table-container table thead th.fixed-col button.top-right {
  margin: auto;
  right: 17px;
  top: 0;
  bottom: 0;
}
.group-reporting table thead th.add-column,
.sa-table-container table thead th.add-column {
  min-width: 100px;
  max-width: 100px;
  vertical-align: middle;
  padding: 0;
}
.group-reporting table thead th.add-column .btn,
.sa-table-container table thead th.add-column .btn {
  width: 100%;
  height: 70px;
  background-color: transparent;
  border: none;
  color: var(--primary-light1-color);
  transition: font-size 0.2s ease-in-out;
}
.group-reporting table thead th.add-column .btn:hover, .group-reporting table thead th.add-column .btn:focus,
.sa-table-container table thead th.add-column .btn:hover,
.sa-table-container table thead th.add-column .btn:focus {
  color: var(--primary-color);
  font-size: 17px;
}
.group-reporting table thead th:nth-child(2),
.sa-table-container table thead th:nth-child(2) {
  border-left: none;
}
.group-reporting table .column-pagination,
.sa-table-container table .column-pagination {
  width: 80px;
  position: absolute;
  top: 15px;
  right: 12px;
  text-align: center;
}
.group-reporting table .column-pagination button,
.sa-table-container table .column-pagination button {
  width: 35px;
  line-height: 1;
}
.group-reporting table .column-pagination div,
.sa-table-container table .column-pagination div {
  margin-top: 10px;
}
.group-reporting table .column-pagination-small,
.sa-table-container table .column-pagination-small {
  width: 80px;
  position: absolute;
  top: 8px;
  right: 12px;
  text-align: center;
}
.group-reporting table .column-pagination-small button,
.sa-table-container table .column-pagination-small button {
  width: 35px;
  line-height: 1;
}
.group-reporting table .column-pagination-small div,
.sa-table-container table .column-pagination-small div {
  margin-top: 2px;
}
.group-reporting table tbody,
.sa-table-container table tbody {
  position: relative;
  display: block;
  width: 100%;
  max-height: calc(100vh - 250px);
  overflow: auto;
  /*Change last rows tooltip to remove small white spacing*/
}
.group-reporting table tbody td,
.sa-table-container table tbody td {
  height: 30px;
  position: relative;
  padding: 0;
  min-width: 150px;
  width: 150px;
  box-sizing: border-box;
}
.group-reporting table tbody td.fixed-col,
.sa-table-container table tbody td.fixed-col {
  position: relative;
  display: block;
  width: 300px;
  background-color: var(--primary-white-color);
  border-right: 2px solid var(--primary-light1-color-darken-10percent);
  line-height: 30px;
  padding: 0 10px;
}
.group-reporting table tbody td.fixed-col a.line-title, .group-reporting table tbody td.fixed-col span.line-title,
.sa-table-container table tbody td.fixed-col a.line-title,
.sa-table-container table tbody td.fixed-col span.line-title {
  color: var(--grayscale-color);
  font-weight: normal;
  max-width: 280px;
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.group-reporting table tbody td.fixed-col a.line-title .line-number, .group-reporting table tbody td.fixed-col span.line-title .line-number,
.sa-table-container table tbody td.fixed-col a.line-title .line-number,
.sa-table-container table tbody td.fixed-col span.line-title .line-number {
  color: var(--primary-color);
  margin-right: 3px;
  display: inline;
}
.group-reporting table tbody td.fixed-col a.line-title:hover,
.sa-table-container table tbody td.fixed-col a.line-title:hover {
  color: var(--primary-color3);
}
.group-reporting table tbody td.fixed-col.group-header,
.sa-table-container table tbody td.fixed-col.group-header {
  background-color: var(--primary-color);
  color: white;
  font-size: 0.875rem;
}
.group-reporting table tbody td.fixed-col.group-header td,
.sa-table-container table tbody td.fixed-col.group-header td {
  color: var(--white-color);
}
.group-reporting table tbody td.form-control-sm .form-control,
.sa-table-container table tbody td.form-control-sm .form-control {
  min-width: 149px;
  width: 149px;
  height: 30px;
  min-height: 30px;
  border: none;
  outline: 1px solid var(--grayscale-light-color);
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
}
.group-reporting table tbody td.form-control-sm .form-control:focus,
.sa-table-container table tbody td.form-control-sm .form-control:focus {
  outline-color: white;
  border: 1px solid var(--primary-color);
  z-index: 2;
}
.group-reporting table tbody tr.sub-row td,
.sa-table-container table tbody tr.sub-row td {
  background: var(--grayscale-white-color-lighten-5percent);
}
.group-reporting table tbody tr.sub-row a.line-title, .group-reporting table tbody tr.sub-row span.line-title,
.sa-table-container table tbody tr.sub-row a.line-title,
.sa-table-container table tbody tr.sub-row span.line-title {
  padding-left: 10px;
  max-width: 260px;
}
.group-reporting table tbody tr.group-header,
.sa-table-container table tbody tr.group-header {
  border-right: 1px solid var(--primary-color);
}
.group-reporting table tbody tr.group-header td,
.sa-table-container table tbody tr.group-header td {
  height: 32px;
  min-width: 300px;
}
.group-reporting table tbody tr.blank-row td,
.sa-table-container table tbody tr.blank-row td {
  height: 20px;
  border: none;
  background: none;
}
.group-reporting table tbody tr.blank-row td.fixed-col a.line,
.sa-table-container table tbody tr.blank-row td.fixed-col a.line {
  pointer-events: none;
}
.group-reporting table tbody tr.blank-row td.fixed-col a.line:hover,
.sa-table-container table tbody tr.blank-row td.fixed-col a.line:hover {
  color: var(--grayscale-color);
}
.group-reporting table tbody tr.blank-row td div, .group-reporting table tbody tr.blank-row td a,
.sa-table-container table tbody tr.blank-row td div,
.sa-table-container table tbody tr.blank-row td a {
  display: none;
}
.group-reporting table tbody tr:last-child .fixed-col .tooltip-br,
.sa-table-container table tbody tr:last-child .fixed-col .tooltip-br {
  display: inline;
}
.group-reporting table tbody tr:last-child .fixed-col .tooltip-br span,
.sa-table-container table tbody tr:last-child .fixed-col .tooltip-br span {
  top: 25%;
  left: 75%;
  bottom: initial;
  transform: translateY(-100%);
}

/*SA Specific Overrides*/
.sa-search .form-group {
  margin-bottom: 0;
}

.sa-table-container table thead th {
  vertical-align: top;
}
.sa-table-container table thead th.fixed-col {
  height: 96px;
}
.sa-table-container table thead th.fixed-col button.switch-view {
  left: 15px;
  top: 15px;
  width: 150px;
}
.sa-table-container table thead th.fixed-col button.add-btn {
  right: 17px;
  top: 15px;
  width: 100px;
}
.sa-table-container table td, .sa-table-container table tr {
  border-color: var(--grayscale-white-color-darken-5percent);
}
.sa-table-container table tbody {
  max-height: calc(100vh - 400px);
}
.sa-table-container table tbody td.fixed-col {
  border-top: none;
  border-bottom: none;
  box-shadow: inset 1px 0 0 var(--grayscale-white-color-darken-5percent);
  padding-left: 15px;
}
.sa-table-container table tbody td.form-control-sm span.form-control {
  background-color: var(--grayscale-white-color);
  line-height: 28px;
}
.sa-table-container table tbody td.form-control-sm .btn-unstyled {
  position: absolute;
  top: 0;
}
.sa-table-container table tbody td.form-control-sm .btn-unstyled ~ .input-group-append {
  display: none;
}
.sa-table-container table tbody td.form-control-sm .form-control ~ .input-group-append, .sa-table-container table tbody td.form-control-sm .form-control .input-group-append {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 3;
}
.sa-table-container table tbody td.form-control-sm .form-control:not(.no-comment):focus {
  width: 118px;
  outline-color: white;
  border: 1px solid var(--primary-color);
  z-index: 2;
  background-color: var(--primary-white-color);
}
.sa-table-container table tbody td.form-control-sm .form-control:not(.no-comment):focus ~ .input-group-append {
  display: block;
}
.sa-table-container table tbody td.form-control-sm .form-control:not(.no-comment):focus ~ .calculation-indicator {
  left: 32px;
}
.sa-table-container table tbody td.form-control-sm .checkbox-radio, .sa-table-container table tbody td.form-control-sm .checkbox-radio-no-margin {
  margin: 0;
}
.sa-table-container table tbody td.form-control-sm .checkbox-radio .checkbox-input:focus ~ .input-group-append, .sa-table-container table tbody td.form-control-sm .checkbox-radio-no-margin .checkbox-input:focus ~ .input-group-append {
  display: block;
}
.sa-table-container table tbody td.form-control-sm .checkbox-radio .checkbox-radio-indicator, .sa-table-container table tbody td.form-control-sm .checkbox-radio-no-margin .checkbox-radio-indicator {
  right: 10px;
  top: 4px;
  left: initial;
}
.sa-table-container table tbody td.form-control-sm .checkbox-radio .checkbox-radio-indicator:after, .sa-table-container table tbody td.form-control-sm .checkbox-radio-no-margin .checkbox-radio-indicator:after {
  top: -5px;
}
.sa-table-container table tbody td.form-control-sm .checkbox-radio:focus-within, .sa-table-container table tbody td.form-control-sm .checkbox-radio-no-margin:focus-within {
  width: 118px;
  outline-color: white;
  border: 1px solid var(--primary-color);
  z-index: 2;
  background-color: var(--primary-white-color);
}
.sa-table-container table tbody td.form-control-sm .checkbox-radio:focus-within ~ .input-group-append, .sa-table-container table tbody td.form-control-sm .checkbox-radio-no-margin:focus-within ~ .input-group-append {
  display: block;
}
.sa-table-container table tbody td.form-control-sm .calculation-indicator {
  position: absolute;
  top: 0;
  left: -1px;
  z-index: 5;
  border: 4px solid transparent;
  border-left-color: var(--primary-color3);
  border-top-color: var(--primary-color3);
}
.sa-table-container table tbody td.form-control-sm .comment-indicator {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 5;
  border: 4px solid transparent;
  border-right-color: var(--secondary-color2);
  border-top-color: var(--secondary-color2);
}
.sa-table-container table tbody tr.line-type.line-type-header {
  border-top: 4px solid var(--primary-light1-color-darken-10percent);
}
.sa-table-container table tbody tr.line-type.line-type-header .form-control {
  font-weight: bold;
}
.sa-table-container table tbody tr.line-type td.fixed-col {
  padding-left: 12px;
  border-left: 5px solid var(--primary-light1-color-darken-10percent);
}
.sa-table-container table tbody tr.line-type td.fixed-col .btn-toggle .fa {
  margin-right: 10px;
  color: white;
  font-size: 16px;
  font-weight: bold;
  width: 15px;
  padding: 1px;
  border: 1px solid var(--primary-color);
  background-color: var(--primary-color);
  border-radius: 2px;
  position: relative;
  bottom: 1px;
}
.sa-table-container table tbody tr.line-type td.fixed-col .btn-toggle .fa.open {
  color: var(--primary-color);
  background-color: white !important;
}
.sa-table-container table tbody tr.line-type td.fixed-col .btn-toggle .fa.fa-angle-right:before {
  position: relative;
  bottom: 1px;
}
.sa-table-container table tbody tr.line-type.balancing.line-type-header {
  border-top-color: var(--secondary-color);
}
.sa-table-container table tbody tr.line-type.balancing td.fixed-col {
  border-left-color: var(--secondary-color);
}
.sa-table-container table tbody tr.line-type.balancing td.fixed-col .btn-toggle .fa {
  border-color: var(--secondary-color);
  background-color: var(--secondary-color);
}
.sa-table-container table tbody tr.line-type.balancing td.fixed-col .btn-toggle .fa.open {
  color: var(--secondary-color);
}
.sa-table-container table tbody tr.line-type.assets.line-type-header {
  border-top-color: var(--primary-color3);
}
.sa-table-container table tbody tr.line-type.assets td.fixed-col {
  border-left-color: var(--primary-color3);
}
.sa-table-container table tbody tr.line-type.assets td.fixed-col .btn-toggle .fa {
  border-color: var(--primary-color3);
  background-color: var(--primary-color3);
}
.sa-table-container table tbody tr.line-type.assets td.fixed-col .btn-toggle .fa.open {
  color: var(--primary-color3);
}
.sa-table-container table tbody tr.line-type.liabilities.line-type-header {
  border-top-color: var(--error-color-darken-10percent);
}
.sa-table-container table tbody tr.line-type.liabilities td.fixed-col {
  border-left-color: var(--error-color-darken-10percent);
}
.sa-table-container table tbody tr.line-type.liabilities td.fixed-col .btn-toggle .fa {
  border-color: var(--error-color-darken-10percent);
  background-color: var(--error-color-darken-10percent);
}
.sa-table-container table tbody tr.line-type.liabilities td.fixed-col .btn-toggle .fa.open {
  color: var(--error-color-darken-10percent);
}
.sa-table-container table tbody tr.line-type.net-worth.line-type-header {
  border-top-color: var(--secondary-dark-color);
}
.sa-table-container table tbody tr.line-type.net-worth td.fixed-col {
  border-left-color: var(--secondary-dark-color);
}
.sa-table-container table tbody tr.line-type.net-worth td.fixed-col .btn-toggle .fa {
  border-color: var(--secondary-dark-color);
  background-color: var(--secondary-dark-color);
}
.sa-table-container table tbody tr.line-type.net-worth td.fixed-col .btn-toggle .fa.open {
  color: var(--secondary-dark-color);
}
.sa-table-container table tbody tr.line-type.operating.line-type-header {
  border-top-color: var(--secondary-color3-darken-3percent);
}
.sa-table-container table tbody tr.line-type.operating td.fixed-col {
  border-left-color: var(--secondary-color3-darken-3percent);
}
.sa-table-container table tbody tr.line-type.operating td.fixed-col .btn-toggle .fa {
  border-color: var(--secondary-color3-darken-3percent);
  background-color: var(--secondary-color3-darken-3percent);
}
.sa-table-container table tbody tr.line-type.operating td.fixed-col .btn-toggle .fa.open {
  color: var(--secondary-color3-darken-3percent);
}
.sa-table-container table tbody tr.line-type.net-income.line-type-header {
  border-top-color: var(--grayscale-color);
}
.sa-table-container table tbody tr.line-type.net-income td.fixed-col {
  border-left-color: var(--grayscale-color);
}
.sa-table-container table tbody tr.line-type.net-income td.fixed-col .btn-toggle .fa {
  border-color: var(--grayscale-color);
  background-color: var(--grayscale-color);
}
.sa-table-container table tbody tr.line-type.net-income td.fixed-col .btn-toggle .fa.open {
  color: var(--grayscale-color);
}
.sa-table-container table tbody tr.line-type.revenue.line-type-header {
  border-top-color: var(--primary-dark2-color);
}
.sa-table-container table tbody tr.line-type.revenue td.fixed-col {
  border-left-color: var(--primary-dark2-color);
}
.sa-table-container table tbody tr.line-type.revenue td.fixed-col .btn-toggle .fa {
  border-color: var(--primary-dark2-color);
  background-color: var(--primary-dark2-color);
}
.sa-table-container table tbody tr.line-type.revenue td.fixed-col .btn-toggle .fa.open {
  color: var(--primary-dark2-color);
}
.sa-table-container table tbody tr.line-type.changes-net-worth.line-type-header {
  border-top-color: var(--primary-dark-color);
}
.sa-table-container table tbody tr.line-type.changes-net-worth td.fixed-col {
  border-left-color: var(--primary-dark-color);
}
.sa-table-container table tbody tr.line-type.changes-net-worth td.fixed-col .btn-toggle .fa {
  border-color: var(--primary-dark-color);
  background-color: var(--primary-dark-color);
}
.sa-table-container table tbody tr.line-type.changes-net-worth td.fixed-col .btn-toggle .fa.open {
  color: var(--primary-dark-color);
}
.sa-table-container table tbody tr.line-type.retained-earnings.line-type-header {
  border-top-color: var(--secondary-color2);
}
.sa-table-container table tbody tr.line-type.retained-earnings td.fixed-col {
  border-left-color: var(--secondary-color2);
}
.sa-table-container table tbody tr.line-type.retained-earnings td.fixed-col .btn-toggle .fa {
  border-color: var(--secondary-color2);
  background-color: var(--secondary-color2);
}
.sa-table-container table tbody tr.line-type.retained-earnings td.fixed-col .btn-toggle .fa.open {
  color: var(--secondary-color2);
}
.sa-table-container table tbody tr.line-type.other-lines.line-type-header {
  border-top-color: darkgray;
}
.sa-table-container table tbody tr.line-type.other-lines td.fixed-col {
  border-left-color: darkgray;
}
.sa-table-container table tbody tr.line-type.other-lines td.fixed-col .btn-toggle .fa {
  border-color: darkgray;
  background-color: darkgray;
}
.sa-table-container table tbody tr.line-type.other-lines td.fixed-col .btn-toggle .fa.open {
  color: darkgray;
}
.sa-table-container table tbody tr.line-type.convenant-lines.line-type-header {
  border-top-color: var(--primary-color);
}
.sa-table-container table tbody tr.line-type.convenant-lines td.fixed-col {
  border-left-color: var(--primary-color);
}
.sa-table-container table tbody tr.line-type.convenant-lines td.fixed-col .btn-toggle .fa {
  border-color: var(--primary-color);
  background-color: var(--primary-color);
}
.sa-table-container table tbody tr.line-type.convenant-lines td.fixed-col .btn-toggle .fa.open {
  color: var(--primary-color);
}
.sa-table-container table tbody tr.line-type.appraisal.line-type-header {
  border-top-color: mediumpurple;
}
.sa-table-container table tbody tr.line-type.appraisal td.fixed-col {
  border-left-color: mediumpurple;
}
.sa-table-container table tbody tr.line-type.appraisal td.fixed-col .btn-toggle .fa {
  border-color: mediumpurple;
  background-color: mediumpurple;
}
.sa-table-container table tbody tr.line-type.appraisal td.fixed-col .btn-toggle .fa.open {
  color: mediumpurple;
}
.sa-table-container table tbody tr.line-type.rent-roll.line-type-header {
  border-top-color: saddlebrown;
}
.sa-table-container table tbody tr.line-type.rent-roll td.fixed-col {
  border-left-color: saddlebrown;
}
.sa-table-container table tbody tr.line-type.rent-roll td.fixed-col .btn-toggle .fa {
  border-color: saddlebrown;
  background-color: saddlebrown;
}
.sa-table-container table tbody tr.line-type.rent-roll td.fixed-col .btn-toggle .fa.open {
  color: saddlebrown;
}
.sa-table-container table tbody tr.sub-row td.fixed-col:before {
  font-family: "FontAwesome", sans-serif;
  content: "\f148";
  transform: rotate(180deg);
  font-size: 0.875rem;
  color: var(--spun-pearl);
  position: relative;
  float: left;
  left: 2px;
  top: 2px;
}
.sa-table-container table tbody tr.sub-row td.form-control-sm .calculation-indicator {
  left: 14px;
}
.sa-table-container table tbody tr.sub-row td.form-control-sm .btn-unstyled {
  right: 0;
}
.sa-table-container table tbody tr.sub-row td.form-control-sm .form-control {
  width: 135px;
  min-width: 135px;
  right: 0;
  left: initial;
}
.sa-table-container table tbody tr.sub-row td.form-control-sm .form-control:focus {
  width: 105px;
}
.sa-table-container table tbody tr.sub-row td.form-control-sm .form-control:focus ~ .calculation-indicator {
  left: 45px;
}
.sa-table-container table tbody tr.sub-row td.form-control-sm .input-group-append .btn {
  position: relative;
  left: 13px;
}
.sa-table-container table tbody tr.sub-row + tr:not(.sub-row) td.fixed-col, .sa-table-container table tbody tr.calculated-row:not(.balancing) td.fixed-col {
  border-top: 1px solid var(--raven-gray);
  color: var(--raven-gray);
}
.sa-table-container table tbody tr.sub-row + tr:not(.sub-row) td.fixed-col a.line-title, .sa-table-container table tbody tr.calculated-row:not(.balancing) td.fixed-col a.line-title {
  line-height: 29px;
  color: var(--raven-gray);
}
.sa-table-container table tbody tr.sub-row + tr:not(.sub-row) td.form-control-sm .form-control:first-of-type, .sa-table-container table tbody tr.calculated-row:not(.balancing) td.form-control-sm .form-control:first-of-type {
  line-height: 26px;
  border-top: 1px solid var(--raven-gray);
  font-weight: bold;
  color: var(--raven-gray);
}
.sa-table-container table tbody tr.total-row:not(.balancing) td.fixed-col {
  border-top: 4px double var(--grayscale-color);
}
.sa-table-container table tbody tr.total-row:not(.balancing) td.fixed-col a.line-title {
  line-height: 26px;
  font-weight: bold;
  text-transform: uppercase;
  color: var(--grayscale-color);
}
.sa-table-container table tbody tr.total-row:not(.balancing) td.form-control-sm .form-control:first-of-type {
  line-height: 23px;
  border-top: 4px double var(--grayscale-color);
  font-weight: bold;
  color: var(--grayscale-color);
}
.sa-table-container table tbody tr.calculated-row td .input-group-append {
  display: none;
}
.sa-table-container table tbody tr.covenant-row td .form-control.failed-covenant:before, .sa-table-container table tbody tr.covenant-row td .form-control.waived-covenant:before, .sa-table-container table tbody tr.covenant-row td .form-control.exclude-covenant:before, .sa-table-container table tbody tr.covenant-row td .form-control.unmatched-frequency-covenant:before {
  font-family: "FontAwesome", sans-serif;
  content: "";
  font-size: 0.875rem;
  position: absolute;
  left: 10px;
  top: 1px;
}
.sa-table-container table tbody tr.covenant-row td .form-control.failed-covenant {
  background-color: var(--error-light-color);
}
.sa-table-container table tbody tr.covenant-row td .form-control.failed-covenant:before {
  content: "\f071";
  color: var(--error-color);
}
.sa-table-container table tbody tr.covenant-row td .form-control.waived-covenant {
  background-color: var(--primary-color3-lighten-40percent);
}
.sa-table-container table tbody tr.covenant-row td .form-control.waived-covenant:before {
  content: "\f00c";
  color: var(--primary-color3);
}
.sa-table-container table tbody tr.covenant-row td .form-control.exclude-covenant {
  background-color: var(--grayscale-light-color);
}
.sa-table-container table tbody tr.covenant-row td .form-control.exclude-covenant:before {
  content: "\f056";
  color: black;
}
.sa-table-container table tbody tr.covenant-row td .form-control.unmatched-frequency-covenant {
  background-color: var(--primary-white-color-darken-2percent);
}
.sa-table-container table tbody tr.covenant-row td .form-control.unmatched-frequency-covenant:before {
  content: "\f05a";
  color: var(--primary-color);
}
.sa-table-container table tbody tr.covenant-row td .input-group-append {
  display: none;
}

.sa-table-container .form-control-sm .form-control .input-group-append {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 3;
}

.negative-cell {
  color: var(--error-color) !important;
}

@media (max-width: 1199px) {
  .sa-table-container table tbody {
    max-height: calc(100vh - 260px);
  }
  .sa-table-container.projections table tbody {
    max-height: calc(100vh - 320px);
  }
}
/*Projections Specific Overrides*/
.sa-table-container.projections table thead th.historical {
  background: var(--grayscale-white-color-lighten-2percent) linear-gradient(to bottom, var(--grayscale-white-color-lighten-2percent), var(--grayscale-white-color-lighten-2percent) 50%, var(--grayscale-white-color-darken-5percent));
}
.sa-table-container.projections table thead th.fixed-col {
  min-width: 350px;
  max-width: 350px;
}
.sa-table-container.projections table tbody {
  max-height: calc(100vh - 400px);
  /*& tr.sub-row + tr:not(.sub-row) td.form-control-sm .info-spans .form-control {
      border-top: none;
      font-weight:normal;
      color: $gray;
  }*/
}
.sa-table-container.projections table tbody tr td.fixed-col {
  width: 350px;
}
.sa-table-container.projections table tbody tr td.fixed-col a.line-title, .sa-table-container.projections table tbody tr td.fixed-col span.line-title {
  max-width: 240px;
}
.sa-table-container.projections table tbody tr td.fixed-col .checkbox-radio-indicator {
  top: -4px;
}
.sa-table-container.projections table tbody tr td.fixed-col .checkbox-radio-indicator:after {
  top: -5px;
}
.sa-table-container.projections table tbody tr.group-header td {
  min-width: 350px;
}
.sa-table-container.projections table tbody tr:not(.blank-row) td.calculation-cell {
  height: 61px;
}
.sa-table-container.projections table tbody tr:not(.blank-row) td.calculation-cell input.form-control {
  font-style: italic;
}
.sa-table-container.projections table tbody tr:not(.blank-row) td.calculation-cell .projections-dropdown {
  position: absolute;
  right: 0;
  top: 0;
  z-index: 5;
  width: 80px;
}
.sa-table-container.projections table tbody tr:not(.blank-row) td.calculation-cell .projections-dropdown .form-control {
  border-bottom-width: 1px;
  font-size: 13px;
  padding: 2px 26px 2px 5px !important;
}
.sa-table-container.projections table tbody tr:not(.blank-row) td.calculation-cell .projections-dropdown .fa {
  margin-top: -30px;
}
.sa-table-container.projections table tbody tr:not(.blank-row) td.calculation-cell .info-spans {
  position: absolute;
  top: 31px;
  left: 0;
}
.sa-table-container.projections table tbody tr:not(.blank-row) td.calculation-cell .info-spans .form-control {
  position: static !important;
  margin-bottom: 1px;
  line-height: 28px;
}
.sa-table-container.projections table tbody tr:not(.blank-row) td.calculation-cell .info-spans .form-control.funds-required {
  background-color: var(--error-light-color);
}
.sa-table-container.projections table tbody tr:not(.blank-row) td.calculation-cell .info-spans .form-control.excess-funds {
  background-color: var(--primary-color3-lighten-40percent);
}
.sa-table-container.projections table tbody tr:not(.blank-row) td.calculation-cell .historical-labels span {
  position: absolute;
  top: 63px;
  right: 10px;
  text-align: right;
  font-size: 13px;
  width: 320px;
}
.sa-table-container.projections table tbody tr:not(.blank-row) td.calculation-cell .historical-labels span i.left-label {
  float: left;
  max-width: 230px;
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-style: normal;
}
.sa-table-container.projections table tbody tr:not(.blank-row) td.calculation-cell .historical-labels span:nth-child(2) {
  top: 94px;
}
.sa-table-container.projections table tbody tr:not(.blank-row) td.calculation-cell .historical-labels span:nth-child(3) {
  top: 125px;
}
.sa-table-container.projections table tbody tr:not(.blank-row) td.hide-assumptions {
  height: 30px;
}
.sa-table-container.projections table tbody tr:not(.blank-row) td.hide-assumptions .info-spans {
  top: 0;
}
.sa-table-container.projections table tbody tr:not(.blank-row) td.historic-data-1 {
  height: 92px;
}
.sa-table-container.projections table tbody tr:not(.blank-row) td.historic-data-2 {
  height: 123px;
}
.sa-table-container.projections table tbody tr:not(.blank-row) td.historic-data-3 {
  height: 154px;
}
.sa-table-container.projections table tbody tr.calculated-row td.calculation-cell, .sa-table-container.projections table tbody tr.no-projection-row td.calculation-cell {
  height: 30px;
}
.sa-table-container.projections table tbody tr.calculated-row td.calculation-cell .info-spans, .sa-table-container.projections table tbody tr.no-projection-row td.calculation-cell .info-spans {
  top: 0;
}
.sa-table-container.projections table tbody tr.calculated-row td.calculation-cell .historical-labels span, .sa-table-container.projections table tbody tr.no-projection-row td.calculation-cell .historical-labels span {
  top: 32px;
}
.sa-table-container.projections table tbody tr.calculated-row td.calculation-cell .historical-labels span:nth-child(2), .sa-table-container.projections table tbody tr.no-projection-row td.calculation-cell .historical-labels span:nth-child(2) {
  top: 63px;
}
.sa-table-container.projections table tbody tr.calculated-row td.calculation-cell .historical-labels span:nth-child(3), .sa-table-container.projections table tbody tr.no-projection-row td.calculation-cell .historical-labels span:nth-child(3) {
  top: 94px;
}
.sa-table-container.projections table tbody tr.calculated-row td.historic-data-1, .sa-table-container.projections table tbody tr.no-projection-row td.historic-data-1 {
  height: 61px;
}
.sa-table-container.projections table tbody tr.calculated-row td.historic-data-2, .sa-table-container.projections table tbody tr.no-projection-row td.historic-data-2 {
  height: 92px;
}
.sa-table-container.projections table tbody tr.calculated-row td.historic-data-3, .sa-table-container.projections table tbody tr.no-projection-row td.historic-data-3 {
  height: 123px;
}
.sa-table-container.projections table tbody tr.sub-row span.form-control {
  width: 149px;
  min-width: 149px;
}
.sa-table-container.projections table tbody tr.sub-row + tr:not(.sub-row) td.form-control-sm > div {
  border-top: 1px solid var(--raven-gray);
}

.form-control-sm .input-group-append {
  margin-top: unset;
}
.form-control-sm .input-group-append .btn {
  font-size: 18px;
  width: 32px;
  height: 30px;
}

/* Old Colors

$color0: #ededed; // white gray
$color1: rgb(190,190,190); // #bebebe - light gray
$color2: rgb(80,80,80); // #505050 - gray
$color3: rgb(207,214,224); // #cfd6e0 - white blue
$color4: rgb(159,172,193); // #9facc1 - light blue
$color5: rgb(88,111,146); // #586f92 - mid blue
$color6: rgb(15,48,99); // #1B3D6D - dark blue
$color7: rgb(57,213,31); // #39d51f - green
$color8: rgb(52,197,26); // #34C51A - dark green
*/
/*Theming*/
:root {
  --loading-login-screen-background:
          linear-gradient(to right, rgba(15, 48, 99, 0) 0%, rgba(75, 107, 155, .4) 25%, rgba(168, 187, 215, .4) 42%, rgba(168, 187, 215, .4) 58%, rgba(75, 107, 155, .4) 75%, rgba(15, 48, 99, 0) 100%),
          url(".././assets/images/pattern-block-lines.png") repeat;
  --loading-screen-logo: url(".././assets/images/bakerhill-nextgen-logo-white.png");
  --login-box-logo: url(".././assets/images/bakerhill-nextgen-logo.png");
  --primary-dark-color: #1B3D6D;
  --primary-color: #456aa1;
  --primary-color-darken-7percent: #3a5a88;
  --primary-color-darken-11percent: #34507a;
  --primary-color-lighten-10percent: #5f84ba;
  --primary-color-lighten-20percent: #829fca;
  --primary-color-lighten-40percent: #cad6e8;
  --primary-light1-color: #a6bad9;
  --primary-light1-color-darken-5percent:#94acd1;
  --primary-light1-color-darken-10percent: #829eca;
  --primary-light1-color-darken-15percent: #7090c2;
  --primary-light1-color-lighten-6percent: #bbcbe2;
  --primary-white-color: #e6ecf2;
  --primary-white-color-darken-2percent: #dfe7ef;
  --primary-white-color-darken-6percent: #d2dde8;
  --primary-white-color-lighten-2percent: #edf1f5;
  --primary-white-color-lighten-4percent: #f3f6f9;
  --primary-color2: #cddc29;
  --primary-color3: #81BD41;
  --primary-color3-lighten-40percent: #e5f2d8;
  --primary-color3-darken-5percent: #74aa3a;
  --primary-dark2-color: #689834;
  --primary-dark2-color-darken-10percent: #4e7227;
  --primary-light2-color: #e6f2d9;
  --primary-light2-color-darken-2percent: #e1efd1;
  --primary-light2-color-darken-6percent: #d7eac2;
  --primary-light2-color-darken-10percent: #cde5b3;
  --grayscale-color: #58595B;
  --grayscale-color-lighten-25percent: #98999b;
  --grayscale-light-color: #bdbdc2;
  --grayscale-light-color-darken-8percent: #a8a8ae;
  --grayscale-light-color-lighten-7percent: #d0d0d3;
  --grayscale-white-color: #ebebed;
  --grayscale-white-color-darken-5percent: #dedee1;
  --grayscale-white-color-darken-8percent: #d6d6da;
  --grayscale-white-color-lighten-2percent: #f0f0f2;
  --grayscale-white-color-lighten-5percent: #f8f8f9;
  --white-color: #ffffff;
  --black-color: #000000;
  --secondary-dark-color: #007299;
  --secondary-color: #77256c;
  --secondary-color2: #f68d29;
  --secondary-color3: #ffd240;
  --secondary-color3-darken-3percent: #ffce31;
  --secondary-color3-lighten-17percent: #ffe697;
  --secondary-color3-lighten-27percent: #fff2ca;
  --secondary-bright-color: #ece815;
  --error-color: #cc0000;
  --error-color-darken-10percent: #990000;
  --error-color-lighten-50percent: #ffcccc;
  --error-color-lighten-55percent: #ffe6e6;
  --error-light-color: #ffe6e6;
  --error-light-color-darken-5percent: #ffcdcd;
  --disabled-color: #abbcd5;
  --disabled-color2: #9badca;
  --disabled-color3: #a7d17a;
  --default-sprite-position: top left;
  --active-hover-sprite-position: top right;
  /*remaing colors*/
  --snow-gray: #fcfcfc;
  --light-gainsboro-gray: #f7f7f7;
  --white-smoke: #f4f4f4;
  --very-light-gray: #f0f0f0;
  --light-pink: #febbbb;
  --light-yellow: #ffffe5;
  --orange-peel: #ff9900;
  --electric-yellow: #ffff00;
  --yellow-green: #effadb;
  --whisper-gray: #ededed;
  --dark-whisper-gray: #eeeeee;
  --white-dark-smoke: #e6e6e6;
  --gainsboro-gray: #e0e0e0;
  --medium-gray: #cccccc;
  --silver: #c6c6c6;
  --silver-very-light: #b9b9b9;
  --silver-light: #bbbbbb;
  --blue-smoke: #eef1f7;
  --ivory-red: #f8f8f2;
  --spun-pearl: #afafb6;
  --very-light-blue: #e6ebf5;
  --dark-gainsboro-gray: #d9d9d9;
  --bright-gainsboro-gray: #dddddd;
  --hawakes-blue: #ccd6eb;
  --blue-violet: #9933ff;
  --nobel-gray: #999999;
  --light-danube-blue: #89a4cd;
  --matte-gray: #888888;
  --light-matte-gray: #787878;
  --dark-gray: #777777;
  --raven-gray: #707275;
  --danube-blue: #6685c2;
  --dim-gray: #666666;
  --davy-grey: #555555;
  --analog-blue: #435f8b;
  --charcoal-gray: #444444;
  --mariner-blue: #335cad;
  --dark-charcoal-gray: #333333;
  --green-waterloo: #23241f;
  --nero-dark: #222222;
  --smalt-blue: #003399;
  --deep-green: #008a00;
  --navy-blue: #0066cc;
  --bondi-blue: #0085c7;
  --violation-text: #cc0000;
  --violation-bg: #ffe6e6;
  --warning-text: #f68d29;
  --warning-bg: #fff2ca;
  --highcharts-color-2: #cddc29;
  --highcharts-color-3: #007299;
  --highcharts-color-6: #689834;
  --highcharts-color-7: #a6bad9;
}

[data-theme=fiserv] {
  --loading-login-screen-background: #666666;
  --loading-screen-logo: url(".././assets/images/Fiserv-logo-1200x603.png");
  --login-box-logo: url(".././assets/images/Fiserv-logo-1200x603.png");
  --primary-dark-color: #333333;
  --primary-color: #666666;
  --primary-color-darken-7percent: #545454;
  --primary-color-darken-11percent: #4a4a4a;
  --primary-color-lighten-10percent: gray;
  --primary-color-lighten-20percent: #999999;
  --primary-color-lighten-40percent: #cccccc;
  --primary-light1-color: #bfbfbf;
  --primary-light1-color-darken-5percent: #b2b2b2;
  --primary-light1-color-darken-10percent: #a6a6a6;
  --primary-light1-color-darken-15percent: #999999;
  --primary-light1-color-lighten-6percent: #cecece;
  --primary-white-color: #efefef;
  --primary-white-color-darken-2percent: #eaeaea;
  --primary-white-color-darken-6percent: #e0e0e0;
  --primary-white-color-lighten-2percent: #f4f4f4;
  --primary-white-color-lighten-4percent: #f9f9f9;
  --primary-color2: #4695d1;
  --primary-color3: #ff6600;
  --primary-color3-lighten-40percent: #ffe0cc;
  --primary-color3-darken-5percent: #e65c00;
  --primary-dark2-color: #ea6b15;
  --primary-dark2-color-darken-10percent: #bb5611;
  --primary-light2-color: #f2d3c4;
  --primary-light2-color-darken-2percent: #f0cdbc;
  --primary-light2-color-darken-6percent: #ecc0ab;
  --primary-light2-color-darken-10percent: #e9b49a;
  --grayscale-color: #666666;
  --grayscale-color-lighten-25percent: #a6a6a6;
  --grayscale-light-color: #e0e0e0;
  --grayscale-light-color-darken-8percent: #cccccc;
  --grayscale-light-color-lighten-7percent: #f2f2f2;
  --grayscale-white-color: #e0e0e0;
  --grayscale-white-color-darken-5percent: lightgray;
  --grayscale-white-color-darken-8percent: #cccccc;
  --grayscale-white-color-lighten-2percent: #e5e5e5;
  --grayscale-white-color-lighten-5percent: #ededed;
  --white-color: #ffffff;
  --black-color: #0e0e0e;
  --secondary-dark-color: #007299;
  --secondary-color: #77256c;
  --secondary-color2: #81bd41;
  --secondary-color3: #ffd240;
  --secondary-color3-darken-3percent: #ffce31;
  --secondary-color3-lighten-17percent: #ffe697;
  --secondary-color3-lighten-27percent: #fff2ca;
  --secondary-bright-color: #ece815;
  --error-color: #dd3435;
  --error-color-darken-10percent: #be2021;
  --error-color-lighten-50percent: white;
  --error-color-lighten-55percent: white;
  --error-light-color: #eabec0;
  --error-light-color-darken-5percent: #e4abad;
  --disabled-color: #c9c9c9;
  --disabled-color2: #c9c9c9;
  --disabled-color3: #dd9e73;
  --default-sprite-position: bottom left;
  --active-hover-sprite-position: bottom right;
  /*remaing colors*/
  --snow-gray: #fcfcfc;
  --light-gainsboro-gray: #f7f7f7;
  --white-smoke: #f4f4f4;
  --very-light-gray: #f0f0f0;
  --light-pink: #febbbb;
  --light-yellow: #ffffe5;
  --orange-peel: #ff9900;
  --electric-yellow: #ffff00;
  --yellow-green: #effadb;
  --whisper-gray: #ededed;
  --dark-whisper-gray: #eeeeee;
  --white-dark-smoke: #e6e6e6;
  --gainsboro-gray: #e0e0e0;
  --medium-gray: #cccccc;
  --silver: #c6c6c6;
  --silver-very-light: #b9b9b9;
  --silver-light: #bbbbbb;
  --blue-smoke: #eef1f7;
  --ivory-red: #f8f8f2;
  --spun-pearl: #afafb6;
  --very-light-blue: #e6ebf5;
  --dark-gainsboro-gray: #d9d9d9;
  --bright-gainsboro-gray: #dddddd;
  --hawakes-blue: #ccd6eb;
  --blue-violet: #9933ff;
  --nobel-gray: #999999;
  --light-danube-blue: #89a4cd;
  --matte-gray: #888888;
  --light-matte-gray: #787878;
  --dark-gray: #777777;
  --raven-gray: #707275;
  --danube-blue: #6685c2;
  --dim-gray: #666666;
  --davy-grey: #555555;
  --analog-blue: #435f8b;
  --charcoal-gray: #444444;
  --mariner-blue: #335cad;
  --dark-charcoal-gray: #333333;
  --green-waterloo: #23241f;
  --nero-dark: #222222;
  --smalt-blue: #003399;
  --deep-green: #008a00;
  --navy-blue: #0066cc;
  --bondi-blue: #0085c7;
  --violation-text: #cc0000;
  --violation-bg: #ffe6e6;
  --warning-text: #f68d29;
  --warning-bg: #fff2ca;
  --highcharts-color-2: #ffa366;
  --highcharts-color-3: #777777;
  --highcharts-color-6: #e65c00;
  --highcharts-color-7: #e0e0e0;
}

/*Sprite Positions*/
/*Images*/
/*Primary*/
/*Grayscale*/
/*Secondary*/
/*disabled*/
/*remaing colors*/
.darkblue {
  color: var(--primary-dark-color);
}

.blue {
  color: var(--primary-color);
}

.lightblue {
  color: var(--primary-light1-color);
}

.whiteblue {
  color: var(--primary-white-color);
}

.yellowgreen {
  color: var(--primary-color2);
}

.green {
  color: var(--primary-color3) !important;
}

.darkgreen {
  color: var(--primary-dark2-color);
}

.lightgreen {
  color: var(--primary-light2-color);
}

.gray {
  color: var(--grayscale-color);
}

.lightgray {
  color: var(--grayscale-light-color);
}

.whitegray {
  color: var(--grayscale-white-color);
}

.darkcyan {
  color: var(--secondary-dark-color);
}

.purple {
  color: var(--secondary-color);
}

.orange {
  color: var(--secondary-color2) !important;
}

.yellow {
  color: var(--secondary-color3);
}

.brightyellow {
  color: var(--secondary-bright-color);
}

.red {
  color: var(--error-color) !important;
}

.lightred {
  color: var(--error-light-color);
}

.white {
  color: var(--white-color) !important;
}

.darkblue-bg {
  background-color: var(--primary-dark-color);
}

.blue-bg {
  background-color: var(--primary-color) !important;
}

.lightblue-bg {
  background-color: var(--primary-light1-color);
}

.whiteblue-bg {
  background-color: var(--primary-white-color);
}

.yellowgreen-bg {
  background-color: var(--primary-color2);
}

.green-bg {
  background-color: var(--primary-color3);
}

.darkgreen-bg {
  background-color: var(--primary-dark2-color);
}

.lightgreen-bg {
  background-color: var(--primary-light2-color);
}

.gray-bg {
  background-color: var(--grayscale-color);
}

.lightgray-bg {
  background-color: var(--grayscale-light-color);
}

.whitegray-bg {
  background-color: var(--grayscale-white-color);
}

.darkcyan-bg {
  background-color: var(--secondary-dark-color);
}

.purple-bg {
  background-color: var(--secondary-color);
}

.orange-bg {
  background-color: var(--secondary-color2) !important;
}

.yellow-bg {
  background-color: var(--secondary-color3) !important;
}

.brightyellow-bg {
  background-color: var(--secondary-bright-color) !important;
}

.red-bg {
  background-color: var(--error-color) !important;
}

.lightred-bg {
  background-color: var(--error-light-color) !important;
}

/* Wijmo Control */
/* Primary wj control style - applies to all controls */
.wj-content {
  display: inline-block;
  background-color: white;
  outline: none;
  box-sizing: border-box;
  /* set display: inline-table so height:100% works properly even without <!DOCTYPE html> tag */
}
.wj-content.wj-dropdown, .wj-content.wj-inputnumber, .wj-content.wj-inputmask, .wj-content.wj-calendar-outer, .wj-content.wj-pager {
  display: inline-table;
}
.wj-content.wj-listbox {
  border: 1px solid var(--grayscale-light-color);
}
.wj-content input[type=radio],
.wj-content input[type=checkbox] {
  position: relative;
  top: 2px;
  margin-right: 6px;
  margin-left: 2px;
  transform: scale(1.25);
}
.wj-content.wj-dropdown, .wj-content.wj-inputnumber {
  margin-top: 4px;
  width: 100%;
}
.wj-content.wj-dropdown .wj-input-group .wj-form-control[readonly]:not([disabled]) {
  background-color: white;
}
.wj-content.wj-dropdown:not(.wj-state-collapsed) .wj-input-group-btn .wj-btn {
  background-color: var(--primary-color);
  border: 1px solid var(--primary-color);
  border-bottom: 3px solid var(--primary-color-darken-7percent);
}
.wj-content.wj-dropdown:not(.wj-state-collapsed) .wj-input-group-btn .wj-btn .wj-glyph-down {
  color: white !important;
}

.wj-control {
  outline: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/*
  Required to make contenteditable work on iOS
  http://stackoverflow.com/questions/21295698/html5-contenteditable-attribute-not-working-properly-on-ios7-mobile-safari
*/
.wj-control [contenteditable] {
  -webkit-user-select: text;
  user-select: text;
}

/* Define control header */
.wj-header {
  background-color: var(--grayscale-light-color);
  color: var(--grayscale-color);
  font-weight: bold;
}

.wj-state-disabled,
.wj-control[disabled] {
  background-color: var(--grayscale-white-color);
  cursor: not-allowed;
}
.wj-state-disabled .wj-input-group-btn,
.wj-control[disabled] .wj-input-group-btn {
  cursor: not-allowed;
  pointer-events: none;
}

/* Readonly elements (via CSS) */
/*.wj-state-readonly,
.wj-control[readonly] {
    background-color: $whitegray;
}*/
/* Button Styles */
/* Basic style for all button types */
button,
html input[type=button],
input[type=reset],
input[type=submit] {
  -webkit-appearance: button;
  overflow: visible;
  border-radius: inherit;
  cursor: pointer;
}

/* Global base button style */
.wj-btn {
  /*display: block;*/
  padding: 0 10px;
  vertical-align: top;
  text-align: center;
  white-space: nowrap;
  cursor: pointer;
}

/* Additional styling for default buttons */
.wj-btn-default {
  border: 1px solid rgba(0, 0, 0, 0.2);
  background-color: transparent;
  color: inherit;
  /*font-weight: bold;*/
}

/* Hover on 'default' button */
.wj-btn-default:hover {
  background-color: rgba(0, 0, 0, 0.1);
}

.wj-btn-default:focus {
  background-color: rgba(0, 0, 0, 0.1);
}

/* Link Buttons in Wijmo controls*/
.wj-control button[wj-part^=btn-] {
  display: inline-block;
  margin-bottom: 0;
  font-weight: normal;
  text-align: center;
  vertical-align: middle;
  touch-action: manipulation;
  cursor: pointer;
  white-space: nowrap;
  padding: 10px;
  font-size: 14px;
  line-height: 1.42857143;
  border-radius: 4px;
  user-select: none;
  transition-duration: 0.2s;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.08), 0 1px 1px rgba(0, 0, 0, 0.16) !important;
  outline: none !important;
  background-color: var(--primary-color);
  color: #fff;
  border-color: var(--primary-color);
}

/* Button Group Styles */
/* Define button group based on bootstrap */
.wj-btn-group,
.wj-btn-group-vertical {
  position: relative;
  display: inline-block;
  border-radius: 4px;
  vertical-align: top;
}

/* Remove inner button border radius */
.wj-btn-group > .wj-btn:not(:first-child):not(:last-child) {
  border-radius: 0;
}

.wj-btn-group > .wj-btn:first-child {
  margin-left: 0;
}

/* Remove first button right-side border radius and margin */
.wj-btn-group > .wj-btn:first-child:not(:last-child) {
  border-right: none;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

/* Remove last button left-side border radius and margin */
.wj-btn-group > .wj-btn:last-child:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

/* Remove margin from inner buttons to prevent double border */
.wj-btn-group .wj-btn + .wj-btn,
.wj-btn-group .wj-btn + .wj-btn-group,
.wj-btn-group .wj-btn-group + .wj-btn,
.wj-btn-group .wj-btn-group + .wj-btn-group {
  margin-left: -1px;
}

/* ListBox Styles */
.wj-listbox {
  overflow: auto;
  cursor: default;
}

.wj-listbox-item {
  box-sizing: border-box;
  padding: 3px 10px;
}
.wj-listbox-item.wj-separator {
  height: 1px;
  margin: 3px 0;
  padding: 0;
  background-color: rgba(0, 0, 0, 0.1);
}
.wj-listbox-item.wj-select-all {
  background-color: transparent;
  border-bottom: 1px solid var(--primary-color);
  padding-top: 5px;
}
.wj-listbox-item.wj-select-all label {
  font-weight: bold;
}
.wj-listbox-item.wj-state-selected, .wj-listbox-item:not(.wj-state-selected):not(.wj-state-disabled):not(.wj-separator):hover {
  background-color: var(--primary-white-color);
}

.wj-listbox-item.wj-state-selected input[type=checkbox]:focus {
  outline: none;
}

.wj-listbox .wj-listbox-item label {
  font-weight: normal;
  margin: 0;
}

.wj-listbox-item label span[wj-part=sp-select-all] {
  font-weight: bold;
}

.wj-dropdown .wj-listbox {
  padding: 0;
  white-space: nowrap;
}

.wj-listbox-item.wj-state-selected .wj-control {
  background: var(--white-color);
  color: var(--charcoal-gray);
}

/* Dropdown Styles */
.wj-dropdown {
  vertical-align: middle;
}

.wj-dropdown .wj-template,
.wj-dropdown .wj-dropdown-menu {
  border-radius: inherit;
}

.wj-template {
  height: 100%;
}

.wj-dropdown-panel {
  outline: none;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  /* Boostrap modals have zIndex 1050, so go higher */
  z-index: 1500 !important;
  border: 1px solid var(--primary-color);
}

.wj-autocomplete-match {
  font-weight: bold;
}

/* Input Group Styles */
/* Remove spinner buttons from InputNumber control */
.wj-inputnumber input[type=number]::-webkit-inner-spin-button,
.wj-inputnumber input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Remove clear icon from IE textbox */
input::-ms-clear {
  display: none;
}

/* Remove default browser focus outlines */
button,
input,
optgroup,
select,
textarea {
  outline: 0;
}

.wj-inputnumber {
  vertical-align: middle;
}

.wj-input-group-btn,
.wj-input-group .wj-form-control {
  box-sizing: border-box;
  display: table-cell;
}

.wj-input {
  height: 100%;
  overflow: hidden;
}
.wj-input input {
  width: 100%;
}

/* Define input group based on bootstrap */
.wj-input-group {
  position: relative;
  display: table;
  overflow: hidden;
  width: 100%;
  height: 100%;
  border-collapse: separate;
  background: inherit;
}
.wj-input-group .wj-btn {
  font-size: 20px;
  padding: 0;
  width: 38px;
  height: 36px;
  border-radius: 0;
  border-bottom: 3px solid var(--grayscale-light-color);
}
.wj-input-group .wj-btn:hover, .wj-input-group .wj-btn:focus {
  border-color: var(--primary-color);
  border-bottom: 3px solid var(--primary-color-darken-7percent);
  background-color: var(--primary-color);
}
.wj-input-group .wj-btn:hover [class^=wj-glyph-], .wj-input-group .wj-btn:focus [class^=wj-glyph-] {
  color: white;
}

/* Style for text input box in group */
.wj-input-group .wj-form-control {
  display: block;
  width: 100%;
  background-image: none;
  border: 1px solid #ccc;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  -webkit-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  -o-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  color: var(--grayscale-color);
  height: auto;
  border-bottom: 3px solid var(--grayscale-light-color);
  min-height: 36px;
}

.wj-form-group-sm .wj-input-group .wj-form-control .form-control {
  font-size: 0.875rem;
  min-height: 30px;
  height: 30px;
  padding: 2px 10px 0;
  border-radius: 0;
  line-height: 2;
}

/* Text alignment for numeric input types */
.wj-input-group .wj-form-control.wj-numeric {
  text-align: right;
}

/* Adjust float, width, and padding for Menu control */
.wj-input-group div[wj-part=header] {
  float: none;
  width: auto;
  vertical-align: middle;
  padding: 6px 8px;
}

/* RTL borders */
/* Remove first button left-side border radius */
[dir=rtl] .wj-input-group-btn:first-child > .wj-btn {
  border-left-width: 1px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  /* Unset LTR border styles */
  border-right-width: 0;
  border-top-right-radius: inherit;
  border-bottom-right-radius: inherit;
}

/* Remove last button right-side border radius */
[dir=rtl] .wj-input-group-btn:last-child > .wj-btn {
  border-right-width: 1px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  /* Unset LTR border styles */
  border-left-width: 0;
  border-top-left-radius: inherit;
  border-bottom-left-radius: inherit;
}

/* Style for buttons in group */
.wj-input-group-btn {
  position: relative;
  width: 28px;
  height: 100%;
  vertical-align: top;
  white-space: nowrap;
}

/* Pager Styles */
.wj-pager {
  vertical-align: middle;
  margin-left: 5px;
  margin-right: 5px;
}
.wj-pager .wj-input-group-btn > .wj-btn {
  min-width: 40px;
}
.wj-pager .wj-btn[disabled] span {
  opacity: 0.5;
  cursor: default;
}
.wj-pager .wj-form-control {
  text-align: center;
  border-left: 1px solid rgba(0, 0, 0, 0.2);
  border-right: 1px solid rgba(0, 0, 0, 0.2);
}

/* Calendar Date Input Styles */
.wj-calendar-outer {
  display: block;
  padding: 10px;
  width: 100%;
  height: auto;
  cursor: default;
}

.wj-calendar td {
  text-align: center;
}

/* In dropdown, set width to avoid flicker as the user navigates months (in IE only...) */
.wj-dropdown-panel.wj-calendar {
  width: 20em;
}

/* In dropdown, remove background and border */
.wj-dropdown-panel .wj-calendar-outer {
  border: 0;
  background: none;
}

.wj-day-today {
  font-weight: bold;
}

.wj-day-othermonth {
  opacity: 0.5;
}

/* -- Date selection -- */
/* Added padding to bottom of date selection options */
.wj-calendar-header {
  display: block;
  padding: 0 0 15px 0;
  width: 100%;
}

/* Float month selection to the left */
.wj-month-select {
  float: left;
  display: inline-block;
  cursor: pointer;
}

/* Float calendar button group to the right */
.wj-calendar-header .wj-btn-group {
  float: right;
  display: inline-block;
  margin-bottom: 7px;
}

/* Size button group in calendar header */
.wj-calendar-header .wj-btn-group .wj-btn {
  padding: 0 8px 1px 8px;
  min-height: 25px;
}

/* -- Days of the month -- */
.wj-calendar-month {
  box-sizing: border-box;
  width: 100%;
  border-collapse: collapse;
  font: inherit;
}

.wj-calendar-month td {
  width: 14.29%;
  padding: 5px 0;
  border: none;
}

/* Slightly reduce size of text in calendar header */
.wj-calendar-month .wj-header {
  font-size: 90%;
}

/* -- Months of the year -- */
.wj-calendar-year {
  box-sizing: border-box;
  width: 100%;
  border-collapse: collapse;
  font: inherit;
}

.wj-calendar-year td {
  width: 25%;
  padding: 8px;
  border: none;
}

/* -- FlexGrid -- */
.wj-flexgrid .wj-cell:not(.wj-frozen):not(.wj-frozen-col) {
  z-index: auto !important;
}

.wj-flexgrid {
  width: 100%;
  overflow: hidden;
  border: 1px solid var(--primary-light1-color-darken-10percent);
  position: relative;
  top: -1px;
  /* Group row background */
  /* Selection Marquee */
  /* Grid drag/resize row/col marker */
}
.wj-flexgrid .wj-colheaders .wj-header, .wj-flexgrid .wj-topleft .wj-header {
  border-bottom: 2px solid var(--primary-light1-color-darken-10percent);
  background: var(--primary-white-color-darken-2percent) linear-gradient(to bottom, var(--primary-white-color), var(--primary-white-color) 50%, var(--primary-white-color-darken-6percent));
  color: var(--grayscale-color);
  font-weight: normal;
  text-align: left !important;
  padding: 0;
  min-height: 38px;
  /* Selected headers */
}
.wj-flexgrid .wj-colheaders .wj-header.wj-sort-desc:after, .wj-flexgrid .wj-colheaders .wj-header.wj-sort-asc:after, .wj-flexgrid .wj-topleft .wj-header.wj-sort-desc:after, .wj-flexgrid .wj-topleft .wj-header.wj-sort-asc:after {
  content: "";
}
.wj-flexgrid .wj-colheaders .wj-header .wj-glyph-up, .wj-flexgrid .wj-colheaders .wj-header .wj-glyph-down, .wj-flexgrid .wj-topleft .wj-header .wj-glyph-up, .wj-flexgrid .wj-topleft .wj-header .wj-glyph-down {
  padding: 0 4px;
  font-size: 0.9625rem;
  line-height: 1;
}
.wj-flexgrid .wj-colheaders .wj-header .blue-background, .wj-flexgrid .wj-topleft .wj-header .blue-background {
  padding: 8px 10px;
  background: var(--primary-white-color-darken-2percent) linear-gradient(to bottom, var(--primary-white-color), var(--primary-white-color) 50%, var(--primary-white-color-darken-6percent));
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.wj-flexgrid .wj-colheaders .wj-header .green-background, .wj-flexgrid .wj-topleft .wj-header .green-background {
  padding: 8px 10px;
  background: var(--primary-light2-color-darken-2percent) linear-gradient(to bottom, var(--primary-light2-color), var(--primary-light2-color-darken-6percent));
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.wj-flexgrid .wj-colheaders .wj-header .has-filter, .wj-flexgrid .wj-topleft .wj-header .has-filter {
  padding-right: 30px;
}
.wj-flexgrid .wj-colheaders .wj-header .word-wrap, .wj-flexgrid .wj-topleft .wj-header .word-wrap {
  white-space: normal;
  background: transparent none;
}
.wj-flexgrid .wj-colheaders .wj-header.wj-state-multi-selected, .wj-flexgrid .wj-topleft .wj-header.wj-state-multi-selected {
  background-color: var(--primary-light2-color);
  color: var(--grayscale-color);
}
.wj-flexgrid .wj-colheaders .wj-header .btn-col .btn, .wj-flexgrid .wj-topleft .wj-header .btn-col .btn {
  top: 5px;
  left: 10px;
}
.wj-flexgrid .wj-colheaders .wj-header .dropdown-menu, .wj-flexgrid .wj-topleft .wj-header .dropdown-menu {
  min-width: 125px;
}
.wj-flexgrid .wj-colheaders .wj-header .dropdown-menu > li:hover, .wj-flexgrid .wj-colheaders .wj-header .dropdown-menu > li:focus, .wj-flexgrid .wj-topleft .wj-header .dropdown-menu > li:hover, .wj-flexgrid .wj-topleft .wj-header .dropdown-menu > li:focus {
  background-color: var(--grayscale-white-color);
}
.wj-flexgrid .wj-colfooters .wj-header {
  border-top: 2px solid var(--primary-light1-color-darken-10percent);
  background-color: var(--grayscale-white-color);
  font-weight: bold;
}
.wj-flexgrid .wj-rowheaders .wj-header {
  background: var(--primary-white-color-darken-2percent) linear-gradient(to bottom, var(--primary-white-color), var(--primary-white-color-darken-6percent));
  cursor: move;
}
.wj-flexgrid .wj-rowheaders .wj-header:before, .wj-flexgrid .wj-rowheaders .wj-header:after {
  font-family: "FontAwesome", sans-serif;
  content: "\f142";
  font-size: 18px;
  color: var(--primary-light1-color);
  position: absolute;
  left: 9px;
}
.wj-flexgrid .wj-rowheaders .wj-header:after {
  left: 16px;
}
.wj-flexgrid .wj-rowheaders .wj-header:hover::before, .wj-flexgrid .wj-rowheaders .wj-header:hover::after {
  color: var(--primary-color);
}
.wj-flexgrid .wj-cell {
  position: absolute;
  overflow: hidden;
  box-sizing: border-box;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding: 8px 10px;
  border-right: 1px solid var(--grayscale-light-color);
  border-bottom: 1px solid var(--grayscale-light-color);
  outline: none;
  background: white;
  /*Edit button column - width defined in html property*/
  /*Override boolean datatype*/
  /* Frozen area boundaries */
  /* Alternate grid cell color */
  /*Wijmo Selected States */
  /* Text color for nested controls in selected rows */
}
.wj-flexgrid .wj-cell.btn-col:not(.wj-group), .wj-flexgrid .wj-cell.btn2-col:not(.wj-group) {
  padding: 5px 10px !important;
  overflow: visible;
}
.wj-flexgrid .wj-cell.btn-col:not(.wj-group) .btn ~ .btn, .wj-flexgrid .wj-cell.btn2-col:not(.wj-group) .btn ~ .btn {
  float: right;
}
.wj-flexgrid .wj-cell.btn-col:not(.wj-group) .checked-bkg, .wj-flexgrid .wj-cell.btn2-col:not(.wj-group) .checked-bkg {
  width: 100%;
  height: 100%;
  background-color: var(--primary-light2-color);
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
}
.wj-flexgrid .wj-cell.dynamic-col:not(.wj-group) {
  padding: 4px 10px 6px;
  overflow: visible;
}
.wj-flexgrid .wj-cell.dynamic-col:not(.wj-group) .checked-bkg {
  width: 100%;
  height: 100%;
  background-color: var(--primary-light2-color);
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
}
.wj-flexgrid .wj-cell.wj-frozen:nth-of-type(1) {
  overflow: visible;
  z-index: 10 !important;
}
.wj-flexgrid .wj-cell.wj-frozen:nth-of-type(2) {
  overflow: visible;
  z-index: 9 !important;
}
.wj-flexgrid .wj-cell.wj-frozen:nth-of-type(3) {
  overflow: visible;
  z-index: 8 !important;
}
.wj-flexgrid .wj-cell.wj-frozen:nth-of-type(4) {
  overflow: visible;
  z-index: 7 !important;
}
.wj-flexgrid .wj-cell.wj-frozen:nth-of-type(5) {
  overflow: visible;
  z-index: 6 !important;
}
.wj-flexgrid .wj-cell.wj-frozen:nth-of-type(6) {
  overflow: visible;
  z-index: 5 !important;
}
.wj-flexgrid .wj-cell.wj-frozen:nth-of-type(7) {
  overflow: visible;
  z-index: 4 !important;
}
.wj-flexgrid .wj-cell.wj-frozen:nth-of-type(8) {
  overflow: visible;
  z-index: 3 !important;
}
.wj-flexgrid .wj-cell.wj-frozen:nth-of-type(9) {
  overflow: visible;
  z-index: 2 !important;
}
.wj-flexgrid .wj-cell.wj-frozen:nth-of-type(10) {
  overflow: visible;
  z-index: 1 !important;
}
.wj-flexgrid .wj-cell.btn3-col:not(.wj-group) {
  padding: 0 0 !important;
  overflow: visible;
}
.wj-flexgrid .wj-cell.btn3-col:not(.wj-group) .btn {
  margin-right: 2px;
}
.wj-flexgrid .wj-cell.btn3-col:not(.wj-group) .btn:last-child {
  margin-right: none;
}
.wj-flexgrid .wj-cell.btn3-col:not(.wj-group) div {
  margin: 5px auto;
  width: 82px;
}
.wj-flexgrid .wj-cell.dynamic-col.wj-group, .wj-flexgrid .wj-cell.btn-col.wj-group, .wj-flexgrid .wj-cell.btn2-col.wj-group {
  padding: 4px 10px 6px;
  overflow: visible;
  z-index: 1 !important;
}
.wj-flexgrid .wj-cell.dynamic-col.wj-group .btn ~ .btn, .wj-flexgrid .wj-cell.btn-col.wj-group .btn ~ .btn, .wj-flexgrid .wj-cell.btn2-col.wj-group .btn ~ .btn {
  float: right;
}
.wj-flexgrid .wj-cell.wj-group[role=gridcell]:not(.wj-frozen) {
  z-index: unset !important;
}
.wj-flexgrid .wj-cell input[type=checkbox].wj-cell-check {
  visibility: hidden;
  top: 0;
  margin: 0;
}
.wj-flexgrid .wj-cell input[type=checkbox].wj-cell-check:checked::after {
  font-family: "FontAwesome", sans-serif;
  content: "\f00c";
  font-size: 0.875rem;
  position: absolute;
  color: var(--primary-color3);
  visibility: visible;
}
.wj-flexgrid .wj-cell.wj-frozen-row {
  border-bottom: 1px solid var(--primary-light1-color-darken-10percent);
}
.wj-flexgrid .wj-cell.wj-frozen-col {
  border-right: 1px solid var(--primary-light1-color-darken-10percent);
}
.wj-flexgrid .wj-cell.wj-alt {
  background: var(--grayscale-white-color-lighten-2percent);
}
.wj-flexgrid .wj-cell.row-selected {
  background-color: var(--primary-light2-color);
}
.wj-flexgrid .wj-cell.row-active-search {
  background-color: var(--primary-light2-color);
}
.wj-flexgrid .wj-cell.row-disabled {
  cursor: not-allowed;
  opacity: 0.65;
}
.wj-flexgrid .wj-cell.row-disabled.btn-col button {
  display: none;
}
.wj-flexgrid .wj-cell.checkbox-disabled {
  cursor: not-allowed;
}
.wj-flexgrid .wj-cell.checkbox-disabled button.btn {
  display: block;
  pointer-events: none;
  opacity: 0.65;
}
.wj-flexgrid .wj-cell.wj-state-selected .wj-control, .wj-flexgrid .wj-cell.wj-state-multi-selected .wj-control {
  color: var(--grayscale-color);
}
.wj-flexgrid .wj-group,
.wj-flexgrid .wj-cell.wj-group {
  background-color: var(--primary-color);
  border-bottom: 2px solid var(--primary-light1-color);
  color: white;
  border-right: 0;
  /*height: 37px !important;*/
}
.wj-flexgrid .wj-group .wj-elem-collapse,
.wj-flexgrid .wj-cell.wj-group .wj-elem-collapse {
  padding: 0;
  background-color: var(--primary-color);
  border: 0;
  width: 26px;
  line-height: 22px;
  display: inline-block;
  text-align: center;
  position: relative;
  top: 3px;
  font-size: 22px;
  font-weight: bold;
}
.wj-flexgrid .wj-group .wj-elem-collapse span,
.wj-flexgrid .wj-cell.wj-group .wj-elem-collapse span {
  color: white;
}
.wj-flexgrid .wj-marquee {
  position: absolute;
  box-sizing: border-box;
  border: 2px solid var(--bondi-blue);
}
.wj-flexgrid .wj-marker {
  position: absolute;
  background-color: var(--primary-color3);
  pointer-events: none;
}
.wj-flexgrid.wj-state-focused .wj-cell:not(.btn-col):not(.check-col):not(.wj-group).wj-state-selected {
  background: var(--primary-white-color-lighten-4percent);
}
.wj-flexgrid.wj-state-focused .wj-cell:not(.btn-col):not(.check-col):not(.wj-group).column-editable.wj-state-selected, .wj-flexgrid.wj-state-focused .wj-cell:not(.btn-col):not(.check-col):not(.wj-group).wj-state-multi-selected {
  background: var(--primary-light2-color);
}

.flexgrid-treeview .wj-state-focused .wj-cell.wj-group.wj-state-selected {
  background: var(--primary-white-color-lighten-4percent);
}
.flexgrid-treeview .wj-state-focused .wj-cell.wj-group.column-editable.wj-state-selected, .flexgrid-treeview .wj-state-focused .wj-cell.wj-group.wj-state-multi-selected {
  background: var(--primary-light2-color);
}

.pagination-container .wj-inputnumber {
  margin: 0;
  width: auto;
}

.flexgrid-single-select .wj-flexgrid .wj-colheaders .wj-header:first-of-type,
.flexgrid-multi-select .wj-flexgrid .wj-colheaders .wj-header:nth-of-type(-n + 2),
.wj-flexgrid .wj-topleft .wj-header {
  overflow: visible;
}
.flexgrid-single-select .wj-flexgrid .wj-colheaders .wj-header:first-of-type:hover::after,
.flexgrid-multi-select .wj-flexgrid .wj-colheaders .wj-header:nth-of-type(-n + 2):hover::after,
.wj-flexgrid .wj-topleft .wj-header:hover::after {
  content: "";
}

.flexgrid-multi-select .wj-flexgrid .wj-colheaders .wj-header:first-of-type {
  z-index: 2 !important;
}

.flexgrid-multi-select {
  position: relative;
}
.flexgrid-multi-select .dropdown-menu {
  min-width: 125px;
  top: 34px;
  left: 10px;
}
.flexgrid-multi-select .dropdown-menu > li:hover, .flexgrid-multi-select .dropdown-menu > li:focus {
  background-color: var(--grayscale-white-color);
}
.flexgrid-multi-select .dropdown-menu.opened {
  display: block;
}

.wj-flexgrid .wj-header:not(.wj-filter-off) {
  overflow: visible;
}

.flexgrid-pagination .wj-flexgrid .pagination-btn-container {
  position: absolute;
  left: 10px;
  top: 5px;
}
.flexgrid-pagination .wj-flexgrid .pagination-btn-container .btn + .btn {
  margin-left: 10px;
}
.flexgrid-pagination .wj-flexgrid .pagination-btn-container + .pagination-btn-container {
  left: 56px;
}
.flexgrid-pagination .wj-flexgrid [wj-part=root] {
  overflow-y: hidden !important;
}

/*.flexgrid-hide-sorting-hover .wj-flexgrid .wj-colheaders .wj-header:hover::after,
.flexgrid-hide-sorting-hover .wj-flexgrid .wj-topleft .wj-header:hover::after {
    display: none;
}*/
.flexgrid-no-sorting .wj-flexgrid .wj-colheaders .wj-header,
.flexgrid-no-sorting .wj-flexgrid .wj-topleft .wj-header {
  cursor: default;
}

.folder-icons .wj-flexgrid .wj-group .wj-glyph-right,
.folder-icons .wj-flexgrid .wj-group .wj-glyph-down-right {
  margin-right: 12px !important;
  top: 5px !important;
  left: 2px;
}
.folder-icons .wj-flexgrid .wj-group .wj-glyph-right:after {
  content: "\f07b";
}
.folder-icons .wj-flexgrid .wj-group .wj-glyph-down-right:after {
  content: "\f07c";
}
.folder-icons .flexgrid-treeview .wj-flexgrid .wj-group .wj-elem-collapse {
  margin-right: 5px !important;
  border: none;
  color: var(--primary-color);
  background-color: transparent;
}
.folder-icons .flexgrid-treeview .wj-flexgrid .wj-group .wj-elem-collapse:hover {
  color: var(--primary-light1-color);
  background-color: transparent;
}
.folder-icons .flexgrid-treeview .wj-flexgrid .wj-group .wj-elem-collapse:after {
  top: 4px;
  right: 4px;
}
.folder-icons .flexgrid-treeview .wj-flexgrid .wj-group .wj-elem-collapse.wj-glyph-right, .folder-icons .flexgrid-treeview .wj-flexgrid .wj-group .wj-elem-collapse.wj-glyph-down-right {
  background-color: transparent;
  top: -3px !important;
  color: var(--primary-color);
}
.folder-icons .flexgrid-treeview .wj-flexgrid .wj-group .wj-elem-collapse.wj-glyph-right:hover, .folder-icons .flexgrid-treeview .wj-flexgrid .wj-group .wj-elem-collapse.wj-glyph-down-right:hover {
  color: var(--primary-light1-color);
  background-color: transparent;
}

/* FlexGrid Filter Dropdown */
.wj-header .wj-elem-filter {
  border: 0;
  float: right;
  cursor: pointer;
  padding: 0 4px;
  background-color: var(--primary-light1-color);
  border-radius: 3px;
  color: white;
  position: absolute;
  right: 8px;
  top: 8px;
  line-height: 1.428571429;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.08), 0 1px 1px rgba(0, 0, 0, 0.16);
}
.wj-header .wj-elem-filter:hover {
  background-color: var(--primary-color);
}

[dir=rtl] .wj-header .wj-elem-filter {
  float: left;
}

.wj-header.wj-filter-on,
.wj-header.wj-filter-off {
  padding-right: 32px;
}

.wj-filter-on .wj-elem-filter {
  background-color: var(--primary-color3);
}
.wj-filter-on .wj-elem-filter:hover {
  background-color: var(--primary-dark2-color);
}

.wj-header .wj-glyph-filter:after {
  font-family: "FontAwesome", sans-serif;
  content: "\f0b0";
  font-size: 13px;
}

.wj-columnfiltereditor {
  padding: 15px;
  min-width: 300px !important;
  max-width: 50%;
}
.wj-columnfiltereditor .wj-listbox {
  max-height: 165px;
}
.wj-columnfiltereditor div[wj-part^=div-sort] {
  display: none;
  border-bottom: 1px solid var(--grayscale-light-color);
}
.wj-columnfiltereditor div[wj-part^=div-type] {
  font-size: 0.875rem;
  text-align: left;
  border-bottom: 1px solid var(--grayscale-light-color);
  margin-bottom: 10px;
  width: 100%;
  height: 36px;
  color: white;
}
.wj-columnfiltereditor div[wj-part^=div-type] a[wj-part=a-cnd], .wj-columnfiltereditor div[wj-part^=div-type] a[wj-part=a-val] {
  border: 1px solid var(--grayscale-light-color);
  border-radius: 4px 4px 0 0;
  font-weight: normal !important;
  color: var(--grayscale-color);
  padding: 7px 12px;
  position: absolute;
}
.wj-columnfiltereditor div[wj-part^=div-type] a[wj-part=a-cnd]:hover, .wj-columnfiltereditor div[wj-part^=div-type] a[wj-part=a-cnd]:focus, .wj-columnfiltereditor div[wj-part^=div-type] a[wj-part=a-val]:hover, .wj-columnfiltereditor div[wj-part^=div-type] a[wj-part=a-val]:focus {
  color: var(--grayscale-color);
  background-color: var(--primary-white-color);
}
.wj-columnfiltereditor div[wj-part^=div-type] a[wj-part=a-val] {
  top: 15px;
  left: 15px;
}
.wj-columnfiltereditor div[wj-part^=div-type] a[wj-part=a-cnd] {
  top: 15px;
  left: 142px;
}
.wj-columnfiltereditor div[wj-part^=div-edt-val] div[wj-part^=div-values] div:not(.wj-listbox-item):before {
  content: "No Results Found";
  position: relative;
  top: 3px;
  left: 10px;
}
.wj-columnfiltereditor div[wj-part^=div-edt-cnd]:before, .wj-columnfiltereditor div[wj-part^=div-edt-val]:before {
  content: "";
  background-color: var(--primary-color);
  color: white;
  border: 1px solid var(--primary-color);
  border-radius: 4px 4px 0 0;
  padding: 7px 12px;
  position: absolute;
}
.wj-columnfiltereditor div[wj-part^=div-edt-val]:before {
  content: "Filter by Value";
  top: 15px;
  left: 15px;
  font-size: 14px;
}
.wj-columnfiltereditor div[wj-part^=div-edt-cnd]:before {
  content: "Filter by Condition";
  top: 15px;
  left: 142px;
  font-size: 14px;
}
.wj-columnfiltereditor div[wj-part^=div-edt-cnd] label {
  float: left;
  width: 30%;
  margin: 10px 0 12px;
}
.wj-columnfiltereditor .wj-control {
  margin-bottom: 6px;
  width: 100%;
}
.wj-columnfiltereditor .wj-listbox-item.wj-state-selected {
  background-color: transparent;
}
.wj-columnfiltereditor .wj-listbox .wj-listbox-item label {
  overflow: visible;
  white-space: nowrap;
}

.modal .wj-columnfiltereditor {
  max-width: 300px;
}

/* Selected column headers */
.wj-colheaders .wj-header.wj-state-multi-selected {
  border-bottom: 2px solid var(--bondi-blue);
}

/* Selected row headers */
.wj-rowheaders .wj-header.wj-state-multi-selected {
  border-right: 2px solid var(--bondi-blue);
}

/* Sticky headers */
.wj-state-sticky .wj-header {
  opacity: 0.75;
}

/* Invalid state */
.wj-cell.wj-state-invalid {
  border: 1.2px solid red;
}

.wj-rowheaders .wj-cell.wj-state-invalid {
  background: rgba(255, 0, 0, 0.3);
}

.wj-cells .wj-cell.wj-state-invalid:after {
  position: absolute;
  content: "";
  width: 0;
  right: 0;
  top: -6px;
  border: 6px solid transparent;
  border-right: 6px solid red;
}

/* Cells with word-wrapping */
.wj-cell.wj-wrap {
  white-space: normal;
  text-overflow: clip;
}

/*Duplicate check striping*/
.dupe-check-striping .wj-cells .wj-cell:nth-child(36n-17), .dupe-check-striping .wj-cells .wj-cell:nth-child(36n-16), .dupe-check-striping .wj-cells .wj-cell:nth-child(36n-15), .dupe-check-striping .wj-cells .wj-cell:nth-child(36n-14), .dupe-check-striping .wj-cells .wj-cell:nth-child(36n-13), .dupe-check-striping .wj-cells .wj-cell:nth-child(36n-12), .dupe-check-striping .wj-cells .wj-cell:nth-child(36n-11), .dupe-check-striping .wj-cells .wj-cell:nth-child(36n-10), .dupe-check-striping .wj-cells .wj-cell:nth-child(36n-9), .dupe-check-striping .wj-cells .wj-cell:nth-child(36n-8), .dupe-check-striping .wj-cells .wj-cell:nth-child(36n-7), .dupe-check-striping .wj-cells .wj-cell:nth-child(36n-6), .dupe-check-striping .wj-cells .wj-cell:nth-child(36n-5), .dupe-check-striping .wj-cells .wj-cell:nth-child(36n-4), .dupe-check-striping .wj-cells .wj-cell:nth-child(36n-3), .dupe-check-striping .wj-cells .wj-cell:nth-child(36n-2), .dupe-check-striping .wj-cells .wj-cell:nth-child(36n-1), .dupe-check-striping .wj-cells .wj-cell:nth-child(36n) {
  background: var(--grayscale-white-color-lighten-2percent);
}

/* Treeview */
.flexgrid-treeview .tree-btn-container {
  position: absolute;
  left: 10px;
  top: 5px;
}
.flexgrid-treeview .tree-btn-container .btn + .btn {
  margin-left: 10px;
}
.flexgrid-treeview .tree-btn-container + .tree-btn-container {
  left: 56px;
}
.flexgrid-treeview .treeview-shadebox {
  border-left: 1px solid var(--grayscale-light-color);
}
.flexgrid-treeview .border-line,
.flexgrid-treeview .border-line-2 {
  width: 1px;
  height: 100%;
  background-color: var(--grayscale-light-color);
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  left: 46px;
}

.flexgrid-treeview.flexgrid-single-select .treeview-shift,
.flexgrid-treeview.flexgrid-multi-select .treeview-shift {
  padding-left: 46px;
}
.flexgrid-treeview.flexgrid-single-select .treeview-shadebox,
.flexgrid-treeview.flexgrid-multi-select .treeview-shadebox {
  left: 46px;
}

.flexgrid-treeview.flexgrid-single-select.flexgrid-two-buttons .treeview-shift {
  padding-left: 82px;
}
.flexgrid-treeview.flexgrid-single-select.flexgrid-two-buttons .treeview-shadebox,
.flexgrid-treeview.flexgrid-single-select.flexgrid-two-buttons .border-line-2 {
  left: 82px;
}

.flexgrid-treeview.flexgrid-multi-select.flexgrid-two-buttons .treeview-shift {
  padding-left: 92px;
}
.flexgrid-treeview.flexgrid-multi-select.flexgrid-two-buttons .treeview-shadebox,
.flexgrid-treeview.flexgrid-multi-select.flexgrid-two-buttons .border-line-2 {
  left: 92px;
}

/* Treeview Group row */
.flexgrid-treeview .wj-flexgrid .wj-group {
  background-color: white;
  color: var(--grayscale-color);
  border-bottom: 1px solid var(--grayscale-light-color);
  border-right: 1px solid var(--grayscale-light-color);
}
.flexgrid-treeview .wj-flexgrid .wj-group .wj-elem-collapse {
  color: white;
  width: 15px;
  height: 17px;
  margin-right: 10px;
  font-size: 16px;
  font-weight: bold;
  padding: 1px;
  border: 1px solid var(--primary-color);
  background-color: var(--primary-color);
  border-radius: 2px;
  line-height: 15px;
  top: 1px;
  /*opened*/
}
.flexgrid-treeview .wj-flexgrid .wj-group .wj-elem-collapse:after {
  position: relative;
  bottom: 1px;
}
.flexgrid-treeview .wj-flexgrid .wj-group .wj-elem-collapse:hover {
  background-color: var(--primary-light1-color);
  color: white;
}
.flexgrid-treeview .wj-flexgrid .wj-group .wj-elem-collapse.wj-glyph-down-right {
  color: var(--primary-color);
  background-color: white;
}
.flexgrid-treeview .wj-flexgrid .wj-group .wj-elem-collapse.wj-glyph-down-right:hover {
  background-color: var(--primary-white-color);
}
.flexgrid-treeview .wj-flexgrid .wj-group.wj-alt {
  background: var(--grayscale-white-color-lighten-2percent);
}
.flexgrid-treeview .wj-flexgrid .wj-group.row-selected {
  background-color: var(--primary-light2-color);
}

/* Grid editor */
.wj-flexgrid .wj-grid-editor {
  box-sizing: border-box;
  padding: 7px 10px;
  border: 1px solid var(--primary-color3);
  width: calc(100% + 20px);
  position: relative;
  left: -10px;
}
.wj-flexgrid .wj-grid-editor.wj-combobox {
  padding: 0;
}

.wj-cell .wj-elem-dropdown {
  position: absolute;
  top: 0;
  padding: 3px 6px;
  opacity: 0.25;
  right: 0;
}

[dir=rtl] .wj-cell .wj-elem-dropdown {
  left: 0;
  right: auto;
}

/* Switch cell borders in RTL grids */
[dir=rtl] .wj-cell {
  border-left: 1px solid var(--silver);
  border-right: none;
}

/* Switch frozen borders in RTL grids */
[dir=rtl] .wj-frozen-col {
  border-left: 1px solid var(--dim-gray);
  border-right: none;
}

/* FlexGrid GroupPanel extension */
.wj-grouppanel {
  background-color: var(--white-smoke);
  padding: 15px;
}

.wj-grouppanel .wj-cell {
  margin-right: 10px;
  padding: 6px 16px;
  border: 1px solid var(--white-dark-smoke);
  cursor: pointer;
}

.wj-grouppanel .wj-cell:hover {
  background: var(--gainsboro-gray);
}

/* FlexGrid MultiRow extension */
.wj-multirow .wj-cell.wj-record-end:not(.wj-header) {
  border-bottom-color: var(--light-matte-gray);
}

.wj-multirow .wj-cell.wj-group-end {
  border-right-color: var(--light-matte-gray);
}

.wj-multirow .wj-hdr-collapse {
  position: absolute;
  padding: 6px;
  right: 0;
  top: 0;
  color: var(--bondi-blue);
  opacity: 0.5;
}

.wj-multirow .wj-hdr-collapse:hover {
  opacity: 1;
}

/* FlexSheet extension */
.wj-flexsheet .wj-marquee {
  position: absolute;
  box-sizing: border-box;
  border: 2px solid var(--bondi-blue);
}

.wj-flexsheet .wj-state-multi-selected {
  background: var(--white-dark-smoke);
  color: var(--nero-dark);
}

.wj-flexsheet .wj-cell.wj-state-multi-selected {
  border-right: 1px solid var(--silver-light);
  border-bottom: 1px solid var(--silver-light);
}

/* Tooltip */
.wj-tooltip {
  position: absolute;
  z-index: 1000;
  top: 0;
  left: 0;
  pointer-events: none;
  max-width: 400px;
  padding: 6px;
  background-color: var(--light-yellow);
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  box-sizing: border-box;
}

/* ColorPicker */
.wj-inputcolor .wj-form-control {
  padding-left: 34px !important;
}
.wj-inputcolor .wj-inputcolorbox {
  left: 10px !important;
  top: 9px !important;
  width: 15px !important;
  bottom: 11px !important;
  border: 1px solid var(--grayscale-color) !important;
  border-radius: 2px;
}

.wj-colorpicker {
  width: 420px;
  height: 200px;
  padding: 4px;
}

.wj-colorbox {
  position: relative;
  width: 100%;
  height: 100%;
  cursor: pointer;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}

/* wj-popup */
.wj-popup {
  background-color: var(--white-color);
  box-shadow: 0 3px 9px rgba(0, 0, 0, 0.5);
  z-index: 1500;
  margin: 2px 0;
}

.wj-popup-backdrop {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 1500;
  background-color: rgba(0, 0, 0, 0.5);
}

/**************************************************************************
    Glyphs
*/
.wj-glyph-up,
.wj-glyph-down,
.wj-glyph-right,
.wj-glyph-down-right {
  color: var(--primary-color);
  cursor: pointer;
  font-size: 26px;
  font-family: "FontAwesome", sans-serif;
}

.wj-glyph-up:after {
  content: "\f0d8";
}

.wj-glyph-down:after {
  content: "\f0d7";
}

.wj-glyph-right:after {
  content: "\f105";
}

.wj-glyph-down-right:after {
  content: "\f107";
}

/*.wj-glyph-up {
    position: relative;
    display: inline-block;
    border-right: .4em solid transparent;
    border-bottom: .5em solid;
    border-left: .4em solid transparent;
    opacity: 0.75;
    cursor: pointer;
}

.wj-glyph-down {
    position: relative;
    display: inline-block;
    border-top: .5em solid;
    border-right: .4em solid transparent;
    border-left: .4em solid transparent;
    opacity: 0.75;
    cursor: pointer;
}*/
.wj-glyph-left,
.wj-glyph-step-backward {
  position: relative;
  display: inline-block;
  border-top: 0.4em solid transparent;
  border-right: 0.5em solid;
  border-bottom: 0.4em solid transparent;
  opacity: 0.75;
  cursor: pointer;
}

.wj-glyph-step-backward {
  transform: translateX(0.12em);
}

.wj-glyph-step-backward:after {
  position: absolute;
  border-left: 0.2em solid;
  height: 0.75em;
  transform: translate(-100%, -50%);
  content: "";
}

/*.wj-glyph-right,
.wj-glyph-step-forward {
    position: relative;
    display: inline-block;
    border-top: .4em solid transparent;
    border-bottom: .4em solid transparent;
    border-left: .5em solid;
    opacity: 0.75;
    cursor: pointer;
}*/
.wj-glyph-step-forward:after {
  position: absolute;
  border-left: 0.2em solid;
  height: 0.75em;
  transform: translateY(-50%);
  content: "";
}

.wj-glyph-left-rtl,
.wj-glyph-step-backward-rtl {
  position: relative;
  display: inline-block;
  border-top: 0.4em solid transparent;
  border-bottom: 0.4em solid transparent;
  border-left: 0.5em solid;
  opacity: 0.75;
  cursor: pointer;
}

.wj-glyph-step-backward-rtl:after {
  position: absolute;
  border-left: 0.2em solid;
  height: 0.75em;
  transform: translate(100%, -50%);
  content: "";
}

.wj-glyph-right-rtl,
.wj-glyph-step-forward-rtl {
  position: relative;
  display: inline-block;
  border-top: 0.4em solid transparent;
  border-right: 0.5em solid;
  border-bottom: 0.4em solid transparent;
  opacity: 0.75;
  cursor: pointer;
}

.wj-glyph-step-forward-rtl {
  transform: translateX(0.12em);
}

.wj-glyph-step-forward-rtl:after {
  position: absolute;
  border-left: 0.2em solid;
  height: 0.75em;
  transform: translateY(-50%);
  content: "";
}

.wj-glyph-down-left {
  position: relative;
  display: inline-block;
  border-top: 0.65em solid transparent;
  border-left: 0.65em solid;
  opacity: 0.75;
  cursor: pointer;
}

/*.wj-glyph-down-right {
    position: relative;
    display: inline-block;
    border-bottom: .65em solid;
    border-left: .65em solid transparent;
    opacity: 0.75;
    cursor: pointer;
}*/
.wj-glyph-up-left {
  position: relative;
  display: inline-block;
  border-bottom: 0.65em solid transparent;
  border-left: 0.65em solid;
  opacity: 0.75;
  cursor: pointer;
}

.wj-glyph-up-right {
  position: relative;
  display: inline-block;
  border-top: 0.65em solid;
  border-left: 0.65em solid transparent;
  opacity: 0.75;
  cursor: pointer;
}

.wj-glyph-circle {
  position: relative;
  display: inline-block;
  border: 0.25em solid;
  border-radius: 1em;
  transform: translateY(-0.1em);
  opacity: 0.75;
  cursor: pointer;
}

.wj-glyph-square {
  position: relative;
  display: inline-block;
  border: 0.25em solid;
  opacity: 0.75;
  cursor: pointer;
}

.wj-glyph-diamond {
  position: relative;
  display: inline-block;
  border: 0.25em solid;
  transform: rotate(45deg);
  transform-origin: 50% 50%;
  opacity: 0.75;
  cursor: pointer;
}

.wj-glyph-check {
  position: relative;
  display: inline-block;
  width: 0.75em;
  height: 0.75em;
  border-right: 0.3em solid;
  border-bottom: 0.22em solid;
  transform: rotate(35deg) scaleX(0.5);
  opacity: 0.75;
  cursor: pointer;
}

/*.wj-glyph-filter {
    position: relative;
    box-sizing: border-box;
    display: inline-block;
    top: -.1em;
    width: .5em;
    border-top: .5em solid;
    border-right: .4em solid transparent;
    border-left: .4em solid transparent;
    opacity: 0.75;
    cursor: pointer;
}
    .wj-glyph-filter:after {
        position: absolute;
        box-sizing: border-box;
        border-left: .2em solid;
        height: .4em;
        transform: translateX(-50%);
        top: -.2em;
        content: "";
    }

[dir="rtl"] .wj-glyph-filter:after {
    transform: translateX(50%);
}*/
.wj-glyph-plus,
.wj-glyph-minus {
  position: relative;
  box-sizing: border-box;
  display: inline-block;
  border-top: 0.25em solid;
  width: 0.9em;
  top: -0.2em;
  opacity: 0.75;
  cursor: pointer;
}

.wj-glyph-plus:after {
  position: absolute;
  box-sizing: border-box;
  border-left: 0.25em solid;
  width: 0.25em;
  height: 0.85em;
  left: 0.32em;
  top: -0.55em;
  content: "";
}

.wj-glyph-file {
  position: relative;
  display: inline-block;
  border-left: 0.7em solid;
  height: 0.85em;
  opacity: 0.75;
  cursor: pointer;
}

.wj-glyph-file:after {
  position: absolute;
  transform: translateX(-100%);
  border-top: 0.3em solid transparent;
  border-left: 0.3em solid white;
  content: "";
}

.wj-glyph-calendar {
  position: relative;
  display: inline-block;
  top: 0.2em;
  width: 1em;
  height: 1em;
  border: 0.1em solid;
  border-top: 0.3em solid;
  opacity: 0.75;
  cursor: pointer;
}

.wj-glyph-calendar:after {
  position: absolute;
  font-size: 50%;
  top: -0.15em;
  left: 0.4em;
  content: "1";
}

.wj-glyph-clock {
  position: relative;
  display: inline-block;
  top: 0.2em;
  width: 1em;
  height: 1em;
  border: 0.13em solid;
  border-radius: 50%;
  opacity: 0.75;
  cursor: pointer;
}

.wj-glyph-clock:after {
  position: absolute;
  border-left: 0.1em solid;
  border-bottom: 0.1em solid;
  width: 0.3em;
  height: 0.4em;
  top: 0.05em;
  left: 0.32em;
  content: "";
}

.wj-glyph-pencil {
  position: relative;
  display: inline-block;
  width: 1em;
  height: 1em;
  opacity: 0.75;
}

.wj-glyph-pencil:after {
  position: absolute;
  left: 0;
  content: "✎";
}

.wj-glyph-asterisk {
  position: relative;
  display: inline-block;
  width: 1em;
  height: 1em;
  opacity: 0.75;
}

.wj-glyph-asterisk:after {
  position: absolute;
  left: 0;
  content: "✱";
}

.wj-glyph-asterisk-center {
  position: relative;
  display: inline-block;
  width: 1em;
  height: 1em;
  opacity: 0.75;
  vertical-align: middle;
  text-align: center;
}

.wj-glyph-asterisk-center:after {
  position: absolute;
  content: "✱";
}

/**** MultiAutoComplete styles ****/
.wj-multi-autocomplete {
  width: 100%;
  height: auto;
  padding: 5px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  margin-bottom: 10px;
}

.wj-multi-autocomplete .wj-input {
  float: left;
}

.wj-multi-autocomplete .wj-form-control {
  padding: 10px;
}

.wj-multi-autocomplete .wj-token {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  display: inline-block;
  float: left;
  border: 1px solid var(--dark-gainsboro-gray);
  background-color: var(--whisper-gray);
  white-space: nowrap;
  margin: 5px 5px 5px 5px;
  vertical-align: middle;
  cursor: default;
}

.wj-multi-autocomplete .wj-token .wj-token-label {
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 5px 5px 5px 10px;
  vertical-align: middle;
  max-width: 500px;
}

.wj-multi-autocomplete .wj-token:hover {
  border-color: var(--silver-very-light);
}

.wj-multi-autocomplete .wj-token.wj-token-active {
  border-color: var(--light-pink);
}

.wj-multi-autocomplete .wj-token-helper {
  position: absolute;
  left: -10000px;
}

.wj-multi-autocomplete .wj-token .wj-token-close {
  font-family: "Arial", sans-serif;
  display: inline-block;
  line-height: 100%;
  font-size: 1.1em;
  line-height: 1.49em;
  margin-left: 5px;
  float: none;
  height: 100%;
  vertical-align: middle;
  padding-right: 4px;
  cursor: pointer;
}

.wj-multi-autocomplete .wj-token .wj-token-close:hover,
.wj-multi-autocomplete .wj-token .wj-token-close:focus {
  color: var(--black-color);
  text-decoration: none;
  cursor: pointer;
  opacity: 0.5;
}

/**************************************************************************
REMOVED CSS for
    wijmo.grid.sheet module - Flexsheet
    wijmo.olap module - Pivot
    TreeView styles - actual control not flexgrid
    Report Viewer
    Flexchart
    Gauges
*/
.bh-reactive-tree .wj-cell {
  overflow: unset;
}

.bh-reactive-tree .wj-flexgrid .wj-colheaders .wj-header,
.bh-reactive-tree .wj-flexgrid .wj-topleft .wj-header {
  border-bottom: 1px solid var(--primary-light1-color-darken-10percent);
}

.bh-reactive-tree.flexgrid-treeview.flexgrid-single-select .treeview-shift,
.bh-reactive-tree.flexgrid-treeview.flexgrid-multi-select .treeview-shift {
  padding-left: 0;
}

.bh-reactive-tree.flexgrid-treeview.flexgrid-single-select .treeview-shadebox,
.bh-reactive-tree.flexgrid-treeview.flexgrid-multi-select .treeview-shadebox {
  left: 0;
}

.filter .form-group.input-group {
  width: 90%;
}

/* Old Colors

$color0: #ededed; // white gray
$color1: rgb(190,190,190); // #bebebe - light gray
$color2: rgb(80,80,80); // #505050 - gray
$color3: rgb(207,214,224); // #cfd6e0 - white blue
$color4: rgb(159,172,193); // #9facc1 - light blue
$color5: rgb(88,111,146); // #586f92 - mid blue
$color6: rgb(15,48,99); // #1B3D6D - dark blue
$color7: rgb(57,213,31); // #39d51f - green
$color8: rgb(52,197,26); // #34C51A - dark green
*/
/*Theming*/
:root {
  --loading-login-screen-background:
          linear-gradient(to right, rgba(15, 48, 99, 0) 0%, rgba(75, 107, 155, .4) 25%, rgba(168, 187, 215, .4) 42%, rgba(168, 187, 215, .4) 58%, rgba(75, 107, 155, .4) 75%, rgba(15, 48, 99, 0) 100%),
          url(".././assets/images/pattern-block-lines.png") repeat;
  --loading-screen-logo: url(".././assets/images/bakerhill-nextgen-logo-white.png");
  --login-box-logo: url(".././assets/images/bakerhill-nextgen-logo.png");
  --primary-dark-color: #1B3D6D;
  --primary-color: #456aa1;
  --primary-color-darken-7percent: #3a5a88;
  --primary-color-darken-11percent: #34507a;
  --primary-color-lighten-10percent: #5f84ba;
  --primary-color-lighten-20percent: #829fca;
  --primary-color-lighten-40percent: #cad6e8;
  --primary-light1-color: #a6bad9;
  --primary-light1-color-darken-5percent:#94acd1;
  --primary-light1-color-darken-10percent: #829eca;
  --primary-light1-color-darken-15percent: #7090c2;
  --primary-light1-color-lighten-6percent: #bbcbe2;
  --primary-white-color: #e6ecf2;
  --primary-white-color-darken-2percent: #dfe7ef;
  --primary-white-color-darken-6percent: #d2dde8;
  --primary-white-color-lighten-2percent: #edf1f5;
  --primary-white-color-lighten-4percent: #f3f6f9;
  --primary-color2: #cddc29;
  --primary-color3: #81BD41;
  --primary-color3-lighten-40percent: #e5f2d8;
  --primary-color3-darken-5percent: #74aa3a;
  --primary-dark2-color: #689834;
  --primary-dark2-color-darken-10percent: #4e7227;
  --primary-light2-color: #e6f2d9;
  --primary-light2-color-darken-2percent: #e1efd1;
  --primary-light2-color-darken-6percent: #d7eac2;
  --primary-light2-color-darken-10percent: #cde5b3;
  --grayscale-color: #58595B;
  --grayscale-color-lighten-25percent: #98999b;
  --grayscale-light-color: #bdbdc2;
  --grayscale-light-color-darken-8percent: #a8a8ae;
  --grayscale-light-color-lighten-7percent: #d0d0d3;
  --grayscale-white-color: #ebebed;
  --grayscale-white-color-darken-5percent: #dedee1;
  --grayscale-white-color-darken-8percent: #d6d6da;
  --grayscale-white-color-lighten-2percent: #f0f0f2;
  --grayscale-white-color-lighten-5percent: #f8f8f9;
  --white-color: #ffffff;
  --black-color: #000000;
  --secondary-dark-color: #007299;
  --secondary-color: #77256c;
  --secondary-color2: #f68d29;
  --secondary-color3: #ffd240;
  --secondary-color3-darken-3percent: #ffce31;
  --secondary-color3-lighten-17percent: #ffe697;
  --secondary-color3-lighten-27percent: #fff2ca;
  --secondary-bright-color: #ece815;
  --error-color: #cc0000;
  --error-color-darken-10percent: #990000;
  --error-color-lighten-50percent: #ffcccc;
  --error-color-lighten-55percent: #ffe6e6;
  --error-light-color: #ffe6e6;
  --error-light-color-darken-5percent: #ffcdcd;
  --disabled-color: #abbcd5;
  --disabled-color2: #9badca;
  --disabled-color3: #a7d17a;
  --default-sprite-position: top left;
  --active-hover-sprite-position: top right;
  /*remaing colors*/
  --snow-gray: #fcfcfc;
  --light-gainsboro-gray: #f7f7f7;
  --white-smoke: #f4f4f4;
  --very-light-gray: #f0f0f0;
  --light-pink: #febbbb;
  --light-yellow: #ffffe5;
  --orange-peel: #ff9900;
  --electric-yellow: #ffff00;
  --yellow-green: #effadb;
  --whisper-gray: #ededed;
  --dark-whisper-gray: #eeeeee;
  --white-dark-smoke: #e6e6e6;
  --gainsboro-gray: #e0e0e0;
  --medium-gray: #cccccc;
  --silver: #c6c6c6;
  --silver-very-light: #b9b9b9;
  --silver-light: #bbbbbb;
  --blue-smoke: #eef1f7;
  --ivory-red: #f8f8f2;
  --spun-pearl: #afafb6;
  --very-light-blue: #e6ebf5;
  --dark-gainsboro-gray: #d9d9d9;
  --bright-gainsboro-gray: #dddddd;
  --hawakes-blue: #ccd6eb;
  --blue-violet: #9933ff;
  --nobel-gray: #999999;
  --light-danube-blue: #89a4cd;
  --matte-gray: #888888;
  --light-matte-gray: #787878;
  --dark-gray: #777777;
  --raven-gray: #707275;
  --danube-blue: #6685c2;
  --dim-gray: #666666;
  --davy-grey: #555555;
  --analog-blue: #435f8b;
  --charcoal-gray: #444444;
  --mariner-blue: #335cad;
  --dark-charcoal-gray: #333333;
  --green-waterloo: #23241f;
  --nero-dark: #222222;
  --smalt-blue: #003399;
  --deep-green: #008a00;
  --navy-blue: #0066cc;
  --bondi-blue: #0085c7;
  --violation-text: #cc0000;
  --violation-bg: #ffe6e6;
  --warning-text: #f68d29;
  --warning-bg: #fff2ca;
  --highcharts-color-2: #cddc29;
  --highcharts-color-3: #007299;
  --highcharts-color-6: #689834;
  --highcharts-color-7: #a6bad9;
}

[data-theme=fiserv] {
  --loading-login-screen-background: #666666;
  --loading-screen-logo: url(".././assets/images/Fiserv-logo-1200x603.png");
  --login-box-logo: url(".././assets/images/Fiserv-logo-1200x603.png");
  --primary-dark-color: #333333;
  --primary-color: #666666;
  --primary-color-darken-7percent: #545454;
  --primary-color-darken-11percent: #4a4a4a;
  --primary-color-lighten-10percent: gray;
  --primary-color-lighten-20percent: #999999;
  --primary-color-lighten-40percent: #cccccc;
  --primary-light1-color: #bfbfbf;
  --primary-light1-color-darken-5percent: #b2b2b2;
  --primary-light1-color-darken-10percent: #a6a6a6;
  --primary-light1-color-darken-15percent: #999999;
  --primary-light1-color-lighten-6percent: #cecece;
  --primary-white-color: #efefef;
  --primary-white-color-darken-2percent: #eaeaea;
  --primary-white-color-darken-6percent: #e0e0e0;
  --primary-white-color-lighten-2percent: #f4f4f4;
  --primary-white-color-lighten-4percent: #f9f9f9;
  --primary-color2: #4695d1;
  --primary-color3: #ff6600;
  --primary-color3-lighten-40percent: #ffe0cc;
  --primary-color3-darken-5percent: #e65c00;
  --primary-dark2-color: #ea6b15;
  --primary-dark2-color-darken-10percent: #bb5611;
  --primary-light2-color: #f2d3c4;
  --primary-light2-color-darken-2percent: #f0cdbc;
  --primary-light2-color-darken-6percent: #ecc0ab;
  --primary-light2-color-darken-10percent: #e9b49a;
  --grayscale-color: #666666;
  --grayscale-color-lighten-25percent: #a6a6a6;
  --grayscale-light-color: #e0e0e0;
  --grayscale-light-color-darken-8percent: #cccccc;
  --grayscale-light-color-lighten-7percent: #f2f2f2;
  --grayscale-white-color: #e0e0e0;
  --grayscale-white-color-darken-5percent: lightgray;
  --grayscale-white-color-darken-8percent: #cccccc;
  --grayscale-white-color-lighten-2percent: #e5e5e5;
  --grayscale-white-color-lighten-5percent: #ededed;
  --white-color: #ffffff;
  --black-color: #0e0e0e;
  --secondary-dark-color: #007299;
  --secondary-color: #77256c;
  --secondary-color2: #81bd41;
  --secondary-color3: #ffd240;
  --secondary-color3-darken-3percent: #ffce31;
  --secondary-color3-lighten-17percent: #ffe697;
  --secondary-color3-lighten-27percent: #fff2ca;
  --secondary-bright-color: #ece815;
  --error-color: #dd3435;
  --error-color-darken-10percent: #be2021;
  --error-color-lighten-50percent: white;
  --error-color-lighten-55percent: white;
  --error-light-color: #eabec0;
  --error-light-color-darken-5percent: #e4abad;
  --disabled-color: #c9c9c9;
  --disabled-color2: #c9c9c9;
  --disabled-color3: #dd9e73;
  --default-sprite-position: bottom left;
  --active-hover-sprite-position: bottom right;
  /*remaing colors*/
  --snow-gray: #fcfcfc;
  --light-gainsboro-gray: #f7f7f7;
  --white-smoke: #f4f4f4;
  --very-light-gray: #f0f0f0;
  --light-pink: #febbbb;
  --light-yellow: #ffffe5;
  --orange-peel: #ff9900;
  --electric-yellow: #ffff00;
  --yellow-green: #effadb;
  --whisper-gray: #ededed;
  --dark-whisper-gray: #eeeeee;
  --white-dark-smoke: #e6e6e6;
  --gainsboro-gray: #e0e0e0;
  --medium-gray: #cccccc;
  --silver: #c6c6c6;
  --silver-very-light: #b9b9b9;
  --silver-light: #bbbbbb;
  --blue-smoke: #eef1f7;
  --ivory-red: #f8f8f2;
  --spun-pearl: #afafb6;
  --very-light-blue: #e6ebf5;
  --dark-gainsboro-gray: #d9d9d9;
  --bright-gainsboro-gray: #dddddd;
  --hawakes-blue: #ccd6eb;
  --blue-violet: #9933ff;
  --nobel-gray: #999999;
  --light-danube-blue: #89a4cd;
  --matte-gray: #888888;
  --light-matte-gray: #787878;
  --dark-gray: #777777;
  --raven-gray: #707275;
  --danube-blue: #6685c2;
  --dim-gray: #666666;
  --davy-grey: #555555;
  --analog-blue: #435f8b;
  --charcoal-gray: #444444;
  --mariner-blue: #335cad;
  --dark-charcoal-gray: #333333;
  --green-waterloo: #23241f;
  --nero-dark: #222222;
  --smalt-blue: #003399;
  --deep-green: #008a00;
  --navy-blue: #0066cc;
  --bondi-blue: #0085c7;
  --violation-text: #cc0000;
  --violation-bg: #ffe6e6;
  --warning-text: #f68d29;
  --warning-bg: #fff2ca;
  --highcharts-color-2: #ffa366;
  --highcharts-color-3: #777777;
  --highcharts-color-6: #e65c00;
  --highcharts-color-7: #e0e0e0;
}

/*Sprite Positions*/
/*Images*/
/*Primary*/
/*Grayscale*/
/*Secondary*/
/*disabled*/
/*remaing colors*/
.darkblue {
  color: var(--primary-dark-color);
}

.blue {
  color: var(--primary-color);
}

.lightblue {
  color: var(--primary-light1-color);
}

.whiteblue {
  color: var(--primary-white-color);
}

.yellowgreen {
  color: var(--primary-color2);
}

.green {
  color: var(--primary-color3) !important;
}

.darkgreen {
  color: var(--primary-dark2-color);
}

.lightgreen {
  color: var(--primary-light2-color);
}

.gray {
  color: var(--grayscale-color);
}

.lightgray {
  color: var(--grayscale-light-color);
}

.whitegray {
  color: var(--grayscale-white-color);
}

.darkcyan {
  color: var(--secondary-dark-color);
}

.purple {
  color: var(--secondary-color);
}

.orange {
  color: var(--secondary-color2) !important;
}

.yellow {
  color: var(--secondary-color3);
}

.brightyellow {
  color: var(--secondary-bright-color);
}

.red {
  color: var(--error-color) !important;
}

.lightred {
  color: var(--error-light-color);
}

.white {
  color: var(--white-color) !important;
}

.darkblue-bg {
  background-color: var(--primary-dark-color);
}

.blue-bg {
  background-color: var(--primary-color) !important;
}

.lightblue-bg {
  background-color: var(--primary-light1-color);
}

.whiteblue-bg {
  background-color: var(--primary-white-color);
}

.yellowgreen-bg {
  background-color: var(--primary-color2);
}

.green-bg {
  background-color: var(--primary-color3);
}

.darkgreen-bg {
  background-color: var(--primary-dark2-color);
}

.lightgreen-bg {
  background-color: var(--primary-light2-color);
}

.gray-bg {
  background-color: var(--grayscale-color);
}

.lightgray-bg {
  background-color: var(--grayscale-light-color);
}

.whitegray-bg {
  background-color: var(--grayscale-white-color);
}

.darkcyan-bg {
  background-color: var(--secondary-dark-color);
}

.purple-bg {
  background-color: var(--secondary-color);
}

.orange-bg {
  background-color: var(--secondary-color2) !important;
}

.yellow-bg {
  background-color: var(--secondary-color3) !important;
}

.brightyellow-bg {
  background-color: var(--secondary-bright-color) !important;
}

.red-bg {
  background-color: var(--error-color) !important;
}

.lightred-bg {
  background-color: var(--error-light-color) !important;
}

/*CSS3 loaders http://cssdeck.com/labs/css3-loader-newtons-cradle */
.loading-screen {
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: space-around;
  background: var(--loading-login-screen-background);
}
.loading-screen .loading-screen-center {
  position: relative;
  width: 300px;
  height: 200px;
  margin: 0 auto;
}
.loading-screen .loading-screen-center .logo {
  width: 300px;
  height: 122px;
  margin: 0 auto 40px;
  background-image: var(--loading-screen-logo);
  background-size: contain;
  background-repeat: no-repeat;
}
.loading-screen .loading-screen-center .load-box {
  width: 14px;
  height: 14px;
  margin: 0 13px;
  border-radius: 3px;
  float: left;
  animation-fill-mode: both;
  /*animation: top 1s ease-in-out 0s infinite; /*v1*/
  /*animation: fade 1.4s ease 0s infinite; /*v2 v3*/
  /*animation-name: fade, spin; /*v3*/
  animation: wave 1.2s ease 0s infinite alternate-reverse; /*v4*/
}
.loading-screen .loading-screen-center .load-box.first {
  margin-left: 20px;
}
.loading-screen .loading-screen-center .load-box:nth-child(2) {
  background: var(--secondary-color2);
  /*animation-name: top; /*v1*/
  /*animation-delay: .2s; v2 v3*/
  animation-delay: 0.075s; /*v4*/
}
.loading-screen .loading-screen-center .load-box:nth-child(3) {
  background: var(--primary-dark-color);
  /*animation-name: bottom; /*v1*/
  /*animation-delay: .4s; /*v2 v3*/
  animation-delay: 0.15s; /*v4*/
}
.loading-screen .loading-screen-center .load-box:nth-child(4) {
  background: var(--primary-color2);
  /*animation-name: top; /*v1*/
  /*animation-delay: .6s; /*v2 v3*/
  animation-delay: 0.225s; /*v4*/
}
.loading-screen .loading-screen-center .load-box:nth-child(5) {
  background: var(--primary-light1-color);
  /*animation-name: bottom; /*v1*/
  /*animation-delay: .8s; /*v2 v3*/
  animation-delay: 0.3s; /*v4*/
}
.loading-screen .loading-screen-center .load-box:nth-child(6) {
  background: var(--primary-color3);
  /*animation-name: top; /*v1*/
  /*animation-delay: 1.0s; /*v2 v3*/
  animation-delay: 0.375s; /*v4*/
}
.loading-screen .loading-screen-center .load-box:nth-child(7) {
  background: var(--secondary-color3);
  /*animation-name: bottom; /*v1*/
  /*animation-delay: 1.2s; /*v2 v3*/
  animation-delay: 0.45s; /*v4*/
}
.loading-screen .loading-screen-center .load-box:nth-child(8) {
  background: var(--secondary-color);
  /*animation-name: top; /*v1*/
  /*animation-delay: 1.4s; /*v2 v3*/
  animation-delay: 0.525s; /*v4*/
}
@keyframes top {
  0%, 100% {
    transform: translateY(-8px);
  }
  50% {
    transform: translateY(8px);
  }
}
@keyframes bottom {
  0%, 100% {
    transform: translateY(8px);
  }
  50% {
    transform: translateY(-8px);
  }
}
@keyframes fade {
  0%, 100% {
    opacity: 0.1;
  }
  20% {
    opacity: 1;
  }
}
@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}
@keyframes wave {
  0%, 40%, 100% {
    transform: translate(0, 0) scale(1, 1);
  }
  10% {
    transform: translate(0, -20px) scale(1.25, 1.25);
  }
}

.login-container {
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--loading-login-screen-background);
}

.accent-lines-left {
  position: fixed;
  left: 0;
  top: 15%;
  width: 220px;
  height: 55px;
  background-image: url(".././assets/images/accent-lines-left-lg.png");
}

.accent-lines-right {
  position: fixed;
  right: 0;
  bottom: 15%;
  width: 223px;
  height: 51px;
  background-image: url(".././assets/images/accent-lines-right-lg.png");
}

.login-box {
  border-radius: 6px;
  padding: 30px;
  background-color: white;
  box-shadow: 0 0 20px var(--primary-dark-color);
  width: 420px;
}
.login-box .logo {
  width: 250px;
  height: 102px;
  margin: 10px auto 30px;
  background-image: var(--login-box-logo);
  background-size: contain;
  background-repeat: no-repeat;
}
.login-box .btn:not(.btn-link) {
  min-width: calc(50% - 8px);
  margin: 15px 0;
}
.login-box .btn:not(.btn-link) + .btn {
  margin-left: 16px;
}
.login-box i {
  margin-right: 6px;
}
.login-box .error-message {
  margin-bottom: 10px;
}

@media (max-width: 991px) {
  .login-box {
    width: 320px;
  }
}
.login-version-info {
  position: fixed;
  bottom: 2px;
  left: 5px;
  font-size: 0.75rem;
  color: white;
}
.login-version-info .btn-link {
  color: white !important;
  text-decoration: underline;
  margin-left: 5px;
}
.login-version-info .btn-link:hover {
  color: var(--primary-color3) !important;
}

/* Old Colors

$color0: #ededed; // white gray
$color1: rgb(190,190,190); // #bebebe - light gray
$color2: rgb(80,80,80); // #505050 - gray
$color3: rgb(207,214,224); // #cfd6e0 - white blue
$color4: rgb(159,172,193); // #9facc1 - light blue
$color5: rgb(88,111,146); // #586f92 - mid blue
$color6: rgb(15,48,99); // #1B3D6D - dark blue
$color7: rgb(57,213,31); // #39d51f - green
$color8: rgb(52,197,26); // #34C51A - dark green
*/
/*Theming*/
:root {
  --loading-login-screen-background:
          linear-gradient(to right, rgba(15, 48, 99, 0) 0%, rgba(75, 107, 155, .4) 25%, rgba(168, 187, 215, .4) 42%, rgba(168, 187, 215, .4) 58%, rgba(75, 107, 155, .4) 75%, rgba(15, 48, 99, 0) 100%),
          url(".././assets/images/pattern-block-lines.png") repeat;
  --loading-screen-logo: url(".././assets/images/bakerhill-nextgen-logo-white.png");
  --login-box-logo: url(".././assets/images/bakerhill-nextgen-logo.png");
  --primary-dark-color: #1B3D6D;
  --primary-color: #456aa1;
  --primary-color-darken-7percent: #3a5a88;
  --primary-color-darken-11percent: #34507a;
  --primary-color-lighten-10percent: #5f84ba;
  --primary-color-lighten-20percent: #829fca;
  --primary-color-lighten-40percent: #cad6e8;
  --primary-light1-color: #a6bad9;
  --primary-light1-color-darken-5percent:#94acd1;
  --primary-light1-color-darken-10percent: #829eca;
  --primary-light1-color-darken-15percent: #7090c2;
  --primary-light1-color-lighten-6percent: #bbcbe2;
  --primary-white-color: #e6ecf2;
  --primary-white-color-darken-2percent: #dfe7ef;
  --primary-white-color-darken-6percent: #d2dde8;
  --primary-white-color-lighten-2percent: #edf1f5;
  --primary-white-color-lighten-4percent: #f3f6f9;
  --primary-color2: #cddc29;
  --primary-color3: #81BD41;
  --primary-color3-lighten-40percent: #e5f2d8;
  --primary-color3-darken-5percent: #74aa3a;
  --primary-dark2-color: #689834;
  --primary-dark2-color-darken-10percent: #4e7227;
  --primary-light2-color: #e6f2d9;
  --primary-light2-color-darken-2percent: #e1efd1;
  --primary-light2-color-darken-6percent: #d7eac2;
  --primary-light2-color-darken-10percent: #cde5b3;
  --grayscale-color: #58595B;
  --grayscale-color-lighten-25percent: #98999b;
  --grayscale-light-color: #bdbdc2;
  --grayscale-light-color-darken-8percent: #a8a8ae;
  --grayscale-light-color-lighten-7percent: #d0d0d3;
  --grayscale-white-color: #ebebed;
  --grayscale-white-color-darken-5percent: #dedee1;
  --grayscale-white-color-darken-8percent: #d6d6da;
  --grayscale-white-color-lighten-2percent: #f0f0f2;
  --grayscale-white-color-lighten-5percent: #f8f8f9;
  --white-color: #ffffff;
  --black-color: #000000;
  --secondary-dark-color: #007299;
  --secondary-color: #77256c;
  --secondary-color2: #f68d29;
  --secondary-color3: #ffd240;
  --secondary-color3-darken-3percent: #ffce31;
  --secondary-color3-lighten-17percent: #ffe697;
  --secondary-color3-lighten-27percent: #fff2ca;
  --secondary-bright-color: #ece815;
  --error-color: #cc0000;
  --error-color-darken-10percent: #990000;
  --error-color-lighten-50percent: #ffcccc;
  --error-color-lighten-55percent: #ffe6e6;
  --error-light-color: #ffe6e6;
  --error-light-color-darken-5percent: #ffcdcd;
  --disabled-color: #abbcd5;
  --disabled-color2: #9badca;
  --disabled-color3: #a7d17a;
  --default-sprite-position: top left;
  --active-hover-sprite-position: top right;
  /*remaing colors*/
  --snow-gray: #fcfcfc;
  --light-gainsboro-gray: #f7f7f7;
  --white-smoke: #f4f4f4;
  --very-light-gray: #f0f0f0;
  --light-pink: #febbbb;
  --light-yellow: #ffffe5;
  --orange-peel: #ff9900;
  --electric-yellow: #ffff00;
  --yellow-green: #effadb;
  --whisper-gray: #ededed;
  --dark-whisper-gray: #eeeeee;
  --white-dark-smoke: #e6e6e6;
  --gainsboro-gray: #e0e0e0;
  --medium-gray: #cccccc;
  --silver: #c6c6c6;
  --silver-very-light: #b9b9b9;
  --silver-light: #bbbbbb;
  --blue-smoke: #eef1f7;
  --ivory-red: #f8f8f2;
  --spun-pearl: #afafb6;
  --very-light-blue: #e6ebf5;
  --dark-gainsboro-gray: #d9d9d9;
  --bright-gainsboro-gray: #dddddd;
  --hawakes-blue: #ccd6eb;
  --blue-violet: #9933ff;
  --nobel-gray: #999999;
  --light-danube-blue: #89a4cd;
  --matte-gray: #888888;
  --light-matte-gray: #787878;
  --dark-gray: #777777;
  --raven-gray: #707275;
  --danube-blue: #6685c2;
  --dim-gray: #666666;
  --davy-grey: #555555;
  --analog-blue: #435f8b;
  --charcoal-gray: #444444;
  --mariner-blue: #335cad;
  --dark-charcoal-gray: #333333;
  --green-waterloo: #23241f;
  --nero-dark: #222222;
  --smalt-blue: #003399;
  --deep-green: #008a00;
  --navy-blue: #0066cc;
  --bondi-blue: #0085c7;
  --violation-text: #cc0000;
  --violation-bg: #ffe6e6;
  --warning-text: #f68d29;
  --warning-bg: #fff2ca;
  --highcharts-color-2: #cddc29;
  --highcharts-color-3: #007299;
  --highcharts-color-6: #689834;
  --highcharts-color-7: #a6bad9;
}

[data-theme=fiserv] {
  --loading-login-screen-background: #666666;
  --loading-screen-logo: url(".././assets/images/Fiserv-logo-1200x603.png");
  --login-box-logo: url(".././assets/images/Fiserv-logo-1200x603.png");
  --primary-dark-color: #333333;
  --primary-color: #666666;
  --primary-color-darken-7percent: #545454;
  --primary-color-darken-11percent: #4a4a4a;
  --primary-color-lighten-10percent: gray;
  --primary-color-lighten-20percent: #999999;
  --primary-color-lighten-40percent: #cccccc;
  --primary-light1-color: #bfbfbf;
  --primary-light1-color-darken-5percent: #b2b2b2;
  --primary-light1-color-darken-10percent: #a6a6a6;
  --primary-light1-color-darken-15percent: #999999;
  --primary-light1-color-lighten-6percent: #cecece;
  --primary-white-color: #efefef;
  --primary-white-color-darken-2percent: #eaeaea;
  --primary-white-color-darken-6percent: #e0e0e0;
  --primary-white-color-lighten-2percent: #f4f4f4;
  --primary-white-color-lighten-4percent: #f9f9f9;
  --primary-color2: #4695d1;
  --primary-color3: #ff6600;
  --primary-color3-lighten-40percent: #ffe0cc;
  --primary-color3-darken-5percent: #e65c00;
  --primary-dark2-color: #ea6b15;
  --primary-dark2-color-darken-10percent: #bb5611;
  --primary-light2-color: #f2d3c4;
  --primary-light2-color-darken-2percent: #f0cdbc;
  --primary-light2-color-darken-6percent: #ecc0ab;
  --primary-light2-color-darken-10percent: #e9b49a;
  --grayscale-color: #666666;
  --grayscale-color-lighten-25percent: #a6a6a6;
  --grayscale-light-color: #e0e0e0;
  --grayscale-light-color-darken-8percent: #cccccc;
  --grayscale-light-color-lighten-7percent: #f2f2f2;
  --grayscale-white-color: #e0e0e0;
  --grayscale-white-color-darken-5percent: lightgray;
  --grayscale-white-color-darken-8percent: #cccccc;
  --grayscale-white-color-lighten-2percent: #e5e5e5;
  --grayscale-white-color-lighten-5percent: #ededed;
  --white-color: #ffffff;
  --black-color: #0e0e0e;
  --secondary-dark-color: #007299;
  --secondary-color: #77256c;
  --secondary-color2: #81bd41;
  --secondary-color3: #ffd240;
  --secondary-color3-darken-3percent: #ffce31;
  --secondary-color3-lighten-17percent: #ffe697;
  --secondary-color3-lighten-27percent: #fff2ca;
  --secondary-bright-color: #ece815;
  --error-color: #dd3435;
  --error-color-darken-10percent: #be2021;
  --error-color-lighten-50percent: white;
  --error-color-lighten-55percent: white;
  --error-light-color: #eabec0;
  --error-light-color-darken-5percent: #e4abad;
  --disabled-color: #c9c9c9;
  --disabled-color2: #c9c9c9;
  --disabled-color3: #dd9e73;
  --default-sprite-position: bottom left;
  --active-hover-sprite-position: bottom right;
  /*remaing colors*/
  --snow-gray: #fcfcfc;
  --light-gainsboro-gray: #f7f7f7;
  --white-smoke: #f4f4f4;
  --very-light-gray: #f0f0f0;
  --light-pink: #febbbb;
  --light-yellow: #ffffe5;
  --orange-peel: #ff9900;
  --electric-yellow: #ffff00;
  --yellow-green: #effadb;
  --whisper-gray: #ededed;
  --dark-whisper-gray: #eeeeee;
  --white-dark-smoke: #e6e6e6;
  --gainsboro-gray: #e0e0e0;
  --medium-gray: #cccccc;
  --silver: #c6c6c6;
  --silver-very-light: #b9b9b9;
  --silver-light: #bbbbbb;
  --blue-smoke: #eef1f7;
  --ivory-red: #f8f8f2;
  --spun-pearl: #afafb6;
  --very-light-blue: #e6ebf5;
  --dark-gainsboro-gray: #d9d9d9;
  --bright-gainsboro-gray: #dddddd;
  --hawakes-blue: #ccd6eb;
  --blue-violet: #9933ff;
  --nobel-gray: #999999;
  --light-danube-blue: #89a4cd;
  --matte-gray: #888888;
  --light-matte-gray: #787878;
  --dark-gray: #777777;
  --raven-gray: #707275;
  --danube-blue: #6685c2;
  --dim-gray: #666666;
  --davy-grey: #555555;
  --analog-blue: #435f8b;
  --charcoal-gray: #444444;
  --mariner-blue: #335cad;
  --dark-charcoal-gray: #333333;
  --green-waterloo: #23241f;
  --nero-dark: #222222;
  --smalt-blue: #003399;
  --deep-green: #008a00;
  --navy-blue: #0066cc;
  --bondi-blue: #0085c7;
  --violation-text: #cc0000;
  --violation-bg: #ffe6e6;
  --warning-text: #f68d29;
  --warning-bg: #fff2ca;
  --highcharts-color-2: #ffa366;
  --highcharts-color-3: #777777;
  --highcharts-color-6: #e65c00;
  --highcharts-color-7: #e0e0e0;
}

/*Sprite Positions*/
/*Images*/
/*Primary*/
/*Grayscale*/
/*Secondary*/
/*disabled*/
/*remaing colors*/
.darkblue {
  color: var(--primary-dark-color);
}

.blue {
  color: var(--primary-color);
}

.lightblue {
  color: var(--primary-light1-color);
}

.whiteblue {
  color: var(--primary-white-color);
}

.yellowgreen {
  color: var(--primary-color2);
}

.green {
  color: var(--primary-color3) !important;
}

.darkgreen {
  color: var(--primary-dark2-color);
}

.lightgreen {
  color: var(--primary-light2-color);
}

.gray {
  color: var(--grayscale-color);
}

.lightgray {
  color: var(--grayscale-light-color);
}

.whitegray {
  color: var(--grayscale-white-color);
}

.darkcyan {
  color: var(--secondary-dark-color);
}

.purple {
  color: var(--secondary-color);
}

.orange {
  color: var(--secondary-color2) !important;
}

.yellow {
  color: var(--secondary-color3);
}

.brightyellow {
  color: var(--secondary-bright-color);
}

.red {
  color: var(--error-color) !important;
}

.lightred {
  color: var(--error-light-color);
}

.white {
  color: var(--white-color) !important;
}

.darkblue-bg {
  background-color: var(--primary-dark-color);
}

.blue-bg {
  background-color: var(--primary-color) !important;
}

.lightblue-bg {
  background-color: var(--primary-light1-color);
}

.whiteblue-bg {
  background-color: var(--primary-white-color);
}

.yellowgreen-bg {
  background-color: var(--primary-color2);
}

.green-bg {
  background-color: var(--primary-color3);
}

.darkgreen-bg {
  background-color: var(--primary-dark2-color);
}

.lightgreen-bg {
  background-color: var(--primary-light2-color);
}

.gray-bg {
  background-color: var(--grayscale-color);
}

.lightgray-bg {
  background-color: var(--grayscale-light-color);
}

.whitegray-bg {
  background-color: var(--grayscale-white-color);
}

.darkcyan-bg {
  background-color: var(--secondary-dark-color);
}

.purple-bg {
  background-color: var(--secondary-color);
}

.orange-bg {
  background-color: var(--secondary-color2) !important;
}

.yellow-bg {
  background-color: var(--secondary-color3) !important;
}

.brightyellow-bg {
  background-color: var(--secondary-bright-color) !important;
}

.red-bg {
  background-color: var(--error-color) !important;
}

.lightred-bg {
  background-color: var(--error-light-color) !important;
}

.bh-navbar h3, .bh-navbar .h3 {
  margin-top: 15px;
  margin-bottom: 0;
}

.docprep .form-group-container {
  padding: 10px 15px;
}
.docprep .form-group-container.outlined {
  outline: 1px solid var(--primary-color3);
  background-color: var(--primary-light2-color);
}
.docprep label.form-group {
  margin-bottom: 0;
}

.right-sidebar {
  position: fixed;
  height: calc(100% - 46px);
  width: 450px;
  top: 46px;
  right: 0;
  overflow-y: auto;
  padding: 25px;
  background-color: var(--blue-smoke);
}

@media (min-width: 768px) and (max-width: 991px) {
  .right-sidebar {
    width: 300px;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .right-sidebar {
    width: 320px;
  }
}
@media (min-width: 1200px) and (max-width: 1599px) {
  .right-sidebar {
    width: 320px;
  }
}
.missing-buttons {
  margin: 25px 0;
}

.missing-buttons button {
  margin-right: 10px;
  width: 100px;
}
.missing-buttons button.prevbtn, .missing-buttons button.nextbtn {
  width: 50px;
}
.missing-buttons button .fa {
  padding: 0 5px;
}

@media (min-width: 768px) and (max-width: 1599px) {
  .missing-buttons button {
    margin-right: 5px;
    width: 70px;
  }
  .missing-buttons button.prevbtn, .missing-buttons button.nextbtn {
    width: 40px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .missing-buttons button {
    margin-right: 3px;
    padding: 8px;
    width: 66px;
  }
}
.missing-list ol {
  padding-left: 20px;
}

.missing-list li {
  margin-bottom: 12px;
  font-weight: bold;
}
.missing-list li a {
  color: var(--primary-color);
  cursor: pointer;
}
.missing-list li a:hover, .missing-list li a:focus {
  color: var(--primary-color3) !important;
  text-decoration: none;
}

/* Old Colors

$color0: #ededed; // white gray
$color1: rgb(190,190,190); // #bebebe - light gray
$color2: rgb(80,80,80); // #505050 - gray
$color3: rgb(207,214,224); // #cfd6e0 - white blue
$color4: rgb(159,172,193); // #9facc1 - light blue
$color5: rgb(88,111,146); // #586f92 - mid blue
$color6: rgb(15,48,99); // #1B3D6D - dark blue
$color7: rgb(57,213,31); // #39d51f - green
$color8: rgb(52,197,26); // #34C51A - dark green
*/
/*Theming*/
:root {
  --loading-login-screen-background:
          linear-gradient(to right, rgba(15, 48, 99, 0) 0%, rgba(75, 107, 155, .4) 25%, rgba(168, 187, 215, .4) 42%, rgba(168, 187, 215, .4) 58%, rgba(75, 107, 155, .4) 75%, rgba(15, 48, 99, 0) 100%),
          url(".././assets/images/pattern-block-lines.png") repeat;
  --loading-screen-logo: url(".././assets/images/bakerhill-nextgen-logo-white.png");
  --login-box-logo: url(".././assets/images/bakerhill-nextgen-logo.png");
  --primary-dark-color: #1B3D6D;
  --primary-color: #456aa1;
  --primary-color-darken-7percent: #3a5a88;
  --primary-color-darken-11percent: #34507a;
  --primary-color-lighten-10percent: #5f84ba;
  --primary-color-lighten-20percent: #829fca;
  --primary-color-lighten-40percent: #cad6e8;
  --primary-light1-color: #a6bad9;
  --primary-light1-color-darken-5percent:#94acd1;
  --primary-light1-color-darken-10percent: #829eca;
  --primary-light1-color-darken-15percent: #7090c2;
  --primary-light1-color-lighten-6percent: #bbcbe2;
  --primary-white-color: #e6ecf2;
  --primary-white-color-darken-2percent: #dfe7ef;
  --primary-white-color-darken-6percent: #d2dde8;
  --primary-white-color-lighten-2percent: #edf1f5;
  --primary-white-color-lighten-4percent: #f3f6f9;
  --primary-color2: #cddc29;
  --primary-color3: #81BD41;
  --primary-color3-lighten-40percent: #e5f2d8;
  --primary-color3-darken-5percent: #74aa3a;
  --primary-dark2-color: #689834;
  --primary-dark2-color-darken-10percent: #4e7227;
  --primary-light2-color: #e6f2d9;
  --primary-light2-color-darken-2percent: #e1efd1;
  --primary-light2-color-darken-6percent: #d7eac2;
  --primary-light2-color-darken-10percent: #cde5b3;
  --grayscale-color: #58595B;
  --grayscale-color-lighten-25percent: #98999b;
  --grayscale-light-color: #bdbdc2;
  --grayscale-light-color-darken-8percent: #a8a8ae;
  --grayscale-light-color-lighten-7percent: #d0d0d3;
  --grayscale-white-color: #ebebed;
  --grayscale-white-color-darken-5percent: #dedee1;
  --grayscale-white-color-darken-8percent: #d6d6da;
  --grayscale-white-color-lighten-2percent: #f0f0f2;
  --grayscale-white-color-lighten-5percent: #f8f8f9;
  --white-color: #ffffff;
  --black-color: #000000;
  --secondary-dark-color: #007299;
  --secondary-color: #77256c;
  --secondary-color2: #f68d29;
  --secondary-color3: #ffd240;
  --secondary-color3-darken-3percent: #ffce31;
  --secondary-color3-lighten-17percent: #ffe697;
  --secondary-color3-lighten-27percent: #fff2ca;
  --secondary-bright-color: #ece815;
  --error-color: #cc0000;
  --error-color-darken-10percent: #990000;
  --error-color-lighten-50percent: #ffcccc;
  --error-color-lighten-55percent: #ffe6e6;
  --error-light-color: #ffe6e6;
  --error-light-color-darken-5percent: #ffcdcd;
  --disabled-color: #abbcd5;
  --disabled-color2: #9badca;
  --disabled-color3: #a7d17a;
  --default-sprite-position: top left;
  --active-hover-sprite-position: top right;
  /*remaing colors*/
  --snow-gray: #fcfcfc;
  --light-gainsboro-gray: #f7f7f7;
  --white-smoke: #f4f4f4;
  --very-light-gray: #f0f0f0;
  --light-pink: #febbbb;
  --light-yellow: #ffffe5;
  --orange-peel: #ff9900;
  --electric-yellow: #ffff00;
  --yellow-green: #effadb;
  --whisper-gray: #ededed;
  --dark-whisper-gray: #eeeeee;
  --white-dark-smoke: #e6e6e6;
  --gainsboro-gray: #e0e0e0;
  --medium-gray: #cccccc;
  --silver: #c6c6c6;
  --silver-very-light: #b9b9b9;
  --silver-light: #bbbbbb;
  --blue-smoke: #eef1f7;
  --ivory-red: #f8f8f2;
  --spun-pearl: #afafb6;
  --very-light-blue: #e6ebf5;
  --dark-gainsboro-gray: #d9d9d9;
  --bright-gainsboro-gray: #dddddd;
  --hawakes-blue: #ccd6eb;
  --blue-violet: #9933ff;
  --nobel-gray: #999999;
  --light-danube-blue: #89a4cd;
  --matte-gray: #888888;
  --light-matte-gray: #787878;
  --dark-gray: #777777;
  --raven-gray: #707275;
  --danube-blue: #6685c2;
  --dim-gray: #666666;
  --davy-grey: #555555;
  --analog-blue: #435f8b;
  --charcoal-gray: #444444;
  --mariner-blue: #335cad;
  --dark-charcoal-gray: #333333;
  --green-waterloo: #23241f;
  --nero-dark: #222222;
  --smalt-blue: #003399;
  --deep-green: #008a00;
  --navy-blue: #0066cc;
  --bondi-blue: #0085c7;
  --violation-text: #cc0000;
  --violation-bg: #ffe6e6;
  --warning-text: #f68d29;
  --warning-bg: #fff2ca;
  --highcharts-color-2: #cddc29;
  --highcharts-color-3: #007299;
  --highcharts-color-6: #689834;
  --highcharts-color-7: #a6bad9;
}

[data-theme=fiserv] {
  --loading-login-screen-background: #666666;
  --loading-screen-logo: url(".././assets/images/Fiserv-logo-1200x603.png");
  --login-box-logo: url(".././assets/images/Fiserv-logo-1200x603.png");
  --primary-dark-color: #333333;
  --primary-color: #666666;
  --primary-color-darken-7percent: #545454;
  --primary-color-darken-11percent: #4a4a4a;
  --primary-color-lighten-10percent: gray;
  --primary-color-lighten-20percent: #999999;
  --primary-color-lighten-40percent: #cccccc;
  --primary-light1-color: #bfbfbf;
  --primary-light1-color-darken-5percent: #b2b2b2;
  --primary-light1-color-darken-10percent: #a6a6a6;
  --primary-light1-color-darken-15percent: #999999;
  --primary-light1-color-lighten-6percent: #cecece;
  --primary-white-color: #efefef;
  --primary-white-color-darken-2percent: #eaeaea;
  --primary-white-color-darken-6percent: #e0e0e0;
  --primary-white-color-lighten-2percent: #f4f4f4;
  --primary-white-color-lighten-4percent: #f9f9f9;
  --primary-color2: #4695d1;
  --primary-color3: #ff6600;
  --primary-color3-lighten-40percent: #ffe0cc;
  --primary-color3-darken-5percent: #e65c00;
  --primary-dark2-color: #ea6b15;
  --primary-dark2-color-darken-10percent: #bb5611;
  --primary-light2-color: #f2d3c4;
  --primary-light2-color-darken-2percent: #f0cdbc;
  --primary-light2-color-darken-6percent: #ecc0ab;
  --primary-light2-color-darken-10percent: #e9b49a;
  --grayscale-color: #666666;
  --grayscale-color-lighten-25percent: #a6a6a6;
  --grayscale-light-color: #e0e0e0;
  --grayscale-light-color-darken-8percent: #cccccc;
  --grayscale-light-color-lighten-7percent: #f2f2f2;
  --grayscale-white-color: #e0e0e0;
  --grayscale-white-color-darken-5percent: lightgray;
  --grayscale-white-color-darken-8percent: #cccccc;
  --grayscale-white-color-lighten-2percent: #e5e5e5;
  --grayscale-white-color-lighten-5percent: #ededed;
  --white-color: #ffffff;
  --black-color: #0e0e0e;
  --secondary-dark-color: #007299;
  --secondary-color: #77256c;
  --secondary-color2: #81bd41;
  --secondary-color3: #ffd240;
  --secondary-color3-darken-3percent: #ffce31;
  --secondary-color3-lighten-17percent: #ffe697;
  --secondary-color3-lighten-27percent: #fff2ca;
  --secondary-bright-color: #ece815;
  --error-color: #dd3435;
  --error-color-darken-10percent: #be2021;
  --error-color-lighten-50percent: white;
  --error-color-lighten-55percent: white;
  --error-light-color: #eabec0;
  --error-light-color-darken-5percent: #e4abad;
  --disabled-color: #c9c9c9;
  --disabled-color2: #c9c9c9;
  --disabled-color3: #dd9e73;
  --default-sprite-position: bottom left;
  --active-hover-sprite-position: bottom right;
  /*remaing colors*/
  --snow-gray: #fcfcfc;
  --light-gainsboro-gray: #f7f7f7;
  --white-smoke: #f4f4f4;
  --very-light-gray: #f0f0f0;
  --light-pink: #febbbb;
  --light-yellow: #ffffe5;
  --orange-peel: #ff9900;
  --electric-yellow: #ffff00;
  --yellow-green: #effadb;
  --whisper-gray: #ededed;
  --dark-whisper-gray: #eeeeee;
  --white-dark-smoke: #e6e6e6;
  --gainsboro-gray: #e0e0e0;
  --medium-gray: #cccccc;
  --silver: #c6c6c6;
  --silver-very-light: #b9b9b9;
  --silver-light: #bbbbbb;
  --blue-smoke: #eef1f7;
  --ivory-red: #f8f8f2;
  --spun-pearl: #afafb6;
  --very-light-blue: #e6ebf5;
  --dark-gainsboro-gray: #d9d9d9;
  --bright-gainsboro-gray: #dddddd;
  --hawakes-blue: #ccd6eb;
  --blue-violet: #9933ff;
  --nobel-gray: #999999;
  --light-danube-blue: #89a4cd;
  --matte-gray: #888888;
  --light-matte-gray: #787878;
  --dark-gray: #777777;
  --raven-gray: #707275;
  --danube-blue: #6685c2;
  --dim-gray: #666666;
  --davy-grey: #555555;
  --analog-blue: #435f8b;
  --charcoal-gray: #444444;
  --mariner-blue: #335cad;
  --dark-charcoal-gray: #333333;
  --green-waterloo: #23241f;
  --nero-dark: #222222;
  --smalt-blue: #003399;
  --deep-green: #008a00;
  --navy-blue: #0066cc;
  --bondi-blue: #0085c7;
  --violation-text: #cc0000;
  --violation-bg: #ffe6e6;
  --warning-text: #f68d29;
  --warning-bg: #fff2ca;
  --highcharts-color-2: #ffa366;
  --highcharts-color-3: #777777;
  --highcharts-color-6: #e65c00;
  --highcharts-color-7: #e0e0e0;
}

/*Sprite Positions*/
/*Images*/
/*Primary*/
/*Grayscale*/
/*Secondary*/
/*disabled*/
/*remaing colors*/
.darkblue {
  color: var(--primary-dark-color);
}

.blue {
  color: var(--primary-color);
}

.lightblue {
  color: var(--primary-light1-color);
}

.whiteblue {
  color: var(--primary-white-color);
}

.yellowgreen {
  color: var(--primary-color2);
}

.green {
  color: var(--primary-color3) !important;
}

.darkgreen {
  color: var(--primary-dark2-color);
}

.lightgreen {
  color: var(--primary-light2-color);
}

.gray {
  color: var(--grayscale-color);
}

.lightgray {
  color: var(--grayscale-light-color);
}

.whitegray {
  color: var(--grayscale-white-color);
}

.darkcyan {
  color: var(--secondary-dark-color);
}

.purple {
  color: var(--secondary-color);
}

.orange {
  color: var(--secondary-color2) !important;
}

.yellow {
  color: var(--secondary-color3);
}

.brightyellow {
  color: var(--secondary-bright-color);
}

.red {
  color: var(--error-color) !important;
}

.lightred {
  color: var(--error-light-color);
}

.white {
  color: var(--white-color) !important;
}

.darkblue-bg {
  background-color: var(--primary-dark-color);
}

.blue-bg {
  background-color: var(--primary-color) !important;
}

.lightblue-bg {
  background-color: var(--primary-light1-color);
}

.whiteblue-bg {
  background-color: var(--primary-white-color);
}

.yellowgreen-bg {
  background-color: var(--primary-color2);
}

.green-bg {
  background-color: var(--primary-color3);
}

.darkgreen-bg {
  background-color: var(--primary-dark2-color);
}

.lightgreen-bg {
  background-color: var(--primary-light2-color);
}

.gray-bg {
  background-color: var(--grayscale-color);
}

.lightgray-bg {
  background-color: var(--grayscale-light-color);
}

.whitegray-bg {
  background-color: var(--grayscale-white-color);
}

.darkcyan-bg {
  background-color: var(--secondary-dark-color);
}

.purple-bg {
  background-color: var(--secondary-color);
}

.orange-bg {
  background-color: var(--secondary-color2) !important;
}

.yellow-bg {
  background-color: var(--secondary-color3) !important;
}

.brightyellow-bg {
  background-color: var(--secondary-bright-color) !important;
}

.red-bg {
  background-color: var(--error-color) !important;
}

.lightred-bg {
  background-color: var(--error-light-color) !important;
}

.toast-message {
  position: fixed;
  background: var(--primary-color);
  right: 16px;
  top: 12px;
  width: auto;
  height: auto;
  z-index: 3000;
  padding: 16px 45px 16px 16px;
  color: white;
  border-radius: 3px;
  font-size: 0.9375rem;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}
.toast-message i {
  margin-right: 8px;
}
.toast-message button {
  position: absolute;
  top: 5px;
  right: 0;
}
.toast-message button .fa {
  font-size: 1rem;
}
.toast-message.error {
  background: var(--error-color);
}
.toast-message.error .fa-exclamation-triangle {
  color: var(--error-color-lighten-50percent);
}
.toast-message.success {
  background: var(--primary-color3);
}
.toast-message.success .fa-check {
  color: var(--primary-color3-lighten-40percent);
}

.modal.show, .modal.open {
  background-color: rgba(0, 0, 0, 0.6666666667);
}

.modal-dialog.notification .modal-content {
  text-align: center;
}
.modal-dialog.notification .modal-content .modal-icon {
  padding: 8px;
  background-color: white;
  width: 46px;
  height: 46px;
  margin: -23px auto;
  border-radius: 50%;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}
.modal-dialog.notification .modal-content .modal-icon.error {
  background-color: var(--error-light-color);
}
.modal-dialog.notification .modal-content .modal-icon.error .fa {
  color: var(--error-color);
}
.modal-dialog.notification .modal-content .modal-icon.question {
  background-color: var(--primary-white-color);
}
.modal-dialog.notification .modal-content .modal-icon.question .fa {
  color: var(--primary-color);
  font-size: 2.3em;
}
.modal-dialog.notification .modal-content .modal-body {
  padding-top: 40px;
}
.modal-dialog.notification .modal-content .modal-footer {
  border-top: none;
  padding-top: 3px;
  text-align: center;
}
.modal-dialog.notification .modal-content .modal-footer button {
  min-width: 60px;
}

/* Old Colors

$color0: #ededed; // white gray
$color1: rgb(190,190,190); // #bebebe - light gray
$color2: rgb(80,80,80); // #505050 - gray
$color3: rgb(207,214,224); // #cfd6e0 - white blue
$color4: rgb(159,172,193); // #9facc1 - light blue
$color5: rgb(88,111,146); // #586f92 - mid blue
$color6: rgb(15,48,99); // #1B3D6D - dark blue
$color7: rgb(57,213,31); // #39d51f - green
$color8: rgb(52,197,26); // #34C51A - dark green
*/
/*Theming*/
:root {
  --loading-login-screen-background:
          linear-gradient(to right, rgba(15, 48, 99, 0) 0%, rgba(75, 107, 155, .4) 25%, rgba(168, 187, 215, .4) 42%, rgba(168, 187, 215, .4) 58%, rgba(75, 107, 155, .4) 75%, rgba(15, 48, 99, 0) 100%),
          url(".././assets/images/pattern-block-lines.png") repeat;
  --loading-screen-logo: url(".././assets/images/bakerhill-nextgen-logo-white.png");
  --login-box-logo: url(".././assets/images/bakerhill-nextgen-logo.png");
  --primary-dark-color: #1B3D6D;
  --primary-color: #456aa1;
  --primary-color-darken-7percent: #3a5a88;
  --primary-color-darken-11percent: #34507a;
  --primary-color-lighten-10percent: #5f84ba;
  --primary-color-lighten-20percent: #829fca;
  --primary-color-lighten-40percent: #cad6e8;
  --primary-light1-color: #a6bad9;
  --primary-light1-color-darken-5percent:#94acd1;
  --primary-light1-color-darken-10percent: #829eca;
  --primary-light1-color-darken-15percent: #7090c2;
  --primary-light1-color-lighten-6percent: #bbcbe2;
  --primary-white-color: #e6ecf2;
  --primary-white-color-darken-2percent: #dfe7ef;
  --primary-white-color-darken-6percent: #d2dde8;
  --primary-white-color-lighten-2percent: #edf1f5;
  --primary-white-color-lighten-4percent: #f3f6f9;
  --primary-color2: #cddc29;
  --primary-color3: #81BD41;
  --primary-color3-lighten-40percent: #e5f2d8;
  --primary-color3-darken-5percent: #74aa3a;
  --primary-dark2-color: #689834;
  --primary-dark2-color-darken-10percent: #4e7227;
  --primary-light2-color: #e6f2d9;
  --primary-light2-color-darken-2percent: #e1efd1;
  --primary-light2-color-darken-6percent: #d7eac2;
  --primary-light2-color-darken-10percent: #cde5b3;
  --grayscale-color: #58595B;
  --grayscale-color-lighten-25percent: #98999b;
  --grayscale-light-color: #bdbdc2;
  --grayscale-light-color-darken-8percent: #a8a8ae;
  --grayscale-light-color-lighten-7percent: #d0d0d3;
  --grayscale-white-color: #ebebed;
  --grayscale-white-color-darken-5percent: #dedee1;
  --grayscale-white-color-darken-8percent: #d6d6da;
  --grayscale-white-color-lighten-2percent: #f0f0f2;
  --grayscale-white-color-lighten-5percent: #f8f8f9;
  --white-color: #ffffff;
  --black-color: #000000;
  --secondary-dark-color: #007299;
  --secondary-color: #77256c;
  --secondary-color2: #f68d29;
  --secondary-color3: #ffd240;
  --secondary-color3-darken-3percent: #ffce31;
  --secondary-color3-lighten-17percent: #ffe697;
  --secondary-color3-lighten-27percent: #fff2ca;
  --secondary-bright-color: #ece815;
  --error-color: #cc0000;
  --error-color-darken-10percent: #990000;
  --error-color-lighten-50percent: #ffcccc;
  --error-color-lighten-55percent: #ffe6e6;
  --error-light-color: #ffe6e6;
  --error-light-color-darken-5percent: #ffcdcd;
  --disabled-color: #abbcd5;
  --disabled-color2: #9badca;
  --disabled-color3: #a7d17a;
  --default-sprite-position: top left;
  --active-hover-sprite-position: top right;
  /*remaing colors*/
  --snow-gray: #fcfcfc;
  --light-gainsboro-gray: #f7f7f7;
  --white-smoke: #f4f4f4;
  --very-light-gray: #f0f0f0;
  --light-pink: #febbbb;
  --light-yellow: #ffffe5;
  --orange-peel: #ff9900;
  --electric-yellow: #ffff00;
  --yellow-green: #effadb;
  --whisper-gray: #ededed;
  --dark-whisper-gray: #eeeeee;
  --white-dark-smoke: #e6e6e6;
  --gainsboro-gray: #e0e0e0;
  --medium-gray: #cccccc;
  --silver: #c6c6c6;
  --silver-very-light: #b9b9b9;
  --silver-light: #bbbbbb;
  --blue-smoke: #eef1f7;
  --ivory-red: #f8f8f2;
  --spun-pearl: #afafb6;
  --very-light-blue: #e6ebf5;
  --dark-gainsboro-gray: #d9d9d9;
  --bright-gainsboro-gray: #dddddd;
  --hawakes-blue: #ccd6eb;
  --blue-violet: #9933ff;
  --nobel-gray: #999999;
  --light-danube-blue: #89a4cd;
  --matte-gray: #888888;
  --light-matte-gray: #787878;
  --dark-gray: #777777;
  --raven-gray: #707275;
  --danube-blue: #6685c2;
  --dim-gray: #666666;
  --davy-grey: #555555;
  --analog-blue: #435f8b;
  --charcoal-gray: #444444;
  --mariner-blue: #335cad;
  --dark-charcoal-gray: #333333;
  --green-waterloo: #23241f;
  --nero-dark: #222222;
  --smalt-blue: #003399;
  --deep-green: #008a00;
  --navy-blue: #0066cc;
  --bondi-blue: #0085c7;
  --violation-text: #cc0000;
  --violation-bg: #ffe6e6;
  --warning-text: #f68d29;
  --warning-bg: #fff2ca;
  --highcharts-color-2: #cddc29;
  --highcharts-color-3: #007299;
  --highcharts-color-6: #689834;
  --highcharts-color-7: #a6bad9;
}

[data-theme=fiserv] {
  --loading-login-screen-background: #666666;
  --loading-screen-logo: url(".././assets/images/Fiserv-logo-1200x603.png");
  --login-box-logo: url(".././assets/images/Fiserv-logo-1200x603.png");
  --primary-dark-color: #333333;
  --primary-color: #666666;
  --primary-color-darken-7percent: #545454;
  --primary-color-darken-11percent: #4a4a4a;
  --primary-color-lighten-10percent: gray;
  --primary-color-lighten-20percent: #999999;
  --primary-color-lighten-40percent: #cccccc;
  --primary-light1-color: #bfbfbf;
  --primary-light1-color-darken-5percent: #b2b2b2;
  --primary-light1-color-darken-10percent: #a6a6a6;
  --primary-light1-color-darken-15percent: #999999;
  --primary-light1-color-lighten-6percent: #cecece;
  --primary-white-color: #efefef;
  --primary-white-color-darken-2percent: #eaeaea;
  --primary-white-color-darken-6percent: #e0e0e0;
  --primary-white-color-lighten-2percent: #f4f4f4;
  --primary-white-color-lighten-4percent: #f9f9f9;
  --primary-color2: #4695d1;
  --primary-color3: #ff6600;
  --primary-color3-lighten-40percent: #ffe0cc;
  --primary-color3-darken-5percent: #e65c00;
  --primary-dark2-color: #ea6b15;
  --primary-dark2-color-darken-10percent: #bb5611;
  --primary-light2-color: #f2d3c4;
  --primary-light2-color-darken-2percent: #f0cdbc;
  --primary-light2-color-darken-6percent: #ecc0ab;
  --primary-light2-color-darken-10percent: #e9b49a;
  --grayscale-color: #666666;
  --grayscale-color-lighten-25percent: #a6a6a6;
  --grayscale-light-color: #e0e0e0;
  --grayscale-light-color-darken-8percent: #cccccc;
  --grayscale-light-color-lighten-7percent: #f2f2f2;
  --grayscale-white-color: #e0e0e0;
  --grayscale-white-color-darken-5percent: lightgray;
  --grayscale-white-color-darken-8percent: #cccccc;
  --grayscale-white-color-lighten-2percent: #e5e5e5;
  --grayscale-white-color-lighten-5percent: #ededed;
  --white-color: #ffffff;
  --black-color: #0e0e0e;
  --secondary-dark-color: #007299;
  --secondary-color: #77256c;
  --secondary-color2: #81bd41;
  --secondary-color3: #ffd240;
  --secondary-color3-darken-3percent: #ffce31;
  --secondary-color3-lighten-17percent: #ffe697;
  --secondary-color3-lighten-27percent: #fff2ca;
  --secondary-bright-color: #ece815;
  --error-color: #dd3435;
  --error-color-darken-10percent: #be2021;
  --error-color-lighten-50percent: white;
  --error-color-lighten-55percent: white;
  --error-light-color: #eabec0;
  --error-light-color-darken-5percent: #e4abad;
  --disabled-color: #c9c9c9;
  --disabled-color2: #c9c9c9;
  --disabled-color3: #dd9e73;
  --default-sprite-position: bottom left;
  --active-hover-sprite-position: bottom right;
  /*remaing colors*/
  --snow-gray: #fcfcfc;
  --light-gainsboro-gray: #f7f7f7;
  --white-smoke: #f4f4f4;
  --very-light-gray: #f0f0f0;
  --light-pink: #febbbb;
  --light-yellow: #ffffe5;
  --orange-peel: #ff9900;
  --electric-yellow: #ffff00;
  --yellow-green: #effadb;
  --whisper-gray: #ededed;
  --dark-whisper-gray: #eeeeee;
  --white-dark-smoke: #e6e6e6;
  --gainsboro-gray: #e0e0e0;
  --medium-gray: #cccccc;
  --silver: #c6c6c6;
  --silver-very-light: #b9b9b9;
  --silver-light: #bbbbbb;
  --blue-smoke: #eef1f7;
  --ivory-red: #f8f8f2;
  --spun-pearl: #afafb6;
  --very-light-blue: #e6ebf5;
  --dark-gainsboro-gray: #d9d9d9;
  --bright-gainsboro-gray: #dddddd;
  --hawakes-blue: #ccd6eb;
  --blue-violet: #9933ff;
  --nobel-gray: #999999;
  --light-danube-blue: #89a4cd;
  --matte-gray: #888888;
  --light-matte-gray: #787878;
  --dark-gray: #777777;
  --raven-gray: #707275;
  --danube-blue: #6685c2;
  --dim-gray: #666666;
  --davy-grey: #555555;
  --analog-blue: #435f8b;
  --charcoal-gray: #444444;
  --mariner-blue: #335cad;
  --dark-charcoal-gray: #333333;
  --green-waterloo: #23241f;
  --nero-dark: #222222;
  --smalt-blue: #003399;
  --deep-green: #008a00;
  --navy-blue: #0066cc;
  --bondi-blue: #0085c7;
  --violation-text: #cc0000;
  --violation-bg: #ffe6e6;
  --warning-text: #f68d29;
  --warning-bg: #fff2ca;
  --highcharts-color-2: #ffa366;
  --highcharts-color-3: #777777;
  --highcharts-color-6: #e65c00;
  --highcharts-color-7: #e0e0e0;
}

/*Sprite Positions*/
/*Images*/
/*Primary*/
/*Grayscale*/
/*Secondary*/
/*disabled*/
/*remaing colors*/
.darkblue {
  color: var(--primary-dark-color);
}

.blue {
  color: var(--primary-color);
}

.lightblue {
  color: var(--primary-light1-color);
}

.whiteblue {
  color: var(--primary-white-color);
}

.yellowgreen {
  color: var(--primary-color2);
}

.green {
  color: var(--primary-color3) !important;
}

.darkgreen {
  color: var(--primary-dark2-color);
}

.lightgreen {
  color: var(--primary-light2-color);
}

.gray {
  color: var(--grayscale-color);
}

.lightgray {
  color: var(--grayscale-light-color);
}

.whitegray {
  color: var(--grayscale-white-color);
}

.darkcyan {
  color: var(--secondary-dark-color);
}

.purple {
  color: var(--secondary-color);
}

.orange {
  color: var(--secondary-color2) !important;
}

.yellow {
  color: var(--secondary-color3);
}

.brightyellow {
  color: var(--secondary-bright-color);
}

.red {
  color: var(--error-color) !important;
}

.lightred {
  color: var(--error-light-color);
}

.white {
  color: var(--white-color) !important;
}

.darkblue-bg {
  background-color: var(--primary-dark-color);
}

.blue-bg {
  background-color: var(--primary-color) !important;
}

.lightblue-bg {
  background-color: var(--primary-light1-color);
}

.whiteblue-bg {
  background-color: var(--primary-white-color);
}

.yellowgreen-bg {
  background-color: var(--primary-color2);
}

.green-bg {
  background-color: var(--primary-color3);
}

.darkgreen-bg {
  background-color: var(--primary-dark2-color);
}

.lightgreen-bg {
  background-color: var(--primary-light2-color);
}

.gray-bg {
  background-color: var(--grayscale-color);
}

.lightgray-bg {
  background-color: var(--grayscale-light-color);
}

.whitegray-bg {
  background-color: var(--grayscale-white-color);
}

.darkcyan-bg {
  background-color: var(--secondary-dark-color);
}

.purple-bg {
  background-color: var(--secondary-color);
}

.orange-bg {
  background-color: var(--secondary-color2) !important;
}

.yellow-bg {
  background-color: var(--secondary-color3) !important;
}

.brightyellow-bg {
  background-color: var(--secondary-bright-color) !important;
}

.red-bg {
  background-color: var(--error-color) !important;
}

.lightred-bg {
  background-color: var(--error-light-color) !important;
}

.tox {
  box-shadow: none;
  box-sizing: content-box;
  color: #222f3e;
  cursor: auto;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  -webkit-tap-highlight-color: transparent;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  vertical-align: initial;
  white-space: normal;
}

.tox :not(svg):not(rect) {
  box-sizing: inherit;
  color: inherit;
  cursor: inherit;
  direction: inherit;
  font-family: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  line-height: inherit;
  -webkit-tap-highlight-color: inherit;
  text-align: inherit;
  text-decoration: inherit;
  text-shadow: inherit;
  text-transform: inherit;
  vertical-align: inherit;
  white-space: inherit;
}

.tox :not(svg):not(rect) {
  background: 0 0;
  border: 0;
  box-shadow: none;
  float: none;
  height: auto;
  margin: 0;
  max-width: none;
  outline: 0;
  padding: 0;
  position: static;
  width: auto;
}

.tox:not([dir=rtl]) {
  direction: ltr;
  text-align: left;
}

.tox[dir=rtl] {
  direction: rtl;
  text-align: right;
}

.tox-tinymce {
  border: 2px solid #eee;
  border-radius: 10px;
  box-shadow: none;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  overflow: hidden;
  position: relative;
  visibility: inherit !important;
}

.tox.tox-tinymce-inline {
  border: none;
  box-shadow: none;
  overflow: initial;
}

.tox.tox-tinymce-inline .tox-editor-container {
  overflow: initial;
}

.tox.tox-tinymce-inline .tox-editor-header {
  background-color: #fff;
  border: 2px solid #eee;
  border-radius: 10px;
  box-shadow: none;
  overflow: hidden;
}

.tox-tinymce-aux {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  z-index: 1300;
}

.tox-tinymce :focus, .tox-tinymce-aux :focus {
  outline: 0;
}

button::-moz-focus-inner {
  border: 0;
}

.tox[dir=rtl] .tox-icon--flip svg {
  transform: rotateY(180deg);
}

.tox .accessibility-issue__header {
  align-items: center;
  display: flex;
  margin-bottom: 4px;
}

.tox .accessibility-issue__description {
  align-items: stretch;
  border-radius: 6px;
  display: flex;
  justify-content: space-between;
}

.tox .accessibility-issue__description > div {
  padding-bottom: 4px;
}

.tox .accessibility-issue__description > div > div {
  align-items: center;
  display: flex;
  margin-bottom: 4px;
}

.tox .accessibility-issue__description > div > div .tox-icon svg {
  display: block;
}

.tox .accessibility-issue__repair {
  margin-top: 16px;
}

.tox .tox-dialog__body-content .accessibility-issue--info .accessibility-issue__description {
  background-color: rgba(0, 101, 216, 0.1);
  color: #222f3e;
}

.tox .tox-dialog__body-content .accessibility-issue--info .tox-form__group h2, .tox .tox-dialog__body-content .accessibility-issue--info .tox-form__group .h2 {
  color: #006ce7;
}

.tox .tox-dialog__body-content .accessibility-issue--info .tox-icon svg {
  fill: #006ce7;
}

.tox .tox-dialog__body-content .accessibility-issue--info a.tox-button--naked.tox-button--icon {
  background-color: #006ce7;
  color: #fff;
}

.tox .tox-dialog__body-content .accessibility-issue--info a.tox-button--naked.tox-button--icon:focus, .tox .tox-dialog__body-content .accessibility-issue--info a.tox-button--naked.tox-button--icon:hover {
  background-color: #0060ce;
}

.tox .tox-dialog__body-content .accessibility-issue--info a.tox-button--naked.tox-button--icon:active {
  background-color: #0054b4;
}

.tox .tox-dialog__body-content .accessibility-issue--warn .accessibility-issue__description {
  background-color: rgba(255, 165, 0, 0.08);
  color: #222f3e;
}

.tox .tox-dialog__body-content .accessibility-issue--warn .tox-form__group h2, .tox .tox-dialog__body-content .accessibility-issue--warn .tox-form__group .h2 {
  color: #8f5d00;
}

.tox .tox-dialog__body-content .accessibility-issue--warn .tox-icon svg {
  fill: #8f5d00;
}

.tox .tox-dialog__body-content .accessibility-issue--warn a.tox-button--naked.tox-button--icon {
  background-color: #ffe89d;
  color: #222f3e;
}

.tox .tox-dialog__body-content .accessibility-issue--warn a.tox-button--naked.tox-button--icon:focus, .tox .tox-dialog__body-content .accessibility-issue--warn a.tox-button--naked.tox-button--icon:hover {
  background-color: #f2d574;
  color: #222f3e;
}

.tox .tox-dialog__body-content .accessibility-issue--warn a.tox-button--naked.tox-button--icon:active {
  background-color: #e8c657;
  color: #222f3e;
}

.tox .tox-dialog__body-content .accessibility-issue--error .accessibility-issue__description {
  background-color: rgba(204, 0, 0, 0.1);
  color: #222f3e;
}

.tox .tox-dialog__body-content .accessibility-issue--error .tox-form__group h2, .tox .tox-dialog__body-content .accessibility-issue--error .tox-form__group .h2 {
  color: #c00;
}

.tox .tox-dialog__body-content .accessibility-issue--error .tox-icon svg {
  fill: #c00;
}

.tox .tox-dialog__body-content .accessibility-issue--error a.tox-button--naked.tox-button--icon {
  background-color: #f2bfbf;
  color: #222f3e;
}

.tox .tox-dialog__body-content .accessibility-issue--error a.tox-button--naked.tox-button--icon:focus, .tox .tox-dialog__body-content .accessibility-issue--error a.tox-button--naked.tox-button--icon:hover {
  background-color: #e9a4a4;
  color: #222f3e;
}

.tox .tox-dialog__body-content .accessibility-issue--error a.tox-button--naked.tox-button--icon:active {
  background-color: #ee9494;
  color: #222f3e;
}

.tox .tox-dialog__body-content .accessibility-issue--success .accessibility-issue__description {
  background-color: rgba(120, 171, 70, 0.1);
  color: #222f3e;
}

.tox .tox-dialog__body-content .accessibility-issue--success .accessibility-issue__description > :last-child {
  display: none;
}

.tox .tox-dialog__body-content .accessibility-issue--success .tox-form__group h2, .tox .tox-dialog__body-content .accessibility-issue--success .tox-form__group .h2 {
  color: #527530;
}

.tox .tox-dialog__body-content .accessibility-issue--success .tox-icon svg {
  fill: #527530;
}

.tox .tox-dialog__body-content .accessibility-issue__header .tox-form__group h1, .tox .tox-dialog__body-content .accessibility-issue__header .tox-form__group .h1, .tox .tox-dialog__body-content .tox-form__group .accessibility-issue__description h2, .tox .tox-dialog__body-content .tox-form__group .accessibility-issue__description .h2 {
  font-size: 14px;
  margin-top: 0;
}

.tox:not([dir=rtl]) .tox-dialog__body-content .accessibility-issue__header .tox-button {
  margin-left: 4px;
}

.tox:not([dir=rtl]) .tox-dialog__body-content .accessibility-issue__header > :nth-last-child(2) {
  margin-left: auto;
}

.tox:not([dir=rtl]) .tox-dialog__body-content .accessibility-issue__description {
  padding: 4px 4px 4px 8px;
}

.tox[dir=rtl] .tox-dialog__body-content .accessibility-issue__header .tox-button {
  margin-right: 4px;
}

.tox[dir=rtl] .tox-dialog__body-content .accessibility-issue__header > :nth-last-child(2) {
  margin-right: auto;
}

.tox[dir=rtl] .tox-dialog__body-content .accessibility-issue__description {
  padding: 4px 8px 4px 4px;
}

.tox .tox-advtemplate .tox-form__grid {
  flex: 1;
}

.tox .tox-advtemplate .tox-form__grid > div:first-child {
  display: flex;
  flex-direction: column;
  width: 30%;
}

.tox .tox-advtemplate .tox-form__grid > div:first-child > div:nth-child(2) {
  flex-basis: 0;
  flex-grow: 1;
  overflow: auto;
}

@media only screen and (max-width: 767px) {
  body:not(.tox-force-desktop) .tox .tox-advtemplate .tox-form__grid > div:first-child {
    width: 100%;
  }
}
.tox .tox-advtemplate iframe {
  border-color: #eee;
  border-radius: 10px;
  border-style: solid;
  border-width: 1px;
  margin: 0 10px;
}

.tox .tox-anchorbar {
  display: flex;
  flex: 0 0 auto;
}

.tox .tox-bottom-anchorbar {
  display: flex;
  flex: 0 0 auto;
}

.tox .tox-bar {
  display: flex;
  flex: 0 0 auto;
}

.tox .tox-button {
  background-color: #006ce7;
  background-image: none;
  background-position: 0 0;
  background-repeat: repeat;
  border-color: #006ce7;
  border-radius: 6px;
  border-style: solid;
  border-width: 1px;
  box-shadow: none;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  letter-spacing: normal;
  line-height: 24px;
  margin: 0;
  outline: 0;
  padding: 4px 16px;
  position: relative;
  text-align: center;
  text-decoration: none;
  text-transform: none;
  white-space: nowrap;
}

.tox .tox-button::before {
  border-radius: 6px;
  bottom: -1px;
  box-shadow: inset 0 0 0 2px #fff, 0 0 0 1px #006ce7, 0 0 0 3px rgba(0, 108, 231, 0.25);
  content: "";
  left: -1px;
  opacity: 0;
  pointer-events: none;
  position: absolute;
  right: -1px;
  top: -1px;
}

.tox .tox-button[disabled] {
  background-color: #006ce7;
  background-image: none;
  border-color: #006ce7;
  box-shadow: none;
  color: rgba(255, 255, 255, 0.5);
  cursor: not-allowed;
}

.tox .tox-button:focus:not(:disabled) {
  background-color: #0060ce;
  background-image: none;
  border-color: #0060ce;
  box-shadow: none;
  color: #fff;
}

.tox .tox-button:focus-visible:not(:disabled)::before {
  opacity: 1;
}

.tox .tox-button:hover:not(:disabled) {
  background-color: #0060ce;
  background-image: none;
  border-color: #0060ce;
  box-shadow: none;
  color: #fff;
}

.tox .tox-button:active:not(:disabled) {
  background-color: #0054b4;
  background-image: none;
  border-color: #0054b4;
  box-shadow: none;
  color: #fff;
}

.tox .tox-button.tox-button--enabled {
  background-color: #0054b4;
  background-image: none;
  border-color: #0054b4;
  box-shadow: none;
  color: #fff;
}

.tox .tox-button.tox-button--enabled[disabled] {
  background-color: #0054b4;
  background-image: none;
  border-color: #0054b4;
  box-shadow: none;
  color: rgba(255, 255, 255, 0.5);
  cursor: not-allowed;
}

.tox .tox-button.tox-button--enabled:focus:not(:disabled) {
  background-color: #00489b;
  background-image: none;
  border-color: #00489b;
  box-shadow: none;
  color: #fff;
}

.tox .tox-button.tox-button--enabled:hover:not(:disabled) {
  background-color: #00489b;
  background-image: none;
  border-color: #00489b;
  box-shadow: none;
  color: #fff;
}

.tox .tox-button.tox-button--enabled:active:not(:disabled) {
  background-color: #003c81;
  background-image: none;
  border-color: #003c81;
  box-shadow: none;
  color: #fff;
}

.tox .tox-button--icon-and-text, .tox .tox-button.tox-button--icon-and-text, .tox .tox-button.tox-button--secondary.tox-button--icon-and-text {
  display: flex;
  padding: 5px 4px;
}

.tox .tox-button--icon-and-text .tox-icon svg, .tox .tox-button.tox-button--icon-and-text .tox-icon svg, .tox .tox-button.tox-button--secondary.tox-button--icon-and-text .tox-icon svg {
  display: block;
  fill: currentColor;
}

.tox .tox-button--secondary {
  background-color: #f0f0f0;
  background-image: none;
  background-position: 0 0;
  background-repeat: repeat;
  border-color: #f0f0f0;
  border-radius: 6px;
  border-style: solid;
  border-width: 1px;
  box-shadow: none;
  color: #222f3e;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  letter-spacing: normal;
  outline: 0;
  padding: 4px 16px;
  text-decoration: none;
  text-transform: none;
}

.tox .tox-button--secondary[disabled] {
  background-color: #f0f0f0;
  background-image: none;
  border-color: #f0f0f0;
  box-shadow: none;
  color: rgba(34, 47, 62, 0.5);
}

.tox .tox-button--secondary:focus:not(:disabled) {
  background-color: #e3e3e3;
  background-image: none;
  border-color: #e3e3e3;
  box-shadow: none;
  color: #222f3e;
}

.tox .tox-button--secondary:hover:not(:disabled) {
  background-color: #e3e3e3;
  background-image: none;
  border-color: #e3e3e3;
  box-shadow: none;
  color: #222f3e;
}

.tox .tox-button--secondary:active:not(:disabled) {
  background-color: #d6d6d6;
  background-image: none;
  border-color: #d6d6d6;
  box-shadow: none;
  color: #222f3e;
}

.tox .tox-button--secondary.tox-button--enabled {
  background-color: #a8c8ed;
  background-image: none;
  border-color: #a8c8ed;
  box-shadow: none;
  color: #222f3e;
}

.tox .tox-button--secondary.tox-button--enabled[disabled] {
  background-color: #a8c8ed;
  background-image: none;
  border-color: #a8c8ed;
  box-shadow: none;
  color: rgba(34, 47, 62, 0.5);
}

.tox .tox-button--secondary.tox-button--enabled:focus:not(:disabled) {
  background-color: #93bbe9;
  background-image: none;
  border-color: #93bbe9;
  box-shadow: none;
  color: #222f3e;
}

.tox .tox-button--secondary.tox-button--enabled:hover:not(:disabled) {
  background-color: #93bbe9;
  background-image: none;
  border-color: #93bbe9;
  box-shadow: none;
  color: #222f3e;
}

.tox .tox-button--secondary.tox-button--enabled:active:not(:disabled) {
  background-color: #7daee4;
  background-image: none;
  border-color: #7daee4;
  box-shadow: none;
  color: #222f3e;
}

.tox .tox-button--icon, .tox .tox-button.tox-button--icon, .tox .tox-button.tox-button--secondary.tox-button--icon {
  padding: 4px;
}

.tox .tox-button--icon .tox-icon svg, .tox .tox-button.tox-button--icon .tox-icon svg, .tox .tox-button.tox-button--secondary.tox-button--icon .tox-icon svg {
  display: block;
  fill: currentColor;
}

.tox .tox-button-link {
  background: 0;
  border: none;
  box-sizing: border-box;
  cursor: pointer;
  display: inline-block;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.3;
  margin: 0;
  padding: 0;
  white-space: nowrap;
}

.tox .tox-button-link--sm {
  font-size: 14px;
}

.tox .tox-button--naked {
  background-color: transparent;
  border-color: transparent;
  box-shadow: unset;
  color: #222f3e;
}

.tox .tox-button--naked[disabled] {
  background-color: rgba(34, 47, 62, 0.12);
  border-color: transparent;
  box-shadow: unset;
  color: rgba(34, 47, 62, 0.5);
}

.tox .tox-button--naked:hover:not(:disabled) {
  background-color: rgba(34, 47, 62, 0.12);
  border-color: transparent;
  box-shadow: unset;
  color: #222f3e;
}

.tox .tox-button--naked:focus:not(:disabled) {
  background-color: rgba(34, 47, 62, 0.12);
  border-color: transparent;
  box-shadow: unset;
  color: #222f3e;
}

.tox .tox-button--naked:active:not(:disabled) {
  background-color: rgba(34, 47, 62, 0.18);
  border-color: transparent;
  box-shadow: unset;
  color: #222f3e;
}

.tox .tox-button--naked .tox-icon svg {
  fill: currentColor;
}

.tox .tox-button--naked.tox-button--icon:hover:not(:disabled) {
  color: #222f3e;
}

.tox .tox-checkbox {
  align-items: center;
  border-radius: 6px;
  cursor: pointer;
  display: flex;
  height: 36px;
  min-width: 36px;
}

.tox .tox-checkbox__input {
  height: 1px;
  overflow: hidden;
  position: absolute;
  top: auto;
  width: 1px;
}

.tox .tox-checkbox__icons {
  align-items: center;
  border-radius: 6px;
  box-shadow: 0 0 0 2px transparent;
  box-sizing: content-box;
  display: flex;
  height: 24px;
  justify-content: center;
  padding: 3px;
  width: 24px;
}

.tox .tox-checkbox__icons .tox-checkbox-icon__unchecked svg {
  display: block;
  fill: rgba(34, 47, 62, 0.3);
}

.tox .tox-checkbox__icons .tox-checkbox-icon__indeterminate svg {
  display: none;
  fill: #006ce7;
}

.tox .tox-checkbox__icons .tox-checkbox-icon__checked svg {
  display: none;
  fill: #006ce7;
}

.tox .tox-checkbox--disabled {
  color: rgba(34, 47, 62, 0.5);
  cursor: not-allowed;
}

.tox .tox-checkbox--disabled .tox-checkbox__icons .tox-checkbox-icon__checked svg {
  fill: rgba(34, 47, 62, 0.5);
}

.tox .tox-checkbox--disabled .tox-checkbox__icons .tox-checkbox-icon__unchecked svg {
  fill: rgba(34, 47, 62, 0.5);
}

.tox .tox-checkbox--disabled .tox-checkbox__icons .tox-checkbox-icon__indeterminate svg {
  fill: rgba(34, 47, 62, 0.5);
}

.tox input.tox-checkbox__input:checked + .tox-checkbox__icons .tox-checkbox-icon__unchecked svg {
  display: none;
}

.tox input.tox-checkbox__input:checked + .tox-checkbox__icons .tox-checkbox-icon__checked svg {
  display: block;
}

.tox input.tox-checkbox__input:indeterminate + .tox-checkbox__icons .tox-checkbox-icon__unchecked svg {
  display: none;
}

.tox input.tox-checkbox__input:indeterminate + .tox-checkbox__icons .tox-checkbox-icon__indeterminate svg {
  display: block;
}

.tox input.tox-checkbox__input:focus + .tox-checkbox__icons {
  border-radius: 6px;
  box-shadow: inset 0 0 0 1px #006ce7;
  padding: 3px;
}

.tox:not([dir=rtl]) .tox-checkbox__label {
  margin-left: 4px;
}

.tox:not([dir=rtl]) .tox-checkbox__input {
  left: -10000px;
}

.tox:not([dir=rtl]) .tox-bar .tox-checkbox {
  margin-left: 4px;
}

.tox[dir=rtl] .tox-checkbox__label {
  margin-right: 4px;
}

.tox[dir=rtl] .tox-checkbox__input {
  right: -10000px;
}

.tox[dir=rtl] .tox-bar .tox-checkbox {
  margin-right: 4px;
}

.tox .tox-collection--toolbar .tox-collection__group {
  display: flex;
  padding: 0;
}

.tox .tox-collection--grid .tox-collection__group {
  display: flex;
  flex-wrap: wrap;
  max-height: 208px;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 0;
}

.tox .tox-collection--list .tox-collection__group {
  border-bottom-width: 0;
  border-color: #e3e3e3;
  border-left-width: 0;
  border-right-width: 0;
  border-style: solid;
  border-top-width: 1px;
  padding: 4px 0;
}

.tox .tox-collection--list .tox-collection__group:first-child {
  border-top-width: 0;
}

.tox .tox-collection__group-heading {
  background-color: #fcfcfc;
  color: rgba(34, 47, 62, 0.7);
  cursor: default;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  margin-bottom: 4px;
  margin-top: -4px;
  padding: 4px 8px;
  text-transform: none;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}

.tox .tox-collection__item {
  align-items: center;
  border-radius: 3px;
  color: #222f3e;
  display: flex;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}

.tox .tox-collection--list .tox-collection__item {
  padding: 4px 8px;
}

.tox .tox-collection--toolbar .tox-collection__item {
  border-radius: 3px;
  padding: 4px;
}

.tox .tox-collection--grid .tox-collection__item {
  border-radius: 3px;
  padding: 4px;
}

.tox .tox-collection--list .tox-collection__item--enabled {
  background-color: #fff;
  color: #222f3e;
}

.tox .tox-collection--list .tox-collection__item--active {
  background-color: #cce2fa;
}

.tox .tox-collection--toolbar .tox-collection__item--enabled {
  background-color: #a6ccf7;
  color: #222f3e;
}

.tox .tox-collection--toolbar .tox-collection__item--active {
  background-color: #cce2fa;
}

.tox .tox-collection--grid .tox-collection__item--enabled {
  background-color: #a6ccf7;
  color: #222f3e;
}

.tox .tox-collection--grid .tox-collection__item--active:not(.tox-collection__item--state-disabled) {
  background-color: #cce2fa;
  color: #222f3e;
}

.tox .tox-collection--list .tox-collection__item--active:not(.tox-collection__item--state-disabled) {
  color: #222f3e;
}

.tox .tox-collection--toolbar .tox-collection__item--active:not(.tox-collection__item--state-disabled) {
  color: #222f3e;
}

.tox .tox-collection__item-checkmark, .tox .tox-collection__item-icon {
  align-items: center;
  display: flex;
  height: 24px;
  justify-content: center;
  width: 24px;
}

.tox .tox-collection__item-checkmark svg, .tox .tox-collection__item-icon svg {
  fill: currentColor;
}

.tox .tox-collection--toolbar-lg .tox-collection__item-icon {
  height: 48px;
  width: 48px;
}

.tox .tox-collection__item-label {
  color: currentColor;
  display: inline-block;
  flex: 1;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  max-width: 100%;
  text-transform: none;
  word-break: break-all;
}

.tox .tox-collection__item-accessory {
  color: rgba(34, 47, 62, 0.7);
  display: inline-block;
  font-size: 14px;
  height: 24px;
  line-height: 24px;
  text-transform: none;
}

.tox .tox-collection__item-caret {
  align-items: center;
  display: flex;
  min-height: 24px;
}

.tox .tox-collection__item-caret::after {
  content: "";
  font-size: 0;
  min-height: inherit;
}

.tox .tox-collection__item-caret svg {
  fill: #222f3e;
}

.tox .tox-collection__item--state-disabled {
  background-color: transparent;
  color: rgba(34, 47, 62, 0.5);
  cursor: not-allowed;
}

.tox .tox-collection__item--state-disabled .tox-collection__item-caret svg {
  fill: rgba(34, 47, 62, 0.5);
}

.tox .tox-collection--list .tox-collection__item:not(.tox-collection__item--enabled) .tox-collection__item-checkmark svg {
  display: none;
}

.tox .tox-collection--list .tox-collection__item:not(.tox-collection__item--enabled) .tox-collection__item-accessory + .tox-collection__item-checkmark {
  display: none;
}

.tox .tox-collection--horizontal {
  background-color: #fff;
  border: 1px solid #e3e3e3;
  border-radius: 6px;
  box-shadow: 0 0 2px 0 rgba(34, 47, 62, 0.2), 0 4px 8px 0 rgba(34, 47, 62, 0.15);
  display: flex;
  flex: 0 0 auto;
  flex-shrink: 0;
  flex-wrap: nowrap;
  margin-bottom: 0;
  overflow-x: auto;
  padding: 0;
}

.tox .tox-collection--horizontal .tox-collection__group {
  align-items: center;
  display: flex;
  flex-wrap: nowrap;
  margin: 0;
  padding: 0 4px;
}

.tox .tox-collection--horizontal .tox-collection__item {
  height: 28px;
  margin: 6px 1px 5px 0;
  padding: 0 4px;
}

.tox .tox-collection--horizontal .tox-collection__item-label {
  white-space: nowrap;
}

.tox .tox-collection--horizontal .tox-collection__item-caret {
  margin-left: 4px;
}

.tox .tox-collection__item-container {
  display: flex;
}

.tox .tox-collection__item-container--row {
  align-items: center;
  flex: 1 1 auto;
  flex-direction: row;
}

.tox .tox-collection__item-container--row.tox-collection__item-container--align-left {
  margin-right: auto;
}

.tox .tox-collection__item-container--row.tox-collection__item-container--align-right {
  justify-content: flex-end;
  margin-left: auto;
}

.tox .tox-collection__item-container--row.tox-collection__item-container--valign-top {
  align-items: flex-start;
  margin-bottom: auto;
}

.tox .tox-collection__item-container--row.tox-collection__item-container--valign-middle {
  align-items: center;
}

.tox .tox-collection__item-container--row.tox-collection__item-container--valign-bottom {
  align-items: flex-end;
  margin-top: auto;
}

.tox .tox-collection__item-container--column {
  align-self: center;
  flex: 1 1 auto;
  flex-direction: column;
}

.tox .tox-collection__item-container--column.tox-collection__item-container--align-left {
  align-items: flex-start;
}

.tox .tox-collection__item-container--column.tox-collection__item-container--align-right {
  align-items: flex-end;
}

.tox .tox-collection__item-container--column.tox-collection__item-container--valign-top {
  align-self: flex-start;
}

.tox .tox-collection__item-container--column.tox-collection__item-container--valign-middle {
  align-self: center;
}

.tox .tox-collection__item-container--column.tox-collection__item-container--valign-bottom {
  align-self: flex-end;
}

.tox:not([dir=rtl]) .tox-collection--horizontal .tox-collection__group:not(:last-of-type) {
  border-right: 1px solid transparent;
}

.tox:not([dir=rtl]) .tox-collection--list .tox-collection__item > :not(:first-child) {
  margin-left: 8px;
}

.tox:not([dir=rtl]) .tox-collection--list .tox-collection__item > .tox-collection__item-label:first-child {
  margin-left: 4px;
}

.tox:not([dir=rtl]) .tox-collection__item-accessory {
  margin-left: 16px;
  text-align: right;
}

.tox:not([dir=rtl]) .tox-collection .tox-collection__item-caret {
  margin-left: 16px;
}

.tox[dir=rtl] .tox-collection--horizontal .tox-collection__group:not(:last-of-type) {
  border-left: 1px solid transparent;
}

.tox[dir=rtl] .tox-collection--list .tox-collection__item > :not(:first-child) {
  margin-right: 8px;
}

.tox[dir=rtl] .tox-collection--list .tox-collection__item > .tox-collection__item-label:first-child {
  margin-right: 4px;
}

.tox[dir=rtl] .tox-collection__item-accessory {
  margin-right: 16px;
  text-align: left;
}

.tox[dir=rtl] .tox-collection .tox-collection__item-caret {
  margin-right: 16px;
  transform: rotateY(180deg);
}

.tox[dir=rtl] .tox-collection--horizontal .tox-collection__item-caret {
  margin-right: 4px;
}

.tox .tox-color-picker-container {
  display: flex;
  flex-direction: row;
  height: 225px;
  margin: 0;
}

.tox .tox-sv-palette {
  box-sizing: border-box;
  display: flex;
  height: 100%;
}

.tox .tox-sv-palette-spectrum {
  height: 100%;
}

.tox .tox-sv-palette, .tox .tox-sv-palette-spectrum {
  width: 225px;
}

.tox .tox-sv-palette-thumb {
  background: 0 0;
  border: 1px solid #000;
  border-radius: 50%;
  box-sizing: content-box;
  height: 12px;
  position: absolute;
  width: 12px;
}

.tox .tox-sv-palette-inner-thumb {
  border: 1px solid #fff;
  border-radius: 50%;
  height: 10px;
  position: absolute;
  width: 10px;
}

.tox .tox-hue-slider {
  box-sizing: border-box;
  height: 100%;
  width: 25px;
}

.tox .tox-hue-slider-spectrum {
  background: linear-gradient(to bottom, red, #ff0080, #f0f, #8000ff, #00f, #0080ff, #0ff, #00ff80, #0f0, #80ff00, #ff0, #ff8000, red);
  height: 100%;
  width: 100%;
}

.tox .tox-hue-slider, .tox .tox-hue-slider-spectrum {
  width: 20px;
}

.tox .tox-hue-slider-spectrum:focus, .tox .tox-sv-palette-spectrum:focus {
  outline: #08f solid;
}

.tox .tox-hue-slider-thumb {
  background: #fff;
  border: 1px solid #000;
  box-sizing: content-box;
  height: 4px;
  width: 100%;
}

.tox .tox-rgb-form {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.tox .tox-rgb-form div {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
  width: inherit;
}

.tox .tox-rgb-form input {
  width: 6em;
}

.tox .tox-rgb-form input.tox-invalid {
  border: 1px solid red !important;
}

.tox .tox-rgb-form .tox-rgba-preview {
  border: 1px solid #000;
  flex-grow: 2;
  margin-bottom: 0;
}

.tox:not([dir=rtl]) .tox-sv-palette {
  margin-right: 15px;
}

.tox:not([dir=rtl]) .tox-hue-slider {
  margin-right: 15px;
}

.tox:not([dir=rtl]) .tox-hue-slider-thumb {
  margin-left: -1px;
}

.tox:not([dir=rtl]) .tox-rgb-form label {
  margin-right: 0.5em;
}

.tox[dir=rtl] .tox-sv-palette {
  margin-left: 15px;
}

.tox[dir=rtl] .tox-hue-slider {
  margin-left: 15px;
}

.tox[dir=rtl] .tox-hue-slider-thumb {
  margin-right: -1px;
}

.tox[dir=rtl] .tox-rgb-form label {
  margin-left: 0.5em;
}

.tox .tox-toolbar .tox-swatches, .tox .tox-toolbar__overflow .tox-swatches, .tox .tox-toolbar__primary .tox-swatches {
  margin: 5px 0 6px 11px;
}

.tox .tox-collection--list .tox-collection__group .tox-swatches-menu {
  border: 0;
  margin: -4px -4px;
}

.tox .tox-swatches__row {
  display: flex;
}

.tox .tox-swatch {
  height: 30px;
  transition: transform 0.15s, box-shadow 0.15s;
  width: 30px;
}

.tox .tox-swatch:focus, .tox .tox-swatch:hover {
  box-shadow: 0 0 0 1px rgba(127, 127, 127, 0.3) inset;
  transform: scale(0.8);
}

.tox .tox-swatch--remove {
  align-items: center;
  display: flex;
  justify-content: center;
}

.tox .tox-swatch--remove svg path {
  stroke: #e74c3c;
}

.tox .tox-swatches__picker-btn {
  align-items: center;
  background-color: transparent;
  border: 0;
  cursor: pointer;
  display: flex;
  height: 30px;
  justify-content: center;
  outline: 0;
  padding: 0;
  width: 30px;
}

.tox .tox-swatches__picker-btn svg {
  fill: #222f3e;
  height: 24px;
  width: 24px;
}

.tox .tox-swatches__picker-btn:hover {
  background: #cce2fa;
}

.tox div.tox-swatch:not(.tox-swatch--remove) svg {
  display: none;
  fill: #222f3e;
  height: 24px;
  margin: 3px 3px;
  width: 24px;
}

.tox div.tox-swatch:not(.tox-swatch--remove) svg path {
  fill: #fff;
  paint-order: stroke;
  stroke: #222f3e;
  stroke-width: 2px;
}

.tox div.tox-swatch:not(.tox-swatch--remove).tox-collection__item--enabled svg {
  display: block;
}

.tox:not([dir=rtl]) .tox-swatches__picker-btn {
  margin-left: auto;
}

.tox[dir=rtl] .tox-swatches__picker-btn {
  margin-right: auto;
}

.tox .tox-comment-thread {
  background: #fff;
  position: relative;
}

.tox .tox-comment-thread > :not(:first-child) {
  margin-top: 8px;
}

.tox .tox-comment {
  background: #fff;
  border: 1px solid #eee;
  border-radius: 6px;
  box-shadow: 0 4px 8px 0 rgba(34, 47, 62, 0.1);
  padding: 8px 8px 16px 8px;
  position: relative;
}

.tox .tox-comment__header {
  align-items: center;
  color: #222f3e;
  display: flex;
  justify-content: space-between;
}

.tox .tox-comment__date {
  color: #222f3e;
  font-size: 12px;
  line-height: 18px;
}

.tox .tox-comment__body {
  color: #222f3e;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.3;
  margin-top: 8px;
  position: relative;
  text-transform: initial;
}

.tox .tox-comment__body textarea {
  resize: none;
  white-space: normal;
  width: 100%;
}

.tox .tox-comment__expander {
  padding-top: 8px;
}

.tox .tox-comment__expander p {
  color: rgba(34, 47, 62, 0.7);
  font-size: 14px;
  font-style: normal;
}

.tox .tox-comment__body p {
  margin: 0;
}

.tox .tox-comment__buttonspacing {
  padding-top: 16px;
  text-align: center;
}

.tox .tox-comment-thread__overlay::after {
  background: #fff;
  bottom: 0;
  content: "";
  display: flex;
  left: 0;
  opacity: 0.9;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 5;
}

.tox .tox-comment__reply {
  display: flex;
  flex-shrink: 0;
  flex-wrap: wrap;
  justify-content: flex-end;
  margin-top: 8px;
}

.tox .tox-comment__reply > :first-child {
  margin-bottom: 8px;
  width: 100%;
}

.tox .tox-comment__edit {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  margin-top: 16px;
}

.tox .tox-comment__gradient::after {
  background: linear-gradient(rgba(255, 255, 255, 0), #fff);
  bottom: 0;
  content: "";
  display: block;
  height: 5em;
  margin-top: -40px;
  position: absolute;
  width: 100%;
}

.tox .tox-comment__overlay {
  background: #fff;
  bottom: 0;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  left: 0;
  opacity: 0.9;
  position: absolute;
  right: 0;
  text-align: center;
  top: 0;
  z-index: 5;
}

.tox .tox-comment__loading-text {
  align-items: center;
  color: #222f3e;
  display: flex;
  flex-direction: column;
  position: relative;
}

.tox .tox-comment__loading-text > div {
  padding-bottom: 16px;
}

.tox .tox-comment__overlaytext {
  bottom: 0;
  flex-direction: column;
  font-size: 14px;
  left: 0;
  padding: 1em;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 10;
}

.tox .tox-comment__overlaytext p {
  background-color: #fff;
  box-shadow: 0 0 8px 8px #fff;
  color: #222f3e;
  text-align: center;
}

.tox .tox-comment__overlaytext div:nth-of-type(2) {
  font-size: 0.8em;
}

.tox .tox-comment__busy-spinner {
  align-items: center;
  background-color: #fff;
  bottom: 0;
  display: flex;
  justify-content: center;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 20;
}

.tox .tox-comment__scroll {
  display: flex;
  flex-direction: column;
  flex-shrink: 1;
  overflow: auto;
}

.tox .tox-conversations {
  margin: 8px;
}

.tox:not([dir=rtl]) .tox-comment__edit {
  margin-left: 8px;
}

.tox:not([dir=rtl]) .tox-comment__buttonspacing > :last-child, .tox:not([dir=rtl]) .tox-comment__edit > :last-child, .tox:not([dir=rtl]) .tox-comment__reply > :last-child {
  margin-left: 8px;
}

.tox[dir=rtl] .tox-comment__edit {
  margin-right: 8px;
}

.tox[dir=rtl] .tox-comment__buttonspacing > :last-child, .tox[dir=rtl] .tox-comment__edit > :last-child, .tox[dir=rtl] .tox-comment__reply > :last-child {
  margin-right: 8px;
}

.tox .tox-user {
  align-items: center;
  display: flex;
}

.tox .tox-user__avatar svg {
  fill: rgba(34, 47, 62, 0.7);
}

.tox .tox-user__avatar img {
  border-radius: 50%;
  height: 36px;
  object-fit: cover;
  vertical-align: middle;
  width: 36px;
}

.tox .tox-user__name {
  color: #222f3e;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px;
  text-transform: none;
}

.tox:not([dir=rtl]) .tox-user__avatar img, .tox:not([dir=rtl]) .tox-user__avatar svg {
  margin-right: 8px;
}

.tox:not([dir=rtl]) .tox-user__avatar + .tox-user__name {
  margin-left: 8px;
}

.tox[dir=rtl] .tox-user__avatar img, .tox[dir=rtl] .tox-user__avatar svg {
  margin-left: 8px;
}

.tox[dir=rtl] .tox-user__avatar + .tox-user__name {
  margin-right: 8px;
}

.tox .tox-dialog-wrap {
  align-items: center;
  bottom: 0;
  display: flex;
  justify-content: center;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 1100;
}

.tox .tox-dialog-wrap__backdrop {
  background-color: rgba(255, 255, 255, 0.75);
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1;
}

.tox .tox-dialog-wrap__backdrop--opaque {
  background-color: #fff;
}

.tox .tox-dialog {
  background-color: #fff;
  border-color: #eee;
  border-radius: 10px;
  border-style: solid;
  border-width: 0;
  box-shadow: 0 16px 16px -10px rgba(34, 47, 62, 0.15), 0 0 40px 1px rgba(34, 47, 62, 0.15);
  display: flex;
  flex-direction: column;
  max-height: 100%;
  max-width: 480px;
  overflow: hidden;
  position: relative;
  width: 95vw;
  z-index: 2;
}

@media only screen and (max-width: 767px) {
  body:not(.tox-force-desktop) .tox .tox-dialog {
    align-self: flex-start;
    margin: 8px auto;
    max-height: calc(100vh - 16px);
    width: calc(100vw - 16px);
  }
}
.tox .tox-dialog-inline {
  z-index: 1100;
}

.tox .tox-dialog__header {
  align-items: center;
  background-color: #fff;
  border-bottom: none;
  color: #222f3e;
  display: flex;
  font-size: 16px;
  justify-content: space-between;
  padding: 8px 16px 0 16px;
  position: relative;
}

.tox .tox-dialog__header .tox-button {
  z-index: 1;
}

.tox .tox-dialog__draghandle {
  cursor: grab;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.tox .tox-dialog__draghandle:active {
  cursor: grabbing;
}

.tox .tox-dialog__dismiss {
  margin-left: auto;
}

.tox .tox-dialog__title {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.3;
  margin: 0;
  text-transform: none;
}

.tox .tox-dialog__body {
  color: #222f3e;
  display: flex;
  flex: 1;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.3;
  min-width: 0;
  text-align: left;
  text-transform: none;
}

@media only screen and (max-width: 767px) {
  body:not(.tox-force-desktop) .tox .tox-dialog__body {
    flex-direction: column;
  }
}
.tox .tox-dialog__body-nav {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  padding: 16px 16px;
}

@media only screen and (min-width: 768px) {
  .tox .tox-dialog__body-nav {
    max-width: 11em;
  }
}
@media only screen and (max-width: 767px) {
  body:not(.tox-force-desktop) .tox .tox-dialog__body-nav {
    flex-direction: row;
    -webkit-overflow-scrolling: touch;
    overflow-x: auto;
    padding-bottom: 0;
  }
}
.tox .tox-dialog__body-nav-item {
  border-bottom: 2px solid transparent;
  color: rgba(34, 47, 62, 0.7);
  display: inline-block;
  flex-shrink: 0;
  font-size: 14px;
  line-height: 1.3;
  margin-bottom: 8px;
  max-width: 13em;
  text-decoration: none;
}

.tox .tox-dialog__body-nav-item:focus {
  background-color: rgba(0, 108, 231, 0.1);
}

.tox .tox-dialog__body-nav-item--active {
  border-bottom: 2px solid #006ce7;
  color: #006ce7;
}

.tox .tox-dialog__body-content {
  box-sizing: border-box;
  display: flex;
  flex: 1;
  flex-direction: column;
  max-height: min(650px, 100vh - 110px);
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  padding: 16px 16px;
}

.tox .tox-dialog__body-content > * {
  margin-bottom: 0;
  margin-top: 16px;
}

.tox .tox-dialog__body-content > :first-child {
  margin-top: 0;
}

.tox .tox-dialog__body-content > :last-child {
  margin-bottom: 0;
}

.tox .tox-dialog__body-content > :only-child {
  margin-bottom: 0;
  margin-top: 0;
}

.tox .tox-dialog__body-content a {
  color: #006ce7;
  cursor: pointer;
  text-decoration: underline;
}

.tox .tox-dialog__body-content a:focus, .tox .tox-dialog__body-content a:hover {
  color: #003c81;
  text-decoration: underline;
}

.tox .tox-dialog__body-content a:focus-visible {
  border-radius: 1px;
  outline: 2px solid #006ce7;
  outline-offset: 2px;
}

.tox .tox-dialog__body-content a:active {
  color: #00244e;
  text-decoration: underline;
}

.tox .tox-dialog__body-content svg {
  fill: #222f3e;
}

.tox .tox-dialog__body-content strong {
  font-weight: 700;
}

.tox .tox-dialog__body-content ul {
  list-style-type: disc;
}

.tox .tox-dialog__body-content dd, .tox .tox-dialog__body-content ol, .tox .tox-dialog__body-content ul {
  padding-inline-start: 2.5rem;
}

.tox .tox-dialog__body-content dl, .tox .tox-dialog__body-content ol, .tox .tox-dialog__body-content ul {
  margin-bottom: 16px;
}

.tox .tox-dialog__body-content dd, .tox .tox-dialog__body-content dl, .tox .tox-dialog__body-content dt, .tox .tox-dialog__body-content ol, .tox .tox-dialog__body-content ul {
  display: block;
  margin-inline-end: 0;
  margin-inline-start: 0;
}

.tox .tox-dialog__body-content .tox-form__group h1, .tox .tox-dialog__body-content .tox-form__group .h1 {
  color: #222f3e;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  letter-spacing: normal;
  margin-bottom: 16px;
  margin-top: 2rem;
  text-transform: none;
}

.tox .tox-dialog__body-content .tox-form__group h2, .tox .tox-dialog__body-content .tox-form__group .h2 {
  color: #222f3e;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  letter-spacing: normal;
  margin-bottom: 16px;
  margin-top: 2rem;
  text-transform: none;
}

.tox .tox-dialog__body-content .tox-form__group p {
  margin-bottom: 16px;
}

.tox .tox-dialog__body-content .tox-form__group h1:first-child, .tox .tox-dialog__body-content .tox-form__group .h1:first-child, .tox .tox-dialog__body-content .tox-form__group h2:first-child, .tox .tox-dialog__body-content .tox-form__group .h2:first-child, .tox .tox-dialog__body-content .tox-form__group p:first-child {
  margin-top: 0;
}

.tox .tox-dialog__body-content .tox-form__group h1:last-child, .tox .tox-dialog__body-content .tox-form__group .h1:last-child, .tox .tox-dialog__body-content .tox-form__group h2:last-child, .tox .tox-dialog__body-content .tox-form__group .h2:last-child, .tox .tox-dialog__body-content .tox-form__group p:last-child {
  margin-bottom: 0;
}

.tox .tox-dialog__body-content .tox-form__group h1:only-child, .tox .tox-dialog__body-content .tox-form__group .h1:only-child, .tox .tox-dialog__body-content .tox-form__group h2:only-child, .tox .tox-dialog__body-content .tox-form__group .h2:only-child, .tox .tox-dialog__body-content .tox-form__group p:only-child {
  margin-bottom: 0;
  margin-top: 0;
}

.tox .tox-dialog__body-content .tox-form__group .tox-label.tox-label--center {
  text-align: center;
}

.tox .tox-dialog__body-content .tox-form__group .tox-label.tox-label--end {
  text-align: end;
}

.tox .tox-dialog--width-lg {
  height: 650px;
  max-width: 1200px;
}

.tox .tox-dialog--fullscreen {
  height: 100%;
  max-width: 100%;
}

.tox .tox-dialog--fullscreen .tox-dialog__body-content {
  max-height: 100%;
}

.tox .tox-dialog--width-md {
  max-width: 800px;
}

.tox .tox-dialog--width-md .tox-dialog__body-content {
  overflow: auto;
}

.tox .tox-dialog__body-content--centered {
  text-align: center;
}

.tox .tox-dialog__footer {
  align-items: center;
  background-color: #fff;
  border-top: none;
  display: flex;
  justify-content: space-between;
  padding: 8px 16px;
}

.tox .tox-dialog__footer-end, .tox .tox-dialog__footer-start {
  display: flex;
}

.tox .tox-dialog__busy-spinner {
  align-items: center;
  background-color: rgba(255, 255, 255, 0.75);
  bottom: 0;
  display: flex;
  justify-content: center;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 3;
}

.tox .tox-dialog__table {
  border-collapse: collapse;
  width: 100%;
}

.tox .tox-dialog__table thead th {
  font-weight: 700;
  padding-bottom: 8px;
}

.tox .tox-dialog__table thead th:first-child {
  padding-right: 8px;
}

.tox .tox-dialog__table tbody tr {
  border-bottom: 1px solid #626262;
}

.tox .tox-dialog__table tbody tr:last-child {
  border-bottom: none;
}

.tox .tox-dialog__table td {
  padding-bottom: 8px;
  padding-top: 8px;
}

.tox .tox-dialog__table td:first-child {
  padding-right: 8px;
}

.tox .tox-dialog__iframe {
  min-height: 200px;
}

.tox .tox-dialog__iframe.tox-dialog__iframe--opaque {
  background: #fff;
}

.tox .tox-navobj-bordered {
  position: relative;
}

.tox .tox-navobj-bordered::before {
  border: 1px solid #eee;
  border-radius: 6px;
  content: "";
  inset: 0;
  opacity: 1;
  pointer-events: none;
  position: absolute;
  z-index: 1;
}

.tox .tox-navobj-bordered-focus.tox-navobj-bordered::before {
  border-color: #006ce7;
  box-shadow: 0 0 0 2px rgba(0, 108, 231, 0.25);
  outline: 0;
}

.tox .tox-dialog__popups {
  position: absolute;
  width: 100%;
  z-index: 1100;
}

.tox .tox-dialog__body-iframe {
  display: flex;
  flex: 1;
  flex-direction: column;
}

.tox .tox-dialog__body-iframe .tox-navobj {
  display: flex;
  flex: 1;
}

.tox .tox-dialog__body-iframe .tox-navobj :nth-child(2) {
  flex: 1;
  height: 100%;
}

.tox .tox-dialog-dock-fadeout {
  opacity: 0;
  visibility: hidden;
}

.tox .tox-dialog-dock-fadein {
  opacity: 1;
  visibility: visible;
}

.tox .tox-dialog-dock-transition {
  transition: visibility 0s linear 0.3s, opacity 0.3s ease;
}

.tox .tox-dialog-dock-transition.tox-dialog-dock-fadein {
  transition-delay: 0s;
}

@media only screen and (max-width: 767px) {
  body:not(.tox-force-desktop) .tox:not([dir=rtl]) .tox-dialog__body-nav {
    margin-right: 0;
  }
}
@media only screen and (max-width: 767px) {
  body:not(.tox-force-desktop) .tox:not([dir=rtl]) .tox-dialog__body-nav-item:not(:first-child) {
    margin-left: 8px;
  }
}
.tox:not([dir=rtl]) .tox-dialog__footer .tox-dialog__footer-end > *, .tox:not([dir=rtl]) .tox-dialog__footer .tox-dialog__footer-start > * {
  margin-left: 8px;
}

.tox[dir=rtl] .tox-dialog__body {
  text-align: right;
}

@media only screen and (max-width: 767px) {
  body:not(.tox-force-desktop) .tox[dir=rtl] .tox-dialog__body-nav {
    margin-left: 0;
  }
}
@media only screen and (max-width: 767px) {
  body:not(.tox-force-desktop) .tox[dir=rtl] .tox-dialog__body-nav-item:not(:first-child) {
    margin-right: 8px;
  }
}
.tox[dir=rtl] .tox-dialog__footer .tox-dialog__footer-end > *, .tox[dir=rtl] .tox-dialog__footer .tox-dialog__footer-start > * {
  margin-right: 8px;
}

body.tox-dialog__disable-scroll {
  overflow: hidden;
}

.tox .tox-dropzone-container {
  display: flex;
  flex: 1;
}

.tox .tox-dropzone {
  align-items: center;
  background: #fff;
  border: 2px dashed #eee;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: center;
  min-height: 100px;
  padding: 10px;
}

.tox .tox-dropzone p {
  color: rgba(34, 47, 62, 0.7);
  margin: 0 0 16px 0;
}

.tox .tox-edit-area {
  display: flex;
  flex: 1;
  overflow: hidden;
  position: relative;
}

.tox .tox-edit-area::before {
  border: 2px solid #2d6adf;
  border-radius: 4px;
  content: "";
  inset: 0;
  opacity: 0;
  pointer-events: none;
  position: absolute;
  transition: opacity 0.15s;
  z-index: 1;
}

.tox .tox-edit-area__iframe {
  background-color: #fff;
  border: 0;
  box-sizing: border-box;
  flex: 1;
  height: 100%;
  position: absolute;
  width: 100%;
}

.tox.tox-edit-focus .tox-edit-area::before {
  opacity: 1;
}

.tox.tox-inline-edit-area {
  border: 1px dotted #eee;
}

.tox .tox-editor-container {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  overflow: hidden;
}

.tox .tox-editor-header {
  display: grid;
  grid-template-columns: 1fr min-content;
  z-index: 2;
}

.tox:not(.tox-tinymce-inline) .tox-editor-header {
  background-color: #fff;
  border-bottom: none;
  box-shadow: 0 2px 2px -2px rgba(34, 47, 62, 0.1), 0 8px 8px -4px rgba(34, 47, 62, 0.07);
  padding: 4px 0;
}

.tox:not(.tox-tinymce-inline) .tox-editor-header:not(.tox-editor-dock-transition) {
  transition: box-shadow 0.5s;
}

.tox:not(.tox-tinymce-inline).tox-tinymce--toolbar-bottom .tox-editor-header {
  border-top: 1px solid #e3e3e3;
  box-shadow: none;
}

.tox:not(.tox-tinymce-inline).tox-tinymce--toolbar-sticky-on .tox-editor-header {
  background-color: #fff;
  box-shadow: 0 2px 2px -2px rgba(34, 47, 62, 0.2), 0 8px 8px -4px rgba(34, 47, 62, 0.15);
  padding: 4px 0;
}

.tox:not(.tox-tinymce-inline).tox-tinymce--toolbar-sticky-on.tox-tinymce--toolbar-bottom .tox-editor-header {
  box-shadow: 0 2px 2px -2px rgba(34, 47, 62, 0.2), 0 8px 8px -4px rgba(34, 47, 62, 0.15);
}

.tox.tox:not(.tox-tinymce-inline) .tox-editor-header.tox-editor-header--empty {
  background: 0 0;
  border: none;
  box-shadow: none;
  padding: 0;
}

.tox-editor-dock-fadeout {
  opacity: 0;
  visibility: hidden;
}

.tox-editor-dock-fadein {
  opacity: 1;
  visibility: visible;
}

.tox-editor-dock-transition {
  transition: visibility 0s linear 0.25s, opacity 0.25s ease;
}

.tox-editor-dock-transition.tox-editor-dock-fadein {
  transition-delay: 0s;
}

.tox .tox-control-wrap {
  flex: 1;
  position: relative;
}

.tox .tox-control-wrap:not(.tox-control-wrap--status-invalid) .tox-control-wrap__status-icon-invalid, .tox .tox-control-wrap:not(.tox-control-wrap--status-unknown) .tox-control-wrap__status-icon-unknown, .tox .tox-control-wrap:not(.tox-control-wrap--status-valid) .tox-control-wrap__status-icon-valid {
  display: none;
}

.tox .tox-control-wrap svg {
  display: block;
}

.tox .tox-control-wrap__status-icon-wrap {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.tox .tox-control-wrap__status-icon-invalid svg {
  fill: #c00;
}

.tox .tox-control-wrap__status-icon-unknown svg {
  fill: orange;
}

.tox .tox-control-wrap__status-icon-valid svg {
  fill: green;
}

.tox:not([dir=rtl]) .tox-control-wrap--status-invalid .tox-textfield, .tox:not([dir=rtl]) .tox-control-wrap--status-unknown .tox-textfield, .tox:not([dir=rtl]) .tox-control-wrap--status-valid .tox-textfield {
  padding-right: 32px;
}

.tox:not([dir=rtl]) .tox-control-wrap__status-icon-wrap {
  right: 4px;
}

.tox[dir=rtl] .tox-control-wrap--status-invalid .tox-textfield, .tox[dir=rtl] .tox-control-wrap--status-unknown .tox-textfield, .tox[dir=rtl] .tox-control-wrap--status-valid .tox-textfield {
  padding-left: 32px;
}

.tox[dir=rtl] .tox-control-wrap__status-icon-wrap {
  left: 4px;
}

.tox .tox-autocompleter {
  max-width: 25em;
}

.tox .tox-autocompleter .tox-menu {
  box-sizing: border-box;
  max-width: 25em;
}

.tox .tox-autocompleter .tox-autocompleter-highlight {
  font-weight: 700;
}

.tox .tox-color-input {
  display: flex;
  position: relative;
  z-index: 1;
}

.tox .tox-color-input .tox-textfield {
  z-index: -1;
}

.tox .tox-color-input span {
  border-color: rgba(34, 47, 62, 0.2);
  border-radius: 6px;
  border-style: solid;
  border-width: 1px;
  box-shadow: none;
  box-sizing: border-box;
  height: 24px;
  position: absolute;
  top: 6px;
  width: 24px;
}

.tox .tox-color-input span:focus:not([aria-disabled=true]), .tox .tox-color-input span:hover:not([aria-disabled=true]) {
  border-color: #006ce7;
  cursor: pointer;
}

.tox .tox-color-input span::before {
  background-image: linear-gradient(45deg, rgba(0, 0, 0, 0.25) 25%, transparent 25%), linear-gradient(-45deg, rgba(0, 0, 0, 0.25) 25%, transparent 25%), linear-gradient(45deg, transparent 75%, rgba(0, 0, 0, 0.25) 75%), linear-gradient(-45deg, transparent 75%, rgba(0, 0, 0, 0.25) 75%);
  background-position: 0 0, 0 6px, 6px -6px, -6px 0;
  background-size: 12px 12px;
  border: 1px solid #fff;
  border-radius: 6px;
  box-sizing: border-box;
  content: "";
  height: 24px;
  left: -1px;
  position: absolute;
  top: -1px;
  width: 24px;
  z-index: -1;
}

.tox .tox-color-input span[aria-disabled=true] {
  cursor: not-allowed;
}

.tox:not([dir=rtl]) .tox-color-input .tox-textfield {
  padding-left: 36px;
}

.tox:not([dir=rtl]) .tox-color-input span {
  left: 6px;
}

.tox[dir=rtl] .tox-color-input .tox-textfield {
  padding-right: 36px;
}

.tox[dir=rtl] .tox-color-input span {
  right: 6px;
}

.tox .tox-label, .tox .tox-toolbar-label {
  color: rgba(34, 47, 62, 0.7);
  display: block;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.3;
  padding: 0 8px 0 0;
  text-transform: none;
  white-space: nowrap;
}

.tox .tox-toolbar-label {
  padding: 0 8px;
}

.tox[dir=rtl] .tox-label {
  padding: 0 0 0 8px;
}

.tox .tox-form {
  display: flex;
  flex: 1;
  flex-direction: column;
}

.tox .tox-form__group {
  box-sizing: border-box;
  margin-bottom: 4px;
}

.tox .tox-form-group--maximize {
  flex: 1;
}

.tox .tox-form__group--error {
  color: #c00;
}

.tox .tox-form__group--collection {
  display: flex;
}

.tox .tox-form__grid {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
}

.tox .tox-form__grid--2col > .tox-form__group {
  width: calc(50% - 4px);
}

.tox .tox-form__grid--3col > .tox-form__group {
  width: calc(33.3333333333% - 4px);
}

.tox .tox-form__grid--4col > .tox-form__group {
  width: calc(25% - 4px);
}

.tox .tox-form__controls-h-stack {
  align-items: center;
  display: flex;
}

.tox .tox-form__group--inline {
  align-items: center;
  display: flex;
}

.tox .tox-form__group--stretched {
  display: flex;
  flex: 1;
  flex-direction: column;
}

.tox .tox-form__group--stretched .tox-textarea {
  flex: 1;
}

.tox .tox-form__group--stretched .tox-navobj {
  display: flex;
  flex: 1;
}

.tox .tox-form__group--stretched .tox-navobj :nth-child(2) {
  flex: 1;
  height: 100%;
}

.tox:not([dir=rtl]) .tox-form__controls-h-stack > :not(:first-child) {
  margin-left: 4px;
}

.tox[dir=rtl] .tox-form__controls-h-stack > :not(:first-child) {
  margin-right: 4px;
}

.tox .tox-lock.tox-locked .tox-lock-icon__unlock, .tox .tox-lock:not(.tox-locked) .tox-lock-icon__lock {
  display: none;
}

.tox .tox-listboxfield .tox-listbox--select, .tox .tox-textarea, .tox .tox-textarea-wrap .tox-textarea:focus, .tox .tox-textfield, .tox .tox-toolbar-textfield {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: #fff;
  border-color: #eee;
  border-radius: 6px;
  border-style: solid;
  border-width: 1px;
  box-shadow: none;
  box-sizing: border-box;
  color: #222f3e;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  font-size: 16px;
  line-height: 24px;
  margin: 0;
  min-height: 34px;
  outline: 0;
  padding: 5px 5.5px;
  resize: none;
  width: 100%;
}

.tox .tox-textarea[disabled], .tox .tox-textfield[disabled] {
  background-color: #f2f2f2;
  color: rgba(34, 47, 62, 0.85);
  cursor: not-allowed;
}

.tox .tox-custom-editor:focus-within, .tox .tox-listboxfield .tox-listbox--select:focus, .tox .tox-textarea-wrap:focus-within, .tox .tox-textarea:focus, .tox .tox-textfield:focus {
  background-color: #fff;
  border-color: #006ce7;
  box-shadow: 0 0 0 2px rgba(0, 108, 231, 0.25);
  outline: 0;
}

.tox .tox-toolbar-textfield {
  border-width: 0;
  margin-bottom: 3px;
  margin-top: 2px;
  max-width: 250px;
}

.tox .tox-naked-btn {
  background-color: transparent;
  border: 0;
  border-color: transparent;
  box-shadow: unset;
  color: #006ce7;
  cursor: pointer;
  display: block;
  margin: 0;
  padding: 0;
}

.tox .tox-naked-btn svg {
  display: block;
  fill: #222f3e;
}

.tox:not([dir=rtl]) .tox-toolbar-textfield + * {
  margin-left: 4px;
}

.tox[dir=rtl] .tox-toolbar-textfield + * {
  margin-right: 4px;
}

.tox .tox-listboxfield {
  cursor: pointer;
  position: relative;
}

.tox .tox-listboxfield .tox-listbox--select[disabled] {
  background-color: #f2f2f2;
  color: rgba(34, 47, 62, 0.85);
  cursor: not-allowed;
}

.tox .tox-listbox__select-label {
  cursor: default;
  flex: 1;
  margin: 0 4px;
}

.tox .tox-listbox__select-chevron {
  align-items: center;
  display: flex;
  justify-content: center;
  width: 16px;
}

.tox .tox-listbox__select-chevron svg {
  fill: #222f3e;
}

.tox .tox-listboxfield .tox-listbox--select {
  align-items: center;
  display: flex;
}

.tox:not([dir=rtl]) .tox-listboxfield svg {
  right: 8px;
}

.tox[dir=rtl] .tox-listboxfield svg {
  left: 8px;
}

.tox .tox-selectfield {
  cursor: pointer;
  position: relative;
}

.tox .tox-selectfield select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: #fff;
  border-color: #eee;
  border-radius: 6px;
  border-style: solid;
  border-width: 1px;
  box-shadow: none;
  box-sizing: border-box;
  color: #222f3e;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  font-size: 16px;
  line-height: 24px;
  margin: 0;
  min-height: 34px;
  outline: 0;
  padding: 5px 5.5px;
  resize: none;
  width: 100%;
}

.tox .tox-selectfield select[disabled] {
  background-color: #f2f2f2;
  color: rgba(34, 47, 62, 0.85);
  cursor: not-allowed;
}

.tox .tox-selectfield select::-ms-expand {
  display: none;
}

.tox .tox-selectfield select:focus {
  background-color: #fff;
  border-color: #006ce7;
  box-shadow: 0 0 0 2px rgba(0, 108, 231, 0.25);
  outline: 0;
}

.tox .tox-selectfield svg {
  pointer-events: none;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.tox:not([dir=rtl]) .tox-selectfield select[size="0"], .tox:not([dir=rtl]) .tox-selectfield select[size="1"] {
  padding-right: 24px;
}

.tox:not([dir=rtl]) .tox-selectfield svg {
  right: 8px;
}

.tox[dir=rtl] .tox-selectfield select[size="0"], .tox[dir=rtl] .tox-selectfield select[size="1"] {
  padding-left: 24px;
}

.tox[dir=rtl] .tox-selectfield svg {
  left: 8px;
}

.tox .tox-textarea-wrap {
  border-color: #eee;
  border-radius: 6px;
  border-style: solid;
  border-width: 1px;
  display: flex;
  flex: 1;
  overflow: hidden;
}

.tox .tox-textarea {
  -webkit-appearance: textarea;
  -moz-appearance: textarea;
  appearance: textarea;
  white-space: pre-wrap;
}

.tox .tox-textarea-wrap .tox-textarea {
  border: none;
}

.tox .tox-textarea-wrap .tox-textarea:focus {
  border: none;
}

.tox-fullscreen {
  border: 0;
  height: 100%;
  margin: 0;
  overflow: hidden;
  overscroll-behavior: none;
  padding: 0;
  touch-action: pinch-zoom;
  width: 100%;
}

.tox.tox-tinymce.tox-fullscreen .tox-statusbar__resize-handle {
  display: none;
}

.tox-shadowhost.tox-fullscreen, .tox.tox-tinymce.tox-fullscreen {
  left: 0;
  position: fixed;
  top: 0;
  z-index: 1200;
}

.tox.tox-tinymce.tox-fullscreen {
  background-color: transparent;
}

.tox-fullscreen .tox.tox-tinymce-aux, .tox-fullscreen ~ .tox.tox-tinymce-aux {
  z-index: 1201;
}

.tox .tox-help__more-link {
  list-style: none;
  margin-top: 1em;
}

.tox .tox-imagepreview {
  background-color: #666;
  height: 380px;
  overflow: hidden;
  position: relative;
  width: 100%;
}

.tox .tox-imagepreview.tox-imagepreview__loaded {
  overflow: auto;
}

.tox .tox-imagepreview__container {
  display: flex;
  left: 100vw;
  position: absolute;
  top: 100vw;
}

.tox .tox-imagepreview__image {
  background: url(data:image/gif;base64,R0lGODdhDAAMAIABAMzMzP///ywAAAAADAAMAAACFoQfqYeabNyDMkBQb81Uat85nxguUAEAOw==);
}

.tox .tox-image-tools .tox-spacer {
  flex: 1;
}

.tox .tox-image-tools .tox-bar {
  align-items: center;
  display: flex;
  height: 60px;
  justify-content: center;
}

.tox .tox-image-tools .tox-imagepreview, .tox .tox-image-tools .tox-imagepreview + .tox-bar {
  margin-top: 8px;
}

.tox .tox-image-tools .tox-croprect-block {
  background: #000;
  opacity: 0.5;
  position: absolute;
  zoom: 1;
}

.tox .tox-image-tools .tox-croprect-handle {
  border: 2px solid #fff;
  height: 20px;
  left: 0;
  position: absolute;
  top: 0;
  width: 20px;
}

.tox .tox-image-tools .tox-croprect-handle-move {
  border: 0;
  cursor: move;
  position: absolute;
}

.tox .tox-image-tools .tox-croprect-handle-nw {
  border-width: 2px 0 0 2px;
  cursor: nw-resize;
  left: 100px;
  margin: -2px 0 0 -2px;
  top: 100px;
}

.tox .tox-image-tools .tox-croprect-handle-ne {
  border-width: 2px 2px 0 0;
  cursor: ne-resize;
  left: 200px;
  margin: -2px 0 0 -20px;
  top: 100px;
}

.tox .tox-image-tools .tox-croprect-handle-sw {
  border-width: 0 0 2px 2px;
  cursor: sw-resize;
  left: 100px;
  margin: -20px 2px 0 -2px;
  top: 200px;
}

.tox .tox-image-tools .tox-croprect-handle-se {
  border-width: 0 2px 2px 0;
  cursor: se-resize;
  left: 200px;
  margin: -20px 0 0 -20px;
  top: 200px;
}

.tox .tox-insert-table-picker {
  display: flex;
  flex-wrap: wrap;
  width: 170px;
}

.tox .tox-insert-table-picker > div {
  border-color: #eee;
  border-style: solid;
  border-width: 0 1px 1px 0;
  box-sizing: border-box;
  height: 17px;
  width: 17px;
}

.tox .tox-collection--list .tox-collection__group .tox-insert-table-picker {
  margin: -4px -4px;
}

.tox .tox-insert-table-picker .tox-insert-table-picker__selected {
  background-color: rgba(0, 108, 231, 0.5);
  border-color: rgba(0, 108, 231, 0.5);
}

.tox .tox-insert-table-picker__label {
  color: rgba(34, 47, 62, 0.7);
  display: block;
  font-size: 14px;
  padding: 4px;
  text-align: center;
  width: 100%;
}

.tox:not([dir=rtl]) .tox-insert-table-picker > div:nth-child(10n) {
  border-right: 0;
}

.tox[dir=rtl] .tox-insert-table-picker > div:nth-child(10n+1) {
  border-right: 0;
}

.tox .tox-menu {
  background-color: #fff;
  border: 1px solid transparent;
  border-radius: 6px;
  box-shadow: 0 0 2px 0 rgba(34, 47, 62, 0.2), 0 4px 8px 0 rgba(34, 47, 62, 0.15);
  display: inline-block;
  overflow: hidden;
  vertical-align: top;
  z-index: 1150;
}

.tox .tox-menu.tox-collection.tox-collection--list {
  padding: 0 4px;
}

.tox .tox-menu.tox-collection.tox-collection--toolbar {
  padding: 8px;
}

.tox .tox-menu.tox-collection.tox-collection--grid {
  padding: 8px;
}

@media only screen and (min-width: 768px) {
  .tox .tox-menu .tox-collection__item-label {
    overflow-wrap: break-word;
    word-break: normal;
  }
  .tox .tox-dialog__popups .tox-menu .tox-collection__item-label {
    word-break: break-all;
  }
}
.tox .tox-menu__label blockquote, .tox .tox-menu__label code, .tox .tox-menu__label h1, .tox .tox-menu__label .h1, .tox .tox-menu__label h2, .tox .tox-menu__label .h2, .tox .tox-menu__label h3, .tox .tox-menu__label .h3, .tox .tox-menu__label h4, .tox .tox-menu__label .h4, .tox .tox-menu__label h5, .tox .tox-menu__label .h5, .tox .tox-menu__label h6, .tox .tox-menu__label .h6, .tox .tox-menu__label p {
  margin: 0;
}

.tox .tox-menubar {
  background: repeating-linear-gradient(transparent 0 1px, transparent 1px 39px) center top 39px/100% calc(100% - 39px) no-repeat;
  background-color: #fff;
  display: flex;
  flex: 0 0 auto;
  flex-shrink: 0;
  flex-wrap: wrap;
  grid-column: 1/-1;
  grid-row: 1;
  padding: 0 11px 0 12px;
}

.tox .tox-promotion + .tox-menubar {
  grid-column: 1;
}

.tox .tox-promotion {
  background: repeating-linear-gradient(transparent 0 1px, transparent 1px 39px) center top 39px/100% calc(100% - 39px) no-repeat;
  background-color: #fff;
  grid-column: 2;
  grid-row: 1;
  padding-inline-end: 8px;
  padding-inline-start: 4px;
  padding-top: 5px;
}

.tox .tox-promotion-link {
  align-items: unsafe center;
  background-color: #e8f1f8;
  border-radius: 5px;
  color: #086be6;
  cursor: pointer;
  display: flex;
  font-size: 14px;
  height: 26.6px;
  padding: 4px 8px;
  white-space: nowrap;
}

.tox .tox-promotion-link:hover {
  background-color: #b4d7ff;
}

.tox .tox-promotion-link:focus {
  background-color: #d9edf7;
}

.tox .tox-mbtn {
  align-items: center;
  background: 0 0;
  border: 0;
  border-radius: 3px;
  box-shadow: none;
  color: #222f3e;
  display: flex;
  flex: 0 0 auto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  height: 28px;
  justify-content: center;
  margin: 5px 1px 6px 0;
  outline: 0;
  overflow: hidden;
  padding: 0 4px;
  text-transform: none;
  width: auto;
}

.tox .tox-mbtn[disabled] {
  background-color: transparent;
  border: 0;
  box-shadow: none;
  color: rgba(34, 47, 62, 0.5);
  cursor: not-allowed;
}

.tox .tox-mbtn:focus:not(:disabled) {
  background: #cce2fa;
  border: 0;
  box-shadow: none;
  color: #222f3e;
}

.tox .tox-mbtn--active {
  background: #a6ccf7;
  border: 0;
  box-shadow: none;
  color: #222f3e;
}

.tox .tox-mbtn:hover:not(:disabled):not(.tox-mbtn--active) {
  background: #cce2fa;
  border: 0;
  box-shadow: none;
  color: #222f3e;
}

.tox .tox-mbtn__select-label {
  cursor: default;
  font-weight: 400;
  margin: 0 4px;
}

.tox .tox-mbtn[disabled] .tox-mbtn__select-label {
  cursor: not-allowed;
}

.tox .tox-mbtn__select-chevron {
  align-items: center;
  display: flex;
  justify-content: center;
  width: 16px;
  display: none;
}

.tox .tox-notification {
  border-radius: 6px;
  border-style: solid;
  border-width: 1px;
  box-shadow: none;
  box-sizing: border-box;
  display: grid;
  font-size: 14px;
  font-weight: 400;
  grid-template-columns: minmax(40px, 1fr) auto minmax(40px, 1fr);
  margin-top: 4px;
  opacity: 0;
  padding: 4px;
  transition: transform 0.1s ease-in, opacity 150ms ease-in;
}

.tox .tox-notification p {
  font-size: 14px;
  font-weight: 400;
}

.tox .tox-notification a {
  cursor: pointer;
  text-decoration: underline;
}

.tox .tox-notification--in {
  opacity: 1;
}

.tox .tox-notification--success {
  background-color: #e4eeda;
  border-color: #d7e6c8;
  color: #222f3e;
}

.tox .tox-notification--success p {
  color: #222f3e;
}

.tox .tox-notification--success a {
  color: #517342;
}

.tox .tox-notification--success svg {
  fill: #222f3e;
}

.tox .tox-notification--error {
  background-color: #f5cccc;
  border-color: #f0b3b3;
  color: #222f3e;
}

.tox .tox-notification--error p {
  color: #222f3e;
}

.tox .tox-notification--error a {
  color: #77181f;
}

.tox .tox-notification--error svg {
  fill: #222f3e;
}

.tox .tox-notification--warn, .tox .tox-notification--warning {
  background-color: #fff5cc;
  border-color: #fff0b3;
  color: #222f3e;
}

.tox .tox-notification--warn p, .tox .tox-notification--warning p {
  color: #222f3e;
}

.tox .tox-notification--warn a, .tox .tox-notification--warning a {
  color: #7a6e25;
}

.tox .tox-notification--warn svg, .tox .tox-notification--warning svg {
  fill: #222f3e;
}

.tox .tox-notification--info {
  background-color: #d6e7fb;
  border-color: #c1dbf9;
  color: #222f3e;
}

.tox .tox-notification--info p {
  color: #222f3e;
}

.tox .tox-notification--info a {
  color: #2a64a6;
}

.tox .tox-notification--info svg {
  fill: #222f3e;
}

.tox .tox-notification__body {
  align-self: center;
  color: #222f3e;
  font-size: 14px;
  grid-column-end: 3;
  grid-column-start: 2;
  grid-row-end: 2;
  grid-row-start: 1;
  text-align: center;
  white-space: normal;
  word-break: break-all;
  word-break: break-word;
}

.tox .tox-notification__body > * {
  margin: 0;
}

.tox .tox-notification__body > * + * {
  margin-top: 1rem;
}

.tox .tox-notification__icon {
  align-self: center;
  grid-column-end: 2;
  grid-column-start: 1;
  grid-row-end: 2;
  grid-row-start: 1;
  justify-self: end;
}

.tox .tox-notification__icon svg {
  display: block;
}

.tox .tox-notification__dismiss {
  align-self: start;
  grid-column-end: 4;
  grid-column-start: 3;
  grid-row-end: 2;
  grid-row-start: 1;
  justify-self: end;
}

.tox .tox-notification .tox-progress-bar {
  grid-column-end: 4;
  grid-column-start: 1;
  grid-row-end: 3;
  grid-row-start: 2;
  justify-self: center;
}

.tox .tox-pop {
  display: inline-block;
  position: relative;
}

.tox .tox-pop--resizing {
  transition: width 0.1s ease;
}

.tox .tox-pop--resizing .tox-toolbar, .tox .tox-pop--resizing .tox-toolbar__group {
  flex-wrap: nowrap;
}

.tox .tox-pop--transition {
  transition: 0.15s ease;
  transition-property: left, right, top, bottom;
}

.tox .tox-pop--transition::after, .tox .tox-pop--transition::before {
  transition: all 0.15s, visibility 0s, opacity 75ms ease 75ms;
}

.tox .tox-pop__dialog {
  background-color: #fff;
  border: 1px solid #eee;
  border-radius: 6px;
  box-shadow: 0 0 2px 0 rgba(34, 47, 62, 0.2), 0 4px 8px 0 rgba(34, 47, 62, 0.15);
  min-width: 0;
  overflow: hidden;
}

.tox .tox-pop__dialog > :not(.tox-toolbar) {
  margin: 4px 4px 4px 8px;
}

.tox .tox-pop__dialog .tox-toolbar {
  background-color: transparent;
  margin-bottom: -1px;
}

.tox .tox-pop::after, .tox .tox-pop::before {
  border-style: solid;
  content: "";
  display: block;
  height: 0;
  opacity: 1;
  position: absolute;
  width: 0;
}

.tox .tox-pop.tox-pop--inset::after, .tox .tox-pop.tox-pop--inset::before {
  opacity: 0;
  transition: all 0s 0.15s, visibility 0s, opacity 75ms ease;
}

.tox .tox-pop.tox-pop--bottom::after, .tox .tox-pop.tox-pop--bottom::before {
  left: 50%;
  top: 100%;
}

.tox .tox-pop.tox-pop--bottom::after {
  border-color: #fff transparent transparent transparent;
  border-width: 8px;
  margin-left: -8px;
  margin-top: -1px;
}

.tox .tox-pop.tox-pop--bottom::before {
  border-color: #eee transparent transparent transparent;
  border-width: 9px;
  margin-left: -9px;
}

.tox .tox-pop.tox-pop--top::after, .tox .tox-pop.tox-pop--top::before {
  left: 50%;
  top: 0;
  transform: translateY(-100%);
}

.tox .tox-pop.tox-pop--top::after {
  border-color: transparent transparent #fff transparent;
  border-width: 8px;
  margin-left: -8px;
  margin-top: 1px;
}

.tox .tox-pop.tox-pop--top::before {
  border-color: transparent transparent #eee transparent;
  border-width: 9px;
  margin-left: -9px;
}

.tox .tox-pop.tox-pop--left::after, .tox .tox-pop.tox-pop--left::before {
  left: 0;
  top: calc(50% - 1px);
  transform: translateY(-50%);
}

.tox .tox-pop.tox-pop--left::after {
  border-color: transparent #fff transparent transparent;
  border-width: 8px;
  margin-left: -15px;
}

.tox .tox-pop.tox-pop--left::before {
  border-color: transparent #eee transparent transparent;
  border-width: 10px;
  margin-left: -19px;
}

.tox .tox-pop.tox-pop--right::after, .tox .tox-pop.tox-pop--right::before {
  left: 100%;
  top: calc(50% + 1px);
  transform: translateY(-50%);
}

.tox .tox-pop.tox-pop--right::after {
  border-color: transparent transparent transparent #fff;
  border-width: 8px;
  margin-left: -1px;
}

.tox .tox-pop.tox-pop--right::before {
  border-color: transparent transparent transparent #eee;
  border-width: 10px;
  margin-left: -1px;
}

.tox .tox-pop.tox-pop--align-left::after, .tox .tox-pop.tox-pop--align-left::before {
  left: 20px;
}

.tox .tox-pop.tox-pop--align-right::after, .tox .tox-pop.tox-pop--align-right::before {
  left: calc(100% - 20px);
}

.tox .tox-sidebar-wrap {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  min-height: 0;
}

.tox .tox-sidebar {
  background-color: #fff;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.tox .tox-sidebar__slider {
  display: flex;
  overflow: hidden;
}

.tox .tox-sidebar__pane-container {
  display: flex;
}

.tox .tox-sidebar__pane {
  display: flex;
}

.tox .tox-sidebar--sliding-closed {
  opacity: 0;
}

.tox .tox-sidebar--sliding-open {
  opacity: 1;
}

.tox .tox-sidebar--sliding-growing, .tox .tox-sidebar--sliding-shrinking {
  transition: width 0.5s ease, opacity 0.5s ease;
}

.tox .tox-selector {
  background-color: #4099ff;
  border-color: #4099ff;
  border-style: solid;
  border-width: 1px;
  box-sizing: border-box;
  display: inline-block;
  height: 10px;
  position: absolute;
  width: 10px;
}

.tox.tox-platform-touch .tox-selector {
  height: 12px;
  width: 12px;
}

.tox .tox-slider {
  align-items: center;
  display: flex;
  flex: 1;
  height: 24px;
  justify-content: center;
  position: relative;
}

.tox .tox-slider__rail {
  background-color: transparent;
  border: 1px solid #eee;
  border-radius: 6px;
  height: 10px;
  min-width: 120px;
  width: 100%;
}

.tox .tox-slider__handle {
  background-color: #006ce7;
  border: 2px solid #0054b4;
  border-radius: 6px;
  box-shadow: none;
  height: 24px;
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  width: 14px;
}

.tox .tox-form__controls-h-stack > .tox-slider:not(:first-of-type) {
  margin-inline-start: 8px;
}

.tox .tox-form__controls-h-stack > .tox-form__group + .tox-slider {
  margin-inline-start: 32px;
}

.tox .tox-form__controls-h-stack > .tox-slider + .tox-form__group {
  margin-inline-start: 32px;
}

.tox .tox-source-code {
  overflow: auto;
}

.tox .tox-spinner {
  display: flex;
}

.tox .tox-spinner > div {
  animation: tam-bouncing-dots 1.5s ease-in-out 0s infinite both;
  background-color: rgba(34, 47, 62, 0.7);
  border-radius: 100%;
  height: 8px;
  width: 8px;
}

.tox .tox-spinner > div:nth-child(1) {
  animation-delay: -0.32s;
}

.tox .tox-spinner > div:nth-child(2) {
  animation-delay: -0.16s;
}

@keyframes tam-bouncing-dots {
  0%, 100%, 80% {
    transform: scale(0);
  }
  40% {
    transform: scale(1);
  }
}
.tox:not([dir=rtl]) .tox-spinner > div:not(:first-child) {
  margin-left: 4px;
}

.tox[dir=rtl] .tox-spinner > div:not(:first-child) {
  margin-right: 4px;
}

.tox .tox-statusbar {
  align-items: center;
  background-color: #fff;
  border-top: 1px solid #e3e3e3;
  color: rgba(34, 47, 62, 0.7);
  display: flex;
  flex: 0 0 auto;
  font-size: 14px;
  font-weight: 400;
  height: 25px;
  overflow: hidden;
  padding: 0 8px;
  position: relative;
  text-transform: none;
}

.tox .tox-statusbar__path {
  display: flex;
  flex: 1 1 auto;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.tox .tox-statusbar__right-container {
  display: flex;
  justify-content: flex-end;
  white-space: nowrap;
}

.tox .tox-statusbar__help-text {
  text-align: center;
}

.tox .tox-statusbar__text-container {
  display: flex;
  flex: 1 1 auto;
  justify-content: space-between;
  overflow: hidden;
}

@media only screen and (min-width: 768px) {
  .tox .tox-statusbar__text-container.tox-statusbar__text-container-3-cols > .tox-statusbar__help-text, .tox .tox-statusbar__text-container.tox-statusbar__text-container-3-cols > .tox-statusbar__path, .tox .tox-statusbar__text-container.tox-statusbar__text-container-3-cols > .tox-statusbar__right-container {
    flex: 0 0 33.3333333333%;
  }
}
.tox .tox-statusbar__text-container.tox-statusbar__text-container--flex-end {
  justify-content: flex-end;
}

.tox .tox-statusbar__text-container.tox-statusbar__text-container--flex-start {
  justify-content: flex-start;
}

.tox .tox-statusbar__text-container.tox-statusbar__text-container--space-around {
  justify-content: space-around;
}

.tox .tox-statusbar__path > * {
  display: inline;
  white-space: nowrap;
}

.tox .tox-statusbar__wordcount {
  flex: 0 0 auto;
  margin-left: 1ch;
}

@media only screen and (max-width: 767px) {
  .tox .tox-statusbar__text-container .tox-statusbar__help-text {
    display: none;
  }
  .tox .tox-statusbar__text-container .tox-statusbar__help-text:only-child {
    display: block;
  }
}
.tox .tox-statusbar a, .tox .tox-statusbar__path-item, .tox .tox-statusbar__wordcount {
  color: rgba(34, 47, 62, 0.7);
  text-decoration: none;
}

.tox .tox-statusbar a:focus:not(:disabled):not([aria-disabled=true]), .tox .tox-statusbar a:hover:not(:disabled):not([aria-disabled=true]), .tox .tox-statusbar__path-item:focus:not(:disabled):not([aria-disabled=true]), .tox .tox-statusbar__path-item:hover:not(:disabled):not([aria-disabled=true]), .tox .tox-statusbar__wordcount:focus:not(:disabled):not([aria-disabled=true]), .tox .tox-statusbar__wordcount:hover:not(:disabled):not([aria-disabled=true]) {
  color: #222f3e;
  cursor: pointer;
}

.tox .tox-statusbar__branding svg {
  fill: rgba(34, 47, 62, 0.8);
  height: 1.14em;
  vertical-align: -0.28em;
  width: 3.6em;
}

.tox .tox-statusbar__branding a:focus:not(:disabled):not([aria-disabled=true]) svg, .tox .tox-statusbar__branding a:hover:not(:disabled):not([aria-disabled=true]) svg {
  fill: #222f3e;
}

.tox .tox-statusbar__resize-handle {
  align-items: flex-end;
  align-self: stretch;
  cursor: nwse-resize;
  display: flex;
  flex: 0 0 auto;
  justify-content: flex-end;
  margin-left: auto;
  margin-right: -8px;
  padding-bottom: 3px;
  padding-left: 1ch;
  padding-right: 3px;
}

.tox .tox-statusbar__resize-handle svg {
  display: block;
  fill: rgba(34, 47, 62, 0.5);
}

.tox .tox-statusbar__resize-handle:focus svg {
  background-color: #dee0e2;
  border-radius: 1px 1px 5px 1px;
  box-shadow: 0 0 0 2px #dee0e2;
}

.tox:not([dir=rtl]) .tox-statusbar__path > * {
  margin-right: 4px;
}

.tox:not([dir=rtl]) .tox-statusbar__branding {
  margin-left: 2ch;
}

.tox[dir=rtl] .tox-statusbar {
  flex-direction: row-reverse;
}

.tox[dir=rtl] .tox-statusbar__path > * {
  margin-left: 4px;
}

.tox .tox-throbber {
  z-index: 1299;
}

.tox .tox-throbber__busy-spinner {
  align-items: center;
  background-color: rgba(255, 255, 255, 0.6);
  bottom: 0;
  display: flex;
  justify-content: center;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}

.tox .tox-tbtn {
  align-items: center;
  background: 0 0;
  border: 0;
  border-radius: 3px;
  box-shadow: none;
  color: #222f3e;
  display: flex;
  flex: 0 0 auto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  height: 28px;
  justify-content: center;
  margin: 6px 1px 5px 0;
  outline: 0;
  overflow: hidden;
  padding: 0;
  text-transform: none;
  width: 34px;
}

.tox .tox-tbtn svg {
  display: block;
  fill: #222f3e;
}

.tox .tox-tbtn.tox-tbtn-more {
  padding-left: 5px;
  padding-right: 5px;
  width: inherit;
}

.tox .tox-tbtn:focus {
  background: #cce2fa;
  border: 0;
  box-shadow: none;
}

.tox .tox-tbtn:hover {
  background: #cce2fa;
  border: 0;
  box-shadow: none;
  color: #222f3e;
}

.tox .tox-tbtn:hover svg {
  fill: #222f3e;
}

.tox .tox-tbtn:active {
  background: #a6ccf7;
  border: 0;
  box-shadow: none;
  color: #222f3e;
}

.tox .tox-tbtn:active svg {
  fill: #222f3e;
}

.tox .tox-tbtn--disabled .tox-tbtn--enabled svg {
  fill: rgba(34, 47, 62, 0.5);
}

.tox .tox-tbtn--disabled, .tox .tox-tbtn--disabled:hover, .tox .tox-tbtn:disabled, .tox .tox-tbtn:disabled:hover {
  background: 0 0;
  border: 0;
  box-shadow: none;
  color: rgba(34, 47, 62, 0.5);
  cursor: not-allowed;
}

.tox .tox-tbtn--disabled svg, .tox .tox-tbtn--disabled:hover svg, .tox .tox-tbtn:disabled svg, .tox .tox-tbtn:disabled:hover svg {
  fill: rgba(34, 47, 62, 0.5);
}

.tox .tox-tbtn--enabled, .tox .tox-tbtn--enabled:hover {
  background: #a6ccf7;
  border: 0;
  box-shadow: none;
  color: #222f3e;
}

.tox .tox-tbtn--enabled:hover > *, .tox .tox-tbtn--enabled > * {
  transform: none;
}

.tox .tox-tbtn--enabled svg, .tox .tox-tbtn--enabled:hover svg {
  fill: #222f3e;
}

.tox .tox-tbtn--enabled.tox-tbtn--disabled svg, .tox .tox-tbtn--enabled:hover.tox-tbtn--disabled svg {
  fill: rgba(34, 47, 62, 0.5);
}

.tox .tox-tbtn:focus:not(.tox-tbtn--disabled) {
  color: #222f3e;
}

.tox .tox-tbtn:focus:not(.tox-tbtn--disabled) svg {
  fill: #222f3e;
}

.tox .tox-tbtn:active > * {
  transform: none;
}

.tox .tox-tbtn--md {
  height: 42px;
  width: 51px;
}

.tox .tox-tbtn--lg {
  flex-direction: column;
  height: 56px;
  width: 68px;
}

.tox .tox-tbtn--return {
  align-self: stretch;
  height: unset;
  width: 16px;
}

.tox .tox-tbtn--labeled {
  padding: 0 4px;
  width: unset;
}

.tox .tox-tbtn__vlabel {
  display: block;
  font-size: 10px;
  font-weight: 400;
  letter-spacing: -0.025em;
  margin-bottom: 4px;
  white-space: nowrap;
}

.tox .tox-number-input {
  border-radius: 3px;
  display: flex;
  margin: 6px 1px 5px 0;
  padding: 0 4px;
  width: auto;
}

.tox .tox-number-input .tox-input-wrapper {
  background: #f7f7f7;
  display: flex;
  pointer-events: none;
  text-align: center;
}

.tox .tox-number-input .tox-input-wrapper:focus {
  background: #cce2fa;
}

.tox .tox-number-input input {
  border-radius: 3px;
  color: #222f3e;
  font-size: 14px;
  margin: 2px 0;
  pointer-events: all;
  width: 60px;
}

.tox .tox-number-input input:hover {
  background: #cce2fa;
  color: #222f3e;
}

.tox .tox-number-input input:focus {
  background: #fff;
  color: #222f3e;
}

.tox .tox-number-input input:disabled {
  background: 0 0;
  border: 0;
  box-shadow: none;
  color: rgba(34, 47, 62, 0.5);
  cursor: not-allowed;
}

.tox .tox-number-input button {
  background: #f7f7f7;
  color: #222f3e;
  height: 28px;
  text-align: center;
  width: 24px;
}

.tox .tox-number-input button svg {
  display: block;
  fill: #222f3e;
  margin: 0 auto;
  transform: scale(0.67);
}

.tox .tox-number-input button:focus {
  background: #cce2fa;
}

.tox .tox-number-input button:hover {
  background: #cce2fa;
  border: 0;
  box-shadow: none;
  color: #222f3e;
}

.tox .tox-number-input button:hover svg {
  fill: #222f3e;
}

.tox .tox-number-input button:active {
  background: #a6ccf7;
  border: 0;
  box-shadow: none;
  color: #222f3e;
}

.tox .tox-number-input button:active svg {
  fill: #222f3e;
}

.tox .tox-number-input button:disabled {
  background: 0 0;
  border: 0;
  box-shadow: none;
  color: rgba(34, 47, 62, 0.5);
  cursor: not-allowed;
}

.tox .tox-number-input button:disabled svg {
  fill: rgba(34, 47, 62, 0.5);
}

.tox .tox-number-input button.minus {
  border-radius: 3px 0 0 3px;
}

.tox .tox-number-input button.plus {
  border-radius: 0 3px 3px 0;
}

.tox .tox-number-input:focus:not(:active) > .tox-input-wrapper, .tox .tox-number-input:focus:not(:active) > button {
  background: #cce2fa;
}

.tox .tox-tbtn--select {
  margin: 6px 1px 5px 0;
  padding: 0 4px;
  width: auto;
}

.tox .tox-tbtn__select-label {
  cursor: default;
  font-weight: 400;
  height: initial;
  margin: 0 4px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.tox .tox-tbtn__select-chevron {
  align-items: center;
  display: flex;
  justify-content: center;
  width: 16px;
}

.tox .tox-tbtn__select-chevron svg {
  fill: rgba(34, 47, 62, 0.5);
}

.tox .tox-tbtn--bespoke {
  background: #f7f7f7;
}

.tox .tox-tbtn--bespoke + .tox-tbtn--bespoke {
  margin-inline-start: 4px;
}

.tox .tox-tbtn--bespoke .tox-tbtn__select-label {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 7em;
}

.tox .tox-tbtn--disabled .tox-tbtn__select-label, .tox .tox-tbtn--select:disabled .tox-tbtn__select-label {
  cursor: not-allowed;
}

.tox .tox-split-button {
  border: 0;
  border-radius: 3px;
  box-sizing: border-box;
  display: flex;
  margin: 6px 1px 5px 0;
  overflow: hidden;
}

.tox .tox-split-button:hover {
  box-shadow: 0 0 0 1px #cce2fa inset;
}

.tox .tox-split-button:focus {
  background: #cce2fa;
  box-shadow: none;
  color: #222f3e;
}

.tox .tox-split-button > * {
  border-radius: 0;
}

.tox .tox-split-button__chevron {
  width: 16px;
}

.tox .tox-split-button__chevron svg {
  fill: rgba(34, 47, 62, 0.5);
}

.tox .tox-split-button .tox-tbtn {
  margin: 0;
}

.tox .tox-split-button.tox-tbtn--disabled .tox-tbtn:focus, .tox .tox-split-button.tox-tbtn--disabled .tox-tbtn:hover, .tox .tox-split-button.tox-tbtn--disabled:focus, .tox .tox-split-button.tox-tbtn--disabled:hover {
  background: 0 0;
  box-shadow: none;
  color: rgba(34, 47, 62, 0.5);
}

.tox.tox-platform-touch .tox-split-button .tox-tbtn--select {
  padding: 0 0;
}

.tox.tox-platform-touch .tox-split-button .tox-tbtn:not(.tox-tbtn--select):first-child {
  width: 30px;
}

.tox.tox-platform-touch .tox-split-button__chevron {
  width: 20px;
}

.tox .tox-split-button.tox-tbtn--disabled svg #tox-icon-highlight-bg-color__color, .tox .tox-split-button.tox-tbtn--disabled svg #tox-icon-text-color__color {
  opacity: 0.6;
}

.tox .tox-toolbar-overlord {
  background-color: #fff;
}

.tox .tox-toolbar, .tox .tox-toolbar__overflow, .tox .tox-toolbar__primary {
  background-attachment: local;
  background-color: #fff;
  background-image: repeating-linear-gradient(#e3e3e3 0 1px, transparent 1px 39px);
  background-position: center top 40px;
  background-repeat: no-repeat;
  background-size: calc(100% - 22px) calc(100% - 41px);
  display: flex;
  flex: 0 0 auto;
  flex-shrink: 0;
  flex-wrap: wrap;
  padding: 0 0;
  transform: perspective(1px);
}

.tox .tox-toolbar-overlord > .tox-toolbar, .tox .tox-toolbar-overlord > .tox-toolbar__overflow, .tox .tox-toolbar-overlord > .tox-toolbar__primary {
  background-position: center top 0;
  background-size: calc(100% - 22px) calc(100% - 0px);
}

.tox .tox-toolbar__overflow.tox-toolbar__overflow--closed {
  height: 0;
  opacity: 0;
  padding-bottom: 0;
  padding-top: 0;
  visibility: hidden;
}

.tox .tox-toolbar__overflow--growing {
  transition: height 0.3s ease, opacity 0.2s linear 0.1s;
}

.tox .tox-toolbar__overflow--shrinking {
  transition: opacity 0.3s ease, height 0.2s linear 0.1s, visibility 0s linear 0.3s;
}

.tox .tox-anchorbar, .tox .tox-toolbar-overlord {
  grid-column: 1/-1;
}

.tox .tox-menubar + .tox-toolbar, .tox .tox-menubar + .tox-toolbar-overlord {
  border-top: 1px solid transparent;
  margin-top: -1px;
  padding-bottom: 1px;
  padding-top: 1px;
}

.tox .tox-toolbar--scrolling {
  flex-wrap: nowrap;
  overflow-x: auto;
}

.tox .tox-pop .tox-toolbar {
  border-width: 0;
}

.tox .tox-toolbar--no-divider {
  background-image: none;
}

.tox .tox-toolbar-overlord .tox-toolbar:not(.tox-toolbar--scrolling):first-child, .tox .tox-toolbar-overlord .tox-toolbar__primary {
  background-position: center top 39px;
}

.tox .tox-editor-header > .tox-toolbar--scrolling, .tox .tox-toolbar-overlord .tox-toolbar--scrolling:first-child {
  background-image: none;
}

.tox.tox-tinymce-aux .tox-toolbar__overflow {
  background-color: #fff;
  background-position: center top 43px;
  background-size: calc(100% - 16px) calc(100% - 51px);
  border: none;
  border-radius: 6px;
  box-shadow: 0 0 2px 0 rgba(34, 47, 62, 0.2), 0 4px 8px 0 rgba(34, 47, 62, 0.15);
  overscroll-behavior: none;
  padding: 4px 0;
}

.tox-pop .tox-pop__dialog .tox-toolbar {
  background-position: center top 43px;
  background-size: calc(100% - 22px) calc(100% - 51px);
  padding: 4px 0;
}

.tox .tox-toolbar__group {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  margin: 0 0;
  padding: 0 11px 0 12px;
}

.tox .tox-toolbar__group--pull-right {
  margin-left: auto;
}

.tox .tox-toolbar--scrolling .tox-toolbar__group {
  flex-shrink: 0;
  flex-wrap: nowrap;
}

.tox:not([dir=rtl]) .tox-toolbar__group:not(:last-of-type) {
  border-right: 1px solid transparent;
}

.tox[dir=rtl] .tox-toolbar__group:not(:last-of-type) {
  border-left: 1px solid transparent;
}

.tox .tox-tooltip {
  display: inline-block;
  padding: 8px;
  position: relative;
}

.tox .tox-tooltip__body {
  background-color: #222f3e;
  border-radius: 6px;
  box-shadow: 0 2px 4px rgba(34, 47, 62, 0.3);
  color: rgba(255, 255, 255, 0.75);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  padding: 4px 8px;
  text-transform: none;
}

.tox .tox-tooltip__arrow {
  position: absolute;
}

.tox .tox-tooltip--down .tox-tooltip__arrow {
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-top: 8px solid #222f3e;
  bottom: 0;
  left: 50%;
  position: absolute;
  transform: translateX(-50%);
}

.tox .tox-tooltip--up .tox-tooltip__arrow {
  border-bottom: 8px solid #222f3e;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  left: 50%;
  position: absolute;
  top: 0;
  transform: translateX(-50%);
}

.tox .tox-tooltip--right .tox-tooltip__arrow {
  border-bottom: 8px solid transparent;
  border-left: 8px solid #222f3e;
  border-top: 8px solid transparent;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}

.tox .tox-tooltip--left .tox-tooltip__arrow {
  border-bottom: 8px solid transparent;
  border-right: 8px solid #222f3e;
  border-top: 8px solid transparent;
  left: 0;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.tox .tox-tree {
  display: flex;
  flex-direction: column;
}

.tox .tox-tree .tox-trbtn {
  align-items: center;
  background: 0 0;
  border: 0;
  border-radius: 4px;
  box-shadow: none;
  color: #222f3e;
  display: flex;
  flex: 0 0 auto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  height: 28px;
  margin-bottom: 4px;
  margin-top: 4px;
  outline: 0;
  overflow: hidden;
  padding: 0;
  padding-left: 8px;
  text-transform: none;
}

.tox .tox-tree .tox-trbtn .tox-tree__label {
  cursor: default;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.tox .tox-tree .tox-trbtn svg {
  display: block;
  fill: #222f3e;
}

.tox .tox-tree .tox-trbtn:focus {
  background: #cce2fa;
  border: 0;
  box-shadow: none;
}

.tox .tox-tree .tox-trbtn:hover {
  background: #cce2fa;
  border: 0;
  box-shadow: none;
  color: #222f3e;
}

.tox .tox-tree .tox-trbtn:hover svg {
  fill: #222f3e;
}

.tox .tox-tree .tox-trbtn:active {
  background: #a6ccf7;
  border: 0;
  box-shadow: none;
  color: #222f3e;
}

.tox .tox-tree .tox-trbtn:active svg {
  fill: #222f3e;
}

.tox .tox-tree .tox-trbtn--disabled, .tox .tox-tree .tox-trbtn--disabled:hover, .tox .tox-tree .tox-trbtn:disabled, .tox .tox-tree .tox-trbtn:disabled:hover {
  background: 0 0;
  border: 0;
  box-shadow: none;
  color: rgba(34, 47, 62, 0.5);
  cursor: not-allowed;
}

.tox .tox-tree .tox-trbtn--disabled svg, .tox .tox-tree .tox-trbtn--disabled:hover svg, .tox .tox-tree .tox-trbtn:disabled svg, .tox .tox-tree .tox-trbtn:disabled:hover svg {
  fill: rgba(34, 47, 62, 0.5);
}

.tox .tox-tree .tox-trbtn--enabled, .tox .tox-tree .tox-trbtn--enabled:hover {
  background: #a6ccf7;
  border: 0;
  box-shadow: none;
  color: #222f3e;
}

.tox .tox-tree .tox-trbtn--enabled:hover > *, .tox .tox-tree .tox-trbtn--enabled > * {
  transform: none;
}

.tox .tox-tree .tox-trbtn--enabled svg, .tox .tox-tree .tox-trbtn--enabled:hover svg {
  fill: #222f3e;
}

.tox .tox-tree .tox-trbtn:focus:not(.tox-trbtn--disabled) {
  color: #222f3e;
}

.tox .tox-tree .tox-trbtn:focus:not(.tox-trbtn--disabled) svg {
  fill: #222f3e;
}

.tox .tox-tree .tox-trbtn:active > * {
  transform: none;
}

.tox .tox-tree .tox-trbtn--return {
  align-self: stretch;
  height: unset;
  width: 16px;
}

.tox .tox-tree .tox-trbtn--labeled {
  padding: 0 4px;
  width: unset;
}

.tox .tox-tree .tox-trbtn__vlabel {
  display: block;
  font-size: 10px;
  font-weight: 400;
  letter-spacing: -0.025em;
  margin-bottom: 4px;
  white-space: nowrap;
}

.tox .tox-tree .tox-tree--directory {
  display: flex;
  flex-direction: column;
}

.tox .tox-tree .tox-tree--directory .tox-tree--directory__label {
  font-weight: 700;
}

.tox .tox-tree .tox-tree--directory .tox-tree--directory__label .tox-mbtn {
  margin-left: auto;
}

.tox .tox-tree .tox-tree--directory .tox-tree--directory__label .tox-mbtn svg {
  fill: transparent;
}

.tox .tox-tree .tox-tree--directory .tox-tree--directory__label .tox-mbtn.tox-mbtn--active svg, .tox .tox-tree .tox-tree--directory .tox-tree--directory__label .tox-mbtn:focus svg {
  fill: #222f3e;
}

.tox .tox-tree .tox-tree--directory .tox-tree--directory__label:focus .tox-mbtn svg, .tox .tox-tree .tox-tree--directory .tox-tree--directory__label:hover .tox-mbtn svg {
  fill: #222f3e;
}

.tox .tox-tree .tox-tree--directory .tox-tree--directory__label:hover:has(.tox-mbtn:hover) {
  background-color: transparent;
  color: #222f3e;
}

.tox .tox-tree .tox-tree--directory .tox-tree--directory__label:hover:has(.tox-mbtn:hover) .tox-chevron svg {
  fill: #222f3e;
}

.tox .tox-tree .tox-tree--directory .tox-tree--directory__label .tox-chevron {
  margin-right: 6px;
}

.tox .tox-tree .tox-tree--directory .tox-tree--directory__label:has(+ .tox-tree--directory__children--growing) .tox-chevron, .tox .tox-tree .tox-tree--directory .tox-tree--directory__label:has(+ .tox-tree--directory__children--shrinking) .tox-chevron {
  transition: transform 0.5s ease-in-out;
}

.tox .tox-tree .tox-tree--directory .tox-tree--directory__label:has(+ .tox-tree--directory__children--growing) .tox-chevron, .tox .tox-tree .tox-tree--directory .tox-tree--directory__label:has(+ .tox-tree--directory__children--open) .tox-chevron {
  transform: rotate(90deg);
}

.tox .tox-tree .tox-tree--leaf__label {
  font-weight: 400;
}

.tox .tox-tree .tox-tree--leaf__label .tox-mbtn {
  margin-left: auto;
}

.tox .tox-tree .tox-tree--leaf__label .tox-mbtn svg {
  fill: transparent;
}

.tox .tox-tree .tox-tree--leaf__label .tox-mbtn.tox-mbtn--active svg, .tox .tox-tree .tox-tree--leaf__label .tox-mbtn:focus svg {
  fill: #222f3e;
}

.tox .tox-tree .tox-tree--leaf__label:hover .tox-mbtn svg {
  fill: #222f3e;
}

.tox .tox-tree .tox-tree--leaf__label:hover:has(.tox-mbtn:hover) {
  background-color: transparent;
  color: #222f3e;
}

.tox .tox-tree .tox-tree--leaf__label:hover:has(.tox-mbtn:hover) .tox-chevron svg {
  fill: #222f3e;
}

.tox .tox-tree .tox-tree--directory__children {
  overflow: hidden;
  padding-left: 16px;
}

.tox .tox-tree .tox-tree--directory__children.tox-tree--directory__children--growing, .tox .tox-tree .tox-tree--directory__children.tox-tree--directory__children--shrinking {
  transition: height 0.5s ease-in-out;
}

.tox .tox-tree .tox-trbtn.tox-tree--leaf__label {
  display: flex;
  justify-content: space-between;
}

.tox .tox-view-wrap, .tox .tox-view-wrap__slot-container {
  background-color: #fff;
  display: flex;
  flex: 1;
  flex-direction: column;
}

.tox .tox-view {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  overflow: hidden;
}

.tox .tox-view__header {
  align-items: center;
  display: flex;
  font-size: 16px;
  justify-content: space-between;
  padding: 8px 8px 0 8px;
  position: relative;
}

.tox .tox-view--mobile.tox-view__header, .tox .tox-view--mobile.tox-view__toolbar {
  padding: 8px;
}

.tox .tox-view--scrolling {
  flex-wrap: nowrap;
  overflow-x: auto;
}

.tox .tox-view__toolbar {
  display: flex;
  flex-direction: row;
  gap: 8px;
  justify-content: space-between;
  padding: 8px 8px 0 8px;
}

.tox .tox-view__toolbar__group {
  display: flex;
  flex-direction: row;
  gap: 12px;
}

.tox .tox-view__header-end, .tox .tox-view__header-start {
  display: flex;
}

.tox .tox-view__pane {
  height: 100%;
  padding: 8px;
  width: 100%;
}

.tox .tox-view__pane_panel {
  border: 1px solid #eee;
  border-radius: 6px;
}

.tox:not([dir=rtl]) .tox-view__header .tox-view__header-end > *, .tox:not([dir=rtl]) .tox-view__header .tox-view__header-start > * {
  margin-left: 8px;
}

.tox[dir=rtl] .tox-view__header .tox-view__header-end > *, .tox[dir=rtl] .tox-view__header .tox-view__header-start > * {
  margin-right: 8px;
}

.tox .tox-well {
  border: 1px solid #eee;
  border-radius: 6px;
  padding: 8px;
  width: 100%;
}

.tox .tox-well > :first-child {
  margin-top: 0;
}

.tox .tox-well > :last-child {
  margin-bottom: 0;
}

.tox .tox-well > :only-child {
  margin: 0;
}

.tox .tox-custom-editor {
  border: 1px solid #eee;
  border-radius: 6px;
  display: flex;
  flex: 1;
  overflow: hidden;
  position: relative;
}

.tox .tox-dialog-loading::before {
  background-color: rgba(0, 0, 0, 0.5);
  content: "";
  height: 100%;
  position: absolute;
  width: 100%;
  z-index: 1000;
}

.tox .tox-tab {
  cursor: pointer;
}

.tox .tox-dialog__content-js {
  display: flex;
  flex: 1;
}

.tox .tox-dialog__body-content .tox-collection {
  display: flex;
  flex: 1;
}

.tag-bar {
  background: var(--primary-white-color);
  border: 1px solid var(--medium-gray);
  padding: 0 15px;
  border-bottom-width: 3px;
  position: relative;
  top: -3px;
}
.tag-bar > div {
  display: inline-block;
  vertical-align: middle;
  margin-right: 5px;
  padding: 6px 0;
}
.tag-bar > div .wj-form-group-sm {
  margin: 0;
}
.tag-bar > div:first-child {
  font-size: 0.9625rem;
}
.tag-bar > div .btn {
  padding: 5px 8px 4px;
  font-size: 0.75rem;
}
.tag-bar > div .insert-btn {
  margin-right: 30px;
  border-radius: 0;
  padding: 6px 6px 5px;
  position: relative;
  left: -5px;
}

.tox .tox-toolbar, .tox .tox-toolbar__overflow, .tox .tox-toolbar__primary {
  background-color: var(--primary-white-color);
}

.tox:not(.tox-tinymce-inline) .tox-editor-header {
  background-color: var(--primary-white-color);
  box-shadow: unset;
  border-bottom: 1px solid var(--medium-gray);
}

.tox-tinymce {
  border-top-right-radius: 0;
  border-top-left-radius: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
  border: 1px solid var(--medium-gray);
}

/* Old Colors

$color0: #ededed; // white gray
$color1: rgb(190,190,190); // #bebebe - light gray
$color2: rgb(80,80,80); // #505050 - gray
$color3: rgb(207,214,224); // #cfd6e0 - white blue
$color4: rgb(159,172,193); // #9facc1 - light blue
$color5: rgb(88,111,146); // #586f92 - mid blue
$color6: rgb(15,48,99); // #1B3D6D - dark blue
$color7: rgb(57,213,31); // #39d51f - green
$color8: rgb(52,197,26); // #34C51A - dark green
*/
/*Theming*/
:root {
  --loading-login-screen-background:
          linear-gradient(to right, rgba(15, 48, 99, 0) 0%, rgba(75, 107, 155, .4) 25%, rgba(168, 187, 215, .4) 42%, rgba(168, 187, 215, .4) 58%, rgba(75, 107, 155, .4) 75%, rgba(15, 48, 99, 0) 100%),
          url(".././assets/images/pattern-block-lines.png") repeat;
  --loading-screen-logo: url(".././assets/images/bakerhill-nextgen-logo-white.png");
  --login-box-logo: url(".././assets/images/bakerhill-nextgen-logo.png");
  --primary-dark-color: #1B3D6D;
  --primary-color: #456aa1;
  --primary-color-darken-7percent: #3a5a88;
  --primary-color-darken-11percent: #34507a;
  --primary-color-lighten-10percent: #5f84ba;
  --primary-color-lighten-20percent: #829fca;
  --primary-color-lighten-40percent: #cad6e8;
  --primary-light1-color: #a6bad9;
  --primary-light1-color-darken-5percent:#94acd1;
  --primary-light1-color-darken-10percent: #829eca;
  --primary-light1-color-darken-15percent: #7090c2;
  --primary-light1-color-lighten-6percent: #bbcbe2;
  --primary-white-color: #e6ecf2;
  --primary-white-color-darken-2percent: #dfe7ef;
  --primary-white-color-darken-6percent: #d2dde8;
  --primary-white-color-lighten-2percent: #edf1f5;
  --primary-white-color-lighten-4percent: #f3f6f9;
  --primary-color2: #cddc29;
  --primary-color3: #81BD41;
  --primary-color3-lighten-40percent: #e5f2d8;
  --primary-color3-darken-5percent: #74aa3a;
  --primary-dark2-color: #689834;
  --primary-dark2-color-darken-10percent: #4e7227;
  --primary-light2-color: #e6f2d9;
  --primary-light2-color-darken-2percent: #e1efd1;
  --primary-light2-color-darken-6percent: #d7eac2;
  --primary-light2-color-darken-10percent: #cde5b3;
  --grayscale-color: #58595B;
  --grayscale-color-lighten-25percent: #98999b;
  --grayscale-light-color: #bdbdc2;
  --grayscale-light-color-darken-8percent: #a8a8ae;
  --grayscale-light-color-lighten-7percent: #d0d0d3;
  --grayscale-white-color: #ebebed;
  --grayscale-white-color-darken-5percent: #dedee1;
  --grayscale-white-color-darken-8percent: #d6d6da;
  --grayscale-white-color-lighten-2percent: #f0f0f2;
  --grayscale-white-color-lighten-5percent: #f8f8f9;
  --white-color: #ffffff;
  --black-color: #000000;
  --secondary-dark-color: #007299;
  --secondary-color: #77256c;
  --secondary-color2: #f68d29;
  --secondary-color3: #ffd240;
  --secondary-color3-darken-3percent: #ffce31;
  --secondary-color3-lighten-17percent: #ffe697;
  --secondary-color3-lighten-27percent: #fff2ca;
  --secondary-bright-color: #ece815;
  --error-color: #cc0000;
  --error-color-darken-10percent: #990000;
  --error-color-lighten-50percent: #ffcccc;
  --error-color-lighten-55percent: #ffe6e6;
  --error-light-color: #ffe6e6;
  --error-light-color-darken-5percent: #ffcdcd;
  --disabled-color: #abbcd5;
  --disabled-color2: #9badca;
  --disabled-color3: #a7d17a;
  --default-sprite-position: top left;
  --active-hover-sprite-position: top right;
  /*remaing colors*/
  --snow-gray: #fcfcfc;
  --light-gainsboro-gray: #f7f7f7;
  --white-smoke: #f4f4f4;
  --very-light-gray: #f0f0f0;
  --light-pink: #febbbb;
  --light-yellow: #ffffe5;
  --orange-peel: #ff9900;
  --electric-yellow: #ffff00;
  --yellow-green: #effadb;
  --whisper-gray: #ededed;
  --dark-whisper-gray: #eeeeee;
  --white-dark-smoke: #e6e6e6;
  --gainsboro-gray: #e0e0e0;
  --medium-gray: #cccccc;
  --silver: #c6c6c6;
  --silver-very-light: #b9b9b9;
  --silver-light: #bbbbbb;
  --blue-smoke: #eef1f7;
  --ivory-red: #f8f8f2;
  --spun-pearl: #afafb6;
  --very-light-blue: #e6ebf5;
  --dark-gainsboro-gray: #d9d9d9;
  --bright-gainsboro-gray: #dddddd;
  --hawakes-blue: #ccd6eb;
  --blue-violet: #9933ff;
  --nobel-gray: #999999;
  --light-danube-blue: #89a4cd;
  --matte-gray: #888888;
  --light-matte-gray: #787878;
  --dark-gray: #777777;
  --raven-gray: #707275;
  --danube-blue: #6685c2;
  --dim-gray: #666666;
  --davy-grey: #555555;
  --analog-blue: #435f8b;
  --charcoal-gray: #444444;
  --mariner-blue: #335cad;
  --dark-charcoal-gray: #333333;
  --green-waterloo: #23241f;
  --nero-dark: #222222;
  --smalt-blue: #003399;
  --deep-green: #008a00;
  --navy-blue: #0066cc;
  --bondi-blue: #0085c7;
  --violation-text: #cc0000;
  --violation-bg: #ffe6e6;
  --warning-text: #f68d29;
  --warning-bg: #fff2ca;
  --highcharts-color-2: #cddc29;
  --highcharts-color-3: #007299;
  --highcharts-color-6: #689834;
  --highcharts-color-7: #a6bad9;
}

[data-theme=fiserv] {
  --loading-login-screen-background: #666666;
  --loading-screen-logo: url(".././assets/images/Fiserv-logo-1200x603.png");
  --login-box-logo: url(".././assets/images/Fiserv-logo-1200x603.png");
  --primary-dark-color: #333333;
  --primary-color: #666666;
  --primary-color-darken-7percent: #545454;
  --primary-color-darken-11percent: #4a4a4a;
  --primary-color-lighten-10percent: gray;
  --primary-color-lighten-20percent: #999999;
  --primary-color-lighten-40percent: #cccccc;
  --primary-light1-color: #bfbfbf;
  --primary-light1-color-darken-5percent: #b2b2b2;
  --primary-light1-color-darken-10percent: #a6a6a6;
  --primary-light1-color-darken-15percent: #999999;
  --primary-light1-color-lighten-6percent: #cecece;
  --primary-white-color: #efefef;
  --primary-white-color-darken-2percent: #eaeaea;
  --primary-white-color-darken-6percent: #e0e0e0;
  --primary-white-color-lighten-2percent: #f4f4f4;
  --primary-white-color-lighten-4percent: #f9f9f9;
  --primary-color2: #4695d1;
  --primary-color3: #ff6600;
  --primary-color3-lighten-40percent: #ffe0cc;
  --primary-color3-darken-5percent: #e65c00;
  --primary-dark2-color: #ea6b15;
  --primary-dark2-color-darken-10percent: #bb5611;
  --primary-light2-color: #f2d3c4;
  --primary-light2-color-darken-2percent: #f0cdbc;
  --primary-light2-color-darken-6percent: #ecc0ab;
  --primary-light2-color-darken-10percent: #e9b49a;
  --grayscale-color: #666666;
  --grayscale-color-lighten-25percent: #a6a6a6;
  --grayscale-light-color: #e0e0e0;
  --grayscale-light-color-darken-8percent: #cccccc;
  --grayscale-light-color-lighten-7percent: #f2f2f2;
  --grayscale-white-color: #e0e0e0;
  --grayscale-white-color-darken-5percent: lightgray;
  --grayscale-white-color-darken-8percent: #cccccc;
  --grayscale-white-color-lighten-2percent: #e5e5e5;
  --grayscale-white-color-lighten-5percent: #ededed;
  --white-color: #ffffff;
  --black-color: #0e0e0e;
  --secondary-dark-color: #007299;
  --secondary-color: #77256c;
  --secondary-color2: #81bd41;
  --secondary-color3: #ffd240;
  --secondary-color3-darken-3percent: #ffce31;
  --secondary-color3-lighten-17percent: #ffe697;
  --secondary-color3-lighten-27percent: #fff2ca;
  --secondary-bright-color: #ece815;
  --error-color: #dd3435;
  --error-color-darken-10percent: #be2021;
  --error-color-lighten-50percent: white;
  --error-color-lighten-55percent: white;
  --error-light-color: #eabec0;
  --error-light-color-darken-5percent: #e4abad;
  --disabled-color: #c9c9c9;
  --disabled-color2: #c9c9c9;
  --disabled-color3: #dd9e73;
  --default-sprite-position: bottom left;
  --active-hover-sprite-position: bottom right;
  /*remaing colors*/
  --snow-gray: #fcfcfc;
  --light-gainsboro-gray: #f7f7f7;
  --white-smoke: #f4f4f4;
  --very-light-gray: #f0f0f0;
  --light-pink: #febbbb;
  --light-yellow: #ffffe5;
  --orange-peel: #ff9900;
  --electric-yellow: #ffff00;
  --yellow-green: #effadb;
  --whisper-gray: #ededed;
  --dark-whisper-gray: #eeeeee;
  --white-dark-smoke: #e6e6e6;
  --gainsboro-gray: #e0e0e0;
  --medium-gray: #cccccc;
  --silver: #c6c6c6;
  --silver-very-light: #b9b9b9;
  --silver-light: #bbbbbb;
  --blue-smoke: #eef1f7;
  --ivory-red: #f8f8f2;
  --spun-pearl: #afafb6;
  --very-light-blue: #e6ebf5;
  --dark-gainsboro-gray: #d9d9d9;
  --bright-gainsboro-gray: #dddddd;
  --hawakes-blue: #ccd6eb;
  --blue-violet: #9933ff;
  --nobel-gray: #999999;
  --light-danube-blue: #89a4cd;
  --matte-gray: #888888;
  --light-matte-gray: #787878;
  --dark-gray: #777777;
  --raven-gray: #707275;
  --danube-blue: #6685c2;
  --dim-gray: #666666;
  --davy-grey: #555555;
  --analog-blue: #435f8b;
  --charcoal-gray: #444444;
  --mariner-blue: #335cad;
  --dark-charcoal-gray: #333333;
  --green-waterloo: #23241f;
  --nero-dark: #222222;
  --smalt-blue: #003399;
  --deep-green: #008a00;
  --navy-blue: #0066cc;
  --bondi-blue: #0085c7;
  --violation-text: #cc0000;
  --violation-bg: #ffe6e6;
  --warning-text: #f68d29;
  --warning-bg: #fff2ca;
  --highcharts-color-2: #ffa366;
  --highcharts-color-3: #777777;
  --highcharts-color-6: #e65c00;
  --highcharts-color-7: #e0e0e0;
}

/*Sprite Positions*/
/*Images*/
/*Primary*/
/*Grayscale*/
/*Secondary*/
/*disabled*/
/*remaing colors*/
.darkblue {
  color: var(--primary-dark-color);
}

.blue {
  color: var(--primary-color);
}

.lightblue {
  color: var(--primary-light1-color);
}

.whiteblue {
  color: var(--primary-white-color);
}

.yellowgreen {
  color: var(--primary-color2);
}

.green {
  color: var(--primary-color3) !important;
}

.darkgreen {
  color: var(--primary-dark2-color);
}

.lightgreen {
  color: var(--primary-light2-color);
}

.gray {
  color: var(--grayscale-color);
}

.lightgray {
  color: var(--grayscale-light-color);
}

.whitegray {
  color: var(--grayscale-white-color);
}

.darkcyan {
  color: var(--secondary-dark-color);
}

.purple {
  color: var(--secondary-color);
}

.orange {
  color: var(--secondary-color2) !important;
}

.yellow {
  color: var(--secondary-color3);
}

.brightyellow {
  color: var(--secondary-bright-color);
}

.red {
  color: var(--error-color) !important;
}

.lightred {
  color: var(--error-light-color);
}

.white {
  color: var(--white-color) !important;
}

.darkblue-bg {
  background-color: var(--primary-dark-color);
}

.blue-bg {
  background-color: var(--primary-color) !important;
}

.lightblue-bg {
  background-color: var(--primary-light1-color);
}

.whiteblue-bg {
  background-color: var(--primary-white-color);
}

.yellowgreen-bg {
  background-color: var(--primary-color2);
}

.green-bg {
  background-color: var(--primary-color3);
}

.darkgreen-bg {
  background-color: var(--primary-dark2-color);
}

.lightgreen-bg {
  background-color: var(--primary-light2-color);
}

.gray-bg {
  background-color: var(--grayscale-color);
}

.lightgray-bg {
  background-color: var(--grayscale-light-color);
}

.whitegray-bg {
  background-color: var(--grayscale-white-color);
}

.darkcyan-bg {
  background-color: var(--secondary-dark-color);
}

.purple-bg {
  background-color: var(--secondary-color);
}

.orange-bg {
  background-color: var(--secondary-color2) !important;
}

.yellow-bg {
  background-color: var(--secondary-color3) !important;
}

.brightyellow-bg {
  background-color: var(--secondary-bright-color) !important;
}

.red-bg {
  background-color: var(--error-color) !important;
}

.lightred-bg {
  background-color: var(--error-light-color) !important;
}

chart {
  display: block;
}

.highcharts-container {
  position: relative;
  overflow: visible;
  width: 100%;
  height: 100%;
  text-align: left;
  line-height: normal;
  z-index: 0;
  /* #1072 */
  -webkit-tap-highlight-color: transparent;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 0.875rem;
  background: linear-gradient(var(--white-color), var(--white-color), var(--grayscale-white-color));
  border: 1px solid var(--primary-light1-color);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  border-radius: 4px;
}

.highcharts-root text {
  stroke-width: 0;
}

.highcharts-background {
  fill: transparent;
}

.highcharts-plot-border, .highcharts-plot-background {
  fill: none;
}

.highcharts-label-box {
  fill: none;
}

.highcharts-button-box {
  fill: inherit;
}

/* Gradients 
#bg-gradient stop {
	stop-color: $whitegray;
}
#bg-gradient stop[offset="0"] {
	stop-opacity: 0.75;
}
#bg-gradient stop[offset="1"] {
	stop-opacity: 0;
}
*/
/* Titles */
.highcharts-title {
  fill: var(--primary-color);
  font-size: 1.4rem;
}

.highcharts-subtitle {
  fill: var(--grayscale-color);
  font-size: 1.09375rem;
}

/* Axes */
.highcharts-axis-line {
  fill: none;
  stroke: var(--grayscale-light-color);
}

.highcharts-yaxis .highcharts-axis-line {
  stroke-width: 0;
}

.highcharts-axis-title {
  fill: var(--grayscale-color);
}

.highcharts-axis-labels {
  fill: var(--grayscale-color);
  cursor: default;
}

.highcharts-grid-line {
  fill: none;
  stroke: var(--grayscale-light-color);
}

.highcharts-xaxis-grid .highcharts-grid-line {
  stroke-width: 0;
}

.highcharts-tick {
  stroke: var(--grayscale-light-color);
}

.highcharts-yaxis .highcharts-tick {
  stroke-width: 0;
}

.highcharts-minor-grid-line {
  stroke: var(--grayscale-white-color);
}

.highcharts-crosshair-thin {
  stroke-width: 1px;
  stroke: var(--grayscale-light-color);
}

.highcharts-crosshair-category {
  stroke: var(--primary-white-color);
  stroke-opacity: 0.25;
}

/* Credits */
.highcharts-credits {
  cursor: none;
  fill: var(--white-color);
  font-size: 1px;
  transition: fill 250ms, font-size 250ms;
}

.highcharts-credits:hover {
  fill: var(--white-color);
  font-size: 1px;
}

/* Tooltip */
.highcharts-tooltip {
  cursor: default;
  pointer-events: none;
  white-space: nowrap;
  transition: stroke 150ms;
  filter: none !important;
}
.highcharts-tooltip hr {
  margin-bottom: 10px;
}

.highcharts-tooltip text {
  fill: var(--grayscale-color);
}

.highcharts-tooltip-box {
  stroke: var(--grayscale-light-color);
  stroke-width: 1px;
  fill: var(--white-color);
  fill-opacity: 1;
}

.highcharts-selection-marker {
  fill: var(--primary-color3);
  fill-opacity: 0.25;
}

.highcharts-graph {
  fill: none;
  stroke-width: 2px;
  stroke-linecap: round;
  stroke-linejoin: round;
}

.highcharts-state-hover .highcharts-graph {
  stroke-width: 3;
}

.highcharts-state-hover path {
  transition: stroke-width 50;
  /* quick in */
}

.highcharts-state-normal path {
  transition: stroke-width 250ms;
  /* slow out */
}

/* Legend hover affects points and series */
g.highcharts-series, .highcharts-point {
  transition: opacity 250ms;
}

.highcharts-legend-series-active g.highcharts-series:not(.highcharts-series-hover),
.highcharts-legend-point-active .highcharts-point:not(.highcharts-point-hover) {
  opacity: 0.2;
}

/* Series options */
/* Default colors */
.highcharts-color-0,
.hc-darkblue,
.hc-darkblue .highcharts-point {
  fill: var(--primary-dark-color);
  stroke: var(--primary-dark-color);
}

.highcharts-color-1,
.hc-green,
.hc-green .highcharts-point {
  fill: var(--primary-color3);
  stroke: var(--primary-color3);
}

.highcharts-color-2,
.hc-yellowgreen,
.hc-yellowgreen .highcharts-point {
  fill: var(--highcharts-color-2);
  stroke: var(--highcharts-color-2);
}

.highcharts-color-3,
.hc-darkcyan,
.hc-darkcyan .highcharts-point {
  fill: var(--highcharts-color-3);
  stroke: var(--highcharts-color-3);
}

.highcharts-color-4,
.hc-purple,
.hc-purple .highcharts-point {
  fill: var(--secondary-color);
  stroke: var(--secondary-color);
}

.highcharts-color-5,
.hc-orange,
.hc-orange .highcharts-point {
  fill: var(--secondary-color2);
  stroke: var(--secondary-color2);
}

.highcharts-color-6,
.hc-darkgreen,
.hc-darkgreen .highcharts-point {
  fill: var(--primary-dark2-color);
  stroke: var(--primary-dark2-color);
}

.highcharts-color-7,
.hc-lightblue,
.hc-lightblue .highcharts-point {
  fill: var(--highcharts-color-7);
  stroke: var(--highcharts-color-7);
}

.highcharts-color-8,
.hc-yellow,
.hc-yellow .highcharts-point {
  fill: var(--secondary-color3);
  stroke: var(--secondary-color3);
}

.highcharts-color-9,
.hc-blue,
.hc-blue .highcharts-point {
  fill: var(--primary-color);
  stroke: var(--primary-color);
}

.hc-red,
.hc-red .highcharts-point {
  fill: var(--error-color);
  stroke: var(--error-color);
}

.highcharts-area {
  fill-opacity: 0.75;
  stroke-width: 0;
}

.highcharts-markers {
  stroke-width: 1px;
  stroke: var(--white-color);
}

.highcharts-point {
  stroke-width: 1px;
}

.highcharts-dense-data .highcharts-point {
  stroke-width: 0;
}

.highcharts-data-label {
  font-weight: normal;
  font-size: 0.75rem;
}

.highcharts-data-label-box {
  fill: none;
  stroke-width: 0;
}

.highcharts-data-label text {
  fill: var(--grayscale-color);
}

.highcharts-data-label-connector {
  fill: none;
}

.highcharts-halo {
  fill-opacity: 0.25;
  stroke-width: 0;
}

.highcharts-point-select {
  fill: var(--grayscale-light-color);
  stroke: var(--black-color);
}

.highcharts-column-series .highcharts-point {
  stroke: var(--white-color);
  transition: fill-opacity 250ms;
}

.highcharts-column-series .highcharts-point-hover {
  fill-opacity: 0.75;
  transition: fill-opacity 50ms;
}

.highcharts-pie-series .highcharts-point {
  stroke-linejoin: round;
  stroke: var(--white-color);
}

.highcharts-pie-series .highcharts-point-hover {
  fill-opacity: 0.75;
  transition: fill-opacity 50ms;
}

.highcharts-pie-series .highcharts-point-select {
  fill: inherit;
  stroke: inherit;
}

.highcharts-funnel-series .highcharts-point {
  stroke-linejoin: round;
  stroke: var(--white-color);
}

.highcharts-funnel-series .highcharts-point-hover {
  fill-opacity: 0.75;
  transition: fill-opacity 50ms;
}

.highcharts-funnel-series .highcharts-point-select {
  fill: inherit;
  stroke: inherit;
}

.highcharts-pyramid-series .highcharts-point {
  stroke-linejoin: round;
  stroke: var(--white-color);
}

.highcharts-pyramid-series .highcharts-point-hover {
  fill-opacity: 0.75;
  transition: fill-opacity 50ms;
}

.highcharts-pyramid-series .highcharts-point-select {
  fill: inherit;
  stroke: inherit;
}

.highcharts-solidgauge-series .highcharts-point {
  stroke-width: 0;
}

.highcharts-treemap-series .highcharts-point {
  stroke-width: 1px;
  stroke: var(--grayscale-white-color);
  transition: stroke 250ms, fill 250ms, fill-opacity 250ms;
}

.highcharts-treemap-series .highcharts-point-hover {
  stroke: var(--grayscale-color);
  transition: stroke 25ms, fill 25ms, fill-opacity 25ms;
}

.highcharts-treemap-series .highcharts-above-level {
  display: none;
}

.highcharts-treemap-series .highcharts-internal-node {
  fill: none;
}

.highcharts-treemap-series .highcharts-internal-node-interactive {
  fill-opacity: 0.15;
  cursor: pointer;
}

.highcharts-treemap-series .highcharts-internal-node-interactive:hover {
  fill-opacity: 0.75;
}

/* Legend */
.highcharts-legend-box {
  fill: var(--white-color);
  stroke-width: 1px;
  stroke: var(--grayscale-light-color);
}

.highcharts-legend-item text {
  fill: var(--grayscale-color);
  font-weight: bold;
  cursor: pointer;
  stroke-width: 0;
}

.highcharts-legend-item:hover text {
  fill: var(--grayscale-color);
}

.highcharts-legend-item-hidden * {
  fill: var(--medium-gray) !important;
  stroke: var(--medium-gray) !important;
  transition: fill 250ms;
}

.highcharts-legend-nav-active {
  fill: var(--primary-dark-color);
  cursor: pointer;
}

.highcharts-legend-nav-inactive {
  fill: var(--grayscale-light-color);
}

.highcharts-legend-title-box {
  fill: none;
  stroke-width: 0;
}

/* Loading */
.highcharts-loading {
  position: absolute;
  background-color: var(--white-color);
  opacity: 0.5;
  text-align: center;
  z-index: 10;
  transition: opacity 250ms;
}

.highcharts-loading-hidden {
  height: 0 !important;
  opacity: 0;
  overflow: hidden;
  transition: opacity 250ms, height 250ms step-end;
}

.highcharts-loading-inner {
  font-weight: bold;
  position: relative;
  top: 45%;
}

/* Plot bands and polar pane backgrounds */
.highcharts-plot-band {
  fill: var(--primary-light2-color);
  fill-opacity: 1;
}

.highcharts-plot-line {
  fill: none;
  stroke: var(--nobel-gray);
  stroke-width: 1px;
}

/* Highcharts More */
.highcharts-boxplot-box {
  fill: var(--white-color);
}

.highcharts-boxplot-median {
  stroke-width: 2px;
}

.highcharts-bubble-series .highcharts-point {
  fill-opacity: 0.5;
}

.highcharts-errorbar-series .highcharts-point {
  stroke: var(--black-color);
}

.highcharts-gauge-series .highcharts-data-label-box {
  stroke: var(--medium-gray);
  stroke-width: 1px;
}

.highcharts-gauge-series .highcharts-dial {
  fill: var(--black-color);
  stroke-width: 0;
}

.highcharts-polygon-series .highcharts-graph {
  fill: inherit;
  stroke-width: 0;
}

.highcharts-waterfall-series .highcharts-graph {
  stroke: var(--dark-charcoal-gray);
  stroke-dasharray: 1, 3;
}

/* Highstock */
.highcharts-navigator-mask {
  fill: var(--danube-blue);
  /* navigator.maskFill option */
  fill-opacity: 0.25;
}

.highcharts-navigator-mask-inside {
  fill: var(--danube-blue);
  /* navigator.maskFill option */
  fill-opacity: 0.25;
  cursor: ew-resize;
}

.highcharts-navigator-outline {
  stroke: var(--medium-gray);
  fill: none;
}

.highcharts-navigator-handle {
  stroke: var(--medium-gray);
  fill: var(--white-smoke);
  cursor: ew-resize;
}

.highcharts-navigator-series {
  fill: var(--mariner-blue);
  stroke: var(--mariner-blue);
}

.highcharts-navigator-series .highcharts-graph {
  stroke-width: 1px;
}

.highcharts-navigator-series .highcharts-area {
  fill-opacity: 0.05;
}

.highcharts-navigator-xaxis .highcharts-axis-line {
  stroke-width: 0;
}

.highcharts-navigator-xaxis .highcharts-grid-line {
  stroke-width: 1px;
  stroke: var(--white-dark-smoke);
}

.highcharts-navigator-xaxis.highcharts-axis-labels {
  fill: var(--nobel-gray);
}

.highcharts-navigator-yaxis .highcharts-grid-line {
  stroke-width: 0;
}

.highcharts-scrollbar-thumb {
  fill: var(--medium-gray);
  stroke: var(--medium-gray);
  stroke-width: 1px;
}

.highcharts-scrollbar-button {
  fill: var(--white-dark-smoke);
  stroke: var(--medium-gray);
  stroke-width: 1px;
}

.highcharts-scrollbar-arrow {
  fill: var(--dim-gray);
}

.highcharts-scrollbar-rifles {
  stroke: var(--dim-gray);
  stroke-width: 1px;
}

.highcharts-scrollbar-track {
  fill: var(--white-smoke);
  stroke: var(--white-smoke);
  stroke-width: 1px;
}

.highcharts-button {
  fill: var(--light-gainsboro-gray);
  stroke: var(--medium-gray);
  cursor: default;
  stroke-width: 1px;
  transition: fill 250ms;
}

.highcharts-button text {
  fill: var(--dark-charcoal-gray);
}

.highcharts-button-hover {
  transition: fill 0ms;
  fill: var(--white-dark-smoke);
  stroke: var(--dark-charcoal-gray);
}

.highcharts-button-pressed {
  font-weight: bold;
  fill: var(--very-light-blue);
  stroke: var(--mariner-blue);
}

.highcharts-button-disabled text {
  fill: var(--medium-gray);
}

.highcharts-range-selector-buttons .highcharts-button {
  stroke-width: 0;
}

.highcharts-range-label rect {
  fill: none;
}

.highcharts-range-label text {
  fill: var(--dim-gray);
}

.highcharts-range-input rect {
  fill: none;
}

.highcharts-range-input text {
  fill: var(--dark-charcoal-gray);
}

input.highcharts-range-selector {
  position: absolute;
  border: 0;
  width: 1px;
  /* Chrome needs a pixel to see it */
  height: 1px;
  padding: 0;
  text-align: center;
  left: -9em;
  /* #4798 */
}

.highcharts-crosshair-label text {
  fill: var(--white-color);
}

.highcharts-crosshair-label .highcharts-label-box {
  fill: inherit;
}

.highcharts-candlestick-series .highcharts-point {
  stroke: var(--black-color);
  stroke-width: 1px;
}

.highcharts-candlestick-series .highcharts-point-up {
  fill: var(--white-color);
}

.highcharts-ohlc-series .highcharts-point-hover {
  stroke-width: 3px;
}

.highcharts-flags-series .highcharts-point {
  stroke: var(--nobel-gray);
  fill: var(--white-color);
}

.highcharts-flags-series .highcharts-point-hover {
  stroke: var(--black-color);
  fill: var(--hawakes-blue);
}

.highcharts-flags-series .highcharts-point text {
  fill: var(--black-color);
  font-weight: bold;
}

/* Highmaps */
.highcharts-map-series .highcharts-point {
  transition: fill 500ms, fill-opacity 500ms, stroke-width 250ms;
  stroke: var(--medium-gray);
}

.highcharts-map-series .highcharts-point-hover {
  transition: fill 0ms, fill-opacity 0ms;
  fill-opacity: 0.5;
  stroke-width: 2px;
}

.highcharts-mapline-series .highcharts-point {
  fill: none;
}

.highcharts-heatmap-series .highcharts-point {
  stroke-width: 0;
}

.highcharts-map-navigation {
  font-weight: bold;
  text-align: center;
}

.highcharts-coloraxis {
  stroke-width: 0;
}

.highcharts-coloraxis-marker {
  fill: var(--nobel-gray);
}

.highcharts-null-point {
  fill: var(--light-gainsboro-gray);
}

/* 3d charts */
.highcharts-3d-frame {
  fill: transparent;
}

.highcharts-column-series .highcharts-point {
  stroke: inherit;
  /* use point color */
}

/* Exporting module */
.highcharts-contextbutton {
  fill: var(--white-color);
  /* needed to capture hover */
  stroke: none;
  stroke-linecap: round;
}

.highcharts-contextbutton:hover {
  fill: var(--white-dark-smoke);
  stroke: var(--white-dark-smoke);
}

.highcharts-button-symbol {
  stroke: var(--dim-gray);
  stroke-width: 3px;
}

.highcharts-menu {
  border: 1px solid var(--nobel-gray);
  background: var(--white-color);
  padding: 5px 0;
  box-shadow: 3px 3px 10px var(--matte-gray);
}

.highcharts-menu-item {
  padding: 0.5em 1em;
  background: none;
  color: var(--dark-charcoal-gray);
  cursor: pointer;
  transition: background 250ms, color 250ms;
}

.highcharts-menu-item:hover {
  background: var(--mariner-blue);
  color: var(--white-color);
}

/* Drilldown module */
.highcharts-drilldown-point {
  cursor: pointer;
}

.highcharts-drilldown-data-label text, .highcharts-drilldown-axis-label {
  cursor: pointer;
  fill: var(--smalt-blue);
  font-weight: bold;
  text-decoration: underline;
}

/* No-data module */
.highcharts-no-data text {
  font-weight: bold;
  fill: var(--dim-gray);
}

highcharts-chart {
  width: 96%;
  margin: auto;
  display: block;
  overflow: visible !important;
}

/* Old Colors

$color0: #ededed; // white gray
$color1: rgb(190,190,190); // #bebebe - light gray
$color2: rgb(80,80,80); // #505050 - gray
$color3: rgb(207,214,224); // #cfd6e0 - white blue
$color4: rgb(159,172,193); // #9facc1 - light blue
$color5: rgb(88,111,146); // #586f92 - mid blue
$color6: rgb(15,48,99); // #1B3D6D - dark blue
$color7: rgb(57,213,31); // #39d51f - green
$color8: rgb(52,197,26); // #34C51A - dark green
*/
/*Theming*/
:root {
  --loading-login-screen-background:
          linear-gradient(to right, rgba(15, 48, 99, 0) 0%, rgba(75, 107, 155, .4) 25%, rgba(168, 187, 215, .4) 42%, rgba(168, 187, 215, .4) 58%, rgba(75, 107, 155, .4) 75%, rgba(15, 48, 99, 0) 100%),
          url(".././assets/images/pattern-block-lines.png") repeat;
  --loading-screen-logo: url(".././assets/images/bakerhill-nextgen-logo-white.png");
  --login-box-logo: url(".././assets/images/bakerhill-nextgen-logo.png");
  --primary-dark-color: #1B3D6D;
  --primary-color: #456aa1;
  --primary-color-darken-7percent: #3a5a88;
  --primary-color-darken-11percent: #34507a;
  --primary-color-lighten-10percent: #5f84ba;
  --primary-color-lighten-20percent: #829fca;
  --primary-color-lighten-40percent: #cad6e8;
  --primary-light1-color: #a6bad9;
  --primary-light1-color-darken-5percent:#94acd1;
  --primary-light1-color-darken-10percent: #829eca;
  --primary-light1-color-darken-15percent: #7090c2;
  --primary-light1-color-lighten-6percent: #bbcbe2;
  --primary-white-color: #e6ecf2;
  --primary-white-color-darken-2percent: #dfe7ef;
  --primary-white-color-darken-6percent: #d2dde8;
  --primary-white-color-lighten-2percent: #edf1f5;
  --primary-white-color-lighten-4percent: #f3f6f9;
  --primary-color2: #cddc29;
  --primary-color3: #81BD41;
  --primary-color3-lighten-40percent: #e5f2d8;
  --primary-color3-darken-5percent: #74aa3a;
  --primary-dark2-color: #689834;
  --primary-dark2-color-darken-10percent: #4e7227;
  --primary-light2-color: #e6f2d9;
  --primary-light2-color-darken-2percent: #e1efd1;
  --primary-light2-color-darken-6percent: #d7eac2;
  --primary-light2-color-darken-10percent: #cde5b3;
  --grayscale-color: #58595B;
  --grayscale-color-lighten-25percent: #98999b;
  --grayscale-light-color: #bdbdc2;
  --grayscale-light-color-darken-8percent: #a8a8ae;
  --grayscale-light-color-lighten-7percent: #d0d0d3;
  --grayscale-white-color: #ebebed;
  --grayscale-white-color-darken-5percent: #dedee1;
  --grayscale-white-color-darken-8percent: #d6d6da;
  --grayscale-white-color-lighten-2percent: #f0f0f2;
  --grayscale-white-color-lighten-5percent: #f8f8f9;
  --white-color: #ffffff;
  --black-color: #000000;
  --secondary-dark-color: #007299;
  --secondary-color: #77256c;
  --secondary-color2: #f68d29;
  --secondary-color3: #ffd240;
  --secondary-color3-darken-3percent: #ffce31;
  --secondary-color3-lighten-17percent: #ffe697;
  --secondary-color3-lighten-27percent: #fff2ca;
  --secondary-bright-color: #ece815;
  --error-color: #cc0000;
  --error-color-darken-10percent: #990000;
  --error-color-lighten-50percent: #ffcccc;
  --error-color-lighten-55percent: #ffe6e6;
  --error-light-color: #ffe6e6;
  --error-light-color-darken-5percent: #ffcdcd;
  --disabled-color: #abbcd5;
  --disabled-color2: #9badca;
  --disabled-color3: #a7d17a;
  --default-sprite-position: top left;
  --active-hover-sprite-position: top right;
  /*remaing colors*/
  --snow-gray: #fcfcfc;
  --light-gainsboro-gray: #f7f7f7;
  --white-smoke: #f4f4f4;
  --very-light-gray: #f0f0f0;
  --light-pink: #febbbb;
  --light-yellow: #ffffe5;
  --orange-peel: #ff9900;
  --electric-yellow: #ffff00;
  --yellow-green: #effadb;
  --whisper-gray: #ededed;
  --dark-whisper-gray: #eeeeee;
  --white-dark-smoke: #e6e6e6;
  --gainsboro-gray: #e0e0e0;
  --medium-gray: #cccccc;
  --silver: #c6c6c6;
  --silver-very-light: #b9b9b9;
  --silver-light: #bbbbbb;
  --blue-smoke: #eef1f7;
  --ivory-red: #f8f8f2;
  --spun-pearl: #afafb6;
  --very-light-blue: #e6ebf5;
  --dark-gainsboro-gray: #d9d9d9;
  --bright-gainsboro-gray: #dddddd;
  --hawakes-blue: #ccd6eb;
  --blue-violet: #9933ff;
  --nobel-gray: #999999;
  --light-danube-blue: #89a4cd;
  --matte-gray: #888888;
  --light-matte-gray: #787878;
  --dark-gray: #777777;
  --raven-gray: #707275;
  --danube-blue: #6685c2;
  --dim-gray: #666666;
  --davy-grey: #555555;
  --analog-blue: #435f8b;
  --charcoal-gray: #444444;
  --mariner-blue: #335cad;
  --dark-charcoal-gray: #333333;
  --green-waterloo: #23241f;
  --nero-dark: #222222;
  --smalt-blue: #003399;
  --deep-green: #008a00;
  --navy-blue: #0066cc;
  --bondi-blue: #0085c7;
  --violation-text: #cc0000;
  --violation-bg: #ffe6e6;
  --warning-text: #f68d29;
  --warning-bg: #fff2ca;
  --highcharts-color-2: #cddc29;
  --highcharts-color-3: #007299;
  --highcharts-color-6: #689834;
  --highcharts-color-7: #a6bad9;
}

[data-theme=fiserv] {
  --loading-login-screen-background: #666666;
  --loading-screen-logo: url(".././assets/images/Fiserv-logo-1200x603.png");
  --login-box-logo: url(".././assets/images/Fiserv-logo-1200x603.png");
  --primary-dark-color: #333333;
  --primary-color: #666666;
  --primary-color-darken-7percent: #545454;
  --primary-color-darken-11percent: #4a4a4a;
  --primary-color-lighten-10percent: gray;
  --primary-color-lighten-20percent: #999999;
  --primary-color-lighten-40percent: #cccccc;
  --primary-light1-color: #bfbfbf;
  --primary-light1-color-darken-5percent: #b2b2b2;
  --primary-light1-color-darken-10percent: #a6a6a6;
  --primary-light1-color-darken-15percent: #999999;
  --primary-light1-color-lighten-6percent: #cecece;
  --primary-white-color: #efefef;
  --primary-white-color-darken-2percent: #eaeaea;
  --primary-white-color-darken-6percent: #e0e0e0;
  --primary-white-color-lighten-2percent: #f4f4f4;
  --primary-white-color-lighten-4percent: #f9f9f9;
  --primary-color2: #4695d1;
  --primary-color3: #ff6600;
  --primary-color3-lighten-40percent: #ffe0cc;
  --primary-color3-darken-5percent: #e65c00;
  --primary-dark2-color: #ea6b15;
  --primary-dark2-color-darken-10percent: #bb5611;
  --primary-light2-color: #f2d3c4;
  --primary-light2-color-darken-2percent: #f0cdbc;
  --primary-light2-color-darken-6percent: #ecc0ab;
  --primary-light2-color-darken-10percent: #e9b49a;
  --grayscale-color: #666666;
  --grayscale-color-lighten-25percent: #a6a6a6;
  --grayscale-light-color: #e0e0e0;
  --grayscale-light-color-darken-8percent: #cccccc;
  --grayscale-light-color-lighten-7percent: #f2f2f2;
  --grayscale-white-color: #e0e0e0;
  --grayscale-white-color-darken-5percent: lightgray;
  --grayscale-white-color-darken-8percent: #cccccc;
  --grayscale-white-color-lighten-2percent: #e5e5e5;
  --grayscale-white-color-lighten-5percent: #ededed;
  --white-color: #ffffff;
  --black-color: #0e0e0e;
  --secondary-dark-color: #007299;
  --secondary-color: #77256c;
  --secondary-color2: #81bd41;
  --secondary-color3: #ffd240;
  --secondary-color3-darken-3percent: #ffce31;
  --secondary-color3-lighten-17percent: #ffe697;
  --secondary-color3-lighten-27percent: #fff2ca;
  --secondary-bright-color: #ece815;
  --error-color: #dd3435;
  --error-color-darken-10percent: #be2021;
  --error-color-lighten-50percent: white;
  --error-color-lighten-55percent: white;
  --error-light-color: #eabec0;
  --error-light-color-darken-5percent: #e4abad;
  --disabled-color: #c9c9c9;
  --disabled-color2: #c9c9c9;
  --disabled-color3: #dd9e73;
  --default-sprite-position: bottom left;
  --active-hover-sprite-position: bottom right;
  /*remaing colors*/
  --snow-gray: #fcfcfc;
  --light-gainsboro-gray: #f7f7f7;
  --white-smoke: #f4f4f4;
  --very-light-gray: #f0f0f0;
  --light-pink: #febbbb;
  --light-yellow: #ffffe5;
  --orange-peel: #ff9900;
  --electric-yellow: #ffff00;
  --yellow-green: #effadb;
  --whisper-gray: #ededed;
  --dark-whisper-gray: #eeeeee;
  --white-dark-smoke: #e6e6e6;
  --gainsboro-gray: #e0e0e0;
  --medium-gray: #cccccc;
  --silver: #c6c6c6;
  --silver-very-light: #b9b9b9;
  --silver-light: #bbbbbb;
  --blue-smoke: #eef1f7;
  --ivory-red: #f8f8f2;
  --spun-pearl: #afafb6;
  --very-light-blue: #e6ebf5;
  --dark-gainsboro-gray: #d9d9d9;
  --bright-gainsboro-gray: #dddddd;
  --hawakes-blue: #ccd6eb;
  --blue-violet: #9933ff;
  --nobel-gray: #999999;
  --light-danube-blue: #89a4cd;
  --matte-gray: #888888;
  --light-matte-gray: #787878;
  --dark-gray: #777777;
  --raven-gray: #707275;
  --danube-blue: #6685c2;
  --dim-gray: #666666;
  --davy-grey: #555555;
  --analog-blue: #435f8b;
  --charcoal-gray: #444444;
  --mariner-blue: #335cad;
  --dark-charcoal-gray: #333333;
  --green-waterloo: #23241f;
  --nero-dark: #222222;
  --smalt-blue: #003399;
  --deep-green: #008a00;
  --navy-blue: #0066cc;
  --bondi-blue: #0085c7;
  --violation-text: #cc0000;
  --violation-bg: #ffe6e6;
  --warning-text: #f68d29;
  --warning-bg: #fff2ca;
  --highcharts-color-2: #ffa366;
  --highcharts-color-3: #777777;
  --highcharts-color-6: #e65c00;
  --highcharts-color-7: #e0e0e0;
}

/*Sprite Positions*/
/*Images*/
/*Primary*/
/*Grayscale*/
/*Secondary*/
/*disabled*/
/*remaing colors*/
.darkblue {
  color: var(--primary-dark-color);
}

.blue {
  color: var(--primary-color);
}

.lightblue {
  color: var(--primary-light1-color);
}

.whiteblue {
  color: var(--primary-white-color);
}

.yellowgreen {
  color: var(--primary-color2);
}

.green {
  color: var(--primary-color3) !important;
}

.darkgreen {
  color: var(--primary-dark2-color);
}

.lightgreen {
  color: var(--primary-light2-color);
}

.gray {
  color: var(--grayscale-color);
}

.lightgray {
  color: var(--grayscale-light-color);
}

.whitegray {
  color: var(--grayscale-white-color);
}

.darkcyan {
  color: var(--secondary-dark-color);
}

.purple {
  color: var(--secondary-color);
}

.orange {
  color: var(--secondary-color2) !important;
}

.yellow {
  color: var(--secondary-color3);
}

.brightyellow {
  color: var(--secondary-bright-color);
}

.red {
  color: var(--error-color) !important;
}

.lightred {
  color: var(--error-light-color);
}

.white {
  color: var(--white-color) !important;
}

.darkblue-bg {
  background-color: var(--primary-dark-color);
}

.blue-bg {
  background-color: var(--primary-color) !important;
}

.lightblue-bg {
  background-color: var(--primary-light1-color);
}

.whiteblue-bg {
  background-color: var(--primary-white-color);
}

.yellowgreen-bg {
  background-color: var(--primary-color2);
}

.green-bg {
  background-color: var(--primary-color3);
}

.darkgreen-bg {
  background-color: var(--primary-dark2-color);
}

.lightgreen-bg {
  background-color: var(--primary-light2-color);
}

.gray-bg {
  background-color: var(--grayscale-color);
}

.lightgray-bg {
  background-color: var(--grayscale-light-color);
}

.whitegray-bg {
  background-color: var(--grayscale-white-color);
}

.darkcyan-bg {
  background-color: var(--secondary-dark-color);
}

.purple-bg {
  background-color: var(--secondary-color);
}

.orange-bg {
  background-color: var(--secondary-color2) !important;
}

.yellow-bg {
  background-color: var(--secondary-color3) !important;
}

.brightyellow-bg {
  background-color: var(--secondary-bright-color) !important;
}

.red-bg {
  background-color: var(--error-color) !important;
}

.lightred-bg {
  background-color: var(--error-light-color) !important;
}

.lending-icon-container {
  display: flex;
  flex-wrap: wrap;
}
.lending-icon-container .lending-icons {
  display: flex;
  flex-direction: column;
  padding: 0;
  cursor: pointer;
}
.lending-icon-container .lending-icon-btns {
  padding-right: 25px;
}
.lending-icon-container .lending-icon-btns .btn {
  width: 100%;
  min-width: 95px;
  padding: 7px 5px;
  margin-bottom: 10px;
  font-size: 15px;
}
.lending-icon-container .lending-icon-btns .btn.btn-2 {
  padding: 12px 5px;
}
.lending-icon-container .lending-icon-btns .btn.btn-1 {
  padding: 14px 5px;
  margin-top: 18px;
}
.lending-icon-container .lending-icon-btns .btn i {
  margin-right: 3px;
}
.lending-icon-container .lending-icon-btns .btn span:not(.process-time) {
  font-weight: bold;
}
.lending-icon-container .lending-icon-btns .dropdown-menu .btn {
  margin-bottom: 0;
  padding-top: 0;
  padding-bottom: 0;
}
.lending-icon-container .lending-icon-btns .process-time {
  font-size: 11px;
  display: block;
  position: relative;
  bottom: -2px;
  margin-top: 2px;
}
.lending-icon-container .lending-icon-btns .dropdown {
  display: block;
}
.lending-icon-container .lending-icon-btns .dropdown .dropdown-menu {
  margin-top: -10px;
}
.lending-icon-container .lending-icon-btns .dropdown .dropdown-menu .btn .dropdown-button-styles {
  font-size: 0.875rem;
}
.lending-icon-container .lending-icon-btns .dropdown .dropdown-menu .btn:hover, .lending-icon-container .lending-icon-btns .dropdown .dropdown-menu .btn:focus {
  background-color: var(--grayscale-white-color);
}

.lending-icon-bar {
  width: 100%;
  height: 26px;
  background-color: var(--primary-color);
  position: absolute;
  top: 28px;
  left: 0;
}

.lending-icon-container div:nth-child(2) .lending-icon-bar {
  left: 50%;
  width: 50%;
}

.lending-icon-container div:last-child .lending-icon-bar {
  width: 50%;
}

@media (min-width: 1200px) and (max-width: 1599px) {
  .lending-icon-container .lending-icon-btns {
    padding: 0 15px 0 0;
  }
  .lending-icon-container .lending-icon-btns .btn {
    font-size: 14px;
    padding: 7px 3px;
  }
  .lending-icon-container .lending-icon-btns .btn i {
    font-size: 13px;
    vertical-align: 0;
  }
}
@media (min-width: 1200px) and (max-width: 1400px) {
  .lending-icon-container .lending-icon-btns {
    padding: 0;
  }
}
.lending-icon,
.lending-icon-visited,
.lending-icon-active {
  background-color: var(--grayscale-white-color);
  border-radius: 50%;
  width: 80px;
  height: 80px;
  position: relative;
  margin: 0 auto;
  z-index: 98;
  display: flex;
  align-items: center;
  justify-content: center;
}
.lending-icon .lending-icon-inner,
.lending-icon-visited .lending-icon-inner,
.lending-icon-active .lending-icon-inner {
  border-radius: 50%;
  border: 1px solid white;
  background-color: var(--primary-color);
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.lending-icon .lending-icon-inner .fa,
.lending-icon-visited .lending-icon-inner .fa,
.lending-icon-active .lending-icon-inner .fa {
  color: white;
}

.lending-icon {
  background-color: var(--grayscale-white-color);
}
.lending-icon .lending-icon-inner {
  border: 2px solid white;
  background-color: var(--grayscale-light-color);
}
.lending-icon .lending-icon-inner .fa {
  color: white;
}

.lending-icon-visited {
  background-color: var(--primary-color3);
}
.lending-icon-visited .lending-icon-inner {
  border: 2px solid white;
  background-color: var(--primary-color);
}
.lending-icon-visited .lending-icon-inner .fa {
  color: white;
}

.lending-icon-active {
  background-color: var(--primary-light1-color);
  width: 95px;
  height: 95px;
  margin-top: -7px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}
.lending-icon-active .lending-icon-inner {
  border: 2px solid white;
  background-color: var(--primary-color);
  width: 70px;
  height: 70px;
}
.lending-icon-active .lending-icon-inner .fa {
  color: white;
  font-size: 2.5em;
}

.warning .lending-icon-active {
  background-color: var(--secondary-color2);
}

.violation .lending-icon-active {
  background-color: var(--error-color);
}

/*.lending-switch-icon {
    width: 90px;
    height: 72px;
    margin: 5px auto 0;
    position: relative;

    &:hover {
        background-color: $blue;
    }

    &:before {
        font-family: FontAwesome;
        content: "\f07b";
        font-size: 66px;
        position: absolute;
        z-index: 1;
        top: -10px;
        left: 14px;
    }

    &:hover:before {
        content: "\f07c";
    }

    & .arrow:before {
        font-family: FontAwesome;
        content: "\f178";
        font-size: 46px;
        position: absolute;
        z-index: 2;
        color: $blue;
        top: 8px;
        left: 22px;
    }

    &:hover .arrow:before {
        content: "";
    }
}*/
.lending-icon-text {
  text-align: center;
  margin: 10px 0;
  padding: 0 5px;
  word-wrap: break-word;
  min-height: 40px;
}
.lending-icon-text span {
  font-weight: bold;
  display: block;
  color: var(--primary-color);
}

@media (max-width: 1599px) {
  .lending-icon-bar {
    height: 22px;
    top: 26px;
  }
  .lending-icon,
  .lending-icon-visited,
  .lending-icon-active {
    width: 70px;
    height: 70px;
  }
  .lending-icon .lending-icon-inner,
  .lending-icon-visited .lending-icon-inner,
  .lending-icon-active .lending-icon-inner {
    width: 54px;
    height: 54px;
  }
  .lending-icon-active {
    width: 85px;
    height: 85px;
    margin-top: -8px;
  }
  .lending-icon-active .lending-icon-inner {
    width: 62px;
    height: 62px;
  }
  .lending-switch-icon {
    width: 75px;
    height: 60px;
  }
  .lending-switch-icon:before {
    font-size: 55px;
    top: -8px;
    left: 11px;
  }
  .lending-switch-icon .arrow:before {
    font-size: 40px;
    top: 5px;
    left: 18px;
  }
  .lending-icon-text {
    font-size: 13px;
  }
}
@media (min-width: 1200px) and (max-width: 1400px) {
  .lending-icon-container div[class*=col-]:not([class*=lending-icon-btns]) {
    left: 20px;
  }
  .lending-icon-bar {
    height: 18px;
    top: 24px;
  }
  .lending-icon,
  .lending-icon-visited,
  .lending-icon-active {
    width: 62px;
    height: 62px;
  }
  .lending-icon .lending-icon-inner,
  .lending-icon-visited .lending-icon-inner,
  .lending-icon-active .lending-icon-inner {
    width: 50px;
    height: 50px;
  }
  .lending-icon-active {
    width: 75px;
    height: 75px;
    margin-top: -7px;
  }
  .lending-icon-active .lending-icon-inner {
    width: 57px;
    height: 57px;
  }
  .lending-switch-icon {
    width: 65px;
    height: 55px;
    margin-top: 4px;
  }
  .lending-switch-icon:before {
    font-size: 48px;
    top: -6px;
    left: 9px;
  }
  .lending-switch-icon .arrow:before {
    font-size: 36px;
    top: 4px;
    left: 13px;
  }
  .lending-icon-text {
    font-size: 12px;
  }
}
@media (max-width: 1199px) {
  .lending-icon-btns .btn {
    margin-bottom: 6px;
  }
}
.deal-nav-container {
  background: var(--primary-light1-color) linear-gradient(to top, var(--primary-light1-color), var(--primary-color));
  border-radius: 4px;
  padding: 15px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}
.deal-nav-container .top-name {
  color: white;
  font-size: 0.9625rem;
  margin-bottom: 10px;
}
.deal-nav-container .top-name .btn {
  font-size: 0.9625rem;
}
.deal-nav-container .top-name .btn:hover strong, .deal-nav-container .top-name .btn:focus strong {
  color: var(--primary-color3);
}
.deal-nav-container .btn-deal {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  width: 100%;
  font-weight: bold;
  padding: 6px 10px;
  text-align: left;
  min-height: 42px;
}
.deal-nav-container .btn-deal i {
  margin-right: 10px;
}
.deal-nav-container .btn-deal i.fa-paperclip {
  font-size: 1.5em;
  width: 24px;
}
.deal-nav-container .btn-deal .deal-title {
  margin-right: auto;
  white-space: normal;
}
.deal-nav-container .btn-deal .info-badge {
  color: white;
  padding: 3px 5px;
  background-color: var(--primary-color3);
  border-radius: 4px;
  min-width: 26px;
  height: 26px;
  text-align: center;
  margin-left: 3px;
}
.deal-nav-container ul.info-collapse {
  background-color: var(--white-color);
  padding: 10px;
  list-style: none;
  position: relative;
  top: -9px;
  border-radius: 0 0 4px 4px;
  border: 1px solid var(--primary-color-lighten-10percent);
  border-top: none;
}
.deal-nav-container ul.info-collapse li.deal {
  padding: 6px 0;
  display: inline-block;
  width: 100%;
}
.deal-nav-container ul.info-collapse li.deal.deal-number {
  padding: 0 0 6px 0;
}
.deal-nav-container ul.info-collapse li.deal a.complete:before {
  display: inline-block;
  font-family: "FontAwesome", sans-serif;
  content: "\f00c";
  font-size: 0.9625rem;
  margin-right: 10px;
  color: var(--primary-color3);
}
.deal-nav-container ul.info-collapse li.deal a.editing:before {
  display: inline-block;
  font-family: "FontAwesome", sans-serif;
  content: "\f040";
  font-size: 0.9625rem;
  margin-right: 10px;
}
.deal-nav-container ul.info-collapse li.deal a.incomplete:before {
  display: inline-block;
  font-family: "FontAwesome", sans-serif;
  content: "\f071";
  height: 22px;
  margin-right: 10px;
  color: var(--error-color);
}
.deal-nav-container ul.info-collapse li.deal a.deal-nav-info {
  float: left;
}
.deal-nav-container ul.info-collapse li.deal button.deal-nav-info-btn {
  padding: 0px 4px 0px;
  font-size: 1rem;
  display: inline-block;
  float: right;
}
.deal-nav-container ul.info-collapse li.deal span {
  display: block;
}
.deal-nav-container ul.info-collapse .btn-deal-inner {
  padding: 5px 10px;
  width: 100%;
  margin-bottom: 15px;
}
.deal-nav-container ul.info-collapse .btn-deal-inner:focus, .deal-nav-container ul.info-collapse .btn-deal-inner:hover {
  background-color: var(--primary-color3);
}
.deal-nav-container ul.info-collapse .btn-deal-inner.half-width {
  max-width: 48%;
  float: left;
}
.deal-nav-container ul.info-collapse .btn-deal-inner.half-width + .btn.half-width {
  float: right;
}

/*Opportunity Tasks*/
.opp-task-container {
  position: relative;
  display: flex;
  padding-right: 40px;
  margin-top: 5px;
}
.opp-task-container .opp-task-type {
  padding: 8px 0;
}
.opp-task-container .opp-task-assign {
  border: 1px solid var(--grayscale-white-color-darken-5percent);
  background-color: var(--grayscale-white-color-lighten-5percent);
  padding: 2px 4px 0 2px;
  margin: 7px 0 7px 15px;
  font-size: 0.75rem;
}
.opp-task-container .opp-task-assign i {
  margin-right: 3px;
}
.opp-task-container .btn {
  right: 5px;
}

.opp-task-edit-container {
  max-height: 0;
  overflow-y: hidden;
  transition: max-height 0.3s ease-out;
}

.opp-task-edit-container.opened {
  max-height: 200px;
}

.opp-task-edit {
  background-color: var(--primary-white-color);
  border: 1px solid var(--primary-light1-color);
  border-radius: 3px;
  margin: 5px 0 10px;
  padding: 10px 0 0;
}

.opp-task-add-btn {
  border: 1px solid var(--primary-light1-color);
  padding: 5px;
  width: 100%;
  text-align: center;
  border-radius: 4px;
  margin-top: 10px;
}
.opp-task-add-btn .fa {
  color: var(--primary-light1-color);
  margin-right: 10px;
}
.opp-task-add-btn:hover {
  border: 1px solid var(--primary-color);
  background-color: var(--primary-white-color);
}
.opp-task-add-btn:hover .fa {
  color: var(--primary-color);
}

/*Process Modal*/
.action-type {
  text-transform: uppercase;
  margin-right: 4px;
  color: var(--primary-color);
  font-weight: bold;
}

.modal-loader {
  text-align: center;
  position: relative;
  right: 10px;
}

.bureau-card {
  padding: 10px;
  border: 1px solid var(--primary-color-lighten-10percent);
  border-radius: 4px;
  background: var(--primary-white-color-lighten-4percent);
  margin-bottom: 8px;
}
.bureau-card .contact-card {
  background-color: var(--primary-white-color);
}
.bureau-card .bureau-card-header {
  padding: 0 80px 8px 2px;
  position: relative;
}
.bureau-card .bureau-card-header h3, .bureau-card .bureau-card-header .h3, .bureau-card .bureau-card-header p {
  margin: 0;
}
.bureau-card .bureau-card-header h3, .bureau-card .bureau-card-header .h3 {
  color: var(--primary-color);
}
.bureau-card .bureau-card-header .bureau-card-header-right {
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  justify-content: flex-end;
  gap: 4px;
}
.bureau-card .bureau-card-table {
  border-color: var(--grayscale-light-color);
}
.bureau-card .bureau-card-table > tbody > tr > td {
  padding: 5px 10px;
  min-width: 15%;
}
.bureau-card .bureau-card-table > tbody > tr > td:last-child {
  text-align: right;
  word-break: normal;
}
.bureau-card .bureau-card-table .bureau-row-header {
  background-color: var(--grayscale-white-color);
}
.bureau-card .bureau-card-table .bureau-row-header td {
  padding: 2px 10px;
}
.bureau-card .bureau-card-table .bureau-row-header td:last-child {
  text-align: left;
}
.bureau-card.bank-defined {
  background-color: var(--primary-light2-color);
}
.bureau-card.bank-defined .bureau-card-header h3, .bureau-card.bank-defined .bureau-card-header .h3 {
  color: var(--primary-color);
}

/*Collateral Analysis - Bureau Reports*/
.collateral-container,
.bureau-container {
  margin: 25px 0;
  border: 1px solid var(--grayscale-light-color);
  border-radius: 4px;
  box-shadow: 0px 0px 5px var(--grayscale-light-color-lighten-7percent);
}
.collateral-container:first-of-type,
.bureau-container:first-of-type {
  margin-top: 5px;
}
.collateral-container .collateral-header,
.collateral-container .bureau-header,
.bureau-container .collateral-header,
.bureau-container .bureau-header {
  background: var(--primary-white-color);
  border-radius: 4px 4px 0 0;
  margin-bottom: 14px;
  border-bottom: 1px solid var(--primary-light1-color);
  box-shadow: none;
}
.collateral-container .collateral-header h3, .collateral-container .collateral-header .h3,
.collateral-container .bureau-header h3,
.collateral-container .bureau-header .h3,
.bureau-container .collateral-header h3,
.bureau-container .collateral-header .h3,
.bureau-container .bureau-header h3,
.bureau-container .bureau-header .h3 {
  color: var(--primary-color);
  margin-top: 9px;
}

.bureau-container {
  margin: 15px 0;
}
.bureau-container .bureau-header {
  min-height: 44px;
  margin-bottom: 0;
}
.bureau-container .bureau-header .checkbox {
  margin: 12px 0 6px !important;
}
.bureau-container .bureau-header h3, .bureau-container .bureau-header .h3, .bureau-container .bureau-header h4, .bureau-container .bureau-header .h4 {
  margin-top: 15px;
  float: right;
}

.bureau-container .bureau-header .form-section-header h3 span, .bureau-container .bureau-header .form-section-header .h3 span {
  background: var(--primary-white-color);
}

.bureau-content {
  /*overflow-y: hidden;*/
  min-height: 0;
  max-height: 0;
  transition: max-height 0.3s ease-out, opacity 0.2s linear;
}
.bureau-content .show-bureau-content {
  display: none;
}

.bureau-content.opened {
  max-height: 3000px;
}

.bureau-content.opened .show-bureau-content {
  display: block;
}

/*Transactional Data*/
.question-container {
  padding: 6px 0;
  margin: 0;
  border-bottom: 1px solid var(--grayscale-white-color);
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
.question-container .form-group {
  margin-bottom: 0;
}

/*Lending Comment Message Board Post*/
.comment-container {
  width: 100%;
  margin: 20px 0;
  display: flex;
  background-color: white;
  border: 1px solid var(--grayscale-light-color);
  border-radius: 8px;
  box-shadow: 0px 0px 5px var(--grayscale-light-color-lighten-7percent);
}
.comment-container .comment-info {
  background-color: var(--primary-white-color-lighten-2percent);
  border-right: 1px solid var(--grayscale-light-color);
  padding: 10px;
  border-radius: 10px 0 0 10px;
  text-align: center;
}
.comment-container .comment-info h4:first-child, .comment-container .comment-info .h4:first-child {
  color: var(--primary-color);
}
.comment-container .comment-info .fa {
  font-size: 28px;
}
.comment-container .comment-info .fa.fa-thumbs-up, .comment-container .comment-info .fa.fa-check {
  color: var(--primary-color3);
}
.comment-container .comment-info .fa.fa-share {
  color: var(--secondary-color2);
}
.comment-container .comment-info .fa.fa-times {
  color: var(--error-color);
}
.comment-container .comment-info .fa.fa-comment {
  color: var(--primary-color);
  font-size: 26px;
}
.comment-container .comment-info h4:last-of-type, .comment-container .comment-info .h4:last-of-type {
  margin: 5px 0;
}
.comment-container .comment-info p {
  margin: 5px 0;
  font-style: italic;
}
.comment-container .comment-message {
  padding: 10px 20px;
  border-radius: 0 0 10px 10px;
  position: relative;
}
.comment-container .comment-message a {
  text-decoration: underline;
}
.comment-container .comment-message h4:first-child, .comment-container .comment-message .h4:first-child {
  padding-right: 70px;
}
.comment-container h4:first-child, .comment-container .h4:first-child {
  font-weight: bold;
  color: var(--primary-color);
}

/*WOPI EDITOR*/
.office_frame {
  width: 100%;
  height: calc(100vh - 210px);
  display: block;
  border: 1px solid var(--grayscale-light-color);
}

#btnEditOnWeb-Medium20 {
  display: none !important;
}

/*Debt Analysis*/
@media (min-width: 1200px) {
  .debt-col1 {
    padding-right: 30px;
  }
  .debt-col2 {
    padding-left: 30px;
  }
}
@media (max-width: 768px) {
  .debt-field-radios {
    top: -15px;
  }
  .debt-field-radios label div {
    display: inline-block;
    margin-right: 10px;
    width: auto;
  }
  .debt-field-radios label div + div {
    margin-right: 0;
  }
}
@media (min-width: 991px) and (max-width: 1199px) {
  .debt-field-radios .checkbox-radio, .debt-field-radios .checkbox-radio-no-margin {
    margin: 5px 0;
  }
}
@media (min-width: 1200px) and (max-width: 1599px) {
  .debt-field-radios {
    top: -15px;
  }
  .debt-field-radios label div {
    display: inline-block;
    margin-right: 10px;
    width: auto;
  }
  .debt-field-radios label div + div {
    margin-right: 0;
  }
}
@media (min-width: 1600px) {
  .debt-field-radios .checkbox-radio, .debt-field-radios .checkbox-radio-no-margin {
    margin: 5px 0;
  }
}
/* Decision Screen */
.final-decision {
  width: 33.33333333%;
}

/* Old Colors

$color0: #ededed; // white gray
$color1: rgb(190,190,190); // #bebebe - light gray
$color2: rgb(80,80,80); // #505050 - gray
$color3: rgb(207,214,224); // #cfd6e0 - white blue
$color4: rgb(159,172,193); // #9facc1 - light blue
$color5: rgb(88,111,146); // #586f92 - mid blue
$color6: rgb(15,48,99); // #1B3D6D - dark blue
$color7: rgb(57,213,31); // #39d51f - green
$color8: rgb(52,197,26); // #34C51A - dark green
*/
/*Theming*/
:root {
  --loading-login-screen-background:
          linear-gradient(to right, rgba(15, 48, 99, 0) 0%, rgba(75, 107, 155, .4) 25%, rgba(168, 187, 215, .4) 42%, rgba(168, 187, 215, .4) 58%, rgba(75, 107, 155, .4) 75%, rgba(15, 48, 99, 0) 100%),
          url(".././assets/images/pattern-block-lines.png") repeat;
  --loading-screen-logo: url(".././assets/images/bakerhill-nextgen-logo-white.png");
  --login-box-logo: url(".././assets/images/bakerhill-nextgen-logo.png");
  --primary-dark-color: #1B3D6D;
  --primary-color: #456aa1;
  --primary-color-darken-7percent: #3a5a88;
  --primary-color-darken-11percent: #34507a;
  --primary-color-lighten-10percent: #5f84ba;
  --primary-color-lighten-20percent: #829fca;
  --primary-color-lighten-40percent: #cad6e8;
  --primary-light1-color: #a6bad9;
  --primary-light1-color-darken-5percent:#94acd1;
  --primary-light1-color-darken-10percent: #829eca;
  --primary-light1-color-darken-15percent: #7090c2;
  --primary-light1-color-lighten-6percent: #bbcbe2;
  --primary-white-color: #e6ecf2;
  --primary-white-color-darken-2percent: #dfe7ef;
  --primary-white-color-darken-6percent: #d2dde8;
  --primary-white-color-lighten-2percent: #edf1f5;
  --primary-white-color-lighten-4percent: #f3f6f9;
  --primary-color2: #cddc29;
  --primary-color3: #81BD41;
  --primary-color3-lighten-40percent: #e5f2d8;
  --primary-color3-darken-5percent: #74aa3a;
  --primary-dark2-color: #689834;
  --primary-dark2-color-darken-10percent: #4e7227;
  --primary-light2-color: #e6f2d9;
  --primary-light2-color-darken-2percent: #e1efd1;
  --primary-light2-color-darken-6percent: #d7eac2;
  --primary-light2-color-darken-10percent: #cde5b3;
  --grayscale-color: #58595B;
  --grayscale-color-lighten-25percent: #98999b;
  --grayscale-light-color: #bdbdc2;
  --grayscale-light-color-darken-8percent: #a8a8ae;
  --grayscale-light-color-lighten-7percent: #d0d0d3;
  --grayscale-white-color: #ebebed;
  --grayscale-white-color-darken-5percent: #dedee1;
  --grayscale-white-color-darken-8percent: #d6d6da;
  --grayscale-white-color-lighten-2percent: #f0f0f2;
  --grayscale-white-color-lighten-5percent: #f8f8f9;
  --white-color: #ffffff;
  --black-color: #000000;
  --secondary-dark-color: #007299;
  --secondary-color: #77256c;
  --secondary-color2: #f68d29;
  --secondary-color3: #ffd240;
  --secondary-color3-darken-3percent: #ffce31;
  --secondary-color3-lighten-17percent: #ffe697;
  --secondary-color3-lighten-27percent: #fff2ca;
  --secondary-bright-color: #ece815;
  --error-color: #cc0000;
  --error-color-darken-10percent: #990000;
  --error-color-lighten-50percent: #ffcccc;
  --error-color-lighten-55percent: #ffe6e6;
  --error-light-color: #ffe6e6;
  --error-light-color-darken-5percent: #ffcdcd;
  --disabled-color: #abbcd5;
  --disabled-color2: #9badca;
  --disabled-color3: #a7d17a;
  --default-sprite-position: top left;
  --active-hover-sprite-position: top right;
  /*remaing colors*/
  --snow-gray: #fcfcfc;
  --light-gainsboro-gray: #f7f7f7;
  --white-smoke: #f4f4f4;
  --very-light-gray: #f0f0f0;
  --light-pink: #febbbb;
  --light-yellow: #ffffe5;
  --orange-peel: #ff9900;
  --electric-yellow: #ffff00;
  --yellow-green: #effadb;
  --whisper-gray: #ededed;
  --dark-whisper-gray: #eeeeee;
  --white-dark-smoke: #e6e6e6;
  --gainsboro-gray: #e0e0e0;
  --medium-gray: #cccccc;
  --silver: #c6c6c6;
  --silver-very-light: #b9b9b9;
  --silver-light: #bbbbbb;
  --blue-smoke: #eef1f7;
  --ivory-red: #f8f8f2;
  --spun-pearl: #afafb6;
  --very-light-blue: #e6ebf5;
  --dark-gainsboro-gray: #d9d9d9;
  --bright-gainsboro-gray: #dddddd;
  --hawakes-blue: #ccd6eb;
  --blue-violet: #9933ff;
  --nobel-gray: #999999;
  --light-danube-blue: #89a4cd;
  --matte-gray: #888888;
  --light-matte-gray: #787878;
  --dark-gray: #777777;
  --raven-gray: #707275;
  --danube-blue: #6685c2;
  --dim-gray: #666666;
  --davy-grey: #555555;
  --analog-blue: #435f8b;
  --charcoal-gray: #444444;
  --mariner-blue: #335cad;
  --dark-charcoal-gray: #333333;
  --green-waterloo: #23241f;
  --nero-dark: #222222;
  --smalt-blue: #003399;
  --deep-green: #008a00;
  --navy-blue: #0066cc;
  --bondi-blue: #0085c7;
  --violation-text: #cc0000;
  --violation-bg: #ffe6e6;
  --warning-text: #f68d29;
  --warning-bg: #fff2ca;
  --highcharts-color-2: #cddc29;
  --highcharts-color-3: #007299;
  --highcharts-color-6: #689834;
  --highcharts-color-7: #a6bad9;
}

[data-theme=fiserv] {
  --loading-login-screen-background: #666666;
  --loading-screen-logo: url(".././assets/images/Fiserv-logo-1200x603.png");
  --login-box-logo: url(".././assets/images/Fiserv-logo-1200x603.png");
  --primary-dark-color: #333333;
  --primary-color: #666666;
  --primary-color-darken-7percent: #545454;
  --primary-color-darken-11percent: #4a4a4a;
  --primary-color-lighten-10percent: gray;
  --primary-color-lighten-20percent: #999999;
  --primary-color-lighten-40percent: #cccccc;
  --primary-light1-color: #bfbfbf;
  --primary-light1-color-darken-5percent: #b2b2b2;
  --primary-light1-color-darken-10percent: #a6a6a6;
  --primary-light1-color-darken-15percent: #999999;
  --primary-light1-color-lighten-6percent: #cecece;
  --primary-white-color: #efefef;
  --primary-white-color-darken-2percent: #eaeaea;
  --primary-white-color-darken-6percent: #e0e0e0;
  --primary-white-color-lighten-2percent: #f4f4f4;
  --primary-white-color-lighten-4percent: #f9f9f9;
  --primary-color2: #4695d1;
  --primary-color3: #ff6600;
  --primary-color3-lighten-40percent: #ffe0cc;
  --primary-color3-darken-5percent: #e65c00;
  --primary-dark2-color: #ea6b15;
  --primary-dark2-color-darken-10percent: #bb5611;
  --primary-light2-color: #f2d3c4;
  --primary-light2-color-darken-2percent: #f0cdbc;
  --primary-light2-color-darken-6percent: #ecc0ab;
  --primary-light2-color-darken-10percent: #e9b49a;
  --grayscale-color: #666666;
  --grayscale-color-lighten-25percent: #a6a6a6;
  --grayscale-light-color: #e0e0e0;
  --grayscale-light-color-darken-8percent: #cccccc;
  --grayscale-light-color-lighten-7percent: #f2f2f2;
  --grayscale-white-color: #e0e0e0;
  --grayscale-white-color-darken-5percent: lightgray;
  --grayscale-white-color-darken-8percent: #cccccc;
  --grayscale-white-color-lighten-2percent: #e5e5e5;
  --grayscale-white-color-lighten-5percent: #ededed;
  --white-color: #ffffff;
  --black-color: #0e0e0e;
  --secondary-dark-color: #007299;
  --secondary-color: #77256c;
  --secondary-color2: #81bd41;
  --secondary-color3: #ffd240;
  --secondary-color3-darken-3percent: #ffce31;
  --secondary-color3-lighten-17percent: #ffe697;
  --secondary-color3-lighten-27percent: #fff2ca;
  --secondary-bright-color: #ece815;
  --error-color: #dd3435;
  --error-color-darken-10percent: #be2021;
  --error-color-lighten-50percent: white;
  --error-color-lighten-55percent: white;
  --error-light-color: #eabec0;
  --error-light-color-darken-5percent: #e4abad;
  --disabled-color: #c9c9c9;
  --disabled-color2: #c9c9c9;
  --disabled-color3: #dd9e73;
  --default-sprite-position: bottom left;
  --active-hover-sprite-position: bottom right;
  /*remaing colors*/
  --snow-gray: #fcfcfc;
  --light-gainsboro-gray: #f7f7f7;
  --white-smoke: #f4f4f4;
  --very-light-gray: #f0f0f0;
  --light-pink: #febbbb;
  --light-yellow: #ffffe5;
  --orange-peel: #ff9900;
  --electric-yellow: #ffff00;
  --yellow-green: #effadb;
  --whisper-gray: #ededed;
  --dark-whisper-gray: #eeeeee;
  --white-dark-smoke: #e6e6e6;
  --gainsboro-gray: #e0e0e0;
  --medium-gray: #cccccc;
  --silver: #c6c6c6;
  --silver-very-light: #b9b9b9;
  --silver-light: #bbbbbb;
  --blue-smoke: #eef1f7;
  --ivory-red: #f8f8f2;
  --spun-pearl: #afafb6;
  --very-light-blue: #e6ebf5;
  --dark-gainsboro-gray: #d9d9d9;
  --bright-gainsboro-gray: #dddddd;
  --hawakes-blue: #ccd6eb;
  --blue-violet: #9933ff;
  --nobel-gray: #999999;
  --light-danube-blue: #89a4cd;
  --matte-gray: #888888;
  --light-matte-gray: #787878;
  --dark-gray: #777777;
  --raven-gray: #707275;
  --danube-blue: #6685c2;
  --dim-gray: #666666;
  --davy-grey: #555555;
  --analog-blue: #435f8b;
  --charcoal-gray: #444444;
  --mariner-blue: #335cad;
  --dark-charcoal-gray: #333333;
  --green-waterloo: #23241f;
  --nero-dark: #222222;
  --smalt-blue: #003399;
  --deep-green: #008a00;
  --navy-blue: #0066cc;
  --bondi-blue: #0085c7;
  --violation-text: #cc0000;
  --violation-bg: #ffe6e6;
  --warning-text: #f68d29;
  --warning-bg: #fff2ca;
  --highcharts-color-2: #ffa366;
  --highcharts-color-3: #777777;
  --highcharts-color-6: #e65c00;
  --highcharts-color-7: #e0e0e0;
}

/*Sprite Positions*/
/*Images*/
/*Primary*/
/*Grayscale*/
/*Secondary*/
/*disabled*/
/*remaing colors*/
.darkblue {
  color: var(--primary-dark-color);
}

.blue {
  color: var(--primary-color);
}

.lightblue {
  color: var(--primary-light1-color);
}

.whiteblue {
  color: var(--primary-white-color);
}

.yellowgreen {
  color: var(--primary-color2);
}

.green {
  color: var(--primary-color3) !important;
}

.darkgreen {
  color: var(--primary-dark2-color);
}

.lightgreen {
  color: var(--primary-light2-color);
}

.gray {
  color: var(--grayscale-color);
}

.lightgray {
  color: var(--grayscale-light-color);
}

.whitegray {
  color: var(--grayscale-white-color);
}

.darkcyan {
  color: var(--secondary-dark-color);
}

.purple {
  color: var(--secondary-color);
}

.orange {
  color: var(--secondary-color2) !important;
}

.yellow {
  color: var(--secondary-color3);
}

.brightyellow {
  color: var(--secondary-bright-color);
}

.red {
  color: var(--error-color) !important;
}

.lightred {
  color: var(--error-light-color);
}

.white {
  color: var(--white-color) !important;
}

.darkblue-bg {
  background-color: var(--primary-dark-color);
}

.blue-bg {
  background-color: var(--primary-color) !important;
}

.lightblue-bg {
  background-color: var(--primary-light1-color);
}

.whiteblue-bg {
  background-color: var(--primary-white-color);
}

.yellowgreen-bg {
  background-color: var(--primary-color2);
}

.green-bg {
  background-color: var(--primary-color3);
}

.darkgreen-bg {
  background-color: var(--primary-dark2-color);
}

.lightgreen-bg {
  background-color: var(--primary-light2-color);
}

.gray-bg {
  background-color: var(--grayscale-color);
}

.lightgray-bg {
  background-color: var(--grayscale-light-color);
}

.whitegray-bg {
  background-color: var(--grayscale-white-color);
}

.darkcyan-bg {
  background-color: var(--secondary-dark-color);
}

.purple-bg {
  background-color: var(--secondary-color);
}

.orange-bg {
  background-color: var(--secondary-color2) !important;
}

.yellow-bg {
  background-color: var(--secondary-color3) !important;
}

.brightyellow-bg {
  background-color: var(--secondary-bright-color) !important;
}

.red-bg {
  background-color: var(--error-color) !important;
}

.lightred-bg {
  background-color: var(--error-light-color) !important;
}

a {
  cursor: pointer;
}
a:focus {
  color: inherit;
}
a.disabled {
  cursor: not-allowed;
  pointer-events: none;
  opacity: 0.65;
}

hr {
  border-top: 1px solid var(--primary-light1-color);
  margin-top: 10px;
}

.large {
  font-size: 0.9625rem;
}

small, .small {
  font-size: 0.75rem;
}

.pre-wrap {
  white-space: pre-wrap;
}

.nowrap {
  white-space: nowrap;
}

.bold {
  font-weight: bold;
}

.italic {
  font-style: italic;
}

.underline {
  text-decoration: underline;
}

.uppercase {
  text-transform: uppercase;
}

.uppercase-input input {
  text-transform: uppercase;
}

.center-text {
  display: flex;
  align-items: center;
  justify-content: center;
}

.info-message,
.warning-message,
.alert-message,
.error-message,
.violation-message {
  border: 1px solid var(--primary-light1-color);
  background-color: var(--primary-white-color-lighten-2percent);
  padding: 3px 10px;
  display: inline-block;
  font-size: 13px;
  color: var(--grayscale-color) !important;
}
.info-message:before,
.warning-message:before,
.alert-message:before,
.error-message:before,
.violation-message:before {
  font-family: "FontAwesome", sans-serif;
  content: "";
  font-size: 0.9625rem;
  margin-right: 10px;
}
.info-message.large,
.warning-message.large,
.alert-message.large,
.error-message.large,
.violation-message.large {
  font-size: 0.9625rem;
  padding: 6px 15px 5px;
}
.info-message.policy-message,
.warning-message.policy-message,
.alert-message.policy-message,
.error-message.policy-message,
.violation-message.policy-message {
  width: 100%;
  border-width: 0 1px;
}
.info-message.policy-message:first-of-type,
.warning-message.policy-message:first-of-type,
.alert-message.policy-message:first-of-type,
.error-message.policy-message:first-of-type,
.violation-message.policy-message:first-of-type {
  border-top-width: 1px;
}
.info-message.policy-message:last-of-type,
.warning-message.policy-message:last-of-type,
.alert-message.policy-message:last-of-type,
.error-message.policy-message:last-of-type,
.violation-message.policy-message:last-of-type {
  border-bottom-width: 1px;
  margin-bottom: 10px;
}

.import-lock-message {
  border: 1px solid var(--violation-text);
  background-color: var(--violation-bg);
  color: var(--violation-text) !important;
  padding: 3px 10px;
  display: inline-block;
  font-size: 13px;
}

.import-lock-message:before {
  font-family: "FontAwesome", sans-serif;
  content: "\f023";
  font-size: 0.9625rem;
  margin-right: 10px;
  color: var(--violation-text);
}

.info-message {
  border: 1px solid var(--primary-light1-color);
  background-color: var(--primary-white-color-lighten-2percent);
}
.info-message:before {
  content: "\f05a";
  color: var(--primary-color);
}

.warning-message {
  border: 1px solid var(--secondary-color3);
  background-color: var(--warning-bg);
}
.warning-message:before {
  content: "\f071";
  color: var(--warning-text);
}

.alert-message,
.violation-message,
.error-message {
  border: 1px solid var(--violation-text);
  background-color: var(--violation-bg);
  color: var(--violation-text) !important;
}
.alert-message:before,
.violation-message:before,
.error-message:before {
  content: "\f057";
  color: var(--violation-text);
}

.icon-unbalanced {
  display: inline-block;
  height: 20px;
  width: 22px;
  vertical-align: bottom;
  background-image: url("../assets/images/icon-unbalanced@2x.png");
  background-size: cover;
}

.single-wordwrap,
.single-wordwrap label {
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-word;
}

.truncate-ellipsis {
  display: table;
  table-layout: fixed;
  width: 100%;
  white-space: nowrap;
}
.truncate-ellipsis > * {
  display: table-cell;
  overflow: hidden;
  text-overflow: ellipsis;
}
.truncate-ellipsis .input-group-append, .truncate-ellipsis .input-group-btn {
  width: 38px;
}

h1 .fa-spin, .h1 .fa-spin {
  position: relative;
  top: 7px;
}

.file-type-icons .fa-file-word-o {
  color: var(--primary-color);
}
.file-type-icons .fa-file-excel-o {
  color: var(--primary-color3);
}
.file-type-icons .fa-file-pdf-o {
  color: var(--error-color);
}
.file-type-icons .fa-file-powerpoint-o {
  color: var(--secondary-color2);
}
.file-type-icons .fa-file-code-o {
  color: var(--secondary-color);
}

.primary-check:after {
  font-family: "FontAwesome", sans-serif;
  font-weight: bold;
  font-size: 0.9625rem;
  content: "\f00c";
  padding-left: 5px;
  color: var(--primary-color3);
  line-height: normal;
}

.badge-container {
  padding-top: 8px;
}

/* Old Colors

$color0: #ededed; // white gray
$color1: rgb(190,190,190); // #bebebe - light gray
$color2: rgb(80,80,80); // #505050 - gray
$color3: rgb(207,214,224); // #cfd6e0 - white blue
$color4: rgb(159,172,193); // #9facc1 - light blue
$color5: rgb(88,111,146); // #586f92 - mid blue
$color6: rgb(15,48,99); // #1B3D6D - dark blue
$color7: rgb(57,213,31); // #39d51f - green
$color8: rgb(52,197,26); // #34C51A - dark green
*/
/*Theming*/
:root {
  --loading-login-screen-background:
          linear-gradient(to right, rgba(15, 48, 99, 0) 0%, rgba(75, 107, 155, .4) 25%, rgba(168, 187, 215, .4) 42%, rgba(168, 187, 215, .4) 58%, rgba(75, 107, 155, .4) 75%, rgba(15, 48, 99, 0) 100%),
          url(".././assets/images/pattern-block-lines.png") repeat;
  --loading-screen-logo: url(".././assets/images/bakerhill-nextgen-logo-white.png");
  --login-box-logo: url(".././assets/images/bakerhill-nextgen-logo.png");
  --primary-dark-color: #1B3D6D;
  --primary-color: #456aa1;
  --primary-color-darken-7percent: #3a5a88;
  --primary-color-darken-11percent: #34507a;
  --primary-color-lighten-10percent: #5f84ba;
  --primary-color-lighten-20percent: #829fca;
  --primary-color-lighten-40percent: #cad6e8;
  --primary-light1-color: #a6bad9;
  --primary-light1-color-darken-5percent:#94acd1;
  --primary-light1-color-darken-10percent: #829eca;
  --primary-light1-color-darken-15percent: #7090c2;
  --primary-light1-color-lighten-6percent: #bbcbe2;
  --primary-white-color: #e6ecf2;
  --primary-white-color-darken-2percent: #dfe7ef;
  --primary-white-color-darken-6percent: #d2dde8;
  --primary-white-color-lighten-2percent: #edf1f5;
  --primary-white-color-lighten-4percent: #f3f6f9;
  --primary-color2: #cddc29;
  --primary-color3: #81BD41;
  --primary-color3-lighten-40percent: #e5f2d8;
  --primary-color3-darken-5percent: #74aa3a;
  --primary-dark2-color: #689834;
  --primary-dark2-color-darken-10percent: #4e7227;
  --primary-light2-color: #e6f2d9;
  --primary-light2-color-darken-2percent: #e1efd1;
  --primary-light2-color-darken-6percent: #d7eac2;
  --primary-light2-color-darken-10percent: #cde5b3;
  --grayscale-color: #58595B;
  --grayscale-color-lighten-25percent: #98999b;
  --grayscale-light-color: #bdbdc2;
  --grayscale-light-color-darken-8percent: #a8a8ae;
  --grayscale-light-color-lighten-7percent: #d0d0d3;
  --grayscale-white-color: #ebebed;
  --grayscale-white-color-darken-5percent: #dedee1;
  --grayscale-white-color-darken-8percent: #d6d6da;
  --grayscale-white-color-lighten-2percent: #f0f0f2;
  --grayscale-white-color-lighten-5percent: #f8f8f9;
  --white-color: #ffffff;
  --black-color: #000000;
  --secondary-dark-color: #007299;
  --secondary-color: #77256c;
  --secondary-color2: #f68d29;
  --secondary-color3: #ffd240;
  --secondary-color3-darken-3percent: #ffce31;
  --secondary-color3-lighten-17percent: #ffe697;
  --secondary-color3-lighten-27percent: #fff2ca;
  --secondary-bright-color: #ece815;
  --error-color: #cc0000;
  --error-color-darken-10percent: #990000;
  --error-color-lighten-50percent: #ffcccc;
  --error-color-lighten-55percent: #ffe6e6;
  --error-light-color: #ffe6e6;
  --error-light-color-darken-5percent: #ffcdcd;
  --disabled-color: #abbcd5;
  --disabled-color2: #9badca;
  --disabled-color3: #a7d17a;
  --default-sprite-position: top left;
  --active-hover-sprite-position: top right;
  /*remaing colors*/
  --snow-gray: #fcfcfc;
  --light-gainsboro-gray: #f7f7f7;
  --white-smoke: #f4f4f4;
  --very-light-gray: #f0f0f0;
  --light-pink: #febbbb;
  --light-yellow: #ffffe5;
  --orange-peel: #ff9900;
  --electric-yellow: #ffff00;
  --yellow-green: #effadb;
  --whisper-gray: #ededed;
  --dark-whisper-gray: #eeeeee;
  --white-dark-smoke: #e6e6e6;
  --gainsboro-gray: #e0e0e0;
  --medium-gray: #cccccc;
  --silver: #c6c6c6;
  --silver-very-light: #b9b9b9;
  --silver-light: #bbbbbb;
  --blue-smoke: #eef1f7;
  --ivory-red: #f8f8f2;
  --spun-pearl: #afafb6;
  --very-light-blue: #e6ebf5;
  --dark-gainsboro-gray: #d9d9d9;
  --bright-gainsboro-gray: #dddddd;
  --hawakes-blue: #ccd6eb;
  --blue-violet: #9933ff;
  --nobel-gray: #999999;
  --light-danube-blue: #89a4cd;
  --matte-gray: #888888;
  --light-matte-gray: #787878;
  --dark-gray: #777777;
  --raven-gray: #707275;
  --danube-blue: #6685c2;
  --dim-gray: #666666;
  --davy-grey: #555555;
  --analog-blue: #435f8b;
  --charcoal-gray: #444444;
  --mariner-blue: #335cad;
  --dark-charcoal-gray: #333333;
  --green-waterloo: #23241f;
  --nero-dark: #222222;
  --smalt-blue: #003399;
  --deep-green: #008a00;
  --navy-blue: #0066cc;
  --bondi-blue: #0085c7;
  --violation-text: #cc0000;
  --violation-bg: #ffe6e6;
  --warning-text: #f68d29;
  --warning-bg: #fff2ca;
  --highcharts-color-2: #cddc29;
  --highcharts-color-3: #007299;
  --highcharts-color-6: #689834;
  --highcharts-color-7: #a6bad9;
}

[data-theme=fiserv] {
  --loading-login-screen-background: #666666;
  --loading-screen-logo: url(".././assets/images/Fiserv-logo-1200x603.png");
  --login-box-logo: url(".././assets/images/Fiserv-logo-1200x603.png");
  --primary-dark-color: #333333;
  --primary-color: #666666;
  --primary-color-darken-7percent: #545454;
  --primary-color-darken-11percent: #4a4a4a;
  --primary-color-lighten-10percent: gray;
  --primary-color-lighten-20percent: #999999;
  --primary-color-lighten-40percent: #cccccc;
  --primary-light1-color: #bfbfbf;
  --primary-light1-color-darken-5percent: #b2b2b2;
  --primary-light1-color-darken-10percent: #a6a6a6;
  --primary-light1-color-darken-15percent: #999999;
  --primary-light1-color-lighten-6percent: #cecece;
  --primary-white-color: #efefef;
  --primary-white-color-darken-2percent: #eaeaea;
  --primary-white-color-darken-6percent: #e0e0e0;
  --primary-white-color-lighten-2percent: #f4f4f4;
  --primary-white-color-lighten-4percent: #f9f9f9;
  --primary-color2: #4695d1;
  --primary-color3: #ff6600;
  --primary-color3-lighten-40percent: #ffe0cc;
  --primary-color3-darken-5percent: #e65c00;
  --primary-dark2-color: #ea6b15;
  --primary-dark2-color-darken-10percent: #bb5611;
  --primary-light2-color: #f2d3c4;
  --primary-light2-color-darken-2percent: #f0cdbc;
  --primary-light2-color-darken-6percent: #ecc0ab;
  --primary-light2-color-darken-10percent: #e9b49a;
  --grayscale-color: #666666;
  --grayscale-color-lighten-25percent: #a6a6a6;
  --grayscale-light-color: #e0e0e0;
  --grayscale-light-color-darken-8percent: #cccccc;
  --grayscale-light-color-lighten-7percent: #f2f2f2;
  --grayscale-white-color: #e0e0e0;
  --grayscale-white-color-darken-5percent: lightgray;
  --grayscale-white-color-darken-8percent: #cccccc;
  --grayscale-white-color-lighten-2percent: #e5e5e5;
  --grayscale-white-color-lighten-5percent: #ededed;
  --white-color: #ffffff;
  --black-color: #0e0e0e;
  --secondary-dark-color: #007299;
  --secondary-color: #77256c;
  --secondary-color2: #81bd41;
  --secondary-color3: #ffd240;
  --secondary-color3-darken-3percent: #ffce31;
  --secondary-color3-lighten-17percent: #ffe697;
  --secondary-color3-lighten-27percent: #fff2ca;
  --secondary-bright-color: #ece815;
  --error-color: #dd3435;
  --error-color-darken-10percent: #be2021;
  --error-color-lighten-50percent: white;
  --error-color-lighten-55percent: white;
  --error-light-color: #eabec0;
  --error-light-color-darken-5percent: #e4abad;
  --disabled-color: #c9c9c9;
  --disabled-color2: #c9c9c9;
  --disabled-color3: #dd9e73;
  --default-sprite-position: bottom left;
  --active-hover-sprite-position: bottom right;
  /*remaing colors*/
  --snow-gray: #fcfcfc;
  --light-gainsboro-gray: #f7f7f7;
  --white-smoke: #f4f4f4;
  --very-light-gray: #f0f0f0;
  --light-pink: #febbbb;
  --light-yellow: #ffffe5;
  --orange-peel: #ff9900;
  --electric-yellow: #ffff00;
  --yellow-green: #effadb;
  --whisper-gray: #ededed;
  --dark-whisper-gray: #eeeeee;
  --white-dark-smoke: #e6e6e6;
  --gainsboro-gray: #e0e0e0;
  --medium-gray: #cccccc;
  --silver: #c6c6c6;
  --silver-very-light: #b9b9b9;
  --silver-light: #bbbbbb;
  --blue-smoke: #eef1f7;
  --ivory-red: #f8f8f2;
  --spun-pearl: #afafb6;
  --very-light-blue: #e6ebf5;
  --dark-gainsboro-gray: #d9d9d9;
  --bright-gainsboro-gray: #dddddd;
  --hawakes-blue: #ccd6eb;
  --blue-violet: #9933ff;
  --nobel-gray: #999999;
  --light-danube-blue: #89a4cd;
  --matte-gray: #888888;
  --light-matte-gray: #787878;
  --dark-gray: #777777;
  --raven-gray: #707275;
  --danube-blue: #6685c2;
  --dim-gray: #666666;
  --davy-grey: #555555;
  --analog-blue: #435f8b;
  --charcoal-gray: #444444;
  --mariner-blue: #335cad;
  --dark-charcoal-gray: #333333;
  --green-waterloo: #23241f;
  --nero-dark: #222222;
  --smalt-blue: #003399;
  --deep-green: #008a00;
  --navy-blue: #0066cc;
  --bondi-blue: #0085c7;
  --violation-text: #cc0000;
  --violation-bg: #ffe6e6;
  --warning-text: #f68d29;
  --warning-bg: #fff2ca;
  --highcharts-color-2: #ffa366;
  --highcharts-color-3: #777777;
  --highcharts-color-6: #e65c00;
  --highcharts-color-7: #e0e0e0;
}

/*Sprite Positions*/
/*Images*/
/*Primary*/
/*Grayscale*/
/*Secondary*/
/*disabled*/
/*remaing colors*/
.darkblue {
  color: var(--primary-dark-color);
}

.blue {
  color: var(--primary-color);
}

.lightblue {
  color: var(--primary-light1-color);
}

.whiteblue {
  color: var(--primary-white-color);
}

.yellowgreen {
  color: var(--primary-color2);
}

.green {
  color: var(--primary-color3) !important;
}

.darkgreen {
  color: var(--primary-dark2-color);
}

.lightgreen {
  color: var(--primary-light2-color);
}

.gray {
  color: var(--grayscale-color);
}

.lightgray {
  color: var(--grayscale-light-color);
}

.whitegray {
  color: var(--grayscale-white-color);
}

.darkcyan {
  color: var(--secondary-dark-color);
}

.purple {
  color: var(--secondary-color);
}

.orange {
  color: var(--secondary-color2) !important;
}

.yellow {
  color: var(--secondary-color3);
}

.brightyellow {
  color: var(--secondary-bright-color);
}

.red {
  color: var(--error-color) !important;
}

.lightred {
  color: var(--error-light-color);
}

.white {
  color: var(--white-color) !important;
}

.darkblue-bg {
  background-color: var(--primary-dark-color);
}

.blue-bg {
  background-color: var(--primary-color) !important;
}

.lightblue-bg {
  background-color: var(--primary-light1-color);
}

.whiteblue-bg {
  background-color: var(--primary-white-color);
}

.yellowgreen-bg {
  background-color: var(--primary-color2);
}

.green-bg {
  background-color: var(--primary-color3);
}

.darkgreen-bg {
  background-color: var(--primary-dark2-color);
}

.lightgreen-bg {
  background-color: var(--primary-light2-color);
}

.gray-bg {
  background-color: var(--grayscale-color);
}

.lightgray-bg {
  background-color: var(--grayscale-light-color);
}

.whitegray-bg {
  background-color: var(--grayscale-white-color);
}

.darkcyan-bg {
  background-color: var(--secondary-dark-color);
}

.purple-bg {
  background-color: var(--secondary-color);
}

.orange-bg {
  background-color: var(--secondary-color2) !important;
}

.yellow-bg {
  background-color: var(--secondary-color3) !important;
}

.brightyellow-bg {
  background-color: var(--secondary-bright-color) !important;
}

.red-bg {
  background-color: var(--error-color) !important;
}

.lightred-bg {
  background-color: var(--error-light-color) !important;
}

@page {
  size: A4;
  margin: 8mm 1mm 8mm 1mm;
}
@media print {
  *,
  *:before,
  *:after {
    background: transparent !important;
    color: var(--black-color) !important;
    box-shadow: none !important;
    text-shadow: none !important;
  }
  a,
  a:visited {
    text-decoration: underline;
  }
  a[href]:after {
    content: " (" attr(href) ")";
  }
  abbr[title]:after {
    content: " (" attr(title) ")";
  }
  a[href^="#"]:after,
  a[href^="javascript:"]:after {
    content: "";
  }
  pre,
  blockquote {
    border: 1px solid var(--nobel-gray);
    page-break-inside: avoid;
  }
  thead {
    display: table-header-group;
  }
  tr,
  img {
    page-break-inside: avoid;
  }
  img {
    max-width: 100% !important;
  }
  p,
  h2,
  .h2,
  h3,
  .h3 {
    orphans: 3;
    widows: 3;
  }
  h2, .h2,
  h3,
  .h3 {
    page-break-after: avoid;
  }
}
.visible-print {
  display: none !important;
}

@media print {
  .checkbox-radio input:checked ~ .checkbox-radio-indicator, .checkbox-radio-no-margin input:checked ~ .checkbox-radio-indicator {
    background: var(--primary-color) !important;
    -webkit-print-color-adjust: exact !important;
  }
  .radio .checkbox-radio-indicator:after {
    background: var(--white-color) !important;
    -webkit-print-color-adjust: exact !important;
  }
  .hidden-print,
  .bh-navbar,
  #sidebar-left-panel,
  .header-right {
    display: none !important;
  }
  .visible-print {
    display: block !important;
  }
  .page-break-after {
    page-break-after: always !important;
    display: block;
  }
  #main-content {
    padding-left: 0;
  }
  #sidebar-wrapper {
    top: 0;
  }
  .sidebar-minimized #main-content {
    padding-left: 0;
  }
  .header-panel {
    padding: 0;
  }
  h1, .h1 {
    font-size: 1.75rem;
  }
  h2, .h2 {
    font-size: 1.4rem;
  }
  h3, .h3 {
    font-size: 1.09375rem;
  }
  h1, .h1, h2, .h2, h3, .h3 {
    margin: 0;
  }
  .form-section-header h2, .form-section-header .h2,
  .form-section-header h3,
  .form-section-header .h3 {
    border-bottom: none;
    margin: 15px 0 10px;
    line-height: inherit;
  }
  .table-hscroll, table, thead, tbody, tfoot, th, td, tr {
    border-color: var(--grayscale-light-color);
  }
  .table-hscroll {
    overflow-x: hidden;
  }
  td.green-background,
  td.blue-background {
    background-color: var(--grayscale-white-color) !important;
  }
  .score-trend-layout .report-logo {
    position: absolute;
    top: 0;
    left: 0;
  }
  .score-trend-layout .report-info {
    position: absolute;
    top: 0;
    right: 0;
  }
  .score-trend-layout table > thead > tr > th,
  .score-trend-layout table > tbody > tr > th,
  .score-trend-layout table > tfoot > tr > th,
  .score-trend-layout table > thead > tr > td,
  .score-trend-layout table > tbody > tr > td,
  .score-trend-layout table > tfoot > tr > td {
    padding: 2px 10px;
  }
  .score-trend-layout table > thead tr > th, .score-trend-layout table > thead tr > td,
  .score-trend-layout table > tfoot tr > th,
  .score-trend-layout table > tfoot tr > td {
    background-color: var(--primary-white-color) !important;
  }
  .score-trend-layout .header-panel {
    border-bottom: 1px solid var(--grayscale-light-color);
    padding-bottom: 10px;
  }
  .score-trend-layout .header-panel .header-left {
    float: none;
  }
  .score-trend-layout .header-panel h1, .score-trend-layout .header-panel .h1, .score-trend-layout .header-panel h2, .score-trend-layout .header-panel .h2 {
    padding-left: 55px;
  }
  .score-trend-layout .header-panel h2, .score-trend-layout .header-panel .h2 {
    margin-top: 10px;
  }
  .header-panel.sticky,
  .header-panel-table.sticky,
  .header-panel-connect.sticky {
    position: static;
    top: auto;
    z-index: auto;
  }
}
/* Old Colors

$color0: #ededed; // white gray
$color1: rgb(190,190,190); // #bebebe - light gray
$color2: rgb(80,80,80); // #505050 - gray
$color3: rgb(207,214,224); // #cfd6e0 - white blue
$color4: rgb(159,172,193); // #9facc1 - light blue
$color5: rgb(88,111,146); // #586f92 - mid blue
$color6: rgb(15,48,99); // #1B3D6D - dark blue
$color7: rgb(57,213,31); // #39d51f - green
$color8: rgb(52,197,26); // #34C51A - dark green
*/
/*Theming*/
:root {
  --loading-login-screen-background:
          linear-gradient(to right, rgba(15, 48, 99, 0) 0%, rgba(75, 107, 155, .4) 25%, rgba(168, 187, 215, .4) 42%, rgba(168, 187, 215, .4) 58%, rgba(75, 107, 155, .4) 75%, rgba(15, 48, 99, 0) 100%),
          url(".././assets/images/pattern-block-lines.png") repeat;
  --loading-screen-logo: url(".././assets/images/bakerhill-nextgen-logo-white.png");
  --login-box-logo: url(".././assets/images/bakerhill-nextgen-logo.png");
  --primary-dark-color: #1B3D6D;
  --primary-color: #456aa1;
  --primary-color-darken-7percent: #3a5a88;
  --primary-color-darken-11percent: #34507a;
  --primary-color-lighten-10percent: #5f84ba;
  --primary-color-lighten-20percent: #829fca;
  --primary-color-lighten-40percent: #cad6e8;
  --primary-light1-color: #a6bad9;
  --primary-light1-color-darken-5percent:#94acd1;
  --primary-light1-color-darken-10percent: #829eca;
  --primary-light1-color-darken-15percent: #7090c2;
  --primary-light1-color-lighten-6percent: #bbcbe2;
  --primary-white-color: #e6ecf2;
  --primary-white-color-darken-2percent: #dfe7ef;
  --primary-white-color-darken-6percent: #d2dde8;
  --primary-white-color-lighten-2percent: #edf1f5;
  --primary-white-color-lighten-4percent: #f3f6f9;
  --primary-color2: #cddc29;
  --primary-color3: #81BD41;
  --primary-color3-lighten-40percent: #e5f2d8;
  --primary-color3-darken-5percent: #74aa3a;
  --primary-dark2-color: #689834;
  --primary-dark2-color-darken-10percent: #4e7227;
  --primary-light2-color: #e6f2d9;
  --primary-light2-color-darken-2percent: #e1efd1;
  --primary-light2-color-darken-6percent: #d7eac2;
  --primary-light2-color-darken-10percent: #cde5b3;
  --grayscale-color: #58595B;
  --grayscale-color-lighten-25percent: #98999b;
  --grayscale-light-color: #bdbdc2;
  --grayscale-light-color-darken-8percent: #a8a8ae;
  --grayscale-light-color-lighten-7percent: #d0d0d3;
  --grayscale-white-color: #ebebed;
  --grayscale-white-color-darken-5percent: #dedee1;
  --grayscale-white-color-darken-8percent: #d6d6da;
  --grayscale-white-color-lighten-2percent: #f0f0f2;
  --grayscale-white-color-lighten-5percent: #f8f8f9;
  --white-color: #ffffff;
  --black-color: #000000;
  --secondary-dark-color: #007299;
  --secondary-color: #77256c;
  --secondary-color2: #f68d29;
  --secondary-color3: #ffd240;
  --secondary-color3-darken-3percent: #ffce31;
  --secondary-color3-lighten-17percent: #ffe697;
  --secondary-color3-lighten-27percent: #fff2ca;
  --secondary-bright-color: #ece815;
  --error-color: #cc0000;
  --error-color-darken-10percent: #990000;
  --error-color-lighten-50percent: #ffcccc;
  --error-color-lighten-55percent: #ffe6e6;
  --error-light-color: #ffe6e6;
  --error-light-color-darken-5percent: #ffcdcd;
  --disabled-color: #abbcd5;
  --disabled-color2: #9badca;
  --disabled-color3: #a7d17a;
  --default-sprite-position: top left;
  --active-hover-sprite-position: top right;
  /*remaing colors*/
  --snow-gray: #fcfcfc;
  --light-gainsboro-gray: #f7f7f7;
  --white-smoke: #f4f4f4;
  --very-light-gray: #f0f0f0;
  --light-pink: #febbbb;
  --light-yellow: #ffffe5;
  --orange-peel: #ff9900;
  --electric-yellow: #ffff00;
  --yellow-green: #effadb;
  --whisper-gray: #ededed;
  --dark-whisper-gray: #eeeeee;
  --white-dark-smoke: #e6e6e6;
  --gainsboro-gray: #e0e0e0;
  --medium-gray: #cccccc;
  --silver: #c6c6c6;
  --silver-very-light: #b9b9b9;
  --silver-light: #bbbbbb;
  --blue-smoke: #eef1f7;
  --ivory-red: #f8f8f2;
  --spun-pearl: #afafb6;
  --very-light-blue: #e6ebf5;
  --dark-gainsboro-gray: #d9d9d9;
  --bright-gainsboro-gray: #dddddd;
  --hawakes-blue: #ccd6eb;
  --blue-violet: #9933ff;
  --nobel-gray: #999999;
  --light-danube-blue: #89a4cd;
  --matte-gray: #888888;
  --light-matte-gray: #787878;
  --dark-gray: #777777;
  --raven-gray: #707275;
  --danube-blue: #6685c2;
  --dim-gray: #666666;
  --davy-grey: #555555;
  --analog-blue: #435f8b;
  --charcoal-gray: #444444;
  --mariner-blue: #335cad;
  --dark-charcoal-gray: #333333;
  --green-waterloo: #23241f;
  --nero-dark: #222222;
  --smalt-blue: #003399;
  --deep-green: #008a00;
  --navy-blue: #0066cc;
  --bondi-blue: #0085c7;
  --violation-text: #cc0000;
  --violation-bg: #ffe6e6;
  --warning-text: #f68d29;
  --warning-bg: #fff2ca;
  --highcharts-color-2: #cddc29;
  --highcharts-color-3: #007299;
  --highcharts-color-6: #689834;
  --highcharts-color-7: #a6bad9;
}

[data-theme=fiserv] {
  --loading-login-screen-background: #666666;
  --loading-screen-logo: url(".././assets/images/Fiserv-logo-1200x603.png");
  --login-box-logo: url(".././assets/images/Fiserv-logo-1200x603.png");
  --primary-dark-color: #333333;
  --primary-color: #666666;
  --primary-color-darken-7percent: #545454;
  --primary-color-darken-11percent: #4a4a4a;
  --primary-color-lighten-10percent: gray;
  --primary-color-lighten-20percent: #999999;
  --primary-color-lighten-40percent: #cccccc;
  --primary-light1-color: #bfbfbf;
  --primary-light1-color-darken-5percent: #b2b2b2;
  --primary-light1-color-darken-10percent: #a6a6a6;
  --primary-light1-color-darken-15percent: #999999;
  --primary-light1-color-lighten-6percent: #cecece;
  --primary-white-color: #efefef;
  --primary-white-color-darken-2percent: #eaeaea;
  --primary-white-color-darken-6percent: #e0e0e0;
  --primary-white-color-lighten-2percent: #f4f4f4;
  --primary-white-color-lighten-4percent: #f9f9f9;
  --primary-color2: #4695d1;
  --primary-color3: #ff6600;
  --primary-color3-lighten-40percent: #ffe0cc;
  --primary-color3-darken-5percent: #e65c00;
  --primary-dark2-color: #ea6b15;
  --primary-dark2-color-darken-10percent: #bb5611;
  --primary-light2-color: #f2d3c4;
  --primary-light2-color-darken-2percent: #f0cdbc;
  --primary-light2-color-darken-6percent: #ecc0ab;
  --primary-light2-color-darken-10percent: #e9b49a;
  --grayscale-color: #666666;
  --grayscale-color-lighten-25percent: #a6a6a6;
  --grayscale-light-color: #e0e0e0;
  --grayscale-light-color-darken-8percent: #cccccc;
  --grayscale-light-color-lighten-7percent: #f2f2f2;
  --grayscale-white-color: #e0e0e0;
  --grayscale-white-color-darken-5percent: lightgray;
  --grayscale-white-color-darken-8percent: #cccccc;
  --grayscale-white-color-lighten-2percent: #e5e5e5;
  --grayscale-white-color-lighten-5percent: #ededed;
  --white-color: #ffffff;
  --black-color: #0e0e0e;
  --secondary-dark-color: #007299;
  --secondary-color: #77256c;
  --secondary-color2: #81bd41;
  --secondary-color3: #ffd240;
  --secondary-color3-darken-3percent: #ffce31;
  --secondary-color3-lighten-17percent: #ffe697;
  --secondary-color3-lighten-27percent: #fff2ca;
  --secondary-bright-color: #ece815;
  --error-color: #dd3435;
  --error-color-darken-10percent: #be2021;
  --error-color-lighten-50percent: white;
  --error-color-lighten-55percent: white;
  --error-light-color: #eabec0;
  --error-light-color-darken-5percent: #e4abad;
  --disabled-color: #c9c9c9;
  --disabled-color2: #c9c9c9;
  --disabled-color3: #dd9e73;
  --default-sprite-position: bottom left;
  --active-hover-sprite-position: bottom right;
  /*remaing colors*/
  --snow-gray: #fcfcfc;
  --light-gainsboro-gray: #f7f7f7;
  --white-smoke: #f4f4f4;
  --very-light-gray: #f0f0f0;
  --light-pink: #febbbb;
  --light-yellow: #ffffe5;
  --orange-peel: #ff9900;
  --electric-yellow: #ffff00;
  --yellow-green: #effadb;
  --whisper-gray: #ededed;
  --dark-whisper-gray: #eeeeee;
  --white-dark-smoke: #e6e6e6;
  --gainsboro-gray: #e0e0e0;
  --medium-gray: #cccccc;
  --silver: #c6c6c6;
  --silver-very-light: #b9b9b9;
  --silver-light: #bbbbbb;
  --blue-smoke: #eef1f7;
  --ivory-red: #f8f8f2;
  --spun-pearl: #afafb6;
  --very-light-blue: #e6ebf5;
  --dark-gainsboro-gray: #d9d9d9;
  --bright-gainsboro-gray: #dddddd;
  --hawakes-blue: #ccd6eb;
  --blue-violet: #9933ff;
  --nobel-gray: #999999;
  --light-danube-blue: #89a4cd;
  --matte-gray: #888888;
  --light-matte-gray: #787878;
  --dark-gray: #777777;
  --raven-gray: #707275;
  --danube-blue: #6685c2;
  --dim-gray: #666666;
  --davy-grey: #555555;
  --analog-blue: #435f8b;
  --charcoal-gray: #444444;
  --mariner-blue: #335cad;
  --dark-charcoal-gray: #333333;
  --green-waterloo: #23241f;
  --nero-dark: #222222;
  --smalt-blue: #003399;
  --deep-green: #008a00;
  --navy-blue: #0066cc;
  --bondi-blue: #0085c7;
  --violation-text: #cc0000;
  --violation-bg: #ffe6e6;
  --warning-text: #f68d29;
  --warning-bg: #fff2ca;
  --highcharts-color-2: #ffa366;
  --highcharts-color-3: #777777;
  --highcharts-color-6: #e65c00;
  --highcharts-color-7: #e0e0e0;
}

/*Sprite Positions*/
/*Images*/
/*Primary*/
/*Grayscale*/
/*Secondary*/
/*disabled*/
/*remaing colors*/
.darkblue {
  color: var(--primary-dark-color);
}

.blue {
  color: var(--primary-color);
}

.lightblue {
  color: var(--primary-light1-color);
}

.whiteblue {
  color: var(--primary-white-color);
}

.yellowgreen {
  color: var(--primary-color2);
}

.green {
  color: var(--primary-color3) !important;
}

.darkgreen {
  color: var(--primary-dark2-color);
}

.lightgreen {
  color: var(--primary-light2-color);
}

.gray {
  color: var(--grayscale-color);
}

.lightgray {
  color: var(--grayscale-light-color);
}

.whitegray {
  color: var(--grayscale-white-color);
}

.darkcyan {
  color: var(--secondary-dark-color);
}

.purple {
  color: var(--secondary-color);
}

.orange {
  color: var(--secondary-color2) !important;
}

.yellow {
  color: var(--secondary-color3);
}

.brightyellow {
  color: var(--secondary-bright-color);
}

.red {
  color: var(--error-color) !important;
}

.lightred {
  color: var(--error-light-color);
}

.white {
  color: var(--white-color) !important;
}

.darkblue-bg {
  background-color: var(--primary-dark-color);
}

.blue-bg {
  background-color: var(--primary-color) !important;
}

.lightblue-bg {
  background-color: var(--primary-light1-color);
}

.whiteblue-bg {
  background-color: var(--primary-white-color);
}

.yellowgreen-bg {
  background-color: var(--primary-color2);
}

.green-bg {
  background-color: var(--primary-color3);
}

.darkgreen-bg {
  background-color: var(--primary-dark2-color);
}

.lightgreen-bg {
  background-color: var(--primary-light2-color);
}

.gray-bg {
  background-color: var(--grayscale-color);
}

.lightgray-bg {
  background-color: var(--grayscale-light-color);
}

.whitegray-bg {
  background-color: var(--grayscale-white-color);
}

.darkcyan-bg {
  background-color: var(--secondary-dark-color);
}

.purple-bg {
  background-color: var(--secondary-color);
}

.orange-bg {
  background-color: var(--secondary-color2) !important;
}

.yellow-bg {
  background-color: var(--secondary-color3) !important;
}

.brightyellow-bg {
  background-color: var(--secondary-bright-color) !important;
}

.red-bg {
  background-color: var(--error-color) !important;
}

.lightred-bg {
  background-color: var(--error-light-color) !important;
}

bh-r-span {
  min-height: 77px;
  display: inline-block;
}

span.required-icon.tooltip-tr.no-margin:before {
  margin: 0 !important;
}

.upload-text {
  position: absolute;
  overflow: hidden;
  white-space: nowrap;
  width: 100%;
  text-overflow: ellipsis;
  padding-right: 8px;
}

.table-contact-card > tbody > tr > td:first-child.btn3 {
  min-width: 100px;
  width: 100px;
}

.contact-card .contact-card-header button.btn-comm-add {
  position: relative;
  top: 10px;
}

.spacertop20.user-security span.radio-label {
  display: inline;
  padding-right: 20px;
}

.hide-on {
  width: 100%;
  height: 100%;
  opacity: 0.65;
  position: absolute;
  z-index: 99;
  background: var(--grayscale-white-color);
}

.pos-rel {
  position: relative;
}

/****************************** for custom table branch 21579 ******************************/
.table-fixed {
  table-layout: fixed;
}
.table-fixed tr td label {
  padding: 0;
  margin: 0;
}
.table-fixed tr td bh-r-input label.form-group {
  padding: 0;
  margin: 0;
}
.table-fixed tr td bh-r-input label.form-group input {
  padding: 0 5px;
  margin: 0;
}
.table-fixed tr th:not(.btn-col).sort button .sort-icon:after {
  left: 96px;
}
.table-fixed tr th:not(.btn-col).sort button .sort-icon-desc:after {
  left: 96px;
}
.table-fixed tr th:not(.btn-col).sort button .sort-icon-asc:after {
  left: 96px;
}
.table-fixed tr th:not(.btn2-col).sort button .sort-icon:after {
  left: 96px;
}
.table-fixed tr th:not(.btn2-col).sort button .sort-icon-desc:after {
  left: 96px;
}
.table-fixed tr th:not(.btn2-col).sort button .sort-icon-asc:after {
  left: 96px;
}
.table-fixed tr th:first-child {
  width: 50px;
}

/****************************** for custom table branch 21579 end ******************************/
.label-right bh-r-input label {
  text-align: right;
  margin-top: 10px;
}
.label-right bh-r-input label input {
  width: 60%;
  float: left;
  margin-top: -8px !important;
}
.label-right bh-r-input label input + .errorMessage {
  width: 72%;
  text-align: left;
}

.dropdown-menu .fa-flip-horizontal {
  padding-left: 5px;
}

.highcharts-container {
  overflow: hidden;
}

.appointment-chart-container .highcharts-graph {
  stroke-width: 20px;
  stroke-linecap: butt;
  stroke-linejoin: round;
}
.appointment-chart-container .highcharts-plot-band {
  fill-opacity: 1;
  stroke-width: 2;
  stroke: var(--primary-color3);
}
.appointment-chart-container .highcharts-xaxis-grid .highcharts-grid-line {
  stroke-width: 1;
  stroke: var(--grayscale-white-color);
}

.print-table table tr td .fa-percent {
  font-size: 0.75rem;
  color: var(--grayscale-color);
  padding-left: 3px;
}
.print-table table tr td .fa-usd {
  font-size: 0.75rem;
  color: var(--grayscale-color);
  padding-right: 2px;
}

#stackingChart .highcharts-color-1 {
  fill: var(--secondary-dark-color);
  stroke: var(--secondary-dark-color);
}
#stackingChart .hc-green {
  fill: var(--secondary-dark-color);
  stroke: var(--secondary-dark-color);
}
#stackingChart .hc-green .highcharts-point {
  fill: var(--secondary-dark-color);
  stroke: var(--secondary-dark-color);
}
#stackingChart .highcharts-color-0 {
  fill: var(--secondary-color2);
  stroke: var(--secondary-color2);
}
#stackingChart .hc-darkblue {
  fill: var(--secondary-color2);
  stroke: var(--secondary-color2);
}
#stackingChart .hc-darkblue .highcharts-point {
  fill: var(--secondary-color2);
  stroke: var(--secondary-color2);
}
#stackingChart .highcharts-data-label text {
  fill: var(--grayscale-white-color);
}

.input-group-btn.disabled {
  opacity: 0.65;
  cursor: not-allowed;
}
.input-group-btn.disabled label {
  pointer-events: none;
}

.highcharts-subtitle {
  font-size: 0.9625rem;
}

.spacer-top-75 {
  margin-top: -75px;
}

#print-section h1, #print-section .h1 {
  color: var(--primary-dark-color);
}
#print-section h3, #print-section .h3 {
  color: var(--primary-dark-color);
}
#print-section table.gridtable {
  margin: 20px 0;
}
#print-section table.gridtable tr th.none {
  background: var(--primary-color);
  color: var(--white-color);
  border: 0;
}
#print-section table.gridtable tr th {
  background: var(--primary-color);
  color: var(--white-color);
  border: 0;
}

bh-query-node-field bh-r-loading bh-table .flexgrid-treeview .wj-flexgrid .wj-cell:nth-child(odd) {
  width: calc(100% + 10px) !important;
  overflow-x: inherit !important;
}

bh-user-search-and-select bh-r-modal .modal-body {
  min-height: 240px;
}
bh-user-search-and-select bh-r-modal .modal-body .flexgrid-single-select.flexgrid-treeview .wj-flexgrid .wj-group {
  border-right: 1px solid var(--grayscale-light-color) !important;
  white-space: normal;
  word-break: break-all;
}

bh-import-file span.required-icon.tooltip-tr {
  display: inline;
}

.form-section-header.plus-checkbox bh-r-checkbox {
  display: initial;
}

table.table-striped tr.group-header td.columntype-currency:before {
  display: none;
}
table.table-striped tr.group-header td.columntype-percentage:after {
  display: none;
}

.high-chart-container .highcharts-graph {
  stroke-width: 33px;
  stroke-linecap: square;
}
.high-chart-container .highcharts-xaxis-grid .highcharts-grid-line {
  stroke-width: 1;
  stroke: var(--grayscale-white-color);
}

.row.flex-wrap .report-box {
  overflow-wrap: break-word;
}

.news-message.fi.unread p {
  overflow-wrap: break-word;
}

.checkbox-disabled {
  cursor: not-allowed;
}
.checkbox-disabled button.btn {
  display: block !important;
  pointer-events: none;
  opacity: 0.65;
}

.checkbox-disable:first-child .btn {
  display: block !important;
  pointer-events: none;
  opacity: 0.65;
  cursor: not-allowed;
}

table.fixed-header {
  width: calc(100% - 14px);
  table-layout: fixed;
}
table.fixed-header tr th.col-order {
  width: 60px !important;
}

table.fixed-body {
  table-layout: fixed;
}
table.fixed-body tbody {
  width: 100%;
}
table.fixed-body tbody tr td.col-order {
  width: 60px !important;
}

.pagination-container {
  display: flex;
  display: -webkit-flex;
  flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  justify-content: space-between;
}

.btn-white:disabled,
.btn-white.disabled {
  cursor: not-allowed;
  opacity: 1;
  background: var(--disabled-color);
  color: var(--primary-color);
}
.btn-white:disabled:hover,
.btn-white.disabled:hover {
  background-color: var(--disabled-color) !important;
  color: var(--primary-color) !important;
}

.select-actions .btn-blue:disabled {
  cursor: not-allowed;
  opacity: 1;
  background: var(--disabled-color2);
  border-color: var(--disabled-color2);
}
.select-actions .btn-blue:disabled .overlay-icon-blue {
  background: var(--disabled-color2);
}

.dropdown-menu button[disabled] {
  color: var(--disabled-color) !important;
}

.bureau-container .bureau-header {
  padding: 0 12px;
}
.bureau-container .bureau-header .header-left {
  float: left;
  width: auto;
}
.bureau-container .bureau-header .header-right h4, .bureau-container .bureau-header .header-right .h4 {
  color: var(--grayscale-color);
  line-height: 1.25;
}

.form-row-align .form-group {
  min-height: 60px;
}

bh-report-list .header-panel .header-right {
  width: auto;
}
bh-report-list .header-panel .header-left {
  width: auto;
}

bh-task-list .header-panel .header-right {
  width: auto;
}
bh-task-list .header-panel .header-left {
  width: auto;
}

.group-reporting table.spreadTable tbody td.form-control-sm {
  overflow: hidden;
}

.btn-blue .fa-user-o + .overlay-icon-blue {
  top: 16px;
}

.btn-white.btn-contact {
  background-color: var(--primary-color) !important;
  color: #fff !important;
  border-color: var(--primary-color) !important;
}

#sidebar-left-panel .panel-list > li > a > i.fa-user-o span.overlay-icon {
  top: 10px;
  font-size: 1.188rem;
}

.draggable-area {
  outline: 2px dashed var(--primary-color);
}

.draggable-scroll {
  overflow-y: scroll;
  position: relative;
  max-height: calc(100vh - 360px);
}

bh-r-modal .loading-indicator-details-container .loading-indicator-details {
  min-height: 100px;
}
bh-r-modal .loading-indicator-details-container .loading-indicator-details + i {
  top: 0;
}

.width-auto {
  width: auto !important;
}

@media only screen and (max-width: 1262px) {
  .wrap-normal {
    white-space: normal;
  }
}
.vertical-inherit .modal-body {
  overflow-y: inherit !important;
}

bh-r-lending-progress .lending-icon-text strong {
  display: block;
  color: var(--primary-color);
}

bh-sql-lending-authority .btn + .btn {
  margin-left: 5px;
}

bh-integer-lending-authority .btn + .btn {
  margin-left: 5px;
}

.cell-disabled {
  cursor: not-allowed;
  opacity: 0.65;
}

.cell-disabled.btn2-col {
  cursor: auto;
  opacity: 1;
}

.sa-table-container.update-statement-table table tbody td.fixed-col {
  line-height: 1.5rem;
}

@media screen and (max-width: 991px) {
  .list-inline.pull-left li .filetype-icon {
    margin-left: 16px;
  }
}
.overflow-wrap {
  overflow-wrap: break-word;
  word-wrap: break-word;
  white-space: pre-line;
}

.datepicker-label.focus .ng-touched.ng-dirty.ng-invalid + ng2-datetime-picker {
  top: 66px !important;
}

.height-auto {
  height: auto !important;
}

.sa-table-container.update-statement-table table tr .blank-cell {
  text-align: center;
  width: 33px;
  min-width: 33px;
  line-height: 1.25rem;
  min-height: 20px;
  vertical-align: top;
  border-right: 2px solid var(--light-danube-blue);
}

.static-object {
  position: static !important;
}

.height-auto {
  height: auto !important;
}

.sa-table-container.update-statement-table table.spreadTable tbody td.fixed-col .line-title {
  max-width: 249px;
}
.sa-table-container.update-statement-table table.spreadTable table {
  background: transparent;
  display: table-cell;
}
.sa-table-container.update-statement-table table.spreadTable table tbody {
  display: inline;
}
.sa-table-container.update-statement-table table.spreadTable table tbody th:first-child {
  padding: 8px 12px;
}
.sa-table-container.update-statement-table table.spreadTable table tbody td:nth-child(2) {
  display: block !important;
  padding-left: 8px;
}
.sa-table-container.update-statement-table table.spreadTable tr.line-type.sub-row table td:first-child {
  width: 25px;
  min-width: 25px;
}
.sa-table-container.update-statement-table table.spreadTable tr.line-type.sub-row td.fixed-col:before {
  left: 37px;
  top: 2px;
  z-index: 9;
}
.sa-table-container.update-statement-table tr.white-bg th {
  text-align: right;
}

.sa-table-container.update-statement-table table table {
  width: 100%;
  table-layout: auto;
}
.sa-table-container.update-statement-table table table .tooltip-br span {
  bottom: 80%;
  left: -40px;
}

.white-bg {
  background-color: var(--white-color);
}

.header-panel-table {
  background: var(--primary-color) linear-gradient(to bottom, var(--primary-color), var(--primary-color) 55%, var(--primary-color-darken-7percent));
  border-radius: 4px;
  min-height: 50px;
  padding: 7px 12px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}
.header-panel-table .header-left {
  float: left;
  width: calc(100% - 280px);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.header-panel-table .header-right {
  float: right;
  width: 280px;
  text-align: right;
}
@media (max-width: 479px) {
  .header-panel-table .header-left,
  .header-panel-table .header-right {
    float: none;
    width: 100%;
    text-align: left;
  }
}
.header-panel-table .dropdown-menu {
  white-space: nowrap;
}
.header-panel-table .dropdown-menu > li {
  line-height: 1.428571429;
}
.header-panel-table .dropdown-menu > li:hover, .header-panel-table .dropdown-menu > li:focus {
  background-color: var(--grayscale-white-color);
}
.header-panel-table .inline-control {
  display: inline-block;
  margin-right: 10px;
  float: left;
}
.header-panel-table .inline-control-label {
  color: white !important;
}
.header-panel-table .inline-control-label .inline-control {
  margin-top: 15px;
}

.flexgrid-pagination .stickyHeight {
  max-height: calc(100vh - 300px);
}
.flexgrid-pagination .stickyHeight [wj-part=root] {
  overflow-y: auto !important;
}
.flexgrid-pagination .stickyHeight .wj-header {
  opacity: 1;
}

.width-100px {
  width: 100px;
}

.width-200px {
  width: 200px !important;
}

.width-300px {
  width: 300px !important;
}

.display-none {
  display: none;
}

.overflow-visible {
  overflow: visible !important;
}

.no-margin-top {
  margin-top: 0;
}

.display-block {
  display: block;
}

.display-block-important {
  display: block !important;
}

.spacer-right-10 {
  margin-right: 10px;
}

.spacer-left-15 {
  margin-left: 15px;
}

.spacer-minus-top-20 {
  margin-top: -20px;
}

.spacer-right-5 {
  margin-right: 5px;
}

.top-7 {
  top: 7px;
}

.top-0 {
  top: 0;
}

.right-15 {
  right: 15px;
}

.right-65 {
  right: -65px !important;
}

.position-absolute {
  position: absolute;
}

.height-65 {
  height: 65px;
}

.lightgray-border-right {
  border-right: 1px solid var(--grayscale-light-color);
}

.lightgray-border-top {
  border-top: 1px solid var(--grayscale-light-color);
}

.padding-left-50 {
  padding-left: 50px !important;
}

th.text-left {
  text-align: left !important;
}

.spacer120 {
  margin-bottom: 120px;
}

.height-77-important {
  height: 77px !important;
}

.text-underline {
  text-decoration: underline;
}

.min-width-auto {
  min-width: auto !important;
}

.width-25 {
  width: 25px;
}

.width-35 {
  width: 35px;
}

.width-140px {
  width: 140px;
}

.width-110px {
  width: 110px;
}

.margin-right-3px {
  margin-right: 3px;
}

.padding-right-40 {
  padding-right: 40px !important;
}

.modal-third-button {
  width: 450px;
}

@media only screen and (max-width: 1366px) {
  .normal-line-height {
    line-height: 1.428571429;
  }
}
.bottom-54 {
  bottom: 54px;
}

.zindex-1 {
  z-index: 1;
}

.cursor-reset * {
  cursor: default !important;
}
.cursor-reset *:hover {
  outline: 0 !important;
  right: 0 !important;
  bottom: 0 !important;
}
.cursor-reset * button {
  cursor: pointer !important;
}

.pull-label-right .radio-label {
  float: right;
  line-height: 2.3;
}

.padding-auto-8 {
  padding: 0 8px;
}

.margin-auto-10 {
  margin: 0 10px;
}

.line-height-30 {
  line-height: 1.875rem;
}

.margin-left-29 {
  margin-left: 29px;
}

.border-left-light-danube-blue {
  border-left: 2px solid var(--light-danube-blue);
}

.border-right-light-danube-blue {
  border-right: 2px solid var(--light-danube-blue);
}

.no-radius {
  border-radius: 0;
}

.height-inherit {
  height: inherit;
}

.sub-row .line-title {
  padding-left: 20px !important;
  border-left: 2px solid var(--light-danube-blue);
}
.sub-row .tooltip-br span {
  left: -21px !important;
}
.sub-row table {
  padding-left: 21px !important;
}

.line-height-1 {
  line-height: 1px;
}

.line-title {
  line-height: 29px;
}

.parent-checkbox-disabled .parent-disable {
  cursor: not-allowed;
}
.parent-checkbox-disabled .parent-disable .btn {
  pointer-events: none;
  opacity: 0.65;
}

.btn-extra-small {
  padding: 1px 6px;
  background: var(--primary-color-lighten-10percent);
  border: 2px solid var(--primary-dark-color);
  font-weight: normal;
  font-size: 0.8125rem;
}

.no-asterisk .required-icon {
  display: none;
}

bh-dropdown-table .wj-flexgrid .wj-colheaders .wj-header {
  padding: 8px;
}

.m-h-77 {
  min-height: 77px;
}

.top-message-color {
  background: var(--white-smoke);
  margin: 0 0 9px 0;
  font-size: 0.75rem;
  padding: 9px 5px;
  box-shadow: 3px 3px 9px var(--dim-gray);
  border: 2px solid var(--medium-gray);
  color: var(--error-color);
  text-align: center;
}

.border-red {
  border: 3px solid var(--error-color);
}

.margin-minus-15 {
  margin: -15px;
}

.padding-15 {
  padding: 15px;
}

.url-limit {
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-word;
  hyphens: auto;
}

.folder-group-header .folder-actions button {
  position: relative;
  top: 7px;
  right: 10px;
}

.ng2-datetime-picker-wrapper {
  margin-top: 3px;
}

bh-r-checkbox:first-of-type, .checkbox-container:first-of-type {
  padding-top: 8px;
}

.plus-check .input-group .btn {
  position: absolute;
  z-index: 2;
  top: 23px;
  right: 0px;
}

.list-inline > ul.doc-list {
  display: flex;
  list-style: none;
  padding-left: 0;
}

.list-inline > ul.doc-list > li {
  padding-right: 10px;
}

.pull-bureau bh-r-checkbox:first-of-type {
  padding-top: 25px;
}

.rentroll-table td.btn2-col {
  padding: 12px 10px !important;
}

.rentroll-table td bh-r-checkbox:last-of-type {
  padding-bottom: 3px;
}

.rentroll-table .spacer-left-15 {
  padding-top: 11px;
}

.rentroll-table .icon-right + span {
  position: absolute;
  right: 7px;
  top: 5px;
}

.rentroll-table .input-group .btn {
  position: absolute;
  right: 0px;
  top: 1px;
  font-size: 1.4em;
  width: 28px;
}

.rentroll-table .input-group-append .btn {
  height: 28px;
}

.repository-file-upload {
  position: absolute;
  top: 19px;
  right: 0px;
  z-index: 2;
}

.repository-file-upload .btn {
  height: 36px !important;
}

.send-email-btn-align {
  position: relative;
  top: -50px;
  right: 0px;
  text-align: right;
}

.margin-right {
  margin-right: 5px;
}

.action-container {
  padding: 30px 22px 0;
}

.send-email-btn-align .message-builder-actions {
  position: absolute;
  top: -26px;
  right: 0px;
  bottom: 0;
  z-index: 2;
  text-align: right;
}

.lookup-tbl-lft, .lookup-tbl-rgt {
  width: auto !important;
}

.div-greyed {
  opacity: 0.6;
}

.form-section-header .btn-container {
  width: max-content;
}

.btn-white2:disabled,
.btn-white2.disabled {
  background-color: var(--disabled-color) !important;
}

.contact-card-header > ul.nav > li {
  width: 50%;
}

.contact-card .nav > li > a {
  text-align: center;
  border-radius: 4px !important;
}

.contact-card .nav-pills > li.active > a {
  background-color: var(--primary-color);
  color: #fff;
}

.cp-modal .tooltip-tl span {
  position: absolute;
  right: 22px;
  top: 30px;
}

.cp-modal .modal-header .close {
  padding: 0;
}

.cp-modal .fa-envelope-o {
  top: 20px;
}

.list-view .header-panel {
  z-index: auto;
}

#navMenuAdministration .dropdown-menu-end {
  transform: translate3D(0px, 47px, 0px) !important;
}

.tooltip-tr span {
  top: 0;
  left: 0;
}

.banker-map-field.input-group .btn {
  position: absolute;
  z-index: 2;
  right: 0px;
}

.banker-map-field .input-group-append .btn {
  height: 36px;
}

.highcharts-container {
  display: flex;
  justify-content: center;
}

.position-workspace-field .field-visibility-btn {
  position: absolute;
  right: 15px;
}

.position-workspace-field {
  padding: 15px;
  position: relative;
  cursor: pointer;
}

.position-workspace-field .field-visibility-btn {
  display: none;
}

.position-workspace-field:hover .field-visibility-btn {
  display: block;
}

.lending-icon-text-owner strong {
  display: block;
  color: var(--primary-color);
  margin-top: -10px;
  text-align: center;
  font-size: 13px;
}

.banker .sticky-panel {
  position: sticky;
  top: 110px;
  z-index: 999;
}
.banker .banker-application-configuration-controls .application-pages {
  position: sticky;
  z-index: 700;
}
.banker .banker-application-configuration-controls .application-sections {
  position: sticky;
  z-index: 650;
}
.banker .banker-application-configuration-controls .z-index-send-back {
  z-index: -1;
}
.banker .text-right {
  text-align: right;
}
.banker .lending-icon-container div:first-child .lending-icon-bar {
  left: 50%;
}
.banker a.card-link:hover {
  color: unset;
}
.banker bh-r-checkbox {
  padding: 4px;
}
.banker .card {
  border: 1px solid var(--grayscale-color-lighten-25percent);
  border-radius: 4px;
  margin-bottom: 15px;
  padding: 10px;
  box-shadow: var(--dark-whisper-gray) 2px 2px 6px;
}
.banker .card h2, .banker .card .h2 {
  margin-top: unset;
}
.banker .card h3, .banker .card .h3 {
  margin-top: unset;
}
.banker .card h6, .banker .card .h6 {
  margin-top: 0;
}
.banker .status-info {
  color: var(--secondary-color2);
}
.banker .equal {
  display: flex;
  display: -webkit-flex;
  flex-wrap: wrap;
}
.banker .application-card {
  background: var(--grayscale-white-color-lighten-2percent);
  border: solid 1px var(--primary-light1-color);
  padding: 1rem 1.5rem;
  border-radius: 4px;
}
.banker .application-card .form-section-header h3 span, .banker .application-card .form-section-header .h3 span {
  background: var(--grayscale-white-color-lighten-2percent);
}
.banker .modal-body .form-section-header h3 span, .banker .modal-body .form-section-header .h3 span {
  background: white;
}
.banker .tile-selection {
  background: var(--white-color);
  border-radius: 4px;
  border: 1px solid var(--primary-white-color-darken-2percent);
  box-shadow: 5px 5px 10px var(--grayscale-light-color-darken-7percent);
  padding: 40px 8px;
  cursor: pointer;
  margin-bottom: 10px;
}
.banker .tile-selection p.bold {
  text-align: center;
  margin-bottom: 0;
}
.banker .row.equal {
  display: flex;
  display: -webkit-flex;
  flex-wrap: wrap;
}
.banker .tile-selection.tile-selected {
  box-shadow: inset var(--primary-color-darken-7percent) 4px 4px 6px;
  border: 1px solid var(--primary-white-color-darken-6percent);
  background: var(--primary-color);
  color: var(--white-color);
}
.banker button.tile-selection {
  width: 100%;
}
.banker .card-link .card:hover, .banker .tile-selection:hover {
  box-shadow: var(--primary-white-color-darken-2percent) 4px 4px 6px;
  color: var(--primary-color3);
}
.banker .tile-selected:hover {
  background-color: var(--primary-light1-color);
  border-color: var(--primary-light1-color-darken-5percent);
  color: var(--white-color);
}
.banker .list-group-item {
  border-top: unset;
  border-left: unset;
  border-right: unset;
  background-color: unset;
  border-bottom: #ddd 1px solid;
}
.banker .list-group-item a b:hover {
  color: var(--primary-color3);
}
.banker .app-page-header {
  color: var(--primary-color);
}
.banker .help-link i {
  margin-left: 8px;
}
.banker .mb-5 {
  margin-bottom: 5px;
}
.banker .application-page-container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}
.banker .application-page-container .page {
  display: flex;
  text-align: center;
  cursor: pointer;
  position: relative;
}
.banker .application-page-container .page .application-page-bar {
  width: 100px;
  height: 5px;
  background-color: var(--primary-light1-color-darken-5percent);
  position: absolute;
  bottom: 44px;
  z-index: -1000;
  margin-left: 50px;
}
.banker .application-page-container .page-icon {
  min-width: 100px;
}
.banker .application-page-container .page-icon .fa-circle {
  color: var(--grayscale-light-color);
}
.banker .application-page-container .page-icon i {
  color: white;
  text-shadow: 1px 2px 6px rgba(0, 0, 0, 0.21);
}
.banker .application-page-container .page-icon .fa-stack-1x {
  color: white !important;
}
.banker .application-page-container .page-icon .page-title-text {
  font-weight: 700;
  text-align: center;
}

.page-icon-complete .fa-circle {
  color: var(--primary-color3) !important;
}

.page div:last-child .application-page-bar {
  width: 0 !important;
}

.page-icon.page-icon-active .fa-circle.fa-stack-2x {
  color: var(--primary-light1-color-darken-15percent) !important;
}

.sidebar-has-menu .nav-desktop .fa-book {
  transform: rotate(10deg);
}

table.spreadTable i.fa-star-o {
  font-size: 0.6875rem;
  margin-left: 2px;
}
table.spreadTable .spread-table-button {
  margin-left: 30px;
}
table.spreadTable .line-number {
  margin-left: 6px;
}
table.spreadTable .tooltip-br.overflow-visible {
  vertical-align: bottom;
}

.font-weight-bold {
  font-weight: 700;
}

.banker-parent-container {
  position: relative;
}

.banker-parent-container input {
  width: 100%;
}

.banker-parent-container {
  position: relative;
}

.banker-parent-container input {
  width: 100%;
}

.banker-search-results {
  background: white;
  overflow-y: hidden;
  position: absolute;
  left: 0;
  right: 0;
  opacity: 0;
  padding: 3px;
  overflow-x: hidden;
}

.banker-search-results.opened {
  opacity: 1;
  transform: translateY(0px);
  z-index: 999;
}

.banker-form-group {
  margin-bottom: -15px !important;
}

.banker-blue-background {
  vertical-align: middle;
  text-align: center;
  background: var(--primary-white-color-darken-2percent) linear-gradient(to bottom, var(--primary-white-color), var(--primary-white-color) 50%, var(--primary-white-color-darken-6percent));
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.banker-tiles-move {
  background: var(--primary-white-color-darken-2percent) linear-gradient(to bottom, var(--primary-white-color), var(--primary-white-color-darken-6percent));
  cursor: move !important;
}
.banker-tiles-move:before, .banker-tiles-move:after {
  font-family: "FontAwesome", sans-serif;
  content: "\f142";
  font-size: 18px;
  color: var(--primary-light1-color);
}
.banker-tiles-move:after {
  left: 16px;
}
.banker-tiles-move:hover::before, .banker-tiles-move:hover::after {
  color: var(--primary-color);
}

/* Old Colors

$color0: #ededed; // white gray
$color1: rgb(190,190,190); // #bebebe - light gray
$color2: rgb(80,80,80); // #505050 - gray
$color3: rgb(207,214,224); // #cfd6e0 - white blue
$color4: rgb(159,172,193); // #9facc1 - light blue
$color5: rgb(88,111,146); // #586f92 - mid blue
$color6: rgb(15,48,99); // #1B3D6D - dark blue
$color7: rgb(57,213,31); // #39d51f - green
$color8: rgb(52,197,26); // #34C51A - dark green
*/
/*Theming*/
:root {
  --loading-login-screen-background:
          linear-gradient(to right, rgba(15, 48, 99, 0) 0%, rgba(75, 107, 155, .4) 25%, rgba(168, 187, 215, .4) 42%, rgba(168, 187, 215, .4) 58%, rgba(75, 107, 155, .4) 75%, rgba(15, 48, 99, 0) 100%),
          url(".././assets/images/pattern-block-lines.png") repeat;
  --loading-screen-logo: url(".././assets/images/bakerhill-nextgen-logo-white.png");
  --login-box-logo: url(".././assets/images/bakerhill-nextgen-logo.png");
  --primary-dark-color: #1B3D6D;
  --primary-color: #456aa1;
  --primary-color-darken-7percent: #3a5a88;
  --primary-color-darken-11percent: #34507a;
  --primary-color-lighten-10percent: #5f84ba;
  --primary-color-lighten-20percent: #829fca;
  --primary-color-lighten-40percent: #cad6e8;
  --primary-light1-color: #a6bad9;
  --primary-light1-color-darken-5percent:#94acd1;
  --primary-light1-color-darken-10percent: #829eca;
  --primary-light1-color-darken-15percent: #7090c2;
  --primary-light1-color-lighten-6percent: #bbcbe2;
  --primary-white-color: #e6ecf2;
  --primary-white-color-darken-2percent: #dfe7ef;
  --primary-white-color-darken-6percent: #d2dde8;
  --primary-white-color-lighten-2percent: #edf1f5;
  --primary-white-color-lighten-4percent: #f3f6f9;
  --primary-color2: #cddc29;
  --primary-color3: #81BD41;
  --primary-color3-lighten-40percent: #e5f2d8;
  --primary-color3-darken-5percent: #74aa3a;
  --primary-dark2-color: #689834;
  --primary-dark2-color-darken-10percent: #4e7227;
  --primary-light2-color: #e6f2d9;
  --primary-light2-color-darken-2percent: #e1efd1;
  --primary-light2-color-darken-6percent: #d7eac2;
  --primary-light2-color-darken-10percent: #cde5b3;
  --grayscale-color: #58595B;
  --grayscale-color-lighten-25percent: #98999b;
  --grayscale-light-color: #bdbdc2;
  --grayscale-light-color-darken-8percent: #a8a8ae;
  --grayscale-light-color-lighten-7percent: #d0d0d3;
  --grayscale-white-color: #ebebed;
  --grayscale-white-color-darken-5percent: #dedee1;
  --grayscale-white-color-darken-8percent: #d6d6da;
  --grayscale-white-color-lighten-2percent: #f0f0f2;
  --grayscale-white-color-lighten-5percent: #f8f8f9;
  --white-color: #ffffff;
  --black-color: #000000;
  --secondary-dark-color: #007299;
  --secondary-color: #77256c;
  --secondary-color2: #f68d29;
  --secondary-color3: #ffd240;
  --secondary-color3-darken-3percent: #ffce31;
  --secondary-color3-lighten-17percent: #ffe697;
  --secondary-color3-lighten-27percent: #fff2ca;
  --secondary-bright-color: #ece815;
  --error-color: #cc0000;
  --error-color-darken-10percent: #990000;
  --error-color-lighten-50percent: #ffcccc;
  --error-color-lighten-55percent: #ffe6e6;
  --error-light-color: #ffe6e6;
  --error-light-color-darken-5percent: #ffcdcd;
  --disabled-color: #abbcd5;
  --disabled-color2: #9badca;
  --disabled-color3: #a7d17a;
  --default-sprite-position: top left;
  --active-hover-sprite-position: top right;
  /*remaing colors*/
  --snow-gray: #fcfcfc;
  --light-gainsboro-gray: #f7f7f7;
  --white-smoke: #f4f4f4;
  --very-light-gray: #f0f0f0;
  --light-pink: #febbbb;
  --light-yellow: #ffffe5;
  --orange-peel: #ff9900;
  --electric-yellow: #ffff00;
  --yellow-green: #effadb;
  --whisper-gray: #ededed;
  --dark-whisper-gray: #eeeeee;
  --white-dark-smoke: #e6e6e6;
  --gainsboro-gray: #e0e0e0;
  --medium-gray: #cccccc;
  --silver: #c6c6c6;
  --silver-very-light: #b9b9b9;
  --silver-light: #bbbbbb;
  --blue-smoke: #eef1f7;
  --ivory-red: #f8f8f2;
  --spun-pearl: #afafb6;
  --very-light-blue: #e6ebf5;
  --dark-gainsboro-gray: #d9d9d9;
  --bright-gainsboro-gray: #dddddd;
  --hawakes-blue: #ccd6eb;
  --blue-violet: #9933ff;
  --nobel-gray: #999999;
  --light-danube-blue: #89a4cd;
  --matte-gray: #888888;
  --light-matte-gray: #787878;
  --dark-gray: #777777;
  --raven-gray: #707275;
  --danube-blue: #6685c2;
  --dim-gray: #666666;
  --davy-grey: #555555;
  --analog-blue: #435f8b;
  --charcoal-gray: #444444;
  --mariner-blue: #335cad;
  --dark-charcoal-gray: #333333;
  --green-waterloo: #23241f;
  --nero-dark: #222222;
  --smalt-blue: #003399;
  --deep-green: #008a00;
  --navy-blue: #0066cc;
  --bondi-blue: #0085c7;
  --violation-text: #cc0000;
  --violation-bg: #ffe6e6;
  --warning-text: #f68d29;
  --warning-bg: #fff2ca;
  --highcharts-color-2: #cddc29;
  --highcharts-color-3: #007299;
  --highcharts-color-6: #689834;
  --highcharts-color-7: #a6bad9;
}

[data-theme=fiserv] {
  --loading-login-screen-background: #666666;
  --loading-screen-logo: url(".././assets/images/Fiserv-logo-1200x603.png");
  --login-box-logo: url(".././assets/images/Fiserv-logo-1200x603.png");
  --primary-dark-color: #333333;
  --primary-color: #666666;
  --primary-color-darken-7percent: #545454;
  --primary-color-darken-11percent: #4a4a4a;
  --primary-color-lighten-10percent: gray;
  --primary-color-lighten-20percent: #999999;
  --primary-color-lighten-40percent: #cccccc;
  --primary-light1-color: #bfbfbf;
  --primary-light1-color-darken-5percent: #b2b2b2;
  --primary-light1-color-darken-10percent: #a6a6a6;
  --primary-light1-color-darken-15percent: #999999;
  --primary-light1-color-lighten-6percent: #cecece;
  --primary-white-color: #efefef;
  --primary-white-color-darken-2percent: #eaeaea;
  --primary-white-color-darken-6percent: #e0e0e0;
  --primary-white-color-lighten-2percent: #f4f4f4;
  --primary-white-color-lighten-4percent: #f9f9f9;
  --primary-color2: #4695d1;
  --primary-color3: #ff6600;
  --primary-color3-lighten-40percent: #ffe0cc;
  --primary-color3-darken-5percent: #e65c00;
  --primary-dark2-color: #ea6b15;
  --primary-dark2-color-darken-10percent: #bb5611;
  --primary-light2-color: #f2d3c4;
  --primary-light2-color-darken-2percent: #f0cdbc;
  --primary-light2-color-darken-6percent: #ecc0ab;
  --primary-light2-color-darken-10percent: #e9b49a;
  --grayscale-color: #666666;
  --grayscale-color-lighten-25percent: #a6a6a6;
  --grayscale-light-color: #e0e0e0;
  --grayscale-light-color-darken-8percent: #cccccc;
  --grayscale-light-color-lighten-7percent: #f2f2f2;
  --grayscale-white-color: #e0e0e0;
  --grayscale-white-color-darken-5percent: lightgray;
  --grayscale-white-color-darken-8percent: #cccccc;
  --grayscale-white-color-lighten-2percent: #e5e5e5;
  --grayscale-white-color-lighten-5percent: #ededed;
  --white-color: #ffffff;
  --black-color: #0e0e0e;
  --secondary-dark-color: #007299;
  --secondary-color: #77256c;
  --secondary-color2: #81bd41;
  --secondary-color3: #ffd240;
  --secondary-color3-darken-3percent: #ffce31;
  --secondary-color3-lighten-17percent: #ffe697;
  --secondary-color3-lighten-27percent: #fff2ca;
  --secondary-bright-color: #ece815;
  --error-color: #dd3435;
  --error-color-darken-10percent: #be2021;
  --error-color-lighten-50percent: white;
  --error-color-lighten-55percent: white;
  --error-light-color: #eabec0;
  --error-light-color-darken-5percent: #e4abad;
  --disabled-color: #c9c9c9;
  --disabled-color2: #c9c9c9;
  --disabled-color3: #dd9e73;
  --default-sprite-position: bottom left;
  --active-hover-sprite-position: bottom right;
  /*remaing colors*/
  --snow-gray: #fcfcfc;
  --light-gainsboro-gray: #f7f7f7;
  --white-smoke: #f4f4f4;
  --very-light-gray: #f0f0f0;
  --light-pink: #febbbb;
  --light-yellow: #ffffe5;
  --orange-peel: #ff9900;
  --electric-yellow: #ffff00;
  --yellow-green: #effadb;
  --whisper-gray: #ededed;
  --dark-whisper-gray: #eeeeee;
  --white-dark-smoke: #e6e6e6;
  --gainsboro-gray: #e0e0e0;
  --medium-gray: #cccccc;
  --silver: #c6c6c6;
  --silver-very-light: #b9b9b9;
  --silver-light: #bbbbbb;
  --blue-smoke: #eef1f7;
  --ivory-red: #f8f8f2;
  --spun-pearl: #afafb6;
  --very-light-blue: #e6ebf5;
  --dark-gainsboro-gray: #d9d9d9;
  --bright-gainsboro-gray: #dddddd;
  --hawakes-blue: #ccd6eb;
  --blue-violet: #9933ff;
  --nobel-gray: #999999;
  --light-danube-blue: #89a4cd;
  --matte-gray: #888888;
  --light-matte-gray: #787878;
  --dark-gray: #777777;
  --raven-gray: #707275;
  --danube-blue: #6685c2;
  --dim-gray: #666666;
  --davy-grey: #555555;
  --analog-blue: #435f8b;
  --charcoal-gray: #444444;
  --mariner-blue: #335cad;
  --dark-charcoal-gray: #333333;
  --green-waterloo: #23241f;
  --nero-dark: #222222;
  --smalt-blue: #003399;
  --deep-green: #008a00;
  --navy-blue: #0066cc;
  --bondi-blue: #0085c7;
  --violation-text: #cc0000;
  --violation-bg: #ffe6e6;
  --warning-text: #f68d29;
  --warning-bg: #fff2ca;
  --highcharts-color-2: #ffa366;
  --highcharts-color-3: #777777;
  --highcharts-color-6: #e65c00;
  --highcharts-color-7: #e0e0e0;
}

/*Sprite Positions*/
/*Images*/
/*Primary*/
/*Grayscale*/
/*Secondary*/
/*disabled*/
/*remaing colors*/
.darkblue {
  color: var(--primary-dark-color);
}

.blue {
  color: var(--primary-color);
}

.lightblue {
  color: var(--primary-light1-color);
}

.whiteblue {
  color: var(--primary-white-color);
}

.yellowgreen {
  color: var(--primary-color2);
}

.green {
  color: var(--primary-color3) !important;
}

.darkgreen {
  color: var(--primary-dark2-color);
}

.lightgreen {
  color: var(--primary-light2-color);
}

.gray {
  color: var(--grayscale-color);
}

.lightgray {
  color: var(--grayscale-light-color);
}

.whitegray {
  color: var(--grayscale-white-color);
}

.darkcyan {
  color: var(--secondary-dark-color);
}

.purple {
  color: var(--secondary-color);
}

.orange {
  color: var(--secondary-color2) !important;
}

.yellow {
  color: var(--secondary-color3);
}

.brightyellow {
  color: var(--secondary-bright-color);
}

.red {
  color: var(--error-color) !important;
}

.lightred {
  color: var(--error-light-color);
}

.white {
  color: var(--white-color) !important;
}

.darkblue-bg {
  background-color: var(--primary-dark-color);
}

.blue-bg {
  background-color: var(--primary-color) !important;
}

.lightblue-bg {
  background-color: var(--primary-light1-color);
}

.whiteblue-bg {
  background-color: var(--primary-white-color);
}

.yellowgreen-bg {
  background-color: var(--primary-color2);
}

.green-bg {
  background-color: var(--primary-color3);
}

.darkgreen-bg {
  background-color: var(--primary-dark2-color);
}

.lightgreen-bg {
  background-color: var(--primary-light2-color);
}

.gray-bg {
  background-color: var(--grayscale-color);
}

.lightgray-bg {
  background-color: var(--grayscale-light-color);
}

.whitegray-bg {
  background-color: var(--grayscale-white-color);
}

.darkcyan-bg {
  background-color: var(--secondary-dark-color);
}

.purple-bg {
  background-color: var(--secondary-color);
}

.orange-bg {
  background-color: var(--secondary-color2) !important;
}

.yellow-bg {
  background-color: var(--secondary-color3) !important;
}

.brightyellow-bg {
  background-color: var(--secondary-bright-color) !important;
}

.red-bg {
  background-color: var(--error-color) !important;
}

.lightred-bg {
  background-color: var(--error-light-color) !important;
}

.btn {
  display: inline-block;
  margin-bottom: 0;
  font-weight: normal;
  text-align: center;
  vertical-align: middle;
  touch-action: manipulation;
  cursor: pointer;
  border: 1px solid transparent;
  white-space: nowrap;
  padding: 10px;
  font-size: 0.875rem;
  line-height: 1.428571429;
  border-radius: 4px;
  user-select: none;
  transition-duration: 0.2s;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.08), 0 1px 1px rgba(0, 0, 0, 0.16);
  outline: none !important;
}
.btn .fa-text {
  margin-right: 6px;
}

.btn-margin {
  margin: 0 4px;
}

.btn-sm, .btn-group-sm > .btn {
  padding: 9px 10px 8px;
  font-weight: bold;
  font-size: 0.75rem;
}

.btn-lg, .btn-group-lg > .btn {
  padding: 12px;
  font-size: 0.9625rem;
}

/*Used for fa-lg*/
.btn-icon {
  padding: 7px 5px;
}

/*Used for fa-lg within table rows*/
.btn-icon-sm {
  padding: 2px 0;
}
.btn-icon-sm .fa-lg {
  font-size: 1.125em;
  min-width: 23px;
}

/*Used for fa-2x*/
.btn-icon-lg {
  padding: 5px 12px 4px;
}

.btn-unstyled {
  border: none;
  background-color: transparent;
  padding: 0;
  margin: 0;
  text-align: inherit;
  color: inherit !important;
  box-shadow: none;
}

.btn-link .btn-unstyled {
  color: var(--primary-color) !important;
}
.btn-link:hover, .btn-link:focus {
  color: var(--primary-color3) !important;
}

.btn.fa {
  box-shadow: none;
}
.btn.fa.fa-info-circle {
  margin-left: 8px !important;
}

.btn-default,
.btn-blue, .dropdown-menu .btn-blue {
  background-color: var(--primary-color);
  color: white;
  border-color: var(--primary-color);
}
.btn-default .overlay-icon-blue,
.btn-blue .overlay-icon-blue, .dropdown-menu .btn-blue .overlay-icon-blue {
  position: absolute;
  top: 17px;
  right: 1px;
  -webkit-text-fill-color: white;
  text-shadow: -1px -1px 0 var(--primary-color), 1px -1px 0 var(--primary-color), -1px 1px 0 var(--primary-color), 1px 1px 0 var(--primary-color);
}
.btn-default:hover,
.btn-blue:hover, .dropdown-menu .btn-blue:hover {
  background-color: var(--primary-light1-color);
  border-color: var(--primary-light1-color-darken-5percent);
}
.btn-default:hover .overlay-icon-blue,
.btn-blue:hover .overlay-icon-blue, .dropdown-menu .btn-blue:hover .overlay-icon-blue {
  -webkit-text-fill-color: white;
  text-shadow: -1px -1px 0 var(--primary-light1-color), 1px -1px 0 var(--primary-light1-color), -1px 1px 0 var(--primary-light1-color), 1px 1px 0 var(--primary-light1-color);
}
.btn-default:focus,
.btn-blue:focus, .dropdown-menu .btn-blue:focus {
  box-shadow: 0 0 0 1px var(--primary-dark-color);
}
.btn-default:active:hover, .btn-default:active:focus,
.btn-blue:active:hover,
.btn-blue:active:focus, .dropdown-menu .btn-blue:active:hover, .dropdown-menu .btn-blue:active:focus {
  background-color: var(--primary-dark-color);
  border-color: var(--primary-dark-color);
}
.btn-default:disabled, .btn-default.disabled,
.btn-blue:disabled,
.btn-blue.disabled, .dropdown-menu .btn-blue:disabled, .dropdown-menu .btn-blue.disabled {
  background-color: var(--disabled-color2) !important;
  color: var(--white-color) !important;
  border-color: var(--disabled-color2) !important;
}
.btn-default:disabled .overlay-icon-blue, .btn-default.disabled .overlay-icon-blue,
.btn-blue:disabled .overlay-icon-blue,
.btn-blue.disabled .overlay-icon-blue, .dropdown-menu .btn-blue:disabled .overlay-icon-blue, .dropdown-menu .btn-blue.disabled .overlay-icon-blue {
  -webkit-text-fill-color: var(--white-color);
  text-shadow: -1px -1px 0 var(--disabled-color2), 1px -1px 0 var(--disabled-color2), -1px 1px 0 var(--disabled-color2), 1px 1px 0 var(--disabled-color2);
}

.dropdown-menu .btn-sm.btn-blue, .dropdown-menu .btn-group-sm > .btn-blue.btn {
  width: unset !important;
  margin-left: 5px !important;
}

.btn-white2:hover {
  color: white;
  background-color: var(--primary-color);
  border-color: var(--primary-color);
}

.btn-white,
.btn-white2 {
  background-color: white;
  color: var(--primary-color);
  border-color: var(--primary-color);
}
.btn-white .overlay-icon,
.btn-white2 .overlay-icon {
  position: absolute;
  top: 17px;
  right: 1px;
  -webkit-text-fill-color: var(--primary-color);
  text-shadow: -1px -1px 0 var(--white-color), 1px -1px 0 var(--white-color), -1px 1px 0 var(--white-color), 1px 1px 0 var(--white-color);
}
.btn-white:hover,
.btn-white2:hover {
  color: var(--primary-color);
  background-color: var(--primary-white-color);
  border-color: var(--primary-color);
}
.btn-white:hover .overlay-icon,
.btn-white2:hover .overlay-icon {
  -webkit-text-fill-color: var(--primary-color);
  text-shadow: -1px -1px 0 var(--primary-white-color), 1px -1px 0 var(--primary-white-color), -1px 1px 0 var(--primary-white-color), 1px 1px 0 var(--primary-white-color);
}
.btn-white:focus,
.btn-white2:focus {
  box-shadow: 0 0 0 1px var(--primary-color);
}
.btn-white:active:hover, .btn-white:active:focus,
.btn-white2:active:hover,
.btn-white2:active:focus {
  border-color: var(--primary-color);
}
.btn-white.btn-icon:hover, .btn-white.btn-icon:focus,
.btn-white2.btn-icon:hover,
.btn-white2.btn-icon:focus {
  color: var(--primary-color3);
  background-color: white;
}
.btn-white.btn-icon:hover .overlay-icon, .btn-white.btn-icon:focus .overlay-icon,
.btn-white2.btn-icon:hover .overlay-icon,
.btn-white2.btn-icon:focus .overlay-icon {
  -webkit-text-fill-color: var(--primary-color3);
  text-shadow: -1px -1px 0 var(--white-color), 1px -1px 0 var(--white-color), -1px 1px 0 var(--white-color), 1px 1px 0 var(--white-color);
}
.btn-white:hover,
.btn-white2:hover {
  color: white;
  background-color: var(--primary-color);
  border-color: var(--primary-color);
}
.btn-white:disabled, .btn-white.disabled,
.btn-white2:disabled,
.btn-white2.disabled {
  color: var(--primary-color) !important;
  border-color: var(--disabled-color) !important;
}
.btn-white:disabled .overlay-icon, .btn-white.disabled .overlay-icon,
.btn-white2:disabled .overlay-icon,
.btn-white2.disabled .overlay-icon {
  -webkit-text-fill-color: var(--primary-color);
  text-shadow: -1px -1px 0 var(--disabled-color), 1px -1px 0 var(--disabled-color), -1px 1px 0 var(--disabled-color), 1px 1px 0 var(--disabled-color);
}
.btn-white:disabled .overlay-icon:hover, .btn-white.disabled .overlay-icon:hover,
.btn-white2:disabled .overlay-icon:hover,
.btn-white2.disabled .overlay-icon:hover {
  -webkit-text-fill-color: var(--primary-color);
  text-shadow: -1px -1px 0 var(--disabled-color), 1px 1px 0 var(--disabled-color), -1px 1px 0 var(--disabled-color), 1px 1px 0 var(--disabled-color) !important;
}

.btn-red-solid {
  background-color: var(--error-color);
  color: white;
  border-color: var(--error-color);
}

.btn-red {
  background-color: white;
  color: var(--error-color);
  border-color: var(--error-color);
}
.btn-red:hover {
  color: var(--error-color);
  background-color: var(--primary-white-color);
  border-color: var(--error-color);
}
.btn-red:focus {
  box-shadow: 0 0 0 1px var(--error-color);
}
.btn-red:active:hover, .btn-red:active:focus {
  border-color: var(--error-color);
}
.btn-red.btn-icon:hover, .btn-red.btn-icon:focus {
  color: var(--primary-color3);
  background-color: white;
}
.btn-red:hover {
  color: white;
  background-color: var(--error-color);
  border-color: var(--error-color);
}
.btn-red:disabled, .btn-red.disabled {
  color: var(--error-color) !important;
  border-color: var(--disabled-color) !important;
  background-color: var(--disabled-color) !important;
}

.btn-green {
  background-color: var(--primary-color3);
  color: white;
  border-color: var(--primary-dark2-color);
}
.btn-green:hover {
  background-color: var(--primary-dark2-color);
}
.btn-green:focus {
  box-shadow: 0 0 0 1px var(--primary-dark2-color-darken-10percent);
}
.btn-green:active:hover, .btn-green:active:focus {
  background-color: var(--primary-dark2-color-darken-10percent);
  border-color: var(--primary-dark2-color-darken-10percent);
}
.btn-green:disabled, .btn-green.disabled {
  background-color: var(--disabled-color3) !important;
  color: var(--white-color) !important;
  border-color: var(--disabled-color3) !important;
}

.show > .dropdown-toggle.btn-blue:focus,
.show > .dropdown-toggle.btn-blue:hover {
  background-color: var(--primary-dark-color);
  border-color: var(--primary-dark-color);
}

.btn-badge {
  padding: 0 1px;
  border-radius: 4px;
  min-width: 20px;
  text-align: center;
  display: inline-block;
  font-weight: bold;
  margin-left: 5px;
}

.btn-badge-violation .btn-badge {
  background-color: var(--violation-bg);
  color: var(--violation-text);
}

.btn-badge-warning .btn-badge {
  background-color: var(--warning-bg);
  color: var(--warning-text);
}

.btn-badge-information .btn-badge {
  background-color: var(--primary-white-color-lighten-2percent);
  color: var(--primary-color);
}

.btn-corner .btn-unstyled {
  position: absolute !important;
  top: 10px;
  right: 10px;
  color: var(--primary-color) !important;
}
.btn-corner + .btn {
  right: 40px;
}
.btn-corner:hover, .btn-corner:focus {
  color: var(--primary-color3) !important;
}

.inline-btns .btn {
  margin-right: 10px;
}
.inline-btns .btn:last-of-type {
  margin-right: 0;
}

button.scroll-top-btn,
button.scroll-bottom-btn {
  background: var(--primary-color3);
  width: 34px;
  height: 34px;
  margin-left: 5px;
  background-color: var(--primary-color3);
  border-radius: 3px;
  padding: 0;
  text-align: inherit;
  border: 0;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.1);
}
button.scroll-top-btn .btn-unstyled,
button.scroll-bottom-btn .btn-unstyled {
  width: 34px;
  height: 34px;
  margin-left: 5px;
  background-color: var(--primary-color3);
  border-radius: 3px;
}
button.scroll-top-btn:hover,
button.scroll-bottom-btn:hover {
  background-color: var(--primary-dark2-color);
}
button.scroll-top-btn i,
button.scroll-bottom-btn i {
  color: white;
  font-size: 1.375rem;
  padding: 4px 6px 4px 7px;
}

.close {
  float: right;
  font-size: 1.09375rem;
  color: var(--primary-color);
  opacity: 0.6;
  cursor: pointer;
  margin-left: 10px;
}
.close:hover, .close:focus {
  opacity: 1;
}
.close button {
  padding: 0;
  cursor: pointer;
  background: transparent;
  border: 0;
  -webkit-appearance: none;
}

.min-width50 {
  min-width: 50px;
}

.page-scroll-container {
  position: fixed;
  bottom: 5px;
  right: 15px;
  z-index: 999;
}

button.close {
  padding: 0;
  cursor: pointer;
  background: transparent;
  border: 0;
  -webkit-appearance: none;
}

.input-group-append .icon-btn {
  border-bottom: none;
  margin-right: 8px;
  margin-left: 0px !important;
}
.input-group-append .btn {
  height: 39px;
}

.clear-field-btn {
  border: none;
  margin: 0;
  text-align: inherit;
  color: inherit !important;
  box-shadow: none;
  position: absolute;
  padding: 2px;
  right: 50px;
  bottom: 4px;
  z-index: 5;
  background-color: #fff;
}

button.info-btn {
  border: none;
  margin: 0;
  text-align: inherit;
  color: var(--primary-color);
  box-shadow: none;
  position: absolute;
  padding: 2px;
  bottom: 0px;
  margin-left: 5px;
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
}

.btn-corner {
  border: none;
  background-color: transparent;
  padding: 0;
  margin: 0;
  text-align: inherit;
  box-shadow: none;
  position: absolute !important;
  top: 10px;
  right: 10px;
  color: var(--primary-color) !important;
}

.bg-danger {
  background: var(--error-light-color);
}

.btn:disabled:hover, .btn:disabled:focus, .btn-white:disabled:hover, .btn-white:disabled:focus, .btn-icon:disabled:hover, .btn-icon:disabled:focus, .btn-white .disabled:hover, .btn-white .disabled:focus, .btn-icon .disabled:hover, .btn-icon .disabled:focus {
  background-color: var(--disabled-color) !important;
}
.btn:disabled:hover .overlay-icon, .btn:disabled:focus .overlay-icon, .btn-white:disabled:hover .overlay-icon, .btn-white:disabled:focus .overlay-icon, .btn-icon:disabled:hover .overlay-icon, .btn-icon:disabled:focus .overlay-icon, .btn-white .disabled:hover .overlay-icon, .btn-white .disabled:focus .overlay-icon, .btn-icon .disabled:hover .overlay-icon, .btn-icon .disabled:focus .overlay-icon {
  -webkit-text-fill-color: var(--primary-color);
  text-shadow: -1px -1px 0 var(--disabled-color), 1px 1px 0 var(--disabled-color), -1px 1px 0 var(--disabled-color), 1px 1px 0 var(--disabled-color) !important;
}

.btn:disabled,
.btn.disabled {
  cursor: not-allowed;
  box-shadow: none;
}

.btn-white.btn-icon.disabled:focus {
  background-color: var(--disabled-color) !important;
}

.btn.btn-blue.btn-icon-stack i:last-child {
  position: relative;
  bottom: -3px;
  left: -7px;
  -webkit-text-fill-color: white;
  text-shadow: -1px -1px 0 var(--primary-color), 1px -1px 0 var(--primary-color), -1px 1px 0 var(--primary-color), 1px 1px 0 var(--primary-color);
}
.btn.btn-blue.btn-icon-stack:hover {
  background-color: var(--primary-light1-color);
  border-color: var(--primary-light1-color-darken-5percent);
}
.btn.btn-blue.btn-icon-stack:hover .overlay-icon-blue {
  -webkit-text-fill-color: var(--primary-color);
  text-shadow: -1px -1px 0 var(--primary-light1-color), 1px -1px 0 var(--primary-light1-color), -1px 1px 0 var(--primary-light1-color), 1px 1px 0 var(--primary-light1-color);
}
.btn.btn-blue.btn-icon-stack:focus {
  box-shadow: 0 0 0 1px var(--primary-dark-color);
}
.btn.btn-blue.btn-icon-stack:active:hover, .btn.btn-blue.btn-icon-stack:active:focus {
  background-color: var(--primary-dark-color);
  border-color: var(--primary-dark-color);
}
.btn.btn-blue.btn-icon-stack:disabled, .btn.btn-blue.btn-icon-stack.disabled {
  background-color: var(--disabled-color2) !important;
  color: var(--white-color) !important;
  border-color: var(--disabled-color2) !important;
}
.btn.btn-blue.btn-icon-stack:disabled .overlay-icon-blue, .btn.btn-blue.btn-icon-stack.disabled .overlay-icon-blue {
  -webkit-text-fill-color: var(--white-color);
  text-shadow: -1px -1px 0 var(--disabled-color2), 1px -1px 0 var(--disabled-color2), -1px 1px 0 var(--disabled-color2), 1px 1px 0 var(--disabled-color2);
}

.btn-input-group {
  border: 1px solid var(--grayscale-light-color);
  border-bottom: 3px solid var(--grayscale-light-color);
  border-left: 0px;
  box-shadow: none;
  border-radius: 0;
  font-size: 0.9625rem;
  min-height: 36px;
  line-height: 26px;
  padding: 4px 4px 0;
  transition: none;
  margin-top: 4px;
}

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}
.input-group > .form-control,
.input-group > .form-select {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
}
.input-group > .form-control:focus,
.input-group > .form-select:focus {
  z-index: 3;
}
.input-group .btn {
  position: relative;
  z-index: 2;
}
.input-group .btn:focus {
  z-index: 3;
}

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}

.input-group-lg > .form-control,
.input-group-lg > .form-select,
.input-group-lg > .input-group-text,
.input-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  border-radius: 0.3rem;
}

.input-group-sm > .form-control,
.input-group-sm > .form-select,
.input-group-sm > .input-group-text,
.input-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  border-radius: 0.2rem;
}

.input-group-lg > .form-select,
.input-group-sm > .form-select {
  padding-right: 3rem;
}

.input-group:not(.has-validation) > :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu),
.input-group:not(.has-validation) > .dropdown-toggle:nth-last-child(n+3) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group.has-validation > :nth-last-child(n+3):not(.dropdown-toggle):not(.dropdown-menu),
.input-group.has-validation > .dropdown-toggle:nth-last-child(n+4) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
  margin-left: -1px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

:root {
  --card-padding: 24px;
  --card-height: 340px;
  --card-skeleton: linear-gradient(lightgrey var(--card-height), transparent 0);
  --title-height: 32px;
  --title-width: 200px;
  --title-position: var(--card-padding) 60px;
  --title-skeleton: linear-gradient(white var(--title-height), transparent 0);
  --desc-line-height: 16px;
  --desc-line-skeleton: linear-gradient(white var(--desc-line-height), transparent 0);
  --desc-line-1-width:230px;
  --desc-line-1-position: var(--card-padding) 122px;
  --desc-line-2-width:180px;
  --desc-line-2-position: var(--card-padding) 145px;
  --blur-width: 200px;
  --blur-size: var(--blur-width) calc(var(--card-height));
}

/*
 * Card Skeleton for Loading
 */
.skeleton {
  height: var(--card-height);
}
.skeleton:empty::after {
  content: "";
  display: block;
  width: 100%;
  height: 100%;
  border-radius: 4px;
  box-shadow: 0 10px 45px rgba(0, 0, 0, 0.1);
  background-image: linear-gradient(90deg, rgba(211, 211, 211, 0) 0, rgba(211, 211, 211, 0.8) 50%, rgba(211, 211, 211, 0) 100%), var(--title-skeleton), var(--desc-line-skeleton), var(--desc-line-skeleton), var(--card-skeleton);
  background-size: var(--blur-size), var(--title-width) var(--title-height), var(--desc-line-1-width) var(--desc-line-height), var(--desc-line-2-width) var(--desc-line-height), 100% 100%;
  background-position: -150% 0, var(--title-position), var(--desc-line-1-position), var(--desc-line-2-position), 0 0;
  background-repeat: no-repeat;
  animation: loading 2s infinite;
}

@keyframes loading {
  to {
    background-position: 350% 0, var(--title-position), var(--desc-line-1-position), var(--desc-line-2-position), 0 0;
  }
}