@import "custom-variables";
.bh-navbar h3 {
    margin-top: 15px;
    margin-bottom: 0;
}

.docprep {

    & .form-group-container {
        padding: 10px 15px;

        &.outlined {
            outline: 1px solid $green;
            background-color: $lightgreen;
        }
    }

    & label.form-group {
        margin-bottom: 0;
    }
}

// Right Sidebar

.right-sidebar {
    position: fixed;
    height: calc(100% - 46px);
    width: 450px;
    top: 46px;
    right: 0;
    overflow-y: auto;
    padding: 25px;
    background-color: $blue-smoke;
}

@media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
    .right-sidebar {
        width: 300px;
    }
}

@media (min-width: $screen-md-min) and (max-width: $screen-md-max) {
    .right-sidebar {
        width: 320px;
    }
}

@media (min-width: $screen-lg-min) and (max-width: $screen-lg-max) {
    .right-sidebar {
        width: 320px;
    }
}

.missing-buttons {
    margin: 25px 0;
}

.missing-buttons button {
    margin-right: 10px;
    width: 100px;

    &.prevbtn,
    &.nextbtn {
        width: 50px;
    }

    & .fa {
        padding: 0 5px;
    }
}


@media (min-width: $screen-sm-min) and (max-width: $screen-lg-max) {
    .missing-buttons button {
        margin-right: 5px;
        width: 70px;

        &.prevbtn,
        &.nextbtn {
            width: 40px;
        }
    }
}

@media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
    .missing-buttons button {
        margin-right: 3px;
        padding: 8px;
        width: 66px;
    }
}

.missing-list ol {
    padding-left: 20px;
}

.missing-list li {
    margin-bottom: 12px;
    font-weight: bold;

    & a {
        color: $blue;
        cursor: pointer;

        &:hover,
        &:focus {
            color: $green !important;
            text-decoration: none;
        }
    }
}