@import "custom-variables";

bh-r-span {
    min-height: 77px;
    display: inline-block;
}


span.required-icon.tooltip-tr.no-margin {
    &:before {
        margin: 0 !important;
    }
}

.upload-text {
    position: absolute;
    overflow: hidden;
    white-space: nowrap;
    width: 100%;
    text-overflow: ellipsis;
    padding-right: 8px;
}

.table-contact-card > tbody > tr > td:first-child.btn3 {
    min-width: 100px;
    width: 100px;
}

.contact-card .contact-card-header button.btn-comm-add {
    position: relative;
    top: 10px;
}

.spacertop20.user-security {
    span.radio-label {
        display: inline;
        padding-right: 20px;
    }
}


.hide-on {
    width: 100%;
    height: 100%;
    opacity: 0.65;
    position: absolute;
    z-index: 99;
    background: $whitegray;
}

.pos-rel {
    position: relative;
}

/****************************** for custom table branch 21579 ******************************/
.table-fixed {
    table-layout: fixed;

    tr {
        td {
            label {
                padding: 0;
                margin: 0;
            }

            bh-r-input {
                label.form-group {
                    padding: 0;
                    margin: 0;

                    input {
                        padding: 0 5px;
                        margin: 0;
                    }
                }
            }
        }

        th {
            &:not(.btn-col).sort {
                button {
                    .sort-icon {
                        &:after {
                            left: 96px;
                        }
                    }

                    .sort-icon-desc {
                        &:after {
                            left: 96px;
                        }
                    }

                    .sort-icon-asc {
                        &:after {
                            left: 96px;
                        }
                    }
                }
            }

            &:not(.btn2-col).sort {
                button {
                    .sort-icon {
                        &:after {
                            left: 96px;
                        }
                    }

                    .sort-icon-desc {
                        &:after {
                            left: 96px;
                        }
                    }

                    .sort-icon-asc {
                        &:after {
                            left: 96px;
                        }
                    }
                }
            }

            &:first-child {
                width: 50px;
            }
        }
    }
}
/****************************** for custom table branch 21579 end ******************************/


.label-right {
    bh-r-input {
        label {
            text-align: right;
            margin-top: 10px;

            input {
                width: 60%;
                float: left;
                margin-top: -8px !important;

                & + .errorMessage {
                    width: 72%;
                    text-align: left;
                }
            }
        }
    }
}

.dropdown-menu {
    .fa-flip-horizontal {
        padding-left: 5px;
    }
}

.highcharts-container {
    overflow: hidden;
}

.appointment-chart-container {

    .highcharts-graph {
        stroke-width: 20px;
        stroke-linecap: butt;
        stroke-linejoin: round;
    }

    .highcharts-plot-band {
        fill-opacity: 1;
        stroke-width: 2;
        stroke: $green;
    }

    .highcharts-xaxis-grid .highcharts-grid-line {
        stroke-width: 1;
        stroke: $whitegray;
    }
}

.print-table {
    table {
        tr {
            td {
                .fa-percent {
                    font-size: 0.75rem;
                    color: $gray;
                    padding-left: 3px;
                }

                .fa-usd {
                    font-size: 0.75rem;
                    color: $gray;
                    padding-right: 2px;
                }
            }
        }
    }
}

#stackingChart {
    .highcharts-color-1 {
        fill: $darkcyan;
        stroke: $darkcyan;
    }

    .hc-green {
        fill: $darkcyan;
        stroke: $darkcyan;

        .highcharts-point {
            fill: $darkcyan;
            stroke: $darkcyan;
        }
    }

    .highcharts-color-0 {
        fill: $orange;
        stroke: $orange;
    }

    .hc-darkblue {
        fill: $orange;
        stroke: $orange;

        .highcharts-point {
            fill: $orange;
            stroke: $orange;
        }
    }

    .highcharts-data-label {
        text {
            fill: $whitegray;
        }
    }
}


.input-group-btn.disabled {
    opacity: 0.65;
    cursor: not-allowed;

    label {
        pointer-events: none;
    }
}


.highcharts-subtitle {
    font-size: $h4-font-size;
}

.spacer-top-75 {
    margin-top: -75px;
}

#print-section {
    h1 {
        color: $darkblue;
    }

    h3 {
        color: $darkblue;
    }

    table.gridtable {
        margin: 20px 0;

        tr {
            th.none {
                background: $blue;
                color: $white;
                border: 0;
            }

            th {
                background: $blue;
                color: $white;
                border: 0;
            }
        }
    }
}

bh-query-node-field {
    bh-r-loading {
        bh-table {
            .flexgrid-treeview {
                .wj-flexgrid {
                    .wj-cell {
                        &:nth-child(odd) {
                            width: calc(100% + 10px) !important;
                            overflow-x: inherit !important;
                        }
                    }
                }
            }
        }
    }
}

bh-user-search-and-select {
    bh-r-modal {
        .modal-body {
            min-height: 240px;

            .flexgrid-single-select.flexgrid-treeview {
                .wj-flexgrid {
                    .wj-group {
                        border-right: 1px solid $lightgray !important;
                        white-space: normal;
                        word-break: break-all;
                    }
                }
            }
        }
    }
}

bh-import-file {
    span.required-icon.tooltip-tr {
        display: inline;
    }
}

.form-section-header.plus-checkbox {
    bh-r-checkbox {
        display: initial;
    }
}

table.table-striped {
    tr.group-header {
        td.columntype-currency {
            &:before {
                display: none;
            }
        }

        td.columntype-percentage {
            &:after {
                display: none;
            }
        }
    }
}

.high-chart-container {
    .highcharts-graph {
        stroke-width: 33px;
        stroke-linecap: square;
    }

    .highcharts-xaxis-grid .highcharts-grid-line {
        stroke-width: 1;
        stroke: $whitegray;
    }
}

.row.flex-wrap {
	.report-box {
     overflow-wrap: break-word;
    }
}
.news-message.fi.unread {
    p {
        overflow-wrap: break-word
    }
    }

.checkbox-disabled {
    cursor: not-allowed;
    button.btn {
        display: block !important;
    pointer-events: none;
        opacity: .65;
    }
}
.checkbox-disable {
    &:first-child {
        .btn {
            display: block !important;
            pointer-events: none;
            opacity: .65;
            cursor: not-allowed;
        }
    }
}

table.fixed-header {
    width: calc(100% - 14px);
    table-layout: fixed;

    tr {
        th.col-order {
            width: 60px !important;
        }
    }
}

table.fixed-body {
    tbody {
        width: 100%;

        tr {
            td.col-order {
                width: 60px !important;
            }
        }
    }

    table-layout: fixed;
}
.pagination-container {
    display: flex;
    display: -webkit-flex;
    flex-wrap: wrap;
    -webkit-flex-wrap: wrap;
    justify-content: space-between;
}

.btn-white:disabled,
.btn-white.disabled {
    cursor: not-allowed;
    opacity: 1;
    background: $white-disabled;
    color: $blue;

    &:hover {
        background-color: $white-disabled!important;
        color: $blue!important;
    }
}

.select-actions {
    .btn-blue:disabled {
        cursor: not-allowed;
        opacity: 1;
        background: $blue-disabled;
        border-color: $blue-disabled;

        .overlay-icon-blue {
            background: $blue-disabled;
        }
    }
}
.dropdown-menu {
    button[disabled] {
        color: $white-disabled !important;
    }
}
.bureau-container{
    .bureau-header {
        padding: 0 12px;
        .header-left {
            float: left;
            width: auto;
        }
        .header-right {
            h4 {
                color: $gray;
                line-height: 1.25;
            }
        }
    }
}
.form-row-align{
    .form-group {
        min-height: 60px;
    }
}
bh-report-list {
    .header-panel {
        .header-right {
            width: auto;
        }
        .header-left{
            width:auto;
        }
    }
}
bh-task-list {
    .header-panel {
        .header-right {
            width: auto;
        }
        .header-left {
            width: auto;
        }
    }
}
.group-reporting {
    table.spreadTable {
        tbody {
            td.form-control-sm {
                overflow: hidden;
            }
        }
    }
}

.btn-blue {
    .fa-user-o {
        & + .overlay-icon-blue {
            top: 16px;
        }
    }
}


.btn-white.btn-contact {
    background-color: var(--primary-color) !important;
    color: #fff !important;
    border-color: var(--primary-color) !important;
}

#sidebar-left-panel {
    .panel-list {
        > li {
            > a {
                > i.fa-user-o {
                    span.overlay-icon {
                        top: 10px;
                        font-size: 1.188rem;
                    }
                }
            }
        }
    }
}

.draggable-area {
    outline: 2px dashed $blue;
}

.draggable-scroll {
    overflow-y: scroll;
    position: relative;
    max-height: calc(100vh - 360px);
}

bh-r-modal{
    .loading-indicator-details-container {
        .loading-indicator-details {
            min-height: 100px;
            & + i {
                top: 0;
            }
        }
    }
}

.width-auto {
    width: auto !important;
}

@media only screen and (max-width: 1262px) {
    .wrap-normal {
        white-space: normal;
    }
}

.vertical-inherit {
    .modal-body {
        overflow-y: inherit !important;
    }
}

bh-r-lending-progress{
    .lending-icon-text {
        strong {
            display: block;
            color: $blue;
        }
    }
}

bh-sql-lending-authority {
    .btn {
        & + .btn {
            margin-left: 5px;
        }
    }
}

bh-integer-lending-authority {
    .btn {
        & + .btn {
            margin-left: 5px;
        }
    }
}

.cell-disabled {
    cursor: not-allowed;
    opacity: .65;
}
.cell-disabled.btn2-col {
    cursor: auto;
    opacity: 1;
}

.sa-table-container.update-statement-table {
    table {
        tbody {
            td.fixed-col {
                line-height: 1.5rem;
            }
        }
    }
}

@media screen and (max-width:991px) {
    .list-inline.pull-left {
        li {
            .filetype-icon {
                margin-left: 16px;
            }
        }
    }
}
.overflow-wrap {
    overflow-wrap: break-word;
    word-wrap: break-word;
    white-space: pre-line;
}

.datepicker-label.focus {
        .ng-touched.ng-dirty.ng-invalid {
            & + ng2-datetime-picker {
                top: 66px !important;
            }
        }
    }

    .height-auto {
        height: auto !important;
    }

.sa-table-container.update-statement-table {
    table {
        tr {
            .blank-cell {
                text-align: center;
                width: 33px;
                min-width: 33px;
                line-height: 1.25rem;
                min-height: 20px;
                vertical-align: top;
                border-right: 2px solid $light-danube-blue;
            }
        }
    }
}

    .static-object {
        position: static !important;
    }

    .height-auto {
        height: auto !important;
    }

    .sa-table-container.update-statement-table {
        table.spreadTable {
            tbody {
                td.fixed-col {
                    .line-title {
                        max-width: 249px;
                    }
                }
            }

            table {
                background: transparent;
                display: table-cell;

                tbody {
                    display: inline;

                    th {
                        &:first-child {
                            padding: 8px 12px;
                        }
                    }

                    td {
                        &:nth-child(2) {
                            display: block !important;
                            padding-left: 8px;
                        }
                    }
                }
            }

            tr.line-type.sub-row {
                table {
                    td {
                        &:first-child {
                            width: 25px;
                            min-width: 25px;
                        }
                    }
                }

                td.fixed-col {
                    &:before {
                        left: 37px;
                        top: 2px;
                        z-index: 9;
                    }
                }
            }
        }

        tr.white-bg {
            th {
                text-align: right;
            }
        }
    }

    .sa-table-container.update-statement-table {
        table {
            table {
                width: 100%;
                table-layout: auto;

                .tooltip-br span {
                    bottom: 80%;
                    left: -40px;
                }
            }
        }
    }

    .white-bg {
        background-color: $white;
    }
.header-panel-table {
    background: $blue linear-gradient(to bottom, $blue, $blue 55%, $blue-darken-7percent);
    border-radius: $border-radius-base;
    min-height: 50px;
    padding: 7px 12px;
    box-shadow: $box-shadow-2;

    .header-left {
        float: left;
        width: calc(100% - 280px);
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .header-right {
        float: right;
        width: 280px;
        text-align: right;
    }

    @media (max-width: $screen-xs-max) {
        .header-left,
        .header-right {
            float: none;
            width: 100%;
            text-align: left;
        }
    }


    .dropdown-menu {
        white-space: nowrap;

        & > li {
            line-height: $line-height-base;

            &:hover,
            &:focus {
                background-color: $whitegray;
            }
        }
    }

    .inline-control {
        display: inline-block;
        margin-right: 10px;
        float: left;
    }

    .inline-control-label {
        color: white!important;

        .inline-control{
            margin-top: 15px;
        }
    }

}
.flexgrid-pagination {
    .stickyHeight {
        [wj-part="root"] {
            overflow-y: auto !important;
        }
        max-height: calc(100vh - 300px);
            .wj-header {
                opacity: 1;
        }
    }
}
.width-100px{
    width:100px;
}
.width-200px{
    width:200px !important;
}
.width-300px{
    width:300px !important;
}
.display-none{
    display:none;
}
.overflow-visible {
    overflow: visible !important;
}
.no-margin-top{
    margin-top:0;
}
.display-block{
    display:block;
}
.display-block-important {
    display: block !important;
}
.spacer-right-10{
    margin-right:10px;
}
.spacer-left-15{
    margin-left:15px;
}
.spacer-minus-top-20{
    margin-top:-20px;
}
.spacer-right-5{
    margin-right:5px;
}
.top-7{
    top:7px;
}
.top-0{
    top:0;
}
.right-15{
    right:15px;
}
.right-65 {
    right: -65px !important;
}
.position-absolute{
    position:absolute;
}
.height-65{
    height:65px;
}
.lightgray-border-right {
    border-right:1px solid $lightgray;
}
.lightgray-border-top {
    border-top: 1px solid $lightgray;
}
.padding-left-50{
    padding-left:50px !important;
}
th.text-left{
    text-align:left !important
}
.spacer120{
    margin-bottom:120px;
}
.height-77-important{
    height:77px !important
}
.text-underline {
    text-decoration: underline;
}
.min-width-auto{
    min-width:auto !important;
}
.width-25{
    width:25px;
}
.width-35 {
    width: 35px;
}
.width-140px{
    width:140px;
}
.width-110px {
    width: 110px;
}
.margin-right-3px{
    margin-right:3px;
}
.padding-right-40{
    padding-right:40px !important;
}
.modal-third-button {
    width: 450px;
}

@media only screen and (max-width: 1366px) {
    .normal-line-height{
        line-height:$line-height-base;
    }
}
.bottom-54{
    bottom:54px
}
.zindex-1 {
    z-index: 1;
}
.cursor-reset{
    *{
        cursor:default !important;
        &:hover{
            outline:0 !important;
            right:0 !important;
            bottom:0 !important;
        }
        button{
            cursor:pointer !important;
        }
    }
}
.pull-label-right {
    .radio-label {
        float: right;
        line-height: 2.3;
    }
}
.padding-auto-8 {
    padding: 0 8px;
}
.margin-auto-10 {
    margin: 0 10px;
}
.line-height-30 {
    line-height: 1.875rem;
}
.margin-left-29 {
    margin-left: 29px;
}
.border-left-light-danube-blue {
    border-left: 2px solid $light-danube-blue;
}
.border-right-light-danube-blue {
    border-right: 2px solid $light-danube-blue;
}
.no-radius {
    border-radius: 0;
}
.height-inherit {
    height: inherit;
}
.sub-row {
    .line-title {
        padding-left: 20px !important;
        border-left: 2px solid $light-danube-blue;
    }

    .tooltip-br span {
        left: -21px !important;
    }

    table {
        padding-left: 21px !important;
    }
}
.line-height-1 {
    line-height: 1px;
}
.line-title {
    line-height: 29px;
}
.parent-checkbox-disabled {
    .parent-disable {
        cursor: not-allowed;
        .btn {
            pointer-events: none;
            opacity: .65;
        }
    }
}
.btn-extra-small {
    padding: 1px 6px;
    background: $blue-lighten-10percent;
    border: 2px solid $darkblue;
    font-weight: normal;
    font-size: 0.8125rem;
}
.no-asterisk {
    .required-icon{
        display:none;
    }
}

bh-dropdown-table {
    .wj-flexgrid {
        .wj-colheaders {
                .wj-header {
                    padding: 8px
                }
        }
    }
}
.m-h-77{
    min-height:77px;
}

.top-message-color {
    background: $white-smoke;
    margin: 0 0 9px 0;
    font-size: 0.75rem;
    padding: 9px 5px;
    box-shadow: 3px 3px 9px $dim-gray;
    border: 2px solid $medium-gray;
    color: $red;
    text-align: center;
}
.border-red {
    border: 3px solid $red;
}
.margin-minus-15 {
    margin: -15px;
}

.padding-15 {
    padding: 15px;
}
.url-limit {
    overflow-wrap: break-word;
    word-wrap: break-word;
    word-break: break-word;
    hyphens: auto;
}

.folder-group-header .folder-actions button {
    position: relative;
    top: 7px;
    right: 10px;
}

.ng2-datetime-picker-wrapper{
    margin-top:3px;
}

bh-r-checkbox:first-of-type, .checkbox-container:first-of-type{
    padding-top:8px;
}

.plus-check .input-group .btn {
    position: absolute;
    z-index: 2;
    top: 23px;
    right: 0px;
}

.list-inline > ul.doc-list {
    display: flex;
    list-style: none;
    padding-left: 0;
}

.list-inline > ul.doc-list > li {
    padding-right: 10px;
}

.pull-bureau bh-r-checkbox:first-of-type {
    padding-top:25px;
}

.rentroll-table td.btn2-col {
    padding: 12px 10px !important;
}

.rentroll-table td bh-r-checkbox:last-of-type {
    padding-bottom: 3px;
}

.rentroll-table .spacer-left-15 {
    padding-top: 11px;
}

.rentroll-table .icon-right + span {
    position: absolute;
    right: 7px;
    top: 5px;
}

.rentroll-table .input-group .btn {
    position: absolute;
    right: 0px;
    top: 1px;
    font-size: 1.4em;
    width: 28px;
}

.rentroll-table .input-group-append .btn {
    height: 28px;
}

.repository-file-upload {
    position: absolute;
    top: 19px;
    right: 0px;
    z-index: 2;
}

.repository-file-upload .btn {
    height: 36px !important;
}

.send-email-btn-align {
    position: relative;
    top: -50px;
    right: 0px;
    text-align: right;
}
.margin-right {
    margin-right: 5px;
}
.action-container{
    padding:30px 22px 0;
}

.send-email-btn-align .message-builder-actions {
    position: absolute;
    top: -26px;
    right: 0px;
    bottom: 0;
    z-index: 2;
    text-align: right;
}

.lookup-tbl-lft , .lookup-tbl-rgt {
    width: auto !important;
}

.div-greyed {
    opacity: .6;
}

.form-section-header .btn-container {
    width: max-content;
}
.btn-white2:disabled,
.btn-white2.disabled {
    background-color: var(--disabled-color) !important;
}
.contact-card-header >ul.nav>li{
    width:50%;
}

.contact-card .nav > li> a{
    text-align :center;
    border-radius: 4px !important;
}
.contact-card .nav-pills > li.active > a {
    background-color: var(--primary-color);
    color: #fff;
}
.cp-modal .tooltip-tl span {
    position:absolute;
    right:22px;
    top:30px;
}
.cp-modal .modal-header .close {
    padding:0;
}
.cp-modal  .fa-envelope-o {
    top:20px;
}

.list-view .header-panel {
    z-index: auto;
}

#navMenuAdministration .dropdown-menu-end {
    transform: translate3D(0px, 47px, 0px) !important;
}


.tooltip-tr span{
    top:0;
    left:0;
}

.banker-map-field.input-group .btn {
    position: absolute;
    z-index: 2;
    right:0px;
}

.banker-map-field .input-group-append .btn{
    height:36px;
}

.highcharts-container {
    display:flex;
    justify-content:center;
}

.position-workspace-field .field-visibility-btn {
    position: absolute;
    right: 15px;
}

.position-workspace-field {
    padding: 15px;
    position: relative;
    cursor: pointer;
}

.position-workspace-field .field-visibility-btn {
    display: none;
}

.position-workspace-field:hover .field-visibility-btn {
    display: block;
}
.lending-icon-text-owner {
    strong {
        display: block;
        color: $blue;
        margin-top: -10px;
        text-align: center;
        font-size: 13px;
    }
}


// TODO see about cleaning up a lot of these custom classes
