
.ngx-data-viewer {

    .toggle.right::after {
        display: inline-block;
        content: "►";
        color: #505050;
        cursor: pointer;
        position: absolute;
    }

    .toggle.down::after {
        display: inline-block;
        content: "►";
        transform: rotate(90deg);
        color: #505050;
        cursor: pointer;
        position: absolute;
    }

    div {
        max-width: 100%;
    }

    .tag {
        color: #4E187C;
    }

    span, div {
        font-family: monospace;
        font-size: 1em;
    }

    .value {
        color: #FF6B6B;
        overflow-wrap: anywhere;
    }

    .hint {
        color: #999;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
    }

    .spacer {
        width: 18px;
        display: inline-block
    }

    .container {
        margin-left: 10px;
        max-width: 100%;

        &.expanded {

        }

        &.collapsed {
            display: flex;
        }
    }

    .collection {
        position: relative;
    }

}
