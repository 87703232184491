@import "../../node_modules/bootstrap/scss/functions";
@import "../../node_modules/bootstrap/scss/variables";
@import "custom-variables";
//== Lending Flow Bar
.lending-icon-container {
    display: flex;
    flex-wrap: wrap;

    .lending-icons {
        display: flex;
        flex-direction: column;
        padding: 0;
        cursor: pointer;
    }

    .lending-icon-btns {
        padding-right: 25px;

        .btn {
            width: 100%;
            min-width: 95px;
            padding: 7px 5px;
            margin-bottom: 10px;
            font-size: 15px;

            &.btn-2 {
                padding: 12px 5px;
            }

            &.btn-1 {
                padding: 14px 5px;
                margin-top: 18px;
            }

            i {
                margin-right: 3px;
            }

            span:not(.process-time) {
                font-weight: bold;
            }
        }

        .dropdown-menu .btn {
            margin-bottom: 0;
            padding-top: 0;
            padding-bottom: 0;
        }


        .process-time {
            font-size: 11px;
            display: block;
            position: relative;
            bottom: -2px;
            margin-top: 2px;
        }

        .dropdown {
            display: block;

            .dropdown-menu {
                margin-top: -10px;

                .btn {
                    .dropdown-button-styles{
                    font-size: $font-size-base;
                    }

                    &:hover, &:focus {
                        background-color: $whitegray;
                    }
                }
            }
        }
    }
}

.lending-icon-bar {
    width: 100%;
    height: 26px;
    background-color: $blue;
    position: absolute;
    top: 28px;
    left: 0;
}


.lending-icon-container div:nth-child(2) .lending-icon-bar {
    left: 50%;
    width: 50%;
}

.lending-icon-container div:last-child .lending-icon-bar {
    width: 50%;
}

@media (min-width: $screen-lg-min) and (max-width: $screen-lg-max) {

    .lending-icon-container .lending-icon-btns {
        padding: 0 15px 0 0;

        .btn {
            font-size: 14px;
            padding: 7px 3px;

            i {
                font-size: 13px;
                vertical-align: 0;
            }
        }
    }
}

@media (min-width: $screen-lg-min) and (max-width: 1400px) {
    .lending-icon-container .lending-icon-btns {
        padding: 0;
    }
}

.lending-icon,
.lending-icon-visited,
.lending-icon-active {
    background-color: $whitegray;
    border-radius: 50%;
    width: 80px;
    height: 80px;
    position: relative;
    margin: 0 auto;
    z-index: 98;
    display: flex;
    align-items: center;
    justify-content: center;

    & .lending-icon-inner {
        border-radius: 50%;
        border: 1px solid white;
        background-color: $blue;
        width: 60px;
        height: 60px;
        display: flex;
        align-items: center;
        justify-content: center;

        & .fa {
            color: white;
        }
    }
}

.lending-icon {
    background-color: $whitegray;

    & .lending-icon-inner {
        border: 2px solid white;
        background-color: $lightgray;

        & .fa {
            color: white;
        }
    }
}

.lending-icon-visited {
    background-color: $green;

    & .lending-icon-inner {
        border: 2px solid white;
        background-color: $blue;

        & .fa {
            color: white;
        }
    }
}

.lending-icon-active {
    background-color: $lightblue;
    width: 95px;
    height: 95px;
    margin-top: -7px;
    box-shadow: $box-shadow-2;

    & .lending-icon-inner {
        border: 2px solid white;
        background-color: $blue;
        width: 70px;
        height: 70px;

        & .fa {
            color: white;
            font-size: 2.5em;
        }
    }
}

.warning .lending-icon-active {
    background-color: $orange;
}

.violation .lending-icon-active {
    background-color: $red;
}

/*.lending-switch-icon {
    width: 90px;
    height: 72px;
    margin: 5px auto 0;
    position: relative;

    &:hover {
        background-color: $blue;
    }

    &:before {
        font-family: FontAwesome;
        content: "\f07b";
        font-size: 66px;
        position: absolute;
        z-index: 1;
        top: -10px;
        left: 14px;
    }

    &:hover:before {
        content: "\f07c";
    }

    & .arrow:before {
        font-family: FontAwesome;
        content: "\f178";
        font-size: 46px;
        position: absolute;
        z-index: 2;
        color: $blue;
        top: 8px;
        left: 22px;
    }

    &:hover .arrow:before {
        content: "";
    }
}*/

.lending-icon-text {
    text-align: center;
    margin: 10px 0;
    padding: 0 5px;
    word-wrap: break-word;
    min-height: 40px;

    & span {
        font-weight: bold;
        display: block;
        color: $blue;
    }
}


@media (max-width: $screen-lg-max) {

    .lending-icon-bar {
        height: 22px;
        top: 26px;
    }

    .lending-icon,
    .lending-icon-visited,
    .lending-icon-active {
        width: 70px;
        height: 70px;


        & .lending-icon-inner {
            width: 54px;
            height: 54px;
        }
    }

    .lending-icon-active {
        width: 85px;
        height: 85px;
        margin-top: -8px;

        & .lending-icon-inner {
            width: 62px;
            height: 62px;
        }
    }

    .lending-switch-icon {
        width: 75px;
        height: 60px;

        &:before {
            font-size: 55px;
            top: -8px;
            left: 11px;
        }

        & .arrow:before {
            font-size: 40px;
            top: 5px;
            left: 18px;
        }
    }

    .lending-icon-text {
        font-size: 13px;
    }
}


@media (min-width: $screen-lg-min) and (max-width: 1400px) {

    .lending-icon-container div[class*="col-"]:not([class*="lending-icon-btns"]) {
        left: 20px;
    }

    .lending-icon-bar {
        height: 18px;
        top: 24px;
    }

    .lending-icon,
    .lending-icon-visited,
    .lending-icon-active {
        width: 62px;
        height: 62px;

        & .lending-icon-inner {
            width: 50px;
            height: 50px;
        }
    }

    .lending-icon-active {
        width: 75px;
        height: 75px;
        margin-top: -7px;

        & .lending-icon-inner {
            width: 57px;
            height: 57px;
        }
    }

    .lending-switch-icon {
        width: 65px;
        height: 55px;
        margin-top: 4px;

        &:before {
            font-size: 48px;
            top: -6px;
            left: 9px;
        }

        & .arrow:before {
            font-size: 36px;
            top: 4px;
            left: 13px;
        }
    }

    .lending-icon-text {
        font-size: 12px;
    }
}

@media (max-width: $screen-md-max) {

    .lending-icon-btns .btn {
        margin-bottom: 6px;
    }
}

//Deal Navigator

.deal-nav-container {
    background: $lightblue linear-gradient(to top, $lightblue, $blue);
    border-radius: $border-radius-base;
    padding: 15px;
    box-shadow: $box-shadow-2;

    .top-name {
        color: white;
        font-size: $large-font-size;
        margin-bottom: 10px;

        .btn {
            font-size: $large-font-size;

            &:hover strong, &:focus strong {
                color: $green;
            }
        }
    }

    .btn-deal {
        display: flex;
        align-items: center;
        margin-bottom: 10px;
        width: 100%;
        font-weight: bold;
        padding: 6px 10px;
        text-align: left;
        min-height: 42px;


        i {
            margin-right: 10px;

            &.fa-paperclip {
                font-size: 1.5em;
                width: 24px;
            }
        }

        .deal-title {
            margin-right: auto;
            white-space: normal;
        }

        .info-badge {
            color: white;
            padding: 3px 5px;
            background-color: $green;
            border-radius: $border-radius-base;
            min-width: 26px;
            height: 26px;
            text-align: center;
            margin-left: 3px;
        }
    }

    ul.info-collapse {
        background-color: $white;
        padding: 10px;
        list-style: none;
        position: relative;
        top: -9px;
        border-radius: 0 0 4px 4px;
        border: 1px solid $blue-lighten-10percent;
        border-top: none;

        li.deal {
            padding: 6px 0;
            display: inline-block;
            width: 100%;

            &.deal-number {
                padding: 0 0 6px 0;
            }

            a.complete:before {
                display: inline-block;
                font-family: 'FontAwesome', sans-serif;
                content: "\f00c";
                font-size: $large-font-size;
                margin-right: 10px;
                color: $green;
            }

            a.editing:before {
                display: inline-block;
                font-family: 'FontAwesome', sans-serif;
                content: "\f040";
                font-size: $large-font-size;
                margin-right: 10px;
            }

            a.incomplete:before {
                display: inline-block;
                font-family: 'FontAwesome', sans-serif;
                content: "\f071";
                height: 22px;
                margin-right: 10px;
                color: $red;
            }

            a.deal-nav-info {
                float: left;
            }

            button.deal-nav-info-btn {
                padding: 0px 4px 0px;
                font-size: 1rem;
                display: inline-block;
                float: right;
            }

            span {
                display: block;
            }
        }

        .btn-deal-inner {
            padding: 5px 10px;
            width: 100%;
            margin-bottom: 15px;

            &:focus, &:hover {
                background-color: $green;
            }

            &.half-width {
                max-width: 48%;
                float: left;

                & + .btn.half-width {
                    float: right;
                }
            }
        }
    }
}


/*Opportunity Tasks*/

.opp-task-container {
    position: relative;
    display: flex;
    padding-right: 40px;
    margin-top: 5px;


    .opp-task-type {
        padding: 8px 0;
    }

    .opp-task-assign {
        border: 1px solid $whitegray-darken-5percent;
        background-color: $whitegray-lighten-5percent;
        padding: 2px 4px 0 2px;
        margin: 7px 0 7px 15px;
        font-size: $font-size-small;

        i {
            margin-right: 3px;
        }
    }

    .btn {
        right: 5px;
    }
}

.opp-task-edit-container {
    max-height: 0;
    overflow-y: hidden;
    transition: max-height 0.3s ease-out;
}

.opp-task-edit-container.opened {
    max-height: 200px;
}

.opp-task-edit {
    background-color: $whiteblue;
    border: 1px solid $lightblue;
    border-radius: $border-radius-small;
    margin: 5px 0 10px;
    padding: 10px 0 0;
}

.opp-task-add-btn {
    border: 1px solid $lightblue;
    padding: 5px;
    width: 100%;
    text-align: center;
    border-radius: $border-radius-base;
    margin-top: 10px;

    .fa {
        color: $lightblue;
        margin-right: 10px;
    }

    &:hover {
        border: 1px solid $blue;
        background-color: $whiteblue;

        .fa {
            color: $blue;
        }
    }
}


/*Process Modal*/

.action-type {
    text-transform: uppercase;
    margin-right: 4px;
    color: $blue;
    font-weight: bold;
}

.modal-loader {
    text-align: center;
    position: relative;
    right: 10px;
}



//Bureau Card Layout

.bureau-card {
    padding: 10px;
    border: 1px solid var(--primary-color-lighten-10percent);
    border-radius: 4px;
    background: var(--primary-white-color-lighten-4percent);
    margin-bottom: 8px;

    .contact-card {
        background-color: $whiteblue;
    }

    .bureau-card-header {
        padding: 0 80px 8px 2px;
        position: relative;

        h3, p {
            margin: 0;
        }

        h3 {
            color: $blue;
        }

        .bureau-card-header-right {
            position: absolute;
            top: 0;
            right: 0;
            display: flex;
            justify-content:flex-end;
            gap: 4px;
        }
    }

    .bureau-card-table {
        border-color: $table-cell-border-color;

        & > tbody > tr > td {
            padding: 5px 10px;
            min-width: 15%;

            &:last-child {
                text-align: right;
                word-break: normal;
            }
        }

        & .bureau-row-header {
            background-color: $whitegray;

            & td {
                padding: 2px 10px;

                &:last-child {
                    text-align: left;
                }
            }
        }
    }

    &.bank-defined {
        background-color: $lightgreen;

        .bureau-card-header h3 {
            color: $blue;
        }
    }
}

/*Collateral Analysis - Bureau Reports*/
.collateral-container,
.bureau-container {
    margin: 25px 0;
    border: 1px solid $lightgray;
    border-radius: 4px;
    box-shadow: $box-shadow-all;

    &:first-of-type {
        margin-top: 5px;
    }

    .collateral-header,
    .bureau-header {
        background: $whiteblue;
        border-radius: 4px 4px 0 0;
        margin-bottom: 14px;
        border-bottom: 1px solid $lightblue;
        box-shadow: none;

        h3 {
            color: $blue;
            margin-top: 9px;
        }
    }
}

.bureau-container {
    margin: 15px 0;

    .bureau-header {
        min-height: 44px;
        margin-bottom: 0;

        .checkbox {
            margin: 12px 0 6px!important;
        }

        h3, h4 {
            margin-top: 15px;
            float: right;
        }
    }
}

.bureau-container .bureau-header .form-section-header {
    h3 {
        span {
            background: var(--primary-white-color);
        }
    }
}

.bureau-content {
    /*overflow-y: hidden;*/
    min-height: 0;
    max-height: 0;
    transition: max-height 0.3s ease-out, opacity 0.2s linear;

    .show-bureau-content {
        display: none;
    }
}

.bureau-content.opened {
    max-height: 3000px;
}

.bureau-content.opened {
    .show-bureau-content {
        display: block;
    }
}

/*Transactional Data*/

.question-container {
    padding: 6px 0;
    margin: 0;
    border-bottom: 1px solid $whitegray;
    display: flex;
    flex-wrap: wrap;
    align-items: center;

    .form-group {
        margin-bottom: 0;
    }
}

/*Lending Comment Message Board Post*/
.comment-container {
    width: 100%;
    margin: 20px 0;
    display: flex;
    background-color: white;
    border: 1px solid $lightgray;
    border-radius: 8px;
    box-shadow: $box-shadow-all;


    & .comment-info {
        background-color: $whiteblue-lighten-2percent;
        border-right: 1px solid $lightgray;
        padding: 10px;
        border-radius: 10px 0 0 10px;
        text-align: center;

        & h4:first-child {
            color: $blue;
        }

        & .fa {
            font-size: 28px;

            &.fa-thumbs-up,
            &.fa-check {
                color: $green;
            }

            &.fa-share {
                color: $orange;
            }

            &.fa-times {
                color: $red;
            }

            &.fa-comment {
                color: $blue;
                font-size: 26px;
            }
        }

        & h4:last-of-type {
            margin: 5px 0;
        }

        & p {
            margin: 5px 0;
            font-style: italic;
        }
    }

    & .comment-message {
        padding: 10px 20px;
        border-radius: 0 0 10px 10px;
        position: relative;

        & a {
            text-decoration: underline;
        }

        & h4:first-child {
            padding-right: 70px;
        }
    }

    & h4:first-child {
        font-weight: bold;
        color: $blue;
    }
}
/*WOPI EDITOR*/
.office_frame {
    width: 100%;
    height: calc(100vh - 210px);
    display: block;
    border: 1px solid $lightgray;
}

#btnEditOnWeb-Medium20 {
    display: none !important;
}
/*Debt Analysis*/
@media (min-width: $screen-lg-min) {

    .debt-col1 {
        padding-right: 30px;
    }

    .debt-col2 {
        padding-left: 30px;
    }
}


@media (max-width: $screen-sm-min) {

    .debt-field-radios {
        top: -15px;

        & label div {
            display: inline-block;
            margin-right: 10px;
            width: auto;

            & + div {
                margin-right: 0;
            }
        }
    }
}

@media (min-width: $screen-sm-max) and (max-width: $screen-md-max) {

    .debt-field-radios {
        & .checkbox-radio {
            margin: 5px 0;
        }
    }
}

@media (min-width: $screen-lg-min) and (max-width: $screen-lg-max) {

    .debt-field-radios {
        top: -15px;

        & label div {
            display: inline-block;
            margin-right: 10px;
            width: auto;

            & + div {
                margin-right: 0;
            }
        }
    }
}

@media (min-width: $screen-xl-min) {

    .debt-field-radios {
        & .checkbox-radio {
            margin: 5px 0;
        }
    }
}

/* Decision Screen */
.final-decision {
    width: 33.33333333%;
}
