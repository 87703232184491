@import "../../node_modules/bootstrap/scss/variables";
@import "custom-variables";
/* TABLES */

table {
    empty-cells: show;
    border: 1px solid $border-table-color;
    width: 100%;
    max-width: 100%;

    > thead,
    > tbody,
    > tfoot {
        > tr {
            > th,
            > td {
                padding: $padding-table-cell;
                font-weight: normal;
                line-height: $line-height-base;
                vertical-align: top;
            }
        }
    }

    > tfoot > tr > td {
        font-weight: bold;
    }
}

thead {
    border-bottom: 2px solid $border-table-color;
    background: $whiteblue-darken-2percent linear-gradient(to bottom, $whiteblue, $whiteblue 50%, $whiteblue-darken-6percent);
}

tfoot {
    border-top: 2px solid $border-table-color;
    background-color: $whitegray;
}

th + th {
    border-left: 1px solid $table-cell-border-color;
    border-right: 1px solid $table-cell-border-color;
}

tr + tr {
    border-top: 1px solid $table-cell-border-color;
    border-bottom: 1px solid $table-cell-border-color;
}

td + td {
    border-left: 1px solid $table-cell-border-color;
    border-right: 1px solid $table-cell-border-color;
}



.table-striped > tbody > tr:nth-of-type(odd),
tbody.table-striped > tr:nth-of-type(odd) {
    background-color: $table-bg-color;
}

.table-striped > tbody > tr:nth-of-type(even),
tbody.table-striped > tr:nth-of-type(even) {
    background-color: $accent-table-bg;
}

.table-hscroll {
    overflow-x: auto;
    overflow-y: hidden;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    margin-bottom: 15px;
    border: 1px solid $border-table-color;

    & table {
        border: none;

        & tbody tr:last-of-type {
            border-bottom: none;
        }

        & thead tr th:last-of-type,
        & tbody tr td:last-of-type,
        & tfoot tr td:last-of-type {
            border-right: none;
        }
    }

    & + .pagination-container {
        margin-top: 0;
    }
}

.table-nowrap th,
.table-nowrap td {
    white-space: nowrap;
}

.table-nowrap-rows td {
    white-space: nowrap;
}

th:not(.btn-col),
th:not(.btn2-col) {
    min-width: 90px;
}

// Searchbar row
.table-searchbar {
    background: $whiteblue-darken-2percent linear-gradient(to bottom, $whiteblue, $whiteblue 50%, $whiteblue-darken-6percent);
    border: 1px solid $border-table-color;
    border-bottom: none;
    padding: 8px 10px;

    & .input-group[class*="col-"] {
        float: left;
        padding-left: 15px;
        padding-right: 0;
    }

    & .expand-collapse-container {
        display: inline-block;
        margin: 0 10px;
    }

    & .text-selected {
        text-align: right;
        margin: 11px 0 0;
        float: right;
    }
}

.table-advanced-searchbar {
    background-color: $accent-table-bg;
    padding: 5px 70px 5px 12px;
    margin: 0;
    border: 1px solid $border-table-color;
    position: relative;

    .input-group-btn .btn {
        margin: 0;
    }

    .col-sm-4 {
        padding-bottom: 6px;
    }

    .advanced-checkbox {
        padding: 0;

        .checkbox {
            margin: 13px 0 15px
        }
    }

    .column-toggle-btn {
        position: absolute;
        top: 12px;
        right: 12px;

        .dropdown-menu {
            padding-left: 8px;
            padding-right: 8px;
        }
    }


}

.column-toggle-btn {

    .dropdown-menu {
        padding-left: 8px;
        padding-right: 8px;
    }
}

.header-panel-table .dropdown {
    position: unset;
}

//Multi Editing Container
.table-multiedit-container {
    float: left;
    padding: 4px 10px 5px 18px;
    border-right: 1px solid $lightgray;

    & .selected-rows-indicator {
        color: $blue;
        font-weight: bold;
        font-size: $h3-font-size;
        text-align: center;
        display: inline-block;
        position: relative;
        top: 2px;
        min-width: 20px;
        margin-right: 20px;
    }

    & .dropdown {
        margin-left: 5px;
    }
}

// Grouping
table tbody tr.group-header,
.table tbody tr.group-header {
    background-color: $blue !important;
    color: white;
    font-size: $font-size-base;
    border-bottom: 2px solid $lightblue;

    & > td {
        padding: 7px 10px;

        &.clickable {
            cursor: pointer;
        }

        & .collapse-btn {

            & .fa {
                margin-right: 5px;
                color: white;
                font-size: $h2-font-size;
                font-weight: bold;
                width: 26px;
            }
        }

        & .select-btn {
            width: 26px;
            margin-right: 18px;
        }
    }

    & .fa {
        color: white;
        margin-right: 6px;
    }
}

//Add manual group headers to wijmo treeview
.table-group-header {
    background-color: $blue;
    color: white;
    border-bottom: 2px solid $lightblue;
    padding: 7px 10px;

    & .collapse-btn {

        & .fa {
            margin-right: 5px;
            color: white;
            font-size: $h2-font-size;
            font-weight: bold;
            width: 26px;
        }
    }
}


// Sorting/Filtering

tr.sort-row {
    background: $whiteblue-darken-2percent linear-gradient(to bottom, $whiteblue, $whiteblue-darken-6percent);

    & button {
        text-align: left;
    }
}

th:not(.btn-col).sort,
th:not(.btn2-col).sort {
    padding: 0;

    & button {
        padding: 8px 25px 5px 10px;
        position: relative;
        width: 100%;
    }

    & button .sort-icon:after,
    & button .sort-icon-desc:after,
    & button .sort-icon-asc:after {
        font-family: 'FontAwesome', sans-serif;
        font-size: $large-font-size;
        position: absolute;
        top: 6px;
        right: 10px;
    }

    & button .sort-icon:after {
        content: "\f0dc";
        color: $lightblue;
    }

    & button:hover .sort-icon:after {
        content: "\f0dc";
        color: $blue;
    }

    & button .sort-icon-desc:after,
    & button:hover .sort-icon-desc:after {
        content: "\f0dd";
        color: $blue;
    }

    & button .sort-icon-asc:after,
    & button:hover .sort-icon-asc:after {
        content: "\f0de";
        color: $blue;
    }
}

tr.filter-row,
tr.filter-row th {
    border-top: none !important;
    background: $whiteblue-darken-6percent;
}

th:not(.btn-col).filter,
th:not(.btn2-col).filter {
    padding: 0;
    min-width: 120px !important;

    & .input-group {
        margin: 0 10px 8px 10px;

        .form-control {
            z-index: 1;
        }
    }

    :last-child {
        padding: unset;
        margin-right: unset;
        max-width: 192px;
    }

    & .input-group-btn .btn {
        font-size: 18px;
        height: 30px;
        padding: 0 0 0 10px;
        width: auto;
        border: none;
        background: none;
        color: $blue;

        &:hover {
            color: $darkblue;
        }
    }
}

//==Dropdown Buttons in Table Rows/header
table th .dropdown-menu,
table td .dropdown-menu,
.table-searchbar .dropdown-menu {

    & > li {
        line-height: $line-height-base;

        &:hover,
        &:focus {
            background-color: $whitegray;
        }
    }

    & > li > button {
        .dropdown-button-styles{
            &:hover,
            &:focus {
                color: $blue;
            }
        }

    }
}

//So dropdowns arent hidden in hscroll tables - wont work with fixed height though
td.btn-col .dropdown,
td.btn2-col .dropdown {
    position: static;
    float: right;
}

td.btn-col .dropdown-menu,
td.btn2-col .dropdown-menu {
    top: unset;
    left: unset;
}

//== Pagination

.pagination-container {
    margin-top: 8px;
    margin-bottom: 20px;
    font-size: $font-size-base !important;
    width: 100%;
    display: flex;
    display: -webkit-flex;
    flex-wrap: wrap;
    -webkit-flex-wrap: wrap;
    justify-content: space-between;

    & .pagination-left {
        float: left;
        display: inline-block;
        margin-bottom: 10px;
        margin-right: 10px;

        & button {
            border-radius: 0;
            padding: 5px 11px;
            color: $gray;
            background-color: white;
            border: 1px solid $lightgray;
            margin-left: -1px;

            & .fa {
                color: $blue;
            }

            &:hover,
            &:focus,
            &:hover .fa,
            &:focus .fa {
                color: white;
                background-color: $blue;
                border-color: $blue;
                outline: none;
            }

            &.disabled,
            &[disabled],
            &.disabled .fa,
            &[disabled] .fa {
                color: $lightgray;
                background-color: white;
                border-color: $lightgray;
                cursor: not-allowed;
            }
        }

        & span.page-numbers {
            margin-left: 5px;

            & input {
                width: 34px;
                height: 32px;
                text-align: center;
                border: 1px solid $lightgray;
                margin: 0 5px;

                &:focus {
                    border: 1px solid $blue;
                    outline: none;
                }
            }
        }
    }

    & .pagination-right {
        float: right;
        display: inline-block;

        & button.row-numbers,
        & button.ellipses {

            &:hover,
            &:focus {
                color: $gray;
                background-color: white;
                border-color: $lightgray;
                cursor: default;
            }
        }

        & span.row-numbers {
            height: 32px;
            line-height: 32px;
        }

        & .row-selector {
            margin: 0 5px;
            display: inline-block;
            position: relative;

            & .form-control {
                border: 1px solid $lightgray;
                font-size: $font-size-base;
                width: 50px;
                min-height: 32px;
                padding: 0 7px;

                &.icon-right {
                    padding-right: 0 !important;
                }
            }

            & .fa-caret-down {
                font-size: 20px;
                right: 7px;
                top: 6px;
            }
        }
    }
}


/*Tree collapsing */

button.toggle-collapse {
    float: right;
    margin-left: 15px;

    & i {
        margin-right: 5px;
    }
}

td.tree-tier {
    padding-left: 36px;

    & .tree-cell {
        position: relative;
        //line under collpase button
        & .tree-line {
            position: absolute;
            top: -8px;
            left: -36px;
            border-right: 1px solid $blue-lighten-10percent;
            background-color: $whiteblue-lighten-2percent;
            height: calc(100% + 16px);
            width: 16px;
            display: block;
            z-index: 1;
        }

        & .tree-btn {
            position: absolute;
            top: -8px;
            left: -28px;
            z-index: 2;
            height: calc(100% + 16px);
            width: 15px;

            & .fa {
                color: white;
                font-size: $large-font-size;
                font-weight: bold;
                width: 15px;
                padding: 1px;
                border: 1px solid $blue;
                background-color: $blue;
                border-radius: 2px;
                position: relative;
                bottom: 1px;

                &.fa-angle-right:before {
                    position: relative;
                    bottom: 1px;
                }
            }

            &:hover .fa {
                background-color: $lightblue;
                color: white;
            }

            &.open {

                & .fa {
                    color: $blue;
                    background-color: white;
                }

                &:hover .fa {
                    background-color: $whiteblue;
                    color: $blue;
                }
            }
        }
    }
}
// TODO figure this out
@for $i from 1 through 26 {
    td.tree-tier-#{$i} {
        padding-left: (36px + 24 * $i);
        & .tree-cell .tree-line {
            left: (-36px - 24 * $i);
            width: (16px + 24 * $i);
        }
    }
}

table > tr > td { //needed for layout of tree tier component
    padding: 8px 10px;
    vertical-align: top;
}

/*Edit button columnn*/
th.btn-col,
td.btn-col,
.col-symbol {
    width: 46px !important;
    min-width: 46px !important;
}

th.btn2-col,
td.btn2-col {
    width: 82px !important;
    min-width: 82px !important;
}

th.btn3-col,
td.btn3-col {
    width: 92px !important;
    min-width: 92px !important;
}

th.btn-col,
td.btn-col,
th.btn2-col,
td.btn2-col {
    padding: 5px 10px !important;

    .btn ~ .btn {
        float: right;
    }
}

.btn-col {
    .btn + .btn {
        margin-left: 10px;
    }
}


/*Table slideout Menu - NOT USED*/

td.table-row-menu-container {
    position: relative;
    min-width: 280px;
}

.table-row-menu-btn {
    float: right;
    cursor: pointer;
    padding-top: 5px;
}

.table-row-menu-btn:focus,
.table-row-menu-btn:hover {
    color: $darkgreen;
}

.table-row-menu {
    position: absolute;
    top: 0;
    right: 0;
    padding: 0 8px;
    background-color: $whiteblue;
    height: 100%;
    max-height: 40px;
    width: auto;
    display: none;

    & .fa-times {
        color: $blue;
        vertical-align: middle;

        &:hover {
            color: $darkblue;
        }
    }

    & button,
    & a.btn {
        margin: 5px 4px;

        &:hover {
            background-color: $darkblue;
        }
    }
}


td.select-col {
    display: none;
}

/*Dropdown Table*/

.dropdown-table table {

    & tbody tr {
        cursor: pointer;

        &:hover,
        &:focus {
            background-color: $blue;
            color: white;
        }
    }
}

/*Column Type Styling*/

.columntype-checkmark,
.columntype-boolean {
    text-align: center;

    .fa-check {
        color: $green;
    }

    .fa-times {
        color: $red;
    }

    .fa-share {
        color: $orange;
    }
}

.columntype-currency,
.columntype-number,
.columntype-decimal {
    text-align: right;
    padding-left: 15px;
    position: relative;
}

.columntype-currency:before {
    font-family: 'FontAwesome', sans-serif;
    content: "\f155";
    font-size: $font-size-base;
    position: absolute;
    left: 0;
    color: $lightblue;
}

td.columntype-currency {
    padding-left: 25px !important;

    &:before {
        left: 10px;
    }

    &.error,
    &.success,
    &.info,
    &.warning {
        padding-left: 42px !important;
    }

    &:after {
        left: 27px !important;
    }
}

.columntype-percentage {
    text-align: right;
    padding-right: 20px;
    position: relative;
}

.columntype-percentage:after {
    font-family: 'FontAwesome', sans-serif;
    content: "\f295";
    font-size: $font-size-base;
    position: absolute;
    right: 0;
    color: $lightblue;
}

td.columntype-percentage {
    padding-right: 30px !important;

    &:after {
        right: 10px;
    }
}

.columntype-time {
    min-width: 61px;
}

.column-group {
    text-align: center;
    font-weight: bold;
}

td.green-background,
th.green-background {
    background: $lightgreen-darken-2percent linear-gradient(to bottom, $lightgreen, $lightgreen-darken-6percent);
}

/*Row Type Styling*/

.error-row {
    background-color: $red-lighten-55percent !important;
}

.attention-row {
    background-color: $yellow-lighten-27percent !important;
}

.selected-row {
    background-color: $lightgreen !important;
}

.shaded-row {
    background-color: $accent-table-bg;
}

.summed-row {

    .columntype-currency {
        font-weight: bold;
        padding-left: 35px;

        &:after {
            content: "\03a3";
            font-size: $large-font-size;
            position: absolute;
            left: 20px;
            color: $lightblue;
        }
    }
}

tr.row-readonly td:not(.btn-col):not(.btn2-col),
td.row-readonly {
    opacity: 0.65;
}

tr.row-disabled,
td.row-disabled {
    cursor: not-allowed;
    opacity: 0.65;

    .btn {
        cursor: not-allowed;
        pointer-events: none;
    }
}


/*Cell Type Styling*/

td.cell-disabled {
    cursor: not-allowed;
    opacity: 0.65;
}

td.single-line,
td.double-line,
td.solid-line {
    position: relative;

    & span:before {
        content: " ";
        position: absolute;
        top: 50%;
        border-bottom: 2px solid $gray;
        width: calc(100% - 20px);
        margin: 0 10px;
    }
}

td.double-line span:before {
    top: 15px;
    border-bottom: 7px double $gray;
}

td.solid-line span:before {
    top: 10px;
    border-bottom: 17px solid $gray;
}

th.width75,
td.width75 {
    min-width: 75px;
    max-width: 75px;
}

th.width85,
td.width85 {
    min-width: 85px;
    max-width: 85px;
}

th.width95,
td.width95 {
    min-width: 95px;
    max-width: 95px;
}

td span.column-colorblock {
    display: inline-block;
    width: 15px;
    height: 15px;
    position: relative;
    top: 2px;
    margin-right: 8px;
    border-radius: 2px;
    border: 1px solid $gray;
}

td.error,
td.success,
td.info,
td.warning {
    text-align: right;
    padding-left: 35px;
    position: relative;

    &:after {
        font-family: 'FontAwesome', sans-serif;
        content: "";
        font-size: $font-size-base;
        position: absolute;
        left: 10px;
    }
}

td.error {
    background-color: $lightred;

    &:after {
        content: "\f071";
        color: $red;
    }
}

td.success {
    background-color: $green-lighten-40percent;

    &:after {
        content: "\f00c";
        color: $green;
    }
}

td.warning {
    background-color: $yellow-lighten-27percent;

    &:after {
        content: "\f071";
        color: $orange;
    }
}

td.forecast {
    background-color: $yellow-lighten-27percent;
}

td.info {
    background-color: $whiteblue-darken-2percent;

    &:after {
        content: "\f05a";
        color: $blue;
    }
}

td.indent,
td.indent2 {
    padding-left: 35px;

    &.dash:before {
        content: '-';
        padding-right: 5px;
    }
}

td.indent2 {
    padding-left: 60px;
}



/*Report and Document Folder Tables*/

.folder-group-table {
    margin-bottom: 10px;

    .folder-indent:last-child {
        margin-bottom: 15px;
    }
}

.folder-group-header {
    cursor: pointer;
    background: $accent-table-bg;
    margin: 0 15px;
    padding: 0 9px 8px !important;
    border: 1px solid $lightblue-darken-10percent;
    border-bottom: none;

    & .folder-actions {
        position: absolute;
        right: 25px;

        & button {
            position: relative;
            top: 7px;
        }

        & .dropdown-menu {
            left: auto;
            right: 0;

            & > li {
                line-height: $line-height-base;

                &:hover,
                &:focus {
                    background-color: $whitegray;
                }
            }

            & > li > button {
                .dropdown-button-styles{
                    top: 0;
                }
                
            }
        }
    }

    th {
        padding: 0 9px 8px !important;
    }

    &:hover {
        background-color: $whiteblue;
    }

    &.closed {
        margin-bottom: -1px;
        border: 1px solid $lightblue-darken-10percent;
    }
}

.folder-group-header h4 {
    margin: 0;

    .fa {
        color: $blue;
        margin-right: 8px;
        position: relative;
        top: 6px;
    }

    span {
        font-size: $font-size-base;
        position: relative;
        bottom: 7px;
        left: 7px;

        .fa {
            color: $green;
        }
    }
}

.folder-indent {
    margin-left: 10px;
}

.col-check {
    width: 38px !important;
    padding: 8px 6px !important;
    color: $green;
}

.check-key {
    margin-left: 15px;
    display: inline-block;
}


.drag-mockup {
    width: 30px;
    height: 37px;
    background: $whiteblue-darken-2percent linear-gradient(to bottom, $whiteblue, $whiteblue-darken-6percent);
    cursor: move;
    text-align: center;
    position: relative;

    &:before,
    &:after {
        font-family: 'FontAwesome', sans-serif;
        content: "\f142";
        font-size: 18px;
        color: $lightblue-lighten-6percent;
        position: absolute;
        left: 9px;
    }

    &:after {
        left: 17px;
    }

    &:hover::before,
    &:hover::after {
        color: $blue;
    }
}

.treeview-shadebox {
    position: absolute;
    top: 0;
    left: 0;
    border-right: 1px solid $blue-lighten-20percent;
    background-color: $whiteblue-lighten-4percent;
    height: 100%;
    z-index: 0;
}


.wj-flexgrid:not(.wj-state-focused) .row-selected,
.wj-state-multi-selected {

    .treeview-shadebox {
        background-color: $lightgreen;
    }
}

.factor-weight {
    display: block;
    color: $blue;
    font-weight: bold;

    &:after {
        content: '%';
    }
}
/*For Mockups*/
.table-show-filter th:not(.btn-col) {
    position: relative;
    padding-right: 33px;

    &:after {
        font-family: 'FontAwesome', sans-serif;
        content: "\f0b0";
        font-size: 13px;
        position: absolute;
        right: 8px;
        cursor: pointer;
        padding: 0 4px;
        background-color: $lightblue;
        border-radius: 3px;
        color: white;
        box-shadow: $box-shadow-12;
    }
}

.header-panel-table h2 {
    color: $white;
}

.header-panel-table .select-actions {
    background-color: var(--grayscale-white-color-lighten-2percent);
    margin: 0 -11px;
    padding: 0 100px 0 12px;
    border-bottom: 1px solid var(--analog-blue);
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    position: relative;
    top: 7px;
    clear: both;

    .selected-row-count {
        margin: 1px 10px 0 6px;
    }

    h3 {
        margin: 0;
        display: inline-block;
        font-weight: bold;
        color: var(--primary-color);
    }

    .vertical-divider {
        display: block;
        float: left;
        background: var(--grayscale-light-color);
        margin: 9px 10px;
        height: 32px;
        width: 1px;
    }
}
