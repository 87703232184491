@import "custom-variables";

.flex-wrap,
.inline-flex-wrap {
    display: flex;
    flex-wrap: wrap;

    > [class*='col-'] {
        display: flex;
        flex-direction: column;
    }
}
.inline-flex-wrap {
    display: inline-flex;
}

.flex-center {
    display: flex;
    align-items: center;
}

////TODO Figure out where all this next class is used and replace
.content-block {
    margin-bottom: 25px;
}


//Helper Classes

.spacer10 {
    margin-bottom: 10px !important;
}

.spacer20 {
    margin-bottom: 20px !important;
}

.spacer30 {
    margin-bottom: 30px !important;
}

.spacer40 {
    margin-bottom: 40px !important;
}

.spacer50 {
    margin-bottom: 50px !important;
}

.spacer60 {
    margin-bottom: 60px !important;
}

.spacer70 {
    margin-bottom: 70px !important;
}

.spacer80 {
    margin-bottom: 80px !important;
}

.spacer90 {
    margin-bottom: 90px !important;
}

.spacer100 {
    margin-bottom: 100px !important;
}

.spacertop5 {
    margin-top: 5px !important;
}

.spacertop10 {
    margin-top: 10px !important;
}

.spacertop20 {
    margin-top: 20px !important;
}

.spacertop30 {
    margin-top: 30px !important;
}

.spacertop40 {
    margin-top: 40px !important;
}

.spacertop50 {
    margin-top: 50px !important;
}

.spacertop60 {
    margin-top: 60px !important;
}

.spacertop70 {
    margin-top: 70px !important;
}

.spacertop80 {
    margin-top: 80px !important;
}

.spacertop90 {
    margin-top: 90px !important;
}

.spacertop100 {
    margin-top: 100px !important;
}

.no-padding {
    padding: 0 !important;
}

.no-padding-top {
    padding-top: 0 !important;
}

@media (min-width: $screen-sm-min) {
    .no-padding-left {
        padding-left: 0 !important;
    }

    .no-padding-right {
        padding-right: 0 !important;
    }
}


.no-margin {
    margin: 0 !important;
}

.no-border {
    border: none !important;
}

.disable-click {
    pointer-events: none;
}

.visible-xl {
    display: none !important;
}

@media (min-width: 1600px) {
    .visible-xl {
        display: block !important;
    }
}

@mixin clearfix() {
    &:before,
    &:after {
        content: " ";
        display: table;
    }

    &:after {
        clear: both;
    }
}

////== Scrollbars

/*Webkit*/
::-webkit-scrollbar {
    width: 14px;
    height: 14px;
}

::-webkit-scrollbar-button {
    width: 14px;
    height: 14px;
    background-color: $blue;
}

::-webkit-scrollbar-button:vertical:increment {
    background-image: url('../assets/images/caret-down.png');
    background-position: center;
    background-size: contain;
}

::-webkit-scrollbar-button:vertical:decrement {
    background-image: url('../assets/images/caret-up.png');
    background-position: center;
    background-size: contain;
}

::-webkit-scrollbar-button:horizontal:increment {
    background-image: url('../assets/images/caret-right.png');
    background-position: center;
    background-size: contain;
}

::-webkit-scrollbar-button:horizontal:decrement {
    background-image: url('../assets/images/caret-left.png');
    background-position: center;
    background-size: contain;
}

::-webkit-scrollbar-thumb {
    background: $lightblue;
    border: 0 none $white;
}

::-webkit-scrollbar-thumb:hover {
    background: $blue;
}

::-webkit-scrollbar-thumb:active {
    background: $blue;
}

::-webkit-scrollbar-track {
    background: $whitegray;
    border: 0 none $white;
}

::-webkit-scrollbar-track:hover {
    background: $whitegray;
}

::-webkit-scrollbar-track:active {
    background: $whitegray;
}

::-webkit-scrollbar-corner {
    background: transparent;
}

//== IE Scrollbar color

body {
    scrollbar-face-color: $lightblue;
    -ms-overflow-style: scrollbar;
}


#wrapper {
    padding: 30px;
    background: $black;
    color: $white;
}


//== Loading Indicators

.loading-indicator {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 40px 0;

    & i {
        color: $green;
    }
}

.loading-indicator-details-container {
    position: relative;
    min-height: 200px;

    .loading-indicator-details {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin: 0;
        opacity: 0.5;
        background-color: $whiteblue;
        z-index: 1000;
        border-radius: 4px;
        min-height: 200px;

        i {
            position: absolute;
            top: 80px;
            left: calc(50% - 20px);
            z-index: 1001;
            color: $green;
        }
    }
}
