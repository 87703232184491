@import "custom-variables";

// Scaffolding
body {
    font-family: $base-font-family;
    color: $gray;
    background-color: $white;
    font-size: $font-size-base
}

a {
    color: $blue;
    text-decoration: none;

    &:hover,
    &:focus {
        color: $green-darken-5percent;
        text-decoration: none;
    }
}



// Type
h1, .h1 {
    font-size: $h1-font-size;
}

h2, .h2 {
    font-size: $h2-font-size;
}

h3, .h3 {
    font-size: $h3-font-size;
}

h4, .h4 {
    font-size: $h4-font-size;
}



// Thumbnail
.img-thumbnail {
    background-color: $white;
}



// Tables
table {
    background-color: $table-bg-color;
}

caption {
    padding-top: $padding-table-cell;
    padding-bottom: $padding-table-cell;
}

// Baseline styles
.table {
    // Cells
    > thead,
    > tbody,
    > tfoot {
        > tr {
            > th,
            > td {
                padding: $padding-table-cell;
                border-top: 1px solid $border-table-color;
            }
        }
    }
    // Bottom align for column headings
    > thead > tr > th {
        border-bottom: 2px solid $border-table-color;
    }
    // Account for multiple tbody instances
    > tbody + tbody {
        border-top: 2px solid $border-table-color;
    }
    // Nesting
    .table {
        background-color: $white;
    }
}

// Condensed table w/ half padding
.table-condensed {
    > thead,
    > tbody,
    > tfoot {
        > tr {
            > th,
            > td {
                padding: $padding-table-condensed-cell;
            }
        }
    }
}

// Bordered version
//
// Add borders all around the table and between all the columns.

.table-bordered {
    border: 1px solid $border-table-color;

    > thead,
    > tbody,
    > tfoot {
        > tr {
            > th,
            > td {
                border: 1px solid $border-table-color;
            }
        }
    }
}

// Zebra-striping
//
// Default zebra-stripe styles (alternating gray and transparent backgrounds)

.table-striped {
    > tbody > tr:nth-of-type(odd) {
        background-color: $accent-table-bg;
    }
}

// Hover effect
//
// Placed here since it has to come after the potential zebra striping

.table-hover {
    > tbody > tr:hover {
        background-color: $hover-table-bg;
    }
}

// Responsive tables
//
// Wrap your tables in `.table-responsive` and we'll make them mobile friendly
// by enabling horizontal scrolling. Only applies <768px. Everything above that
// will display normally.

.table-responsive {
    @media screen and (max-width: $screen-xs-max) {
        border: 1px solid $border-table-color;
    }
}


// Forms
legend {
    border-bottom: 1px solid $legend-border;
}

// Adjust output element
output {
    color: $input-text-color;
}

.form-control {
    color: $input-text-color;
    background-color: $input-bg-color;
    border: 1px solid $input-border-color;
    // Disabled and read-only inputs
    //
    // HTML5 says that controls under a fieldset > legend:first-child won't be
    // disabled if the fieldset is disabled. Due to implementation difficulty, we
    // don't honor that edge case; we style them as disabled anyway.
    &[disabled],
    &[readonly],
    fieldset[disabled]  {
        background-color: $disabled-input-bg;
    }
}


// Special styles for iOS temporal inputs
//
// In Mobile Safari, setting `display: block` on temporal inputs causes the
// text within the input to become vertically misaligned. As a workaround, we
// set a pixel line-height that matches the given height of the input, but only
// for Safari. See https://bugs.webkit.org/show_bug.cgi?id=139848
//
// Note that as of 9.3, iOS doesn't support `week`.
@media screen and (-webkit-min-device-pixel-ratio: 0) {
    input[type="date"],
    input[type="time"],
    input[type="datetime-local"],
    input[type="month"] {
        &.input-lg,
        .input-group-lg  {
            line-height: $large-input-height;
        }
    }
}
//
//.input-lg {
//    .input-size {
//        //$large-input-height;
//        $padding-large-vertical;
//        $padding-large-horizontal;
//        $large-font-size;
//        $line-height-large;
//        $input-border-radius-large;
//    }
//}

.form-group-lg {
    .form-control {
        height: $large-input-height;
        font-size: $large-font-size;
    }

    select.form-control {
        height: $large-input-height;
        line-height: $large-input-height;
    }

    .form-control-static {
        height: $large-input-height;
        min-height: ($line-height-computed + $large-font-size);
        font-size: $large-font-size;
    }
}

.input-lg + .form-control-feedback,
.input-group-lg + .form-control-feedback,
.form-group-lg .form-control + .form-control-feedback {
    width: $large-input-height;
    height: $large-input-height;
    line-height: $large-input-height;
}

.help-block {
    color: $grayscale-color-lighten-25percent; // lighten the text some for contrast
}

.form-horizontal {
    // Form group sizes
    //
    // Quick utility class for applying `.input-lg` and `.input-sm` styles to the
    // inputs and labels within a `.form-group`.
    .form-group-lg {
        @media (min-width: $screen-sm-min) {
            .control-label {
                font-size: $large-font-size;
            }
        }
    }
}



// Drop Downs
.dropdown-menu {
    background-color: $dropdown-bg-color;
    border: 1px solid $dropdown-border-color;
    // Dividers (basically an hr) within the dropdown
    .divider {
        .nav-divider{background: $dropdown-divider-bg-color};
    }
    // Links within the dropdown menu
    li > a {
        color: $dropdown-link;

        &:hover,
        &:focus {
            color: $dropdown-link-hover;
            background-color: $dropdown-link-hover-bg-color;
        }
    }
}

.dropdown-menu > .active > a {

    &:hover,
    &:focus {
        color: $white;
        background-color: $blue;
    }
}

.dropdown-menu > .disabled > a {

    &:hover,
    &:focus {
        color: $dropdown-link-disabled;
    }
}

.dropdown-header {
    color: $dropdown-header;
}



// Buttons
.btn-link {
    color: $blue;
    background: unset;
    box-shadow: none!important;

    &:hover,
    &:focus {
        color: $green-darken-5percent;
        text-decoration: none
    }
}



// Input Groups
.input-group-append {
    color: $input-text-color;
    border: 1px solid $input-border-color;

    &.input-lg {
        font-size: $large-font-size;
    }
}



// Navs
.nav {
     li {
         a {
            padding: $nav-link-pad;
             display: block;
             position: relative;
             border-top-right-radius: 4px;
             border-top-left-radius: 4px;
        }
    }
    // Open dropdowns
    .show > a {

        &:hover,
        &:focus {
            border-color: $blue;
        }
    }
}

.navbar-brand {
    font-size: $large-font-size;
}

// Tabs
.nav-tabs {
    border-bottom: 1px solid $nav-tabs-border;

    li {
        // Actual tabs (as links)
         a {
             &:hover,
             &:focus {
                 color: $white;
                 background-color: $blue;
                 border: 1px solid $blue;
             }
        }
        // Active state, and its :hover to override normal :hover
        .active a {
                color: $white;
                background-color: $blue;
                border: 1px solid $blue;
        }

    }
}

// Pills
// -------------------------
.nav-pills {
    > li {
        // Active state
        &.active > a {

            &:hover,
            &:focus {
                color: $white;
                background-color: $blue;
            }
        }
    }
}

// Justified nav links
.nav-tabs-justified {
    > .active > a,
    > .active > a:hover,
    > .active > a:focus {
        border: 1px solid $blue;
    }

    @media (min-width: $screen-sm-min) {
        > li > a {
            border-bottom: 1px solid $blue;
        }

        > .active > a,
        > .active > a:hover,
        > .active > a:focus {
            border-bottom-color: $blue;
        }
    }
}



// Labels
.label {
    color: $label-color;
    // Add hover effects, but only for links
    a {
        &:hover,
        &:focus {
            color: $link-label-hover-color;
        }
    }
}



// List Group
.list-group-item {
    background-color: $white;
    border: 1px solid $gray;

    // Active class on item itself, not parent
    &.active,
    &.active:hover,
    &.active:focus {
        color: $white;
        background-color: $blue;
        border-color: $blue;

        .list-group-item-text {
            color: $blue-lighten-40percent;
        }
    }
}

a.list-group-item,
button.list-group-item {
    color: $gray;

    .list-group-item-heading {
        color: $gray;
    }
    // Hover state
    &:hover,
    &:focus {
        color: $gray;
        background-color: $whitegray;
    }
}



// Panels
// Base class
.panel {
    background-color: $white;
}

// Optional footer (stays gray in every modifier class)
.panel-footer {
    background-color: $whitegray;
    border-top: 1px solid $lightgray;
}

// Tables in panels
//
// Place a non-bordered `.table` within a panel (not within a `.panel-body`) and
// watch it go full width.
.panel {
    > .panel-body + .table,
    > .panel-body + .table-responsive,
    > .table + .panel-body,
    > .table-responsive + .panel-body {
        border-top: 1px solid $border-table-color;
    }
}

// Collapsible panels (aka, accordion)
//
// Wrap a series of panels in `.panel-group` to turn them into an accordion with
// the help of our collapse JavaScript plugin.

.panel-group {
    .panel-heading {
        + .panel-collapse > .panel-body,
        + .panel-collapse > .list-group {
            border-top: 1px solid $lightgray;
        }
    }

    .panel-footer {
        + .panel-collapse .panel-body {
            border-bottom: 1px solid $lightgray;
        }
    }
}

// Modals
// Actual modal
.modal-content {
    background-color: $modal-content-bg-color;
    border: 1px solid $modal-content-fallback-border; //old browsers fallback (ie8 etc)
    border: 1px solid $modal-content-border;
}

// Modal background
.modal-backdrop {
    display: none;
}

// Modal header
// Top section of the modal w/ title and dismiss
.modal-header {
    padding: $modal-title-pad;
    border-bottom: 1px solid $gray;
}

// Footer (for actions)
.modal-footer {
    border-top: 1px solid $gray;
}



// Thumbnails
.thumbnail {
    background-color: $white;
    // Add a hover state for linked versions only
    a &:hover,
    a &:focus,
    a &.active {
        border-color: $blue;
    }
    // Image captions
    .caption {
        color: $gray;
    }
}



// Pagination
.pagination {
    > li {
        > a,
        > span {
            color: $blue;

            &:hover,
            &:focus {
                color: $green-darken-5percent;
            }
        }
    }
}

// Badges
.badge {
    // Account for badges in navs
    .list-group-item.active > &,
    .nav-pills > .active > a > & {
        color: $blue;
    }
}

.btn.fa {
    font-weight: 900;
}

.header-panel .show>.dropdown-menu {
    opacity: 1;
    transform: translateY(0);
    top: 100%;
}
