:root {
  --card-padding: 24px;
  --card-height: 340px;
  --card-skeleton: linear-gradient(lightgrey var(--card-height), transparent 0);

  --title-height: 32px;
  --title-width: 200px;
  --title-position: var(--card-padding) 60px;
  --title-skeleton: linear-gradient(white var(--title-height), transparent 0);

  --desc-line-height: 16px;
  --desc-line-skeleton: linear-gradient(white var(--desc-line-height), transparent 0);
  --desc-line-1-width:230px;
  --desc-line-1-position: var(--card-padding) 122px;
  --desc-line-2-width:180px;
  --desc-line-2-position: var(--card-padding) 145px;

  --blur-width: 200px;
  --blur-size: var(--blur-width) calc(var(--card-height));
}

/*
 * Card Skeleton for Loading
 */

.skeleton {
  height: var(--card-height);


  &:empty::after {
    content:"";
    display:block;
    width: 100%;
    height: 100%;
    border-radius:4px;
    box-shadow: 0 10px 45px rgba(0,0,0, .1);

    background-image:
            linear-gradient(
                            90deg,
                            rgba(lightgrey, 0) 0,
                            rgba(lightgrey, .8) 50%,
                            rgba(lightgrey, 0) 100%
            ),                          //animation blur
            var(--title-skeleton),      //title
            var(--desc-line-skeleton),  //desc1
            var(--desc-line-skeleton),  //desc2
            var(--card-skeleton)        //card
  ;

    background-size:
            var(--blur-size),
            var(--title-width) var(--title-height),
            var(--desc-line-1-width) var(--desc-line-height),
            var(--desc-line-2-width) var(--desc-line-height),
            100% 100%
  ;

    background-position:
            -150% 0,                      //animation
            var(--title-position),        //title
            var(--desc-line-1-position),  //desc1
            var(--desc-line-2-position),  //desc2
            0 0                           //card
  ;

    background-repeat: no-repeat;
    animation: loading 2s infinite;
  }
}

@keyframes loading {
  to {
    background-position:
            350% 0,
            var(--title-position),
            var(--desc-line-1-position),
            var(--desc-line-2-position),
            0 0
  ;
  }
}
