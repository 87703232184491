@import "custom-variables";
//== Navbar

.bh-navbar {
  height: auto;
  min-height: 44px;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
  background: linear-gradient(to bottom, $whitegray, $whitegray-darken-5percent);
  border-top: 1px solid $gray;
  box-shadow: $box-shadow-2;

  ul {
    margin: 0;

    li {
      font-weight: bold;
      list-style: none;
      float: left;

      a {
        color: $gray;
        display: block;
        text-decoration: unset;

        .fa {
          color: $blue;
        }
      }
    }
  }


  .navbar-logo {
    float: left;
    font-size: $large-font-size;
    padding: 7px 15px;
    width: 239px;

    img {
      max-width: 130px;
      height: 30px;
    }
  }

  .navbar-modules {

    li.module {

      a {
        padding: 11px 14px;
        height: 44px;
        border-left: 1px solid transparent;
        border-right: 1px solid transparent;
        font-family: 'Open Sans', sans-serif;
        text-transform: uppercase;
        color: $blue;

        span:first-of-type {
          display: inline-block;
          height: 22px;
          width: 22px;
          background-position: $default-sprite-position;
          background-size: 44px 44px;

          &.icon-dashboard {
            background-image: url('../assets/images/icon-dashboard@2x-sprite.png');
          }

          &.icon-crm {
            background-image: url('../assets/images/icon-crm@2x-sprite.png');
          }

          &.icon-lending {
            background-image: url('../assets/images/icon-lending@2x-sprite.png');
          }

          &.icon-portfolio {
            background-image: url('../assets/images/icon-portfolio@2x-sprite.png');
          }

          &.icon-cecl {
            background-image: url('../assets/images/icon-cecl@2x-sprite.png');
          }

          &.icon-analytics {
            background-image: url('../assets/images/icon-analytics@2x-sprite.png');
          }

          &.icon-banker {
            background-image: url('../assets/images/icon-banker@2x-sprite.png');
          }
        }

        &:hover span:first-of-type {
          background-position: $active-hover-sprite-position;
        }

        &:hover span:last-of-type {
          color: $green;
        }

        span:last-of-type {
          line-height: 24px;
          vertical-align: top;
          margin-left: 5px;
        }

        &:hover .fa {
          color: $green;
        }

        .fa {
          vertical-align: 30%;
          margin-left: 5px;
        }
      }

      & a:hover,
      & a:focus,
      &.active a,
      &.active a:hover,
      &.active a:focus {
        background-color: white;
        color: $blue;
        border-left: 1px solid $lightgray;
        border-right: 1px solid $lightgray;

        & span:first-of-type {
          background-position: $active-hover-sprite-position;
        }
      }

      &.disabled a,
      &.disabled a:hover,
      &.disabled a:focus {
        background-color: transparent;
        border: none;
        opacity: .65;

        & span:first-of-type {
          background-position: initial;
        }
      }
    }
  }

  //Right side search/dropdowns
  .navbar-menus {
    float: right;
    padding: 0;

    li {

      a {
        padding: 12px 14px;

        span {
          margin-left: 10px;
        }
      }

      & a:hover,
      & a:focus,
      &.active a,
      &.active a:hover,
      &.active a:focus {
        & .fa {
          color: $green;
        }
      }
    }
  }

  //Dropdowns
  .navbar-modules,
  .navbar-menus {

    li.dropdown {

      .dropdown-menu {

        .version-info {
          font-size: $font-size-small;
          padding: 0 5px;
          text-align: center;
          color: $body-color;

          span {
            display: block;
          }

          .btn-link {
            font-weight: bold;
            border: 0;
          }

        }
      }
    }
  }


  .navbar-menus {

    li.dropdown {

      &:last-of-type a .fa {
        padding-right: 3px;
      }

      .dropdown-menu {
        left: auto;
        right: 0;
      }
    }
  }

  //Animation of navbar dropdown menus
  & .dropdown-menu {
    margin: 5px 0 0;
    //transition: opacity 0.2s ease-in, transform 0.2s ease-in;
    transform: translateY(-5px);
    top: -9999px;
    opacity: 0;
    display: block;
  }

  & .dropdown-menu.show {
    opacity: 1;
    transform: translateY(0px);
    top: 100%;
  }
}

@media (max-width: $screen-md-max) {

  .bh-navbar {
    & .navbar-logo {
      width: 59px;

      & img {
        width: 30px;
      }
    }
  }
}

/* Search Container*/
.search-container {
  display: block;
  opacity: 0;
  position: fixed;
  top: -9999px;
  right: 0;
  left: unset;
  width: 740px;
  height: auto;
  min-height: calc(50vh - 70px);
  max-height: calc(100vh - 70px);
  overflow-x: hidden;
  z-index: 2000;
  padding: 20px;
  background-color: white;
  border: 1px solid $blue;
  box-shadow: $box-shadow-3;
  background-clip: padding-box;
  transition: opacity 0.2s ease-in, transform 0.2s ease-in, width 0.4s;
  transform: translateY(-5px);

  &.opened {
    opacity: 1;
    transform: translateY(0px);
    top: 45px;
  }

  &.full {
    width: calc(100vw - 255px);
  }

  .dropdown-menu {
    .checkbox-radio {
      margin: 5px 10px 7px;
    }
  }

  .nav-tabs {
    border-bottom: unset!important;

    .nav-item .nav-link{
      border: 1px solid var(--grayscale-light-color);
      border-top-left-radius: unset;
      border-top-right-radius: unset;
    }

    li a {
      min-width: unset;
    }

    a {
      margin-right: 0;
      text-decoration: unset;
    }

    .active a {
      background-color: var(--primary-color);
      color: var(--white-color);
    }
  }
}

@media (max-width: $screen-sm-max) {

  .search-container {
    width: calc(100vw - 60px);

    &.opened {
      top: 89px;
    }

    .min-max-btn {
      display: none;
    }
  }
}

.dropdown-toggle::after {
  display: none;
}

.dropdown-menu {
    text-align: left;
    margin-top: 0;

    .btn {
        color: var(--primary-color);
        font-weight: bold;
        background: none;
        width: -webkit-fill-available;
        text-align: left;
        padding: 6px 15px;
        box-shadow: none;

        .dropdown-button-styles {
            padding: 6px 15px;
        }

        &:hover, &:focus {
            background-color: $whitegray;
        }

        &.active {
            background-color: $blue;
            color: white;
        }
    }
}

#sidebar-left-panel {
  ul.panel-list {
    li {
      width: 100%;
    }
  }
}
