@import "../../node_modules/bootstrap/scss/variables";
@import "custom-variables";
//Inline Editing Form Controls
.inline-edit-table {

    & table {

        & tbody {

            & td {

                & .form-group,
                & .form-control-sm {
                    margin-bottom: 0;

                    & .form-control {
                        font-size: $font-size-base;
                        min-height: 30px;
                        padding: 2px 10px 0;
                        border-bottom-width: 1px;
                        margin-top: 0;

                        &.ng-valid.ng-dirty:focus {
                            border-color: $green;
                        }
                    }

                    & .input-group-btn .btn {
                        font-size: 1.125rem;
                        width: 32px;
                        height: 30px;
                    }

                    & .icon-right,
                    & .icon-left,
                    & .ng2-datetime-picker-wrapper {

                        & + span {
                            font-size: 0.875rem;

                            &.fa-caret-down,
                            &.fa-caret-up {
                                font-size: 1.25rem;
                            }
                        }
                    }

                    & .icon-right {
                        padding-right: 28px !important;
                    }

                    & .icon-left {
                        padding-left: 28px !important;
                    }
                }

                & bh-r-checkbox .checkbox-radio-indicator {
                    left: 50%;
                    transform: translate(-50%, -25%);
                }

                & ng2-datetime-picker {
                    transform: scale(0.75,0.75);
                    top: 5px !important;
                    left: -22px !important;
                    width: 175px !important;
                }

                & .datepicker-label.focus > .errorMessage {
                    margin-left: 175px;
                }
            }
        }
    }
}

//Rent Roll Table - smaller text/padding of cells / fixed height
.rentroll-table {
    height: calc(100vh - 160px); //Fixed Height
    overflow: auto;

    & table {

        & thead, & tbody {
            font-size: $font-size-small;
        }

        & td:not(.btn2-col) {
            padding: 4px 8px;
            vertical-align: middle;
        }

        & tbody {

            & td {
                line-height: 1;

                & .form-group,
                & .form-control-sm {

                    & .form-control {
                        font-size: $font-size-small;
                        padding: 2px 6px 0;
                    }
                }

                &.btn-col .dropdown, //needed for fixed height
                &.btn2-col .dropdown {
                    position: relative;
                }

                &.btn-col .dropdown-menu, //needed for fixed height
                &.btn2-col .dropdown-menu {
                    top: -2px;
                    left: calc(100% + 10px);
                }

                & .checkbox-radio-indicator {
                    top: -4px;

                    &:after {
                        top: 1px;
                    }
                }

                & .columntype-currency, .columntype-number, .columntype-decimal {

                    &:before {
                        top: -1px;
                    }
                }

                & .errorMessage {
                    width: 230px;
                }
            }
        }
    }

    & .modal table {
        & thead, & tbody {
            font-size: $font-size-base;
        }

        & td:not(.btn2-col) {
            padding: 8px 10px;
        }

        & tbody td {

            & .form-group,
            & .form-control-sm {

                & .form-control {
                    font-size: $font-size-base;
                    padding: 2px 10px 0;
                }
            }
        }
    }
}


//Statement Template Details Table

.statement-template-table {
    max-height: calc(100vh - 180px); //Fixed Height
    overflow: auto;
}


//Fixed header, Height, First Column and column widths
.group-reporting,
.sa-table-container {

    & table {
        position: relative;
        width: 100%;
        overflow: hidden;
        table-layout: fixed;
        border: 2px solid $border-table-color;

        & thead {
            position: relative;
            display: block;
            width: 100%;
            overflow: hidden; /*changed from visible for simultaneous scrollbars*/
            & th {
                text-align: center;
                min-width: 150px;
                width: 150px;
                box-sizing: border-box;
                line-height: 20px;
                vertical-align: middle;
                padding: 8px;

                & a {
                    color: $gray;
                    display: block;

                    &:hover {
                        color: $green;
                    }
                }

                & .column-truncate {
                    display: block;
                    width: 130px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                }

                &.fixed-col {
                    position: relative;
                    display: block;
                    min-width: 300px;
                    max-width: 300px;
                    height: 70px;
                    border-right: 2px solid $border-table-color;

                    & button {
                        height: 36px;
                        position: absolute;
                        width: 125px;

                        &.top-left {
                            margin: auto;
                            left: 15px;
                            top: 0;
                            bottom: 0;
                        }

                        &.top-right {
                            margin: auto;
                            right: 17px;
                            top: 0;
                            bottom: 0;
                        }
                    }
                }

                &.add-column {
                    min-width: 100px;
                    max-width: 100px;
                    vertical-align: middle;
                    padding: 0;

                    & .btn {
                        width: 100%;
                        height: 70px;
                        background-color: transparent;
                        border: none;
                        color: $lightblue;
                        transition: font-size .2s ease-in-out;

                        &:hover, &:focus {
                            color: $blue;
                            font-size: 17px;
                        }
                    }
                }
            }

            & th:nth-child(2) {
                border-left: none;
            }
        }

        & .column-pagination {
            width: 80px;
            position: absolute;
            top: 15px;
            right: 12px;
            text-align: center;

            & button {
                width: 35px;
                line-height: 1;
            }

            & div {
                margin-top: 10px;
            }
        }

        & .column-pagination-small {
            width: 80px;
            position: absolute;
            top: 8px;
            right: 12px;
            text-align: center;

            & button {
                width: 35px;
                line-height: 1;
            }

            & div {
                margin-top: 2px;
            }
        }

        & tbody {
            position: relative;
            display: block;
            width: 100%;
            max-height: calc(100vh - 250px); //Fixed Height
            overflow: auto;

            & td {
                height: 30px;
                position: relative;
                padding: 0;
                min-width: 150px;
                width: 150px;
                box-sizing: border-box;

                &.fixed-col {
                    position: relative;
                    display: block;
                    width: 300px;
                    background-color: $whiteblue;
                    border-right: 2px solid $border-table-color;
                    line-height: 30px;
                    padding: 0 10px;

                    & a.line-title, span.line-title {
                        color: $gray;
                        font-weight: normal;
                        max-width: 280px;
                        display: inline-block;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;

                        & .line-number {
                            color: $blue;
                            margin-right: 3px;
                            display: inline;
                        }
                    }

                    & a.line-title:hover {
                        color: $green;
                    }

                    &.group-header {
                        background-color: $blue;
                        color: white;
                        font-size: $font-size-base;
                        td {
                            color: $white;
                        }
                    }
                }

                &.form-control-sm {

                    & .form-control {
                        min-width: 149px;
                        width: 149px;
                        height: 30px;
                        min-height: 30px;
                        border: none;
                        outline: 1px solid $lightgray;
                        position: absolute;
                        top: 0;
                        left: 0;
                        z-index: 1;

                        &:focus {
                            outline-color: white;
                            border: 1px solid $blue;
                            z-index: 2;
                        }
                    }
                }
            }

            & tr.sub-row {

                & td {
                    background: $whitegray-lighten-5percent;
                }

                & a.line-title, span.line-title {
                    padding-left: 10px;
                    max-width: 260px;
                }
            }

            & tr.group-header {
                border-right: 1px solid $blue;

                & td {
                    height: 32px;
                    min-width: 300px;
                }
            }

            & tr.blank-row td {
                height: 20px;
                border: none;
                background: none;

                &.fixed-col a.line {
                    pointer-events: none;

                    &:hover {
                        color: $gray;
                    }
                }

                & div,
                & a { //making completely blank
                    display: none;
                }
            }
            /*Change last rows tooltip to remove small white spacing*/
            & tr:last-child .fixed-col .tooltip-br {
                display: inline;

                & span {
                    top: 25%;
                    left: 75%;
                    bottom: initial;
                    transform: translateY(-100%);
                }
            }
        }
    }
}


/*SA Specific Overrides*/

.sa-search {
    .form-group {
        margin-bottom: 0;
    }
}

.sa-table-container {

    & table {

        & thead {

            & th {
                vertical-align: top;

                &.fixed-col {
                    height: 96px;

                    & button {

                        &.switch-view {
                            left: 15px;
                            top: 15px;
                            width: 150px;
                        }

                        &.add-btn {
                            right: 17px;
                            top: 15px;
                            width: 100px;
                        }
                    }
                }
            }
        }

        & td, tr {
            border-color: $whitegray-darken-5percent;
        }

        & tbody {
            max-height: calc(100vh - 400px);

            & td {

                &.fixed-col {
                    border-top: none;
                    border-bottom: none;
                    box-shadow: inset 1px 0 0 $whitegray-darken-5percent;
                    padding-left: 15px;
                }

                &.form-control-sm {

                    & span.form-control {
                        background-color: $whitegray;
                        line-height: 28px;
                    }

                    & .btn-unstyled {
                        position: absolute;
                        top: 0;

                        & ~ .input-group-append {
                            display: none;
                        }
                    }

                    & .form-control {

                        & ~ .input-group-append,
                        & .input-group-append {
                            display: none;
                            position: absolute;
                            top: 0;
                            left: 0;
                            z-index: 3;
                        }

                        &:not(.no-comment):focus {
                            width: 118px;
                            outline-color: white;
                            border: 1px solid $blue;
                            z-index: 2;
                            background-color: $whiteblue;

                            & ~ .input-group-append {
                                display: block;
                            }

                            & ~ .calculation-indicator {
                                left: 32px;
                            }
                        }
                    }

                    & .checkbox-radio {
                        margin: 0;

                        & .checkbox-input:focus {

                            & ~ .input-group-append {
                                display: block;
                            }
                        }

                        & .checkbox-radio-indicator {
                            right: 10px;
                            top: 4px;
                            left: initial;

                            &:after {
                                top: -5px;
                            }
                        }
                        //Chrome and Firefox
                        &:focus-within {
                            width: 118px;
                            outline-color: white;
                            border: 1px solid $blue;
                            z-index: 2;
                            background-color: $whiteblue;

                            & ~ .input-group-append {
                                display: block;
                            }
                        }
                    }

                    & .calculation-indicator {
                        position: absolute;
                        top: 0;
                        left: -1px;
                        z-index: 5;
                        border: 4px solid transparent;
                        border-left-color: $green;
                        border-top-color: $green;
                    }

                    & .comment-indicator {
                        position: absolute;
                        top: 0;
                        right: 0;
                        z-index: 5;
                        border: 4px solid transparent;
                        border-right-color: $orange;
                        border-top-color: $orange;
                    }
                }
            }

            & tr.line-type {

                &.line-type-header {
                    border-top: 4px solid $border-table-color;

                    & .form-control {
                        font-weight: bold;
                    }
                }

                & td.fixed-col {
                    padding-left: 12px;
                    border-left: 5px solid $border-table-color;

                    & .btn-toggle .fa {
                        margin-right: 10px;
                        color: white;
                        font-size: 16px;
                        font-weight: bold;
                        width: 15px;
                        padding: 1px;
                        border: 1px solid $blue;
                        background-color: $blue;
                        border-radius: 2px;
                        position: relative;
                        bottom: 1px;

                        &.open {
                            color: $blue;
                            background-color: white !important;
                        }

                        &.fa-angle-right:before {
                            position: relative;
                            bottom: 1px;
                        }
                    }
                }
                //Line Type Colors
                &.balancing {

                    &.line-type-header {
                        border-top-color: $purple;
                    }

                    & td.fixed-col {
                        border-left-color: $purple;

                        & .btn-toggle .fa {
                            border-color: $purple;
                            background-color: $purple;

                            &.open {
                                color: $purple;
                            }
                        }
                    }
                }

                &.assets {

                    &.line-type-header {
                        border-top-color: $green;
                    }

                    & td.fixed-col {
                        border-left-color: $green;

                        & .btn-toggle .fa {
                            border-color: $green;
                            background-color: $green;

                            &.open {
                                color: $green;
                            }
                        }
                    }
                }

                &.liabilities {

                    &.line-type-header {
                        border-top-color: $red-darken-10percent;
                    }

                    & td.fixed-col {
                        border-left-color: $red-darken-10percent;

                        & .btn-toggle .fa {
                            border-color: $red-darken-10percent;
                            background-color: $red-darken-10percent;

                            &.open {
                                color: $red-darken-10percent;
                            }
                        }
                    }
                }

                &.net-worth {

                    &.line-type-header {
                        border-top-color: $darkcyan;
                    }

                    & td.fixed-col {
                        border-left-color: $darkcyan;

                        & .btn-toggle .fa {
                            border-color: $darkcyan;
                            background-color: $darkcyan;

                            &.open {
                                color: $darkcyan;
                            }
                        }
                    }
                }

                &.operating {

                    &.line-type-header {
                        border-top-color: $yellow-darken-3percent;
                    }

                    & td.fixed-col {
                        border-left-color: $yellow-darken-3percent;

                        & .btn-toggle .fa {
                            border-color: $yellow-darken-3percent;
                            background-color: $yellow-darken-3percent;

                            &.open {
                                color: $yellow-darken-3percent;
                            }
                        }
                    }
                }

                &.net-income {

                    &.line-type-header {
                        border-top-color: $gray;
                    }

                    & td.fixed-col {
                        border-left-color: $gray;

                        & .btn-toggle .fa {
                            border-color: $gray;
                            background-color: $gray;

                            &.open {
                                color: $gray;
                            }
                        }
                    }
                }

                &.revenue {

                    &.line-type-header {
                        border-top-color: $darkgreen;
                    }


                    & td.fixed-col {
                        border-left-color: $darkgreen;

                        & .btn-toggle .fa {
                            border-color: $darkgreen;
                            background-color: $darkgreen;

                            &.open {
                                color: $darkgreen;
                            }
                        }
                    }
                }

                &.changes-net-worth {

                    &.line-type-header {
                        border-top-color: $darkblue;
                    }

                    & td.fixed-col {
                        border-left-color: $darkblue;

                        & .btn-toggle .fa {
                            border-color: $darkblue;
                            background-color: $darkblue;

                            &.open {
                                color: $darkblue;
                            }
                        }
                    }
                }

                &.retained-earnings {

                    &.line-type-header {
                        border-top-color: $orange;
                    }

                    & td.fixed-col {
                        border-left-color: $orange;

                        & .btn-toggle .fa {
                            border-color: $orange;
                            background-color: $orange;

                            &.open {
                                color: $orange;
                            }
                        }
                    }
                }

                &.other-lines {

                    &.line-type-header {
                        border-top-color: darkgray;
                    }

                    & td.fixed-col {
                        border-left-color: darkgray;

                        & .btn-toggle .fa {
                            border-color: darkgray;
                            background-color: darkgray;

                            &.open {
                                color: darkgray;
                            }
                        }
                    }
                }

                &.convenant-lines {

                    &.line-type-header {
                        border-top-color: $blue;
                    }

                    & td.fixed-col {
                        border-left-color: $blue;

                        & .btn-toggle .fa {
                            border-color: $blue;
                            background-color: $blue;

                            &.open {
                                color: $blue;
                            }
                        }
                    }
                }

                &.appraisal {

                    &.line-type-header {
                        border-top-color: mediumpurple;
                    }

                    & td.fixed-col {
                        border-left-color: mediumpurple;

                        & .btn-toggle .fa {
                            border-color: mediumpurple;
                            background-color: mediumpurple;

                            &.open {
                                color: mediumpurple;
                            }
                        }
                    }
                }

                &.rent-roll {

                    &.line-type-header {
                        border-top-color: saddlebrown;
                    }

                    & td.fixed-col {
                        border-left-color: saddlebrown;

                        & .btn-toggle .fa {
                            border-color: saddlebrown;
                            background-color: saddlebrown;

                            &.open {
                                color: saddlebrown;
                            }
                        }
                    }
                }
            }

            & tr.sub-row {

                & td.fixed-col:before {
                    font-family: 'FontAwesome', sans-serif;
                    content: "\f148";
                    transform: rotate(180deg);
                    font-size: $font-size-base;
                    color: $spun-pearl;
                    position: relative;
                    float: left;
                    left: 2px;
                    top: 2px;
                }

                & td.form-control-sm {

                    .calculation-indicator {
                        left: 14px;
                    }

                    .btn-unstyled {
                        right: 0;
                    }

                    .form-control {
                        width: 135px;
                        min-width: 135px;
                        right: 0;
                        left: initial;

                        &:focus {
                            width: 105px;

                            & ~ .calculation-indicator {
                                left: 45px;
                            }
                        }
                    }

                    & .input-group-append .btn {
                        position: relative;
                        left: 13px;
                    }
                }
            }
            //single line for sub rows and calculated rows
            & tr.sub-row + tr:not(.sub-row) td.fixed-col,
            & tr.calculated-row:not(.balancing) td.fixed-col {
                border-top: 1px solid $raven-gray;
                color: $raven-gray;

                & a.line-title {
                    line-height: 29px;
                    color: $raven-gray;
                }
            }

            & tr.sub-row + tr:not(.sub-row) td.form-control-sm .form-control:first-of-type,
            & tr.calculated-row:not(.balancing) td.form-control-sm .form-control:first-of-type {
                line-height: 26px;
                border-top: 1px solid $raven-gray;
                font-weight: bold;
                color: $raven-gray;
            }
            //double line for total rows
            & tr.total-row:not(.balancing) td.fixed-col {
                border-top: 4px double $gray;

                & a.line-title {
                    line-height: 26px;
                    font-weight: bold;
                    text-transform: uppercase;
                    color: $gray;
                }
            }

            & tr.total-row:not(.balancing) td.form-control-sm .form-control:first-of-type {
                line-height: 23px;
                border-top: 4px double $gray;
                font-weight: bold;
                color: $gray;
            }



            & tr.calculated-row td {

                & .input-group-append {
                    display: none;
                }
            }

            & tr.covenant-row td {

                & .form-control {

                    &.failed-covenant:before,
                    &.waived-covenant:before,
                    &.exclude-covenant:before,
                    &.unmatched-frequency-covenant:before {
                        font-family: 'FontAwesome', sans-serif;
                        content: "";
                        font-size: $font-size-base;
                        position: absolute;
                        left: 10px;
                        top: 1px;
                    }

                    &.failed-covenant {
                        background-color: $lightred;

                        &:before {
                            content: "\f071";
                            color: $red;
                        }
                    }

                    &.waived-covenant {
                        background-color: $green-lighten-40percent;

                        &:before {
                            content: "\f00c";
                            color: $green;
                        }
                    }

                    &.exclude-covenant {
                        background-color: $lightgray;

                        &:before {
                            content: "\f056";
                            color: black;
                        }
                    }

                    &.unmatched-frequency-covenant {
                        background-color: $whiteblue-darken-2percent;

                        &:before {
                            content: "\f05a";
                            color: $blue;
                        }
                    }
                }

                .input-group-append {
                    display: none;
                }
            }
        }
    }
}

.sa-table-container .form-control-sm .form-control .input-group-append {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 3;

}

.negative-cell {
    color: $red !important;
}

@media (max-width: $screen-md-max) {
    .sa-table-container table tbody {
        max-height: calc(100vh - 260px);
    }

    .sa-table-container.projections table tbody {
        max-height: calc(100vh - 320px);
    }
}


/*Projections Specific Overrides*/

.sa-table-container.projections {

    & table {

        & thead {

            & th {

                &.historical {
                    background: $whitegray-lighten-2percent linear-gradient(to bottom, $whitegray-lighten-2percent, $whitegray-lighten-2percent 50%, $whitegray-darken-5percent);
                }

                &.fixed-col {
                    min-width: 350px;
                    max-width: 350px;
                }
            }
        }

        & tbody {
            max-height: calc(100vh - 400px);

            & tr {

                & td.fixed-col {
                    width: 350px;

                    & a.line-title, span.line-title {
                        max-width: 240px;
                    }

                    & .checkbox-radio-indicator {
                        top: -4px;

                        &:after {
                            top: -5px;
                        }
                    }
                }

                &.group-header td {
                    min-width: 350px;
                }
            }

            & tr:not(.blank-row) {

                & td.calculation-cell {
                    height: 61px;

                    & input.form-control {
                        font-style: italic;
                    }

                    & .projections-dropdown {
                        position: absolute;
                        right: 0;
                        top: 0;
                        z-index: 5;
                        width: 80px;

                        & .form-control {
                            border-bottom-width: 1px;
                            font-size: 13px;
                            padding: 2px 26px 2px 5px !important;
                        }

                        & .fa {
                            margin-top: -30px;
                        }
                    }

                    & .info-spans {
                        position: absolute;
                        top: 31px;
                        left: 0;

                        .form-control {
                            position: static !important;
                            margin-bottom: 1px;
                            line-height: 28px;

                            &.funds-required {
                                background-color: $lightred;
                            }

                            &.excess-funds {
                                background-color: $green-lighten-40percent;
                            }
                        }
                    }

                    & .historical-labels {

                        & span {
                            position: absolute;
                            top: 63px;
                            right: 10px;
                            text-align: right;
                            font-size: 13px;
                            width: 320px;

                            & i.left-label {
                                // TODO find this class and see what is happening
                                float: left;
                                max-width: 230px;
                                display: inline-block;
                                overflow: hidden;
                                text-overflow: ellipsis;
                                white-space: nowrap;
                                font-style: normal;
                            }
                        }

                        & span:nth-child(2) {
                            top: 94px;
                        }

                        & span:nth-child(3) {
                            top: 125px;
                        }
                    }
                }

                & td.hide-assumptions {
                    height: 30px;

                    & .info-spans {
                        top: 0;
                    }
                }

                & td.historic-data-1 {
                    height: 92px;
                }

                & td.historic-data-2 {
                    height: 123px;
                }

                & td.historic-data-3 {
                    height: 154px;
                }
            }
            //Remove top spacing for form control in calculated rows / rows with no dropdown
            & tr.calculated-row,
            & tr.no-projection-row {

                & td.calculation-cell {
                    height: 30px;

                    & .info-spans {
                        top: 0;
                    }

                    & .historical-labels {

                        & span {
                            top: 32px;
                        }

                        & span:nth-child(2) {
                            top: 63px;
                        }

                        & span:nth-child(3) {
                            top: 94px;
                        }
                    }
                }

                & td.historic-data-1 {
                    height: 61px;
                }

                & td.historic-data-2 {
                    height: 92px;
                }

                & td.historic-data-3 {
                    height: 123px;
                }
            }

            & tr.sub-row span.form-control {
                width: 149px;
                min-width: 149px;
            }
            //single line for historical sub row lines
            & tr.sub-row + tr:not(.sub-row) td.form-control-sm > div {
                border-top: 1px solid $raven-gray;
            }
            /*& tr.sub-row + tr:not(.sub-row) td.form-control-sm .info-spans .form-control {
                border-top: none;
                font-weight:normal;
                color: $gray;
            }*/
        }
    }
}


.form-control-sm .input-group-append {
    margin-top: unset;

    .btn {
        font-size: 18px;
        width: 32px;
        height: 30px;
    }
}
