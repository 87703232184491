@import "custom-variables";
@page {
    size: A4;
    margin: 8mm 1mm 8mm 1mm;
}

@media print {
    *,
    *:before,
    *:after {
        background: transparent !important;
        color: $black !important; // Black prints faster: h5bp.com/s
        box-shadow: none !important;
        text-shadow: none !important;
    }

    a,
    a:visited {
        text-decoration: underline;
    }

    a[href]:after {
        content: " (" attr(href) ")";
    }

    abbr[title]:after {
        content: " (" attr(title) ")";
    }

    // Don't show links that are fragment identifiers,
    // or use the `javascript:` pseudo protocol
    a[href^="#"]:after,
    a[href^="javascript:"]:after {
        content: "";
    }

    pre,
    blockquote {
        border: 1px solid $nobel-gray;
        page-break-inside: avoid;
    }

    thead {
        display: table-header-group; // h5bp.com/t
    }

    tr,
    img {
        page-break-inside: avoid;
    }

    img {
        max-width: 100% !important;
    }

    p,
    h2,
    h3 {
        orphans: 3;
        widows: 3;
    }

    h2,
    h3 {
        page-break-after: avoid;
    }
}



.visible-print {
    display: none !important;
}

@media print {

   .checkbox-radio input:checked ~ .checkbox-radio-indicator {
        background: $blue !important;
        -webkit-print-color-adjust: exact !important;
    }
    .radio .checkbox-radio-indicator:after {
        background: $white !important;
        -webkit-print-color-adjust: exact !important;
    }
    .hidden-print,
    .bh-navbar, //Top Navigation
    #sidebar-left-panel, //Side Navigation
    .header-right //Header Options 
    {
        display: none !important;
    }

    .visible-print {
        display: block !important;
    }

    .page-break-after {
        page-break-after: always !important;
        display: block;
    }

    #main-content {
        padding-left: 0;
    }

    #sidebar-wrapper {
        top: 0;
    }

    .sidebar-minimized #main-content {
        padding-left: 0;
    }

    .header-panel {
        padding: 0;
    }

    h1 {
        font-size: $h1-font-size;
    }

    h2 {
        font-size: $h2-font-size;
    }

    h3 {
        font-size: $h3-font-size;
    }

    h1, h2, h3 {
        margin: 0;
    }

    .form-section-header h2,
    .form-section-header h3 {
        border-bottom: none;
        margin: 15px 0 10px;
        line-height: inherit;
    }

    .table-hscroll, table, thead, tbody, tfoot, th, td, tr {
        border-color: $lightgray;
    }

    .table-hscroll {
        overflow-x: hidden;
    }

    td.green-background,
    td.blue-background {
        background-color: $whitegray !important;
    }
    //Score Trend
    .score-trend-layout {

        .report-logo {
            position: absolute;
            top: 0;
            left: 0;
        }

        .report-info {
            position: absolute;
            top: 0;
            right: 0;
        }

        table > thead > tr > th,
        table > tbody > tr > th,
        table > tfoot > tr > th,
        table > thead > tr > td,
        table > tbody > tr > td,
        table > tfoot > tr > td {
            padding: 2px 10px;
        }

        table > thead,
        table > tfoot {

            & tr > th,
            & tr > td {
                background-color: $whiteblue !important;
            }
        }

        .header-panel {
            border-bottom: 1px solid $lightgray;
            padding-bottom: 10px;

            .header-left {
                float: none;
            }

            h1, h2 {
                padding-left: 55px;
            }

            h2 {
                margin-top: 10px;
            }
        }
    }

    .header-panel,
    .header-panel-table,
    .header-panel-connect {

        &.sticky {
            position: static;
            top: auto;
            z-index: auto;
        }
    }
}
