@import "../../node_modules/bootstrap/scss/variables";
@import "custom-variables";

@import "../../node_modules/tinymce/skins/ui/oxide/skin.min";

.tag-bar {
    background: $whiteblue;
    border: 1px solid $medium-gray;
    padding: 0 15px;
    border-bottom-width: 3px;
    position: relative;
    top: -3px;

    > div {
        display: inline-block;
        vertical-align: middle;
        margin-right: 5px;
        padding: 6px 0;

        .wj-form-group-sm {
            margin: 0;
        }

        &:first-child {
            font-size: $large-font-size;
        }

        .btn {
            padding: 5px 8px 4px;
            font-size: $font-size-small;
        }

        .insert-btn {
            margin-right: 30px;
            border-radius: 0;
            padding: 6px 6px 5px;
            position: relative;
            left: -5px;
        }
    }
}

.tox .tox-toolbar, .tox .tox-toolbar__overflow, .tox .tox-toolbar__primary {
    background-color: var(--primary-white-color);
}

.tox:not(.tox-tinymce-inline) .tox-editor-header {
    background-color: var(--primary-white-color);
    box-shadow: unset;
    border-bottom: 1px solid var(--medium-gray);
}

.tox-tinymce {
    border-top-right-radius: 0;
    border-top-left-radius: 0;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
    border: 1px solid var(--medium-gray);
}

