@import "../../node_modules/bootstrap/scss/variables";
@import "custom-variables";
/* Wijmo Control */

/* Primary wj control style - applies to all controls */
.wj-content {
    display: inline-block;
    background-color: white;
    outline: none;
    box-sizing: border-box;
    /* set display: inline-table so height:100% works properly even without <!DOCTYPE html> tag */
    &.wj-dropdown,
    &.wj-inputnumber,
    &.wj-inputmask,
    &.wj-calendar-outer,
    &.wj-pager {
        display: inline-table;
    }

    &.wj-listbox {
        border: 1px solid $lightgray;
    }

    & input[type="radio"],
    input[type="checkbox"] {
        position: relative;
        top: 2px;
        margin-right: 6px;
        margin-left: 2px;
        transform: scale(1.25);
    }

    &.wj-dropdown, //add other controls - default styling
    &.wj-inputnumber {
        margin-top: 4px;
        width: 100%;
    }

    &.wj-dropdown .wj-input-group .wj-form-control[readonly]:not([disabled]) {
        background-color: white;
    }

    &.wj-dropdown:not(.wj-state-collapsed) .wj-input-group-btn .wj-btn {
        background-color: $blue;
        border: 1px solid $blue;
        border-bottom: 3px solid $blue-darken-7percent;

        & .wj-glyph-down {
            color: white !important;
        }
    }
}

.wj-control {
    outline: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

/*
  Required to make contenteditable work on iOS
  http://stackoverflow.com/questions/21295698/html5-contenteditable-attribute-not-working-properly-on-ios7-mobile-safari
*/
.wj-control [contenteditable] {
    -webkit-user-select: text;
    user-select: text;
}

/* Define control header */
.wj-header {
    background-color: $lightgray;
    color: $gray;
    font-weight: bold;
}

.wj-state-disabled,
.wj-control[disabled] {
    background-color: $whitegray;
    cursor: not-allowed;

    .wj-input-group-btn {
        cursor: not-allowed;
        pointer-events: none;
    }
}

/* Readonly elements (via CSS) */
/*.wj-state-readonly,
.wj-control[readonly] {
    background-color: $whitegray;
}*/

/* Button Styles */

/* Basic style for all button types */
button,
html input[type="button"],
input[type="reset"],
input[type="submit"] {
    -webkit-appearance: button;
    overflow: visible;
    border-radius: inherit;
    cursor: pointer;
}

/* Global base button style */
.wj-btn {
    /*display: block;*/
    padding: 0 10px;
    vertical-align: top;
    text-align: center;
    white-space: nowrap;
    cursor: pointer;
}

/* Additional styling for default buttons */

.wj-btn-default {
    border: 1px solid $black-alpha-20;
    background-color: transparent;
    color: inherit;
    /*font-weight: bold;*/
}

/* Hover on 'default' button */
.wj-btn-default:hover {
    background-color: $black-alpha-10;
}

.wj-btn-default:focus {
    background-color: $black-alpha-10;
}

/* Link Buttons in Wijmo controls*/
.wj-control button[wj-part^="btn-"] {
    display: inline-block;
    margin-bottom: 0;
    font-weight: normal;
    text-align: center;
    vertical-align: middle;
    touch-action: manipulation;
    cursor: pointer;
    white-space: nowrap;
    padding: 10px;
    font-size: 14px;
    line-height: 1.42857143;
    border-radius: 4px;
    user-select: none;
    transition-duration: 0.2s;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.08), 0 1px 1px rgba(0, 0, 0, 0.16) !important;
    outline: none !important;
    background-color: var(--primary-color);
    color: #fff;
    border-color: var(--primary-color);
}

/* Button Group Styles */

/* Define button group based on bootstrap */
.wj-btn-group,
.wj-btn-group-vertical {
    position: relative;
    display: inline-block;
    border-radius: 4px;
    vertical-align: top;
}

/* Remove inner button border radius */
.wj-btn-group > .wj-btn:not(:first-child):not(:last-child) {
    border-radius: 0;
}

.wj-btn-group > .wj-btn:first-child {
    margin-left: 0;
}

/* Remove first button right-side border radius and margin */
.wj-btn-group > .wj-btn:first-child:not(:last-child) {
    border-right: none;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

/* Remove last button left-side border radius and margin */
.wj-btn-group > .wj-btn:last-child:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

/* Remove margin from inner buttons to prevent double border */
.wj-btn-group .wj-btn + .wj-btn,
.wj-btn-group .wj-btn + .wj-btn-group,
.wj-btn-group .wj-btn-group + .wj-btn,
.wj-btn-group .wj-btn-group + .wj-btn-group {
    margin-left: -1px;
}

/* ListBox Styles */

.wj-listbox {
    overflow: auto;
    cursor: default;
}

.wj-listbox-item {
    box-sizing: border-box;
    padding: 3px 10px;

    &.wj-separator {
        height: 1px;
        margin: 3px 0;
        padding: 0;
        background-color: $black-alpha-10;
    }

    &.wj-select-all {
        background-color: transparent;
        border-bottom: 1px solid $blue;
        padding-top: 5px;

        label {
            font-weight: bold;
        }
    }

    &.wj-state-selected,
    &:not(.wj-state-selected):not(.wj-state-disabled):not(.wj-separator):hover {
        background-color: $whiteblue;
    }
}

.wj-listbox-item.wj-state-selected input[type="checkbox"]:focus {
    outline: none;
}

.wj-listbox .wj-listbox-item label {
    font-weight: normal;
    margin: 0;
}

.wj-listbox-item label {
    & span[wj-part="sp-select-all"] {
        font-weight: bold;
    }
}

.wj-dropdown .wj-listbox {
    padding: 0;
    white-space: nowrap;
}

.wj-listbox-item.wj-state-selected .wj-control {
    background: $white;
    color: $charcoal-gray;
}

/* Dropdown Styles */

.wj-dropdown {
    vertical-align: middle;
}

.wj-dropdown .wj-template,
.wj-dropdown .wj-dropdown-menu {
    border-radius: inherit;
}

.wj-template {
    height: 100%;
}

.wj-dropdown-panel {
    outline: none;
    box-shadow: $box-shadow-3;
    /* Boostrap modals have zIndex 1050, so go higher */
    z-index: 1500 !important;
    border: 1px solid $blue;
}

.wj-autocomplete-match {
    font-weight: bold;
}

/* Input Group Styles */

/* Remove spinner buttons from InputNumber control */
.wj-inputnumber input[type="number"]::-webkit-inner-spin-button,
.wj-inputnumber input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Remove clear icon from IE textbox */
input::-ms-clear {
    display: none;
}

/* Remove default browser focus outlines */
button,
input,
optgroup,
select,
textarea {
    outline: 0;
}

.wj-inputnumber {
    vertical-align: middle;
}

.wj-input-group-btn,
.wj-input-group .wj-form-control {
    box-sizing: border-box;
    display: table-cell;
}

.wj-input {
    height: 100%;
    overflow: hidden;

    input {
        width: 100%;
    }
}

/* Define input group based on bootstrap */
.wj-input-group {
    position: relative;
    display: table;
    overflow: hidden;
    width: 100%;
    height: 100%;
    border-collapse: separate;
    background: inherit;

    & .wj-btn {
        font-size: 20px;
        padding: 0;
        width: 38px;
        height: 36px;
        border-radius: 0;
        border-bottom: 3px solid $lightgray;

        &:hover,
        &:focus {
            border-color: $blue;
            border-bottom: 3px solid $blue-darken-7percent;
            background-color: $blue;

            & [class^="wj-glyph-"] {
                color: white;
            }
        }
    }
}

/* Style for text input box in group */
.wj-input-group .wj-form-control {
    display: block;
    width: 100%;
    background-image: none;
    border: 1px solid #ccc;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    -webkit-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
    -o-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
    color: var(--grayscale-color);
    height: auto;
    border-bottom: 3px solid var(--grayscale-light-color);
    min-height: 36px;
}

.wj-form-group-sm .wj-input-group .wj-form-control {
    .form-control {
        font-size: $font-size-base;
        min-height: 30px;
        height: 30px;
        padding: 2px 10px 0;
        border-radius: 0;
        line-height: 2;
    }
}

/* Text alignment for numeric input types */
.wj-input-group .wj-form-control.wj-numeric {
    text-align: right;
}

/* Adjust float, width, and padding for Menu control */
.wj-input-group div[wj-part="header"] {
    float: none;
    width: auto;
    vertical-align: middle;
    padding: 6px 8px;
}

/* RTL borders */

/* Remove first button left-side border radius */
[dir="rtl"] .wj-input-group-btn:first-child > .wj-btn {
    border-left-width: 1px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    /* Unset LTR border styles */
    border-right-width: 0;
    border-top-right-radius: inherit;
    border-bottom-right-radius: inherit;
}

/* Remove last button right-side border radius */
[dir="rtl"] .wj-input-group-btn:last-child > .wj-btn {
    border-right-width: 1px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    /* Unset LTR border styles */
    border-left-width: 0;
    border-top-left-radius: inherit;
    border-bottom-left-radius: inherit;
}

/* Style for buttons in group */
.wj-input-group-btn {
    position: relative;
    width: 28px;
    height: 100%;
    vertical-align: top;
    white-space: nowrap;
}

/* Pager Styles */

.wj-pager {
    vertical-align: middle;
    margin-left: 5px;
    margin-right: 5px;

    & .wj-input-group-btn > .wj-btn {
        min-width: 40px;
    }

    & .wj-btn[disabled] span {
        opacity: 0.5;
        cursor: default;
    }

    & .wj-form-control {
        text-align: center;
        border-left: 1px solid $black-alpha-20;
        border-right: 1px solid $black-alpha-20;
    }
}

/* Calendar Date Input Styles */

.wj-calendar-outer {
    display: block;
    padding: 10px;
    width: 100%;
    height: auto;
    cursor: default;
}

.wj-calendar td {
    text-align: center;
}

/* In dropdown, set width to avoid flicker as the user navigates months (in IE only...) */
.wj-dropdown-panel.wj-calendar {
    width: 20em;
}

/* In dropdown, remove background and border */
.wj-dropdown-panel .wj-calendar-outer {
    border: 0;
    background: none;
}

.wj-day-today {
    font-weight: bold;
}

.wj-day-othermonth {
    opacity: 0.5;
}

/* -- Date selection -- */

/* Added padding to bottom of date selection options */
.wj-calendar-header {
    display: block;
    padding: 0 0 15px 0;
    width: 100%;
}

/* Float month selection to the left */
.wj-month-select {
    float: left;
    display: inline-block;
    cursor: pointer;
}

/* Float calendar button group to the right */
.wj-calendar-header .wj-btn-group {
    float: right;
    display: inline-block;
    margin-bottom: 7px;
}

/* Size button group in calendar header */
.wj-calendar-header .wj-btn-group .wj-btn {
    padding: 0 8px 1px 8px;
    min-height: 25px;
}

/* -- Days of the month -- */

.wj-calendar-month {
    box-sizing: border-box;
    width: 100%;
    border-collapse: collapse;
    font: inherit;
}

.wj-calendar-month td {
    width: 14.29%;
    padding: 5px 0;
    border: none;
}

/* Slightly reduce size of text in calendar header */
.wj-calendar-month .wj-header {
    font-size: 90%;
}

/* -- Months of the year -- */

.wj-calendar-year {
    box-sizing: border-box;
    width: 100%;
    border-collapse: collapse;
    font: inherit;
}

.wj-calendar-year td {
    width: 25%;
    padding: 8px;
    border: none;
}

/* -- FlexGrid -- */

.wj-flexgrid .wj-cell:not(.wj-frozen):not(.wj-frozen-col) {
    z-index: auto !important;
}

.wj-flexgrid {
    width: 100%;
    overflow: hidden;
    border: 1px solid $border-table-color;
    position: relative;
    top: -1px;

    & .wj-colheaders,
    & .wj-topleft {
        & .wj-header {
            border-bottom: 2px solid $border-table-color;
            background: $whiteblue-darken-2percent linear-gradient( to bottom, $whiteblue, $whiteblue 50%, $whiteblue-darken-6percent );
            color: $gray;
            font-weight: normal;
            text-align: left !important;
            padding: 0;
            min-height: 38px;

            &.wj-sort-desc:after,
            &.wj-sort-asc:after {
                content: "";
            }

            & .wj-glyph-up,
            & .wj-glyph-down {
                padding: 0 4px;
                font-size: $large-font-size;
                line-height: 1;
            }

            & .blue-background {
                padding: 8px 10px;
                background: $whiteblue-darken-2percent linear-gradient( to bottom, $whiteblue, $whiteblue 50%, $whiteblue-darken-6percent );
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
            }

            & .green-background {
                padding: 8px 10px;
                background: $lightgreen-darken-2percent linear-gradient( to bottom, $lightgreen, $lightgreen-darken-6percent );
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
            }

            & .has-filter {
                padding-right: 30px;
            }

            & .word-wrap {
                white-space: normal;
                background: transparent none;
            }
            /* Selected headers */
            &.wj-state-multi-selected {
                background-color: $lightgreen;
                color: $gray;
            }

            & .btn-col .btn {
                top: 5px;
                left: 10px;
            }

            & .dropdown-menu {
                min-width: 125px;

                & > li {
                    &:hover,
                    &:focus {
                        background-color: $whitegray;
                    }
                }
            }
        }
    }

    & .wj-colfooters {
        & .wj-header {
            border-top: 2px solid $border-table-color;
            background-color: $whitegray;
            font-weight: bold;
        }
    }

    & .wj-rowheaders {
        & .wj-header {
            background: $whiteblue-darken-2percent linear-gradient( to bottom, $whiteblue, $whiteblue-darken-6percent );
            cursor: move;

            &:before,
            &:after {
                font-family: "FontAwesome", sans-serif;
                content: "\f142";
                font-size: 18px;
                color: $lightblue;
                position: absolute;
                left: 9px;
            }

            &:after {
                left: 16px;
            }

            &:hover::before,
            &:hover::after {
                color: $blue;
            }
        }
    }

    & .wj-cell {
        position: absolute;
        overflow: hidden;
        box-sizing: border-box;
        white-space: nowrap;
        text-overflow: ellipsis;
        padding: 8px 10px;
        border-right: 1px solid $table-cell-border-color;
        border-bottom: 1px solid $table-cell-border-color;
        outline: none;
        background: white;
        /*Edit button column - width defined in html property*/
        &.btn-col:not(.wj-group),
        &.btn2-col:not(.wj-group) {
            padding: 5px 10px !important;
            overflow: visible;

            .btn ~ .btn {
                float: right;
            }

            & .checked-bkg {
                width: 100%;
                height: 100%;
                background-color: $lightgreen;
                position: absolute;
                top: 0;
                left: 0;
                z-index: 0;
            }
        }

        &.dynamic-col:not(.wj-group) {
            padding: 4px 10px 6px;
            overflow: visible;

            & .checked-bkg {
                width: 100%;
                height: 100%;
                background-color: $lightgreen;
                position: absolute;
                top: 0;
                left: 0;
                z-index: 0;
            }
        }

        &.wj-frozen {
            @for $i from 1 through 10 {
                &:nth-of-type(#{$i}) {
                    overflow: visible;
                    z-index: #{11 - $i} !important;
                }
            }
        }

        &.btn3-col:not(.wj-group) {
            padding: 0 0 !important;
            overflow: visible;

            .btn {
                margin-right: 2px;
            }

            .btn:last-child {
                margin-right: none;
            }

            div {
                margin: 5px auto;
                width: 82px;
            }
        }

        &.dynamic-col.wj-group,
        &.btn-col.wj-group,
        &.btn2-col.wj-group {
            padding: 4px 10px 6px;
            overflow: visible;
            z-index: 1 !important;

            .btn ~ .btn {
                float: right;
            }
        }

        &.wj-group[role="gridcell"]:not(.wj-frozen) {
            z-index: unset !important;
        }
        /*Override boolean datatype*/
        & input[type="checkbox"].wj-cell-check {
            visibility: hidden;
            top: 0;
            margin: 0;

            &:checked::after {
                font-family: "FontAwesome", sans-serif;
                content: "\f00c";
                font-size: $font-size-base;
                position: absolute;
                color: $green;
                visibility: visible;
            }
        }
        /* Frozen area boundaries */
        &.wj-frozen-row {
            border-bottom: 1px solid $border-table-color;
        }

        &.wj-frozen-col {
            border-right: 1px solid $border-table-color;
        }
        /* Alternate grid cell color */
        &.wj-alt {
            background: $accent-table-bg;
        }
        /*Wijmo Selected States */
        // TODO See if this did anything
        // &.wj-state-selected,
        // &.wj-state-multi-selected {
        // }

        &.row-selected {
            background-color: $lightgreen;
        }

        &.row-active-search {
            background-color: $lightgreen;
        }

        &.row-disabled {
            cursor: not-allowed;
            opacity: 0.65;

            &.btn-col button {
                display: none;
            }
        }

        &.checkbox-disabled {
            cursor: not-allowed;

            button.btn {
                display: block;
                pointer-events: none;
                opacity: 0.65;
            }
        }
        /* Text color for nested controls in selected rows */
        &.wj-state-selected .wj-control,
        &.wj-state-multi-selected .wj-control {
            color: $gray;
        }
    }
    /* Group row background */
    .wj-group,
    .wj-cell.wj-group /*,
    .wj-cell.wj-group.wj-state-multi-selected,
    .wj-cell.wj-group.wj-state-selected*/ {
        background-color: $blue;
        border-bottom: 2px solid $lightblue;
        color: white;
        border-right: 0;
        /*height: 37px !important;*/
        & .wj-elem-collapse {
            padding: 0;
            background-color: $blue;
            border: 0;
            width: 26px;
            line-height: 22px;
            display: inline-block;
            text-align: center;
            position: relative;
            top: 3px;
            font-size: 22px;
            font-weight: bold;

            & span {
                color: white;
            }
        }
    }
    /* Selection Marquee */
    & .wj-marquee {
        position: absolute;
        box-sizing: border-box;
        border: 2px solid $bondi-blue;
    }
    /* Grid drag/resize row/col marker */
    & .wj-marker {
        position: absolute;
        background-color: $green;
        pointer-events: none;
    }

    &.wj-state-focused {
        .wj-cell:not(.btn-col):not(.check-col):not(.wj-group) {
            &.wj-state-selected {
                background: $whiteblue-lighten-4percent;
            }

            &.column-editable.wj-state-selected,
            &.wj-state-multi-selected {
                background: $lightgreen;
            }
        }
    }
}

.flexgrid-treeview .wj-state-focused {
    .wj-cell.wj-group {
        &.wj-state-selected {
            background: $whiteblue-lighten-4percent;
        }

        &.column-editable.wj-state-selected,
        &.wj-state-multi-selected {
            background: $lightgreen;
        }
    }
}

//Overrides

.pagination-container .wj-inputnumber {
    margin: 0;
    width: auto;
}

.flexgrid-single-select .wj-flexgrid .wj-colheaders .wj-header:first-of-type,
.flexgrid-multi-select
.wj-flexgrid
.wj-colheaders
.wj-header:nth-of-type(-n + 2),
.wj-flexgrid .wj-topleft .wj-header {
    overflow: visible;

    &:hover::after {
        content: "";
    }
}

.flexgrid-multi-select .wj-flexgrid .wj-colheaders .wj-header:first-of-type {
    z-index: 2 !important;
}

.flexgrid-multi-select {
    position: relative;

    & .dropdown-menu {
        min-width: 125px;
        top: 34px;
        left: 10px;

        & > li {
            &:hover,
            &:focus {
                background-color: $whitegray;
            }
        }

        &.opened {
            display: block;
        }
    }
}

.wj-flexgrid .wj-header:not(.wj-filter-off) {
    overflow: visible;
}

.flexgrid-pagination .wj-flexgrid {
    .pagination-btn-container {
        position: absolute;
        left: 10px;
        top: 5px;

        .btn + .btn {
            margin-left: 10px;
        }

        + .pagination-btn-container {
            left: 56px;
        }
    }

    & [wj-part="root"] {
        overflow-y: hidden !important;
    }
}
/*.flexgrid-hide-sorting-hover .wj-flexgrid .wj-colheaders .wj-header:hover::after,
.flexgrid-hide-sorting-hover .wj-flexgrid .wj-topleft .wj-header:hover::after {
    display: none;
}*/
.flexgrid-no-sorting .wj-flexgrid .wj-colheaders .wj-header,
.flexgrid-no-sorting .wj-flexgrid .wj-topleft .wj-header {
    cursor: default;
}

.folder-icons {
    .wj-flexgrid .wj-group {
        .wj-glyph-right,
        .wj-glyph-down-right {
            margin-right: 12px !important;
            top: 5px !important;
            left: 2px;
        }

        .wj-glyph-right:after {
            content: "\f07b";
        }

        .wj-glyph-down-right:after {
            content: "\f07c";
        }
    }

    .flexgrid-treeview .wj-flexgrid .wj-group {
        .wj-elem-collapse {
            margin-right: 5px !important;
            border: none;
            color: $blue;
            background-color: transparent;

            &:hover {
                color: $lightblue;
                background-color: transparent;
            }

            &:after {
                top: 4px;
                right: 4px;
            }

            &.wj-glyph-right,
            &.wj-glyph-down-right {
                background-color: transparent;
                top: -3px !important;
                color: $blue;

                &:hover {
                    color: $lightblue;
                    background-color: transparent;
                }
            }
        }
    }
}
/* FlexGrid Filter Dropdown */
.wj-header .wj-elem-filter {
    border: 0;
    float: right;
    cursor: pointer;
    padding: 0 4px;
    background-color: $lightblue;
    border-radius: $border-radius-small;
    color: white;
    position: absolute;
    right: 8px;
    top: 8px;
    line-height: $line-height-base;
    box-shadow: $box-shadow-1;

    &:hover {
        background-color: $blue;
    }
}

[dir="rtl"] .wj-header .wj-elem-filter {
    float: left;
}

.wj-header.wj-filter-on,
.wj-header.wj-filter-off {
    padding-right: 32px;
}

.wj-filter-on .wj-elem-filter {
    background-color: $green;

    &:hover {
        background-color: $darkgreen;
    }
}

.wj-header .wj-glyph-filter {
    &:after {
        font-family: "FontAwesome", sans-serif;
        content: "\f0b0";
        font-size: 13px;
    }
}

.wj-columnfiltereditor {
    padding: 15px;
    //Important is used here to override the inline style computed by the wijmo library
    min-width: 300px !important;
    max-width: 50%;

    & .wj-listbox {
        max-height: 165px;
    }

    & div[wj-part^="div-sort"] {
        display: none;
        border-bottom: 1px solid $lightgray;
    }

    & div[wj-part^="div-type"] {
        font-size: $font-size-base;
        text-align: left;
        border-bottom: 1px solid $lightgray;
        margin-bottom: 10px;
        width: 100%;
        height: 36px;
        color: white; //hide | character
        & a[wj-part="a-cnd"],
        & a[wj-part="a-val"] {
            border: 1px solid $lightgray;
            border-radius: 4px 4px 0 0;
            font-weight: normal !important;
            color: $gray;
            padding: 7px 12px;
            position: absolute;

            &:hover,
            &:focus {
                color: $gray;
                background-color: $whiteblue;
            }
        }

        & a[wj-part="a-val"] {
            top: 15px;
            left: 15px;
        }

        & a[wj-part="a-cnd"] {
            top: 15px;
            left: 142px;
        }
    }

    & div[wj-part^="div-edt-val"]
    div[wj-part^="div-values"]
    div:not(.wj-listbox-item):before {
        content: "No Results Found";
        position: relative;
        top: 3px;
        left: 10px;
    }
    //Wijmo Hack to give active state to filter buttons
    & div[wj-part^="div-edt-cnd"]:before,
    & div[wj-part^="div-edt-val"]:before {
        content: "";
        background-color: $blue;
        color: white;
        border: 1px solid $blue;
        border-radius: 4px 4px 0 0;
        padding: 7px 12px;
        position: absolute;
    }

    & div[wj-part^="div-edt-val"]:before {
        content: "Filter by Value";
        top: 15px;
        left: 15px;
        font-size: 14px;
    }

    & div[wj-part^="div-edt-cnd"]:before {
        content: "Filter by Condition";
        top: 15px;
        left: 142px;
        font-size: 14px;
    }

    & div[wj-part^="div-edt-cnd"] label {
        float: left;
        width: 30%;
        margin: 10px 0 12px;
    }

    & .wj-control {
        margin-bottom: 6px;
        width: 100%;
    }

    & .wj-listbox-item.wj-state-selected {
        background-color: transparent;
    }

    & .wj-listbox .wj-listbox-item label {
        overflow: visible;
        white-space: nowrap;
    }
}

.modal .wj-columnfiltereditor {
    max-width: 300px;
}
/* Selected column headers */
.wj-colheaders .wj-header.wj-state-multi-selected {
    border-bottom: 2px solid $bondi-blue;
}
/* Selected row headers */
.wj-rowheaders .wj-header.wj-state-multi-selected {
    border-right: 2px solid $bondi-blue;
}
/* Sticky headers */
.wj-state-sticky .wj-header {
    opacity: 0.75;
}
/* Invalid state */
.wj-cell.wj-state-invalid {
    border: 1.2px solid red;
}

.wj-rowheaders .wj-cell.wj-state-invalid {
    background: $fullwhite-alpha-30;
}

.wj-cells .wj-cell.wj-state-invalid:after {
    position: absolute;
    content: "";
    width: 0;
    right: 0;
    top: -6px;
    border: 6px solid transparent;
    border-right: 6px solid red;
}
/* Cells with word-wrapping */
.wj-cell.wj-wrap {
    white-space: normal;
    text-overflow: clip;
}
/*Duplicate check striping*/
.dupe-check-striping .wj-cells .wj-cell {
    &:nth-child(36n-17),
    &:nth-child(36n-16),
    &:nth-child(36n-15),
    &:nth-child(36n-14),
    &:nth-child(36n-13),
    &:nth-child(36n-12),
    &:nth-child(36n-11),
    &:nth-child(36n-10),
    &:nth-child(36n-9),
    &:nth-child(36n-8),
    &:nth-child(36n-7),
    &:nth-child(36n-6),
    &:nth-child(36n-5),
    &:nth-child(36n-4),
    &:nth-child(36n-3),
    &:nth-child(36n-2),
    &:nth-child(36n-1),
    &:nth-child(36n) {
        background: $accent-table-bg;
    }
}
/* Treeview */
.flexgrid-treeview {
    .tree-btn-container {
        position: absolute;
        left: 10px;
        top: 5px;

        .btn + .btn {
            margin-left: 10px;
        }

        + .tree-btn-container {
            left: 56px;
        }
    }

    .treeview-shadebox {
        border-left: 1px solid $table-cell-border-color;
    }

    .border-line,
    .border-line-2 {
        width: 1px;
        height: 100%;
        background-color: $table-cell-border-color;
        position: absolute;
        top: 0;
        //TODO which left property is the right one?
        left: 0;
        z-index: 0;
        left: 46px;
    }
}

.flexgrid-treeview.flexgrid-single-select,
.flexgrid-treeview.flexgrid-multi-select {
    .treeview-shift {
        padding-left: 46px;
    }

    .treeview-shadebox {
        left: 46px;
    }
}

.flexgrid-treeview.flexgrid-single-select.flexgrid-two-buttons {
    .treeview-shift {
        padding-left: 82px;
    }

    .treeview-shadebox,
    .border-line-2 {
        left: 82px;
    }
}

.flexgrid-treeview.flexgrid-multi-select.flexgrid-two-buttons {
    .treeview-shift {
        padding-left: 92px;
    }

    .treeview-shadebox,
    .border-line-2 {
        left: 92px;
    }
}
/* Treeview Group row */
.flexgrid-treeview .wj-flexgrid {
    & .wj-group {
        background-color: white;
        color: $gray;
        border-bottom: 1px solid $table-cell-border-color;
        border-right: 1px solid $table-cell-border-color;

        & .wj-elem-collapse {
            color: white;
            width: 15px;
            height: 17px;
            margin-right: 10px;
            font-size: 16px;
            font-weight: bold;
            padding: 1px;
            border: 1px solid $blue;
            background-color: $blue;
            border-radius: 2px;
            line-height: 15px;
            top: 1px;

            &:after {
                position: relative;
                bottom: 1px;
            }

            &:hover {
                background-color: $lightblue;
                color: white;
            }
            /*opened*/
            &.wj-glyph-down-right {
                color: $blue;
                background-color: white;

                &:hover {
                    background-color: $whiteblue;
                }
            }
        }

        &.wj-alt {
            background: $accent-table-bg;
        }

        &.row-selected {
            background-color: $lightgreen;
        }
    }
}
/* Grid editor */
.wj-flexgrid .wj-grid-editor {
    box-sizing: border-box;
    padding: 7px 10px;
    border: 1px solid $green;
    width: calc(100% + 20px);
    position: relative;
    left: -10px;

    &.wj-combobox {
        padding: 0;
    }
}

.wj-cell .wj-elem-dropdown {
    position: absolute;
    top: 0;
    padding: 3px 6px;
    opacity: 0.25;
    right: 0;
}

[dir="rtl"] .wj-cell .wj-elem-dropdown {
    left: 0;
    right: auto;
}
/* Switch cell borders in RTL grids */
[dir="rtl"] .wj-cell {
    border-left: 1px solid $silver;
    border-right: none;
}
/* Switch frozen borders in RTL grids */
[dir="rtl"] .wj-frozen-col {
    border-left: 1px solid $dim-gray;
    border-right: none;
}
/* FlexGrid GroupPanel extension */
.wj-grouppanel {
    background-color: $white-smoke;
    padding: 15px;
}

.wj-grouppanel .wj-cell {
    margin-right: 10px;
    padding: 6px 16px;
    border: 1px solid $white-dark-smoke;
    cursor: pointer;
}

.wj-grouppanel .wj-cell:hover {
    background: $gainsboro-gray;
}
/* FlexGrid MultiRow extension */
.wj-multirow .wj-cell.wj-record-end:not(.wj-header) {
    border-bottom-color: $light-matte-gray;
}

.wj-multirow .wj-cell.wj-group-end {
    border-right-color: $light-matte-gray;
}

.wj-multirow .wj-hdr-collapse {
    position: absolute;
    padding: 6px;
    right: 0;
    top: 0;
    color: $bondi-blue;
    opacity: 0.5;
}

.wj-multirow .wj-hdr-collapse:hover {
    opacity: 1;
}
/* FlexSheet extension */
.wj-flexsheet .wj-marquee {
    position: absolute;
    box-sizing: border-box;
    border: 2px solid $bondi-blue;
}

.wj-flexsheet .wj-state-multi-selected {
    background: $white-dark-smoke;
    color: $nero-dark;
}

.wj-flexsheet .wj-cell.wj-state-multi-selected {
    border-right: 1px solid $silver-light;
    border-bottom: 1px solid $silver-light;
}
/* Tooltip */
.wj-tooltip {
    position: absolute;
    z-index: 1000;
    top: 0;
    left: 0;
    pointer-events: none;
    max-width: 400px;
    padding: 6px;
    background-color: $light-yellow;
    border: 1px solid $black-alpha-10;
    border-radius: 6px;
    box-shadow: $box-shadow-3;
    box-sizing: border-box;
}
/* ColorPicker */
.wj-inputcolor {
    & .wj-form-control {
        padding-left: 34px !important;
    }

    & .wj-inputcolorbox {
        left: 10px !important;
        top: 9px !important;
        width: 15px !important;
        bottom: 11px !important;
        border: 1px solid $gray !important;
        border-radius: 2px;
    }
}

.wj-colorpicker {
    width: 420px;
    height: 200px;
    padding: 4px;
}

.wj-colorbox {
    position: relative;
    width: 100%;
    height: 100%;
    cursor: pointer;
    box-shadow: $box-shadow-2;
}
/* wj-popup */
.wj-popup {
    background-color: $white;
    box-shadow: $box-shadow-13;
    z-index: 1500;
    margin: 2px 0;
}

.wj-popup-backdrop {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 1500;
    background-color: $black-alpha-50;
}
/**************************************************************************
    Glyphs
*/
.wj-glyph-up,
.wj-glyph-down,
.wj-glyph-right,
.wj-glyph-down-right {
    color: $blue;
    cursor: pointer;
    font-size: 26px;
    font-family: "FontAwesome", sans-serif;
}

.wj-glyph-up:after {
    content: "\f0d8";
}

.wj-glyph-down:after {
    content: "\f0d7";
}

.wj-glyph-right:after {
    content: "\f105";
}

.wj-glyph-down-right:after {
    content: "\f107";
}
/*.wj-glyph-up {
    position: relative;
    display: inline-block;
    border-right: .4em solid transparent;
    border-bottom: .5em solid;
    border-left: .4em solid transparent;
    opacity: 0.75;
    cursor: pointer;
}

.wj-glyph-down {
    position: relative;
    display: inline-block;
    border-top: .5em solid;
    border-right: .4em solid transparent;
    border-left: .4em solid transparent;
    opacity: 0.75;
    cursor: pointer;
}*/
.wj-glyph-left,
.wj-glyph-step-backward {
    position: relative;
    display: inline-block;
    border-top: 0.4em solid transparent;
    border-right: 0.5em solid;
    border-bottom: 0.4em solid transparent;
    opacity: 0.75;
    cursor: pointer;
}

.wj-glyph-step-backward {
    transform: translateX(0.12em);
}

.wj-glyph-step-backward:after {
    position: absolute;
    border-left: 0.2em solid;
    height: 0.75em;
    transform: translate(-100%, -50%);
    content: "";
}
/*.wj-glyph-right,
.wj-glyph-step-forward {
    position: relative;
    display: inline-block;
    border-top: .4em solid transparent;
    border-bottom: .4em solid transparent;
    border-left: .5em solid;
    opacity: 0.75;
    cursor: pointer;
}*/
.wj-glyph-step-forward:after {
    position: absolute;
    border-left: 0.2em solid;
    height: 0.75em;
    transform: translateY(-50%);
    content: "";
}

.wj-glyph-left-rtl,
.wj-glyph-step-backward-rtl {
    position: relative;
    display: inline-block;
    border-top: 0.4em solid transparent;
    border-bottom: 0.4em solid transparent;
    border-left: 0.5em solid;
    opacity: 0.75;
    cursor: pointer;
}

.wj-glyph-step-backward-rtl:after {
    position: absolute;
    border-left: 0.2em solid;
    height: 0.75em;
    transform: translate(100%, -50%);
    content: "";
}

.wj-glyph-right-rtl,
.wj-glyph-step-forward-rtl {
    position: relative;
    display: inline-block;
    border-top: 0.4em solid transparent;
    border-right: 0.5em solid;
    border-bottom: 0.4em solid transparent;
    opacity: 0.75;
    cursor: pointer;
}

.wj-glyph-step-forward-rtl {
    transform: translateX(0.12em);
}

.wj-glyph-step-forward-rtl:after {
    position: absolute;
    border-left: 0.2em solid;
    height: 0.75em;
    transform: translateY(-50%);
    content: "";
}

.wj-glyph-down-left {
    position: relative;
    display: inline-block;
    border-top: 0.65em solid transparent;
    border-left: 0.65em solid;
    opacity: 0.75;
    cursor: pointer;
}
/*.wj-glyph-down-right {
    position: relative;
    display: inline-block;
    border-bottom: .65em solid;
    border-left: .65em solid transparent;
    opacity: 0.75;
    cursor: pointer;
}*/
.wj-glyph-up-left {
    position: relative;
    display: inline-block;
    border-bottom: 0.65em solid transparent;
    border-left: 0.65em solid;
    opacity: 0.75;
    cursor: pointer;
}

.wj-glyph-up-right {
    position: relative;
    display: inline-block;
    border-top: 0.65em solid;
    border-left: 0.65em solid transparent;
    opacity: 0.75;
    cursor: pointer;
}

.wj-glyph-circle {
    position: relative;
    display: inline-block;
    border: 0.25em solid;
    border-radius: 1em;
    transform: translateY(-0.1em);
    opacity: 0.75;
    cursor: pointer;
}

.wj-glyph-square {
    position: relative;
    display: inline-block;
    border: 0.25em solid;
    opacity: 0.75;
    cursor: pointer;
}

.wj-glyph-diamond {
    position: relative;
    display: inline-block;
    border: 0.25em solid;
    transform: rotate(45deg);
    transform-origin: 50% 50%;
    opacity: 0.75;
    cursor: pointer;
}

.wj-glyph-check {
    position: relative;
    display: inline-block;
    width: 0.75em;
    height: 0.75em;
    border-right: 0.3em solid;
    border-bottom: 0.22em solid;
    transform: rotate(35deg) scaleX(0.5);
    opacity: 0.75;
    cursor: pointer;
}
/*.wj-glyph-filter {
    position: relative;
    box-sizing: border-box;
    display: inline-block;
    top: -.1em;
    width: .5em;
    border-top: .5em solid;
    border-right: .4em solid transparent;
    border-left: .4em solid transparent;
    opacity: 0.75;
    cursor: pointer;
}
    .wj-glyph-filter:after {
        position: absolute;
        box-sizing: border-box;
        border-left: .2em solid;
        height: .4em;
        transform: translateX(-50%);
        top: -.2em;
        content: "";
    }

[dir="rtl"] .wj-glyph-filter:after {
    transform: translateX(50%);
}*/
.wj-glyph-plus,
.wj-glyph-minus {
    position: relative;
    box-sizing: border-box;
    display: inline-block;
    border-top: 0.25em solid;
    width: 0.9em;
    top: -0.2em;
    opacity: 0.75;
    cursor: pointer;
}

.wj-glyph-plus:after {
    position: absolute;
    box-sizing: border-box;
    border-left: 0.25em solid;
    width: 0.25em;
    height: 0.85em;
    left: 0.32em;
    top: -0.55em;
    content: "";
}

.wj-glyph-file {
    position: relative;
    display: inline-block;
    border-left: 0.7em solid;
    height: 0.85em;
    opacity: 0.75;
    cursor: pointer;
}

.wj-glyph-file:after {
    position: absolute;
    transform: translateX(-100%);
    border-top: 0.3em solid transparent;
    border-left: 0.3em solid white;
    content: "";
}

.wj-glyph-calendar {
    position: relative;
    display: inline-block;
    top: 0.2em;
    width: 1em;
    height: 1em;
    border: 0.1em solid;
    border-top: 0.3em solid;
    opacity: 0.75;
    cursor: pointer;
}

.wj-glyph-calendar:after {
    position: absolute;
    font-size: 50%;
    top: -0.15em;
    left: 0.4em;
    content: "1";
}

.wj-glyph-clock {
    position: relative;
    display: inline-block;
    top: 0.2em;
    width: 1em;
    height: 1em;
    border: 0.13em solid;
    border-radius: 50%;
    opacity: 0.75;
    cursor: pointer;
}

.wj-glyph-clock:after {
    position: absolute;
    border-left: 0.1em solid;
    border-bottom: 0.1em solid;
    width: 0.3em;
    height: 0.4em;
    top: 0.05em;
    left: 0.32em;
    content: "";
}

.wj-glyph-pencil {
    position: relative;
    display: inline-block;
    width: 1em;
    height: 1em;
    opacity: 0.75;
}

.wj-glyph-pencil:after {
    position: absolute;
    left: 0;
    content: "\270E";
}

.wj-glyph-asterisk {
    position: relative;
    display: inline-block;
    width: 1em;
    height: 1em;
    opacity: 0.75;
}

.wj-glyph-asterisk:after {
    position: absolute;
    left: 0;
    content: "\2731";
}

.wj-glyph-asterisk-center {
    position: relative;
    display: inline-block;
    width: 1em;
    height: 1em;
    opacity: 0.75;
    vertical-align: middle;
    text-align: center;
}

.wj-glyph-asterisk-center:after {
    position: absolute;
    content: "\2731";
}

/**** MultiAutoComplete styles ****/
.wj-multi-autocomplete {
    width: 100%;
    height: auto;
    padding: 5px;
    border: 1px solid $black-alpha-10;
    border-radius: 6px;
    margin-bottom: 10px;
}

.wj-multi-autocomplete .wj-input {
    float: left;
}

.wj-multi-autocomplete .wj-form-control {
    padding: 10px;
}

.wj-multi-autocomplete .wj-token {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
    display: inline-block;
    // TODO figure out what positioning is being applied to this?
    float: left;
    border: 1px solid $dark-gainsboro-gray;
    background-color: $whisper-gray;
    white-space: nowrap;
    margin: 5px 5px 5px 5px;
    vertical-align: middle;
    cursor: default;
}

.wj-multi-autocomplete .wj-token .wj-token-label {
    display: inline-block;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 5px 5px 5px 10px;
    vertical-align: middle;
    max-width: 500px;
}

.wj-multi-autocomplete .wj-token:hover {
    border-color: $silver-very-light;
}

.wj-multi-autocomplete .wj-token.wj-token-active {
    border-color: $light-pink;
}

.wj-multi-autocomplete .wj-token-helper {
    position: absolute;
    left: -10000px;
}

.wj-multi-autocomplete .wj-token .wj-token-close {
    font-family: "Arial", sans-serif;
    display: inline-block;
    //TODO figure out which lineheight is correct
    line-height: 100%;
    font-size: 1.1em;
    line-height: 1.49em;
    margin-left: 5px;
    float: none;
    height: 100%;
    vertical-align: middle;
    padding-right: 4px;
    cursor: pointer;
}

.wj-multi-autocomplete .wj-token .wj-token-close:hover,
.wj-multi-autocomplete .wj-token .wj-token-close:focus {
    color: $black;
    text-decoration: none;
    cursor: pointer;
    opacity: 0.5;
}
/**************************************************************************
REMOVED CSS for
    wijmo.grid.sheet module - Flexsheet
    wijmo.olap module - Pivot
    TreeView styles - actual control not flexgrid
    Report Viewer
    Flexchart
    Gauges
*/

.bh-reactive-tree .wj-cell {
    overflow: unset;
}

.bh-reactive-tree .wj-flexgrid .wj-colheaders .wj-header,
.bh-reactive-tree .wj-flexgrid .wj-topleft .wj-header {
    border-bottom: 1px solid $lightblue-darken-10percent;
}

.bh-reactive-tree.flexgrid-treeview.flexgrid-single-select .treeview-shift,
.bh-reactive-tree.flexgrid-treeview.flexgrid-multi-select .treeview-shift {
    padding-left: 0;
}

.bh-reactive-tree.flexgrid-treeview.flexgrid-single-select .treeview-shadebox,
.bh-reactive-tree.flexgrid-treeview.flexgrid-multi-select .treeview-shadebox {
    left: 0;
}

.filter {
    .form-group.input-group {
        width: 90%;
    }
}
