@import "custom-variables";
@import "../../node_modules/bootstrap/scss/variables";
//== Buttons

.btn {
    display: inline-block;
    margin-bottom: 0;
    font-weight: normal;
    text-align: center;
    vertical-align: middle;
    touch-action: manipulation;
    cursor: pointer;
    border: 1px solid transparent;
    white-space: nowrap;
    padding: 10px;
    font-size: $font-size-base;
    line-height: $line-height-base;
    border-radius: $border-radius-base;
    user-select: none;
    transition-duration: 0.2s;
    box-shadow: $box-shadow-1;
    outline: none !important;

    & .fa-text {
        margin-right: 6px;
    }
}

.btn-margin {
    margin: 0 4px;
}

.btn-sm {
    padding: 9px 10px 8px;
    font-weight: bold;
    font-size: $font-size-small;
}

.btn-lg {
    padding: 12px;
    font-size: $large-font-size;
}

/*Used for fa-lg*/
.btn-icon {
    padding: 7px 5px;
}

/*Used for fa-lg within table rows*/
.btn-icon-sm {
    padding: 2px 0;

    .fa-lg {
        font-size: 1.125em;
        min-width: 23px;
        //min-height: 23px;
    }
}

/*Used for fa-2x*/
.btn-icon-lg {
    padding: 5px 12px 4px;
}

.btn-unstyled {
    border: none;
    background-color: transparent;
    padding: 0;
    margin: 0;
    text-align: inherit;
    color: inherit !important;
    box-shadow: none;
}

.btn-link {
    .btn-unstyled {
        color: $blue !important;
    }

    &:hover,
    &:focus {
        color: $green !important;
    }
}

.btn.fa {
    box-shadow: none;

    &.fa-info-circle {
        margin-left: 8px !important;
    }
}

.btn-default,
.btn-blue, .dropdown-menu .btn-blue {
    background-color: $blue;
    color: white;
    border-color: $blue;

    .overlay-icon-blue {
        position: absolute;
        top: 17px;
        right: 1px;
        -webkit-text-fill-color: white;
        text-shadow: -1px -1px 0 $blue, 1px -1px 0 $blue, -1px 1px 0 $blue, 1px 1px 0 $blue;
    }

    &:hover {
        background-color: $lightblue;
        border-color: $lightblue-darken-5percent;

        .overlay-icon-blue {
            -webkit-text-fill-color: white;
            text-shadow: -1px -1px 0 $lightblue, 1px -1px 0 $lightblue, -1px 1px 0 $lightblue, 1px 1px 0 $lightblue;
        }
    }

    &:focus {
        box-shadow: 0 0 0 1px $darkblue;
    }

    &:active:hover,
    &:active:focus {
        background-color: $darkblue;
        border-color: $darkblue;
    }

    &:disabled,
    &.disabled {
        background-color: $blue-disabled !important;
        color: $white !important;
        border-color: $blue-disabled !important;

        .overlay-icon-blue {
            -webkit-text-fill-color: $white;
            text-shadow: -1px -1px 0 $blue-disabled, 1px -1px 0 $blue-disabled, -1px 1px 0 $blue-disabled, 1px 1px 0 $blue-disabled;
        }
    }
}

.dropdown-menu .btn-sm.btn-blue {
    width: unset !important;
    margin-left: 5px !important;
}

.btn-white2 {
    &:hover {
        color: white;
        background-color: $blue;
        border-color: $blue;
    }
}

.btn-white,
.btn-white2 {
    background-color: white;
    color: $blue;
    border-color: $blue;

    .overlay-icon {
        position: absolute;
        top: 17px;
        right: 1px;
        -webkit-text-fill-color: $blue;
        text-shadow: -1px -1px 0 $white, 1px -1px 0 $white, -1px 1px 0 $white, 1px 1px 0 $white;
    }

    &:hover {
        color: $blue;
        background-color: $whiteblue;
        border-color: $blue;

        .overlay-icon {
            -webkit-text-fill-color: $blue;
            text-shadow: -1px -1px 0 $whiteblue, 1px -1px 0 $whiteblue, -1px 1px 0 $whiteblue, 1px 1px 0 $whiteblue;
        }
    }

    &:focus {
        box-shadow: 0 0 0 1px $blue;
    }

    &:active:hover,
    &:active:focus {
        border-color: $blue;
    }

    &.btn-icon:hover,
    &.btn-icon:focus {
        color: $green;
        background-color: white;

        .overlay-icon {
            -webkit-text-fill-color: $green;
            text-shadow: -1px -1px 0 $white, 1px -1px 0 $white, -1px 1px 0 $white, 1px 1px 0 $white;
        }
    }

    &:hover {
        color: white;
        background-color: $blue;
        border-color: $blue;
    }

    &:disabled,
    &.disabled {
        color: $blue !important;
        border-color: $white-disabled !important;

        .overlay-icon {
            -webkit-text-fill-color: $blue;
            text-shadow: -1px -1px 0 $white-disabled, 1px -1px 0 $white-disabled, -1px 1px 0 $white-disabled, 1px 1px 0 $white-disabled;

            &:hover {
                -webkit-text-fill-color: $blue;
                text-shadow: -1px -1px 0 $white-disabled, 1px 1px 0 $white-disabled, -1px 1px 0 $white-disabled, 1px 1px 0 $white-disabled !important;
            }
        }
    }
}

.btn-red-solid {
    background-color: $red;
    color: white;
    border-color: $red;
}

.btn-red {
    background-color: white;
    color: $red;
    border-color: $red;

    &:hover {
        color: $red;
        background-color: $whiteblue;
        border-color: $red;
    }

    &:focus {
        box-shadow: 0 0 0 1px $red;
    }

    &:active:hover,
    &:active:focus {
        border-color: $red;
    }

    &.btn-icon:hover,
    &.btn-icon:focus {
        color: $green;
        background-color: white;
    }

    &:hover {
        color: white;
        background-color: $red;
        border-color: $red;
    }

    &:disabled,
    &.disabled {
        color: $red !important;
        border-color: $white-disabled !important;
        background-color: $white-disabled !important;
    }
}


.btn-green {
    background-color: $green;
    color: white;
    border-color: $darkgreen;

    &:hover {
        background-color: $darkgreen;
    }

    &:focus {
        box-shadow: 0 0 0 1px $darkgreen-darken-10percent;
    }

    &:active:hover,
    &:active:focus {
        background-color: $darkgreen-darken-10percent;
        border-color: $darkgreen-darken-10percent;
    }

    &:disabled,
    &.disabled {
        background-color: $green-disabled !important;
        color: $white !important;
        border-color: $green-disabled !important;
    }
}


.show > .dropdown-toggle.btn-blue:focus,
.show > .dropdown-toggle.btn-blue:hover {
    background-color: $darkblue;
    border-color: $darkblue;
}

//Button badges
.btn-badge {
    padding: 0 1px;
    border-radius: $border-radius-base;
    min-width: 20px;
    text-align: center;
    display: inline-block;
    font-weight: bold;
    margin-left: 5px;
}

.btn-badge-violation {
    .btn-badge {
        background-color: $violation-bg;
        color: $violation-text;
    }
}

.btn-badge-warning {
    .btn-badge {
        background-color: $warning-bg;
        color: $warning-text;
    }
}

.btn-badge-information {
    .btn-badge {
        background-color: $whiteblue-lighten-2percent;
        color: $blue;
    }
}

//Corner buttons

.btn-corner {
    .btn-unstyled {
        position: absolute !important;
        top: 10px;
        right: 10px;
        color: $blue !important;
    }

    + .btn {
        right: 40px;
    }

    &:hover,
    &:focus {
        color: $green !important;
    }
}

// Inline Button Groups

.inline-btns {
    & .btn {
        margin-right: 10px;

        &:last-of-type {
            margin-right: 0;
        }
    }
}

//== Back to Top Button

button.scroll-top-btn,
button.scroll-bottom-btn {
    background: var(--primary-color3);
    width: 34px;
    height: 34px;
    margin-left: 5px;
    background-color: $green;
    border-radius: $border-radius-small;
    padding: 0;
    text-align: inherit;
    border: 0;
    box-shadow: 0 2px 3px rgba(0, 0, 0, .1);

    .btn-unstyled {
        width: 34px;
        height: 34px;
        margin-left: 5px;
        background-color: $green;
        border-radius: $border-radius-small;
    }

    &:hover {
        background-color: $darkgreen;
    }

    & i {
        color: white;
        font-size: 1.375rem;
        padding: 4px 6px 4px 7px;
    }
}

//== Close icon
.close {
    float: right;
    font-size: $h3-font-size;
    color: $blue;
    opacity: 0.6;
    cursor: pointer;
    margin-left: 10px;

    &:hover,
    &:focus {
        opacity: 1;
    }
    // Additional properties for button version
    // iOS requires the button element instead of an anchor tag.
    // If you want the anchor version, it requires `href="#"`.
    // See https://developer.mozilla.org/en-US/docs/Web/Events/click#Safari_Mobile
    button {
        padding: 0;
        cursor: pointer;
        background: transparent;
        border: 0;
        -webkit-appearance: none;
    }
}

.min-width50 {
    min-width: 50px;
}

.page-scroll-container {
    position: fixed;
    bottom: 5px;
    right: 15px;
    z-index: 999;
}

button.close {
    padding: 0;
    cursor: pointer;
    background: transparent;
    border: 0;
    -webkit-appearance: none;
}

.input-group-append {
    .icon-btn {
        border-bottom: none;
        margin-right: 8px;
        margin-left: 0px !important;
    }

    .btn {
        height: 39px;
    }
}

.clear-field-btn {
    border: none;
    margin: 0;
    text-align: inherit;
    color: inherit !important;
    box-shadow: none;
    position: absolute;
    padding: 2px;
    right: 50px;
    bottom: 4px;
    z-index: 5;
    background-color: #fff;
}

button.info-btn {
    border: none;
    margin: 0;
    text-align: inherit;
    color: var(--primary-color);
    box-shadow: none;
    position: absolute;
    padding: 2px;
    bottom: 0px;
    margin-left: 5px;
    background-color: transparent;
    border: none;
    outline: none;
    cursor: pointer;
}

.btn-corner {
    border: none;
    background-color: transparent;
    padding: 0;
    margin: 0;
    text-align: inherit;
    box-shadow: none;
    position: absolute !important;
    top: 10px;
    right: 10px;
    color: var(--primary-color) !important;
}

.bg-danger {
    background: var(--error-light-color);
}

.btn:disabled, .btn-white:disabled, .btn-icon:disabled, .btn-white .disabled, .btn-icon .disabled {
    &:hover, &:focus {
        background-color: var(--disabled-color) !important;

        .overlay-icon {
            -webkit-text-fill-color: $blue;
            text-shadow: -1px -1px 0 $white-disabled, 1px 1px 0 $white-disabled, -1px 1px 0 $white-disabled, 1px 1px 0 $white-disabled !important;
        }
    }
}


.btn:disabled,
.btn.disabled {
    cursor: not-allowed;
    box-shadow: none;
}

.btn-white.btn-icon.disabled:focus {
    background-color: var(--disabled-color) !important;
}

.btn.btn-blue.btn-icon-stack {
    i:last-child {
        position: relative;
        bottom: -3px;
        left: -7px;
        -webkit-text-fill-color: white;
        text-shadow: -1px -1px 0 $blue, 1px -1px 0 $blue, -1px 1px 0 $blue, 1px 1px 0 $blue;
    }

    &:hover {
        background-color: $lightblue;
        border-color: $lightblue-darken-5percent;

        .overlay-icon-blue {
            -webkit-text-fill-color: $blue;
            text-shadow: -1px -1px 0 $lightblue, 1px -1px 0 $lightblue, -1px 1px 0 $lightblue, 1px 1px 0 $lightblue;
        }
    }

    &:focus {
        box-shadow: 0 0 0 1px $darkblue;
    }

    &:active:hover,
    &:active:focus {
        background-color: $darkblue;
        border-color: $darkblue;
    }

    &:disabled,
    &.disabled {
        background-color: $blue-disabled !important;
        color: $white !important;
        border-color: $blue-disabled !important;

        .overlay-icon-blue {
            -webkit-text-fill-color: $white;
            text-shadow: -1px -1px 0 $blue-disabled, 1px -1px 0 $blue-disabled, -1px 1px 0 $blue-disabled, 1px 1px 0 $blue-disabled;
        }
    }
}

.btn-input-group {
    border: 1px solid var(--grayscale-light-color);
    border-bottom: 3px solid var(--grayscale-light-color);
    border-left: 0px;
    box-shadow: none;
    border-radius: 0;
    font-size: .9625rem;
    min-height: 36px;
    line-height: 26px;
    padding: 4px 4px 0;
    transition: none;
    margin-top: 4px;
}
