.mega-picker-selected-item {
    color: white;
    background-color: dodgerblue;

    a {
        color: white;
    }
}

.mega-picker .form-control {
    cursor: pointer;
}

.mega-picker-image {
    max-height: 20px;
    max-width: 40px;
}

.mega-picker-content-overflow-auto {
    overflow: auto
}

.mega-picker-content-container {
    left: 0;
    right: 0;
    z-index: 1000;
    position: absolute;
    background-color: white;
    box-shadow: rgba(0, 0, 0, .5) 0 2px 4px 0;
}

.mega-picker-suggestion-container {
    left: 0;
    right: 0;
    z-index: 1000;
    position: inherit;
    background-color: white;
    box-shadow: rgba(0, 0, 0, .5) 0 2px 4px 0;
    overflow-y: scroll;
    padding-bottom: 30px;
}

.mega-picker-suggestion {
    min-height: 25px;
    padding: 10px;
    cursor: pointer;
}

.mega-picker-suggestion:hover {
    color: white;
    background-color: dodgerblue;

    a {
        color: white;
    }
}

.mega-picker-suggestion-selected {
    color: white;
    background-color: dodgerblue
}

.mega-picker-add {
    border-top: 1px solid grey;
    padding: 10px;
    cursor: pointer;

    a {
        float: right;
        margin-bottom: 10px;
    }
}

.mega-picker-child-container {
    margin-top: 10px;
    border-left: 2px solid var(--primary-color);
}

.position-fixed-top {
    position: sticky;
    z-index: 9;
    background: #fff;
    top: 0
}

.position-fixed-bottom {
    position: fixed;
    margin-top: 115px;
    z-index: 99;
    background: #fff;
    height: 25px;
    width: 255px;
    line-height: 0;
}

.input-suggestion-container {
    position: absolute;
    top: 100%;
    background-color: #fff;
    box-shadow: rgb(0 0 0 / 50%) 0 2px 4px 0;
    overflow-y: scroll;
    z-index: 1000
}

.top-absolute {
    position: absolute !important;
    top: 100%;
}

.mega-picker-disabled-item {
    background-color: var(--grayscale-white-color);
    cursor: not-allowed !important;
}
